import React, {useCallback} from 'react'
import { Grid, Icon, IconButton, Typography, makeStyles } from '@material-ui/core';
import userimage from '../../../../../assets/patient.png'
import { contentData } from './content';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';
import NSEContainer from '../NSEContainer/NSEContainer';
import SimpleFile from '../../../../../components/Files/SimpleFile/SimpleFile';

import {constrainst_formA as constrainst} from  '../constraints'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout';
import ProductTypeSelection from '../components/ProductTypeSelection';
import TreatmentForm from '../components/TreatmentForm';
import { grey } from '@material-ui/core/colors';
import useOnboardingContext from '../../../../../providers/OnboardingProvider/useOnboardingContext';
import useAppCatalogs from '../../../../../hooks/api/useAppCatalogs'
import { usePlatformContext } from '../../../../../context/PlatformContext';
import OnboardingActionBar from '../components/OnboardingActionBar';
import { USER_TYPE } from '../../../../../variables/config';
import { getUserType } from '../../../../../shared/utility';


const FormStepA = () => {
    
    const catalogManager = useAppCatalogs()
    const {language} = usePlatformContext() 
    const classes = useStyles()

    const {
      step,
      formManager, 
      is_public_insurance,
      identity_document, 
      proof_of_address,
      nse,
      doctors, 
      genders,
      public_insurances,
      products,
      hospitals,
      productsCat, 
      setIdentityDocument,
      setProofOfAddress,
      setIsPublicInsurance,
      setNSE,
      product_type,
      setProductType,
      treatmentManager,
      setStep,
      setError
    } = useOnboardingContext()

    const {form, onChangeForm, onGetFormData} = formManager


    const onSubmit = useCallback(async() => {
      const userType = getUserType()
      let cleandata = onGetFormData(form)

      if(!(cleandata?.external_id) && userType !== USER_TYPE.HOSPITAL){
        console.log("Hay error", "Necesita colocar un ID programa de bonificaciones")
        setError('Necesita colocar un ID programa de bonificaciones')
        return
      }
      setStep(step + 1)
    }, [formManager, form, onChangeForm, onGetFormData])


    const onSearchData = useCallback(async(id, value) => {
      switch(id){
          case 'external_id':
                  try {
                      await catalogManager.onCheckExternalId(value)
                  } catch (error) {
                      let _data2update = {...form['external_id']}
                      _data2update.isValid = false
                      onChangeForm(_data2update)
                  }
                  break;
          case 'folio':
              try {
                  await catalogManager.onCheckFolio(value)
              } catch (error) {
                  let _data2update = {...form['folio']}
                  _data2update.isValid = false
                  onChangeForm(_data2update)
              }
              break;
          case 'zip':
              try {
                  let response = await catalogManager.onGetNSE(value)
                  //console.log(response)
                  setNSE(response)
              } catch (error) {
                  console.log('Hubo un error')
                  setNSE(null)
              }
              break;
          default:
              break;
      }
  }, [form, onChangeForm])

    const content = contentData[language]

    let publicInsuranceContent = null
    if(is_public_insurance){
      publicInsuranceContent = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText 
                text={content.subtitles.publicInsurance} 
                variant='bold'
                medium
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <LineInputSelect 
                    data={form.public_insurance_id}
                    onChange={onChangeForm}
                    catalogs={public_insurances}
                  />
                </Grid> 
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    let documentsContent = (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SimpleFile 
              id='identity_document'
              label='Identificación oficial'
              file={identity_document}
              onDocumentAttached={(id, files) => setIdentityDocument(files[0])}
              onDocumentRemove={() => setIdentityDocument(null)}/>
          </Grid>
          <Grid item xs={12}>
            <SimpleFile 
              id='proof_of_address'
              label='Comprobante de domicilio'
              file={proof_of_address}
              onDocumentAttached={(id, files) => setProofOfAddress(files[0])}
              onDocumentRemove={() => setProofOfAddress(null)}/>
          </Grid>

        </Grid>
      </Grid>
    )


    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <ContraintLayout constrainst={constrainst.external_id}>
                            <LineInputText 
                              data={form.external_id}
                              onChange={onChangeForm}
                              delayedSearch
                              onDelayedSearch={onSearchData}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.external_id}>
                            <LineInputText 
                              data={form.por_ti_id}
                              onChange={onChangeForm}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.folio}>
                            <LineInputText 
                              data={form.folio}
                              onChange={onChangeForm}
                              delayedSearch
                              onDelayedSearch={onSearchData}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.name}>
                            <LineInputText 
                              data={form.name}
                              onChange={onChangeForm}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.email}>
                            <LineInputText 
                              data={form.email}
                              onChange={onChangeForm}
                            />
                          </ContraintLayout>
                          <ContraintLayout constrainst={constrainst.phone}>
                            <LineInputText 
                              data={form.phone}
                              onChange={onChangeForm}
                            />
                          </ContraintLayout>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={form.doctor_id}
                              onChange={onChangeForm}
                              catalogs={doctors}

                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={form.gender_id}
                              onChange={onChangeForm}
                              catalogs={genders}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}/>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={form.zip}
                              onChange={onChangeForm}
                              delayedSearch
                              onDelayedSearch={onSearchData}
                            />
                          </Grid>
                          {nse ? (
                            <Grid item xs={12} md={6}>
                              <NSEContainer nse={nse}/>
                            </Grid>
                          ) : null}
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item>
                            <ItemSelection 
                              id='is_public_insurance'
                              status={is_public_insurance}
                              text='¿El Beneficiario cuenta con aseguradora pública?'
                              onChange={(id, status) => setIsPublicInsurance(status)}
                            />
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------PUBLIC INSURANCE ------------------------- */}
                  {publicInsuranceContent}
                  {/* ------------------DOCUMENTS CONTENT ------------------------- */}
                  {documentsContent}

                  {/* ------------------ PRODUCT TYPES ------------------------- */}
                  
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs>
                        <Typography variant='h6' color='primary'>Tratamientos</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems='center' spacing={1}>
                          <Grid item><Typography color='primary' variant='subtitle2'>Agregar tratamiento</Typography></Grid>
                          <Grid item><IconButton size='small' className={classes.add_button} onClick={treatmentManager.actions.onAddTreatmentRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <ProductTypeSelection 
                      value={product_type?.value} 
                      onChange={(value) => setProductType(value)}/>
                  </Grid>
                  {/* ------------------ MULTIPLE PRODUCTS ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container>
                      {treatmentManager.treatments.map((item, key)=> {
                        return(
                          <Grid item xs={12} key={`treatmentf-${key}`}>
                            <div style={{
                              borderTop:`1px solid ${grey[300]}`,
                              padding:'32px 0px',
                              marginBottom:32
                            }}>
                              <TreatmentForm 
                                originProducts={products}
                                products={productsCat}
                                position={key+1}
                                form={item.form}
                                vials={item?.vials??[]} 
                                schemes={item?.schemes??[]}
                                diagnostics={item?.diagnostics??[]}
                                hospitals={hospitals}
                                onInputChange={
                                  (data) => treatmentManager.actions.onInputChange(data, key)
                                } 
                                onUpdateVial={(data, mode) => treatmentManager.actions.onUpdateVial(data, mode, key)}
                                onMoveVial={(data, mode) => treatmentManager.actions.onMoveVial(data, mode, key)}
                                />
                            </div>
                            
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <OnboardingActionBar
          onReturn={() => setStep(step-1)}
          onSubmit={onSubmit}
        />
      </div>
    )
  
}

const useStyles = makeStyles((theme) => ({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:160,
    height:160,
  },
  add_button:{
    background:theme.palette.primary.main,
    color:'white',
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  }
}))

export default FormStepA


