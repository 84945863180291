export const content = {
  title:'Carta Distribuidor',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Descargar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    patient_provider_id:{
      label:'Distribuidor',
      placeholder:'Distribuidor',
    },
    manual_provider_address_value:{
      label:'Dirección de envío',
      placeholder:'Dirección de envío',
    },
    alternative_hospital_id:{
      label:'Hospital Alternativo',
      placeholder:'Hospital Alternativo',
    },
    manual_hospital_address_value:{
      label:'Dirección de envío',
      placeholder:'Dirección de envío',
    },
    manual_particular_address_value:{
      label:'Dirección de envío',
      placeholder:'Dirección de envío',
    }
  },
}