export const formData = {
  sectionA:{
    name:{
      label:'',
      data:'',
    },
    contact_name:{
      label:'',
      data:'',
    },
    contact_email:{
      label:'',
      data:'',
    },
    contact_phone:{
      label:'',
      data:'',
    },
    contact_ext:{
      label:'',
      data:'',
    },
    address:{
      label:'',
      data:'',
    },
    crm_id:{
      label:'',
      data:'',
    },
    uci:{
      label:'',
      data:'',
    },
  },
}