import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditBasicInfo from './EditBasicInfo/EditBasicInfo'

import {request_patient, request_edit_patient, request_doctors,
  request_nse, request_external_id, request_folio,request_public_insurances,
  request_hospitals, request_insurance_carriers, request_policy_amounts, request_providers
} from './requests'
import {getDoctorsOptions, getPublicInsurancesOptions, getHospitalsOptions,
  getInsuranceCarriersOptions, getProvidersOptions} from './customFunctions'

class PatientEdit extends Component{

  state = {
    isloading:false,
    issending:false,
    error:null,
    patient:null,
    nse:{},
    doctors:[],
    public_insurances:[],
    hospitals:[],
    providers:[],
    policy_amounts:[],
    insurance_carriers:[],
    is_public_insurance:false,
    is_secondary_hospital:false,
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {history, language, catalogs,translations} = this.props;
    const {isloading, issending, error, patient, success, doctors, public_insurances,
    nse, hospitals,is_secondary_hospital, insurance_carriers, providers, policy_amounts} = this.state
    const content = contentData[language]

    const doctors_catalog = getDoctorsOptions(doctors)
    const public_insurances_catalog = getPublicInsurancesOptions(public_insurances)
    const hospitals_catalog = getHospitalsOptions(hospitals)
    const insurance_carriers_catalog = getInsuranceCarriersOptions(insurance_carriers)
    const providers_catalog = getProvidersOptions(providers)


    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditBasicInfo
              language={language}
              catalogs={catalogs}
              patient={patient}
              nse={nse}
              policy_amounts={policy_amounts}
              doctors={doctors_catalog}
              hospitals={hospitals_catalog}
              providers={providers_catalog}
              is_secondary_hospital={is_secondary_hospital}
              public_insurances={public_insurances_catalog}
              insurance_carriers={insurance_carriers_catalog}
              issending={issending}
              error={error}
              translations={translations}
              onSubmit={this.onSubmitUser}
              onRequestNSE={this.onRequestNSE}
              onRequestExternalID={this.onRequestExternalID}
              onRequestFolio={this.onRequestFolio}
            />
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_patient(id)
    response = {...response, balance_value:response.balance_value !== null ? response.balance_value/100 : 0}
    this.setState({patient:{...response}})
    this.setState({
      nse:{
        nse_name: response.nse,
        nse_id: response.nse_id,
        nse_value: response.nse_vale,
        state:response.state,
        city:response.city
      },
      is_secondary_hospital: response.patient_products.length > 1 
    })

    response = await request_doctors()
    await this.setState({doctors:[...response]})
    response = await request_public_insurances()
    this.setState({public_insurances:[...response]})
    response = await request_hospitals()
    this.setState({hospitals:[...response]})
    response = await request_providers()
    this.setState({providers:[...response]})
    response = await request_insurance_carriers()
    this.setState({insurance_carriers:[...response]})
    response = await request_policy_amounts()
    this.setState({policy_amounts:[...response]})

    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onSubmitUser = async(data) => {
    const {match} = this.props
    const {nse} = this.state
    const id = match.params.id

    if(data.zip){
      data = {...data, nse_id:nse.nse_id}
    }
    if(data.balance_value !== '' && data.balance_value !== undefined  && data.balance_value !== null){
      data = {...data, balance_value: parseInt(parseFloat(data.balance_value)*100)}
    }

    try {
      this.setState({issending:true})
      let response = await request_edit_patient(id,data)
      this.setState({patient:{...response}})
      this.setState({issending:false, error:null})
      this.props.history.push("/patients/"+id)
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }
      
    }
  }

  onRequestNSE = async(zip) => {
    this.setState({issending:true})
    try {
      
      let response
      response = await request_nse(zip)
      await this.setState({nse:{...response}})
    } catch (error) {
      await this.setState({nse:null})
      
    }
    this.setState({issending:false})
  }

  onRequestExternalID = async(data) => {
    this.setState({issending:true})
    const {input_error} = this.state
    try {
      await request_external_id(data)
      let temp = {...input_error, external_id:undefined};
      this.setState({input_error:{...temp}})
    } catch (error) {
      let temp = {...input_error, external_id:'El ID introducido está en uso'};
      this.setState({input_error:{...temp}})
    }
    this.setState({issending:false})
  }

  onRequestFolio = async(data) => {
    this.setState({issending:true})
    const {input_error} = this.state
    try {
      await request_folio(data)
      let temp = {...input_error, folio:undefined};
      this.setState({input_error:{...temp}})
    } catch (error) {
      let temp = {...input_error, folio:'El ID introducido está en uso'};
      this.setState({input_error:{...temp}})
    }
    this.setState({issending:false})
  }
}

export default withStyles(styles)(PatientEdit)