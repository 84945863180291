import React, {useState} from 'react'
import { makeStyles, Button, Icon, Grid, Divider } from '@material-ui/core'
import PopperWrapper from '../../../../../components/Poppers/PopperWrapper'
import ContentText from '../../../../../components/Texts/ContentText'
import { grey } from '@material-ui/core/colors'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{},
    button:{
        textTransform:'none',
        borderRadius:32
    }
}))


const DocumentButton = props => {

    const {text, color, onDownload, onUpload, onDelete, disabled} = props
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const onOpen = (event) => {
        setOpen(true) 
        setAnchorEl(event.currentTarget)
    }

    const onClickAway = () => {
        setOpen(false) 
        setAnchorEl(null)
    }

    const onInternalUpload = () => {
        onClickAway()
        if(onUpload) onUpload()
    }

    const onInternalDownload = () => {
        onClickAway()
        if(onDownload) onDownload()
    }

    const onInternalDelete = () => {
        onClickAway()
        if(onDelete) onDelete()
    }
    

    return(
        <div>
            <PopperWrapper
                anchorEl={anchorEl}
                open={open}
                onClickAway={onClickAway}>
                <div style={{width:140}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <SingleItemMenu icon='publish' label='Subir'
                            onClick={onInternalUpload}/>
                        </Grid>
                        <Grid item xs={12}><Divider/></Grid>
                        <Grid item xs={12}>
                            <SingleItemMenu icon='get_app' label='Descargar' disabled={disabled}
                            onClick={onInternalDownload}/>
                        </Grid>
                        <Grid item xs={12}><Divider/></Grid>
                        <Grid item xs={12}>
                            <SingleItemMenu icon='delete' label='Elminar' isLast disabled={disabled}
                            onClick={onInternalDelete}/>
                        </Grid>
                    </Grid>
                </div>
            </PopperWrapper>
            <Button variant='contained' className={classes.button} color={color}
                onClick={onOpen}>
                {text}
            </Button>
        </div>
    )
}

export default DocumentButton

const useStylesMenuItem = makeStyles(theme => ({
    root:{
        padding:16,
        paddingTop:12,
        paddingBottom:12,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    disabled:{
        background:grey[100],
        '&:hover':{
            cursor:'pointer',
            background:grey[100],
            cursor:'not-allowed'
        }
    },
    iconDisabled:{
        color:grey[500]
    }
}))

const SingleItemMenu = props => {

    const {icon, label, onClick, disabled} = props
    const classes = useStylesMenuItem()

    return(
        <div onClick={!disabled ? onClick : null} className={cx({
            [classes.root]:true,
            [classes.disabled]:disabled
        })}>
            <Grid container wrap='nowrap' spacing={1}>
                <Grid item xs>
                    <ContentText text={label} medium variant={disabled ? 'light' : undefined}/>
                </Grid>
                <Grid item>
                    <Icon className={cx({
                        [classes.icon]:true,
                        [classes.iconDisabled]:disabled
                    })} fontSize='small' color='secondary'>{icon}</Icon>
                </Grid>
            </Grid>
        </div>
    )
}