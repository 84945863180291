import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../../components/Layouts/BasicView/BasicView' 
import ContentText from '../../../../../components/Texts/ContentText';

class Catalogs extends Component{

  state = {
    isloading:false,
    users:[],
    modals:{
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  componentDidMount(){
  }
 
  render(){

    const {classes, history, language,} = this.props
    const content = contentData[language]

    let cardsContent = content.catalogs.map((item,key)=>{
      return(
        <Grid item xs={12} lg={6} key={key.toString()}>
          <div className={classes.card} onClick={() => this.onCatalogSelected(item.url)}>
            <Grid container spacing={2}>
              <Grid item>
                <img src={item.image} alt='' className={classes.image}/>
              </Grid>
              <Grid item xs>
                <ContentText text={item.name} medium/>
              </Grid>
            </Grid>
          </div>
        </Grid>
        
      )
    })

    
    return(
      <BasicView
        history={history}
        content={content.layout}
      >
        <Grid container spacing={2}>
          {cardsContent}
        </Grid>
        
      </BasicView>
      
    )
  }

  onCatalogSelected = (url) => {
    this.props.history.push(url)
  }
}

export default withStyles(styles)(Catalogs)