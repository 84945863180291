import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralView from './GeneralView/GeneralView'
import {request_user} from './requests'

class AgentView extends Component{

  state = {
    isloading:false,
    user:null
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_user(id)
    this.setState({user:{...response}})
    this.setState({isloading:false})
  }

  onEditUser = async() => {
    const {match,history} = this.props
    const id = match.params.id
    history.push('/agents/'+id+'/edit')
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/agents')
  }


  render(){

    const { history, language, catalogs, translations} = this.props
    const {user} = this.state
    const content = contentData[language]
    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <GeneralView
              language={language}
              catalogs={catalogs}
              user={user}
              translations={translations}
              onEdit={this.onEditUser}
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(AgentView)