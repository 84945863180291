import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import {ResponsiveContainer, PieChart, Pie, Cell} from 'recharts'
import { blue, grey } from '@material-ui/core/colors';

const styles = (theme) => ({
  root:{}
})

const COLORS = [blue[900], blue[600], grey[400], '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class PatientStatsChart extends Component {
  render(){

    const {data} = this.props

    let isValid = false
    if(data){
      if(data.length){
        data.forEach(item => {
          if(item.value !== 0){
            isValid = true
          }
        })
      }
    }


    return(
      <div style={{ height:'250px'}}>
        <ResponsiveContainer>
          <PieChart>
            <Pie 
            data={data} 
            fill="#8884d8" 
            labelLine={false}
            label={renderCustomizedLabel}>
              {
                data.map((entry, index) => <Cell key={index.toString()} fill={isValid ? COLORS[index % COLORS.length] : grey[400]}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default withStyles(styles)(PatientStatsChart)