import { useEffect, useState } from "react"
import usePatient from "../../../../../../hooks/usePatient"
import usePatientMedicaReport from "../../../../../../hooks/usePatientMedicalReport"
import { useHistory, useParams } from "react-router-dom";

const useMedicalReportsView = (props) => {

    const patientManager = usePatient()
    const {patient} = patientManager.data
    const {id} = useParams()
    const medicalReportManager = usePatientMedicaReport()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [report, setReport] = useState()
    const [documentPath, setDocumentPath] = useState()

    const [modals,setModals] = useState({
        add_report:false,
        edit_report:false,
        upload_report:false,
        delete_report:false
    })

    useEffect(() => {
        console.log(id)
        patientManager.actions.onGet(id)
    }, [])

    useEffect(() => {
        if(patient) actions.onInitModule()

    }, [patient])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            //console.log('Ya puedo inicializar')
            
            await medicalReportManager.actions.onGetAllByPatient(patient?.id_patient)
            setLoading(false)
        },
        
        onEditMedicalReport: (data) => {
            setReport(data)
            actions.onChangeModalStatus('edit_report', true)
        },
        onApproveMedicalReport: async(data, status) =>{
            if(sending) return
            setSending(true)
            const data2send = {validated_medical_report:status ? 1 : 0}
            //console.log(data2send)
            try {
                const result = await medicalReportManager.actions.onUpdate(data?.id_patient_medical_report, data2send)
                //console.log(result)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
                await medicalReportManager.actions.onGetAllByPatient(patient?.id_patient)
            } catch (error) {
                console.log(error)
                setError('Hubo un error, intente de nuevo')
            }
            setSending(false)
        },
        onSetCurrentMedicalReport: async(data, status) =>{
            if(sending) return
            setSending(true)
            const data2send = {current:status ? 1 : 0}
            //console.log(data2send)
            try {
                const result = await medicalReportManager.actions.onUpdate(data?.id_patient_medical_report, data2send)
                //console.log(result)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
                await medicalReportManager.actions.onGetAllByPatient(patient?.id_patient)
            } catch (error) {
                console.log(error)
                setError('Hubo un error, intente de nuevo')
            }
            setSending(false)
        },
        onUploadDocument: (data, path) => {
            //console.log(data)
            setReport(data)
            setDocumentPath(path)
            actions.onChangeModalStatus('upload_report', true)
        },
        onDeleteDocument: (data, path) => {
            //console.log(data)
            setReport(data)
            setDocumentPath(path)
            actions.onChangeModalStatus('delete_report', true)
        },
        onDownloadDocument: async(data, path) => {
            await medicalReportManager.actions.onDownloadDocument(data?.id_patient_medical_report, path)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
    }

    const system = {loading, sending,error}
    const data = {report, documentPath, patient}

    return {system, data, actions, modals, medicalReportManager}



} 

export default useMedicalReportsView