import { Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../../components/Buttons/ActionModalBar';
import ItemSelection from '../../../../../../../components/Inputs/ItemSelection/ItemSelection';
import LineInputSelect from '../../../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import LineInputText from '../../../../../../../components/Inputs/LineInputText/LineInputText';
import SimpleModalV2 from '../../../../../../../components/Modals/SimpleModalV2';
import { getLetterProvidersOptions } from '../../../../../../../shared/customFunctions';
import { LETTER_TYPES } from '../../../../../../../shared/enums';
import { isFormValid, onGetFormData, onGetHospitalCatalogs, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onUpdateAuthorizationHeader } from '../../../../../../../shared/utility';
import { private_server } from '../../../../../../../variables/config';
import { request_hospitals, request_patient_providers } from '../../../requests';
import { onGetUseData } from './customFunctions';

const modes = [
    {id:1, label:'Hospital'},
    {id:2, label:'Distribuidor'},
    {id:3, label:'Driección particular'},
]

const PatientLetterModal = (props) => {

    const {open, onClose, mode, type, origin, onRefreshData, patient, patientproduct, onAddData, use, onUpdateData} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formaData)))
    const [patientProductForm, setPatientProductForm] = useState(JSON.parse(JSON.stringify(patientproduct_form)))
    const [option, setOption] = useState(0)

    useEffect(() => {
        if(open){
            onInitModule()
        }else{
            setForm(JSON.parse(JSON.stringify(formaData)))
            setPatientProductForm(JSON.parse(JSON.stringify(patientproduct_form)))
            setError(null)
        }
    }, [open, origin])

    const onInitModule = async() => {
        try {
            let _form, _ppform

            let hospitals = []
            let providers = []
            
            if(type === LETTER_TYPES.INIT_PROVIDER){
                onUpdateAuthorizationHeader(private_server)
                let _useRqt = await private_server.get(`/use/${use?.id_use}`)
                const _use = _useRqt.data.data.use
                hospitals = await request_hospitals()
                providers = await request_patient_providers(patient?.id_patient)
                hospitals = onGetHospitalCatalogs(hospitals)
                providers = getLetterProvidersOptions(providers)
                _ppform = onInitForm(JSON.parse(JSON.stringify(patientproduct_form)), _use)
                _ppform.patient_provider_id.options = [...providers]
                _ppform.alternative_hospital_id.options = [...hospitals]
                setPatientProductForm(_ppform)
                
                if(_use){
                    if(_use.alternative_hospital){
                      setOption(_use.alternative_hospital)
                    }
                }
            }

            if(mode === 'add'){
                _form = JSON.parse(JSON.stringify(formaData))
                setForm(_form)
            }else{
              console.log(origin)
              _form = onInitForm(JSON.parse(JSON.stringify(formaData)), origin)
              setForm(_form)
            }
            //onInitForm(
            //    formaData,
            //    mode === 'add' ? null : origin
            //)
            //console.log(_form)
            /*if(mode === 'edit'){
                console.log(origin)
                let reportDate = moment(origin?.report_date)
                if(reportDate.isValid()){
                    _form.report_date.value = reportDate.format('YYYY-MM-DD')
                    _form.report_date.isValid = true
                    console.log(reportDate.format('YYYY-MM-DD')) 
                }
            }*/
            setForm(_form)
        } catch (error) {
            
        }
    }

    const onInputChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onPPInputChange = (data) => {
        let temp = { ...patientProductForm }
        const id = data.config.id
        temp[id] = { ...data }
        setPatientProductForm(temp)
    }

    const onChangeCheckbox = (val) => setOption(val)

    const onSubmit = async() => {
        setSending(true)
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setSending(false)
            return
        }
        //Extract data to send use
        let data2send, result

        // Use handler
        if(mode === 'add' && type === LETTER_TYPES.INIT_PROVIDER){
            try {
                const useData = onGetUseData(patientProductForm, option)
                console.log(useData)
                if(!useData.valid){
                    console.log('Hubo un error')
                    setError('Falta llenar información')
                    setSending(false)
                    return
                }
                onUpdateAuthorizationHeader(private_server)
                await private_server.post(`/use/${use?.id_use}/update`, useData.data)
            } catch (error) {
                setError('Hubo un error al actualizar la información, intente de nuevo')
                setSending(false)
                return
            }
        }else{

        }
       

       // setSending(false)
       // return

        try {
            if(mode == 'add'){
                data2send = onGetFormData(form)
                data2send.patient_product_id = patientproduct?.id_patient_product
                data2send.patient_id = patient?.id_patient
                data2send.letter_type_id = type
                data2send.use_id = use?.id_use ?? undefined
                console.log(data2send)
                result = await onAddData(data2send)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
            }else{
                data2send = onGetSelectedFormData(form, origin)
                result = await onUpdateData(origin?.id_patient_letter, data2send)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
            }
            await onRefreshData()
            setSending(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Opps, hubo un error. Por favor intente de nuevo')
            setSending(false)
            return
        }
    }

    let title = mode === 'add' ? 'Generar carta' : 'Editar carta'

    let contentA = null

    if(option === 0 || option === 1){
      contentA = (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={patientProductForm.patient_provider_id}
                onChange={onPPInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={patientProductForm.alternative_hospital_id}
                onChange={onPPInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LineInputText
                wrap='vertical'
                data={patientProductForm.manual_hospital_address_value}
                onChange={onPPInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    }else if(option === 2){
      contentA = (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={patientProductForm.patient_provider_id}
                onChange={onPPInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LineInputText
                wrap='vertical'
                data={patientProductForm.manual_provider_address_value}
                onChange={onPPInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        
      )
    }else{
      contentA = (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputText
                wrap='vertical'
                data={patientProductForm.manual_particular_address_value}
                onChange={onPPInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        
      )
    }

    let patientproductcontent = (
        <div style={{borderBottom:`1px solid ${grey[300]}`, padding:'32px 0px'}}>
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <ItemSelection
                                    id={1}
                                    status={option === 0 || option === 1}
                                    text='Hospital'
                                    onChange={onChangeCheckbox}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ItemSelection 
                                    id={2}
                                    status={option === 2}
                                    text='Distribuidor'
                                    onChange={onChangeCheckbox}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ItemSelection 
                                    id={3}
                                    status={option === 3}
                                    text='Dirección particular'
                                    onChange={onChangeCheckbox}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {contentA}
                    </div>
                </Grid>
            </Grid>
        </div>
    )


    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <Typography variant='h6'>{title}</Typography>
            <div>
                
                {type === LETTER_TYPES.INIT_PROVIDER && mode === 'add' ? (
                    patientproductcontent
                ) : null}
                <div style={{padding:'32px 0px', paddingBottom:0}}>
                    <Grid container spacing={3}>                     
                        {/*<Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.date}
                                onChange={onInputChange}/>
                        </Grid>*/}
                        <Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.details}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar 
                                btnLabel={mode === 'add' ? 'Generar' : 'Guardar'}
                                error={error}
                                onCloseError={() => setError(null)}
                                loading={sending}
                                onSubmit={onSubmit}
                            />
                        </Grid>
                    </Grid>
                </div>
                
            </div>
        </SimpleModalV2>
     );
}
 
export default PatientLetterModal;

const formaData = {

    details:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'details',
            type:'text',
            multiline:true,
            rows:2,
            fullWidth: true,
            label:'Comentarios',
            placeholder:'Ingrese alguna información adicionar...',
            helper:'El texto no debe de exceder de 256 caracteres'
        },
        rules:{
            type:'distance',
            min:0,
            max:255
        }
    },
    date:{
        value: moment().format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config:{
            id:'date',
            type:'date',
            fullWidth: true,
            label:'Fecha de la carta',
            placeholder:'',
            helper:'Fecha no válida'
        },
        rules:{
            type:'date',
        }
    },
}


const patientproduct_form = {
    patient_provider_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'patient_provider_id',
            type:'select',
            fullWidth: true,
            label:'Distribuidor',
            placeholder:'Distribuidor',
            md:12
        },
        rules:{
            type:'none',
        }
      },
      manual_provider_address_value:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'manual_provider_address_value',
            type:'text',
            fullWidth: true,
            label:'Dirección de envío',
            placeholder:'Dirección de envío',
            md:12
        },
        rules:{
          type:'distance',
          min:1,
          max:512
        }
      },
      manual_particular_address_value:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'manual_particular_address_value',
            type:'text',
            fullWidth: true,
            label:'Dirección de envío',
            placeholder:'Dirección de envío',
            md:12
        },
        rules:{
          type:'distance',
          min:1,
          max:512
        }
      },
      alternative_hospital_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'alternative_hospital_id',
            type:'select',
            fullWidth: true,
            label:'Hospital Alternativo',
            placeholder:'Hospital Alternativo',
            md:12
        },
        rules:{
          type:'none',
        }
      },
      manual_hospital_address_value:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'manual_hospital_address_value',
            type:'text',
            fullWidth: true,
            label:'Dirección de envío',
            placeholder:'Dirección de envío',
            md:12
        },
        rules:{
          type:'distance',
          min:1,
          max:512
        }
      },
}