import React from 'react'
import {Grid, Typography, Checkbox } from '@material-ui/core'
import {grey} from '@material-ui/core/colors'

const styles = {
  text:{
    fontWeight:'500',
    color:grey[700]
  }
}

const ItemSelection = (props) => {

  const {text, status, id, onChange, disabled, position, textStyes} = props

  let rightContent = (
      <Grid item>
        <Checkbox 
          disabled={disabled}
          checked={status}
          onChange={(event, status) => onChange(id,status)}
        />
      </Grid>
  )
  let leftContent = null

  if(position && position === 'start'){
    leftContent = (
      <Grid item>
        <Checkbox 
          disabled={disabled}
          checked={status}
          onChange={(event, status) => onChange(id,status)}
        />
      </Grid>
    )
    rightContent = null
  }

  return(
    <Grid container spacing={0} alignItems="center" wrap='nowrap'>
      {leftContent}
      <Grid item>
        <Typography variant='body2' style={{...styles.text,...textStyes}}>
          {text}
        </Typography>
      </Grid>
      {rightContent}
      
    </Grid>
  )
}

export default ItemSelection
