import React, { createContext, useCallback, useEffect, useState } from "react";
import { REGISTRATION_STEPS, USER_TYPE } from "../../variables/config";
import useAppCatalogs from "../../hooks/api/useAppCatalogs";
import { getDoctorsHospitalsOptions, getDoctorsOptions, getHospitalsOptions, getInsuranceCarriersOptions, getPolicyAmountsOptions, getProductsOptions, getPublicInsurancesOptions, onGetProductSearchParams } from "../../shared/customFunctions";
import { usePlatformContext } from "../../context/PlatformContext";
import { getUserType } from "../../shared/utility";
import { catalogs } from "../../variables/catalogs";
import usePatientProducts from "../../hooks/api/usePatientProduct";
import useTreatments from "../../views/Admin/Applicants/AddApplicant/hooks/useTreatments";
import useForm from "../../hooks/useForm";
import { formdata } from "./formdata";

export const OnboardingContext = createContext(null)

export const OnboardingProvider = ({children}) => {

    const {user, state} = usePlatformContext()
    const formManager = useForm(formdata)

    const programId = state.programId

    const [step, setStep] = useState(REGISTRATION_STEPS.DISCLAIMER)
    const [error, setError] = useState()
    const [error_process_message, setErrorProcessMessage] = useState([])
    const [loading, setLoading] = useState(false)
    const [creating, setCreating] = useState(false)
    const [done, setDone] = useState(false)
    const [admin, setAdmin] = useState()

    const catalogManager = useAppCatalogs()
    const patientProductManager = usePatientProducts()

    // Boolean data
    const [disclaimer_accepted, setDisclaimerAccepted] = useState(false)
    const [is_public_insurance, setIsPublicInsurance] = useState(false)

    // List of documents
    const [privacy_policy, setPrivacyPolicy] = useState(null)
    const [identity_document, setIdentityDocument] = useState(null)
    const [proof_of_address, setProofOfAddress] = useState(null)
    const [medical_report, setMedicalReport] = useState(null)
    const [insurance_policy, setInsurancePolicy] = useState(null)

    // Catalogs
    const [hospitals, setHospitals] = useState([])
    const [public_insurances, setPublicInsurances] = useState([])
    const [insurance_carriers, setInsuranceCarriers] = useState([])
    const [policy_amounts, setPolicyAmounts] = useState(catalogs.spanish.policy_amounts)
    const [doctors, setDoctors] = useState([])
    const [genders, setGenders] = useState(catalogs.spanish.genders)
    const [products, setProducts] = useState([])
    const [productsCat, setProductCat] = useState([])
    const [payment_types, setPaymentTypes] = useState(catalogs.spanish.payment_types)

    const [product_type, setProductType] = useState()
    const [nse, setNSE] = useState()
    const [main_hospital, setMainHospital] = useState()
    const [policy_value, setPolicyValue] = useState()

    const [patient_data, setPatientData] = useState({

    })

    const treatmentManager = useTreatments({catalogs, productsCat, hospitals})

    

    const onRequestProducts = useCallback(async() => {
        let _products = [] 
        let _productsCat = []
        try {
           let params = {product_type_id:product_type.value}
           if(programId !== -1) params.product_type_id = programId
           _products = await patientProductManager.onGetAll(params)
           _productsCat = getProductsOptions(_products)
           setProducts(_products)
           setProductCat(_productsCat)
        } catch (error) {
            console.log(error)
        }
    }, [product_type, programId])
    

    const onUpdatePatientData = useCallback((data2update) => {
        let _temp = {...patient_data, ...data2update}
        setPatientData(_temp)
    }, [patient_data])



    const initialize = useCallback(async() => {
        console.log("Inicializar", user)
        setLoading(true)
        const userType = getUserType()
        try {

            let _hospitals = await catalogManager.onGetHospitals()
            let _public_insurances = await catalogManager.onGetPublicInsurances()
            let _insurance_carriers = await catalogManager.onGetInsuranceCarriers()
            let _policy_amounts = await catalogManager.onGetPolicyAmounts()

            _public_insurances = getPublicInsurancesOptions(_public_insurances)
            _insurance_carriers = getInsuranceCarriersOptions(_insurance_carriers)
            _hospitals = getHospitalsOptions(_hospitals)
            _policy_amounts = getPolicyAmountsOptions(_policy_amounts)
            //_policy_amounts = getInsuranceCarriersOptions(_insurance_carriers)
            
            let _doctors = []
            if(userType !== USER_TYPE.HOSPITAL){ // Request all doctors
                _doctors = await catalogManager.onGetDoctors()
                _doctors = getDoctorsOptions(_doctors)
            }else{ // Is a doctor profile
                _doctors = await catalogManager.onGetDoctorsByHospital()
                _doctors = getDoctorsHospitalsOptions(_doctors)
                formManager.onInitForm({
                    main_hospital_id: user?.hospital_id,
                    hospital_id:user?.hospital_id,
                    secondary_hospital_id: user?.hospital_id,
                })
                /*onUpdatePatientData({
                    main_hospital_id: user?.hospital_id,
                    hospital_id:user?.hospital_id,
                    secondary_hospital_id: user?.hospital_id,
                })*/
            }

            setDoctors(_doctors)
            setPublicInsurances(_public_insurances)
            setHospitals(_hospitals)
            setPolicyAmounts(_policy_amounts)
            setInsuranceCarriers(_insurance_carriers)

            // Define product id based on user type id
            const _tempproductid = onGetProductSearchParams(user)
            const _producttype = catalogs.spanish.product_types.find(el => el.value === _tempproductid.product_type_id)
            //console.log("PRODUCT TYPE DATA", _producttype)
            setProductType(_producttype)

        } catch (error) {
            console.log(error)
        }

        setLoading(false)
        setDone(true)
    }, [user, onUpdatePatientData])

    useEffect(() => {  
        if(user) initialize()
    }, [user])

    useEffect(() => {
        if(done) onRequestProducts()
    }, [product_type, done, programId])

    return(
        <OnboardingContext.Provider 
            value={{
                loading,
                step, 
                error,
                error_process_message,
                creating,
                main_hospital,
                disclaimer_accepted,
                is_public_insurance,
                privacy_policy, 
                identity_document,
                proof_of_address,
                medical_report,
                insurance_policy,
                product_type,
                nse,
                policy_value,
                doctors, 
                hospitals,
                public_insurances,
                insurance_carriers,
                policy_amounts,
                payment_types,
                genders,
                products,
                productsCat,
                treatmentManager,
                formManager,
                setStep,
                setError,
                setDisclaimerAccepted,
                setIsPublicInsurance,
                setPrivacyPolicy,
                setIdentityDocument,
                setProofOfAddress,
                setMedicalReport,
                setProductType,
                setNSE,
                setMainHospital,
                setInsurancePolicy,
                setPolicyValue,
                setCreating,
                setErrorProcessMessage
                
            }}
        >
            {children}
        </OnboardingContext.Provider>
    )
}


