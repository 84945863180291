import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import AddButton from '../../../../../../components/Buttons/AddButon';
import { grey } from '@material-ui/core/colors';
import VialModal from '../../modals/VialModal';
import { onGetVialInformation } from '../../../../../../shared/utility';

const header = [
    {id:1, label:'#Vial', padding:undefined},
    {id:2, label:'Producto', padding:undefined},
    {id:3, label:'', padding:'checkbox'},
]

const MultipleVias = (props) => {

    const {vials, products, onUpdateVial, onMoveVial} = props
    const classes = useStyles()

    const [selected, setSelected] = useState()
    const [modals, setModals] = useState({
        add_vial:false,
        edit_vial:false
    })

    const onInnerUpdateVial = (form,mode) => {
        if(mode === 'delete'){
            onUpdateVial(form,mode)
            return
        }
        onUpdateModal(mode === 'add' ? 'add_vial' : 'edit_vial', false)
        onUpdateVial(form,mode)
    }

    const onUpdateModal = (data, value) => {
        let _modals = {...modals}
        _modals[data] = value
        setModals(_modals)
    }

    const onEdit = (data) => {
        setSelected(data)
        onUpdateModal('edit_vial', true)
    }

    let viasContent = (
        <div>
            <Grid container alignItems='center' direction='column'>
                <Grid item>
                    <Typography variant='body2' color='textSecondary'>No tiene productos agregados aún</Typography>
                </Grid> 
            </Grid>
        </div>
    )

    if(vials && vials.length){
        viasContent = (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {header.map(item => {
                                return(
                                    <TableCell padding={item.padding} key={`th-cell-${item.id}`}>
                                        {item.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>  
                    <TableBody>
                        {vials.map(item => {

                            const vialData = onGetVialInformation(item)

                            return (
                                <TableRow key={`th-cell-${Math.random()*10000}`}>
                                    <TableCell>
                                        <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                                            <Grid item>
                                                <IconButton size='small' onClick={() => onMoveVial(item,'down')}><Icon fontSize='small'>arrow_drop_down</Icon></IconButton>
                                            </Grid>
                                            <Grid item>
                                                {vialData?.number}
                                            </Grid>
                                            <Grid item>
                                                <IconButton size='small' onClick={() => onMoveVial(item,'up')}><Icon fontSize='small'>arrow_drop_up</Icon></IconButton>
                                            </Grid>
                                            
                                        </Grid>
                                        
                                        </TableCell>
                                    <TableCell>{vialData?.product}</TableCell>
                                    <TableCell padding='checkbox'>
                                        <Grid container spacing={1} wrap='nowrap'>
                                            <Grid item>
                                                <IconButton size='small' onClick={() => onEdit(item)}><Icon>edit</Icon></IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size='small' onClick={() => onInnerUpdateVial(item, 'delete' )}><Icon>delete</Icon></IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }


    return ( 
        <div>
            {/* ADD NEW VIAL PRODUCT */}
            <VialModal 
                mode='add' open={modals.add_vial} 
                onClose={() => onUpdateModal('add_vial', false)} 
                products={products}
                onUpdateVial={onInnerUpdateVial} 
                />
            <VialModal 
                mode='edit' open={modals.edit_vial} origin={selected}
                onClose={() => onUpdateModal('edit_vial', false)} 
                products={products}
                onUpdateVial={onInnerUpdateVial} 
            />
            <div>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item >
                        <Typography variant='body2'>Productos(s)</Typography>
                    </Grid>
                    <Grid item>
                        <AddButton onClick={() => onUpdateModal('add_vial', true)} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.vias_content}>
                {viasContent}
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    vias_content:{
        marginTop:16,
        padding:'32px 16px',
        borderRadius:12,
        border:`1px solid ${grey[200]}`
    }
}))
 
export default MultipleVias;