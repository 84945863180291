import React, {useCallback} from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import privacyimage from '../../../../../assets/privacy-policy.png'
import ContentText from '../../../../../components/Texts/ContentText';
import SimpleFile from '../../../../../components/Files/SimpleFile/SimpleFile';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';
import useOnboardingContext from '../../../../../providers/OnboardingProvider/useOnboardingContext';
import { getUserType } from '../../../../../shared/utility';
import { REGISTRATION_STEPS, USER_TYPE } from '../../../../../variables/config';
import OnboardingActionBar from '../components/OnboardingActionBar';


const FormStep0 = () => {

    const {
      privacy_policy,
      disclaimer_accepted,
      setDisclaimerAccepted,
      setPrivacyPolicy,
      setStep,
      setError,
    } = useOnboardingContext()

    const classes = useStyles()

    const onSubmit = useCallback(() => {
      const userType = getUserType()

      const valid = disclaimer_accepted && privacy_policy
      if(!valid && userType === USER_TYPE.HOSPITAL){
        setError("Para continuar necesita cargar el archivo de privacidad y aceptar los términos y condiciones")
        return
      }
      setError(null)
      setStep(REGISTRATION_STEPS.APPLICANT)
      
    }, [privacy_policy, disclaimer_accepted])

    const onUpdateDocument = useCallback((_, files) => {
      setPrivacyPolicy(files[0])
    },[])

    const onRemoveDocument = useCallback(() => {
      setPrivacyPolicy(null)
    }, [])

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={privacyimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ContentText 
                          text='Aviso de privacidad' 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleFile 
                          id='privacy_policy'
                          file={privacy_policy}
                          onDocumentAttached={onUpdateDocument}
                          onDocumentRemove={onRemoveDocument}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ItemSelection 
                          id='disclaimer_accepted'
                          position='start'
                          status={disclaimer_accepted}
                          text='Acepto que Roche tenga acceso a la información personal del paciente'
                          onChange={() => setDisclaimerAccepted(!disclaimer_accepted)}/>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{height:100}}/>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <OnboardingActionBar 
          back_btn_disabled
          onSubmit={onSubmit}
        />
        
      </div>
    )
}

const useStyles = makeStyles(theme => ({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:160,
    height:160,
  }
}))

export default FormStep0