import {mainServer} from '../../../../variables/config'

export const request_approval_contact = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/approvalcontact/"+id);
  return response.data.data.approval_contact
}

export const request_edit_approval_contact = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/approvalcontact/"+id, data)
  let response
  response = await mainServer.instance.get("/approvalcontact/"+id);
  return response.data.data.approval_contact
}

/* export const request_details = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/approval_contact/me/details");
  return response.data.data.approval_contact_details
}

export const request_details_translation = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/approval_contact/me/translations/1");
  return response.data.data.approval_contact_details_translation
} */
