export const cleanData2Table = (data, nodata) => {
  

  const table = data.map(item => {

    let patient_products = item.patient_products.filter(el => el.product_status_id === 1)
    //console.log('data',item?.patient_products)
    let temp = {
      id:item.id_patient, 
      status:item.status_id, 
      approval_status:item.approval_status_id, 
      letter_status:item.letter_status_id, 
      rewards:patient_products,
      ...item,
      data:[]
    }

    let user_type = 'Paciente'
    if(item.patient_type_id === 2) user_type = 'Solicitante'

    let array = [
      item.external_id ? item.external_id : nodata,  
      item.folio ? item.folio : nodata,
      item.name ? item.name : nodata,
      item.main_hospital ? item.main_hospital : nodata, 
      item.insurance_carrier ? item.insurance_carrier : nodata, 
      item.nse ? item.nse : nodata, 
      item.status ? item.status : nodata, 
      item.approval_status ? item.approval_status : nodata, 
      item.letter_status ? item.letter_status : nodata, 

      'Beneficio',
      user_type
    ]
    temp.data = [...array]
    return temp
  })

  return table
}


export const cleanData2TableGeneral = (data, nodata) => {
  
  const table = data.map(item => {
    let temp = {
      id:item.id_patient, 
      status:item.status_id, 
      approval_status:item.approval_status_id, 
      letter_status:item.letter_status_id, 
      rewards:item.patient_products ? [...item.patient_products] : [],
      //por_ti_id:item?.por_ti_id ?? '-',
      ...item,
      data:[]
    }

    let array = [
      item.external_id ? item.external_id : nodata,  
      item.folio ? item.folio : nodata,
      item.name ? item.name : nodata,
      item.hospital ? item.hospital : nodata, 
      item.insurance_carrier ? item.insurance_carrier : nodata, 
      item.nse ? item.nse : nodata, 
      item.status ? item.status : nodata, 
      item.approval_status ? item.approval_status : nodata, 
      item.letter_status ? item.letter_status : nodata, 
      'Beneficio'
    ]
    temp.data = [...array]
    return temp
  })

  return table
}

export const getHospitalsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_hospital,
      value: item.id_hospital,
      label: item.name
    }
  })
  temp.push({id:null, label:'Sin Filtro', url:null},)
  return temp
}

export const getDoctorsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    let fullname = item.first_name
    fullname += item.second_name ? ' ' + item.second_name : ''  
    fullname += item.first_surname ? ' ' + item.first_surname : ''
    fullname += item.second_surname ? ' ' + item.second_surname : ''
    return {
      id: item.id_doctor,
      value: item.id_doctor,
      label: fullname
    }
  })
  temp.push({id:null, label:'Sin Filtro', url:null},)
  return temp
}