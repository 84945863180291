export const contentData = {
  spanish:{
    layout:{
      title:'Información del solicitante',
      navigation:[
        {name:'Solicitantes',path:'/applicants'},
        {name:'Detalles',path:'/applicants/'}
      ],
      menu:[
        {id:1, label:'General', disabled:false},
        {id:2, label:'Tratamientos', disabled:false},
        {id:3, label:'Documentos personales', disabled:false},
        {id:4, label:'Reportes médicos', disabled:false},
        {id:5, label:'Documentos', disabled:false},
        {id:6, label:'Cartas', disabled:false},
        {id:7, label:'Distribuidores', disabled:false},
        {id:8, label:'Notas', disabled:false},
      ]
    },
    layout_nonotes:{
      title:'Información del solicitante',
      navigation:[
        {name:'Solicitantes',path:'/applicants'},
        {name:'Detalles',path:'/applicants/'}
      ],
      menu:[
        {id:1, label:'General', disabled:false},
        {id:2, label:'Tratamientos', disabled:false},
        {id:6, label:'Reportes médicos', disabled:false},
        {id:7, label:'Documentos personales', disabled:false},
        {id:8, label:'Cartas', disabled:false},
        {id:3, label:'Distribuidores', disabled:false},
      ]
    },
    warning_delete_provider:{
      title:'¿Estás seguro?',
      message:'¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido',
      cancelButton:'Cancelar',
      deleteButton:'Eliminar'
    },
    error_benefit_modal:{
      title:'¡Hubo un error!',
      message:'Verifique que los datos necesarios para calcular la oferta estén completos',
      cancelButton:'Cancelar',
      deleteButton:'Eliminar'
    },
  }
}
