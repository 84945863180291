import { useEffect, useState } from "react"
import { getFileExtension, getUserType, isFormValid, onGetDateFormat, onGetFormData, onGetSelectedFormData, onInitForm, onResetFormElement, onSetErrorsToForm, onUpdateAuthorizationHeader } from "../../../../../../../../shared/utility"
import { private_server, USER_TYPE } from "../../../../../../../../variables/config"
import axios from 'axios'
import FileSaver from "file-saver"

const useUseDateModalV2 = (props) => {

    const {origin, open, onClose, hospitals, products, onRefreshPatientProducts, patient} = props
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [use, setUse] = useState()
    const [cycle, setCycle] = useState()
    const [patientproduct, setPatientProduct] = useState()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [remoteVials, setRemoteVials] = useState([])
    const [vials, setVials] = useState([])
    const [vial, setVial] = useState()

    const [documentPath, setDocumentPath] = useState()

    const [modals,setModals] = useState({
        upload_doc:false,
        delete_doc:false,
        add_vial:false,
        edit_vial:false,
    })


    useEffect(() => {
        if(open){
            actions.onInitModule()
        }else{
          setUse(null)
          setRemoteVials([])
          setVials([])
          setVial(null)
        }
    }, [open, origin, hospitals, products])


    const actions = {
        onInitModule: async() => {
            setLoading(true)
            const userType = getUserType()
            let _form = onInitForm(form, origin)
            if(origin.application_date){
              const _ad = onGetDateFormat(origin.application_date, 'YYYY-MM-DD')
              if(_ad !== '-'){
                _form.application_date.value = _ad
                _form.application_date.isValid = true
              }
            }

            //console.log(origin)
            if(origin & !Boolean(origin?.free)) _form.date.config.label = 'Fecha de compra'
            if(userType === USER_TYPE.HOSPITAL) _form.hospital_id.disabled =  true

            console.log('PATIENT',patient)
            // Check patient product
            onUpdateAuthorizationHeader(private_server)
            const _cycleResponse = await private_server.get(`/cycle/${origin?.cycle_id}`)
            const _cycle = _cycleResponse.data.data.cycle
            const _ppResponse = await private_server.get(`/patientproduct/${_cycle?.patient_product_id}`)
            const _patientproduct = _ppResponse.data.data.patient_product
            setCycle(_cycle)
            setPatientProduct(_patientproduct)
            const _params = {
              product_type_id: _patientproduct?.product_type_id
            }
            const _productRq = await private_server.get(`/patientproduct/products`, {params:_params})
            const _products = getProductsOptions( _productRq.data.data.products)
            console.log(getProductsOptions( _productRq.data.data.products))
            //console.log()

            _form.hospital_id.options = hospitals?? []
            _form.product_id.options = _products?? []
            console.log('Use',origin)

            origin.multiple_piece = _patientproduct?.multiple_piece
            

            try {
                
                const response = await private_server.get(`/usevial/use/${origin?.id_use}`)
                const _remoteVials = response.data.data.use_vials
                _remoteVials.sort((a,b) => {
                  if(a.number > b.number) return 1
                  if(a.number < b.number) return -1
                  return 0
              })
                let _vials = []
                //console.log(response.data.data.use_vials)
                setRemoteVials(_remoteVials)

                for(let i=0; i<_remoteVials.length; i++){
                  let _vialform = JSON.parse(JSON.stringify(vial_formdata))
                  _vialform = onInitForm(_vialform, _remoteVials[i])
                  if(!_remoteVials[i].number){
                    _vialform.number = {..._vialform.number, value:i+1, isValid:true }
                  }
                  //const _products = getProductsOptions(products)
                   _vialform.product_id.options = _products   
                   _vials.push(_vialform)
                }
                console.log('PRODUCTOS',products)
                console.log(_vials)
                setVials(_vials)

                

            } catch (error) {
                console.log('VIAL ERROR: CANT GET VIALs', origin, error)
            }

            setForm(_form)
            setUse(origin)
            setLoading(false)
        },
        onClear: (key) => {
            let _form = {...form}
            _form[key] = onResetFormElement(_form[key])
            setForm(_form)
        },
        
        onInputChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onEditVial: (_data) => {
            setVial(_data)
            actions.onChangeModalStatus('edit_vial', true)
        },
        onUpdateVial: async(_data, mode) => {
            let _vials
            switch (mode) {
                case 'add':
                    // ADD VIAL PRODUCT PROCCESS
                    const last_position = vials.length + 1
                    _data.number.value = last_position
                    _data.number.isValid = true
                    _vials = [...vials, _data]
                    actions.onChangeModalStatus('add_vial', false)
                    break;
                case 'edit':
                    // EDIT VIAL PRODUCT PROCCESS
                    _vials = [...vials]
                    _vials[_data.number.value-1] = {..._data}
                    actions.onChangeModalStatus('edit_vial', false)
                    break;
                case 'delete':
                    const pos = _data.number.value
                    _vials = vials.filter(el => el.number.value !== pos)
                    _vials = actions.onReformatVialsNumber(_vials)
                    break;
            
                default:
                    break;
            }
            setVials(_vials)
            
        },
        onMoveVial: async(_data, mode) => {
            console.log('Mover funcion')
            let  _vials
            _vials = [...vials]
            if(_vials.length <= 1) return

            const current_pos = _data.number.value - 1
            let new_pos = mode === 'up' ? current_pos - 1 : current_pos + 1
            if(new_pos < 0) new_pos = 0
            if(new_pos > _vials.length -1 ) new_pos = _vials.length - 1
            // swap technique
            let backup = _vials[new_pos]
            _vials[new_pos] = _data
            _vials[current_pos] = backup
            _vials = actions.onReformatVialsNumber(_vials)
            
            setVials(_vials)

        },
        onReformatVialsNumber: (data) => {
            let result = [...data]
            data.forEach((item, key) => {
                result[key].number.value = key + 1
            })
            return result
        },
        onSubmit: async() => {

          setSending(true)

          const errors = isFormValid(form)
          /// Check if the form is valid
          if(errors && errors.length){
              const new_form = onSetErrorsToForm(form, errors)
              setForm(new_form)
              return
          }

           //Extract data to send use
           let data2send

           if(use.multiple_piece === 1){
            try {
                onUpdateAuthorizationHeader(private_server)

                let vials2delete = []
                for(let i=0; i<remoteVials.length; i++){
                  const _vial = remoteVials[i]
                  const elFound = vials.find(el => el.id_use_vial.value === _vial.id_use_vial)
                  if(!elFound) vials2delete.push(_vial)
                }

                for(let i=0; i<vials2delete.length; i++){
                  await private_server.delete(`/usevial/${vials2delete[i].id_use_vial}`)
                }

                for(let i=0; i<vials.length; i++){
                  data2send = onGetFormData(vials[i])
                  if(!Boolean(data2send.id_use_vial)){
                    // Must create a new one
                    console.log('AGREGAR', data2send)
                    data2send.use_id = origin?.id_use
                    await private_server.post(`/usevial`, data2send)
                  }else{
                    console.log('UPDATEAR', data2send)
                    await private_server.patch(`/usevial/${data2send.id_use_vial}`, data2send)
                    // Must update current vial
                  }
                }
                //Extract data to send use
                data2send = onGetSelectedFormData(form, origin)
                // Update current product_id based on vials
                if(use?.multiple_piece && vials.length){
                  const product_id = vials[0].product_id.value
                  data2send.product_id = product_id
                }
            } catch (error) {
              console.log('USER VIAL UPDATE ERROR', error)
            }
          }else{
            data2send = onGetSelectedFormData(form, origin)
            console.log(data2send)
            if(remoteVials.length > 0){
              console.log('Obtener el inicial, ya hay use')
              console.log('Mis remosot', remoteVials)
              if(data2send.product_id){
                const _vial = remoteVials[0]
                console.log('Hace rpathc sobre', _vial.id_use_vial, {product_id:data2send.product_id})
                await private_server.patch(`/usevial/${_vial.id_use_vial}`, {
                  product_id:data2send.product_id
                })
              }
            }else{
              console.log('Se debe de crear uno nuevo')
            }
          }
           
          data2send = onGetSelectedFormData(form, origin)

          // Update current product_id based on vials
          if(use?.multiple_piece && vials.length){
            const product_id = vials[0].product_id.value
            data2send.product_id = product_id
          }
          
          await onRefreshPatientProducts()

          try {
            onUpdateAuthorizationHeader(private_server)
            await private_server.post(`/use/${origin?.id_use}/update`,data2send)
            await onRefreshPatientProducts()
          } catch (error) {
            console.log('USER UPDATE ERROR', error)
          }

          setSending(false)
          onClose()

        },
        ////////////////////////////  DOCUMENTS SECTION //////////////////////////////////////
        onPreUploadDocument: (path) => {
          setDocumentPath(path)
          actions.onChangeModalStatus('upload_document', true)
        },
        onUploadDocument: async(file) => {

          if(sending) return

          setSending(true)

          try {
            const data2send = {ext:getFileExtension(file.name)}
            await actions.onUploadGenericDocument(use?.id_use, documentPath, file, data2send)
            onUpdateAuthorizationHeader(private_server)
            const _use_request = await private_server.get(`/use/${use?.id_use}`)
            setUse(_use_request.data.data.use)

          } catch (error) {
            console.log('Error cargar documento', error)
          }

          setSending(false)
          actions.onChangeModalStatus('upload_document', false)

        },
        onUpdateModal: (key, data) => {
          let _modals = {...modals}
          _modals[key] = data
          setModals(_modals)
        },
        onUploadGenericDocument: async(id_use, path, file, data) =>{
          onUpdateAuthorizationHeader(private_server)
          const response = await private_server.post(`/use/${id_use}/${path}`, data)
          const options = {headers: {'Content-Type': file.type}}
          delete axios.defaults.headers.common["Authorization"]
          await axios.put(response.data.data.path, file, options);
        },
        onPreDeleteDocument: (path) => {
          setDocumentPath(path)
          actions.onChangeModalStatus('delete_document', true)
        },
        onDeleteDocument:async() => {
          if(sending) return
 
          setSending(true)

          try {
            onUpdateAuthorizationHeader(private_server)
            await private_server.delete(`/use/${use?.id_use}/${documentPath}`)
            const _use_request = await private_server.get(`/use/${use?.id_use}`)
            setUse(_use_request.data.data.use)

          } catch (error) {
            console.log('Error cargar documento', error)
            setError('No se pudo eliminar el documento, intente de nuevo')
          }

          setSending(false)
          actions.onChangeModalStatus('delete_document', false)
        },
        onDownload: async(path, filename) =>{
          const {id_use} = origin
          const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
          console.log(path, filename)
          onUpdateAuthorizationHeader(private_server)
          try {
            let response = await private_server.get(`/use/${id_use}/${path}`)
            delete axios.defaults.headers.common["Authorization"]
            response = await axios.get(response.data.data.path, {responseType: 'blob'});
            FileSaver.saveAs(response.data, `${filename}`);
          } catch (error) {
            setError(errorMessage)
          }
          
          //setError(errorMessage)
        },
        onResetError: () => setError(null)
    }

    const system = {loading, sending,error}
    const data = {use, vial, vials, documentPath}

    return {system, data, actions, form, modals}

}

export default useUseDateModalV2

const getProductsOptions = (data) => {
  let temp = []
  temp = data.map(item => {

    let product_label = `${item.name}, ${item.presentation} ${item.product_type ? `, ${item.product_type}` : '' }`

    return {
      id: item.id_product,
      value: item.id_product,
      label: product_label,
      product_type_id:item.product_type_id
    }
  })
  return temp
} 

export const form_data = {
    product_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'product_id',
        type:'select',
        fullWidth: true,
        md:12,
        label:'Producto+tratamiento',
        placeholder:'Seleccione una opción',
        helper:'Producto no válido'
      },
      rules:{
        type:'select',
      }
    },
    date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'date',
        type:'date',
        fullWidth: true,
        md:12,
        label:'Fecha de entrega',
        placeholder:'Fecha de entrega',
        helper:'Fecha no válida'
      },
      rules:{
        type:'date',
      }
    },
    application_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'application_date',
        type:'date',
        fullWidth: true,
        md:12,
        label:'Fecha de aplicación',
        placeholder:'Fecha de aplicación',
        helper:'Fecha no válida'
      },
      rules:{
        type:'date',
      }
    },
    hospital_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'hospital_id',
        type:'select',
        fullWidth: true,
        md:12,
        label:'Hospital de infusión',
        placeholder:'Seleccione un hospital',
        helper:'Hospital no válido'
      },
      rules:{
        type:'select',
      }
    },
    countable:{
      value: 1,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: true,
      config:{
          id:'countable',
          type:'checkbox',
          fullWidth: true,
          label:'¿Es contabilizable?',
          placeholder:'¿Es contabilizable?',
          helper:''
      },
      rules:{
          type:'none',
      }
    },
  }

  const vial_formdata = {
    id_use_vial:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'id_use_vial',
          type:'tel',
          fullWidth: true,
        },
        rules:{
          type:'none'
        }
    },
    number:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'number',
        type:'tel',
        fullWidth: true,
      },
      rules:{
        type:'numeric',
        min:1,
        max:100
      }
    },
    product_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'product_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'select',
      }
    },
  }