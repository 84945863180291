import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';
import TreatmentCard from './TreatmentCard/TreatmentCard';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import { request_benefits } from './requests';
import StatusMessage from './components/StatusMessage';

class FormStepD extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    
    //formData: JSON.parse(JSON.stringify(mainForm)),
    doctors:[],
    products:[],
    diagnostics:[],
    is_public_insurance:false,
    is_secondary_treatment:false,
    dataItems:{},
    patient_status:false,
    primary_benefit:null,
    secondary_benefit:null,

    // FLAGS
    isfirstproduct:false,
    issecondproduct:false,
    isbenefit:false
  }

  render(){

    const {classes,  user_level} = this.props

    const {dataItems, patient_status, missingItems, primary_benefit, secondary_benefit, isloading, 
      isfirstproduct, issecondproduct} = this.state

//console.log(isfirstproduct)

    const {payment_type,
      is_secondary_treatment, balance,
      diagnostics, secondary_diagnostics, products, } = this.props.generalState

    const {doctor, payment_type_name, public_insurance, product,  hospital, 
          secondary_hospital, main_hospital, main_hospital_value, insurance_carrier, policy_amount, insurance_level}  = this.props.generalState

    let r_payment_type
    let r_insurance_level
    let primary_product
    let secondary_product_item


    if(dataItems){

      // Products
      primary_product = this.prepareProduct(dataItems, products, diagnostics, hospital, 'primary')
      if(is_secondary_treatment){
        secondary_product_item = this.prepareProduct(dataItems, products, secondary_diagnostics, secondary_hospital, 'secondary')
      }
    }

    let secondaryProductBenefit = null
    if(issecondproduct){
      secondaryProductBenefit = (
        <Grid item xs={12}>
          <TreatmentCard 
            type='secondary'
            data={secondary_product_item ? secondary_product_item : {}}
            benefit={secondary_benefit ? secondary_benefit : {}}  
          />
        </Grid>
      )
    }

    let insuranceContent = null
    switch(payment_type){
      case 1:
      case 3:
        insuranceContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Forma de pago'
                text={payment_type_name}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Aseguradora'
                text={insurance_carrier}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Póliza'
                text={policy_amount}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Nivel'
                text={insurance_level}
                endSpacing
              />
            </Grid>
            {balance ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Saldo'
                      text='Sí'
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Monto'
                      text={this.numberWithCommas(parseFloat(dataItems.balance_value))}
                      endSpacing
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            
          </Grid>
        )
        break;
      case 2:
        insuranceContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Forma de pago'
                text={r_payment_type}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Nivel'
                text={r_insurance_level}
                endSpacing
              />
            </Grid>
          </Grid>
        )
        break;
      default:
        break;
    }

    let contentView = null

    if(isloading){
      contentView = (
        <Grid container justifyContent='center'>
          <Grid item><div>
            <SimpleLoader />  
          </div></Grid>
        </Grid>
      )
    }else{
      contentView = (
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <StatusMessage status={patient_status} missingItems={missingItems}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContentText text='Resumen' variant='bold' medium/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {user_level === 3 ? (
                    <Grid item xs={12} md={6}>
                      <HorizontalDataWrapper 
                        label='Nombre'
                        text={dataItems.name}
                        endSpacing
                      />
                    </Grid>
                  ) : null}
                  
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='ID'
                      text={dataItems.external_id}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Folio'
                      text={dataItems.folio}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Doctor'
                      text={doctor}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Código Postal'
                      text={dataItems.zip}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='NSE'
                      text={dataItems.nse_name}
                      endSpacing
                    />
                  </Grid>
                  {public_insurance ? (
                    <Grid item xs={12} md={6}>
                      <HorizontalDataWrapper 
                        label='Aseguradora Pública'
                        text={public_insurance}
                        endSpacing
                      />
                    </Grid>
                  ) : null}
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContentText text='Hospitales o Centros de Infusión' variant='bold' medium/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/************** MAIN HOSPITAL *********************/}
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Hospital/Clínica Principal'
                      text={main_hospital}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Nivel'
                      text={main_hospital_value}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}/>
                  {/************** END SECONDARY HOSPITAL *********************/}  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/************** INSURANCE HOSPITAL *********************/}   
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContentText text='Aseguradora' variant='bold' medium/>
              </Grid>
              <Grid item xs={12}>
                {insuranceContent}
              </Grid>
            </Grid>
          </Grid>

          {/************** TREATMENT SECTIONS *********************/}  
          {isfirstproduct ? (<Grid item xs={12} >
            <TreatmentCard 
              type='primary'
              data={primary_product ? primary_product : {product:product}}
              benefit={primary_benefit ? primary_benefit : {}}  
            />
          </Grid>) : null}
          {secondaryProductBenefit}    
        </Grid>
      )
    }

    return(
      <div className={classes.root}>
        {contentView}
      </div>
    )
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  prepareProduct = (data, products, diagnostics, hospital, type) => {
    let result = {
      product:null,
      presentation:null,
      diagnostic:null,
      hospital:hospital,
      adjacent:null,
      cycles:null,
      availability:null
    }

    let aux
    if(type === 'primary'){
      aux = products.find(el => el.id_product === data.product_id)
      result.product = aux ? aux.name : null
      result.presentation = aux ? aux.presentation : null
      aux = diagnostics.find(el => el.id_diagnosis === data.diagnosis_id)
      result.diagnostic = aux ? aux.name : null
      result.cycles = data.expected_uses
    }else{
      aux = products.find(el => el.id_product === data.secondary_product_id)
      result.product = aux ? aux.name : null
      result.presentation = aux ? aux.presentation : null
      aux = diagnostics.find(el => el.id_diagnosis === data.secondary_diagnosis_id)
      result.diagnostic = aux ? aux.name : null
      result.cycles = data.secondary_expected_uses
      result.adjuvant = data.secondary_adjuvant_id
    }

    return result
  }

  componentDidMount(){
    this.initModule()
    
    //localStorage.setItem('dummydata', JSON.stringify(this.props.generalState))
  }

  initModule  = async() => {

    let response
    this.setState({isloading:true})
    const {patient_status, dataItems, missingItems} = this.onValidatePatientData()

    // PRODUCT CREATION
    let first_product = null
    let second_product = null
    let primary_product_status = this.onCheckProductStatus('primary')
    let secondary_product_status = this.onCheckProductStatus('secondary')

    if(primary_product_status){
      first_product = {
        patient_id:null,
        product_id: dataItems.product_id,
        diagnosis_id: dataItems.diagnosis_id,
        expected_uses: dataItems.expected_uses,
        hospital_id:dataItems.hospital_id,
        secondary:0,
        adjuvant:0,
      }
    }
    if(secondary_product_status){
      second_product = {
        patient_id:null,
        product_id: dataItems.secondary_product_id,
        diagnosis_id: dataItems.secondary_diagnosis_id,
        expected_uses: dataItems.secondary_expected_uses,
        hospital_id:dataItems.secondary_hospital_id,
        secondary:1,
        adjuvant:dataItems.secondary_adjuvant_id === 1 ? 1 : 0,
      }
    }

    try {

      if(patient_status){
        // Calculate prebenefit
        let data2benefit = {
          hospital_id:dataItems.main_hospital_id,
          secondary_hospital_id:dataItems.main_hospital_id,
          policy_value_id:dataItems.policy_value_id,
          nse_id:dataItems.nse_id
        }

        response = await request_benefits(data2benefit)
        this.setState({...response, isbenefit:true})
        
      }
      this.props.onUpdateData({dataItems:dataItems, patient_status:patient_status, first_product:first_product, second_product:second_product})
      this.setState({
        patient_status:patient_status, 
        missingItems:missingItems,
        isfirstproduct:primary_product_status,
        issecondproduct:secondary_product_status})




    } catch (error) {
      console.log()
    }
    this.setState({isloading:false})
  }

  onCheckProductStatus = (type) => {
    const {product_id, diagnosis_id, expected_uses,
    secondary_product_id, secondary_diagnosis_id, secondary_expected_uses} = this.props.generalState.formData
    
    if(type === 'primary'){
      return product_id.value !== null && product_id.value !== undefined && product_id.value !== '' &&
      diagnosis_id.value !== null && diagnosis_id.value !== undefined && diagnosis_id.value !== '' &&
      expected_uses.value !== null && expected_uses.value !== undefined && expected_uses.value !== '' 
      //&&  hospital_id.value !== null && hospital_id.value !== undefined && hospital_id.value !== ''
    }else{
      return secondary_product_id.value !== null && secondary_product_id.value !== undefined && secondary_product_id.value !== '' &&
      secondary_diagnosis_id.value !== null && secondary_diagnosis_id.value !== undefined && secondary_diagnosis_id.value !== '' &&
      secondary_expected_uses.value !== null && secondary_expected_uses.value !== undefined && secondary_expected_uses.value !== '' 
      //&& secondary_hospital_id.value !== null && secondary_hospital_id.value !== undefined && secondary_hospital_id.value !== ''
    }
  }

  onValidatePatientData = () => {
    const {formData, nse, balance, payment_type, is_public_insurance, policy_value} = this.props.generalState
    let status = true
    let data2create = {}
    let missingItems = []
    
    Object.keys(formData).forEach(item =>{
      data2create = {
        ...data2create, 
        [item]: formData[item].value !== null && formData[item].value !== undefined ? formData[item].value : undefined
      }
      if(formData[item].isRequired){
        if(formData[item].value === null || formData[item].value === undefined || formData[item].value === ''){
          console.log(item)
          status = false
          missingItems.push(formData[item].config.label)
        }
      }
    })

    data2create = {
      ...data2create,
      nse_id:nse ? nse.nse_id : undefined,
      balance: balance ? 1 : 0,
      payment_type_id: payment_type,
      policy_value_id: policy_value ? policy_value.policy_value_id : undefined,
      public_insurance_status: is_public_insurance ? 1 : 0

    }

    this.setState({dataItems:{...data2create}})

    return {patient_status:status, dataItems:data2create, missingItems:[...missingItems]}
  }

  onFindItemInArray = (id, items) => {
    let filterItem = items.find(el => el.id === id)
    if(filterItem){
      return filterItem.label
    }else{
      return null
    }
  }

  onNextStep = () => {
    const {patient_form, payment_type, nse,balance, is_public_insurance,policy_value,} = this.props.generalState

    let data2send = {}
    Object.keys(patient_form).forEach(item => {
      if(patient_form[item] !== null && patient_form[item] !== undefined && patient_form[item] !== ""){
        data2send = {...data2send, [item]: patient_form[item]}
      }else{
        data2send = {...data2send, [item]: undefined}
      }
    })
    //id_zip_code
    data2send.balance = balance ? 1 : 0
    data2send.balance_value = balance ? data2send.balance_value : undefined
    
    
    data2send.payment_type_id = payment_type
    data2send.policy_value_id = policy_value.policy_value_id
    data2send.public_insurance_status = is_public_insurance ? 1 : 0
    if(nse){
      data2send.nse_id = nse.nse_id
      data2send.zip_code_id = nse.id_zip_code
    }else{
      data2send.nse_id = patient_form.nse_id
    }

    this.props.onCreatePatient(data2send)

  }

  onPreviousStep = () => {
    this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),2)
    //this.props.onChangeStep(2)
  }

  onChangeCheckbox = (id, status) => {
    this.setState({[id]:status})
  }


  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

export default withStyles(styles)(FormStepD)