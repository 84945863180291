export const contentData = {
  spanish:{
    layout:{
      title:'Agregar Distribuidores',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Distribuidores',path:'/catalogs/providers'},
        {name:'Agregar',path:'/catalogs/providers/add'}
      ],
      menu:null
    },
    header:['ID','Nombre','Email','Teléfono','Género']
  }
}