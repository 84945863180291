import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../../../variables/texts'
import HorizontalDataWrapper from '../../../../../../../components/Texts/HorizontalDataWrapper'
import { getComponentLogInfo, onGetIdFormat } from '../../../../../../../shared/utility'
import InfoButton from '../../../../../../../components/Buttons/InfoButton'
import ContentText from '../../../../../../../components/Texts/ContentText'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_basicInfo as constrainst} from  '../constraints'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons'

const BasicInfoContainer = props => {

    const {data, onEdit} = props
    const buttonInformation = getComponentLogInfo(data)
    const singleID = onGetIdFormat(data ? data.id_patient : null, 8, 'SS')

    let insuranceContent = null

    if(data && data.public_insurance_status){
        insuranceContent = (
            <ContraintLayout constrainst={constrainst.public_insurance}>
                <HorizontalDataWrapper 
                    label='Aseguradora Pública' text={data.public_insurance}/>
            </ContraintLayout>
        )
    }
    
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                        <Grid item>
                            <ContentText text='Información general' medium variant='bold'/>
                        </Grid>
                        <Grid item>
                            <InfoButton data={buttonInformation}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {/*********************  ID ADMINISTRATIVO  ****************************** */}
                        <ContraintLayout constrainst={constrainst.id_patient}>
                            <HorizontalDataWrapper 
                                label='ID Programa' text={singleID}/>
                        </ContraintLayout>
                        {/*********************  ID GENERAL  ****************************** */}
                        <ContraintLayout constrainst={constrainst.por_ti_id}>
                            <HorizontalDataWrapper 
                                label='ID Por Ti' text={data ? data.por_ti_id : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.external_id}>
                            <HorizontalDataWrapper 
                                label='ID Programa' text={data ? data.external_id : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.folio}>
                            <HorizontalDataWrapper 
                                label='Folio' text={data ? data.folio : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Nombre' text={data ? data.name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.gender}>
                            <HorizontalDataWrapper 
                                label='Sexo' text={data ? data.gender : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.email}>
                            <HorizontalDataWrapper 
                                label='E-mail' text={data ? data.email : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.phone}>
                            <HorizontalDataWrapper 
                                label='Teléfono' text={data ? data.phone : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.doctor}>
                            <HorizontalDataWrapper 
                                label='Doctor' text={data ? data.doctor : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.city}>
                            <HorizontalDataWrapper 
                                label='Ciudad' text={data ? (data.city ? data.city : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.state}>
                            <HorizontalDataWrapper 
                                label='Estado' text={data ? (data.state ? data.state : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.zip}>
                            <HorizontalDataWrapper 
                                label='Código Postal' text={data ? (data.zip ? data.zip : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.nse}>
                            <HorizontalDataWrapper 
                                label='NSE' text={data ? data.nse : nodata}/>
                        </ContraintLayout>
                        
                        {insuranceContent}
                        <ContraintLayout constrainst={constrainst.product_type}>
                            <HorizontalDataWrapper 
                                label='Tipo de tratamiento' text={data ? data.product_type : nodata}/>
                        </ContraintLayout>

                        <ContraintLayout constrainst={constrainst.edit_button} md={12}>
                            <Grid  container>
                                <Grid item xs={0} md={6}/>
                                <Grid item xs={12} md={6}>
                                    <Grid container wrap='nowrap'>
                                        <Grid item xs={0} md={12}/>
                                        <Grid item>
                                            <RoundedButton size='small' label='Editar' 
                                            onClick={onEdit}/>
                                        </Grid>
                                        <Grid item xs={1}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </ContraintLayout>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicInfoContainer