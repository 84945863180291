import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';

import userimage from '../../../../../assets/patient.png'
import { contentData } from './content';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import { mainForm } from './data';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';
import { ErrorMessage } from '../../../../../components/DisplayData/DisplayData';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import NSEContainer from '../NSEContainer/NSEContainer';

class FormStepA extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    formData: JSON.parse(JSON.stringify(mainForm)),
    isValid:false,
    doctors:[],
    products:[],
    diagnostics:[],
  }

  render(){

    const {classes, language, catalogs,secondary_diagnostics, diagnostics, public_insurances, nse, is_public_insurance,
      is_secondary_treatment, issending, error, input_error, user_level} = this.props
    const {formData} = this.state

    const content = contentData[language]

    let formDataTemp = {...formData};
      
    if(JSON.stringify(input_error) !== "{}"){
      Object.keys(input_error).forEach(item => {
        formDataTemp[item].helper = input_error[item]
        formDataTemp[item].isValid = input_error[item] ? false : true
        formData[item].helper = input_error[item] 
        formData[item].isValid = input_error[item] ? false : true
      })
    }
    
    formData.diagnosis_id.options = [...diagnostics]
    formData.secondary_diagnosis_id.options = [...secondary_diagnostics]
    formData.public_insurance_id.options = [...public_insurances]
    formData.nse_id.options = [...catalogs.nses]

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    let publicInsuranceContent = null
    if(is_public_insurance){
      publicInsuranceContent = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText 
                text={content.subtitles.publicInsurance} 
                variant='bold'
                medium
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <LineInputSelect 
                    data={formData.public_insurance_id}
                    onChange={this.onInputChange}
                  />
                </Grid> 
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    let secondaryTreatmentContent = null
    if(is_secondary_treatment){
      secondaryTreatmentContent = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText 
                text={content.subtitles.secondaryTreatment} 
                variant='bold'
                medium
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <LineInputSelect 
                    data={formData.secondary_product_id}
                    onChange={this.onInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LineInputSelect 
                    data={formData.secondary_diagnosis_id}
                    onChange={this.onInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LineInputText 
                    data={formData.secondary_expected_uses}
                    onChange={this.onInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          {user_level === 3 ? (
                            <Grid item xs={12} md={6}>
                              <LineInputText 
                                data={formData.name}
                                onChange={this.onInputChange}
                              />
                            </Grid>
                          ) : null}
                          
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.external_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.folio}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formData.doctor_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.zip}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          {nse ? (
                            <Grid item xs={12} md={6}>
                              <NSEContainer nse={nse}/>
                            </Grid>
                          ) : null}
                          {nse === null ? (
                            <Grid item xs={12} md={6}>
                              <LineInputSelect 
                                data={formData.nse_id}
                                onChange={this.onInputChange}
                              />
                            </Grid>
                          ) : null}
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item>
                            <ItemSelection 
                              id='is_public_insurance'
                              status={is_public_insurance}
                              text='¿El Beneficiario cuenta con aseguradora pública?'
                              onChange={this.onChangeCheckbox}
                            />
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------PUBLIC INSURANCE ------------------------- */}
                  {publicInsuranceContent}
                  {/* ------------------ FIRST TREATMENT ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.mainTreatment} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <LineInputSelect 
                              data={formData.product_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LineInputSelect 
                              data={formData.diagnosis_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.expected_uses}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item>
                            <ItemSelection 
                              id='is_secondary_treatment'
                              status={is_secondary_treatment}
                              text='Agregar tratamiento adyuvante'
                              onChange={this.onChangeCheckbox}
                            />
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------ SECOND TREATMENT ------------------------- */}
                  {secondaryTreatmentContent}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
              <Grid item>
                {messageContent}
              </Grid>
              <Grid item>
                <RoundedButton 
                  label='Siguiente'
                  onClick={this.onNextStep}
                  color='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        
      </div>
    )
  }

  componentDidMount(){
    const {formData} = this.state
    const {language, doctors, products, patient_form} = this.props
    const content = contentData[language]
    let temp = {...formData}
    temp.doctor_id.options = doctors
    temp.product_id.options = products
    temp.secondary_product_id.options = products
    ////////************ INIT FORM /////////////*/
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
      temp[item].value = patient_form[item] ? patient_form[item] : '' 
      temp[item].isValid = patient_form[item] ? true : false
    });
    const isValidForm = this.validationForm(temp)
    this.setState({formData:{...temp}, isValid:isValidForm})
    //this.initModule()
  }

  getDoctorsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      let fullname = item.first_name
      fullname += item.second_name ? ' ' + item.second_name : ''  
      fullname += item.first_surname ? ' ' + item.first_surname : ''
      fullname += item.second_surname ? ' ' + item.second_surname : ''
      return {
        id: item.id_doctor,
        value: item.id_doctor,
        label: fullname
      }
    })
    return temp
  }

  getProductsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_diagnosis,
        value: item.id_diagnosis,
        label: item.name + ', ' + item.presentation
      }
    })
    return temp
  }

  onNextStep = () => {

    let isValid = this.finalValidation()
    if(isValid){
      this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),1)
    }else{
    }
  }

  finalValidation = () => {
    const {formData} = this.state
    const {nse, is_public_insurance, is_secondary_treatment} = this.props
    let isValid = formData.external_id.isValid && formData.doctor_id.isValid && formData.zip.isValid &&
    formData.product_id.isValid && formData.diagnosis_id.isValid && formData.expected_uses.isValid

    if(!nse){ //Zip code not found, nse_id is a must
      isValid = isValid && formData.nse_id.isValid
    }
    if(is_public_insurance){
      isValid = isValid && formData.public_insurance_id.isValid
    }
    if(is_secondary_treatment){
      isValid = isValid && formData.secondary_product_id.isValid && 
      formData.secondary_diagnosis_id.isValid && formData.secondary_expected_uses.isValid
    }
    return isValid

  }

  onChangeCheckbox = (id, status) => {
    if(id === 'is_secondary_treatment'){
      let temp = {...this.state.formData}
      temp.secondary_product_id.value = null
      temp.secondary_product_id.isValid = false
      temp.secondary_diagnosis_id.value = null
      temp.secondary_diagnosis_id.isValid = false
      temp.secondary_expected_uses.value = null
      temp.secondary_expected_uses.isValid = false
      this.setState({formData:{...temp}})
    }
    if(id === 'is_public_insurance'){
      let temp = {...this.state.formData}
      temp.public_insurance_id.value = null
      temp.public_insurance_id.isValid = false
      this.setState({formData:{...temp}})
    }
    //this.setState({[id]:status})
    this.props.onChangeCheckbox(id,status)
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

    if(id === 'product_id'){
      this.props.onRequestDiagnostic(data.value,'principal')
    }
    if(id === 'secondary_product_id'){
      this.props.onRequestDiagnostic(data.value,'secondary')
    }
    if(id === 'zip'){
      this.props.onRequestNSE(data.value)
    }
    if(id === 'external_id'){
      this.props.onRequestExternalID(data.value)
    }
    if(id === 'folio'){
      this.props.onRequestFolio(data.value)
    }

  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

export default withStyles(styles)(FormStepA)