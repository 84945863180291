import React from 'react'
import SimpleLoader from '../Loaders/SimpleLoader'
import { Grid } from '@material-ui/core'

const SimpleContainer = props => {
    const {isloading, children, loading} = props

    let contentView = null

    if(isloading || loading){
        contentView = (
            <Grid container justifyContent='center'><Grid item>
                <div style={{padding:40}}><SimpleLoader /></div>
            </Grid></Grid>
        )
    }else{
        contentView = children
    }

    return(
        <div>
            {contentView}
        </div>
    )

}

export default SimpleContainer