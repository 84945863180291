export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    title:'',
    sectionA:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Longitud entre 1-255 caracteres'
      },
      email:{
        label:'E-mail',
        placeholder:'mail',
        helper:'Correo no válido'
      },
    },
  },
}