export const contentData = {
  spanish:{
    layout:{
      title:'Agregar Correo',
      navigation:[
        {name:'Emails',path:'/mails'},
        {name:'Agregar',path:'/mails/add'},
      ],
      menu:null
    },
    header:['ID','Nombre','Email','Teléfono','Género']
  }
}