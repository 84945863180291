export const content = {
  title:'Estatus de beneficio',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Guardar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    benefit_status_id:{
      label:'Tipo de estatus',
      placeholder:'Seleccione un estatus',
    },
  },
}