import React from 'react'
import { Grid } from '@material-ui/core'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import HorizontalDataWrapper from '../../../../../../../components/Texts/HorizontalDataWrapper'
import { nodata } from '../../../../../../../variables/texts'
import {constrainst_patientproduct as constrainst} from  '../../constraints'
import { red, green } from '@material-ui/core/colors'
import ContentText from '../../../../../../../components/Texts/ContentText'
import SimpleMultitpleVias from '../../../components/MultitpleVias/SimpleMultitpleVias'

const PatientProductDetails = props => {

    const {product , vials, number, isApplicable} = props
    let data = {...product}
    let treatmentTitle = `Tratamiento ${number}`

    let isTreatmentValid = isApplicable ? 'Sí' : 'No'
    let isTreatmentValidIcon = isApplicable ? 'check' : 'error'
    let isTreatmentValidColor = isApplicable ? green[700] : red[700]


    let contentView = null

    if(data){
        console.log( `Tratamiento ${number}`, data)
        contentView = (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                        <Grid item>
                            <ContentText text={treatmentTitle} medium variant='bold'/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ContraintLayout md={12} constrainst={constrainst.hospital_value}>
                            <HorizontalDataWrapper 
                                label='¿Es múltiple?' text={data?.multiple_piece === 1 ? 'Sí' : 'No'}/>
                        </ContraintLayout>
                        {vials.length == 0 ? <ContraintLayout md={12} constrainst={constrainst.product_name}>
                            <HorizontalDataWrapper 
                                label='Producto principal' text={data ? data.product_name : nodata}/>
                        </ContraintLayout> : null}
                        {vials.length == 0 ? <ContraintLayout md={12} constrainst={constrainst.product_presentation}>
                            <HorizontalDataWrapper 
                                label='Presentación principal' text={data ? data.product_presentation : nodata}/>
                        </ContraintLayout> : null}
                        <Grid item xs={12}>
                            <SimpleMultitpleVias vials={vials} />
                        </Grid>
                        <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                            <HorizontalDataWrapper 
                                label='Diagnóstico' text={data ? data.diagnosis : nodata}/>
                        </ContraintLayout>
                        {data?.scheme && <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                            <HorizontalDataWrapper 
                                label='Esquema' text={data ? data.scheme : nodata}/>
                        </ContraintLayout>}
                        <ContraintLayout md={12} constrainst={constrainst.hospital}>
                            <HorizontalDataWrapper 
                                label='Hospital de infusión' text={data ? data.hospital : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.hospital_value}>
                            <HorizontalDataWrapper 
                                label='Nivel de hospital' text={data ? data.hospital_value : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.hospital_value}>
                            <HorizontalDataWrapper 
                                label='Dosis' text={data ? data.dose : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.hospital_value}>
                            <HorizontalDataWrapper 
                                label='Frecuencia' text={data ? data.treatment_frequency : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.hospital_value}>
                            <HorizontalDataWrapper 
                                label='Ajustado' text={data?.adjusted === 1 ? 'Sí' : 'No'}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.accredited}>
                            <HorizontalDataWrapper 
                                label='Acreditado' text={data ? data.accredited : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.expected_uses}>
                            <HorizontalDataWrapper 
                                label='No. de ciclos' text={data ? data.expected_uses : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout md={12} constrainst={constrainst.expected_uses}>
                            <HorizontalDataWrapper 
                                label='No. de viales' text={data ? data.vial_number : nodata}/>
                        </ContraintLayout>
                        
                        <ContraintLayout md={12} constrainst={constrainst.applicable}>
                            <HorizontalDataWrapper 
                                label={'Viabilidad'} 
                                icon={isTreatmentValidIcon} 
                                iconColor={isTreatmentValidColor} 
                                text={isTreatmentValid}/>
                        </ContraintLayout>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return(
        <div>
            {contentView}
        </div>
    )
}

export default PatientProductDetails