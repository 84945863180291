import React from 'react'
import HorizontalDataWrapper from '../../../../../../../../../components/Texts/HorizontalDataWrapper'
import { nodata } from '../../../../../../../../../variables/texts'
import { Grid } from '@material-ui/core'

const NSEContainer = props => {

    const {nse} = props

    return(
        <Grid container justifyContent='flex-end'> 
            <Grid item xs={12}>
                <HorizontalDataWrapper label='NSE' text={nse ? nse.nse_name : nodata} disableEndSpacing/>
            </Grid>
            <Grid item xs={12}>
                <HorizontalDataWrapper label='Ciudad' text={nse ? nse.city : nodata} disableEndSpacing/>
            </Grid>
            <Grid item xs={12}>
                <HorizontalDataWrapper label='Estado' text={nse ? nse.state : nodata} disableEndSpacing/>
            </Grid>
        </Grid>
    )
}

export default NSEContainer