import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const Chart = ({ data, key_name, key_value }) => {
	const classes = useStyles();

	const total = data.reduce((acc, item) => acc + item[key_value], 0);

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Grid container className={classes.chart} alignItems='center'>
					{data
						.sort((a, b) => (a[key_value] > b[key_value] ? -1 : b[key_value] > a[key_value] ? 1 : 0))
						.map((item, i) => (
							<Grid item xs={12} key={`bar-${item[key_name]}`}>
								<div className={classes.bar} style={{ width: item[key_value] ? `${(item[key_value] * 100) / total}%` : `0px` }}>
									<Typography className={classes.bart_text}>{item[key_name]}</Typography>
									<Typography className={classes.bart_text} style={{ margin: '0px 20px' }}>
										|
									</Typography>
									<Typography className={classes.bart_text} style={{ fontWeight: 600, marginLeft: 0 }}>
										{item[key_value] ? `${((item[key_value] * 100) / total).toFixed(2)}%` : `0%`}
									</Typography>
								</div>
							</Grid>
						))}
				</Grid>
			</div>
			{[...Array(11).keys()].map((item) => (
				<div className={classes.vertical_grid} style={{ left: `${item * 9.4}%` }} />
			))}

			{[...Array(10).keys()].map((item) => (
				<>
					<Typography className={classes.x_axis_text}>{item * 10}</Typography>
					{/* <div className={classes.vertical_grid} style={{ left: `${item * 10}%` }} /> */}
				</>
			))}
			<Typography className={classes.x_axis_text} style={{ width: 'fit-content' }}>
				100
			</Typography>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	chart: {
		marginLeft: 5,
		width: '95%'
	},
	container: {
		height: 300,
		overflowY: 'scroll',
		// overflowX: 'hidden',
		position: 'relative',
	},
	bar: {
		height: 35,
		borderRadius: '0px 20px 20px 0px',
		margin: '12px 0px',
		background: '#DCE2FE',
		display: 'flex',
		alignItems: 'center',
		zIndex: 10,
		position: 'relative',
	},
	bart_text: {
		fontWeight: 400,
		fontSize: '1.125rem',
		whiteSpace: 'nowrap',
		marginLeft: 20,
		color: '#4466F2',
		zIndex: 10,
		display: 'inline',
	},
	vertical_grid: {
		position: 'absolute',
		top: 0,
		width: 2,
		height: 300,
		background: '#F0F0F0',
		zIndex: 2,
		marginLeft: 5,
	},
	x_axis_text: {
		width: '9.4%',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		marginTop: 15
	},
}));

export default Chart;
