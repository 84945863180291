import React, { useState, useEffect } from 'react';
import { getDoctorsOptions, getGenericProvidersOptions, getProductsOptions, getProvidersOptions, getPublicInsurancesOptions } from '../../../../../shared/customFunctions';
import { getUserType, null2undefined, onGetHospitalCatalogs, onGetProductUserParams, onUpdateAuthorizationHeader, s2ab } from '../../../../../shared/utility';
import { catalogs } from '../../../../../variables/catalogs';
import { private_server, USER_TYPE } from '../../../../../variables/config';
import { getDoctorsHospitalsOptions, getInsuranceCarriersOptions } from '../../../Applicants/AddApplicant/customFunctions';
import { request_doctors } from '../../../Applicants/AddApplicant/FormStepD/requests';
import { request_doctors_by_hospital, request_hospitals, request_insurance_carriers, request_me, request_products, request_providers, request_public_insurances } from '../../../Applicants/AddApplicant/requests';
import moment from 'moment'
import { cleanDeliveredBenefitsExcel, cleanExtendedPatientStatsExcel } from '../customFunctions';
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { usePlatformContext } from '../../../../../context/PlatformContext';

const useDeliveredBenefitsStats = ({user}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const platformContext = usePlatformContext()
    const {programId} = platformContext.state
    const [product_type_id, setProductTypeID] = useState(0);

    //const [user, setUser] = useState()

    const [stats, setStats] = useState([])
    const [filter, setFilter] = useState({
        start_date:moment().subtract(3, 'M').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        product_type_id:null,
        patient_status_id:null, //
        inactive_reason_id:null, //
        payment_type_id:null, //
        main_hospital_id:null,
        patient_product_hospital_id:null,
        doctor_id:null,
        insurance_carrier_id:null,
        public_insurance_id:null,
        provider_id:null,
        product_id:null,
        diagnosis_id:null,
        state:null,
        benefit_type_id: null, //
        benefit_id:null, //
        treatment_frequency_id:null, //
        gender_id:null,//
        state:null,
        nse_id:null
    })

    const filter_disabled = [
        'patient_status_id',
        'inactive_reason_id',
        'insurance_carrier_id',
        'public_insurance_id',
        'benefit_id',
        'treatment_frequency_id',
        'gender_id'
    ]

    const [masterCatalogs, setMasterCatalogs] = useState({
        product_types:[...catalogs.spanish.product_types],
        patient_status:[...catalogs.spanish.patient_statuses],
        inactive_reasons:[...catalogs.spanish.inactive_reasons],
        payment_types:[...catalogs.spanish.payment_types],
        benefit_types: [...catalogs.spanish.benefit_types],
        benefits:[...catalogs.spanish.benefits, ...catalogs.spanish.benefits_roactemra],
        treatment_frequencies:[...catalogs.spanish.treatment_frequencies],
        genders:[...catalogs.spanish.genders],
        states:[...catalogs.spanish.states],
        nses:[...catalogs.spanish.nses],
        hospitals:[],
        doctors:[],
        insurance_carries:[],
        public_insurances:[],
        providers:[],
        products:[],
        diagnoses:[],
    })

    const [pagination, setPagination] = useState({
        limit:10,
        offset:0
    })

    const [total, setTotal] = useState(0)

    const userType = getUserType()

    useEffect(() => {
        actions.onInitModule()
    }, [])

    useEffect(() => {
        if(user) actions.onRequestAnalytics()
    }, [filter, user, programId, pagination])

    const actions = 
    {
        onInitModule: async() => {
            try {
                setLoading(true)
                console.log('Inicializar')
                const _user = await request_me()

                const userType = getUserType()
                const productParams = onGetProductUserParams(user, programId )
                
                let _hospitals = await request_hospitals()
                _hospitals = onGetHospitalCatalogs(_hospitals)
                let _insurance_carries = await request_insurance_carriers()
                _insurance_carries = getInsuranceCarriersOptions(_insurance_carries)
                let _public_insurances = await request_public_insurances()
                _public_insurances = getPublicInsurancesOptions(_public_insurances)
                let _products = await request_products(productParams)
                _products = getProductsOptions(_products)
                let _providers = await request_providers()
                //console.log('Providers', _providers)
                _providers = getGenericProvidersOptions(_providers)

                let _doctors = []

                if(userType !== USER_TYPE.HOSPITAL){
                    _doctors = await request_doctors()
                    _doctors = getDoctorsOptions(_doctors)
                }else{
                    _doctors = await request_doctors_by_hospital()
                    // Update all necesary catalogs
                    _doctors = getDoctorsHospitalsOptions(_doctors)            
                }

                let _catalogs = {...masterCatalogs,
                    hospitals:[..._hospitals],
                    doctors:[..._doctors],
                    insurance_carries:[..._insurance_carries],
                    public_insurances:[..._public_insurances],
                    providers:[..._providers],
                    products:[..._products]
                }

                let _filter = {...filter}

                

                //setUser(_user)
                setMasterCatalogs(_catalogs)
                //console.log('hola')
                //console.log(_filter)
                setFilter(_filter)

            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onUpdateFilter: (val) => setFilter(val),
        onUpdatePagination : (_data) => setPagination(_data),
        onRequestAnalytics: async(_product_type_id) => {
            if(!user) return
            console.log('SOlciitar data')
            setSending(true)
            try {
                onUpdateAuthorizationHeader(private_server)

                let _filter = {}
                Object.keys(filter).forEach(key => {
                    if(filter[key]) _filter[key] = filter[key]?.value || filter[key]
                })

                let product_type = _product_type_id !== undefined && _product_type_id !== null ? _product_type_id : product_type_id;

                switch (user.user_type_id) {
                    case USER_TYPE.ADMIN:
                    case USER_TYPE.ROCHE:
                        if (programId > 0 ) _filter.product_type_id = programId;
                        break;
                    case USER_TYPE.MERCURIO:
                        _filter.product_type_id = user.agency_id === 1 ? 1 : 2;
                        product_type = user.agency_id === 1 ? 1 : 2;
                        break;
                    case USER_TYPE.HOSPITAL:
                        _filter.product_type_id = 1;
                        product_type = 1;
                        break;
                    default:
                }

                setProductTypeID(product_type);
                const productParams = onGetProductUserParams(user, programId )

                let _parmas = null2undefined(_filter)
                const required_params = {start_date:filter.start_date, end_date:filter.end_date,..._parmas, ...productParams}
                const params2send ={limit:pagination.limit, offset:pagination.offset*pagination.limit}
                _parmas = {..._parmas, ...params2send, ...productParams}
                console.log(_parmas)
                const response = await private_server.get(`/analyticv2/deliveredbenefits`, {params:_parmas})
                const responsetotal = await private_server.get(`/analyticv2/deliveredbenefits`, {params:required_params})
                console.log(response.data.data)
                setStats(response.data.data.delivered_benefits)
                setTotal(responsetotal.data.data.delivered_benefits.length)
            } catch (error) {
                
            }
            setSending(false)
        },
        exportData2ExcelSecond: () => {
            const _extended_patient_stats = cleanExtendedPatientStatsExcel(stats)
        
            let wb = XLSX.utils.book_new();
            wb.Props = {
              Title: "Oncopassport Analytics",
              Subject: "General Data",
              Author: "Trionix Technologies",
              CreatedDate: new Date()
            };
        
            wb.SheetNames.push("Pacientes extendidos");
            let ws_data_inactive_patients = _extended_patient_stats
            let ws_inactive_patients = XLSX.utils.aoa_to_sheet(ws_data_inactive_patients)

            

            wb.Sheets["Pacientes extendidos"] = ws_inactive_patients;
        
            let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'analytics_patients.xlsx');
        
        },
        exportData2ExcelThird: async() => {
            const _filter = actions.onGetFilterParams()
            const response = await private_server.get(`/analyticv2/deliveredbenefits`, {params:_filter})
            const _data = response.data.data.delivered_benefits
            const _delivered_benefits_stats = cleanDeliveredBenefitsExcel(_data)
        
            let wb = XLSX.utils.book_new();
            wb.Props = {
              Title: "Oncopassport Analytics",
              Subject: "General Data",
              Author: "Trionix Technologies",
              CreatedDate: new Date()
            };
            wb.SheetNames.push("Beneficios entregados");
            let ws_data_patients = _delivered_benefits_stats
            let ws_patients = XLSX.utils.aoa_to_sheet(ws_data_patients)

            // Change specific cells to date format, the value must be a utc value
            for(let i=1;i<ws_data_patients.length;i++){
                const dataKey = `A${i+1}`
                let cell = ws_patients[dataKey]
                cell.t = 'd'
                ws_patients[dataKey] = cell
            }

            console.log(ws_patients)

            wb.Sheets["Beneficios entregados"] = ws_patients;
        
            let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'analytics_benefits.xlsx');
        
        },
        onGetFilterParams: () => {
            let _filter = {}
            Object.keys(filter).forEach(key => {
                if(filter[key]) _filter[key] = filter[key]?.value || filter[key]
            })

            switch (user.user_type_id) {
                case USER_TYPE.ADMIN:
                case USER_TYPE.ROCHE:
                    if (programId > 0 ) _filter.product_type_id = programId;
                    break;
                case USER_TYPE.MERCURIO:
                    _filter.product_type_id = user.agency_id === 1 ? 1 : 2;
                    break;
                case USER_TYPE.HOSPITAL:
                    _filter.product_type_id = 1;
                    break;
                default:
            }
            return _filter
          }
    }

    const data = {stats, masterCatalogs, filter, filter_disabled, total, pagination}

    const system = {loading, sending, error, setError}

    return {actions, data, system}

}

export default useDeliveredBenefitsStats