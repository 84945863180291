export const cleanData2Table = (data, nodata, translations) => {
  
  const table = data.map(item => {
    
    let temp = {
      id:item.id_public_insurance, 
      data:[]
    }

    let array = [
      item.id_public_insurance,
      item.name ? item.name : nodata, 
      item.crm_id ? item.crm_id : '-',
      item.uci ? item.uci : '-', 
    ]
    temp.data = [...array]
    return temp
  })

  return table
}