import React, {Component, useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import PatientList from './PatientList/PatientList'
import WarningModal from '../../../../components/Modals/WarningModal'


import {request_patients, request_delete_user, request_hospitals, request_doctors} from './requests'
import {cleanData2Table, getDoctorsOptions, getHospitalsOptions} from './customFunctions'
import SpecialPatientList from './SpecialPatientList/SpecialPatientList';
import useApplicants from './useApplicants'

const Applicants = ({history, language, translations, user_level}) => {

  const {
    loading, setLoading,
    patients, setPatients,
    hospitals, setHospitals,
    doctors, setDoctors,
    filter, setFilter,
    total, setTotal,
    table_config, settable_config,
    modals, setModals,
    success, setSuccess,
    actions
  } = useApplicants({history})

  useEffect(() => {
    const initModule = async() => {
      actions.onInitModule()
    }
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) initModule()
  },[])

  useEffect(() => {

    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) actions.onSearchAplicants()
    
  }, [table_config, ])

  const content = contentData[language]
  const data_applicants = cleanData2Table(patients,content.nodata,translations)

  let modal_content = null
  if(modals.warning_delete_user){
    modal_content=(
      <WarningModal 
        data={modals.warning_delete_user}
        content={content.modals.warning_modal}
        onClose={() => actions.onCloseModal('warning_delete_user')}
        onDelete={() => console.log('onDeleteUser() fucntions')}
      />
    )
  }

  let superFilter = [...content.special_superfilters]

  //console.log(table_config)

  return(
    <BasicView
        history={history}
        content={content.layout}
        isloading={loading}
        success={success}
        onCloseSnackbar={actions.onCloseSnackbar} 
      >
        {modal_content}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SpecialPatientList 
              user_level={user_level} total_items={total}
              filter={filter}
              filters={content.filters}
              master_filters={superFilter}
              config={table_config}
              onChangeFilter={actions.onChangeFilter}
              header={content.special_header}
              data={patients}
              onUserSelected={actions.onPatientSelected}
              onAddItem={actions.onAddApplicant}
              onDeleteUser={actions.onPreDeleteUser}
              onChangeConfig={actions.onChangeTableConfig}
            />      
          </Grid>
        </Grid>
        
      </BasicView>
  )
}

export default Applicants
