export const cleanData2Table = (data, nodata) => {
  
  const table = data.map(item => {
    
    let temp = {
      id:item.id_provider, 
      hospital_value:item.hospital_value,
      data:[]
    }

    let array = [
      item.id_provider,
      item.name ? item.name : nodata, 
      item.contact_email ? item.contact_email : nodata,
      item.crm_id ? item.crm_id : '-',
      item.uci ? item.uci : '-', 
    ]
    temp.data = [...array]
    return temp
  })

  return table
}