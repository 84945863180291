export const mainForm = {
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  external_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'external_id',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  folio:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'folio',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  doctor_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'doctor_id',
      type:'select',
      fullWidth: true,
      addoption:true,
    },
    rules:{
      type:'none',
    }
  },
  zip:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'zip',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  public_insurance_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'public_insurance_id',
      type:'select',
      fullWidth: true,
      addoption:true
    },
    rules:{
      type:'none',
    }
  },
  nse_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'nse_id',
      type:'select',
      fullWidth: true,
      addoption:true,
    },
    rules:{
      type:'none',
    }
  },
  product_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'product_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  diagnosis_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'diagnosis_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  
  expected_uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'expected_uses',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'numeric',
      min:0,
      max:50
    }
  },
  secondary_product_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'secondary_product_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  secondary_diagnosis_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'secondary_diagnosis_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  secondary_expected_uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'secondary_expected_uses',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },

}
