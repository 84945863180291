import React from 'react';
import { alpha, Button, CircularProgress, fade, Fade, Grid, Grow, makeStyles } from '@material-ui/core';
import cx from 'classnames'
import { red } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        fontFamily:theme.typography.fontFamily,
        textTransform:'none',
        padding:'8px 20px',
        fontSize:'1rem',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        '&:disabled':{
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    root_small:{
        padding:'8px 20px',
        fontSize:'1rem',
    },
    
    primary:{
        background:theme.palette.primary.main,
        '&:hover':{background:theme.palette.primary.dark}
    },
    secondary:{
        background:theme.palette.secondary.main,
        '&:hover':{background:theme.palette.secondary.dark}
    },
    red:{
        background:red[300],
        '&:hover':{background:red[500]}
    },
    grey:{
        background:grey[300],
        '&:hover':{background:grey[400]},
        color:grey[900],
    },
    white:{
        color:grey[900],
        background:'white',
        '&:hover':{background:alpha('#000',0.025)}
    },
    loading_container:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        //width:55,
        //height:55,
        padding:'8px 11px',
        position:'relative'
    },
    progress:{
        color:'white',
        marginTop:2
        //position:'absolute',
        //top:'50%', left:'50%',
        //transform:'translate(-50%, -50%)'
        //marginLeft:8
    },
    
}))

const RoundedButton = ({loading, children, color, onClick, fullWidth, disabled, size}) => {

    const classes = useStyles()

    return ( 
        <div>
            {!loading ? <Grow in timeout={500}>
                <Button onClick={!loading ? onClick : undefined} fullWidth={fullWidth} disabled={disabled}
                    className={cx({
                        [classes.root]:true, 
                        [classes.root_small]:size==='small',
                        [classes.secondary]: color==='secondary',
                        [classes.red]: color==='red',
                        [classes.white]: color==='white',
                        [classes.grey]: color==='grey',
                        [classes.transparent]: color==='transparent'
                    })}>
                        {children}
                </Button>
            </Grow> : (
                <Fade in timeout={500}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <div className={classes.loading_container}>
                                <CircularProgress size={32} className={classes.progress}/>
                            </div>
                        </Grid>
                    </Grid>
                    
                </Fade>
            )}
            
        </div>
        
     );
}
 
export default RoundedButton;