import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Grid } from '@material-ui/core'
import Subtitle from '../Texts/Subtitle'
import { ErrorMessage } from '../DisplayData/DisplayData'
import SimpleLoader from '../Loaders/SimpleLoader'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:30
    },
    button:{
        borderRadius:32,
        textTransform:'none'
    },
    cancelButton:{
        borderRadius:32,
        textTransform:'none',
        color:grey[700]
    }
}))

const SimpleDialog = props => {

    const classes = useStyles()
    
    //////////////////////////// MULTIPLE STATES AND PROPS  ///////////////////////////////
    const {onClose, data, onSubmit, title, maxWidth, submit,
    hideSubmitBtn, hideCancelBtn} = props
    const {issending, error} = data


    ////////////////////////// RENDER ////////////////////////////////

    let infoContent = null

    if(issending){
        infoContent = <SimpleLoader />
    }else{
        if(error){infoContent = <ErrorMessage message={error}/>}
    }

    return(
        <Dialog
            open={true}
            maxWidth={maxWidth ? maxWidth : 'sm'}
            fullWidth
            onClose={onClose}
            classes={{paper:classes.paper}}
        >
            <DialogTitle>
                <Grid container>
                    <Grid item xs>
                        <Subtitle text={title} medium variant='bold'/>
                    </Grid>
                    {/*<Grid item><IconButton size='small' onClick={onClose}><Icon>close</Icon></IconButton></Grid>*/}
                </Grid>
                
            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                {infoContent}
                <div style={{flex:1}}/>
                {!hideCancelBtn ? (
                    <Button 
                    className={classes.cancelButton} 
                    color='secondary' 
                    variant='text' onClick={onClose}>Cancelar</Button>
                ) : null}
                
                {!hideSubmitBtn ? (
                    <Button 
                    className={classes.button} 
                    color='secondary' 
                    variant='text' onClick={onSubmit}>{submit ? submit : 'Agregar'}</Button>
                ) : null}
            </DialogActions>
        </Dialog>
    )
}

export default SimpleDialog