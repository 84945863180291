import UserProfile from '../views/Admin/Profile/UserProfile/UserProfile'
import Doctors from '../views/Admin/Doctors/Doctors/Doctors'
import Patients from '../views/Admin/Patients/Patients/Patients'
import Agents from '../views/Admin/Agents/Agents/Agents'
import AddPatient from '../views/Admin/Patients/AddPatient/AddPatient.jsx';
import Catalogs from '../views/Admin/Catalogs/Catalogs/Catalogs/Catalogs.jsx';
import AddDoctor from '../views/Admin/Doctors/AddDoctor/AddDoctor.jsx';
import DoctorView from '../views/Admin/Doctors/DoctorView/DoctorView.jsx';
import DoctorEdit from '../views/Admin/Doctors/DoctorEdit/DoctorEdit.jsx';
import Hospitals from '../views/Admin/Hospitals/Hospitals/Hospitals.jsx';
import AddHospital from '../views/Admin/Hospitals/AddHospital/AddHospital.jsx';
import HospitalView from '../views/Admin/Hospitals/HospitalView/HospitalView.jsx';
import HospitalEdit from '../views/Admin/Hospitals/HospitalEdit/HospitalEdit.jsx';
import AddAgent from '../views/Admin/Agents/AddAgent/AddAgent.jsx';
import AgentView from '../views/Admin/Agents/AgentView/AgentView.jsx';
import AgentEdit from '../views/Admin/Agents/AgentEdit/AgentEdit.jsx';
import PublicInsurances from '../views/Admin/PublicInsurances/PublicInsurances/PublicInsurances.jsx';
import AddPublicInsurance from '../views/Admin/PublicInsurances/AddPublicInsurance/AddPublicInsurance.jsx';
import PublicInsuranceView from '../views/Admin/PublicInsurances/PublicInsuranceView/PublicInsuranceView.jsx';
import PublicInsuranceEdit from '../views/Admin/PublicInsurances/PublicInsuranceEdit/PublicInsuranceEdit.jsx';
import InsuranceCarriers from '../views/Admin/InsuranceCarriers/InsuranceCarriers/InsuranceCarriers.jsx';
import AddInsuranceCarrier from '../views/Admin/InsuranceCarriers/AddInsuranceCarrier/AddInsuranceCarrier.jsx';
import InsuranceCarrierView from '../views/Admin/InsuranceCarriers/InsuranceCarrierView/InsuranceCarrierView.jsx';
import InsuranceCarrierEdit from '../views/Admin/InsuranceCarriers/InsuranceCarrierEdit/InsuranceCarrierEdit.jsx';
import Logs from '../views/Admin/Logs/Logs/Logs.jsx';
import Emails from '../views/Admin/Emails/Emails/Emails.jsx';
import AddEmail from '../views/Admin/Emails/AddEmail/AddEmail.jsx';
import EmailEdit from '../views/Admin/Emails/EmailEdit/EmailEdit.jsx';
import EditUserProfile from '../views/Admin/Profile/EditUserProfile/EditUserProfile.jsx';
import PatientEdit from '../views/Admin/Patients/PatientEdit/PatientEdit.jsx';
import Providers from '../views/Admin/Providers/Providers/Providers.jsx';
import AddProvider from '../views/Admin/Providers/AddProvider/AddProvider.jsx';
import ProviderView from '../views/Admin/Providers/ProviderView/ProviderView.jsx';
import ProviderEdit from '../views/Admin/Providers/ProviderEdit/ProviderEdit.jsx';
import Analytics from '../views/Admin/Analytics/Analytics/Analytics.jsx';
import AddPatientv2 from '../views/Admin/Patients/AddPatientv2/AddPatientv2.jsx';
import ApplicantView from '../views/Admin/Applicants/ApplicantView/ApplicantView';
import ApprovalEmails from '../views/Admin/Emails/ApprovalEmails/ApprovalEmails';
import ApplicantEmails from '../views/Admin/Emails/ApplicantEmails/ApplicantEmails';
import AddApplicant from '../views/Admin/Applicants/AddApplicant/AddApplicant';



const dashboardAdmin = {
    sections:[
        {
            path: "/profile",
            icon:'work',
            label:'Asesores',
            component: UserProfile,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/profile/edit",
            icon:'work',
            label:'Asesores',
            component: EditUserProfile,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/agents",
            icon:'work',
            label:'Asesores',
            component: Agents,
            sidebar:true,
            exact:true,
            level:1
        },
        {
            path: "/agents/add",
            icon:'work',
            label:'Asesores',
            component: AddAgent,
            sidebar:false,
            exact:true,
            level:1
        },
        {
            path: "/agents/:id",
            icon:'work',
            label:'Asesores',
            component: AgentView,
            sidebar:false,
            exact:true,
            level:1
        },
        {
            path: "/agents/:id/edit",
            icon:'work',
            label:'Asesores',
            component: AgentEdit,
            sidebar:false,
            exact:true,
            level:1
        },
        /////////////////////////////////////////// PATIENTS  ///////////////////////////////////////////////

        {
            path: "/patients",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: Patients,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/patients/add",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: AddApplicant,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/patients/add2",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: AddPatient,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/patients/:id",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: ApplicantView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/patients/:id/edit",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: PatientEdit,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs",
            icon:'books',
            label:'Catálogos',
            component: Catalogs,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/doctors",
            icon:'books',
            label:'Doctores',
            component: Doctors,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/doctors/add",
            icon:'books',
            label:'Doctores',
            component: AddDoctor,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/doctors/:id",
            icon:'books',
            label:'Doctores',
            component: DoctorView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/doctors/:id/edit",
            icon:'books',
            label:'Doctores',
            component: DoctorEdit,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/hospitals",
            icon:'books',
            label:'Hospitales',
            component: Hospitals,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/hospitals/add",
            icon:'books',
            label:'Hospitales',
            component: AddHospital,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/hospitals/:id",
            icon:'books',
            label:'Hospitales',
            component: HospitalView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/hospitals/:id/edit",
            icon:'books',
            label:'Hospitales',
            component: HospitalEdit,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/providers",
            icon:'books',
            label:'Distribuidores',
            component: Providers,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/providers/add",
            icon:'books',
            label:'Distribuidores',
            component: AddProvider,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/providers/:id",
            icon:'books',
            label:'Distribuidores',
            component: ProviderView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/providers/:id/edit",
            icon:'books',
            label:'Distribuidores',
            component: ProviderEdit,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/public_insurances",
            icon:'books',
            label:'Aseguradoras publicas',
            component: PublicInsurances,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/public_insurances/add",
            icon:'books',
            label:'Aseguradoras publicas',
            component: AddPublicInsurance,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/public_insurances/:id",
            icon:'books',
            label:'Aseguradoras publicas',
            component: PublicInsuranceView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/public_insurances/:id/edit",
            icon:'books',
            label:'Aseguradoras publicas',
            component: PublicInsuranceEdit,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/insurance_carriers",
            icon:'books',
            label:'Aseguradoras privadas',
            component: InsuranceCarriers,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/insurance_carriers/add",
            icon:'books',
            label:'Aseguradoras privadas',
            component: AddInsuranceCarrier,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/insurance_carriers/:id",
            icon:'books',
            label:'Aseguradoras privadas',
            component: InsuranceCarrierView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/catalogs/insurance_carriers/:id/edit",
            icon:'books',
            label:'Aseguradoras privadas',
            component: InsuranceCarrierEdit,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/analytics",
            icon:'insert_chart',
            label:'Analíticos',
            component: Analytics,
            sidebar:true,
            exact:true,
            level:1
        },
        {
            path: "/mails",
            icon:'contact_mail',
            label:'Correos',
            component: Emails,
            sidebar:true,
            exact:true,
            level:1
        },
        {
            path: "/mails/approvals",
            icon:'contact_mail',
            label:'Correos',
            component: ApprovalEmails,
            sidebar:false,
            exact:true,
            level:1
        },
        {
            path: "/mails/applicants",
            icon:'contact_mail',
            label:'Correos',
            component: ApplicantEmails,
            sidebar:false,
            exact:true,
            level:1
        },
        {
            path: "/mails/add",
            icon:'contact_mail',
            label:'Correos',
            component: AddEmail,
            sidebar:false,
            exact:true,
            level:1
        },
        {
            path: "/mails/:id/edit",
            icon:'contact_mail',
            label:'Correos',
            component: EmailEdit,
            sidebar:false,
            exact:true,
            level:1
        },
        {
            path: "/logs",
            icon:'settings_ethernet',
            label:'Logs',
            component: Logs,
            sidebar:true,
            exact:true,
            level:1
        },
        

        { redirect: true, path: "/", to: "/patients", navbarName: "Redirect" }
    ]
}

export default dashboardAdmin;