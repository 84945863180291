import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Chart from './Chart';
import Subtitle from '../../Texts/Subtitle';
import { amber, blue, cyan, deepOrange, green, grey, purple } from '@material-ui/core/colors';

const StackedAreaChart = ({ data, title, xKey, step, domainY }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root} spacing={2}>
			<Grid item xs={12}>
				<Subtitle medium text={title} color={blue[900]}/>
			</Grid>
			<Grid item xs={12}>
				<Chart data={data} xKey={xKey} step={step} domainY={domainY}/>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 25,
	},
}));

export default StackedAreaChart;
