import React, {Component} from 'react'
import {  TableRow,TableCell, Table, TableBody } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ContentText from '../../../../../components/Texts/ContentText';


const styles = (theme) => ({
  root:{},
  table:{
    minWidth:500
  },
  tableWrapper:{
    overflowX: 'auto',
  },
  icon:{
    fontSize:12,
  }
})

class ProductStatsTable extends Component{

  render(){

    const {classes, data} = this.props

    return(
      
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              {data.header.map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant='bold' medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            {data.data.map((item, key)=>{
              return(
                <TableRow key={key.toString()}>
                  {item.map((el, id)=>{
                    return(
                      <TableCell key={id.toString()} align='center'>
                        <ContentText text={el} variant={key === data.data.length -1 ? 'bold' : 'light'} medium />
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
              
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(ProductStatsTable)