import {mainServer} from '../../../../variables/config'

export const request_approval_contacts = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/approvalcontact/all")
  return response.data.data.approval_contacts
}

export const request_add_contact = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/approvalcontact", data)
  let response = await mainServer.instance.get("/approvalcontact/all")
  return response.data.data.approval_contacts
}


export const request_update_contact = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/approvalcontact/${id}`, data)
  let response = await mainServer.instance.get("/approvalcontact/all")
  return response.data.data.approval_contacts
}

export const request_delete_contact = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/approvalcontact/${id}`)
  let response = await mainServer.instance.get("/approvalcontact/all")
  return response.data.data.approval_contacts
}
