import React from 'react'
import { red, blue, green, grey } from '@material-ui/core/colors'
import { Grid, makeStyles, fade, Icon, alpha } from '@material-ui/core'
import ContentText from '../Texts/ContentText'
import Caption from '../Texts/Caption'
import cx from 'classnames'
import { palette } from '../../variables/config'

const useStyles = makeStyles(theme => ({
    docContainer:{
        position:'relative',
        background: alpha(blue[300],0.85),
        width:40,
        height:48,
        borderRadius:8,
        color:'white',
        fontWeight:500,
        fontSize:14
    },
    docContainerWrong:{
        background: grey[300],
        color:grey[700],
    },
    docText:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    goodIcon:{
        paddingTop:4,
        paddingLeft:4,
        color:green[700]
    },
    wrongIcon:{
        paddingTop:4,
        paddingLeft:4,
        color:red[700]
    },
    iconUpload:{
        color:theme.palette.secondary.main,

    }
}))


const FileView = props => {

    const classes = useStyles()
    const {file} = props

    let filename = 'N/D'
    let size = ''
    let type = 'N/D'

    let iconContent = (
        <Icon className={classes.wrongIcon}>error</Icon>
    )

    let contentView = (
        <Grid container direction='column' alignItems='center'>
            <Grid item><Icon className={classes.iconUpload}>cloud_upload</Icon></Grid>
            <Grid item>
                <ContentText text='De click o arrastre aquí o' medium/>
            </Grid>
            <Grid item>
                <ContentText text='Encuentre un archivo' medium color={palette.secondary.main}/>
            </Grid>
        </Grid>
    )

    if(file){
        filename = file.name
        size = `${Math.ceil(file.size / 1024).toString()} kB`
        type = file.name.split('.').slice(-1)[0] 
        iconContent = (
            <Icon className={classes.goodIcon}>verified_user</Icon>
        )

        contentView = (
            <Grid container spacing={1} wrap='nowrap'>
                <Grid item>
                    <div className={cx({
                        [classes.docContainer]:true,
                        [classes.docContainerWrong]: !file
                    })}>
                        <div className={classes.docText}>{type}</div>
                    </div>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <ContentText  text={`${filename}`} medium variant='bold'/>
                                </Grid>
                                <Grid item>
                                    {iconContent}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Caption text={`${size}`} medium/>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item>
                    <RoundedButton label='Adjuntar' color='secondary' size='small' onClick={onClick}/>
                </Grid>*/}
            </Grid>
        )
    }else{

    }

    return(
        <div>{contentView}</div>
    )
}

export default FileView