import React, {Component} from 'react'
import { TableRow,TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import {TableItem} from '../../../../../components/DisplayData/DisplayData'

import { green, red, grey } from '@material-ui/core/colors';


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class AgentList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = 0
    temp.rowsPerPage = value
    onChangeConfig(temp)
  }

  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }
  
  onChangeMasterFilter = (key, data) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter[key] = data
    if(key !== 'status'){
      Object.keys(temp.filter).forEach(item => {
        if(item === key){
          temp.filter[item] = data
        }else{
          if(item !== 'status'){
            temp.filter[item] = null
          }
        }
      })
    }
    
    temp.page = 0
    onChangeConfig(temp)

  }

  render(){

    const {classes, title, header, data, onUserSelected, onAddPatient, 
      filters, config, master_filters
    } = this.props
    const { page, rowsPerPage, totalItems, isSearch} = config

    console.log(config.filter)
    console.log(master_filters)

    return(
      
      <div className={classes.root}>
        <SimpleTable
          title={title}
          header={header}
          isSearch={isSearch}
          filter={config.filter}
          filters={filters}
          master_filters={master_filters}
          onChangeFilter={this.onChangeFilter}
          onChangeMasterFilter={this.onChangeMasterFilter}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={totalItems}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={true}
          onAddItem={onAddPatient}
        >
          {data.map((item,key)=>{
            return(
              <TableRow key={key.toString()} 
              className={classes.tablerow} 
              >
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    avatar={item.image}
                    data={item.data[0]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[1]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[2]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[3]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='mail'
                    data={item.data[4]}
                  />
                </TableCell>

                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='phone'
                    data={item.data[5]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='brightness_1'
                    iconSize={12}
                    iconColor={item.status ? green[700] : red[700]}
                    data={item.data[6]}
                  />
                </TableCell>                
              </TableRow>
            )
          })}
        </SimpleTable>
      </div>
    )
  }
}

export default withStyles(styles)(AgentList)