export const contentData = {
  spanish:{
    layout:{
      title:'Agregar Asesor',
      navigation:[
        {name:'Asesores',path:'/agents'},
        {name:'Agregar',path:'/agents/add'}
      ],
      menu:null
    },
    header:['ID','Nombre','Email','Teléfono','Género']
  }
}