export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    add_button:'Agregar',
    title:{
      sectionA:'Información Básica',
      sectionB:'Información Detallada',
      sectionC:'Detalles',
    },
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Longitud entre 1-255 caracteres'
      },
      email:{
        label:'E-mail',
        placeholder:'mail',
        helper:'Correo no válido'
      },
    },
  },
  
}