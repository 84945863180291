import React, { useState, useEffect, useCallback } from 'react';
import { isFormValid, sleep } from '../../../../../shared/utility';
import { getDiagnosticsOptions, getHospitalsOptions, getProductsOptions } from '../customFunctions';
import { request_diagnostics } from '../requests';
import { catalogs } from '../../../../../variables/catalogs';


const useTreatments = ({products, hospitals}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [validForm, setValidForm] = useState(false)

    const [treatments, setTreatments] = useState([])

    useEffect(() => {
        actions.onAddTreatmentRegister()
    }, [])

    useEffect(() => {
        actions.onUpdateProductsCatalog()
    }, [products])

    useEffect(() => {
        actions.onUpdateHospitalsCatalog()
    }, [hospitals])

    const onUpdateCatalogs = useCallback((position, _schemes, _diagnostics) => {
       //console.log("Actualizar item de schemes", position, data)
       //console.log(JSON.parse(JSON.stringify(treatments)))
        let _treatment = treatments[position]
        _treatment.schemes = _schemes
        _treatment.diagnostics = _diagnostics
        let _treatments = [...treatments]
        _treatments[position] = _treatment
        //console.log(_treatments)
        setTreatments(_treatments)
    },[treatments])

    const actions = {
        onAddTreatmentRegister: () => {
            let _treatments = [...treatments]
            
            const _form = JSON.parse(JSON.stringify(treatment_formdata))
            if(products){
                _form.product_id.options = [...products]
            }
            if(hospitals){
                _form.hospital_id.options = [...hospitals]
            }
            _form.treatment_frequency_id.options = [...catalogs.spanish.treatment_frequencies]
            const data2add = {
                form:_form,
                vials:[],
                prebenefit:null,
                schemes:[],
                diagnostics:[]
            }
            _treatments.push(data2add)
            setTreatments(_treatments)
        },
        onInputChange: async(data, pos) => {

            const id = data.config.id;
            let _treatments = [...treatments]
            let _treatment = {...treatments[pos]}
            let temp = {..._treatment.form};
            temp[id] = {...data};
            _treatment.form = {...temp}
            _treatments[pos] = {..._treatment}
            const isValidForm = isFormValid(temp)
           
            setTreatments(_treatments)
            setValidForm(Boolean(isValidForm))
        },
        onMultipleInputChange: async(data, pos) => {
            let _treatments = [...treatments]
            let _treatment = {...treatments[pos]}
            let temp = {..._treatment.form, ...data};
            _treatment.form = {...temp}
            _treatments[pos] = {..._treatment}
            const isValidForm = isFormValid(temp)
            setTreatments(_treatments)
            setValidForm(Boolean(isValidForm))
        },
        onRequestDiagnosis: () => {

        },
        onUpdateVial: async(_data, mode, treatment_id) => {
            let data2send, _treatment, _vials
            data2send = [...treatments]
            let product_id = null
            switch (mode) {
                case 'add':
                    // ADD VIAL PRODUCT PROCCESS
                    _treatment = treatments[treatment_id]
                    const last_position = _treatment.vials.length + 1
                    _data.number.value = last_position
                    _data.number.isValid = true
                    _vials = [..._treatment.vials, _data]
                    _treatment.vials = [..._vials]

                    if(_vials.length)product_id = _vials[0].product_id.value 
                    data2send[treatment_id] = _treatment
                    break;
                case 'edit':
                    // EDIT VIAL PRODUCT PROCCESS
                    _treatment = treatments[treatment_id]
                    _vials = [..._treatment.vials]
                    _vials[_data.number.value-1] = {..._data}
                    _treatment.vials = [..._vials]

                    if(_vials.length)product_id = _vials[0].product_id.value
                    data2send[treatment_id] = _treatment
                    break;
                case 'delete':
                    _treatment = treatments[treatment_id]
                    const pos = _data.number.value
                    console.log('Eliminar', pos, _treatment.vials)
                    _vials = _treatment.vials.filter(el => el.number.value !== pos)
                    _vials = actions.onReformatVialsNumber(_vials)
                    console.log('Updated vials',_vials)
                    _treatment.vials = [..._vials]
                    if(_vials.length) product_id = _vials[0].product_id.value
                    data2send[treatment_id] = _treatment
                    break;
            
                default:
                    break;
            }
            setTreatments(data2send)
            await sleep(300)
            
            if(product_id){
                const _temp = treatments[treatment_id]
                let product_id_data = {..._temp.form.product_id}
                product_id_data.value = product_id
                product_id_data.isValid = true
                actions.onInputChange(product_id_data, treatment_id)
                //actions.onRequestDiagnosis(product_id, treatment_id)
            }

        },
        onRequestDiagnosis: async(product_id, treatment_id) => {
            let _treatments, _vials
            _treatments = [...treatments]
            let _treatment = _treatments[treatment_id]
            const temp = _treatment.form;
            let _diagnosis = []
            try {
                const response = await request_diagnostics(product_id)
                _diagnosis = getDiagnosticsOptions(response)
                temp.diagnosis_id.options = [..._diagnosis]
                temp.diagnosis_id.value = null
                temp.diagnosis_id.isVisited = false
                temp.diagnosis_id.isValid = false
            } catch (error) {
                console.log(error)
            }

            _treatment.form = {...temp}
            _treatments[treatment_id] = {..._treatment}
            setTreatments(_treatments)
        },
        onMoveVial: async(_data, mode, treatment_id) => {
            console.log('Mover funcion')
            let data2send, _treatment, _vials, product_id
            data2send = [...treatments]
            _treatment = treatments[treatment_id]
            _vials = [..._treatment.vials]

            if(_vials.length <= 1) return

            console.log('EMpezar a mover')
            const current_pos = _data.number.value - 1
            let new_pos = mode === 'up' ? current_pos - 1 : current_pos + 1
            if(new_pos < 0) new_pos = 0
            if(new_pos > _vials.length -1 ) new_pos = _vials.length - 1
            console.log(current_pos, new_pos)
            // swap technique
            let backup = _vials[new_pos]
            _vials[new_pos] = _data
            _vials[current_pos] = backup
            _vials = actions.onReformatVialsNumber(_vials)
            _treatment.vials = [..._vials]
            if(_vials.length)product_id = _vials[0].product_id.value
            data2send[treatment_id] = _treatment
            setTreatments(data2send)
            await sleep(300)
            if(product_id){
                const _temp = treatments[treatment_id]
                let product_id_data = {..._temp.form.product_id}
                product_id_data.value = product_id
                product_id_data.isValid = true
                actions.onInputChange(product_id_data, treatment_id)
                //actions.onRequestDiagnosis(product_id, treatment_id)
            }
        },
        onReformatVialsNumber: (data) => {
            let result = [...data]
            data.forEach((item, key) => {
                result[key].number.value = key + 1
            })
            return result
        },
        
        onUpdateProductsCatalog: async() => {
            if(treatments.length === 0) return
            let _treatments = []
            const _productCatalog = getProductsOptions(products)
            try {
                treatments.forEach((item)=> {
                    
                    let _treatment = {...item}
                    let _form = {..._treatment.form}
                    _form.product_id.options = [..._productCatalog]
                    _form.product_id.value = null
                    _form.product_id.isVisited = false
                    _form.product_id.isValid = false
    
                    _form.diagnosis_id.options = []
                    _form.diagnosis_id.value = null
                    _form.diagnosis_id.isVisited = false
                    _form.diagnosis_id.isValid = false
                    _treatment.form = {..._form}
                    _treatments.push(_treatment)
                })                
                setTreatments(_treatments)
            } catch (e) {
                console.log(e)
            }
            
        },
        onUpdateHospitalsCatalog: async() => {
            if(treatments.length === 0) return
            let _treatments = []
            const _hospitalCatalog = getHospitalsOptions(hospitals)
            try {
                treatments.forEach((item)=> {
                    let _treatment = {...item}
                    let _form = {..._treatment.form}
                    _form.hospital_id.options = [..._hospitalCatalog]
                    _form.hospital_id.value = null
                    _form.hospital_id.isVisited = false
                    _form.hospital_id.isValid = false
                    _treatment.form = {..._form}
                    _treatments.push(_treatment)
                })                
                setTreatments(_treatments)
            } catch (e) {
                console.log(e)
            }
            
        },
        onUpdateTreatments: (data) => setTreatments(data),
        
    }

    const system = {loading, sending, error}
    //const data = {}
    
    return {system, treatments, actions, onUpdateCatalogs}


}

export default useTreatments

const treatment_formdata = {
    multiple_piece:{
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config:{
            id:'multiple_piece',
            type:'select',
            fullWidth: true,
            label:'Multiple Pieza',
            placeholder:'Seleccione un opción',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    product_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'product_id',
            type:'select',
            fullWidth: true,
            label:'Producto + Tratamiento',
            placeholder:'Seleccione un producto',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    diagnosis_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'diagnosis_id',
            type:'select',
            fullWidth: true,
            label:'Diagnóstico',
            placeholder:'Seleccione un diagnóstico',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    scheme_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'scheme_id',
            type:'select',
            fullWidth: true,
            label:'Esquema',
            placeholder:'Seleccione un esquema',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    hospital_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'hospital_id',
            type:'select',
            fullWidth: true,
            label:'Hospital de infusión',
            placeholder:'Seleccione una opción',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    dose:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'dose',
            type:'text',
            fullWidth: true,
            label:'Dosis',
            placeholder:'Dosis',
            helper:'Texto con 256 caracteres permitidos'
        },
        rules:{
            type:'distance',
            min:0,
            max:255
        }
    },
    expected_uses:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'expected_uses',
            type:'number',
            fullWidth: true,
            label:'No. de ciclos',
            placeholder:'No. de ciclos',
            helper:'Número entre 1-50'
        },
        rules:{
            type:'numeric',
            min:0,
            max:50
        }
    },
    vial_number:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'vial_number',
            type:'number',
            fullWidth: true,
            label:'No. de viales',
            placeholder:'No. de viales',
            helper:'Número entre 0-50'
        },
        rules:{
            type:'numeric',
            min:0,
            max:50
        }
    },
    treatment_frequency_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'treatment_frequency_id',
            type:'select',
            fullWidth: true,
            label:'Frecuencia',
            placeholder:'Seleccione una opción',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    adjusted:{
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config:{
            id:'adjusted',
            type:'checkbox',
            fullWidth: true,
            label:'¿Es ajustado?',
            placeholder:'¿Es ajustado?',
            helper:''
        },
        rules:{
            type:'none',
        }
    },
}