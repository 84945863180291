import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import ContentText from '../../../../../components/Texts/ContentText';
import { USER_TYPE } from '../../../../../variables/config'

const styles = (theme) => ({
  root:{padding:'32px'},
  subtitle:{
    color:theme.palette.primary.main,
    fontWeight:'500'
  }
})



class AddAgentCard extends Component {

  state = {
    form: JSON.parse(JSON.stringify(formData)),
    accredited:false,
    isValid:false
  }

  render(){

    const {classes, language, issending, error} = this.props
    const {form, isValid} = this.state
    const content = contentData[language]

    let tempForm = {...form}
    tempForm.password_confirmation.value2 = tempForm.password.value

    let loaderContent = null
    if(issending){
      loaderContent = <SimpleLoader />
    }else{
      if(error){
        loaderContent = <ErrorMessage message={error}/>
      }
    }  
    
    const isHospitalAdmin = tempForm && tempForm.user_type_id.value === 5

    let agencyContent = null
    //console.log(tempForm.user_type_id)
    if(tempForm.user_type_id.value === USER_TYPE.MERCURIO){
      agencyContent = <Grid item xs={12} md={6}>
          <LineInputSelect
            data={tempForm.agency_id}
            onChange={this.onInputChange}
          />
        </Grid>
    }

    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ContentText text='Informacion del asesor' variant='bold' medium/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.first_name}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.last_name}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.username}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.email}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.password}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.password_confirmation}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputSelect
                        data={tempForm.user_type_id}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    {agencyContent}
                    {isHospitalAdmin ? (
                      <Grid item xs={12} md={6}>
                        <LineInputSelect
                          data={tempForm.hospital_id}
                          onChange={this.onInputChange}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <LineInputSelect
                        data={tempForm.gender_id}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputSelect
                        data={tempForm.timezone}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  {loaderContent}
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={content.add_button} 
                    size='small' 
                    disabled={!isValid}
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  componentDidMount(){
    const {language, catalogs, hospitals} = this.props
    const {form} = this.state
    const content = contentData[language]
    let tempA = {...form}
    tempA.user_type_id.options = [...catalogs.user_types]
    tempA.agency_id.options = [...catalogs.agencies]
    tempA.gender_id.options = [...catalogs.user_genders]
    tempA.timezone.options = [...catalogs.timezones]
    tempA.hospital_id.options = [...hospitals]
    Object.keys(form).forEach(item => {
      tempA[item].value = ''
      if(item === 'timezone'){
        tempA[item].value = 'America/Mexico_City'
        tempA[item].isValid = true
      }
      
      tempA[item].config = {...tempA[item].config,...content.form[item]}
    })
    this.setState({form:{...tempA}})
  }

  onInputChange = (data) => {
    const {form} = this.state
    const id = data.config.id;
    let temp = {...form};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp)
    this.setState({form:{...temp}, isValid:isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
      if(item === 'user_type_id'){
        const {user_type_id, hospital_id}  = data
        if(user_type_id.value === 5){
          if(hospital_id.value === null || hospital_id.value === undefined || hospital_id.value === '') isValid = false
        }
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props
    const {form} = this.state
    let data2Send = {}
    
    Object.keys(form).forEach((item)=>{
      if(form[item].isValid){
        if(item !== 'password_confirmation' && item !== 'hospital_id')
        data2Send = {...data2Send, [item]:form[item].value}
      }
    })
    if(data2Send.user_type_id === 5){
      data2Send = {...data2Send, hospital_id: form.hospital_id.value}
    }
    data2Send = {...data2Send, status:1}
    
    onSubmit(data2Send)
  }

  onChangeCheckbox = (id, status) => {
    this.setState({[id]:status})
  }
}

export default withStyles(styles)(AddAgentCard)