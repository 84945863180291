import React from 'react'
import { Grid } from '@material-ui/core'

const ContraintLayout = props => {

    const {status, approvalStatus, constrainst, md, xs, auto, log, div, flex} = props

    let data = localStorage.getItem('data')
    let id = null
    if(data){
        data = JSON.parse(data)
        id = data.user_type_id
    } 

    let hasUserPermission = true
    let hasStatusPermission = true
    let hasApprovalStatus = true
    if(constrainst){
        const {byuser, bystatus, byapprovalstatus} = constrainst
        if(id && byuser && byuser.length){
            const user = byuser.find(el => el === id)
            if(!user) hasUserPermission = false
        } 
        if(status && bystatus && bystatus.length){
            const fStatus = bystatus.find(el => el === status)
            if(!fStatus) hasStatusPermission = false
            
        } 
        if(approvalStatus && byapprovalstatus && byapprovalstatus.length){
            const fApprovalStatus = byapprovalstatus.find(el => el === approvalStatus)
            if(!fApprovalStatus) hasApprovalStatus = false
        } 
        
    }

    if(log){
        console.log('------ LOG -------')
        console.log(`User: ${hasUserPermission} ID: ${id}`)
        console.log(`Status: ${hasStatusPermission}  ID: ${status}`)
        console.log(`Approval Status: ${hasApprovalStatus}  ID: ${approvalStatus}`)
        console.log(`Result: ${!hasUserPermission || !hasStatusPermission ||!hasApprovalStatus}`)
    }
    

    if(!hasUserPermission || !hasStatusPermission ||!hasApprovalStatus ) return null

    if(div) return <div>{props.children}</div>
    if(flex) return (
        <Grid item xs>
            {props.children}
        </Grid>
    )
    
    return(
        <Grid 
            item 
            xs={!auto ? (xs ? xs : 12) : undefined} 
            md={!auto ? (md ? md :6) : undefined}>
            {props.children}
        </Grid>
    )
}

export default ContraintLayout