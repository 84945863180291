import { useState } from "react"
import { getFileExtension, onUpdateAuthorizationHeader } from "../shared/utility"
import { private_server } from "../variables/config"
import axios from 'axios'
import FileSaver from "file-saver"

const usePatientLetter = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [patient_letters, setPatientLetters] = useState([])
    const [patient_letter, setPatientLetter] = useState(null)

    const actions = {
        onGetAll:async(patientid, params, type) => {
            setLoading(true)
            let response = []
            try {
                console.log('pedir cartas')
                onUpdateAuthorizationHeader(private_server)
                let url = `/patientletter/patient/${patientid}`
                console.log(url)
                if(params) url = `${url}${params}`
                const request = await private_server.get(
                    url
                )
                response = request.data.data.patient_letters
                console.log(response)
                //setPatientLetters(response)
                if(type){
                    let _patient_letters = []
                    for(let i=0; i<response.length;i++){
                        const temp = response[i]
                        if(temp.letter_type_id === type) _patient_letters.push(temp)
                    }
                    setPatientLetters(_patient_letters)
                }else{
                    setPatientLetters(response)
                }
                
            } catch (error) {
                setError('No se pudo obtener la lista de cartas de manera correcta, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGet:async(id_patient_letter) => {
            setLoading(true)
            let response = null
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientletter/${id_patient_letter}`
                )
                response = request.data.data.patient_letter
                setPatientLetter(response)
            } catch (error) {
                setError('No se pudo obtener la carta, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onAdd:async(data2send) => {
            setLoading(true)
            let response = {
                status:false,
                error:null
            }
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.post(
                    `/patientletter`, data2send
                )
                
                //response = request.data.data.patient_letter
                //setPatientLetter(response)
                response.status = true
            } catch (error) {
                setError('No se pudo generar la carta, intente de nuevo')
                response.error='No se pudo generar la carta, intente de nuevo'
            }
            setLoading(false)
            return response
        },
        onEdit:async(id, data2send) => {
            setLoading(true)
            let response = {
                status:false,
                error:null
            }
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.patch(
                    `/patientletter/${id}`, data2send
                )
                
                //response = request.data.data.patient_letter
                //setPatientLetter(response)
                response.status = true
            } catch (error) {
                setError('No se pudo actualizar la carta, intente de nuevo')
                response.error='No se pudo actualizar la carta, intente de nuevo'
            }
            setLoading(false)
            return response
        },
        onDelete:async(id) => {
            setLoading(true)
            let response = {
                status:false,
                error:null
            }
            try {
                onUpdateAuthorizationHeader(private_server)
                await private_server.delete(
                    `/patientletter/${id}`
                )
                response.status = true
            } catch (error) {
                setError('No se pudo eliminar la carta, intente de nuevo')
                response.error='No se pudo eliminar la carta, intente de nuevo'
            }
            setLoading(false)
            return response
        },
        onDownloadDocument:async(patientletterid, type) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                let response = await private_server.get(
                    `/patientletter/${patientletterid}/download`,
                    {responseType: 'blob'}
                )
                
                FileSaver.saveAs(response.data, 'letter');    
                //delete axios.defaults.headers.common["Authorization"]
                //response = await axios.get(response.data.data.path, {responseType: 'blob'});
                //console.log(response.data)
                //FileSaver.saveAs(response, `file`);
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            setLoading(false)
            return response
        },
    }

    const system = {loading, sending,error}
    const data = {patient_letter, patient_letters}

    return {system, data, actions}



} 

export default usePatientLetter