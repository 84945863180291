import React, { Component } from 'react';
import { withStyles, Grid, IconButton, Icon } from '@material-ui/core';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import SingleDocument from './components/SingleDocument';
import { getUserType, getFileExtension, getFormatNumberForData } from '../../../../../../shared/utility';
import ErrorInfoModal from '../../../../../../components/Modals/ErrorInfoModal';
import { request_download_document, request_upload_document, request_patient_insurances, 
  request_insurance_carriers, request_policy_amounts, request_add_patient_insurance,
  request_edit_patient_insurance, request_download_insurance_document, request_upload_insurance_document, 
  request_delete_document, request_delete_insurance_document, request_medical_reports,
  request_add_medical_report, request_edit_medical_report, request_upload_medical_report,
  request_download_medical_report, request_delete_medical_report} from './requests';
import UploadDocumentModal from './modals/UploadDocumentModal';
import { request_patient, request_edit_patient } from '../../requests';
import SinglePatientInsurance from './components/SinglePatientInsurance';
import Subtitle from '../../../../../../components/Texts/Subtitle';
import AddInsuranceModal from './modals/AddInsuranceModal/AddInsuranceModal';
import { getInsuranceCarriersOptions } from '../../../AddApplicant/customFunctions';
import EditInsuranceModal from './modals/EditInsuranceModal/EditInsuranceModal';
import WarningModal from '../../../../../../components/Modals/WarningModal';
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout';
import {constrainst_principal as constrainst} from  './components/constraints'
import { green, amber } from '@material-ui/core/colors';
import ContentText from '../../../../../../components/Texts/ContentText';
import { USER_TYPE } from '../../../../../../variables/config';
import AddMedicalReportModal from './modals/AddMedicalReportModal/AddMedicalReportModal';
import SingleMedicalReport from './components/SingleMedicalReport';
import EditMedicalReportModal from './modals/EditMedicalReportModal/EditMedicalReportModal';


class DocumentsView extends Component {

  state = {
    isloading:true,
    error:null,
    patient:null,
    selected_insurance:null,
    selected_medical_report:null,
    patient_insurances:[],
    insurance_carriers:[],
    policy_amounts:[],
    medical_reports:[],
    
    modals:{
      upload_document:{open:false,issending:false,error:null},
      add_insurance_modal:{open:false,issending:false,error:null},
      edit_insurance_modal:{open:false,issending:false,error:null},
      upload_insurance_document:{open:false,issending:false,error:null},
      delete_document:{open:false,issending:false,error:null},
      delete_insurance_document:{open:false,issending:false,error:null},
      add_medical_report:{open:false,issending:false,error:null},
      edit_medical_report:{open:false,issending:false,error:null},
      upload_medical_report_document:{open:false,issending:false,error:null},
      delete_medical_report_document:{open:false,issending:false,error:null},


    }
  }

  render(){

    const {classes, catalogs} = this.props
    const {isloading, error, modals, policy_amounts, patient, patient_insurances, insurance_carriers, 
      selected_insurance, medical_reports, selected_medical_report} = this.state
    const userType = getUserType()

    console.log(patient_insurances)
    let errorModal = null

    if(error){
      errorModal = (
        <ErrorInfoModal 
          data={error}
          onClose={() => this.setState({error:null})}/>
      )
    }

    let modalcontent = null
    
    if(modals.upload_document.open){
      modalcontent = (
        <UploadDocumentModal 
          data={modals.upload_document}
          onClose={() => this.onCloseModal('upload_document')}
          onSubmit={this.onUploadDocument}
        />
      )
    }

    if(modals.upload_insurance_document.open){
      modalcontent = (
        <UploadDocumentModal 
          data={modals.upload_insurance_document}
          onClose={() => this.onCloseModal('upload_insurance_document')}
          onSubmit={this.onUploadInsuranceDocument}
        />
      )
    }

    if(modals.upload_medical_report_document.open){
      modalcontent = (
        <UploadDocumentModal 
          data={modals.upload_medical_report_document}
          onClose={() => this.onCloseModal('upload_medical_report_document')}
          onSubmit={this.onUploadMedicalReport}
        />
      )
    }

    if(modals.delete_document.open){
      modalcontent = (
        <WarningModal data={modals.delete_document} 
        onDelete={this.onDelete}
        onClose={() => this.onCloseModal('delete_document')}
        />
      ) 
    }

    if(modals.delete_insurance_document.open){
      modalcontent = (
        <WarningModal data={modals.delete_insurance_document} 
        onDelete={this.onDeleteInsuranceDocument}
        onClose={() => this.onCloseModal('delete_insurance_document')}
        />
      ) 
    }

    if(modals.delete_medical_report_document.open){
      modalcontent = (
        <WarningModal data={modals.delete_medical_report_document} 
        onDelete={this.onDeleteMedicalReport}
        onClose={() => this.onCloseModal('delete_medical_report_document')}
        />
      ) 
    }

    const insurance_carriers_catalog = getInsuranceCarriersOptions(insurance_carriers)


    //updated_form.insurance_carrier_id.options = [...insurance_carriers_catalog]
    //updated_form.policy_amount_id.options = [...catalogs.policy_amounts] 

    if(modals.add_insurance_modal.open){
      modalcontent = (
        <AddInsuranceModal 
          catalogs={{...catalogs, insurance_carriers:insurance_carriers_catalog}}
          data={modals.add_insurance_modal}
          policy_amounts={policy_amounts}
          onClose={() => this.onCloseModal('add_insurance_modal')}
          onSubmit={this.onAddInsurance}
        />
      )
    }

    if(modals.edit_insurance_modal.open){
      modalcontent = (
        <EditInsuranceModal
          origin={selected_insurance} 
          catalogs={{...catalogs, insurance_carriers:insurance_carriers_catalog}}
          data={modals.edit_insurance_modal}
          policy_amounts={policy_amounts}
          onClose={() => this.onCloseModal('edit_insurance_modal')}
          onSubmit={this.onEditInsurance}
        />
      )
    }

    //////////////////////////// MEDICAL REPORTS ///////////////////////////

    if(modals.add_medical_report.open){
      modalcontent = (
        <AddMedicalReportModal
          data={modals.add_medical_report}
          onClose={() => this.onCloseModal('add_medical_report')}
          onSubmit={this.onAddMedicalReport}
        />
      )
    }

    if(modals.edit_medical_report.open){
      modalcontent = (
        <EditMedicalReportModal
          origin={selected_medical_report}
          data={modals.edit_medical_report}
          onClose={() => this.onCloseModal('edit_medical_report')}
          onSubmit={this.onEditMedicalReport}
        />
      )
    }

    let addPatientInsuranceButton = (
      <ContraintLayout constrainst={constrainst.add_insurance} auto>
        <IconButton size='small' className={classes.addIcon}
        onClick={() => this.onOpenModal('add_insurance_modal')}>
          <Icon fontSize='small'>add</Icon>
        </IconButton>
      </ContraintLayout>
    )

    //if(patient_insurances.length !== 0 && userType === USER_TYPE.HOSPITAL) addPatientInsuranceButton = null
    

    let addMedicalReportButton = (
      <ContraintLayout constrainst={constrainst.add_insurance} auto>
        <IconButton size='small' className={classes.addIcon}
        onClick={() => this.onOpenModal('add_medical_report')}>
          <Icon fontSize='small'>add</Icon>
        </IconButton>
      </ContraintLayout>
    )

    //if(medical_reports.length !== 0 && userType === USER_TYPE.HOSPITAL) addMedicalReportButton = null
    

    return(
      <SimpleContainer isloading={isloading}>
        {errorModal}
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item>
                <Grid container>
                  <Grid item><Icon style={{marginRight:4, fontSize:12, color:green[700]}}>brightness_1</Icon></Grid>
                  <Grid item><ContentText text='Documento aprobado' variant='bold'/></Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item><Icon style={{marginRight:4, fontSize:12, color:amber[700]}}>brightness_1</Icon></Grid>
                  <Grid item><ContentText text='Documento pendiente' variant='bold'/></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CardWrapper>
              <Grid container>
                {documentList.map((item,key)=>{

                  let documentStatus = false
                  if(patient){
                    const aux = patient[item.validationId]
                    documentStatus = aux === 1
                  }

                  return(
                    <Grid item xs={12} key={key.toString()}>
                      <SingleDocument data={item} userType={userType}
                        patient={patient}
                        status={documentStatus}
                        onUpload={this.onUpload}
                        onDownload={this.onDownload}
                        onValidateDocument={this.onValidateGenericDocument}
                        onDelete={this.onPreDelete}/>
                    </Grid>
                  )
                })}
              </Grid>
            </CardWrapper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems='center' spacing={1}>
                  <Grid item><Subtitle text='Pólizas de seguros' medium variant='bold'/></Grid>
                  {addPatientInsuranceButton}
                </Grid>
                
              </Grid>
              <Grid item xs={12}>
                <CardWrapper>
                  <div className={classes.cardContainer}>
                    <Grid container>
                      {patient_insurances.map((item,key)=>{
                        const data2send = {...item, path:'insurancepolicy', obspath:'obsinsurancepolicy   '}
                        return(
                          <Grid item xs={12} key={key.toString()}>
                            <SinglePatientInsurance data={data2send} userType={userType}
                              patient={patient}
                              status={item.current === 1}
                              onDelete={(path) => this.onPreDeleteInsurance(item.id_patient_insurance,path)}
                              onEdit={this.onPreEditInsurance}
                              onUpload={(path) => this.onPreUploadInsuranceDocument(item.id_patient_insurance,path)}
                              onDownload={(path, filename) => this.onDownloadInsuranceDocument(item.id_patient_insurance, path, filename )}
                              onValidate={this.onValidate}
                              onValidateDocument={this.onValidateDocument}/>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </div>
                  
                </CardWrapper>
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
        
      </SimpleContainer>
      
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {id} = this.props.match.params
    this.setState({isloading:true})
    try {
      const patient = await request_patient(id)
      const {id_patient} = patient
      const _patient_insurances = await request_patient_insurances(id_patient)
      const insurance_carriers = await request_insurance_carriers()
      const policy_amounts = await request_policy_amounts()
      const medical_reports = await request_medical_reports(id_patient)
      this.setState({policy_amounts:policy_amounts, patient:patient, patient_insurances:_patient_insurances, insurance_carriers:insurance_carriers, medical_reports:medical_reports})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onValidate = async(data, status) => {
    console.log('Dispatch')
    const {id} = this.props.match.params
    const {id_patient_insurance} = data
    try { 
      const data2send = {current:status ? 1 : 0}
      const patient_insurances = await request_edit_patient_insurance(id, id_patient_insurance, data2send)
      console.log(patient_insurances)
      this.setState({patient_insurances:patient_insurances})
    } catch (error) {
      console.log(error.response)
      this.setState({error:'Hubo un error, intente de nuevo'})
    }
  }

  
  onValidateDocument = async(data, status) => {
    const {id} = this.props.match.params
    const {id_patient_insurance} = data
    try { 
      const data2send = {validated_insurance_policy:status ? 1 : 0}
      const patient_insurances = await request_edit_patient_insurance(id, id_patient_insurance, data2send)
      this.setState({patient_insurances:patient_insurances})
    } catch (error) {
      console.log(error.response)
      this.setState({error:'Hubo un error, intente de nuevo'})
    }
  }

  onValidateGenericDocument = async(data, status) => {
    const {id} = this.props.match.params
    const {validationId} = data
    try { 
      const data2send = {[validationId]:status ? 1 : 0}
      const patient_response = await request_edit_patient(id, data2send)
      this.setState({patient:patient_response})
    } catch (error) {
      console.log(error.response)
      this.setState({error:'Hubo un error, intente de nuevo'})
    }
  }

  ////////////////////////////////  MEDICAL REPORTS ///////////////////////////////////////////

  
  onAddMedicalReport = async(data) => {
    const {id} = this.props.match.params
    let data2send = {...data,patient_id:id, current:1 }
    const type = 'add_medical_report'
    try{
      this.onEditModal(type,{issending:true})
      let medical_reports = await request_add_medical_report(id,data2send)
      this.setState({medical_reports:medical_reports})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onPreEditMedicalReport = (data) => {
    this.setState({selected_medical_report:data})
    this.onOpenModal('edit_medical_report')
  }

  onEditMedicalReport = async(data) => {
    const {id} = this.props.match.params
    const {id_patient_medical_report} = this.state.selected_medical_report
    const type = 'edit_medical_report'
    try{
      this.onEditModal(type,{issending:true})
      let medical_reports = await request_edit_medical_report(id,id_patient_medical_report,data)
      this.setState({medical_reports:medical_reports})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onPreUploadMedicalReport = (selected_medical_report, path) => {
    this.setState({selected_path:path, selected_medical_report:selected_medical_report})
    this.onOpenModal('upload_medical_report_document')
  }

  onUploadMedicalReport = async(file) => {
    const {id} = this.props.match.params
    const {selected_path, selected_medical_report} = this.state
    let data2send = {ext:getFileExtension(file.name)}
    const type = 'upload_medical_report_document'
    try{
      this.onEditModal(type,{issending:true})
      const medical_reports = await request_upload_medical_report(id, selected_medical_report,selected_path,file,data2send)
      this.setState({medical_reports:medical_reports})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }
  
  onDownloadMedicalReport = async(idmedicalreport, path, filename) => {
    //const {id} = this.props.match.params
    const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
    try {
      await request_download_medical_report(idmedicalreport, path, filename)
    } catch (error) {
      this.setState({error:errorMessage})
    }
  }

  onPreDeleteMedicalReport = (idmedicalreport, path) => {
    this.setState({selected_path:path, selected_medical_report:idmedicalreport})
    this.onOpenModal('delete_medical_report_document')
  }


  onDeleteMedicalReport= async() => {
    const {id} = this.props.match.params

    const {selected_path, selected_medical_report} = this.state
    const type = 'delete_medical_report_document'
    try{
      this.onEditModal(type,{issending:true})
      let medical_reports = await request_delete_medical_report(id, selected_medical_report,selected_path)
      this.setState({medical_reports:medical_reports})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onSelectCurrentMedicalReport = async(data, status) => {
    const {id} = this.props.match.params
    const {id_patient_medical_report} = data
    try { 
      const data2send = {current:status ? 1 : 0}
      const medical_reports = await request_edit_medical_report(id, id_patient_medical_report, data2send)
      this.setState({medical_reports:medical_reports})
    } catch (error) {
      console.log(error.response)
      this.setState({error:'Hubo un error, intente de nuevo'})
    }
  }

  
  onValidateMedicalReport = async(data, status) => {
    const {id} = this.props.match.params
    const {id_patient_medical_report} = data
    try { 
      const data2send = {validated_medical_report:status ? 1 : 0}
      const medical_reports = await request_edit_medical_report(id, id_patient_medical_report, data2send)
      this.setState({medical_reports:medical_reports})
    } catch (error) {
      console.log(error.response)
      this.setState({error:'Hubo un error, intente de nuevo'})
    }
  }

  ////////////////////////////////  PATIENT INSURANCES ///////////////////////////////////////////
  
  onAddInsurance = async(data) => {
    const {id} = this.props.match.params
    let data2send = {...data,patient_id:id }
    const type = 'add_insurance_modal'

    if(data2send && data2send.balance && data2send.balance_value){
      const balanceReg = parseInt(parseFloat(data2send.balance_value)*100)
      data2send.balance_value = balanceReg.toString()
    }
    try{
      this.onEditModal(type,{issending:true})
      let patient_insurances = await request_add_patient_insurance(id,data2send)
      this.setState({patient_insurances:patient_insurances})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onEditInsurance = async(data) => {
    const {id} = this.props.match.params
    const {id_patient_insurance} = this.state.selected_insurance
    const type = 'edit_insurance_modal'
    let data2send = {...data}
    if(data2send && data2send.balance && data2send.balance_value){
      const balanceReg = parseInt(parseFloat(data2send.balance_value)*100)
      data2send.balance_value = balanceReg.toString()
    }
    try{
      this.onEditModal(type,{issending:true})
      let patient_insurances = await request_edit_patient_insurance(id,id_patient_insurance,data2send)
      console.log(patient_insurances)
      this.setState({patient_insurances:patient_insurances})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onPreEditInsurance = (data) => {
    this.setState({selected_insurance:data})
    this.onOpenModal('edit_insurance_modal')
  }

  onPreUploadInsuranceDocument = (selected_insurance, path) => {
    this.setState({selected_path:path, selected_insurance:selected_insurance})
    this.onOpenModal('upload_insurance_document')
  }

  onUploadInsuranceDocument = async(file) => {
    const {id} = this.props.match.params
    const {selected_path, selected_insurance} = this.state
    let data2send = {ext:getFileExtension(file.name)}
    const type = 'upload_insurance_document'
    try{
      this.onEditModal(type,{issending:true})
      const patient_insurances = await request_upload_insurance_document(id, selected_insurance,selected_path,file,data2send)
      this.setState({patient_insurances:patient_insurances})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onUpload = (path) => {
    this.setState({selected_path:path})
    this.onOpenModal('upload_document')
  }

  onUploadDocument = async(file) => {
    const {id} = this.props.match.params
    const {selected_path} = this.state
    let data2send = {ext:getFileExtension(file.name)}
    const type = 'upload_document'
    try{
      this.onEditModal(type,{issending:true})
      let patient = await request_upload_document(id,selected_path,file,data2send)
      this.setState({patient:patient})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onPreDelete = (path) => {
    this.setState({selected_path:path})
    this.onOpenModal('delete_document')
  }

  onPreDeleteInsurance = (selected_insurance, path) => {
    this.setState({selected_path:path, selected_insurance:selected_insurance})
    this.onOpenModal('delete_insurance_document')
  }

  onDelete = async() => {
    const {id} = this.props.match.params
    const {selected_path} = this.state
    const type = 'delete_document'
    try{
      this.onEditModal(type,{issending:true})
      const patient = await request_delete_document(id,selected_path)
      this.setState({patient:patient})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onDeleteInsuranceDocument = async() => {
    const {id} = this.props.match.params

    const {selected_path, selected_insurance} = this.state
    const type = 'delete_insurance_document'
    try{
      this.onEditModal(type,{issending:true})
      let patient_insurances = await request_delete_insurance_document(id, selected_insurance,selected_path)
      this.setState({patient_insurances:patient_insurances})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onDownload = async(path, filename) => {
    const {id} = this.props.match.params
    const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
    console.log(`Download ${filename} to ${path}`)
    try {
      await request_download_document(id, path, filename)
    } catch (error) {
      this.setState({error:errorMessage})
    }
  }


  onDownloadInsuranceDocument = async(idinsurance, path, filename) => {
    //const {id} = this.props.match.params
    const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
    console.log(`Download ${filename} to ${path}`)
    try {
      console.log(idinsurance, path)
      await request_download_insurance_document(idinsurance, path, filename)
    } catch (error) {
      this.setState({error:errorMessage})
    }
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
    
  }
}




const styles = theme => ({
  root:{

  },
  cardContainer:{
    minWidth:0
  },
  container:{
    padding:32
  },
  addIcon:{
    background:theme.palette.secondary.main,
    color:'white',
    '&:hover':{
      background:theme.palette.secondary.dark,
    }
  }
})

export default withStyles(styles)(DocumentsView)


const documentList = [
  {id:1, key:'privacy_act', obskey:'obs_privacy_act', validationId:'validated_privacy_act', title:'Aviso de privacidad', filename:'privacyact', path:'privacyact', obspath:'obsprivacyact'},
  {id:2, key:'identification', obskey:'obs_identification', validationId:'validated_identification', title:'Identificación oficial', filename:'identification', path:'identification', obspath:'obsidentification'},
  {id:4, key:'address_proof', obskey:'obs_address_proof', validationId:'validated_address_proof', title:'Comprobante de domicilio', filename:'addressproof', path:'addressproof', obspath:'obsaddressproof'},
]