export const content = {
  title:'Combinar aseguradora pública',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Confirmar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    new_public_insurance_id:{
      label:'Seleccione la aseguradora principal',
      placeholder:'Seleccione un elemento...',
    },
  },
}