import { alpha, fade, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import BenefitData from './BenefitData';

const header = [
    {id:1, label:'Producto principal'},
    {id:2, label:'Presentación Principal'},
    {id:3, label:'Tipo'},
    {id:4, label:'Diagnóstico'},
    {id:5, label:'Beneficio'},
    {id:6, label:'Ciclo Previo'},
    {id:7, label:'Ciclos'},
    {id:8, label:'Compras'},
    {id:9, label:'Beneficios'},
]

const PatientProductRow = ({data}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Table>
                <TableHead>
                    {header.map(item => {
                        return(
                            <TableCell className={classes.cell_header}
                            key={`th-pp-${item.id}`}>{item.label}</TableCell>
                        )
                    })}
                </TableHead>
                <TableBody>
                    {data.map(item => {

                        let last_use = null
                            
                        const uses = item?.use_states ?? []
                        let paid_applied = 0
                        let free_applied = 0
                        let use_state = 0
                        //let use_state = 0
                        let previous_cycle = false
                        console.log(item?.product_name, item?.use_states)
                        uses.forEach(el => {
                            if(el.previous === 1)  previous_cycle = true
                            paid_applied += el.paid_applied
                            free_applied += el.free_applied
                            use_state += el.use_state
                        })                    

                        return(
                            <TableRow key={`pp-row-${item?.id_patient_product}`}>
                                <TableCell className={classes.cell_body} style={{width:'auto', minWidth:150}}>
                                    {item?.product_name}
                                </TableCell>
                                <TableCell className={classes.cell_body} style={{width:'auto', minWidth:200}}>
                                    {item?.product_presentation}
                                </TableCell>
                                <TableCell className={classes.cell_body}>
                                    {item?.product_type}
                                </TableCell>
                                <TableCell className={classes.cell_body} style={{width:'auto', minWidth:200}}>
                                    {item?.diagnosis}
                                </TableCell>
                                <TableCell className={classes.cell_body}>
                                    <BenefitData data={item} />
                                </TableCell>
                                <TableCell className={classes.cell_body}>
                                    {previous_cycle ? 'Sí' : 'No'}
                                </TableCell>
                                <TableCell className={classes.cell_body}>
                                    {uses?.length}
                                </TableCell>
                                <TableCell className={classes.cell_body}>
                                    {paid_applied}
                                </TableCell>
                                <TableCell className={classes.cell_body}>
                                    {free_applied}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    
                </TableBody>

            </Table>
        </div>


     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:alpha('#4466F2', 0.1),
        padding:16,
        boxSizing:'border-box',
        margin:'0px 8px',
        borderRadius:12,
        marginBottom:16,
        //width:'100%',
    },
    cell_header:{
        fontWeight:600,
        color:theme.palette.primary.main,
        paddingTop:8,
        paddingBottom:16,
        width:100
    }
}))
 
export default PatientProductRow;