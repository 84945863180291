import React, { useState, useEffect } from 'react';
import { alpha, Avatar, Checkbox, fade, Grid, Icon, IconButton, makeStyles, Tooltip, withStyles } from '@material-ui/core';
import { getUseLogInfo } from '../../shared/utility';
import Caption from '../Texts/Caption';
import { BENEFIT_TYPE, USER_TYPE } from '../../variables/config';
import moment from 'moment'
import cx from 'classnames'
import InfoButton from '../Buttons/InfoButton';
import { green, grey } from '@material-ui/core/colors';
import GenericPatientLetterModal from '../../views/Admin/Applicants/ApplicantView/Modals/GenericPatientLetterModal/GenericPatientLetterModal';
import { LETTER_TYPES } from '../../shared/enums';

const SingleUse = (props) => {
    const {
        use, userType, benefitType, previous, patientproduct,
        onChangeUseDate, 
        onRegisterUse, 
        onChangeBenefitStatus,  
        onDownloadProviderLetter,
        noedit
    } = props
    const classes = useStyles()
    const [letter_modal, setLetterModal] = useState(false)

    const infoButtonData = getUseLogInfo(use)

    let label = ''
    if(use){
        
        if(use.free){
            
            label = benefitType === BENEFIT_TYPE.NORMAL ? 'Beneficio ' : `Beneficio ${use.number}`
        }else{
            label = 'Compra ' + use.number.toString()
        }
    }

    let dateText = 'No disponible'
    if(use.date){
        const auxTime = moment(use.date)
        if(auxTime.isValid())  dateText=auxTime.format('DD MMM YYYY')
    }

    let benefit_status_content = null
    let providerLetterBtn = null
    let providerLetterBtn2 = null
    let isbenefit = false

    

    if(use.free){
        isbenefit = true
        const moment_date = moment(use.date).isValid()
        if(moment_date && use.applied){
            benefit_status_content = (
                <Grid item>
                    <div className={classes.statusbtn} 
                    onClick={(userType < USER_TYPE.PRA && !noedit) ? () => onChangeBenefitStatus(use) : undefined}>
                        <Grid container alignItems='center' wrap='nowrap'>
                            <Grid item>
                                <Avatar className={classes.statusNumber}>{use.benefit_status_id}</Avatar>
                            </Grid>
                            <Grid item>
                                {use.benefit_status ? use.benefit_status : ''}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            )
            providerLetterBtn = (
                <Grid item>
                    <LightTooltip title='Descargar carta distribuidor' className={classes.tooltip} >
                        <IconButton size='small' onClick={() => onDownloadProviderLetter(use)} className={classes.letter_button}>
                            <Icon fontSize='small'>
                                local_shipping
                            </Icon>
                        </IconButton>
                    </LightTooltip>
                  
                </Grid>

                
            )
            providerLetterBtn2 = (
                <Grid item>
                    <LightTooltip title='Descargar carta distribuidor' className={classes.tooltip} >
                        <IconButton size='small' onClick={() => setLetterModal(true)} className={classes.letter_button}>
                            <Icon fontSize='small'>
                                local_shipping
                            </Icon>
                        </IconButton>
                    </LightTooltip>
                  
                </Grid>

                
            )
        }
        
    }

    //console.log(use)
    //console.log(patientproduct)

    return(
        <div className={cx({
            [classes.general_container]:true,
            [classes.benefit_container]:isbenefit
        })}>
            <GenericPatientLetterModal
                type={LETTER_TYPES.INIT_PROVIDER} use={use}
                open={letter_modal} patientproduct={patientproduct}
                onClose={() => setLetterModal(false)} noedit={noedit}
            />
            <Grid container direction='column'>
                <Grid item>
                    <Grid container alignItems='center' spacing={0} >
                        <Grid item>
                            <div style={{paddingRight:8}}>
                            <Caption text={label} variant='bold' medium/>
                            </div>
                        </Grid>
                        <Grid item>
                            <InfoButton data={infoButtonData} size='small'/>
                        </Grid>
                        {use?.countable === 1 ? (
                            <Grid item>
                                <Icon style={{fontSize:16, color:green[700], marginTop:8}}>brightness_1</Icon>
                            </Grid>
                        ):null}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems='center' wrap='nowrap'>
                        <Grid item>
                            <Grid container alignItems='center' wrap='nowrap'>
                                <Grid item>
                                    <div 
                                        className={classes.date} 
                                        onClick={() => onChangeUseDate({...use, previous:previous})}>
                                        <Caption text={dateText} medium noWrap={true}/>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Checkbox 
                                        checked={use.applied ? true : false}
                                        onChange={(event, checked) => userType !== USER_TYPE.PRA 
                                            ? onRegisterUse(use.id_use, checked) 
                                            : null}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {providerLetterBtn2}
                        {benefit_status_content}
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
        
    )
}

const useStyles = makeStyles(theme => ({
    date:{
        padding:2,
        borderRadius:16,
        '&:hover':{
          cursor:'pointer',
          background:grey[200]
        }
    },
    statusNumber:{
        background:theme.palette.primary.main,
        fontSize:12,
        width:18,
        height:18,
        marginRight:4
      },
      statusbtn:{
        fontSize:12,
        color:grey[700],
        fontWeight:500,
        borderRadius:32,
        display:'inline-block',
        padding:8,
        paddingRight:8,
        paddingLeft:8,
        whiteSpace:'nowrap',
        '&:hover':{
          background:grey[100],
          cursor:'pointer'
        }
      },
      letter_button:{
          background:theme.palette.primary.main,
          color:'white',
          //padding:4,
          '&:hover':{
            background:theme.palette.primary.dark,
          }
      },
      tooltip:{
          background:theme.palette.primary.main
      },
      general_container:{
        padding:4,
        display:'inline-block'
      },
      benefit_container:{
          border:  `1px dashed ${theme.palette.primary.main}`,
          background: alpha(theme.palette.primary.main, 0.1),
          borderRadius:4
          
      }
}))
 
export default SingleUse;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#3f51b5",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth:150,
      textAlign:'center'
    },
}))(Tooltip);