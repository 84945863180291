import { useState } from "react"
import { getFileExtension, onUpdateAuthorizationHeader } from "../shared/utility"
import { private_server } from "../variables/config"
import axios from 'axios'
import FileSaver from "file-saver"
const usePatientMedicaReport = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [medical_reports, setMedicalReports] = useState([])
    const [medical_report, setMedicalReport] = useState([])

    const actions = {
        onGetAllByPatient:async(patientid, params) => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientmedicalreport/patient/${patientid}`, 
                    {params:{...params}}
                )
                response = request.data.data.patient_medical_reports
                setMedicalReports(response)
            } catch (error) {
                setError('No se pudo obtener la lista de reportes médicos de manera correcta, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGetAllByProduct:async(patientproductid, params) => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientmedicalreport/patientproduct/${patientproductid}`, 
                    {params:{...params}}
                )
                response = request.data.data.patient_medical_reports
                setMedicalReports(response)
            } catch (error) {
                setError('No se pudo obtener la lista de reportes médicos de manera correcta, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGet:async(medicalreportid) => {
            setLoading(true)
            let response = null
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientmedicalreport/${medicalreportid}`
                )
                response = request.data.data.patient_medical_report
                setMedicalReport(response)
            } catch (error) {
                setError('No se pudo obtener el reporte médico, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onAdd:async(data) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.post(
                    `/patientmedicalreport`,
                    data
                )
                response.status = true
            } catch (error) {
                let msg = 'No se pudo crear el reporte médico, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
            }
            setLoading(false)
            return response
        },
        onUpdate:async(medicalreportid, data) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                await private_server.patch(
                    `/patientmedicalreport/${medicalreportid}`,
                    data
                )
                response.status = true
            } catch (error) {
                let msg = 'No se crear el reporte médico, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
            }
            setLoading(false)
            return response
        },
        onDelete:async() => {

        },
        onUploadDocument:async(medicalreportid, file, path) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            const data2send = {ext:getFileExtension(file.name)}
            try {
                onUpdateAuthorizationHeader(private_server)
                let response = await private_server.post(
                    `/patientmedicalreport/${medicalreportid}/${path}`,
                    data2send
                )
                const options = {headers: {'Content-Type': file.type}}
                delete axios.defaults.headers.common["Authorization"]
                await axios.put(response.data.data.path, file, options);
                
                console.log('Lleve ahasta asdfui')
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            console.log(response)
            setLoading(false)
            return response
        },
        onDownloadDocument:async(medicalreportid, path) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                let response = await private_server.get(
                    `/patientmedicalreport/${medicalreportid}/${path}`
                )
                delete axios.defaults.headers.common["Authorization"]
                response = await axios.get(response.data.data.path, {responseType: 'blob'});
                FileSaver.saveAs(response.data, `${path}`);
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            setLoading(false)
            return response
        },
        onDeleteDocument:async(medicalreportid, path) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                await private_server.delete(
                    `/patientmedicalreport/${medicalreportid}/${path}`
                )
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            setLoading(false)
            return response
        },
    }

    const system = {loading, sending,error}
    const data = {medical_reports, medical_report}

    return {system, data, actions}



} 

export default usePatientMedicaReport