import React, { useState, useEffect } from 'react';
import { USER_TYPE } from '../../../../../../variables/config';
import {
	request_doctors,
	request_doctors_by_hospital,
	request_insurance_carriers,
	request_me,
	request_products,
	request_providers,
	request_public_insurances,
} from '../../../../Applicants/AddApplicant/requests';
import { cleanDataToExcel, cleanPatientStatsByProduct, cleanPatientStatsByProductExcel, cleanProductStats, cleanProductStatsExcel } from '../../customFunctions';
import {
	request_brand_stats,
	request_patient_count,
	request_patient_count_doctor,
	request_patient_count_inactive_reason,
	request_patient_count_main_hospital,
	request_patient_count_payment_type,
	request_patient_count_status,
	request_patient_diagnosis,
	request_patient_insurance_carrier,
	request_patient_nse,
	request_patient_provider,
	request_patient_public_insurance,
	request_patient_state,
	request_patient_stats,
	request_patient_stats_by_product,
	request_patient_stats_by_product_v2,
	request_product_count,
	request_product_stats,
} from '../../requests';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { request_hospitals } from '../../../../Hospitals/Hospitals/requests';
import { getUserType, onGetHospitalCatalogs, onGetProductUserParams, sleep } from '../../../../../../shared/utility';
import { catalogs } from '../../../../../../variables/catalogs';
import moment from 'moment';
import { getDoctorsHospitalsOptions, getDoctorsOptions, getInsuranceCarriersOptions, getProductsOptions, getPublicInsurancesOptions } from '../../../../Applicants/AddApplicant/customFunctions';
import { getGenericProvidersOptions } from '../../../../../../shared/customFunctions';
import { usePlatformContext } from '../../../../../../context/PlatformContext';

const useGeneralView = ({user}) => {
	//system
	const [sending, setSending] = useState(false);
	const [loading, setLoading] = useState(false);

	const platformContext = usePlatformContext()
    const {programId} = platformContext.state


	//data
	const [product_type_id, setProductTypeID] = useState(0);
	const [brand_stats, setBrandStats] = useState([]);
	const [patient_count, setPatientCount] = useState([]);
	const [patient_status, setPatientStatus] = useState([]);
	const [patient_inactive_reason, setPatientInactiveReason] = useState([]);
	const [patient_main_hospital, setPatientMainHospital] = useState([]);
	const [patient_payment_type, setPatientPaymentType] = useState([]);
	const [patient_doctor, setPatientDoctor] = useState([]);
	const [patient_insurance_carrier, setPatientInsuranceCarrier] = useState([]);
	const [patient_public_insurance, setPatientPublicInsurance] = useState([]);
	const [patient_provider, setPatientProvider] = useState([]);
	const [patient_diagnosis, setPatientDiagnosis] = useState([]);
	const [patient_state, setPatientState] = useState([]);
	const [patient_nse, setPatientNse] = useState([]);

	const [product_count, setProductCount] = useState([]);

	const [patient_stats_by_product, setPatientStatsByProduct] = useState(cleanPatientStatsByProduct([]));
	const [product_stats, setProductStats] = useState(cleanProductStats([]));

	const [filters, setFilters] = useState({
		start_date: moment().subtract(4, 'M').format('YYYY-MM-DD'),
		end_date: moment().format('YYYY-MM-DD'),
		step: { value: 3 },
		product_type_id: null,
		patient_status_id: null, //
		inactive_reason_id: null, //
		payment_type_id: null, //
		main_hospital_id: null,
		patient_product_hospital_id: null,
		doctor_id: null,
		insurance_carrier_id: null,
		public_insurance_id: null,
		provider_id: null,
		product_id: null,
		diagnosis_id: null,
		state: null,
		benefit_type_id: null, //
		benefit_id: null, //
		treatment_frequency_id: null, //
		gender_id: null, //
		state:null,
        nse_id:null
	});
	const [masterCatalogs, setMasterCatalogs] = useState({
		steps: [...catalogs.spanish.steps],
		product_types: [...catalogs.spanish.product_types],
		patient_status: [...catalogs.spanish.patient_statuses],
		inactive_reasons: [...catalogs.spanish.inactive_reasons],
		payment_types: [...catalogs.spanish.payment_types],
		benefit_types: [...catalogs.spanish.benefit_types],
		benefits: [...catalogs.spanish.benefits, ...catalogs.spanish.benefits_roactemra],
		treatment_frequencies: [...catalogs.spanish.treatment_frequencies],
		genders: [...catalogs.spanish.genders],
		states:[...catalogs.spanish.states],
        nses:[...catalogs.spanish.nses],
		hospitals: [],
		doctors: [],
		insurance_carries: [],
		public_insurances: [],
		providers: [],
		products: [],
		diagnoses: [],
	});

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem('data'));
		if (data && user) actions.onRequestGeneralAnalytics();
	}, [filters, user, programId]);

	const actions = {
		onRequestGeneralAnalytics: async (_product_type_id) => {
			try {
				// Verify the kind of products the user needs to see.
				let products_params = {};
				setSending(true);
				const userType = getUserType()
				
				
				const _user = await request_me();
				let product_type = _product_type_id !== undefined && _product_type_id !== null ? _product_type_id : product_type_id;
				//console.log(product_type);
				if (_user) {
					switch (_user.user_type_id) {
						case USER_TYPE.ADMIN:
						case USER_TYPE.ROCHE:
							if (programId > 0 ) products_params.product_type_id = programId;
							break;
						case USER_TYPE.MERCURIO:
							products_params.product_type_id = _user.agency_id === 1 ? 1 : 2;
							product_type = _user.agency_id === 1 ? 1 : 2;
							break;
						case USER_TYPE.HOSPITAL:
							products_params.product_type_id = 1;
							product_type = 1;
							break;
						default:
					}
				}

				products_params.step = 4;

				for (const key in filters) {
					if (filters[key]) {
						products_params[key] = filters[key]?.value || filters[key];
					}
				}


				setProductTypeID(product_type);

				const productParams = onGetProductUserParams(user, programId )
				//console.log(productParams)

				products_params = {...products_params, ...productParams}

				let _hospitals = await request_hospitals();
				_hospitals = onGetHospitalCatalogs(_hospitals);
				
				let _insurance_carries = await request_insurance_carriers();
				_insurance_carries = getInsuranceCarriersOptions(_insurance_carries);
				let _public_insurances = await request_public_insurances();
				_public_insurances = getPublicInsurancesOptions(_public_insurances);
				let _products = await request_products(products_params);
				_products = getProductsOptions(_products);
				let _providers = await request_providers();
				//console.log('PROVEEDORES',_providers, getGenericProvidersOptions(_providers))
				_providers = getGenericProvidersOptions(_providers);

				//let _doctors = await request_doctors();
				//_doctors = getDoctorsOptions(_doctors);
				let _doctors = []

                if(userType !== USER_TYPE.HOSPITAL){
                    _doctors = await request_doctors()
                    _doctors = getDoctorsOptions(_doctors)
                }else{
                    _doctors = await request_doctors_by_hospital()
                    // Update all necesary catalogs
                    _doctors = getDoctorsHospitalsOptions(_doctors)            
                }

				let _catalogs = {
					...masterCatalogs,
					hospitals: [..._hospitals],
					doctors: [..._doctors],
					insurance_carries: [..._insurance_carries],
					public_insurances: [..._public_insurances],
					providers: [..._providers],
					products: [..._products],
				};

				setMasterCatalogs(_catalogs);



				let response
				try {
					response = await request_brand_stats(products_params);

						let _brands = []
						for(let i=0; i<response.length; i++){
							let item = response[i]
							if(products_params?.product_type_id > 0){
								if(item.product_type_id === products_params?.product_type_id) _brands.push(item)
							}else{
								_brands.push(item)
							}
						}
						console.log('filtered brands', _brands)
						setBrandStats(_brands);
				} catch (error) {
					
				}
				console.log(products_params)

				try{
					response = await request_patient_count(products_params);
				setPatientCount(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_count_status(products_params);
				setPatientStatus(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_count_inactive_reason(products_params);
				setPatientInactiveReason(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_count_main_hospital(products_params);
					setPatientMainHospital(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_count_payment_type(products_params);
					setPatientPaymentType(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_count_doctor(products_params);
				setPatientDoctor(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_insurance_carrier(products_params);
				setPatientInsuranceCarrier(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_public_insurance(products_params);
					setPatientPublicInsurance(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_provider(products_params);
					setPatientProvider(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_diagnosis(products_params);
					setPatientDiagnosis(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_state(products_params);
					setPatientState(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_patient_nse(products_params);
					setPatientNse(response);
				}catch(e){
					console.log(e)
				}
				try{
					response = await request_product_count(products_params);
					console.log('product stats',response)
					setProductCount(response);
				}catch(e){
					console.log(e)
				}

				try{
					response = await request_patient_stats_by_product_v2(products_params);
					console.log('Response patient_stats_by_product', response, products_params)
					response = cleanPatientStatsByProduct(JSON.parse(JSON.stringify(response)));
					setPatientStatsByProduct(response);
				}catch(e){
					console.log(e)
				}

				try{
					response = await request_product_stats(products_params);
					response = cleanProductStats(JSON.parse(JSON.stringify(response)));
					setProductStats(response);
				}catch(e){
					console.log(e)
				}

				setSending(false);
			} catch (error) {
				console.log(error);
			}
		},

		onUpdateFilters: (val) => setFilters(val),

		onChangeProductTypeFilter: async (id) => {
			await actions.onRequestGeneralAnalytics(id);
			setProductTypeID(id);
		},

		exportData2Excel: async() => {
			let headers = [];
			let exclude_keys = [];
			let total_headers = [];

			let wb = XLSX.utils.book_new();
			wb.Props = {
				Title: 'Oncopassport Analytics',
				Subject: 'General Data',
				Author: 'Trionix Technologies',
				CreatedDate: new Date(),
			};

			wb.SheetNames.push('B. por Marcas');
			headers = ['Marca', 'Beneficiarios', 'Compras', 'Beneficios por producto', 'Porcentaje'];
			exclude_keys = ['product_type_id','brand_id', 'brand_logo'];
			let ws_data_brands = cleanDataToExcel(brand_stats, headers, exclude_keys, 'patient_count');

			let totalboughts = 0
			let totalbenefitproducts = 0
			let totalpercentage = 0

			for(let i = 1; i<ws_data_brands.length-1; i++){
				const row = ws_data_brands[i]
				totalboughts+= parseFloat(row[2])
				totalbenefitproducts+= parseFloat(row[3])
				totalpercentage+= parseFloat(row[4])
			}
			ws_data_brands[ws_data_brands.length-1][2] = totalboughts
			ws_data_brands[ws_data_brands.length-1][3] = totalbenefitproducts
			ws_data_brands[ws_data_brands.length-1][4] = totalpercentage

			let ws_brands = XLSX.utils.aoa_to_sheet(ws_data_brands);
			wb.Sheets['B. por Marcas'] = ws_brands;

			

			wb.SheetNames.push('Beneficiarios históricos');
			if (filters.step.value !== 3) headers = ['Fecha', 'Beneficiarios', 'Porcentaje'];
			else headers = ['Año', 'Mes', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = [];
			let ws_data_patient_count = cleanDataToExcel(patient_count, headers, exclude_keys, 'patient_count', filters.step.value !== 3 ? null : ['', 'Totales']);
			let ws_patient_count = XLSX.utils.aoa_to_sheet(ws_data_patient_count);
			wb.Sheets['Beneficiarios históricos'] = ws_patient_count;

			wb.SheetNames.push('B. por Estatus');
			headers = ['Estatus', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['patient_status_id'];
			let ws_data_patient_status = cleanDataToExcel(patient_status, headers, exclude_keys, 'patient_count');
			let ws_patient_status = XLSX.utils.aoa_to_sheet(ws_data_patient_status);
			wb.Sheets['B. por Estatus'] = ws_patient_status;

			wb.SheetNames.push('B. por Motivo de inactividad');
			headers = ['Motivo', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['inactive_reason_id'];
			let ws_data_patient_inactive_reason = cleanDataToExcel(patient_inactive_reason, headers, exclude_keys, 'patient_count');
			let ws_patient_inactive_reason = XLSX.utils.aoa_to_sheet(ws_data_patient_inactive_reason);
			wb.Sheets['B. por Motivo de inactividad'] = ws_patient_inactive_reason;

			wb.SheetNames.push('B. por Hospital de consulta');
			headers = ['Hospital', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['hospital_id'];
			let ws_data_patient_main_hospital = cleanDataToExcel(patient_main_hospital, headers, exclude_keys, 'patient_count');

			let totalbenefitshospital = 0
			let totalpercentagehospital = 0

			for(let i = 1; i<ws_data_patient_main_hospital.length-1; i++){
				const row = ws_data_patient_main_hospital[i]
				totalbenefitshospital+= parseFloat(row[1])
				totalpercentagehospital+= parseFloat(row[2])
			}
			ws_data_patient_main_hospital[ws_data_patient_main_hospital.length-1][1] = totalbenefitshospital
			ws_data_patient_main_hospital[ws_data_patient_main_hospital.length-1][2] = totalpercentagehospital

			let ws_patient_main_hospital = XLSX.utils.aoa_to_sheet(ws_data_patient_main_hospital);
			wb.Sheets['B. por Hospital de consulta'] = ws_patient_main_hospital;

			wb.SheetNames.push('B. por Forma de pago');
			headers = ['Tipo de pago', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['payment_type_id'];
			let ws_data_patient_payment_type = cleanDataToExcel(patient_payment_type, headers, exclude_keys, 'patient_count');
			let ws_patient_payment_type = XLSX.utils.aoa_to_sheet(ws_data_patient_payment_type);
			wb.Sheets['B. por Forma de pago'] = ws_patient_payment_type;

			wb.SheetNames.push('B. por Doctor');
			headers = ['Doctor', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['doctor_id'];
			let ws_data_patient_doctor = cleanDataToExcel(patient_doctor, headers, exclude_keys, 'patient_count');
			let ws_patient_doctor = XLSX.utils.aoa_to_sheet(ws_data_patient_doctor);
			wb.Sheets['B. por Doctor'] = ws_patient_doctor;
			await sleep(50)
			wb.SheetNames.push('B. por Aseguradora');
			headers = ['Aseguradora', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['insurance_carrier_id'];
			let ws_data_patient_insurance_carrier = cleanDataToExcel(patient_insurance_carrier, headers, exclude_keys, 'patient_count');
			let ws_patient_insurance_carrier = XLSX.utils.aoa_to_sheet(ws_data_patient_insurance_carrier);
			
			wb.Sheets['B. por Aseguradora'] = ws_patient_insurance_carrier;
			await sleep(50)
			wb.SheetNames.push('B. por Aseguradora pública');
			headers = ['Aseguradora', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['public_insurance_id'];
			let ws_data_patient_public_insurance = cleanDataToExcel(patient_public_insurance, headers, exclude_keys, 'patient_count', undefined, false);
			let ws_patient_public_insurance = XLSX.utils.aoa_to_sheet(ws_data_patient_public_insurance);
			//console.log(wb.Sheets['Aseguradora'])
			wb.Sheets['B. por Aseguradora pública'] = ws_patient_public_insurance;
			await sleep(50)
			wb.SheetNames.push('B. por Distribuidor');
			headers = ['Distribuidor', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['provider_id'];
			let ws_data_patient_provider = cleanDataToExcel(patient_provider, headers, exclude_keys, 'patient_count');
			let ws_patient_provider = XLSX.utils.aoa_to_sheet(ws_data_patient_provider);
			wb.Sheets['B. por Distribuidor'] = ws_patient_provider;
			await sleep(50)
			wb.SheetNames.push('B. por Producto');
			let ws_patient_stats_by_product = XLSX.utils.aoa_to_sheet(patient_stats_by_product.table);
			wb.Sheets['B. por Producto'] = ws_patient_stats_by_product;
			await sleep(50)
			wb.SheetNames.push('B. por Diagnóstico');
			headers = ['Diagnóstico', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['diagnosis_id'];
			let ws_data_patient_diagnosis = cleanDataToExcel(patient_diagnosis, headers, exclude_keys, 'patient_count');
			let ws_patient_diagnosis = XLSX.utils.aoa_to_sheet(ws_data_patient_diagnosis);
			wb.Sheets['B. por Diagnóstico'] = ws_patient_diagnosis;
			await sleep(50)
			wb.SheetNames.push('B. por Estado');
			headers = ['Estado', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = [''];
			let ws_data_patient_state = cleanDataToExcel(patient_state, headers, exclude_keys, 'patient_count');
			let ws_patient_state = XLSX.utils.aoa_to_sheet(ws_data_patient_state);
			wb.Sheets['B. por Estado'] = ws_patient_state;
			await sleep(50)
			wb.SheetNames.push('B. por Nivel socioeconómico');
			headers = ['Nivel socioeconómico', 'Beneficiarios', 'Porcentaje'];
			exclude_keys = ['nse_id'];
			let ws_data_patient_nse = cleanDataToExcel(patient_nse, headers, exclude_keys, 'patient_count');
			let ws_patient_nse = XLSX.utils.aoa_to_sheet(ws_data_patient_nse);
			wb.Sheets['B. por Nivel socioeconómico'] = ws_patient_nse;
			await sleep(50)
			wb.SheetNames.push("B. por Estadísticas Producto");
    		let ws_data_product_stats = [[...product_stats.header],...product_stats.data]
    		let ws_product_stats = XLSX.utils.aoa_to_sheet(ws_data_product_stats)
    		wb.Sheets["B. por Estadísticas Producto"] = ws_product_stats;
			await sleep(50)
			wb.SheetNames.push('B. por Entrega de productos');
			if (filters.step.value !== 3) headers = ['Fecha', 'Ventas', 'Beneficios', 'Porcentaje'];
			else headers = ['Año', 'Mes', 'Ventas', 'Beneficios', 'Porcentaje'];
			exclude_keys = [];
			let ws_data_product_count = cleanDataToExcel(product_count, headers, exclude_keys, 'sold_units', filters.step.value !== 3 ? null : ['', 'Totales']);

			let totalsales = 0
			let totalbenefitsprods = 0
			let totalpercentageprods = 0

			for(let i = 1; i<ws_data_product_count.length-1; i++){
				const row = ws_data_product_count[i]
				totalsales+= parseFloat(row[2])
				totalbenefitsprods+= parseFloat(row[3])
				totalpercentageprods+= parseFloat(row[4])

			}
			ws_data_product_count[ws_data_product_count.length-1][2] = totalsales
			ws_data_product_count[ws_data_product_count.length-1][3] = totalbenefitsprods
			ws_data_product_count[ws_data_product_count.length-1][4] = totalpercentageprods


			let ws_product_count = XLSX.utils.aoa_to_sheet(ws_data_product_count);
			wb.Sheets['B. por Entrega de productos'] = ws_product_count;
			await sleep(50)
			let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
			FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'analytics.xlsx');
		},
	};

	const data = {
		brand_stats,
		product_type_id,
		patient_count,
		patient_status,
		patient_inactive_reason,
		patient_main_hospital,
		patient_payment_type,
		patient_doctor,
		patient_insurance_carrier,
		patient_public_insurance,
		patient_provider,
		patient_diagnosis,
		patient_state,
		patient_nse,
		product_count,
		patient_stats_by_product,
		product_stats,
		filters,
		masterCatalogs,
	};
	const system = { sending };
	return { data, actions, system };
};

const s2ab = (s) => {
	var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	var view = new Uint8Array(buf); //create uint8array as viewer
	for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
	return buf;
};

export default useGeneralView;
