import React, {useState} from 'react'
import SimpleDialog from '../../../../../../../components/Modals/SimpleDialog'
import { Icon, makeStyles, Grid, Button } from '@material-ui/core'
import Subtitle from '../../../../../../../components/Texts/Subtitle'
import ContentText from '../../../../../../../components/Texts/ContentText'
import { green, amber } from '@material-ui/core/colors'
import { request_edit_patient_product } from '../../../requests'

const useStyles = makeStyles(theme => ({
    icon:{
        color:theme.palette.secondary.main,
        fontSize:64
    },
    btnContainer:{marginTop:16},
    btnSuspend:{
        textTransform:'none',
        borderColor:amber[700],
        color:amber[700],
        '&:hover':{
            background:amber[50]
        }
    },
    btnFinish:{
        textTransform:'none',
        borderColor:green[700],
        color:green[700],
        '&:hover':{
            background:green[50]
        }
    }
}))

const FinishTreatmentModal = props => {
    const classes = useStyles()
    const {onClose, onUpdateData, patientproduct, match} = props
    console.log(patientproduct)

    const [issending, setIssending] = useState(false)
    const [error, setError] = useState(null)

    const onFinishTreatment = async() => {
        const {id} = match.params
        const {id_patient_product} = patientproduct
        let data2send = {product_status_id:2}
        setIssending(true)
        try{
            let patientproducts = await request_edit_patient_product(id,id_patient_product,data2send)
            onUpdateData(patientproducts)
        }catch(error){
            console.log(error.response)
            if(error.response){
                setError(error.response.data.error.message)
            }
        }
        setIssending(false)

    }

    const onSuspendTreatment = async() => {
        const {id} = match.params
        const {id_patient_product} = patientproduct
        let data2send = {product_status_id:3}
        setIssending(true)
        try{
            let patientproducts = await request_edit_patient_product(id,id_patient_product,data2send)
            onUpdateData(patientproducts)
        }catch(error){
            console.log(error.response)
            if(error.response){
                setError(error.response.data.error.message)
            }
        }
        setIssending(false)
    }


    const data = {issending:issending, error:error}

    return(
        <SimpleDialog data={data} onClose={onClose} maxWidth='xs' hideSubmitBtn>
            <Grid container alignItems='center' direction='column'>
                <Grid item>
                    <Icon className={classes.icon}>error_outline</Icon>
                </Grid>
                <Grid item>
                    <Subtitle text='¿Está seguro?' variant='bold' medium/>
                </Grid>
                <Grid item>
                    <ContentText text='¿Qué acción desea realizar?'/>
                </Grid>
                <Grid item>
                    <div className={classes.btnContainer}>
                        <Grid container spacing={1}>
                            <Grid item><Button onClick={onSuspendTreatment} variant='outlined' className={classes.btnSuspend}> Suspender </Button></Grid>
                            <Grid item><Button onClick={ onFinishTreatment} variant='outlined' className={classes.btnFinish}> Finalizar </Button></Grid>
                        </Grid>
                    </div>
                    
                </Grid>
            </Grid>
        </SimpleDialog>
    )
}

export default FinishTreatmentModal