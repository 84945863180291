export const formData = {
  sectionA:{
    name:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    crm_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'crm_id',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    uci:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'uci',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
  },
}