import { USER_TYPE, PATIENT_STATUS, APPROVAL_STATUS } from '../../../../../../../variables/config'

export const constrainst_toolbar = {
    adjust_cycles:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
        bystatus:[
            PATIENT_STATUS.ACTIVE
        ],
    },
    recalculate_benefit:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
        bystatus:[
            PATIENT_STATUS.PROSPECT,
            PATIENT_STATUS.IN_PROCESS,
        ],
    }
}

export const constrainst_actionbuttons = {

    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    }
}


export const constrainst_patientproduct= {
    add_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    },
    add_previous_cycle:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL,
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    manual_pending_uses:{
        
    },
    edit_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    },
    add_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    }
}