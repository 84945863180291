import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { Grid } from '@material-ui/core';
import Subtitle from '../../../../../components/Texts/Subtitle';
import { blue } from '@material-ui/core/colors';
import InactivePatientStatsTable from './InactivePatientStatsTable';
import InactivePatientStatsChart from './InactivePatientStatsChart';

class InactivePatientStats extends Component {
  render(){

    const {data} = this.props

    return(
      <CardWrapper>
        <div style={{padding:32}}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <Subtitle medium text='Estadísticas Beneficiarios inactivos' color={blue[900]}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <InactivePatientStatsTable data={data.table}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <InactivePatientStatsChart data={data.chartTable}/>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }
}

export default InactivePatientStats