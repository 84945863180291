export const formaData = {
    insurance_carrier_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'insurance_carrier_id',
            type:'select',
            fullWidth: true,
            label:'Nombre',
            placeholder:'Seleccione una opción',
        },
        rules:{
          type:'none',
        }
    },
    policy_amount_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'policy_amount_id',
            type:'select',
            fullWidth: true,
            label:'Monto',
            placeholder:'Seleccione una opción',
            
        },
        rules:{
            type:'none',
        }
    },
    policy_expiry_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'policy_expiry_date',
            type:'date',
            fullWidth: true,
            label:'Expiración',
            placeholder:'',
        },
        rules:{
            type:'date',
        }
    },
    balance_value:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'balance_value',
            type:'number',
            fullWidth: true,
            label:'Cantidad de Saldo',
            placeholder:'Cantidad de Saldo',
        },
        rules:{
            type:'numeric',
            min:0,
            max:999999999999
        }
    },
}