import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { alpha, fade } from '@material-ui/core/styles';
import cx from 'classnames'
import ContentText from '../Texts/ContentText';
import { palette } from '../../variables/config';


class SmallNavigation extends Component {
  render(){

    const {classes, onChange, options, selected} = this.props

    return(
      <div style={{borderRadius:16}}>
        <Grid container alignItems='center'>
          {options.map((item,key)=>{
            
            const isSelected = selected === item.id

            return(
              <Grid item key={key.toString()}>
                <div 
                  onClick={() => onChange(item.id)}
                  className={cx({
                  [classes.button]:true,
                  [classes.firstButton]:key===0,
                  [classes.lastButton]:key===(options.length-1),
                  [classes.selectedButton]:isSelected
                })}>
                  <div className={classes.text}>
                    <Typography 
                      align='center' 
                      variant='body2' 
                      style={{fontSize:'0.875rem', color: isSelected ? 'white' : palette.primary.main}}
                    >
                      {item.label}
                    </Typography>
                    {/*<ContentText 
                      text={item.label}  
                      medium={isSelected}
                      color={isSelected ? 'white' : palette.primary.main } 
              align='center'/>*/}
                  </div>
                  
                </div>
               </Grid> 
            )
          })}
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  root:{},
  button:{
    padding:16,
    paddingTop:8,
    paddingBottom:8,
    background: alpha('#FFF',0.5),
    boxSizing:'border-box',
    height:'100%',
    minWidth:120,
    maxWidth:140,
    //fontSize:'0.875rem',
    height:56,
    position:'relative',
    '&:hover':{
      cursor:'pointer'
    }
  },
  firstButton:{
    borderTopLeftRadius:8,
    borderBottomLeftRadius:8,
  },
  lastButton:{
    borderTopRightRadius:8,
    borderBottomRightRadius:8,
  },
  text:{
    //width:'100%',
    //height:'100%',
    padding:'0px 16px',
    boxSizing:'border-box',
    top:'50%', left:'50%',
    position:'absolute',
    transform:'translate(-50%, -50%)'
  },
  selectedButton:{
    background:theme.palette.primary.main,
    padding:10,
    borderRadius:4,
    boxShadow:'0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  }
})

export default withStyles(styles)(SmallNavigation)