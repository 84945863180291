import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../../variables/texts'
import HorizontalDataWrapper from '../../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../../components/Texts/ContentText'
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_insurance as constrainst} from  '../constraints'
import { onGetDateFormat } from '../../../../../../shared/utility'

const InsuranceContainer = props => {

    const {data} = props

    //console.log(data)

    let insuranceContent = null

    if(data){
        const {payment_type_id} = data
        
        switch (payment_type_id) {
            case 1:
            case 2:
            case 3:

                const expDate = onGetDateFormat(data ? data.policy_expiry_date : null)
                let balanceContent = null

                let balance_value_number = 0.00
                console.log(data.balance_value)
                if(data && data.balance_value !== null && data.balance_value !== undefined){
                    balance_value_number = parseFloat(data.balance_value )
                }

                if(data && data.balance){
                    balanceContent = (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <ContraintLayout constrainst={constrainst.payment_type}>
                                    <HorizontalDataWrapper 
                                        label='Saldo' text={'Sí'}/>
                                </ContraintLayout>
                                <ContraintLayout constrainst={constrainst.payment_type}>
                                    <HorizontalDataWrapper 
                                        label='Monto' text={balance_value_number}/>
                                </ContraintLayout>
                            </Grid>
                        </Grid>
                    )
                }

                insuranceContent = (
                    <Grid container spacing={2}>
                        <ContraintLayout constrainst={constrainst.payment_type}>
                            <HorizontalDataWrapper 
                                label='Forma de pago' text={data ? data.payment_type : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.insurance_carrier}>
                            <HorizontalDataWrapper 
                                label='Aseguradora' text={data ? data.insurance_carrier : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.policy_amount}>
                            <HorizontalDataWrapper 
                                label='Póliza' text={data ? data.policy_amount : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.policy_value_name}>
                            <HorizontalDataWrapper 
                                label='Nivel' text={data ? data.policy_value_name : nodata}/>
                        </ContraintLayout>
                        {balanceContent}
                        <ContraintLayout constrainst={constrainst.policy_expiry_date}>
                            <HorizontalDataWrapper 
                                label='Expiración' text={expDate}/>
                        </ContraintLayout>
                    </Grid>
                )
                break;
            case 2:
                insuranceContent = (
                    <Grid container spacing={2} style={{marginBottom:16}}>
                        <ContraintLayout constrainst={constrainst.payment_type}>
                            <HorizontalDataWrapper 
                                label='Forma de pago' text={data ? data.payment_type : nodata}/>
                        </ContraintLayout>
                        {/*<ContraintLayout constrainst={constrainst.policy_amount}>
                            <HorizontalDataWrapper 
                                label='Póliza' text={data ? data.policy_amount : nodata}/>
                </ContraintLayout>*/}
                        <ContraintLayout constrainst={constrainst.policy_value_name}>
                            <HorizontalDataWrapper 
                                label='Nivel' text={data ? data.policy_value_name : nodata}/>
                        </ContraintLayout>
                    </Grid>
                )
                break
            default:
                break;
        }
    }
   
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ContentText 
                        text='Información de las aseguradora' 
                        medium variant='bold'/>
                </Grid>
                <Grid item xs={12}>
                    {insuranceContent}
                </Grid>
            </Grid>
        </div>
    )
}

export default InsuranceContainer