import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,Text
} from 'recharts'
import { blue, grey } from '@material-ui/core/colors';
import { Grid } from '@material-ui/core';

const styles = (theme) => ({
  root:{},
  root2:{overflowX: 'auto', margin:'auto', paddingTop:32}
})


const CustomizedAxisTick = props => {
    const {x, y, payload} = props;
   	return <Text x={x} y={y} width={40} angle={-90} textAnchor="end" verticalAnchor="start" fontSize={12} fontFamily={"Poppins"} >{payload.value}</Text>
  
}



class ProductPatientStatsChart extends Component {
  render(){

    const {classes, data} = this.props

    return(
      <div className={classes.root}>
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            <BarChart width={1600} height={500} data={data}
                margin={{top: 20, right: 30, left: 20, bottom: 5}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis interval={0} dataKey="name" angle={-90} textAnchor="end" height={150} tick={<CustomizedAxisTick />}/>
              <YAxis/>
              <Tooltip/>
              <Legend />
              <Bar dataKey="Activos" stackId="a" fill={blue[900]} />
              <Bar dataKey="Progreso" stackId="a" fill={blue[600]} />
              <Bar dataKey="Inactivos" stackId="a" fill={grey[400]} />
              <Bar dataKey="Prospectos" stackId="a" fill={blue[200]} />
            </BarChart>
          </Grid>
        </Grid>
          
      </div>
    )
  }
}

export default withStyles(styles)(ProductPatientStatsChart)