import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Chart from './Chart';
import Subtitle from '../../Texts/Subtitle';
import { amber, blue, cyan, deepOrange, green, grey, purple } from '@material-ui/core/colors';
import { useEffect } from 'react';
import { useState } from 'react';

const AreaScatterChart = ({ data_area, data_scatter, title, step, domainY }) => {
	const classes = useStyles();
	//console.log(data_area)
	//console.log(data_scatter)
	return (
		<Grid container className={classes.root} spacing={2}>
			<Grid item xs={12}>
				<Subtitle medium text={title} color={blue[900]}/>
			</Grid>
			<Grid item xs={12}>
				<Chart step={step} data_area={data_area} data_scatter={data_scatter} domainY={domainY} />
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 25,
	},
}));

export default AreaScatterChart;
