export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    views:[
      {id:1, name:'Consetimiento'},
      {id:2, name:'Datos Principales'},
      {id:3, name:'Hospital'},
      {id:4, name:'Aseguradora'},
      {id:5, name:'Propuesta'},
    ],
    layout:{
      title:'Agregar Beneficiario',
      navigation:[
        {name:'Beneficiarios',path:'/patients'},
        {name:'Agregar',path:'/patients/add'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[
      {id:0, label:'ID', url:'externalid'},
      {id:1, label:'Hospital', url:'doctor'},
      {id:2, label:'Aseguradora', url:'insurancecarrier'},
      {id:3, label:'Tipo', url:'type'},
      {id:null, label:'Sin Filtro', url:null},
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID','Hospital','Aseguradora','NSE', 'Estatus','Oferta','Carta','Beneficio']
  }
}