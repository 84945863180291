import {mainServer} from '../../../../variables/config'

export const request_logs = async (payload) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/log"+payload)
  return response.data.data
}

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/all")
  return response.data.data.users
}

export const request_delete_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/users/"+id)
  let response
  response = await mainServer.instance.get("/users/type/1")
  return response.data.data
}

export const request_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}


export const request_patient_product = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/patientproduct/"+id);
  return response.data.data.patient_product
}

export const request_doctor = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/doctor/"+id);
  return response.data.data.doctor
}

export const request_patient = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/patient/"+id);
  return response.data.data.patient
}

export const request_hospital = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/hospital/"+id);
  return response.data.data.hospital
}

export const request_insurance_carrier = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/insurancecarrier/"+id);
  return response.data.data.insurance_carrier
}


export const request_public_insurance = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/publicinsurance/"+id);
  return response.data.data.public_insurance
}

export const request_approval_contacts = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/approvalcontact/"+id)
  return response.data.data.approval_contact
}

export const request_provider = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/provider/"+id);
  return response.data.data.provider
}

export const request_cycle = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/cycle/"+id);
  return response.data.data.cycle
}

export const request_use = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/use/"+id);
  return response.data.data.use
}





