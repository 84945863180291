import { Grid, Icon, IconButton } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import ContentText from '../../../../../components/Texts/ContentText';
import TreatmentSwitchInput from '../../../../../components/Inputs/TreatmentSwitchInput/TreatmentSwitchInput';
import MultipleVias from './MultitpleVias/MultitpleVias';
import CheckboxInput from '../../../../../components/Inputs/CheckboxInput/CheckboxInput';
import useOnboardingContext from '../../../../../providers/OnboardingProvider/useOnboardingContext';
import useAppCatalogs from '../../../../../hooks/api/useAppCatalogs';
import { getDiagnosticsOptions, getSchemesOptions } from '../../../../../shared/customFunctions';


const TreatmentForm = ({
    form, 
    vials, 
    onInputChange, 
    position, 
    products, 
    onUpdateVial, 
    onMoveVial,
    schemes,
    diagnostics,
    hospitals
}) => {

    const {treatmentManager} = useOnboardingContext()
    const catalogManager = useAppCatalogs()

    const onUpdateLocalSchemes = useCallback(async(product) => {
        let schemes = []
        try {
            let _request = await catalogManager.onGetSchemesbyProduct({productid:product?.value})
            schemes = getSchemesOptions(_request)
        } catch (error) {
            console.log(error)
        }
        let diagnostics = []
        try {
            let _requestDiag = await catalogManager.onGetDiagnosticsbyProduct({productid:product?.value})
            diagnostics = getDiagnosticsOptions(_requestDiag)
        } catch (error) {
            console.log(error)
        }
        treatmentManager.onUpdateCatalogs(position-1, schemes, diagnostics)
    }, [position, form, treatmentManager])

    useEffect(() => {
        const _product = products.find(el => el.id == form.product_id.value)
        if(_product){
            onUpdateLocalSchemes(_product)
        }
    }, [form.product_id])

    const onAutoFillSchemeData = useCallback((data) => {
        console.log("Autofilldata", data)
        let expected_uses = {...form.expected_uses}
        let vial_number = {...form.vial_number}
        expected_uses.value = data?.cycles
        expected_uses.isValid = true
        vial_number.value = data?.vials
        vial_number.isValid = true

        treatmentManager.actions.onMultipleInputChange({expected_uses, vial_number}, position-1)

    }, [position, form, treatmentManager])

    useEffect(() => {
        const _scheme = schemes.find(el => el.id == form.scheme_id.value)
        if(_scheme){
            onAutoFillSchemeData(_scheme)
        }
    }, [form.scheme_id])



    return ( 
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <ContentText
                                text={ `Tratamiento ${position}`} 
                                variant='bold'
                                medium
                            />
                        </Grid>
                        <Grid item >
                            <IconButton size='small'><Icon fontSize='small'>delete</Icon></IconButton>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        
                        <Grid item xs={12}>
                            <Grid container justifyContent='center'>
                                <Grid item>
                                    <TreatmentSwitchInput 
                                        data={form.multiple_piece}
                                        onChange={onInputChange}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        {form.multiple_piece.value === 1 ? <Grid item xs={12}>
                            <LineInputText 
                                data={form.dose}
                                onChange={onInputChange}
                            />
                        </Grid> : null}
                        {form.multiple_piece.value === 0 
                        ? (
                            <Grid item xs={12}>
                                <LineInputSelect
                                    data={form.product_id}
                                    onChange={onInputChange}
                                    catalogs={products}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <MultipleVias 
                                    vials={vials??[]} 
                                    products={products??[]} 
                                    onUpdateVial={onUpdateVial}
                                    onMoveVial={onMoveVial}/>
                            </Grid>
                        )}
                        
                            
                        <Grid item xs={12}>
                            <LineInputSelect 
                                data={form.diagnosis_id}
                                onChange={onInputChange}
                                catalogs={diagnostics}
                            />
                        </Grid>
                        {schemes.length > 0 && <Grid item xs={12}>
                            <LineInputSelect 
                                data={form.scheme_id}
                                onChange={onInputChange}
                                catalogs={schemes}
                            />
                        </Grid>}
                        <Grid item xs={12}>
                            <LineInputSelect 
                                data={form.hospital_id}
                                onChange={onInputChange}
                                catalogs={hospitals}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LineInputText
                                data={form.expected_uses}
                                onChange={onInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LineInputText
                                data={form.vial_number}
                                onChange={onInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LineInputSelect 
                                data={form.treatment_frequency_id}
                                onChange={onInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CheckboxInput 
                                data={form.adjusted}
                                onChange={onInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default TreatmentForm;