import { USER_TYPE, PATIENT_STATUS, APPROVAL_STATUS } from "../../../../../../variables/config";

export const constrainst_notes = {
    add_note:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    },
    delete_note:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    }
}

export const constrainst_actionbuttons = {
    doctor_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    edit_button:{ // 4
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }
}