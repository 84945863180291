import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../components/Buttons/ActionModalBar';
import LineInputSelect from '../../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import SimpleModalV2 from '../../../../../../components/Modals/SimpleModalV2';
import usePatient from '../../../../../../hooks/usePatient';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../../shared/utility';
import { catalogs } from '../../../../../../variables/catalogs';
import { PATIENT_STATUS } from '../../../../../../variables/config';


const InactiveReasonModal = (props) => {

    const {open, onClose, patient, onUpdatePatient} = props
    const patientManager = usePatient()
    
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const onInputChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {
        setSending(true)
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setSending(false)
            return
        }
        //Extract data to send use
        let data2send, result

        data2send = onGetFormData(form)
        data2send.status_id = PATIENT_STATUS.INACTIVE

        try {
            await patientManager.actions.onUpdate(patient?.id_patient, data2send)
            const _patient = await patientManager.actions.onGet(patient?.id_patient)
            onUpdatePatient(_patient)

            console.log(data2send, patient)
        } catch (error) {
            console.log(error.response)
            setError('No se pudo actualizar la información, intente de nuevo')
        }

        

        

        setSending(false)
        onClose()

    }


    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <div>
                <Typography variant='h6'>Motivo de inactividad</Typography>
                <Typography variant='body2' style={{marginTop:16}}>Seleccione el motivo por el cual se va a desactivar este paciente</Typography>
                <div style={{marginTop:16}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <LineInputSelect
                                wrap='vertical'
                                data={form.inactive_reason_id}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar 
                                btnLabel={'Guardar'}
                                error={error}
                                onCloseError={() => setError(null)}
                                loading={sending}
                                onSubmit={onSubmit}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModalV2>
     );
}
 
export default InactiveReasonModal;

const form_data = {
    inactive_reason_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.spanish.inactive_reasons],
        config:{
          id:'inactive_reason_id',
          type:'select',
          fullWidth: true,
          label:'Motivo de inactividad',
          placeholder:'Motivo de inactividad',
          helper:'Este campo es requerido'
        },
        rules:{
          type:'none',
        }
      },
}