export const formData = {
  sectionA:{
    name:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    email:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'email',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'email',
      }
    },
  },
}