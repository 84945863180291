export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      title:'Analíticos',
      navigation:[
        {name:'Analíticos',path:'/analytics'},
      ],
      menu:[
        {id:1, label:'General'},
        {id:2, label:'Beneficiarios'},
        {id:3, label:'Beneficios'},
      ],
      
    },
    filters:[ 
      {id:null, label:'Sin Filtro', url:null},
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID','Asesor','Evento']
  }
}