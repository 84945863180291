export const contentData = {
  spanish:{
    layout:{
      title:'Información del doctor',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Doctores',path:'/catalogs/doctors'},
        {name:'Detalles',path:'/catalogs/doctors/:id'}
      ]
    },
  }
}
