import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import UploadMedicalReportDocument from '../../Modals/MedicalReports/UploadMedicalReportDocument';
import DeleteMedicalReportDocument from '../../Modals/MedicalReports/DeleteMedicalReportDocument';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import usePersonalDocumentView from './usePersonalDocumentView';
import { getUserType } from '../../../../../../shared/utility';
import PersonalDocumentList from '../../components/PersonalDocumentList';
import { USER_TYPE } from '../../../../../../variables/config';
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout';
import PersonalDocumentModal from '../../Modals/PersonalDocuments/PersonalDocumentModal/PersonalDocumentModal';
import UploadPersonalDocument from '../../Modals/PersonalDocuments/UploadPersonalDocument';
import DeletePersonalDocumentModal from '../../Modals/PersonalDocuments/DeletePersonalDocumentModal';

const PersonalDocumentView = () => {

    const classes = useStyles()

    const {actions, system, documentManager, modals, data} = usePersonalDocumentView()
    const {documents} = documentManager.data
    const {patient, document} = data
    const userType = getUserType()

    let addDocumentButton = (
        <ContraintLayout constrainst={constrainst.add_button} auto>
            <IconButton size='small' className={classes.addIcon}
            onClick={() => actions.onChangeModalStatus('add_document', true)}>
                <Icon fontSize='small'>add</Icon>
            </IconButton>
        </ContraintLayout>
    )

    return ( 
        <div className={classes.root}>
            <PersonalDocumentModal mode='add'
                open={modals.add_document} 
                patient_id={patient?.id_patient}
                onAddData={documentManager.actions.onAdd}
                onRefreshData={documentManager.actions.onGetAllByPatient}
                onClose={() => actions.onChangeModalStatus('add_document', false)}
            />
            <PersonalDocumentModal mode='edit'
                open={modals.edit_document} origin={document}
                patient_id={patient?.id_patient}
                onUpdateData={documentManager.actions.onUpdate}
                onRefreshData={documentManager.actions.onGetAllByPatient}
                onClose={() => actions.onChangeModalStatus('edit_document', false)}
            />
            <UploadPersonalDocument 
                open={modals.upload_document} origin={document} path={data.documentPath}
                patient_id={patient?.id_patient}
                onUpdateDocument={documentManager.actions.onUploadDocument}
                onRefreshData={documentManager.actions.onGetAllByPatient}
                onClose={() => actions.onChangeModalStatus('upload_document', false)}
            />
            <DeletePersonalDocumentModal 
                open={modals.delete_document} origin={document} path={data.documentPath}
                patient_id={patient?.id_patient}
                onDeleteDocument={documentManager.actions.onDeleteDocument}
                onRefreshData={documentManager.actions.onGetAllByPatient}
                onClose={() => actions.onChangeModalStatus('delete_document', false)}
            />
            <div>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Typography variant='h6'>
                            Documentos
                        </Typography>
                    </Grid>
                    {addDocumentButton}
                </Grid>
            </div>
            
            <div style={{marginTop:16}}>
                <SimpleContainer loading={system.loading}>
                    <PersonalDocumentList 
                        documents={documents} 
                        userType={userType} 
                        onEditPersonalDocument={actions.onEditPersonalDocument}  
                        onUploadDocument={actions.onUploadDocument}
                        onDeleteDocument={actions.onDeleteDocument}
                        onDownloadDocument={actions.onDownloadDocument}

                    />
                </SimpleContainer>
                
            </div>
            

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    addIcon:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
          background:theme.palette.primary.dark,
        }
    }
}))
 
export default PersonalDocumentView;

const  constrainst = {
    validation:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO
        ],
    },
    add_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    },
    edit_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    }
}