import { mainServer } from "../../../../../variables/config";

export const request_extended_patient_stats = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/patientstats/extended/dates", {params:{...params}})
  return response.data.data.extended_patient_stats
}

export const request_delivered_benefits = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/deliveredbenefits/dates",  {params:{...params}})
  return response.data.data.delivered_benefits_stats
}
