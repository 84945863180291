export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    title:'',
    sectionA:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Nombre no válido'
      },
      external_id:{
        label:'ID programa de bonificaciones',
        placeholder:'ID programa de bonificaciones',
        helper:'ID no válida'
      },
      folio:{
        label:'Folio',
        placeholder:'Folio',
        helper:'Folio no válido'
      },
      doctor_id:{
        label:'Doctor',
        placeholder:'Seleccione un doctor',
      },
      email:{
        label:'E-mail',
        placeholder:'E-mail',
        helper:'E-mail incorrecto'
      },
      phone:{
        label:'Teléfono',
        placeholder:'Teléfono',
        helper:'El número debe contener entre 10-12 dígitos'
      },
      zip:{
        label:'Código Postal',
        placeholder:'Código Postal',
        helper:'Ingrese un código postal válido'
      },
      nse_id:{
        label:'Nivel socioeconómico',
        placeholder:'Nivel socioeconómico',
      },
      public_insurance_id:{
        label:'Aseguradora pública',
        placeholder:'Seccione una aseguradora',
      },
      main_hospital_id:{
        label:'Hospital/Clínica de consulta',
        placeholder:'Seleccione una opción',
      },
      secondary_hospital_id:{
        label:'Hospital Secundario',
        placeholder:'Seleccione una opción',
      },
      provider_id:{
        label:'Distribuidor Principal',
        placeholder:'Seleccione una opción',
      },
      secondary_provider_id:{
        label:'Distribuidor Secundario',
        placeholder:'Seleccione una opción',
      },
      payment_type_id:{
        label:'Forma de pago',
        placeholder:'Seleccione una opción',
      },
      policy_value_id:{
        label:'Nivel',
        placeholder:'Seleccione una opción',
      },
      insurance_carrier_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      policy_amount_id:{
        label:'Monto',
        placeholder:'Seleccione una opción',
      },
      balance_value:{
        label:'Cantidad de Saldo',
        placeholder:'Cantidad de Saldo',
        helper:'La cantidad debe ser mayor o igual a 0'
      },
    },
  },
}