import React, { useState, useEffect } from 'react';
import { Card, makeStyles, Grid } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { getPatientProductLogInfo, getUserType } from '../../shared/utility';
import { DASHBOARD_SETTINGS, palette, USER_TYPE } from '../../variables/config';
import { nodata } from '../../variables/texts';
import { RoundedButton } from '../Buttons/GeneralButtons';
import InfoButton from '../Buttons/InfoButton';
import ContraintLayout from '../Layouts/ConstraintLayout';
import ContentText from '../Texts/ContentText';
import HorizontalDataWrapper from '../Texts/HorizontalDataWrapper';
import BenefitContent from './BenefitContent';
import CyclesContent from './CyclesContent';
import SimpleMultitpleVias from './SimpleMultitpleVias';
import PatientProductActions from '../Platform/PatientProductActions/PatientProductActions';


const PatientProductData = (props) => {

    const classes = useStyles()
    const {
        patient,
        patientproduct, 
        constrainst,  
        type, 
        patient_status,
        // Treatment' funtions 
        onViewMedicalReports,
        onEditTreatment, 
        onEditManualUses,
        onStartTreatment,
        onManualChange,
        onFinishTreatment,
        onRefreshPatientProducts,
        // Cycle' funtions 
        onStartNextCycle, onDeleteCycles, onEditPreCycle, onAddPreviousCycle,
        // Uses' funtions 
        onChangeBenefitStatus, onRegisterUse, onChangeUseDate, onDownloadProviderLetter,
        noedit
    } = props

    const buttonInformation = getPatientProductLogInfo(patientproduct)
    //console.log('ESTATUS EDITABLE PPC', noedit)
    const userType = getUserType()
    let isAccredited = patientproduct?.accredited === 1 ? 'Si' : (patientproduct?.accredited === 0 ? 'No' : '-')

    let isTreatmentValid = null
    let isTreatmentValidIcon = 'error'
    let isTreatmentValidColor = red[700]

    let isTreatmentManual = null
    let isTreatmentManualIcon = 'check_circle'
    let isTreatmentManualColor = green[700]

    if(patientproduct?.applicable !== null && patientproduct?.applicable !== undefined){
        isTreatmentValid = patientproduct.applicable === 1 ? 'Sí' : 'No'
        isTreatmentValidIcon = patientproduct.applicable === 1 ? 'check_circle' : 'error'
        isTreatmentValidColor =patientproduct.applicable === 1 ?  green[700] : red[700]
    } 
    if(patientproduct?.manual_change !== null && patientproduct?.manual_change !== undefined){
        isTreatmentManual = patientproduct.manual_change === 1 ? 'Manual' : 'Automático'
        isTreatmentManualIcon = patientproduct.manual_change === 1 ? 'error' : 'check_circle'
        isTreatmentManualColor =patientproduct.manual_change === 1 ?  red[700] : green[700]
    } 

    const vials = patientproduct?.vials ?? []
    vials.sort((a,b) => {
        if(a.number > b.number) return 1
        if(a.number < b.number) return -1
        return 0
    })

    //console.log('Patient product', patientproduct)
    const ismultiple = patientproduct?.multiple_piece === 1

    return ( 
        <Card className={classes.root}>
            <div>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className={classes.product}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                                        <Grid item>
                                            <ContentText text={`Tratamiento ${patientproduct?.id_patient_product}`} medium variant='bold'/>
                                        </Grid>
                                        <Grid item>
                                            <InfoButton data={buttonInformation}/>
                                        </Grid>
                                        <Grid item xs></Grid>
                                        <Grid item>
                                            <PatientProductActions noedit={noedit} patient={patient} patientproduct={patientproduct}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {!ismultiple ? <ContraintLayout md={12} constrainst={constrainst.product_name}>
                                            <HorizontalDataWrapper
                                                label='Producto principal' text={patientproduct ? patientproduct.product_name : nodata}/>
                                        </ContraintLayout> : null}
                                        {!ismultiple ?<ContraintLayout md={12} constrainst={constrainst.product_presentation}>
                                            <HorizontalDataWrapper 
                                                label='Presentación principal' text={patientproduct ? patientproduct.product_presentation : nodata}/>
                                        </ContraintLayout> : null}
                                        {ismultiple && <Grid item xs={12}>
                                            <SimpleMultitpleVias vials={vials} />
                                        </Grid>}
                                        <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                                            <HorizontalDataWrapper 
                                                label='Dosis' text={patientproduct ? patientproduct.dose : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                                            <HorizontalDataWrapper 
                                                label='Frecuencia' text={patientproduct ? patientproduct.treatment_frequency : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                                            <HorizontalDataWrapper 
                                                label='Tipo' text={patientproduct ? patientproduct.product_type : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                                            <HorizontalDataWrapper 
                                                label='Diagnóstico' text={patientproduct ? patientproduct.diagnosis : nodata}/>
                                        </ContraintLayout>
                                        {patientproduct?.scheme && <ContraintLayout md={12} constrainst={constrainst.diagnosis}>
                                            <HorizontalDataWrapper 
                                                label='Esquema' text={patientproduct ? patientproduct.scheme : nodata}/>
                                        </ContraintLayout>}
                                        <ContraintLayout md={12} constrainst={constrainst.hospital}>
                                            <HorizontalDataWrapper 
                                                label='Hospital de infusión actual' text={patientproduct ? patientproduct.hospital : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.hospital_value}>
                                            <HorizontalDataWrapper 
                                                label='Nivel de hospital' text={patientproduct ? patientproduct.hospital_value : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.accredited}>
                                            <HorizontalDataWrapper 
                                                label='Acreditado' text={isAccredited}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.expected_uses}>
                                            <HorizontalDataWrapper 
                                                label='No. de ciclos' text={patientproduct ? patientproduct.expected_uses : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.expected_uses}>
                                            <HorizontalDataWrapper 
                                                label='No. de viales' text={patientproduct ? patientproduct.vial_number : nodata}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.manual_pending_uses}>
                                            <HorizontalDataWrapper 
                                                label='Ciclos pendientes' text={patientproduct ? patientproduct.manual_pending_uses : nodata}
                                                icon={userType === USER_TYPE.MERCURIO  
                                                    ? 'edit' : undefined} 
                                                iconColor={palette.secondary.main} 
                                                onClick={userType === USER_TYPE.MERCURIO 
                                                    ? () => onEditManualUses(patientproduct) : null}/>
                                        </ContraintLayout>
                                        {type === 'secondary' ? (
                                            <ContraintLayout md={12} constrainst={constrainst.adjuvant}>
                                                <HorizontalDataWrapper 
                                                    label='Adyuvante' text={patientproduct ? (patientproduct.adjuvant === 1 ? 'Sí' : 'No') : nodata}/>
                                            </ContraintLayout>
                                        ): null}
                                        <ContraintLayout md={12} constrainst={constrainst.applicable}>
                                            <HorizontalDataWrapper 
                                                label={'Viabilidad'} 
                                                icon={isTreatmentValidIcon} 
                                                iconColor={isTreatmentValidColor} 
                                                text={isTreatmentValid}/>
                                        </ContraintLayout>
                                        <ContraintLayout md={12} constrainst={constrainst.applicable}>
                                            <HorizontalDataWrapper 
                                                label={'Modo'} 
                                                icon={isTreatmentManualIcon} 
                                                iconColor={isTreatmentManualColor} 
                                                text={isTreatmentManual}/>
                                        </ContraintLayout>
                                        {noedit ? null :
                                        <Grid item xs={12}>
                                            <Grid container justifyContent='flex-end' spacing={2}>          
                                                <ContraintLayout auto constrainst={constrainst.edit_treatment}>
                                                    <Grid item>
                                                        <RoundedButton
                                                            size='small'
                                                            label='Editar'
                                                            color='secondary'
                                                            onClick={() => onEditTreatment(patientproduct)}/>
                                                    </Grid>
                                                </ContraintLayout>
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{marginTop:40}}>
                            <BenefitContent 
                                data={patientproduct}
                                patient_status={patient_status} 
                                onStartTreatment={onStartTreatment}
                                onManualChange={onManualChange}
                                onFinishTreatment={onFinishTreatment}
                                noedit={noedit}
                            />
                        </div>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <CyclesContent 
                            patientproduct={patientproduct}
                            cycles={patientproduct?.cycles ?? []}
                            patient_status={patient_status} 
                            /// Patient Product's FUnction
                            onRefreshPatientProducts={onRefreshPatientProducts}
                            /// Cycle's Funtions
                            onStartNextCycle={onStartNextCycle}
                            onDeleteCycles={onDeleteCycles}
                            onEditPreCycle={onEditPreCycle}
                            onAddPreviousCycle={onAddPreviousCycle}
                            /// Use's Funtions
                            onChangeBenefitStatus={onChangeBenefitStatus}
                            onRegisterUse={onRegisterUse}
                            onChangeUseDate={onChangeUseDate}
                            onDownloadProviderLetter={onDownloadProviderLetter}
                            noedit={noedit}
                        />
                    </Grid>
                </Grid>
            </div>
             
        </Card>

     );
}

const useStyles = makeStyles(theme => ({
    root:{
        boxShadow:DASHBOARD_SETTINGS.boxShadow,
        padding:'8px 0px',
        borderRadius:12
    },
    product:{
        padding:16
    }
}))
 
export default PatientProductData;

/*


                
*/