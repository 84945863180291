import { nodata } from "../variables/texts";
import  moment from  'moment'
import { BENEFIT_TYPE, USER_TYPE } from "../variables/config";
import { PAYMENT_TYPES } from "./enums";
import { catalogs } from "../variables/catalogs";
import { request_prebenefit, request_prebenefit_applicant, request_prebenefit_applicant_yearly, request_prebenefit_yearly } from "../views/Admin/Applicants/AddApplicant/FormStepD/requests";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const getFormatNumberForData = (data) => {
  let _data = null
  if(data !== null & Array.isArray(data)){
    _data = [...data]
    _data.map(item => {
      Object.keys(item).map(key => {
        if(typeof item[key] === 'number' && key.substr(0,2) !== 'id'){
          let value = onFormatNumber(item[key] ? item[key]/100 : 0 )
          item[key] = `$${numberWithCommas(value)}`
        }
      })
    })
  }
  
  if(data !== null && typeof data === 'object'){
    _data = {...data}
    Object.keys(_data).map(item => {
      if(typeof _data[item] === 'number'){
        let value = onFormatNumber(_data[item] ? _data[item]/100 : 0 )
        _data[item] = `$${numberWithCommas(value)}`
      }
    })
  }
  
  return data
}

export const onFormatNumber = (number, digits) => {
    let temp = ''
    if(number !== null && number !== undefined){
      temp = number.toFixed(2).toString()//.padStart(digits,'0')
    }
  
    return temp
}

export const numberWithCommas = (x)=> {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const onGetIdFormat = (number, digits, preString) => {
    let result = nodata
    if(Number.isInteger(number)) result = onFormatNumber(number, digits)
    if(typeof preString === 'string') result = `${preString}${result}`
    return result
}

export const onGetDateFormat = (date, format) => {
  let result = '-'
  let current_date = moment(date)
  if(current_date.isValid()) result = current_date.format(format ? format : 'DD MMM YYYY')
  return result
}

export const getFileExtension = (filename) => {
  return filename.split('.').pop();
}

export const getUserType = () => {
  let res = null
  let localData = localStorage.getItem('data')
  if(localData && typeof localData === 'string'){
    localData = JSON.parse(localData)
    res = localData.user_type_id
  }
  return res
}

export const getComponentLogInfo = (data) => {
    let infoButtonData = []
    if(data){
      infoButtonData = [
        {
          title:'Detalles de creación',
          content:[
            {label:'Nombre: ', text: data.creator ? data.creator : nodata},
            {label:'Fecha: ', text: data.created_at ?  moment(data.created_at).format("DD MMM YYYY") : nodata},
          ]
        },
        {
          title:'Detalles de actualización',
          content:[
            {label:'Nombre: ', text: data.updater ? data.updater : nodata},
            {label:'Fecha: ', text: data.updated_at ?  moment(data.updated_at).format("DD MMM YYYY") : nodata},
          ]
        },
      ]
    }
    return infoButtonData
}

export const getPatientProductLogInfo = (data) => {
  let infoButtonData = []
  if(data){
    infoButtonData = [
      {
        title:'Producto aprobado',
        content:[
          {label:'Nombre: ', text: data.approver ? data.approver : nodata},
          {label:'Fecha: ', text: data.approved_date ?  moment(data.approved_date).format("DD/MM/YYYY") : nodata},
        ]
      },
      {
        title:'Cambio manual',
        content:[
          {label:'Nombre: ', text: data.manual_changer ? data.manual_changer : nodata},
        ]
      }
    ]
  }
  return infoButtonData
}

export const getUseLogInfo = (data) => {
  let infoButtonData = []
  if(data){
    //r_gift = data.gift ? true : false
    infoButtonData = [
      {
        title:'Detalles de creación',
        content:[
          {label:'Nombre: ', text: data.creator ? data.creator : nodata},
          {label:'Fecha: ', text: data.created_at ?  moment(data.created_at).format("DD/MM/YYYY") : nodata},
        ]
      },
      {
        title:'Detalles de actualización',
        content:[
          {label:'Nombre: ', text: data.updater ? data.updater : nodata},
          {label:'Fecha: ', text: data.updated_at ?  moment(data.updated_at).format("DD/MM/YYYY") : nodata},
        ]
      },
    ]
  }
  return infoButtonData
}

export const onGetHospitalCatalogs = (data) => {
    let result = []
    if(data && data.length){
        result = data.map(item => {
            return {
                id: item.id_hospital,
                value: item.id_hospital,
                label: item.name
            }
        })
    }
    return result
}


export const getInsuranceEvaluation = (balance, balance_value, policy_amount_id, policy_amounts) => {
  let policy_value_id = null
  let findPolicyAmount
  if(balance){
    findPolicyAmount = policy_amounts.find(el => balance_value >= el.min_value && balance_value <= el.max_value)
    if(findPolicyAmount) policy_value_id = findPolicyAmount.policy_value_id
  }else{
    if(policy_amount_id !== null && policy_amount_id !== undefined && policy_amount_id !== ''){
      findPolicyAmount = policy_amounts.find(el => el.id_policy_amount === policy_amount_id)
      if(findPolicyAmount) policy_value_id = findPolicyAmount.policy_value_id
    }
  }
  return policy_value_id
}

export const isProductValid = (product) => {
  if(product) return  product.product_id !== null && product.product_id !== undefined && 
          product.diagnosis_id !== null && product.diagnosis_id !== undefined && 
          product.expected_uses !== null && product.expected_uses !== undefined 
  return false
}

export const isBenefitPosible = (hospital_id, policy_value_id, nse_id) => {
  return  hospital_id !== null && hospital_id !== undefined && 
          policy_value_id !== null && policy_value_id !== undefined && 
          nse_id !== null && nse_id !== undefined 
}

export const isTreatmentApplicable = (patientproduct, benefitResult) => {
  let result = false
  if(patientproduct && benefitResult){
    const {benefit, uses} = benefitResult
    const {expected_uses} = patientproduct
    if(benefit && uses && expected_uses){
      result = parseInt(expected_uses) >= (benefit + uses)
    }
  }
  return result
}

export const onGetCycleData2Create = (data) => {
  const benefitType = onGetBenefitVariant(data)
  let data2send 
  if(benefitType === BENEFIT_TYPE.NORMAL){
    data2send = {
      patient_product_id:data.id_patient_product,
      paid:data.benefit_uses,
      free:data.benefit_benefit
    }
  }else{
    let free_items = []
    if(data.benefit_name){
      const temp = data.benefit_name.split(',')
      free_items = temp.map(item => parseInt(item))
    }

    data2send = {
      patient_product_id:data.id_patient_product,
      total:12,
      free: free_items
    }
  }
  return data2send

}

export const onGetBenefitVariant = (patientproduct) => {
  let type = BENEFIT_TYPE.NORMAL
  if(!patientproduct) return type
  return  patientproduct.product_id >= 20 && patientproduct.product_id <= 23 ?  BENEFIT_TYPE.ROACTERMA :  BENEFIT_TYPE.NORMAL
}

export const null2undefined = (data) => {
  let res = {...data}
  Object.keys(data).forEach(item => {
    res[item] = res[item] === null ? undefined : res[item]
  })
  return res
}

export const onGetProgress = (patient, product, patient_insurance, documents) => {
  console.log('Evaluar!!!!')
  console.log(patient)
  console.log(product)
  console.log(patient_insurance)
  let missingKeys = []
  const genericKeys = [
    'doctor_id','zip'
  ]
  const insuranceKeys = ['insurance_carrier_id', 'policy_amount_id']
  const productKey = ['product_id', 'diagnosis_id', 'hospital_id', 'expected_uses']
  const docsKey = ['privacy_policy', 'identity_document', 'proof_of_address', 'medical_report']
  const docsInsuranceKey = ['privacy_policy', 'identity_document', 'proof_of_address', 'medical_report', 'insurance_policy']
  genericKeys.forEach(item => {
    if(patient[item] === null || patient[item] === undefined) missingKeys.push(item)
  })

  

  productKey.forEach(item => {
    if(product){
      if(product[item] === null || product[item] === undefined){
        missingKeys.push(item)
      }
    }else{
      console.log('No existe producto')
      console.log(item)
      missingKeys.push(item)
    }
  })
  if(patient_insurance){
    insuranceKeys.forEach(item => {
      if(patient_insurance[item] === null || patient_insurance[item] === undefined) missingKeys.push(item)
    })
    docsInsuranceKey.forEach(item => {
      if(documents[item] === null || documents[item] === undefined) missingKeys.push(item)
    })
  }else{
    if(patient.payment_type_id !== 2){
      missingKeys = [...missingKeys, ...insuranceKeys]
    }
    docsKey.forEach(item => {
      if(documents[item] === null || documents[item] === undefined) missingKeys.push(item)
    })
  }

  let result = 0;
  if(patient_insurance){
    let totalItems = genericKeys.length + insuranceKeys.length + productKey.length + docsInsuranceKey.length
    result = ((totalItems - missingKeys.length) / totalItems)*100
  }else{
    let totalItems = genericKeys.length + productKey.length + docsKey.length
    result = ((totalItems - missingKeys.length) / totalItems)*100
  }

  console.log(missingKeys)
  console.log(result)
  return parseInt(result)

}

export const onValidateProfile = (patient, patient_insurance) => {
  console.log('Evaluar!!!!')
  console.log(patient)
  //console.log(product)
  console.log(patient_insurance)
  let missingKeys = []
  const genericKeys = [
    'doctor_id','zip'
  ]
  const insuranceKeys = ['insurance_carrier_id', 'policy_amount_id']
  const productKey = ['product_id', 'diagnosis_id', 'hospital_id', 'expected_uses']
  const docsKey = ['validated_privacy_act', 'validated_identification', 'validated_address_proof']
  console.log(patient)
  genericKeys.forEach(item => {
    if(patient[item] === null || patient[item] === undefined) missingKeys.push(item)
  })
  //productKey.forEach(item => {
  //  if(product){
  //    if(product[item] === null || product[item] === undefined){
  //      missingKeys.push(item)
  //    }
  //  }else{
  //    console.log('No existe producto')
  //    console.log(item)
  //    missingKeys.push(item)
  //  }
  //})
  if(patient_insurance){
    insuranceKeys.forEach(item => {
      if(patient_insurance[item] === null || patient_insurance[item] === undefined) missingKeys.push(item)
      if(item === 'validated_insurance_policy' ){
        
        if(patient_insurance.validated_insurance_policy === 0) missingKeys.push(item)
      }
    })
  }else{
    if(patient.payment_type_id !== 2){
      missingKeys = [...missingKeys, ...insuranceKeys]
    }

    
  }
  let benefitKey = 'validated_benefit'
  if(patient.patient_products && patient.patient_products.length){
    let isValidBenefit = true
    patient.patient_products.forEach(item => {
      if(item.product_status_id === 1 && !item.benefit_id){
        isValidBenefit = false
      }
    })
    if(!isValidBenefit) missingKeys.push(benefitKey)

  }else{
    missingKeys.push(benefitKey)
  }

  docsKey.forEach(item => {
    if(patient[item] === null || patient[item] === undefined || patient[item] === '' || patient[item] === '0' || patient[item] === 0) missingKeys.push(item)
  })

  //let aux = 'validated_medical_report'
  //if(medical_report){
  //  if(medical_report[aux] === null || medical_report[aux] === undefined || medical_report[aux] === '' || medical_report[aux] === '0' || medical_report[aux] === 0) missingKeys.push(aux)
  //}else{
  //  missingKeys.push(aux)
  //}


  console.log(missingKeys)
  let errorMessage = missingKeys.map(item => keyCatalog[item])
  errorMessage = errorMessage.join(',')
  console.log(errorMessage)
  return {
    response_status: missingKeys.length === 0,
    error_message:`Faltan datos por llenar: ${errorMessage}`
  }


}

//const docsKey = ['privacy_policy', 'identity_document', 'proof_of_address', 'medical_report']


const keyCatalog = {
  doctor_id:'Doctor',
  nse_id:'Calculo del NSE',
  main_hospital_id:'Hospital o clínica de consulta',
  zip:'Código postal',
  insurance_carrier_id:'Aseguradora',
  policy_amount_id:'Póliza',
  policy_value_id:'Cálculo del valor de póliza',
  product_id:'Producto',
  diagnosis_id:'Diagnóstico',
  hospital_id:'Hospital de tratamiento',
  expected_uses:'Ciclos',
  validated_privacy_act:'Aviso de privacidad',
  validated_identification:'Documento de identifación',
  validated_address_proof:'Comprobante de domicilio',
  medical_report:'Reporte médico',
  validated_insurance_policy:'Póliza de seguros',
  validated_medical_report:'Reporte médico',
  validated_benefit:'Calcular beneficio'

}

export const getPatientProduct = (patientproducts, type) => {
  let result = null
  if(patientproducts && patientproducts.length){
    if(type === 'primary') result = patientproducts.find(el => el.product_status_id === 1 && el.secondary === 0)
    if(type === 'secondary') result = patientproducts.find(el => el.product_status_id === 1  && el.secondary === 1)
  }
  return result
}

export const getHistoryFromProducts = (patientproducts) => {
  let result = []
  if(patientproducts && patientproducts.length){
    result = patientproducts.filter(item => item.product_status_id !== 1)
  }
  return result
}

export const onGetFormatNumber = (number) => {
    let result = ''
    if(!number) return '$0.00'
    let temp = parseFloat(number).toFixed(0)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `$ ${result}`
    return result
}

export const isFormValid = (form, keys) => {
  let errors = []
  if(form){
      if(keys){
          keys.forEach(item => {
              if(!form[item].isValid) errors.push(item)
          })
      }else{
          Object.keys(form).forEach(item => {
              if(form[item].isRequired && !form[item].isValid) errors.push(item)
          })
      }
  }
  return errors
} 

export const onGetFormData = (form, keys) => {
  let temp = {}
  if(form){
      if(keys){
          keys.forEach(item => {
              if(form[item].isValid) temp = {...temp, [item]:form[item].value}
          })
      }else{
          Object.keys(form).forEach(item => {
              if(form[item].isValid) temp = {...temp, [item]:form[item].value}
          })
      }
      
  }
  return temp
}

export const onGetVialPosition = () => {

}

export const onGetVialInformation = (form) => {
  let data2send = onGetFormData(form)
  const product_options = form.product_id.options
  const product_data = product_options.find(el => el.value === data2send.product_id)
  if(product_data) data2send.product = product_data.label
  return data2send
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const onGetPolicyValue = (data, policy_amounts) => {
  const {payment_type_id, policy_amount_id, balance, balance_value} = data
  let result
  const _balance_value = balance_value ? parseFloat(balance_value) : null
  if(
    payment_type_id === PAYMENT_TYPES.INSURANCE_DIRECT ||
    payment_type_id === PAYMENT_TYPES.INSURANCE_RETURN ||
    payment_type_id === PAYMENT_TYPES.MIX
  ){
    if(balance){
      if(balance_value !== null){
        const policyAmountEl = policy_amounts.find(
          el=> _balance_value >= el.min_value && _balance_value <= el.max_value
        )
        if(policyAmountEl){
          result = {
            policy_value_id: policyAmountEl.policy_value_id,
            value_name: policyAmountEl.value_name
          }
        }
      }else{
        console.log('Esta fuera de rango el valor')
      }
    }else{
      if(policy_amount_id !== null && policy_amount_id !== undefined && policy_amount_id !== ''){
        console.log(policy_amounts)
        const policyAmountEl = policy_amounts.find(el => el.id_policy_amount === policy_amount_id)
          if(policyAmountEl){
            result = {
            policy_value_id: policyAmountEl.policy_value_id,
            value_name: policyAmountEl.value_name
          }
      }
      }
    }
  }else{
    result = {
      policy_value_id: 6,
      value_name: "Bajo"
    }
  }

  return result
}

export const onCalculatePrebenefit = async(data2send, userType) => {
  let result = null

  try {
    if(userType !== USER_TYPE.HOSPITAL){
      if(data2send.product_id >=20 && data2send.product_id <=23){
        console.log('Calculate yearly')
        result = await request_prebenefit_yearly(data2send)
      }else{
        console.log('Calculate normal')
        result = await request_prebenefit(data2send)
      }
      
    }else{
      if(data2send.product_id >=20 && data2send.product_id <=23){
        console.log('Calculate yearly')
        result = await request_prebenefit_applicant_yearly(data2send)
      }else{
        console.log('Calculate normal')
        result = await request_prebenefit_applicant(data2send)
      }
      
    }
  } catch (error) {
    console.log('Hubo un error al calcular prebeneficio', error)
  }
  
  return result
}

export const onCalculatePrebenefitNoErrorHandler = async(data2send, userType) => {
  let result = null

  if(userType !== USER_TYPE.HOSPITAL){
    if(data2send.product_id >=20 && data2send.product_id <=23){
      console.log('Calculate yearly')
      result = await request_prebenefit_yearly(data2send)
    }else{
      console.log('Calculate normal')
      result = await request_prebenefit(data2send)
    }
    
  }else{
    if(data2send.product_id >=20 && data2send.product_id <=23){
      console.log('Calculate yearly')
      result = await request_prebenefit_applicant_yearly(data2send)
    }else{
      console.log('Calculate normal')
      result = await request_prebenefit_applicant(data2send)
    }
    
  }
  
  return result
}


export const onGetApplicantData = (form, applicant_values, statuses) => {
  let result = {}
  if(!form) return result

  result = onGetFormData(form)

  result.doctor = onGetDataFromSelectInput(form?.doctor_id)
  result.public_insurance = onGetDataFromSelectInput(form?.public_insurance_id)
  result.gender = onGetDataFromSelectInput(form?.gender_id)
  result.payment_type = onGetDataFromSelectInput(form?.payment_type_id)
  result.main_hospital = onGetDataFromSelectInput(form?.main_hospital_id)
  result.insurance_carrier = onGetDataFromSelectInput(form?.insurance_carrier_id)
  result.policy_amount = onGetDataFromSelectInput(form?.policy_amount_id)

  result.nse_name = applicant_values?.nse?.nse_name ?? ''
  result.nse_id = applicant_values?.nse?.nse_id ?? undefined
  result.zip_code_id = applicant_values?.nse?.id_zip_code ?? undefined
  result.product_type_id = applicant_values?.product_type_id
  result.public_insurance_status = (statuses?.is_public_insurance ?? false) ? 1 : 0

  return result

}

export const onGetLabelOption = (value, options=[]) => {
  let result = ''
  const temp = options.find(el => el.value === value)
  if(temp) result = temp.label
  return result
}

export const onGetPatientProductData = (form, products, hospitals, diagnosis, treatment_frequencies, schemes) => {
  let result = {}
  if(!form) return result

  result = onGetFormData(form)

  console.log(products)
  console.log(hospitals)

  result.product = onGetDataFromSelectInput(form?.product_id, products)
  result.diagnosis = onGetDataFromSelectInput(form?.diagnosis_id, diagnosis)
  result.hospital = onGetDataFromSelectInput(form?.hospital_id, hospitals)
  result.treatment_frequency = onGetDataFromSelectInput(form?.treatment_frequency_id, treatment_frequencies)
  result.scheme = onGetDataFromSelectInput(form?.scheme_id, schemes)

  const selectedProduct = products.find(el => el.id_product === form.product_id || el.id_product === result.product_id)
  const selectedHospital = hospitals.find(el => el.id_hospital === form.hospital_id || el.id_hospital === result.hospital_id)

  console.log(selectedProduct)
  console.log(selectedHospital)

  if(selectedProduct){
    result.product_name = selectedProduct?.name
    result.product_presentation = selectedProduct?.presentation
  }

  if(selectedHospital){
    result.hospital = selectedHospital?.name
    result.hospital_value = selectedHospital?.hospital_value_name
    result.accredited = selectedHospital?.accredited === 1 ? 'Sí' : 'No'
  }

  console.log("Clean product", result)

  return result

}

export const onGetDataFromSelectInput = (data, _options) => {
  let result = ''
  const __options = _options || data?.options
  const temp = __options.find(el => el.value === data.value)
  if(temp) result = temp.label
  return result
}

export const isValidPatientProduct = (data) => {
  let result = true
  console.log('Data to evaluted', data)
  if(data?.product_id === null || data?.product_id === undefined) result = false
  if(data?.diagnosis_id === null || data?.diagnosis_id === undefined) result = false
  if(data?.product_status_id === null || data?.product_status_id === undefined) result = false
  if(data?.expected_uses === null || data?.expected_uses === undefined) result = false
  if(data?.treatment_frequency_id === null || data?.treatment_frequency_id === undefined) result = false
  return result
}

export const onGetCatalogElement = (value, options) => {
  if(!options) return null
  const element= options.find(el => el.value === value)
  return element
}

export const onInitForm = (form, origin) => {
  let response = {...form}
  if(!origin) return response
  Object.keys(form).forEach(item => {
      const value = origin[item]
      if(value !== null && value !== undefined) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
  })
  return response
}

export const isValidDocument = (path) => {
  return path === null || path === undefined || path === '' || path === '0'
}

export const onResetFormElement = (data) => {
  let result = {...data}
  result.value = null
  result.isValid = false
  result.isVisited = false
  return result
}

export const onUpdateAuthorizationHeader = (server) => {
  const credentials = JSON.parse(localStorage.getItem('data'))
  if(credentials){
    server.defaults.headers.common['Authorization'] = credentials.token
  }
}

export const onSetErrorsToForm = (form, errors) => {
  let temp = {...form}
  if(errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
  return temp
}

export const onGetSelectedFormData = (form, origin) => {
  let temp = {}

  if(form){
      Object.keys(form).forEach(item => {
          if(!origin){
              temp = {...temp, [item]: onCleanData(form[item].value)}
          }else{
              if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:onCleanData(form[item].value)}
          }
      })
  }
  return temp
}

export const onCleanData = (data) => {
  if(data === undefined || data === null) return undefined
  if(data === "") return "@null!!"
  return data
}

export const onRemoveItemsFromObject = (data, pattern) => {
  let result = {...data}
  Object.keys(data).forEach(item => {
      if(data[item] === pattern) result[item] = undefined
  })
  return result
}

export const s2ab = (s) =>{ 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export const onGetDateWithTimezone = (data, format) => {
  // This function returns a moment object
  if(!data) return null 
  //console.log(moment.utc(data).format())
  if(!format) return moment(moment.utc(data).format())
  if(format) return moment(moment.utc(data).format()).format(format)
}

export const onGetProductUserParams = (master_user, _prodType) => {
  let products_params = {}
  let _product_type = _prodType > 0 ?  _prodType :  null

  switch(master_user.user_type_id){
      case USER_TYPE.ADMIN:
      case USER_TYPE.ROCHE:
          products_params.product_type_id = _product_type
          break;
      case USER_TYPE.MERCURIO:
          products_params.product_type_id = master_user.agency_id === 1 ? 1 : 2
          _product_type = master_user.agency_id === 1 ? 1 : 2
          break;
      case USER_TYPE.HOSPITAL:
          products_params.product_type_id = 1
          _product_type = 1
          break;
      default:
  }

  console.log("Parametros de productos",products_params)

  return products_params
}

export const onGetBenefitInfo = (benefit_id, benefits=[]) => {
  let temp = benefits.find(el => el.id_benefit === benefit_id)
  if(temp.benefit_type_id === BENEFIT_TYPE.INTERMITENT){
    temp.total = parseInt(temp?.uses ?? 0) + parseInt(temp?.benefit ?? 0)
    temp.recurrence_array = (temp?.recurrence??"").split(",")
  }
  return temp
}