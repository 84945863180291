import React, { Component } from 'react';
import { withStyles, Grid, makeStyles, IconButton, Icon } from '@material-ui/core';
import { RoundedButton } from '../../../../../../components/Buttons/GeneralButtons';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../../../components/Texts/ContentText';
import { red } from '@material-ui/core/colors';
import moment from 'moment'

import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import WarningModal from '../../../../../../components/Modals/WarningModal';

import {constrainst_notes as constrainst} from  './constraints'
import AddNoteModal from './modals/AddNoteModal/AddNoteModal';
import { getNotesOptions } from '../../../../../../shared/customFunctions';
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout';
import { request_notes, request_patient_notes, request_delete_note, request_add_note } from '../../requests';


class NotesView extends Component {

  state = {
    isloading:true,
    notes:[],
    patient_notes:[],
    modals:{
      add_note_modal:{open:false,issending:false,error:null},
      warning_delete_note:{open:false, issending:false, error:null},
    }
  }

  render(){

    const {notes, patient_notes, modals, isloading} = this.state
    const {classes} = this.props

    const notes_catalog = getNotesOptions(notes)


    let modalcontent = null

    if(modals.add_note_modal.open){
      modalcontent = (
        <AddNoteModal 
          catalogs={{notes:[...notes_catalog]}}
          data={modals.add_note_modal}
          onClose={() => this.onCloseModal('add_note_modal')}
          onSubmit={this.onAddNote}
          addSpace
        />
      )
    }

    if(modals.warning_delete_note.open){
      modalcontent = (
        <WarningModal 
          data={modals.warning_delete_note}
          onClose={() => this.onCloseModal('warning_delete_note')}
          onDelete={this.onDeleteNote}
        />
      )
    }

    let contentView = null

    if(patient_notes && patient_notes.length > 0) {
      contentView = (
        <Grid container spacing={2}>
          {patient_notes.map((item,key)=>{
            return(
              <Grid item xs={12} key={key.toString()}>
                <NoteItems data={item} onDelete={this.onPreDeleteNote}/>
              </Grid>
            )
          })}
        </Grid>
      ) 
    }else{
      contentView = (<CardWrapper>
        <div className={classes.container}>
          <ContentText text='No existen notas asignadas a este beneficiario' medium align='center'/>
        </div>
      </CardWrapper>)
    }


    return(
      <SimpleContainer isloading={isloading}>
        {modalcontent}
        <Grid container justifyContent='flex-end' spacing={3}>
          <ContraintLayout constrainst={constrainst.add_note} auto>
            <RoundedButton 
              size='small'
              label='Agregar nota'
              color='primary'
              onClick={() => this.onOpenModal('add_note_modal')}
            />
          </ContraintLayout>
          <Grid item xs={12}>
            {contentView}
          </Grid>
        </Grid>
      </SimpleContainer>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {id} = this.props.match.params
    this.setState({isloading:true})
    try {
      const notes = await request_notes()
      const patient_notes = await request_patient_notes(id)
      this.setState({notes:notes, patient_notes:patient_notes})
    } catch (error) {
      console.log(error)
    }

    this.setState({isloading:false})
  }

  /////////////////////////// NOTES MANAGER ////////////////////////////////

  onAddNote = async(data) => {
    const {id} = this.props.match.params
    const type = 'add_note_modal'

    let data2send = {...data,patient_id:id,addition_date: moment().format('YYYY-MM-DD HH:mm:ss'),note:'-'}

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_note(id,data2send)
      this.setState({patient_notes:[...response]})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      } 
    }
  }


  onPreDeleteNote = (data) => {
    this.setState({selected_note:data})
    this.onOpenModal('warning_delete_note')
  }

  onDeleteNote = async() => {
    const {id_patient_note} = this.state.selected_note
    const {id} = this.props.match.params
    const type = 'warning_delete_note'

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_delete_note(id,id_patient_note)
      this.setState({patient_notes:[...response]})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
    
  }
}


const styles = theme => ({
  root:{

  },
  container:{
    padding:32
  }
})

export default withStyles(styles)(NotesView)

const useStyles = makeStyles(theme => ({
  root:{
    padding:16,
    //paddingLeft:32,
    //paddingRight:32,
  },
  iconbutton:{
    color:red[700]
  }
}))

const NoteItems = props => {

  const classes = useStyles()
  const {data, onDelete} = props

  return(
    <CardWrapper>
      <div className={classes.root}>
        <Grid container alignItems='center' spacing={3}>
          <Grid item xs>
            <ContentText text={data.content} medium />
          </Grid>
          <Grid item>
            <ContentText text={moment(data.addition_date).format('DD MMM YYYY')} medium />
          </Grid>
          <Grid item>
            <IconButton 
            onClick={() => onDelete(data)}
            size='small' className={classes.iconbutton}>
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </div>

    </CardWrapper>
  )
}