export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      title:'Asesores',
      navigation:[
        {name:'Asesores',path:'/agents'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[
      {id:0, label:'Nombre', url:'name'},
      {id:1, label:'Usuario', url:'username'},
      {id:2, label:'E-mail', url:'email'},
      {id:null, label:'Sin Filtro', url:null},
    ],
    superfilters:[
      { id:'hospital',
        placeholder:'Hospital',
        filters:[]
      },
      { id:'user_type',
        placeholder:'Usuario',
        filters:[
          {id:1, label:'Admin'},
          {id:2, label:'Roche'},
          {id:3, label:'Registrador'},
          {id:4, label:'Auditor'},
          {id:5, label:'Administrativo Hospital'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      { id:'status',
        placeholder:'Estatus',
        filters:[
          {id:0, label:'Inactivo'},
          {id:1, label:'Activo'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      { id:'general',
        placeholder:'General',
        filters:[
          {id:0, label:'Nombre', url:'name'},
          {id:1, label:'Usuario', url:'username'},
          {id:2, label:'E-mail', url:'email'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      
    ],
    statusFilters:[
      {id:0, label:'Activo'},
      {id:1, label:'Inactivo'},
      {id:null, label:'Sin Filtro', url:null},
    ],
    usertypeFilters:[
      {id:1, label:'Admin'},
      {id:2, label:'Roche'},
      {id:3, label:'Registrador'},
      {id:4, label:'Auditor'},
      {id:null, label:'Sin Filtro', url:null},
    ],

    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['Nombre', 'Usuario','Tipo','Agencia','E-mail','Celular','Estatus']
  }
}