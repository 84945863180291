import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditBasicInfo from './EditBasicInfo/EditBasicInfo'

import {request_approval_contact, request_edit_approval_contact} from './requests'

class EmailEdit extends Component{

  state = {
    isloading:false,
    issending:false,
    error:null,
    approval_contact:null,
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_approval_contact(id)
    this.setState({approval_contact:{...response}})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onSubmitUser = async(data) => {
    const {match} = this.props
    const id = match.params.id
    try {
      this.setState({issending:true})
      let response = await request_edit_approval_contact(id,data)
      this.setState({approval_contact:{...response}})
      this.setState({issending:false, error:null})
      this.props.history.goBack()
      //this.onActivateSuccessSnack()
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }
      
    }
  }

  render(){

    const {history, language, catalogs,translations} = this.props;
    const {isloading, issending, error, approval_contact, success} = this.state
    const content = contentData[language]

    let mainContent = <div>Loading...</div>

    if(!isloading){
      mainContent = (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditBasicInfo
              language={language}
              catalogs={catalogs}
              approval_contact={approval_contact}
              issending={issending}
              error={error}
              translations={translations}
              onSubmit={this.onSubmitUser}
            />
          </Grid>
        </Grid>
      )
    }
    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
      >
        {mainContent}
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(EmailEdit)