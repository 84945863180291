import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../../variables/texts'
import HorizontalDataWrapper from '../../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../../components/Texts/ContentText'
import {constrainst_basicInfo as constrainst} from  '../constraints'
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout'
import { catalogs } from '../../../../../../variables/catalogs'

const BasicInfoContainer = props => {

    const {data} = props
    let insuranceContent = null

    if(data && data.public_insurance_status){
        insuranceContent = (
            <ContraintLayout constrainst={constrainst.public_insurance}>
                <HorizontalDataWrapper 
                    label='Aseguradora Pública' text={data.public_insurance}/>
            </ContraintLayout>
        )
    }

    //const genderData = catalogs.spanish.genders.find(el => el.value === data?.)
    
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                        <Grid item>
                            <ContentText text='Información general' medium variant='bold'/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ContraintLayout constrainst={constrainst.external_id}>
                            <HorizontalDataWrapper 
                                label='ID programa de bonificaciones' text={data ? data.external_id : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.external_id}>
                            <HorizontalDataWrapper 
                                label='ID por Ti' text={data ? data.por_ti_id : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.folio}>
                            <HorizontalDataWrapper 
                                label='Folio' text={data ? data.folio : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Nombre' text={data ? data.name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.email}>
                            <HorizontalDataWrapper 
                                label='E-mail' text={data ? data.email : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.phone}>
                            <HorizontalDataWrapper 
                                label='Teléfono' text={data ? data.phone : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Género' text={data ? data.gender : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.doctor}>
                            <HorizontalDataWrapper 
                                label='Doctor' text={data ? data.doctor : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.city}>
                            <HorizontalDataWrapper 
                                label='Ciudad' text={data ? (data.city ? data.city : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.state}>
                            <HorizontalDataWrapper 
                                label='Estado' text={data ? (data.state ? data.state : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.zip}>
                            <HorizontalDataWrapper 
                                label='Código Postal' text={data ? (data.zip ? data.zip : nodata) : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.nse}>
                            <HorizontalDataWrapper 
                                label='NSE' text={data ? data.nse_name : nodata}/>
                        </ContraintLayout>
                        {insuranceContent}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicInfoContainer