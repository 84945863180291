export const contentData = {
  spanish:{
    layout:{
      title:'Editar Aseguradora Pública',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Aseguradoras Públicas',path:'/catalogs/public_insurances'},
        {name:'Detalles',path:'/catalogs/public_insurances/:id'},
        {name:'Editar',path:'/catalogs/public_insurances/:id/edit'}
        
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
  }
}