import React from 'react'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import { Grid } from '@material-ui/core';

const OnboardingActionBar = ({
    onReturn,
    onSubmit,
    next_btn_label,
    back_btn_label,
    back_btn_disabled,
    next_btn_disabled
}) => {
    return ( 
        <div style={{marginTop:16}}>
          <Grid container justifyContent='flex-end'>
            {!back_btn_disabled && <Grid item>
              <RoundedButton
                label={back_btn_label || 'Regresar'}
                onClick={onReturn}
                color='transparent'
              />
            </Grid>}
            {!next_btn_disabled && <Grid item>
              <RoundedButton 
                label={next_btn_label || 'Siguiente'}
                onClick={onSubmit}
                color='secondary'
              />
            </Grid>
            }
          </Grid>
        </div>
     );
}
 
export default OnboardingActionBar;