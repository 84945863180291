import doctor from '../../../../assets/doctor.png'
import hospital from '../../../../assets/hospital_2.png'

export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      title:'Correos',
      navigation:[
        {name:'Emails',path:'/mails'},
        
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    catalogs:[
      {id:0, name:'Contactos de aprobación', image:doctor, url:'/mails/approvals'},
      {id:1, name:'Contactos de solicitantes', image:hospital, url:'/mails/applicants'},
    ],
    filters:[ 
      {id:null, label:'Sin Filtro', url:null},
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID','Nombre', 'E-mail', 'Acciones']
  }
}