export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      title:'Doctores',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Doctores',path:'/catalogs/doctors'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[ 
      {id:null, label:'Sin Filtro', url:null},
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID','Nombre', 'Segundo Nombre','Apellido Paterno','Apellido Materno', 'CRM ID', 'UCI']
  }
}