import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralView from './GeneralView/GeneralView'
import {request_public_insurance} from './requests'

class PublicInsuranceView extends Component{

  state = {
    isloading:false,
    public_insurance:null
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_public_insurance(id)
    this.setState({public_insurance:{...response}})
    this.setState({isloading:false})
  }

  onEditUser = async() => {
    const {match,history} = this.props
    const id = match.params.id
    history.push('/catalogs/public_insurances/'+id+'/edit')
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/catalogs/public_insurances')
  }


  render(){

    const { history, language,translations, user_level} = this.props
    const {public_insurance} = this.state
    const content = contentData[language]
    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <GeneralView
              user_level={user_level}
              language={language}
              public_insurance={public_insurance}
              translations={translations}
              onEdit={this.onEditUser}
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(PublicInsuranceView)