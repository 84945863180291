import React, {Component, Fragment} from 'react'
import { Grid, IconButton, Icon, withStyles, Button, makeStyles, Typography, Divider } from '@material-ui/core'
import moment from 'moment'

import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'

import DocumentButton from '../../../DocumentsView/components/DocumentButton'
import { amber } from '@material-ui/core/colors'
import ContentText from '../../../../../../../../components/Texts/ContentText'
import { BENEFIT_TYPE, USER_TYPE } from '../../../../../../../../variables/config'
import { getUserType, getFileExtension, isValidDocument } from '../../../../../../../../shared/utility'
import ErrorInfoModal from '../../../../../../../../components/Modals/ErrorInfoModal'

import {request_download_document, request_upload_document, request_delete_document} from './requests'
import UploadDocumentModal from './modals/UploadDocumentModal'
import WarningModal from '../../../../../../../../components/Modals/WarningModal'
import ContraintLayout from '../../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_use_modal as constrainst} from  '../../constraints'
import SimpleModalV2 from '../../../../../../../../components/Modals/SimpleModalV2'
import useUseDateModalV2 from './useUseDateModalV2'
import VialModal from './modals/VialModal'
import MultipleVias from './components/MultipleVias'
import SimpleContainer from '../../../../../../../../components/Layouts/SimpleContainer'
import ActionModalBar from '../../../../../../../../components/Buttons/ActionModalBar'
import CheckboxInput from '../../../../../../../../components/Inputs/CheckboxInput/CheckboxInput'

const UseDateModalV2 = props => {

  const classes = useStyles()
  const {open, onClose, products, noedit, origin} = props

  const {form, modals, actions, system, data} = useUseDateModalV2({...props})
  const {use, vial, vials} = data
  const {error} = system
  
  //const {form, error, modals, use} = actions.state

  let title = 'Actualizar'
  if(use){
      
      if(use.free){
          
        title = `${title} ${`beneficio`}`
      }else{
        title = `${title} ${'aplicación ' + use.number.toString()}`
      }
  }

    let actionButtons = null
    const userType = getUserType()

    const originalPath = 'ticket'; const obsPath = 'obsticket'; const filename = 'ticket'

    let isLast = use?.free === 1
    let disabledDoc = isValidDocument(use?.ticket)
    let disabledObsdoc = isValidDocument(use?.obs_ticket)
  //console.log(use)
    //console.log(use)

    switch (userType) {
        case USER_TYPE.ADMIN:
        case USER_TYPE.ROCHE:
        case USER_TYPE.PRA:
            actionButtons = (
              <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button variant='contained' className={classes.button} color='primary'
                            disabled={disabledObsdoc}
                            onClick={() => actions.onDownload(originalPath, filename)}>
                                <div style={{marginRight:8}}>Original</div>
                                <Icon>get_app</Icon>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' className={classes.button} color='primary'
                            disabled={disabledObsdoc}
                            onClick={() => actions.onDownload(obsPath, filename)}>
                                <div style={{marginRight:8}}>Modificado</div>
                                <Icon>get_app</Icon>
                        </Button>
                    </Grid>
                </Grid>
              </Grid>
            )
            break;

        case USER_TYPE.HOSPITAL:
          console.log('Son estos botones')
            actionButtons = (
              <Grid item>
                  <DocumentButton text={'Acciones'} color='primary' disabled={disabledDoc} noedit={noedit}
                  onUpload={() => actions.onPreUploadDocument(originalPath, filename)} 
                  onDownload={() => actions.onDownload(originalPath, filename)}
                  onDelete={() => actions.onPreDeleteDocument(originalPath)}/>
              </Grid>
            )
            break;
        case USER_TYPE.MERCURIO:
            actionButtons = (
                <Grid item>
                    <Grid container direction='column' spacing={1} alignItems='flex-end'>
                        <Grid item>
                            <DocumentButton text={'Original'} color='primary' disabled={disabledDoc} noedit={noedit}
                            onUpload={() => actions.onPreUploadDocument(originalPath, filename)} 
                            onDownload={() => actions.onDownload(originalPath, filename)}
                            onDelete={() => actions.onPreDeleteDocument(originalPath)}/>
                        </Grid>
                        <Grid item>
                            <DocumentButton text={'Modificado'} color='secondary' disabled={disabledObsdoc} noedit={noedit}
                            onUpload={() => actions.onPreUploadDocument(obsPath, filename)} 
                            onDownload={() => actions.onDownload(obsPath, filename)}
                            onDelete={() => actions.onPreDeleteDocument(obsPath)}/>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
    
        default:
            break;
    }

    let modalcontent = null
    
    if(modals.upload_document){
      modalcontent = (
        <UploadDocumentModal
          data={modals.upload_document}
          onClose={() => actions.onChangeModalStatus('upload_document', false)}
          onSubmit={actions.onUploadDocument}
        />
      )
    }

    if(modals.delete_document){

      modalcontent = (
        <WarningModal 
        data={{
          open:true, 
          issending:system.sending, 
          id:'delete_document'
        }} 
        onDelete={actions.onDeleteDocument}
        onClose={() => actions.onChangeModalStatus('delete_document', false)}
        />
      ) 
    }

    let productForm = null
    

    if(origin?.multiple_piece === 1){
      //console.log('USE MODAL', origin,)
      productForm = (
        <Fragment>
          <Grid item xs={12}>
            <MultipleVias
              editable={!(userType > USER_TYPE.MERCURIO) || !noedit}
              vials={vials??[]} 
              products={products??[]} 
              onAddVial={() => actions.onChangeModalStatus('add_vial', true)}
              onEditVial={actions.onEditVial}
              onUpdateVial={actions.onUpdateVial}
              onMoveVial={actions.onMoveVial}/>
          </Grid>
        </Fragment>
      )
    }else{
      productForm = (
        <Grid item xs={12}>
            <LineInputSelect
            disabled={userType > USER_TYPE.MERCURIO || noedit}
            data={form.product_id}
            onChange={actions.onInputChange}/>
          </Grid>
      )
    }

    console.log('Lista de productos', products)


    return(
      <SimpleModalV2 open={open} onClose={onClose}>
        {/* ADD NEW VIAL PRODUCT */}
        <VialModal 
            mode='add' open={modals.add_vial} 
            onClose={() => actions.onUpdateModal('add_vial', false)} 
            products={products}
            onUpdateVial={actions.onUpdateVial} 
            />
        <VialModal 
            mode='edit' open={modals.edit_vial} origin={vial}
            onClose={() => actions.onUpdateModal('edit_vial', false)} 
            products={products}
            onUpdateVial={actions.onUpdateVial} 
            />
        {/*errorModal*/}
        {modalcontent}
        <SimpleContainer loading={system.loading}>
          <Typography variant='h6'>{title}</Typography>
          <div>
            <Grid container spacing={4}>
              {productForm}
              
              <Grid item xs={12}>
                <Grid container alignItems='flex-end' spacing={1}>
                  
                  <Grid item xs>
                    <LineInputText
                    disabled={userType > USER_TYPE.MERCURIO || noedit}
                    data={form.date}
                    onChange={actions.onInputChange}/>
                  </Grid>
                  <ContraintLayout constrainst={constrainst.delete_date} auto >
                    <IconButton disabled={noedit} size='small' onClick={()=>actions.onClear('date')} style={{marginBottom:3}}>
                    <Icon fontSize='small'>delete</Icon>
                    </IconButton>
                  </ContraintLayout>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems='flex-end' spacing={1}>
                  <Grid item xs>
                    <LineInputText
                    disabled={userType > USER_TYPE.MERCURIO || noedit}
                    data={form.application_date}
                    onChange={actions.onInputChange}/>
                  </Grid>
                  <ContraintLayout constrainst={constrainst.delete_application_date} auto >
                    <IconButton disabled={noedit} size='small' onClick={()=>actions.onClear('application_date')} style={{marginBottom:3}}>
                    <Icon fontSize='small'>delete</Icon>
                    </IconButton>
                  </ContraintLayout>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <LineInputSelect
                disabled={userType > USER_TYPE.MERCURIO || noedit}
                data={form.hospital_id}
                onChange={actions.onInputChange}/>
              </Grid>
              <Grid item xs={12}>
                <CheckboxInput
                disabled={userType > USER_TYPE.MERCURIO || noedit}
                data={form.countable}
                onChange={actions.onInputChange}/>
              </Grid> 
              <ContraintLayout constrainst={constrainst.ticket_action} xs={12} md={12} >
                <Grid container>
                    <Grid item xs>
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item><Icon className={classes.icon}  fontSize='large'>folder</Icon></Grid>
                            <Grid item><ContentText text={isLast ? 'Acuse' : 'Ticket'} variant='bold' medium/></Grid>    
                        </Grid>
                    </Grid>
                    {actionButtons}
                  </Grid>
              </ContraintLayout>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {!noedit ? <Grid item xs={12}>
                <ActionModalBar 
                  btnLabel='Guardar'
                  disabled={noedit}
                  error={error} onCloseError={actions.onResetError}
                  loading={system.sending}
                  onSubmit={actions.onSubmit}
                />
              </Grid> : null}
            </Grid>
          </div>
        </SimpleContainer>
        
          
      </SimpleModalV2>
    )
}

const useStyles = makeStyles(theme => ({
  icon:{
    color:amber[700]
  }
}))

export default UseDateModalV2