import { useState } from "react"
import { getFileExtension, onUpdateAuthorizationHeader } from "../shared/utility"
import { private_server } from "../variables/config"
import axios from 'axios'
import FileSaver from "file-saver"

const usePatient = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [patients, setPatients] = useState([])
    const [patient, setPatient] = useState([])

    const actions = {
        /*onGetAll:async(patientid, params) => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientmedicalreport/patient/${patientid}`, 
                    {params:{...params}}
                )
                response = request.data.data.patient_medical_reports
                setMedicalReports(response)
            } catch (error) {
                setError('No se pudo obtener la lista de reportes médicos de manera correcta, intente de nuevo')
            }
            setLoading(false)
            return response
        },*/
        onGet:async(patientid) => {
            setLoading(true)
            let response = null
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patient/${patientid}`
                )
                response = request.data.data.patient
                setPatient(response)
            } catch (error) {
                setError('No se pudo obtener el paciente solicitado, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onUpdate:async(patientid, data2send) => {
            setLoading(true)
            let response = null
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.patch(`/patient/${patientid}`, data2send)
            } catch (error) {
                setError('No se pudo obtener el paciente solicitado, intente de nuevo')
            }
            setLoading(false)
            return response
        },
       /*onAdd:async(data) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.post(
                    `/patientmedicalreport`,
                    data
                )
                response.status = true
            } catch (error) {
                let msg = 'No se pudo crear el reporte médico, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
            }
            setLoading(false)
            return response
        },
        onUpdate:async(medicalreportid, data) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                await private_server.patch(
                    `/patientmedicalreport/${medicalreportid}`,
                    data
                )
                response.status = true
            } catch (error) {
                let msg = 'No se crear el reporte médico, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
            }
            setLoading(false)
            return response
        },
        onDelete:async() => {

        },*/
        
    }

    const system = {loading, sending,error}
    const data = {patient, patients}

    return {system, data, actions}



} 

export default usePatient