import React from 'react'
import { Icon, Grid, makeStyles, Button, Checkbox, IconButton, Typography } from '@material-ui/core'
import { amber, grey, green, teal } from '@material-ui/core/colors'
import ContentText from '../../../../../components/Texts/ContentText'
import { nodata } from '../../../../../variables/texts'
import { USER_TYPE } from '../../../../../variables/config'
import DocumentButton from './DocumentButton'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout'
import { isValidDocument, onGetDateFormat } from '../../../../../shared/utility'




const PersonalDocument = props => {

    const {data, patient, userType, onUpload, onDownload, onValidateDocument, onDelete, status, onEdit} = props
    const classes = useStyles()
    //console.log(data)
    let actionButtons = null

    let disabledDoc = false
    let disabledObsdoc = false
    let disabledUpdateDoc = false

    disabledDoc = isValidDocument(data.document)
    disabledObsdoc = isValidDocument(data.obs_document)
    //if(patient){
    //    disabledDoc = isValidDocument(data.document)
    //    disabledObsdoc = isValidDocument(data.obs_document)
    //    if(userType === USER_TYPE.HOSPITAL){
    //        disabledUpdateDoc = !disabledDoc
    //    }
    //}

    switch (userType) {
        case USER_TYPE.ADMIN:
        case USER_TYPE.ROCHE:
        case USER_TYPE.PRA:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledDoc}
                                onClick={() => onDownload(data.path, data.filename)}>
                                    <div style={{marginRight:8}}>Original</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledObsdoc}
                                onClick={() => onDownload(data.obspath, data.filename)}>
                                    <div style={{marginRight:8}}>Modificado</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;

        case USER_TYPE.HOSPITAL:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='secondary'
                                onClick={() => onUpload(data.path, data.filename)} disabled={disabledUpdateDoc}>
                                    <div style={{marginRight:8}}>Subir</div>
                                    <Icon>publish</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledDoc} onClick={() => onDownload(data.path, data.filename)}>
                                    <div style={{marginRight:8}}>Descargar</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <IconButton size='small' onClick={() => onDelete(data.path)}
                            disabled={disabledDoc || disabledUpdateDoc}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
        case USER_TYPE.MERCURIO:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DocumentButton text={'Original'} color='primary'
                            disabled={disabledDoc}
                            onUpload={() => onUpload(data.path, data.filename)} 
                            onDownload={() => onDownload(data.path, data.filename)}
                            onDelete={() => onDelete(data.path)}/>
                        </Grid>
                        <Grid item>
                            <DocumentButton text={'Modificado'} color='secondary'
                            disabled={disabledObsdoc}
                            onUpload={() => onUpload(data.obspath, data.filename)} 
                            onDownload={() => onDownload(data.obspath, data.filename)}
                            onDelete={() => onDelete(data.obspath)}/>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
    
        default:
            break;
    }

    return(
        <div className={classes.root}>
            <Grid container  spacing={1}>
                <Grid item xs>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                                <Icon className={classes.icon}  fontSize='large'
                                style={status ? {color:green[700]} : undefined}>folder</Icon>    
                        </Grid>
                        <Grid item xs>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <ContentText text={(data?.name || data?.patient_document) ?? '-'} variant='bold' medium/>
                                        </Grid>
                                        <ContraintLayout constrainst={constrainst.edit_button} auto>
                                            <IconButton size='small' className={classes.editIcon} onClick={() => onEdit(data)}><Icon fontSize='small' className={classes.editSVG}>edit</Icon></IconButton>
                                        </ContraintLayout>
                                    </Grid>
                                </Grid>
                                {data?.date ? <Grid item xs={12}>
                                    <Typography variant='caption' >{onGetDateFormat(data?.date)}</Typography>
                                </Grid> : null}
                                {data?.details ? <Grid item xs={12}>
                                    <Typography variant='body2' >{data?.details}</Typography>
                                </Grid> : null}
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
                
                <Grid item>
                    {actionButtons}
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:16,
        borderBottom:`2px solid ${grey[200]}`
    },
    icon:{
        color:amber[700]
    },
    button:{
        textTransform:'none',
        borderRadius:32
    },
    editIcon:{
        marginLeft:8,
        marginTop:-4,
        color:'white',
        background:theme.palette.secondary.main,
        padding:4,
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
       
    },
    editSVG:{
        fontSize:14
    }
}))

export default PersonalDocument

const  constrainst = {
    validation:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO
        ],
    },
    edit_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    }
}

/*const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);*/