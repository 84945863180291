import React, {Component} from 'react'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GenericTable from './components/GenericTable'
import { request_approval_contacts } from './requests'
import AddEmail from './modals/AddEmail/AddEmail'
import {content} from './content'
import DeleteEmail from './modals/DeleteEmail'


class ApprovalEmails extends Component{

  state = {
    contacts:[],
    isloading:false,
    selected_contact:null,
    add_email_modal:false,
    edit_email_modal:false,
    delete_email_modal:false, 
  }

  render(){

    const { history} = this.props
    const {isloading, contacts, selected_contact,
      add_email_modal, edit_email_modal, delete_email_modal} = this.state

    let modalcontent = null

    if(delete_email_modal){
      modalcontent = <DeleteEmail  
      origin={selected_contact}
      onUpdateContacts={(data) => this.setState({contacts:data, delete_email_modal:false})}
      onClose={() => this.setState({delete_email_modal:false})}/>
    }

    if(add_email_modal){
      modalcontent = (
        <AddEmail 
          onClose={() => this.setState({add_email_modal:false})}
          onUpdateContacts={(data) => this.setState({contacts:data, add_email_modal:false})}/>
      )
    }

    if(edit_email_modal){
      modalcontent = (
        <AddEmail 
          type='edit'
          origin={selected_contact}
          onClose={() => this.setState({edit_email_modal:false})}
          onUpdateContacts={(data) => this.setState({contacts:data, edit_email_modal:false})}/>
      )
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        isloading={isloading}
        returnBtn={true} onReturn={this.onReturn}>
        {modalcontent}
        <GenericTable 
          data={contacts} table={content.table}
          onSelectedItem={this.onSelectedItem}
          onDeleteItem={(item) => this.setState({selected_contact:item, delete_email_modal:true})}
          onAddItem={() => this.setState({add_email_modal:true})}/>
      </BasicView>
      
    )

    
  }

  onSelectedItem = (contact) => {
    this.setState({selected_contact:contact, edit_email_modal:true})
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try {
      const contacts = await request_approval_contacts()
      this.setState({contacts:contacts})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})

  }

  onReturn = () => {
    this.props.history.push('/mails')
  }
}

export default ApprovalEmails