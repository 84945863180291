export const contentData = {
  spanish:{
    layout:{
      title:'Información del distribuidor',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Distribuidores',path:'/catalogs/providers'},
        {name:'Detalles',path:'/catalogs/providers/:id'}
      ]
    },
  }
}
