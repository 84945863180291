export const onGetUseData = (formData, option) => {
    let data2Send = {};
    let isValid = true

    if(option === 1 || option === 0){
        if(formData.alternative_hospital_id.value){
          data2Send = {alternative_hospital_id:formData.alternative_hospital_id.value,
          manual_hospital_address_value:formData.manual_hospital_address_value.value,
          patient_provider_id:formData.patient_provider_id.value}
          isValid = formData.alternative_hospital_id.isValid && formData.manual_hospital_address_value.isValid && formData.patient_provider_id.isValid
  
        }
    }else if(option === 2){
        if(formData.patient_provider_id.value){
            data2Send = {patient_provider_id:formData.patient_provider_id.value,
            manual_provider_address_value:formData.manual_provider_address_value.value}
            isValid = formData.patient_provider_id.isValid && formData.manual_provider_address_value.isValid

        }
    }else{
        data2Send = {manual_particular_address_value:formData.manual_particular_address_value.value}
        isValid = formData.manual_particular_address_value.isValid 
    }

    if(JSON.stringify(data2Send) !== "{}"){
        if(data2Send.alternative_hospital_id){ 
            data2Send = {"alternative_hospital":1,...data2Send}
        }else{
            data2Send = {"alternative_hospital":option,...data2Send}
        }
    }else{
        data2Send = {"alternative_hospital":0,...data2Send}
    }

    return {
        data: data2Send,
        valid: isValid
    }
}