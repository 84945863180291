export const formaData = {

    identifier:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'identifier',
            type:'text',
            fullWidth: true,
            label:'Identificador',
            placeholder:'Ingrese un identificador...',
            helper:'Dato no válido'
        },
        rules:{
            type:'distance',
            min:1,
            max:255
        }
    },
    report_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'report_date',
            type:'date',
            fullWidth: true,
            label:'Fecha del reporte',
            placeholder:'',
        },
        rules:{
            type:'date',
        }
    },
}