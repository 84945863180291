import React, {useState} from 'react'
import { makeStyles, Grid, IconButton, Icon, Divider } from '@material-ui/core'
import { grey, green, amber } from '@material-ui/core/colors'
import Caption from '../../../../../../../../../components/Texts/Caption'
import ContentText from '../../../../../../../../../components/Texts/ContentText'
import PopperWrapper from '../../../../../../../../../components/Poppers/PopperWrapper'

const useStyles = makeStyles(theme => ({
    singleProduct:{
        borderTop:`2px solid ${grey[200]}`,
        paddingTop:12,
        paddingBottom:12,
    },
    menu:{width:100},
    item:{
        padding:8,
        '&:hover':{
            background:grey[200],
            cursor:'pointer'
        }
    },
    icon:{
        fontSize:12
    }
}))

const SingleTreatment = props => {

    const classes = useStyles()
    const {data, onView, onRestore} = props

    const [anchorEl, setAnchorEl] = useState(null) 

    const onOpenMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const onCloseMenu = () => {
        setAnchorEl(null)
    }

    const onInnerView = () => {
        onView(data)
        onCloseMenu()
    }

    const onInnerRestore = () => {
        onRestore(data)
        onCloseMenu()
    }

    return(
        <div className={classes.singleProduct}>
            <PopperWrapper open={Boolean(anchorEl)} anchorEl={anchorEl} onClickAway={onCloseMenu}>
                <div className={classes.menu}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.item} onClick={onInnerView}><ContentText text='Ver' variant='bold'/></div>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                        <Grid item xs={12}>
                            <div className={classes.item} onClick={onInnerRestore}><ContentText text='Restaurar' variant='bold'/></div>
                        </Grid>
                    </Grid>
                </div>
            </PopperWrapper>
            <Grid container wrap='nowrap' spacing={2}>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Caption text='Nombre del producto' medium variant='bold'/>
                            </Grid>
                            <Grid item xs={12}>
                                <ContentText text={`${data.product_name}, ${data.product_presentation}`}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Caption text='Diagnóstico' medium variant='bold'/>
                            </Grid>
                            <Grid item xs={12}>
                                <ContentText text={`${data.diagnosis}`}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Caption text='Tipo' medium variant='bold'/>
                            </Grid>
                            <Grid item xs={12}>
                                <ContentText text={`${data.secondary === 1 ? 'Secundario' : 'Principal'}`}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Caption text='Estatus' medium variant='bold'/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container wrap='nowrap' spacing={1}>
                                    <Grid item><Icon className={classes.icon}
                                    style={{color:data.product_status_id === 2 ? green[700] : amber[700] }}>brightness_1</Icon></Grid>
                                    <Grid item><ContentText text={data.product_status}/></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={12}>
                            <IconButton onClick={onOpenMenu}><Icon>more_horiz</Icon></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SingleTreatment