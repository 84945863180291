import UserProfile from '../views/Admin/Profile/UserProfile/UserProfile'
import EditUserProfile from '../views/Admin/Profile/EditUserProfile/EditUserProfile.jsx';
import Applicants from '../views/Admin/Applicants/Applicants/Applicants';
import AddApplicant from '../views/Admin/Applicants/AddApplicant/AddApplicant';
import ApplicantView from '../views/Admin/Applicants/ApplicantView/ApplicantView';

const dashboardSelfService = {
    sections:[
        {
            path: "/profile",
            icon:'work',
            label:'Asesores',
            component: UserProfile,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/profile/edit",
            icon:'work',
            label:'Asesores',
            component: EditUserProfile,
            sidebar:false,
            exact:true,
            level:4
        },
        /////////////////////////////////////////// APPLICANTS  ///////////////////////////////////////////////
        {
            path: "/applicants",
            icon:'supervisor_account',
            label:'Solicitantes',
            component: Applicants,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/applicants/add",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: AddApplicant,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/applicants/:id",
            icon:'supervisor_account',
            label:'Beneficiarios',
            component: ApplicantView,
            sidebar:false,
            exact:true,
            level:4
        },
        { redirect: true, path: "/", to: "/applicants", navbarName: "Redirect" }
    ]
}

export default dashboardSelfService;