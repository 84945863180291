import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, LinearProgress} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'

import {request_patient_stats, request_patient_stats_by_product, 
  request_inactive_patient_stats, request_product_stats, request_delivered_benefits, request_extended_patient_stats} from './requests'
import {cleanPatientStatsExcel, cleanPatientStats, cleanInactivePatientStats, 
  cleanPatientStatsByProduct, cleanProductStats, cleanInactivePatientStatsExcel,
  cleanPatientStatsByProductExcel, cleanProductStatsExcel, cleanDeliveredBenefits, cleanExtendedPatientStats,
  cleanExtendedPatientStatsExcel, cleanDeliveredBenefitsExcel} from './customFunctions'
import PatientStats from './PatientStats/PatientStats';
import InactivePatientStats from './InactivePatientStats/InactivePatientStats';
import ProductPatientStats from './ProductPatientStats/ProductPatientStats';
import ProductStats from './ProductStats/ProductStats';
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import DeliveredBenefitsStats from './DeliveredBenefitsStats/DeliveredBenefitsStats';
import ExtendedPatientStats from './ExtendedPatientStats/ExtendedPatientStats';
import FilterButton from '../../../../components/Buttons/FilterButton'
import { catalogs } from '../../../../variables/catalogs'
import { USER_TYPE } from '../../../../variables/config'
import { request_me } from '../../Applicants/AddApplicant/requests'
import GeneralView from './views/General/GeneralView'

class Analytics extends Component{

  state = {
    isloading:false,
    patient_stats:[],
    patient_stats_by_product:[],
    inactive_patient_stats:[],
    product_stats:[],
    delivered_benefits_stats:[],
    extended_patient_stats:[],
    selected:1,
    product_type_id:0,
    sending:false,
    modals:{
      log_detail:{open:false, issending:false, error:null},
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {history, language,parentuser, user_level} = this.props
    const {success, isloading, patient_stats, patient_stats_by_product, 
      inactive_patient_stats, product_stats,selected, 
      delivered_benefits_stats, extended_patient_stats, product_type_id, sending} = this.state
    const content = contentData[language]

    const _patient_stats = cleanPatientStats(patient_stats[0])
    const _inactive_patient_stats = cleanInactivePatientStats(inactive_patient_stats)
    const _patient_stats_by_product = cleanPatientStatsByProduct(patient_stats_by_product)
    const _product_stats = cleanProductStats(product_stats)
    const _delivered_benefits_stats = cleanDeliveredBenefits(delivered_benefits_stats)
    const _extended_patient_stats = cleanExtendedPatientStats(extended_patient_stats)

    let contentView = null

    let products_options = [...catalogs.spanish.product_types, {id: 0, value:0, label:'Todos' }]

    let product_filter_enable = false

    if(parentuser){
      product_filter_enable = parentuser.user_type_id === USER_TYPE.ADMIN || parentuser.user_type_id === USER_TYPE.ROCHE || parentuser.user_type_id === USER_TYPE.PRA
    }

    switch (selected) {
      case 1:
        contentView = <GeneralView user={parentuser} product_filter_enable={product_filter_enable} products_options={products_options} />;
        break
      case 10:
        contentView = (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' alignItems='flex-end' spacing={2}>
                <Grid item>
                  <RoundedButton 
                    label='Descargar Excel'
                    size='small'
                    color='primary'
                    onClick={this.exportData2Excel}
                  />
                </Grid>
                {product_filter_enable ? <Grid item>
                  <FilterButton selected={product_type_id} label='Tratamiento' options={products_options} 
                  onChange={this.onChangeProductTypeFilter} />
                </Grid> : null}
                
              </Grid>
            </Grid>
            {sending ? (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <PatientStats data={_patient_stats}/>
            </Grid>
            <Grid item xs={12}>
              <InactivePatientStats data={_inactive_patient_stats}/>
            </Grid>
            <Grid item xs={12}>
              <ProductPatientStats data={_patient_stats_by_product}/>
            </Grid>
            <Grid item xs={12}>
              <ProductStats data={_product_stats}/>
            </Grid>
          </Grid>
        )
        break;
      case 2:
        contentView = (
          <ExtendedPatientStats data={_extended_patient_stats} user={parentuser}/>
        )
        break
      case 3:
        contentView = (
          <DeliveredBenefitsStats data={_delivered_benefits_stats} user={parentuser}/>
        )
      break
    
      default:
        break;
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
        selected={selected}
        onChange={this.onChangeView}
      >
        {contentView}
        
      </BasicView>
      
    )
  }

  onChangeView = (id) => {
    this.setState({selected:id})
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) this.initModule()
  }



  initModule = async() => {
    //try {
    //  const _user = await request_me()
    //  
    //} catch (error) {
    //  console.log(error)
    //}
    //this.setState({isloading:true})
    //await this.onRequestGeneralAnalytics()
    //this.setState({isloading:false})
  }

  onRequestGeneralAnalytics = async(_product_type_id) => {
    try {
      // Verify the kind of products the user needs to see.
      let products_params = {}
      let _product_type = 1
      const {product_type_id} = this.state
      this.setState({sending:true})

      const _user = await request_me()
      let product_type = (_product_type_id !== undefined && _product_type_id !== null)  ? _product_type_id : product_type_id
      if(_user){
        console.log(_user.user_type_id)
        switch(_user.user_type_id){
          case USER_TYPE.ADMIN:
          case USER_TYPE.ROCHE:
            if(product_type !== 0) products_params.product_type_id = product_type
            break;
          case USER_TYPE.MERCURIO:
            products_params.product_type_id = _user.agency_id === 1 ? 1 : 2
            product_type = _user.agency_id === 1 ? 1 : 2
            break;
          case USER_TYPE.HOSPITAL:
            products_params.product_type_id = 1
            product_type = 1
            break;
          default:
        }
      }
      let response = await request_patient_stats(products_params)
      this.setState({patient_stats:JSON.parse(JSON.stringify(response)), product_type_id:product_type})
      response = await request_patient_stats_by_product(products_params)
      this.setState({patient_stats_by_product:JSON.parse(JSON.stringify(response))})
      response = await request_inactive_patient_stats(products_params)
      this.setState({inactive_patient_stats:JSON.parse(JSON.stringify(response))})
      response = await request_product_stats(products_params)
      this.setState({product_stats:JSON.parse(JSON.stringify(response))})
      response = await request_delivered_benefits(products_params)
      this.setState({delivered_benefits_stats:JSON.parse(JSON.stringify(response))})
      response = await request_extended_patient_stats(products_params)
      this.setState({extended_patient_stats:JSON.parse(JSON.stringify(response)), sending:false})
      
    } catch (error) {
      console.log(error)
    }
  }

  onChangeProductTypeFilter = async(id) => {
    console.log(id)
    await this.onRequestGeneralAnalytics(id)
    console.log('Terminar')
    this.setState({product_type_id:id})
  }

  exportData2Excel = () => {
    const {patient_stats, inactive_patient_stats, patient_stats_by_product, product_stats} = this.state
    const _patient_stats = cleanPatientStatsExcel(patient_stats[0])
    const _inactive_patient_stats = cleanInactivePatientStatsExcel(inactive_patient_stats)
    const _patient_stats_by_product = cleanPatientStatsByProductExcel(patient_stats_by_product)
    const _product_stats = cleanProductStatsExcel(product_stats)

    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Oncopassport Analytics",
      Subject: "General Data",
      Author: "Trionix Technologies",
      CreatedDate: new Date()
    };
    wb.SheetNames.push("Beneficiarios");
    let ws_data_patients = _patient_stats
    let ws_patients = XLSX.utils.aoa_to_sheet(ws_data_patients)
    wb.Sheets["Beneficiarios"] = ws_patients;

    wb.SheetNames.push("Beneficiarios Inactivos");
    let ws_data_inactive_patients = _inactive_patient_stats
    let ws_inactive_patients = XLSX.utils.aoa_to_sheet(ws_data_inactive_patients)
    wb.Sheets["Beneficiarios Inactivos"] = ws_inactive_patients;

    wb.SheetNames.push("Beneficiarios Producto");
    let ws_data_patient_stats_by_product = _patient_stats_by_product
    let ws_patient_stats_by_product = XLSX.utils.aoa_to_sheet(ws_data_patient_stats_by_product)
    wb.Sheets["Beneficiarios Producto"] = ws_patient_stats_by_product;

    wb.SheetNames.push("Producto");
    let ws_data_product_statss = _product_stats
    let ws_product_stats = XLSX.utils.aoa_to_sheet(ws_data_product_statss)
    wb.Sheets["Producto"] = ws_product_stats;

    let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    FileSaver.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}),'analytics.xlsx');


  }

  exportData2ExcelSecond = () => {
    const { extended_patient_stats} = this.state
    const _extended_patient_stats = cleanExtendedPatientStatsExcel(extended_patient_stats)

    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Oncopassport Analytics",
      Subject: "General Data",
      Author: "Trionix Technologies",
      CreatedDate: new Date()
    };

    wb.SheetNames.push("Pacientes extendidos");
    let ws_data_inactive_patients = _extended_patient_stats
    let ws_inactive_patients = XLSX.utils.aoa_to_sheet(ws_data_inactive_patients)
    wb.Sheets["Pacientes extendidos"] = ws_inactive_patients;

    let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    FileSaver.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}),'analytics_patients.xlsx');

  }

  exportData2ExcelThird = () => {
    const {delivered_benefits_stats} = this.state
    const _delivered_benefits_stats = cleanDeliveredBenefitsExcel(delivered_benefits_stats)

    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Oncopassport Analytics",
      Subject: "General Data",
      Author: "Trionix Technologies",
      CreatedDate: new Date()
    };
    wb.SheetNames.push("Beneficios entregados");
    let ws_data_patients = _delivered_benefits_stats
    let ws_patients = XLSX.utils.aoa_to_sheet(ws_data_patients)
    wb.Sheets["Beneficios entregados"] = ws_patients;

    let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    FileSaver.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}),'analytics_benefits.xlsx');

  }

  s2ab = (s) =>{ 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
  }
  

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  
}

export default withStyles(styles)(Analytics)