import React, { Component } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import BasicInfoContainer from './components/BasicInfoContainer';
import { request_patient, request_hospitals, request_hospital, request_edit_patient, 
  request_download_file, request_approve_product, request_send_approval, request_change_approval_status,
  request_patient_insurance, 
  request_patientproducts} from '../../requests';
import HospitalContainer from './components/HospitalContainer';
import InsuranceContainer from './components/InsuranceContainer';
import StatusContainer from './components/StatusContainer';
import ActionButtonsContainer from './components/ActionButtonsContainer';
import StatusHeader from './components/StatusHeader';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import { getUserType, onValidateProfile, getPatientProduct } from '../../../../../../shared/utility';
import { onFilterPatientStatuses } from '../../../../../../shared/customFunctions';
import PatientStatusModal from './modals/PatientStatusModal/PatientStatusModal';
import LetterStatusModal from './modals/LetterStatusModal/LetterStatusModal';
import { APPROVAL_STATUS, PATIENT_STATUS } from '../../../../../../variables/config';
import ErrorInfoModal from '../../../../../../components/Modals/ErrorInfoModal';
import { formData } from '../../data';
import EditBasicInfoModal from './modals/EditBasicInfoModal/EditBasicInfoModal';
import EditHospitalInfoModal from './modals/EditHospitalInfoModal/EditHospitalInfoModal';
import EditInsuranceModal from './modals/EditInsuranceModal/EditInsuranceModal';
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout';
import { request_policy_amounts, request_medical_report } from './requests';
import InactiveReasonModal from '../../Modals/InactiveReasonModal/InactiveReasonModal';

class GeneralInfoCard extends Component {

  state = {
    isloading:false,
    error:null,
    patient:null,
    hospital:null,
    patient_insurance:null,
    medical_report:null,
    error_message:null,
    policy_amounts:[],
    hospitals:[],
    doctors:[],
    modals:{
      edit_patient_status:{open:false,issending:false,error:null},
      edit_letter_status:{open:false,issending:false,error:null},
    },
    modalsv2:{
      inactive_reason:false
    },
    edit_basic_info_modal:false,
    edit_hospital_modal:false,
    edit_insurance_carrier_modal:false,
  }

  render(){

    const {hospital, isloading, modals, patient, error, error_message, patient_insurance} = this.state
    // Modals in state
    const {edit_basic_info_modal, edit_hospital_modal, edit_insurance_carrier_modal, policy_amounts } = this.state

    const {classes, catalogs, issending, onEdit, user_level} = this.props

    let approvalStatus = null
    let status = patient?.status_id
    if(patient){
      approvalStatus = patient.status_id
    }
    //console.log('PATIENT STATUS', status)
      // It is important to consider onChangePatientStatus for the right user type
    const userType = getUserType()

    let errorModal = null
    if(error) errorModal = <ErrorInfoModal  data={error} onClose={() => this.setState({error:null})}/>

    ////////////////////////////////////  MODALS SECTION //////////////////////////////////////////////////
    
    let modalcontent

    if(edit_basic_info_modal){
      modalcontent = <EditBasicInfoModal origin={patient} user_level={user_level}
      onUpdateData={(data) => this.setState({patient:data, edit_basic_info_modal:false})}
      onClose={() => this.setState({edit_basic_info_modal:false})}/>
    }

    if(edit_hospital_modal){
      modalcontent = <EditHospitalInfoModal origin={patient} 
      onUpdateData={(patient_data, hospital_data) => this.setState({patient:patient_data, hospital:hospital_data, edit_hospital_modal:false})}
      onClose={() => this.setState({edit_hospital_modal:false})}/>
    }

    if(edit_insurance_carrier_modal){
      modalcontent = <EditInsuranceModal origin={patient} catalogs={catalogs} patient_insurance={patient_insurance}
      policy_amounts={policy_amounts} 
      onUpdateData={(data, insuranceData) => this.setState({patient:data, patient_insurance:insuranceData, edit_insurance_carrier_modal:false})}
      onClose={() => this.setState({edit_insurance_carrier_modal:false})}/>
    }


    //let patient_statuses = onFilterPatientStatuses(patient, catalogs)
    if(modals.edit_patient_status.open){
      modalcontent = (
        <PatientStatusModal 
          addSpace
          origin_data={patient ? {status_id:patient.status_id, inactive_reason_id: patient.inactive_reason_id} :{}}
          catalogs={{patient_statuses:[...catalogs.patient_statuses], inactive_reasons:[...catalogs.inactive_reasons]}}
          data={modals.edit_patient_status}
          onClose={() => this.onCloseModal('edit_patient_status')}
          onSubmit={(data) => this.onUpdatePatient(data,'edit_patient_status')}
        />
      )
    }

    if(modals.edit_letter_status.open){
      modalcontent = (
        <LetterStatusModal 
          addSpace
          origin_data={patient ? {letter_status_id:patient.letter_status_id} :{}}
          catalogs={catalogs}
          data={modals.edit_letter_status}
          onClose={() => this.onCloseModal('edit_letter_status')}
          onSubmit={(data) => this.onUpdatePatient(data,'edit_letter_status')}
        />
      )
    }

    
    return(
      <SimpleContainer isloading={isloading}>  
        <InactiveReasonModal patient={patient}
        open={this.state.modalsv2.inactive_reason}
        onUpdatePatient={(data) => this.setState({patient:data})}
        onClose={this.onCloseInactiveModal}
        />
        {errorModal} 
        {modalcontent}
        <Grid container spacing={3}>
          <ContraintLayout md={12}>
            <StatusHeader 
              data={patient} 
              error_message={error_message}
              issending={issending}
              onChangeApprovalStatus={this.onChangeApprovalStatus} />
          </ContraintLayout>
          <Grid item xs={12}>
            <CardWrapper>
              <div className={classes.root}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <BasicInfoContainer data={patient} 
                      onEdit={() => this.setState({edit_basic_info_modal:true})}/>
                    </Grid>
                    <Grid item xs={12}>
                      <HospitalContainer data={hospital}
                      onEdit={() => this.setState({edit_hospital_modal:true})}/>
                    </Grid>
                    <Grid item xs={12}>
                      <InsuranceContainer data={patient} patient_insurance={patient_insurance}
                      onEdit={() => this.setState({edit_insurance_carrier_modal:true})}/>
                    </Grid>
                    <Grid item xs={12}>
                      <StatusContainer data={patient} userType={userType} 
                      onChangeLetterStatus={status === PATIENT_STATUS.ACTIVE 
                        ? () => this.onOpenModal('edit_letter_status') : null}
                      onChangePatientStatus={this.onPreChangePatientStatus}/>
                    </Grid>
                    <Grid item xs={12}>
                      <ActionButtonsContainer 
                        data={patient}
                        onEdit={onEdit}
                        onDownloadDoctorLetter={this.onDownloadDoctorLetter}
                        onDownloadPatientLetter={this.onDownloadPatientLetter}/>
                    </Grid>              
                </Grid>
              </div>
            </CardWrapper>
          </Grid>
        </Grid>
      </SimpleContainer>
      

      
    )
  }

  onOpenInactiveModal = () => {
    let _modals = {...this.state.modalsv2}
    _modals.inactive_reason = true
    this.setState({modalsv2:_modals})
  }

  onCloseInactiveModal = () => {
    let _modals = {...this.state.modalsv2}
    _modals.inactive_reason = false
    this.setState({modalsv2:_modals})
  }

  

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    try {
      const {id} = this.props.match.params
      this.setState({isloading:true})
      const patient = await request_patient(id)
      const {main_hospital_id, payment_type_id} = patient
      const hospitals = await request_hospitals()
      const policy_amounts = await request_policy_amounts()
      let hospital = null
      let patient_insurance = null
      if(main_hospital_id) hospital = await request_hospital(main_hospital_id)
      if(payment_type_id !== 2){
        try{
          patient_insurance = await request_patient_insurance(id)
        }catch(e){
          //console.log(e.response)
        }        
        
      }
      let medical_report = null
      try {
        medical_report = await request_medical_report(id)
      } catch (error) {
        //console.log(error.response)
      }
       
      this.setState({
        isloading:false,
        patient:patient,
        hospital:hospital,
        hospitals:hospitals,
        patient_insurance:patient_insurance,
        medical_report:medical_report,
        policy_amounts:policy_amounts
      })
    } catch (error) {
      this.setState({isloading:false})
      console.log(error)
    }
  }

  getPatientProduct = (patientproducts, type) => {
    let result = null
    if(patientproducts && patientproducts.length){
      if(type === 'primary') result = patientproducts.find(el => el.product_status_id === 1 && el.secondary === 0)
      if(type === 'secondary') result = patientproducts.find(el => el.secondary === 1  && el.secondary === 1)
    }
    return result
  }

  /////////////////////////// STATUS MANAGER ////////////////////////////////
  onChangeApprovalStatus = async(id) => {
    if(this.state.issending) return
    this.setState({issending:true})
    const {patient_products, status_id} = this.state.patient
    console.log(id)
    console.log('Change approval status')

    let updateStatus = true
    try{
      const {match} = this.props
      const patient_id = match.params.id
      if(id === PATIENT_STATUS.ACTIVE){
        console.log('Cambiar a aprobado', id, status_id)
        console.log(patient_products)
        if(status_id !== PATIENT_STATUS.INACTIVE){
          if(!patient_products.length){
            this.setState({error:'No se encontró ningún tratamiento activo, verifique la información por favor. '})
            return
          }
  
          for(let i=0; i<patient_products.length; i++){
            try {
              await request_approve_product(patient_products[i].id_patient_product)
            } catch (error) {
              this.setState({error:'Hubo un error al aprobar el tratamiento'})
              return
            }
          }
  
          let response = await request_edit_patient(patient_id, {
            status_id:PATIENT_STATUS.ACTIVE,
            letter_status_id:2
          }) 
          this.setState({patient: {...response}})
        }else{
          console.log('ESTOY EN ESTATUS INACTIVO Y PASARE A ACTIVO')
          let response = await request_edit_patient(patient_id, {
            status_id:PATIENT_STATUS.ACTIVE,
          }) 
          this.setState({patient: {...response}})
        }
        
      }
      if(id === PATIENT_STATUS.IN_PROCESS){
        console.log('Cambiar a pendiente')
        if(status_id !==1){
          let response = await request_edit_patient(patient_id, {
            status_id:PATIENT_STATUS.IN_PROCESS,
            letter_status_id:1
          }) 
          this.setState({patient: {...response}})
        }else{

          console.log('Convertir a beneficiario para aprobar')
          //const patientproducts = await request_patientproducts(patient_id)
          //const primary_patientproduct = getPatientProduct(patientproducts, 'primary')
          //const secondary_patientproduct = getPatientProduct(patientproducts, 'secondary')
          ////console.log(patientproducts)
          ////console.log(primary_patientproduct)
          ////console.log(secondary_patientproduct)
//
          const {patient, patient_insurance, medical_report} = this.state
          //console.log(patient_insurance)
          const {response_status, error_message} = onValidateProfile(patient, patient_insurance)
          console.log(response_status)
          ////console.log(statusResponse)
          ////console.log(response_status)
          ////console.log(error_message)
          ////const {response_status, error_message} = this.onCheckPatientValidation()
          if(!response_status){
            this.setState({error_message:error_message})
            updateStatus=false
            return
          }else{
            await request_send_approval(patient_id)
            this.setState({error_message:null})
          }
          //await request_send_approval(patient_id)
          //this.setState({error_message:null})
        }
        
      }
      if(id === PATIENT_STATUS.PROSPECT){
        let response = await request_edit_patient(patient_id, {
          status_id:PATIENT_STATUS.PROSPECT,
          //inactive_reason_id:6
        }) 
        this.setState({patient: {...response}})
      }
      if(id === PATIENT_STATUS.INACTIVE){
        console.log('Abrir modal de razon de inanctivdiada')
        this.onOpenInactiveModal()
        //this.onOpenModal('edit_patient_status') 
        //let response = await request_edit_patient(patient_id, {
        //  status_id:PATIENT_STATUS.INACTIVE,
        //  inactive_reason_id:6
        //}) 
        //this.setState({patient: {...response}})
      }
      
      if(updateStatus){
        // rEVISAR ESTE TEMA CON KIKE
        let response = await request_change_approval_status(patient_id, {status_id:id})
        this.setState({patient:{...response}})
      }
      

    }catch(e){
      console.log(e)
    }
    this.setState({issending:false})
  }

  onCheckPatientValidation = () => {
    const {patient} = this.state

    let status = true
    let message = null
    Object.keys(formData).forEach(item => {
      if(item !== 'product_id' && item !== 'diagnosis_id' && item !== 'expected_uses')
      if(formData[item].isRequired){
        if(patient[item] === null || patient[item] === undefined || patient[item] === ''){
          console.log(item)
          status = false
        }
      }
    })

    if(!status){
      message = 'Falta llenar información del beneficario en la sección de general'
    }

    if(patient.patient_products.length === 0){
      message = 'Falta llenar al menos un tratamiento'
      status = false
    }else{
      
      if(!patient.patient_products[0].benefit_uses){
        message = 'Falta calcular oferta'
        status = false
      }
    }

    if(!patient.nse_id){
      message = 'Falta definir nivel socioeconómico'
      status = false
    }

    return {
      response_status:status,
      error_message:message
    }

  }

  /////////////////////////// LETTERS MANAGER ////////////////////////////////

  onDownloadDoctorLetter = async() => {
    const {id} = this.props.match.params
    try{
      await request_download_file(id, 'doctorletter')
    }catch(e){
      const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
      this.setState({error:errorMessage})
    }
  }

  onDownloadPatientLetter = async() => {
    const {id} = this.props.match.params
    try{
      await request_download_file(id, 'patientletter')
    }catch(e){
      const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
      this.setState({error:errorMessage})
    }
  }

    /////////////////////////// PATIENT MANAGER ////////////////////////////////


  onPreChangePatientStatus = async() => {
    
    try {
      //const hospitals = await request_hospitals()
      this.onOpenModal('edit_patient_status') 
    } catch (error) {
      console.log(error)
    }
  }

  onUpdatePatient = async(data, type) => {
    const {id} = this.props.match.params
    this.setState({issending:true})
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_edit_patient(id,data)
      this.setState({patient:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
    this.setState({issending:false})
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
    
  }
}

const styles = theme => ({
  root:{
    padding:32,
    [theme.breakpoints.down('sm')]: {
      padding:16,
    },
  }
})

export default withStyles(styles)(GeneralInfoCard)