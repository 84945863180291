import React, {Component} from 'react'
import { TableRow,TableCell,  Table, TableBody } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ContentText from '../../../../../components/Texts/ContentText';


const styles = (theme) => ({
  root:{},
  table:{
    minWidth:500
  },
  tableWrapper:{
    overflowX: 'auto',
  },
  icon:{
    fontSize:12,
  }
})

class ProductPatientStatsTable extends Component{

  render(){

    const {classes, data} = this.props

    return(
      
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              {data[0].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} style={{border:0, paddingTop:0, paddingBottom:0}} align='center'>
                    <ContentText text={item} variant='bold' medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {data[1].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant={key ? 'light' : null} medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {data[2].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant={key ? 'light' : null} medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {data[3].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant={key ? 'light' : null} medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {data[4].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant={key ? 'light' : null} medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {data[5].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant={key ? 'light' : null} medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {data[6].map((item,key)=>{
                return(
                  <TableCell key={key.toString()} align='center'>
                    <ContentText text={item} variant={key ? 'light' : null} medium/>
                  </TableCell>
                )
              })}
            </TableRow>
            
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(ProductPatientStatsTable)