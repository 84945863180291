import React, {Component} from 'react'
import { withStyles, Grid, Avatar } from '@material-ui/core';
import HorizontalDataWrapper from '../../../../../../components/Texts/HorizontalDataWrapper';
import ContentText from '../../../../../../components/Texts/ContentText';
import { indigo, green, red } from '@material-ui/core/colors';
import Subtitle from '../../../../../../components/Texts/Subtitle';

class TreatmentCard extends Component {

  render(){

    const {classes, data, benefit, type} = this.props

    let percentage = (benefit ? parseFloat(benefit.percentage) : 0.00)*100
    let title = ''
    if(type==='primary'){
      title = 'Tratamiento'
    }else{
      title = 'Tratamiento Adyuvante'
    }

    let isTreatmentValid = false
    if(benefit.benefit + benefit.uses < parseInt(data.cycles)){
      isTreatmentValid = true
    }


    return(
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <ContentText text={title} variant='bold' medium/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HorizontalDataWrapper label='Producto principal' text={data.product} endSpacing/>
            </Grid>
            <Grid item xs={12}>
              <HorizontalDataWrapper label='Presentación principal' text={data.presentation} endSpacing/>
            </Grid>
            <Grid item xs={12}>
              <HorizontalDataWrapper label='Dosis' text={data.dose} endSpacing/>
            </Grid>
            <Grid item xs={12}>
              <HorizontalDataWrapper label='Frecuencia' text={data.treatment_frequency} endSpacing/>
            </Grid>
            <Grid item xs={12}>
              <HorizontalDataWrapper label='Diagnóstico' text={data.diagnostic} endSpacing/>
            </Grid>
            <Grid item xs={12}>
              <HorizontalDataWrapper label='Ciclos' text={data.cycles} endSpacing/>
            </Grid>
            <Grid item xs={12}>
              <HorizontalDataWrapper 
              label={'Viabilidad'} 
              icon={isTreatmentValid ? 'check_circle' : 'error'} 
              iconColor={isTreatmentValid ? green[700] : red[700]} 
              text={isTreatmentValid ? 'Válido' : 'No válido'} endSpacing/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container 
            direction='column' 
            alignItems='center' 
            justifyContent='center'
            spacing={1}
          >
            <Grid item>
              <Subtitle text={'PLAN A OFRECER'} medium/>
            </Grid>
            <Grid item>
              <div className={classes.root}>
                <div>
                  <Avatar className={classes.firstCircle}>
                    {benefit ? benefit.uses.toString() : '0'}
                  </Avatar>
                </div>
                <div className={classes.secondContainer}>
                  <Avatar className={classes.secondCircle}>
                  {'+'+(benefit ? benefit.benefit.toString() : '0')}
                  </Avatar>
                </div>
              </div>
            </Grid>
            <Grid item>
              <ContentText text={`Ahorro del ${percentage.toFixed(2)}%`} variant='light' medium/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root:{
    position:'relative'
  },
  secondContainer:{
    top:'65px',
    marginTop:'-40px',
    right:0,
    marginRight:'-50px',
    position:'absolute'
  },
  firstCircle:{
    width:'130px',
    height:'130px',
    color:'white',
    background:indigo[900],
    fontSize:72,
  },
  secondCircle:{
    width:'80px',
    height:'80px',
    color:'white',
    background:theme.palette.secondary.main,
    fontSize:36,
  },
  firstNumber:{}
})

export default withStyles(styles)(TreatmentCard)