export const formData = {
  sectionA:{
    first_name:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'first_name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    second_name:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'second_name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:0,
        max:100
      }
    },
    first_surname:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'first_surname',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:0,
        max:100
      }
    },
    second_surname:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'second_surname',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:0,
        max:100
      }
    },
    crm_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'crm_id',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    uci:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'uci',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
  },
}