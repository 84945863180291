import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import ContentText from '../../../../../components/Texts/ContentText';
import NSEContainer from './NSEContainer/NSEContainer';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';


const styles = (theme) => ({
  root:{padding:'32px'}
})



class EditBasicInfo extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData.sectionA)),
    balance:false
  }

  updateData2Form = (form, data) => {
    let temp = {...form}
    Object.keys(form).forEach(item => {
      if(data){
        let value = data[item];
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        temp[item].value = value!== undefined && value!==null ? value : '';
        temp[item].isValid = value!== undefined && value!==null ? true : false;
      }
    });
    return temp
  }

  

  componentDidMount(){
    const {patient, language} = this.props;
    const {formData} = this.state;
    const content = contentData[language];
    let temp = {...formData};
    let r_balance = false
    if(patient){
      r_balance = patient.balance ? true : false
    }
    Object.keys(formData).forEach(item => {
      if(patient){
        let value = patient[item];
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        temp[item].value = value!== undefined && value!==null ? value : '';
        temp[item].isValid = value!== undefined && value!==null ? true : false;
      }
      temp[item].config = {...temp[item].config,...content.sectionA[item]}
    })
    
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm, balance:r_balance})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

    if(id === 'zip'){
      this.props.onRequestNSE(data.value)
    }
    if(id === 'external_id'){
      this.props.onRequestExternalID(data.value)
    }
    if(id === 'folio'){
      this.props.onRequestFolio(data.value)
    }
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, patient} = this.props;
    const {formData, balance} = this.state;
    let data2Send = {};
    
    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid && formData[item].value !== patient[item]){
        data2Send = {...data2Send, [item]:formData[item].value}
      }
    })
    /// Manage insurance step
    data2Send = {...data2Send,
      payment_type_id: formData.payment_type_id.value,
    }

    const {policy_amounts} = this.props;
    switch (formData.payment_type_id.value) {
      case 1:
      case 3:
        data2Send = {
          ...data2Send,
          insurance_carrier_id:formData.insurance_carrier_id.value,
          policy_amount_id:formData.policy_amount_id.value,
          balance: balance ? 1 : 0
        }
        if(balance){
          if(formData.balance_value !== null){
            const selectedCatalog = policy_amounts.find( el => 
              formData.balance_value.value >= el.min_value && 
              formData.balance_value.value <= el.max_value
            )
            data2Send = {...data2Send, policy_value_id: selectedCatalog.policy_value_id}
          }
        }else{
          const selectedCatalog = policy_amounts.find(el => 
            el.id_policy_amount === formData.policy_amount_id.value
          )
          data2Send = {...data2Send, policy_value_id: selectedCatalog ? selectedCatalog.policy_value_id : undefined}
        }
        break;
      case 2:
        data2Send = {
          ...data2Send,
          balance:1,
          policy_amount_id:7,
          policy_value_id:6
        }
        break;
      default:
        break;
    }

    // Clean nulls by undefined

    Object.keys(data2Send).forEach(item => {
      console.log(item)
      if(data2Send[item] === '' || data2Send[item] ===  null){
        data2Send[item] = undefined
      }
    })
    //console.log(data2Send)
    onSubmit(data2Send)
  }

  render(){

    const {classes, language, catalogs, issending, error, doctors, public_insurances,
    nse, hospitals, insurance_carriers} = this.props
    const {formData, balance} = this.state
    const content = contentData[language]

    formData.doctor_id.options = [...doctors]
    formData.public_insurance_id.options = [...public_insurances]
    formData.nse_id.options = [...catalogs.nses]
    formData.main_hospital_id.options = [...hospitals]
    formData.payment_type_id.options = [...catalogs.payment_types]
    formData.insurance_carrier_id.options = [...insurance_carriers]
    formData.policy_amount_id.options = [...catalogs.policy_amounts]

    //let tempForm = this.updateData2Form(formData, patient)

    let loaderContent = null
    if(issending){
      loaderContent = <SimpleLoader />
    }else{
      if(error){
        loaderContent = <ErrorMessage message={error}/>
      }
    }

    //console.log(formData.balance_value)

    let insuranceContent = null
    switch(formData.payment_type_id.value){
      case 1:
      case 3:
        insuranceContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formData.insurance_carrier_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formData.policy_amount_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {balance ? (
                <LineInputText 
                  data={formData.balance_value}
                  onChange={this.onInputChange}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ItemSelection 
                    id='balance'
                    status={balance}
                    text='Saldo'
                    onChange={this.onChangeCheckbox}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
        break;
      case 2:
        insuranceContent = null
        break;
      default:
        break;
    }
    
    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container>
                {/***************  PATIENT INFORMATION ******************/}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ContentText 
                        text={'Información de Beneficiario'} 
                        variant='bold'
                        medium
                      />
                    </Grid>
                    <Grid item xs={12}> 
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.name}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.external_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.folio}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formData.doctor_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.email}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.phone}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formData.zip}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formData.public_insurance_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          {nse ? (
                            <Grid item xs={12} md={6}>
                              <NSEContainer nse={nse}/>
                            </Grid>
                          ) : null}
                          {nse === null ? (
                            <Grid item xs={12} md={6}>
                              <LineInputSelect 
                                data={formData.nse_id}
                                onChange={this.onInputChange}
                              />
                            </Grid>
                          ) : null}
                          
                          
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/***************  HOSPITALS INFORMATION ******************/}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ContentText 
                    text={'Hospital/Clínica de consulta'} 
                    variant='bold'
                    medium
                  />
                </Grid>
                <Grid item xs={12}> 
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <LineInputSelect 
                        data={formData.main_hospital_id}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/***************  INSURANCE INFORMATION ******************/}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ContentText 
                    text={'Aseguradora'} 
                    variant='bold'
                    medium
                  />
                </Grid>
                <Grid item xs={12}> 
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <LineInputSelect 
                        data={formData.payment_type_id}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {insuranceContent}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  {loaderContent}
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={content.save_button} 
                    size='small' 
                    color='secondary'
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  onChangeCheckbox = (id, data) => {
    this.setState({[id]:data})
  }
}

export default withStyles(styles)(EditBasicInfo)