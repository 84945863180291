import { useState } from "react"
import { getFileExtension, onUpdateAuthorizationHeader } from "../shared/utility"
import { private_server } from "../variables/config"
import axios from 'axios'
import FileSaver from "file-saver"
const usePatientDocument = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [documents, setDocuments] = useState([])
    const [document, setDocument] = useState([])

    const actions = {
        
        onGetAllByPatient:async(patientid, params) => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientdocument/patient/${patientid}`, 
                    {params:{...params}}
                )
                response = request.data.data.patient_documents
                setDocuments(response)
            } catch (error) {
                setError('No se pudo obtener la lista de reportes médicos de manera correcta, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGet:async(patientdocumentid) => {
            setLoading(true)
            let response = null
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    `/patientdocument/${patientdocumentid}`
                )
                response = request.data.data.patient_document
                setDocument(response)
            } catch (error) {
                setError('No se pudo obtener el reporte médico, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onAdd:async(data) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.post(
                    `/patientdocument`,
                    data
                )
                response.status = true
            } catch (error) {
                let msg = 'No se pudo crear el documento, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
            }
            setLoading(false)
            return response
        },
        onUpdate:async(patientdocumentid, data) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                await private_server.patch(
                    `/patientdocument/${patientdocumentid}`,
                    data
                )
                response.status = true
            } catch (error) {
                let msg = 'No se crear el documento, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
            }
            setLoading(false)
            return response
        },
        onDelete:async() => {

        },
        onUploadDocument:async(patientdocumentid, file, path) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            const data2send = {ext:getFileExtension(file.name)}
            try {
                onUpdateAuthorizationHeader(private_server)
                let response = await private_server.post(
                    `/patientdocument/${patientdocumentid}/${path}`,
                    data2send
                )
                const options = {headers: {'Content-Type': file.type}}
                delete axios.defaults.headers.common["Authorization"]
                await axios.put(response.data.data.path, file, options);
                
                console.log('Lleve ahasta asdfui')
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            console.log(response)
            setLoading(false)
            return response
        },
        onDownloadDocument:async(patientdocumentid, path) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                let response = await private_server.get(
                    `/patientdocument/${patientdocumentid}/${path}`
                )
                delete axios.defaults.headers.common["Authorization"]
                response = await axios.get(response.data.data.path, {responseType: 'blob'});
                FileSaver.saveAs(response.data, `${path}`);
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            setLoading(false)
            return response
        },
        onDeleteDocument:async(patientdocumentid, path) => {
            setLoading(true)
            let response = {status:false, error:null, details:null}
            try {
                onUpdateAuthorizationHeader(private_server)
                await private_server.delete(
                    `/patientdocument/${patientdocumentid}/${path}`
                )
            } catch (error) {
                console.log(error)
                let msg = 'No se pudo subir el archivo, intente de nuevo'
                const code = error.response?.data?.error?.code
                if(code) msg = `ERROR ${code}: ${msg}`
                setError(msg)
                response.error = msg
                response.details = error.response
                setLoading(false)
                return response
            }
            response.status = true
            setLoading(false)
            return response
        },
    }

    const system = {loading, sending,error}
    const data = {documents, document}

    return {system, data, actions}



} 

export default usePatientDocument