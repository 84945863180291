import {mainServer} from '../../../../variables/config'


export const request_merge_catalog = async (path, id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/"+path+"/"+id+"/replace", data);
}


export const request_doctor = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/doctor/"+id);
  return response.data.data.doctor
}

export const request_doctors = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/all")
  return response.data.data.doctors
}

export const request_edit_doctor = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/doctor/"+id, data)
  let response
  response = await mainServer.instance.get("/doctor/"+id);
  return response.data.data.doctor
}

/* export const request_details = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/doctor/me/details");
  return response.data.data.doctor_details
}

export const request_details_translation = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/doctor/me/translations/1");
  return response.data.data.doctor_details_translation
} */
