export const contentData = {
  spanish:{
    layout:{
      title:'Editar Asesor',
      navigation:[
        {name:'Perfil',path:'/profile'},
        {name:'Editar',path:'/profile/edit'},
        
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
    password_modal:{
      label:'Cambiar Contraseña',
      button:'Actualizar',
      cancelButton:'Cancelar',
    },
  }
}