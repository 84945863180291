export const form_data = {
  status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'status_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  inactive_reason_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'inactive_reason_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
}