import {mainServer} from '../../../../variables/config'

export const request_public_insurance = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/publicinsurance/"+id);
  return response.data.data.public_insurance
}

export const request_edit_public_insurance = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/publicinsurance/"+id, data)
  let response
  response = await mainServer.instance.get("/publicinsurance/"+id);
  return response.data.data.public_insurance
}

export const request_merge_catalog = async (path, id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/"+path+"/"+id+"/replace", data);
}

export const request_public_insurances = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/publicinsurance/all")
  return response.data.data.public_insurances
}



/* export const request_details = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/public_insurance/me/details");
  return response.data.data.public_insurance_details
}

export const request_details_translation = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/public_insurance/me/translations/1");
  return response.data.data.public_insurance_details_translation
} */
