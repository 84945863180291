import {mainServer} from '../../../../variables/config'
import axios from 'axios'

export const request_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}

export const request_hospitals = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/hospital/all")
  return response.data.data.hospitals
}

export const request_edit_user = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/user/"+id, data)
  let response
  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}

export const request_uploadFile = async(id,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  const url = '/user/'+id+'/image'
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.upload_url, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}


export const request_deleteFile = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = '/user/'+id+'/image'
  await mainServer.instance.delete(url)
  let response;
  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}
