export const form_data = {
  product_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'product_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  diagnosis_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'diagnosis_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'hospital_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
	},
  expected_uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'expected_uses',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'numeric',
      min:0,
      max:50
    }
  },
  adjuvant:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'adjuvant',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
}