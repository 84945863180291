export const cleanData2Table = (data, nodata ) => {
  const table = data.map(item => {
    
    let temp = {
      id:item.id_user, 
      status:item.status, 
      image:item.image,
      data:[]
    }
    let fullname = ''
    fullname = fullname + (item.first_name ? item.first_name : nodata)
    fullname = fullname + ' '+(item.last_name ? item.last_name : '')
    let array = [
      fullname, 
      item.username ? item.username : nodata, 
      item.user_type ? item.user_type : nodata, 
      item.agency ? item.agency : '-', 
      item.email ? item.email : nodata, 
      item.mobile ? item.mobile : nodata, 
      item.status ? 'Activo' : 'Inactivo',
    ]
    temp.data = [...array]
    return temp
  })

  return table
}