import React, {Component, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import PatientList from './PatientList/PatientList'
import WarningModal from '../../../../components/Modals/WarningModal'


import {request_patients, request_delete_user, request_hospitals, request_doctors} from './requests'
import {cleanData2Table, getDoctorsOptions, getHospitalsOptions} from './customFunctions'
import SpecialPatientList from './SpecialPatientList/SpecialPatientList';
import usePatients from './usePatients'
import { USER_TYPE } from '../../../../variables/config'


const Patients = ({history, language, translations, parentuser}) => {

  const user_level = parentuser?.user_type_id

  const {
    loading, setLoading,
    patients, setPatients,
    hospitals, setHospitals,
    doctors, setDoctors,
    filter, setFilter,
    total, setTotal,
    table_config, settable_config,
    modals, setModals,
    success, setSuccess,
    expandable,
    view, setView,
    actions
  } = usePatients({history, user_level, parentuser})

  

  const content = contentData[language]
  const data_patients = cleanData2Table(patients,content.nodata,translations)

  const doctorOptions = getDoctorsOptions(doctors)
  const hospitalOptions = getHospitalsOptions(hospitals)

  //const user_level = parentuser?.user_type_id
  

  let modal_content = null
  if(modals.warning_delete_user){
    modal_content=(
      <WarningModal 
        data={modals.warning_delete_user}
        content={content.modals.warning_modal}
        onClose={() => actions.onCloseModal('warning_delete_user')}
        onDelete={() => console.log('onDeleteUser() fucntions')}
      />
    )
  }

  let superFilter
  let patientlistcontent = null

    ////////  MERCURIO USER /////////
    if(user_level === USER_TYPE.MERCURIO || user_level === USER_TYPE.ROCHE || user_level === USER_TYPE.ADMIN){
      const _superfilters = JSON.parse(JSON.stringify([...content.superfilters]))
      if(view === 2){
        _superfilters.shift()
        _superfilters.shift()
        superFilter = JSON.parse(JSON.stringify(_superfilters))
        superFilter[0].filters = [...hospitalOptions]
        superFilter[1].filters = [...doctorOptions]
      }else{
        superFilter = JSON.parse(JSON.stringify(_superfilters))
        superFilter[2].filters = [...hospitalOptions]
        superFilter[3].filters = [...doctorOptions]
      }
      //superFilter = JSON.parse(JSON.stringify(_superfilters))
      //superFilter[2].filters = [...hospitalOptions]
      //superFilter[3].filters = [...doctorOptions]
      
      patientlistcontent = (
        <PatientList 
          user_level={user_level} total_items={total} expandable={expandable}
          loading={loading}  view={view}
          filter={filter} 
          filters={content.filters}
          master_filters={superFilter}
          config={table_config}
          onChangeFilter={actions.onChangeFilter}
          header={content.header}
          data={data_patients}
          onUserSelected={actions.onPatientSelected}
          onAddPatient={actions.onAddPatient}
          onDeleteUser={actions.onPreDeleteUser}
          onChangeConfig={actions.onChangeTableConfig}
          onUpdateExpandableView={actions.onUpdateExpandableView}
        />      
      )
    ////////  OTHER USER /////////
    }else{
      const _superfilters = JSON.parse(JSON.stringify([...content.special_superfilters]))
      ////////  General view /////////
      if(view === 2){
        _superfilters.shift()
        _superfilters.shift()
        superFilter = JSON.parse(JSON.stringify(_superfilters))
        superFilter[0].filters = [...hospitalOptions]
        superFilter[1].filters = [...doctorOptions]
      ////////  Pending view /////////
      }else{
        superFilter = JSON.parse(JSON.stringify(_superfilters))
        superFilter[2].filters = [...hospitalOptions]
        superFilter[3].filters = [...doctorOptions]
      }

      //console.log(superFilter)
      
      //if(user_level === USER_TYPE.HOSPITAL) 

      patientlistcontent = (
        <SpecialPatientList 
          user_level={user_level} total_items={total} expandable={expandable}
          loading={loading} view={view}
          filter={filter}
          filters={content.filters}
          master_filters={superFilter}
          config={table_config}
          onChangeFilter={actions.onChangeFilter}
          header={content.special_header}
          data={JSON.parse(JSON.stringify(data_patients))}
          onUserSelected={actions.onPatientSelected}
          onAddPatient={actions.onAddPatient}
          onDeleteUser={actions.onPreDeleteUser}
          onChangeConfig={actions.onChangeTableConfig}
          onUpdateExpandableView={actions.onUpdateExpandableView}
        />      
      )
    }
  
  return(
    <BasicView
        history={history}
        selected={view}
        content={{
          ...content.layout,
          menu:[
            {id:1, label:'Vista general'},
            {id:2, label:'Autorizaciones pendientes'},
          ]
        }}
        onChange={actions.onChangeView}
        isloading={loading}
        success={success}
        onCloseSnackbar={actions.onCloseSnackbar} 
      >
        {modal_content}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {patientlistcontent}    
          </Grid>
        </Grid>
        
      </BasicView>
  )
}

export default Patients
