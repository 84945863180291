import React, {Component} from 'react'
import { 
  withStyles,
} from '@material-ui/core/styles'
import {   
  Dialog, 
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Icon} from '@material-ui/core'
import {grey, red} from '@material-ui/core/colors'

const styles = (theme) => ({
  container:{
    padding:'16px'
  },
  icon:{
    color:red[700],
    fontSize:54
  },
  title:{
    color:grey[900],
    fontWeight:'500'
  },
  message:{
    color:grey[700],
    fontWeight:'00'
  },
  svg:{
    width:90,
    height:90,
    borderRadius:'50%',
    color:grey[700]
  },
  button:{
    marginRight:8,
    textTransform:'none'
  },
  paper:{
    borderRadius:40
  },
  errorIcon:{
    color:red[700],
    fontSize:54
  }
})

class ErrorInfoModal extends Component {
  render(){

    const {classes, onClose, data} = this.props

    return(
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth='xs'
        fullWidth
        classes={{paper:classes.paper}}
      >
        <DialogContent>
          <div className={classes.container}>
            <Grid container spacing={2} direction='column' alignItems='center'>
              <Grid item>
                <Grid container direction='column' alignItems='center' spacing={1}>
                  <Grid item>
                    <Icon className={classes.errorIcon}>close</Icon>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6' className={classes.title}>
                      {'¡Opps!'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' align='center' className={classes.message}>
                      {data}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          
        </DialogContent>
        <DialogActions>
            <Button 
              onClick={onClose}
              size='medium'
              variant='text' 
              className={classes.button}>Cerrar</Button>
        </DialogActions>
        


      </Dialog>
    )
  }
}

export default withStyles(styles)(ErrorInfoModal)

