import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SingleUse from './SingleUse';


const UsesContent = (props) => {

    const classes = useStyles()

    const {uses, userType, benefitType, previous, patientproduct,
        onRefreshPatientProducts,
        onChangeBenefitStatus, 
        onRegisterUse, 
        onChangeUseDate,  
        onDownloadProviderLetter,
        noedit
    } = props

    const [_uses, setUses] = useState([])

    useEffect(() => {
        if(uses){
            const _data = []
            //console.log(uses)
            let last_benefit = 0
            for(let i=0; i<uses.length;i++){
                let use = {...uses[i]}
                if(use.free){
                    // verificar si es candidato a ser liberado
                    const isdatecompleted = checkAllDated(uses, last_benefit, i);
                    const iscompleted = checkAllApplied(uses, last_benefit, i);
                    last_benefit = i;
                    use.isdatecompleted=isdatecompleted
                    use.iscompleted=iscompleted
                }
                _data.push({...use})
            }
            setUses(_data)

        }
    },[uses])

    const checkAllApplied = (uses, startIdx, endIdx) => {
        let status = true
        if(startIdx >= uses.length-1 && endIdx <= uses.length-1){
            for(let i=startIdx; i<endIdx + 1; i++){
                const item = uses[i]
                if(item.applied === 0) status = false
            }
        }
        return status
    }
    const checkAllDated = (uses, startIdx, endIdx) => {
        let status = true
        if(startIdx <= uses.length-1 && endIdx <= uses.length-1){
            for(let i=startIdx; i<endIdx + 1; i++){
                const item = uses[i]
                if(!item.date) status = false
            }
        }
        return status
    }
    
    return ( 
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
               <TableBody>
                    <TableRow>
                        {_uses.map((item, key)=>{
                            return(
                                <TableCell key={key.toString()} style={{border:0}}>
                                    <SingleUse
                                        use={item} previous={previous} patientproduct={patientproduct}
                                        benefitType={benefitType}
                                        userType={userType}
                                        onRefreshPatientProducts={onRefreshPatientProducts}
                                        onChangeBenefitStatus={onChangeBenefitStatus}
                                        onRegisterUse={onRegisterUse} 
                                        onDownloadProviderLetter={onDownloadProviderLetter}
                                        onChangeUseDate={onChangeUseDate}
                                        noedit={noedit}
                                        />
                                </TableCell>
                            )
                        })}
                    </TableRow>
               </TableBody>
            </Table>
        </div>
     );
}

const useStyles = makeStyles({
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
})
 
export default UsesContent;