export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    title:'',
    sectionA:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Caracteres entre 1-255'
      },
      crm_id:{
        label:'CRM ID',
        placeholder:'CRM ID',
      },
      uci:{
        label:'UCI',
        placeholder:'UCI',
      },
    },
  },
}