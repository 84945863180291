export const contentData = {
  spanish:{
    title:'Cambiar Beneficio',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      benefit_id:{
        label:'Tipo de Beneficio',
        placeholder:'Tipo de Beneficio',
      },
    },
  },
}