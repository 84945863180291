import { useState } from "react";
import qs from 'querystring'
import { request_patients_v2 } from "./requests";
import { getUserType } from "../../../../shared/utility";
import { USER_TYPE } from "../../../../variables/config";

const useApplicants = ({history}) => {

    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    const [hospitals, setHospitals] = useState([])
    const [doctors, setDoctors] = useState([])
    const [filter, setFilter] = useState({general:null})
    const [total, setTotal] = useState(0)
    const [table_config, setTableConfig] = useState({
        filter:{
            general:null,
            status:null,
            doctor:null,
            hospital:null
        },
        searchText:'',
        page:0,
        rowsPerPage:5,
    })
    const [modals, setModals] = useState({
        warning_delete_user:{open:false, issending:false, error:null, id:null}
    })
    const [success, setSuccess] = useState({
        open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'
    })

    const userType = getUserType()

    const actions = {
        onPatientSelected: (id) => {
            let temp = { page:table_config.page, rowsPerPage:table_config.rowsPerPage}
            localStorage.setItem('patients_config', JSON.stringify(temp))
            history.push(`/applicants/${id}`)
        },
        onAddApplicant: () => {
            history.push('/applicants/add')
        },
        onPreDeleteUser: () => {

        },
        onChangeTableConfig: async(data) => {
            let temp = {...data}
            temp.isSearch = data.filter && data.filter.general && data.filter.general.id
            setTableConfig(temp)
        },
        onChangeFilter: (data) => {
            console.log(data)
        },
        onInitModule: async() => {
            try {
                setLoading(true)
                let _table_config = {...table_config}
                
                console.log(_table_config)
                // Check if there was something in local storage to save a previous config on filters
                const prev_config = localStorage.getItem('patients_config')

                if(prev_config){
                    _table_config = {..._table_config, ...JSON.parse(prev_config)}
                    setTableConfig(_table_config)
                }                
            } catch (error) {
                
            }
            setLoading(false)
        },
        onSearchAplicants: async() => {
            try {
                const params = onGetParamsQuery()  
                const patients_request = await request_patients_v2(params)
                setTotal(patients_request.count)
                setPatients(patients_request.patients)
            } catch (error) {
                console.log(error)
            }
        },
        onEditModal: (section,data) => {
            let _modals = {...modals}
            _modals[section] = {..._modals[section], ...data}
            setModals(_modals)
        },
        onCloseModal: (type) => {
            actions.onEditModal(type, {open:false, issending:false, error:null})
        },
        onCloseSnackbar: () => {
            let temp = {...success}
            temp.open = false
            setSuccess(temp)
        },
        onActivateSuccessSnack: () => {
            let temp = {...success}
            temp.open = true
            setSuccess(temp)
        }
    }

    const onGetParamsQuery = () => {
        const {filter, searchText, page, rowsPerPage} = table_config

        let args = {limit:rowsPerPage, offset:page*rowsPerPage, order:1, order_by:'external_id'}
        if(userType === USER_TYPE.HOSPITAL){
            args.order_by = 'id_patient'
        }
        let filterArgs = []
        filterArgs.push({"field":"product_type_id","operator":"=","value":1})
        if(searchText) filterArgs.push({"field":"name","operator":"LIKE","value":`%${searchText}%`})
        if(filter && filter.status && filter.status.id) filterArgs.push({"field":"status_id","operator":"=","value":`${filter.status.id}`})
        let response = `?${qs.stringify(args)}`
        if(filterArgs.length) response = `${response}&filter=${encodeURIComponent(JSON.stringify(filterArgs))}`
        return response
    }


    return {
        loading, setLoading,
        patients, setPatients,
        hospitals, setHospitals,
        doctors, setDoctors,
        filter, setFilter,
        total, setTotal,
        table_config, setTableConfig,
        modals, setModals,
        success, setSuccess,
        actions
    }

}

export default useApplicants;