import React, {useState} from 'react'
import SimpleDialog from '../../../../../../../../../components/Modals/SimpleDialog'
import { Icon, Grid, makeStyles } from '@material-ui/core'
import ContentText from '../../../../../../../../../components/Texts/ContentText'
import Subtitle from '../../../../../../../../../components/Texts/Subtitle'
import { getPatientProduct } from '../../../../../../../../../shared/utility'
import { red, grey } from '@material-ui/core/colors'
import { request_edit_patient_product, request_patientproducts } from '../requests'
import ItemSelection from '../../../../../../../../../components/Inputs/ItemSelection/ItemSelection'

const useStyles = makeStyles(theme=>({
    root:{},
    icon:{color:theme.palette.secondary.main, fontSize:54}
}))

const RestoreProductModal = props => {
    const classes = useStyles()
    const {onClose, selectedProduct, patientproducts, onUpdateData, onBack2Main} = props
    const [isCompleted, setIsCompleted] = useState(true)
    const [issending, setIsSending] = useState(false)
    const [error, setError] = useState(null)

    const onRestoreProduct = async(primary, secondary) => {
        const {id} = props.match.params
        const {id_patient_product} = selectedProduct
        //const productType = selectedProduct.secondary ? 'secondary' : 'primary'
        setIsSending(true)

        //let data2send = {product_status_id: isCompleted ? 2 : 3}
        try {
            /* if(primary && productType === 'primary'){
                await request_edit_patient_product(primary.id_patient_product, data2send)
            }
            if(secondary && productType === 'secondary'){
                await request_edit_patient_product(secondary.id_patient_product, data2send)
            }
            await request_edit_patient_product(id_patient_product, {product_status_id:1}) */

            await request_edit_patient_product(id_patient_product, {product_status_id:1})

            let patientproducts = await request_patientproducts(id)
            onUpdateData(patientproducts)
            onBack2Main()
            onClose()
        } catch (error) {
            console.log(error.response)
            setError('Opps! Hubo un error, intente de nuevo')
        }
        setIsSending(false)
    }


    let data = {issending:issending, error:error}

    const primary_patientproduct = getPatientProduct(patientproducts, 'primary')
    const secondary_patientproduct = getPatientProduct(patientproducts, 'secondary')
    const productType = selectedProduct.secondary ? 'secondary' : 'primary'

    console.log(productType)
    console.log(primary_patientproduct)
    console.log(secondary_patientproduct)

    let infoContent = null
    let isAnotherProduct = false

    /* if(productType === 'primary'){
        if(primary_patientproduct){
            isAnotherProduct=true
            infoContent = (
                <Grid item xs={12}>
                    <ContentText 
                    color={red[700]}
                    align='center'
                    text='Se identificó un tratamiento principal activo, este será puesto de acuerdo a la opción seleccionado y el producto seleccionado tomará su lugar'/>
                </Grid>
            )
        }
    }else{
        if(secondary_patientproduct){
            isAnotherProduct=true
            infoContent = (
                <Grid item xs={12}>
                    <ContentText 
                    color={red[700]}
                    align='center'
                    text='Se identificó un tratamiento secundario activo, este será puesto de acuerdo a la opción seleccionado y el producto seleccionado tomará su lugar'/>
                </Grid>
            )
        }
    } */

    let secondProductAction = null

    if(isAnotherProduct){
        secondProductAction = (
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={1}>
                    <Grid item>
                        <ItemSelection text='Suspender' textStyles={{fontWeight:400, color:grey[900]}}
                        status={!isCompleted} onChange={() => setIsCompleted(false)}/>
                    </Grid>
                    <Grid item>
                        <ItemSelection text='Completado' textStyles={{fontWeight:400, color:grey[900]}}
                        status={isCompleted} onChange={() => setIsCompleted(true)}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }


    return(
        <SimpleDialog
        onClose={onClose}
        data={data}
        submit='Restaurar'
        maxWidth='xs'
        onSubmit={() => onRestoreProduct(primary_patientproduct, secondary_patientproduct)}>
            <div>
                <Grid container justifyContent='center'>
                    <Grid item><Icon className={classes.icon}>info</Icon></Grid>
                    <Grid item xs={12}>
                        <Subtitle variant='bold' medium
                        align='center' text='¿Está seguro de que quiere restaurar este tratamiento?'/>
                    </Grid>
                    {/* {infoContent}
                    {secondProductAction} */}
                </Grid>
            </div>
        </SimpleDialog>
    )
}

export default RestoreProductModal