import { Collapse, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect, Fragment } from 'react';
import { onGetDateFormat } from '../../../../../shared/utility';
import PatientProductRow from '../../../Patients/Patients/components/PatientProductRow';
import emptyIcon from '../../../../../assets/empty.png'
import ActionBar from '../../../../../components/Actions/ActionBar';


const ExtendedPatientStatsTableV2 = ({
    header, data, expandable, total, tableFilter, onUpdateTableFilter
}) => {

    const classes = useStyles()

    if(data.length === 0){
        return (
            <div style={{padding:32}}>
                <Grid container direction='column' spacing={2} alignItems='center'>
                    <Grid item>
                        <img src={emptyIcon} alt='' width={125}/>
                    </Grid>
                    <Grid item>
                        <Typography align='center' variant='body2'>
                            No se encontró información suficiente para mostrar
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return ( 
        <div>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                        {header.map((item,key)=>{
                            return(
                            <TableCell className={classes.headerCell} key={key.toString()}>
                                <Typography 
                                variant='body2' 
                                className={classes.headerText}
                                
                                >
                                    {item}
                                </Typography>
                            </TableCell>
                            )
                        })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item,key) => {

                            const _createdAt = onGetDateFormat(item?.created_at, 'DD-MM-YYYY')

                            return(
                                <Fragment key={`pstats-${key}`}>
                                    <TableRow>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.external_id ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{_createdAt}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.status ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.city ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.state ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.doctor ?? '-'}</Typography>
                                        </TableCell>
                                        {/*<TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.doctor_uci ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.doctor_crm_id ?? '-'}</Typography>
                                         </TableCell>*/}
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.main_hospital ?? '-'}</Typography>
                                        </TableCell>
                                        {/*<TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.main_hospital_uci ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.main_hospital_crm_id ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.main_hospital_address ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.hospital ?? '-'}</Typography>
                                        </TableCell>
                                        {/*<TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.hospital_uci ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.hospital_crm_id ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.hospital_address ?? '-'}</Typography>
                                     </TableCell>*/}
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.provider ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.payment_type  ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.insurance_carrier ?? '-'}</Typography>
                                        </TableCell>
                                        {/*<TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.public_insurance ?? '-'}</Typography>
                                    </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.previous_uses ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.previous_benefits ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.total_uses ?? '-'}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography variant='body2'>{item?.total_benefits ?? '-'}</Typography>
                                        </TableCell>*/}

                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ padding:0 }} colSpan={23}>
                                            <Collapse in={expandable} timeout="auto" unmountOnExit>
                                                <PatientProductRow data={item?.patient_products ?? []} />
                                            </Collapse>
                                        </TableCell>  
                                    </TableRow>
                                </Fragment>
                            )

                        })}
                    </TableBody>
                </Table>
            </div>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {/*<TableFooter>
                <TableRow>
                    <TablePagination
                        style={{border:0}}
                        rowsPerPageOptions={[5, 10, 25]}
                        count={rows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                        inputProps: { 'aria-label': 'Rows per page' },
                        native: true,
                        }}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </TableRow>
                    </TableFooter>*/}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    table:{
        minWidth:500
    },    
    tableWrapper:{
        overflowX: 'auto',
        background:'white',
        borderRadius:12
    },
    headerCell:{
        color:'white',
        border:0,
        minWidth:150,
        paddingTop:8,
        paddingBottom:8,
        background:theme.palette.primary.main,
    },
    headerText:{
        fontSize:14
    },
    actions:{
        marginTop:16,
        display:'flex',
        justifyContent:'flex-end'
    },
}))
 
export default ExtendedPatientStatsTableV2;