import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import MedicalReportModal from '../../Modals/MedicalReports/MedicalReportModal/MedicalReportModal';
import UploadMedicalReportDocument from '../../Modals/MedicalReports/UploadMedicalReportDocument';
import DeleteMedicalReportDocument from '../../Modals/MedicalReports/DeleteMedicalReportDocument';
import MedicalReportList from '../../components/MedicalReportList';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import useMedicalReportsView from './useMedicalReportsView';
import usePatient from '../../../../../../hooks/usePatient';
import { getUserType } from '../../../../../../shared/utility';
import { USER_TYPE } from '../../../../../../variables/config';

const MedicalReportsView = () => {

    const classes = useStyles()

    const {actions, system, medicalReportManager, modals, data} = useMedicalReportsView()
    const {medical_reports, medical_report, patient} = medicalReportManager.data
    const userType = getUserType()

    let addMedicalReportButton = null

    return ( 
        <div className={classes.root}>
            <UploadMedicalReportDocument type='patient'
                open={modals.upload_report} origin={data.report} path={data.documentPath}
                patient_id={patient?.id_patient}
                onUpdateDocument={medicalReportManager.actions.onUploadDocument}
                onRefreshData={medicalReportManager.actions.onGetAllByPatient}
                onClose={() => actions.onChangeModalStatus('upload_report', false)}
            />
            <DeleteMedicalReportDocument type='patient'
                open={modals.delete_report} origin={data.report} path={data.documentPath}
                patient_id={patient?.id_patient}
                onDeleteDocument={medicalReportManager.actions.onDeleteDocument}
                onRefreshData={medicalReportManager.actions.onGetAllByPatient}
                onClose={() => actions.onChangeModalStatus('delete_report', false)}
            />
            <div>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Typography variant='h6'>
                            Reportes médicos
                        </Typography>
                        
                    </Grid>
                    {addMedicalReportButton}
                </Grid>
            </div>
            
            <div style={{marginTop:16}}>
                <SimpleContainer loading={system.loading}>
                    <MedicalReportList 
                        documents={medical_reports} 
                        userType={userType} 
                        onEditMedicalReport={actions.onEditMedicalReport}  
                        onSetCurrentMedicalReport={actions.onSetCurrentMedicalReport}
                        onApproveMedicalReport={actions.onApproveMedicalReport} 
                        onUploadDocument={actions.onUploadDocument}
                        onDeleteDocument={actions.onDeleteDocument}
                        onDownloadDocument={actions.onDownloadDocument}
                        viewOnly={Boolean(userType === USER_TYPE.PRA)}

                    />
                </SimpleContainer>
                
            </div>
            

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    addIcon:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
          background:theme.palette.primary.dark,
        }
    }
}))
 
export default MedicalReportsView;