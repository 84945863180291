export const contentData = {
  spanish:{
    layout:{
      title:'Información de la aseguradora pública',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Aseguradoras Públicas',path:'/catalogs/public_insurances'},
        {name:'Detalles',path:'/catalogs/public_insurances/:id'}
      ]
    },
  }
}
