import React, { Component } from 'react';
import { withStyles, Grid, makeStyles, IconButton, Icon } from '@material-ui/core';
import { RoundedButton } from '../../../../../../components/Buttons/GeneralButtons';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../../../components/Texts/ContentText';
import { red } from '@material-ui/core/colors';
import { request_providers, request_patient_providers, request_delete_provider, request_add_provider } from '../../requests';
import {constrainst_providers as constrainst} from  './constraints'
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout';
import { getProvidersOptions } from '../../../../../../shared/customFunctions';
import AddProviderModal from './modals/AddProviderModal/AddProviderModal';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import WarningModal from '../../../../../../components/Modals/WarningModal';


class ProvidersView extends Component {

  state = {
    isloading:true,
    providers:[],
    patient_providers:[],
    modals:{
      add_provider_modal:{open:false,issending:false,error:null},
      warning_delete_provider:{open:false, issending:false, error:null},
    }
  }

  render(){

    const {providers, patient_providers, modals, isloading} = this.state
    const {classes} = this.props

    const providers_catalog = getProvidersOptions(providers, patient_providers)

    let modalcontent = null

    if(modals.add_provider_modal.open){
      modalcontent = (
        <AddProviderModal
          catalogs={{providers:[...providers_catalog]}}
          data={modals.add_provider_modal}
          onClose={() => this.onCloseModal('add_provider_modal')}
          onSubmit={this.onAddProvider}
          addSpace
        />
      )
    }

    if(modals.warning_delete_provider.open){
      modalcontent = (
        <WarningModal 
          data={modals.warning_delete_provider}
          onClose={() => this.onCloseModal('warning_delete_provider')}
          onDelete={this.onDeleteProvider}
        />
      )
    }

    let contentView = null

    if(patient_providers && patient_providers.length > 0) {
      contentView = (
        <Grid container spacing={2}>
          {patient_providers.map((item,key)=>{
            return(
              <Grid item xs={12} key={key.toString()}>
                <ProviderItems data={item} onDelete={this.onPreDeleteProvider}/>
              </Grid>
            )
          })}
        </Grid>
      ) 
    }else{
      contentView = (<CardWrapper>
        <div className={classes.container}>
          <ContentText text='No existen distribuidores asignados a este beneficiario' medium align='center'/>
        </div>
      </CardWrapper>)
    }


    return(
      <SimpleContainer isloading={isloading}>
        {modalcontent}
        <Grid container justifyContent='flex-end' spacing={3}>
          <ContraintLayout constrainst={constrainst.add_provider} auto>
            <RoundedButton 
              size='small'
              label='Agregar distribuidor'
              color='primary'
              onClick={() => this.onOpenModal('add_provider_modal')}
            />
          </ContraintLayout>
          <Grid item xs={12}>
            {contentView}
          </Grid>
        </Grid>
      </SimpleContainer>
      
    )
    
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {id} = this.props.match.params
    this.setState({isloading:true})
    try {
      const providers = await request_providers()
      const patient_providers = await request_patient_providers(id)
      this.setState({providers:providers, patient_providers:patient_providers})
    } catch (error) {
      console.log(error)
    }

    this.setState({isloading:false})
  }

  /////////////////////////// PROVIDERS MANAGER ////////////////////////////////

  onAddProvider = async(data) => {
    const {id} = this.props.match.params
    const type = 'add_provider_modal'
    const data2send = {...data,patient_id:id}

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_provider(id,data2send)
      this.setState({patient_providers:[...response]})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
  }


  onPreDeleteProvider = (data) => {
    this.setState({selected_provider:data})
    this.onOpenModal('warning_delete_provider')
  }

  onDeleteProvider = async() => {
    const {id} = this.props.match.params
    const {id_patient_provider} = this.state.selected_provider
    const type = 'warning_delete_provider'

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_delete_provider(id,id_patient_provider)
      this.setState({patient_providers:[...response]})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
    
  }
}

const styles = theme => ({
  root:{

  },
  container:{
    padding:32
  }
})

export default withStyles(styles)(ProvidersView)

const useStyles = makeStyles(theme => ({
  root:{
    padding:16,
    //paddingLeft:32,
    //paddingRight:32,
  },
  iconbutton:{
    color:red[700]
  }
}))

const ProviderItems = props => {

  const classes = useStyles()
  const {data, onDelete} = props

  return(
    <CardWrapper>
      <div className={classes.root}>
        <Grid container alignItems='center'>
          <Grid item xs>
            <ContentText text={data.provider} medium />
          </Grid>
          <ContraintLayout constrainst={constrainst.delete_provider} auto>
            <IconButton 
            onClick={() => onDelete(data)}
            size='small' className={classes.iconbutton}>
              <Icon>close</Icon>
              </IconButton>
          </ContraintLayout>
        </Grid>
      </div>
    </CardWrapper>
  )
}