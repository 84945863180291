import React, {Component} from 'react'
import SimpleDialog from '../../../../../../../../components/Modals/SimpleDialog'
import {formaData} from './data'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import { Grid } from '@material-ui/core'
import ItemSelection from '../../../../../../../../components/Inputs/ItemSelection/ItemSelection'
import moment from 'moment'
import { request_policy_amounts } from '../../requests'
import { getInsuranceEvaluation } from '../../../../../../../../shared/utility'
import ContentText from '../../../../../../../../components/Texts/ContentText'

class EditInsuranceModal extends Component {

    state = {
        formData: JSON.parse(JSON.stringify(formaData)),
        balance:false,
        isValid:false,
    }

    render(){

        const {data, onClose, catalogs, policy_amounts} = this.props
        const {formData, balance} = this.state

        let form = {...formData}
        form.insurance_carrier_id.options = [...catalogs.insurance_carriers]
        form.policy_amount_id.options = [...catalogs.policy_amounts]


        const policy_value_id = getInsuranceEvaluation(balance, formData.balance_value.value, formData.policy_amount_id.value, policy_amounts)

        let policy_value_name = ''
        if(policy_value_id && policy_amounts){
            let findItem = policy_amounts.find(el => el.policy_value_id === policy_value_id)
            policy_value_name = findItem.value_name
        }

        return(
            <SimpleDialog
                title='Póliza de seguros'
                maxWidth='sm'
                data={data}
                onClose={onClose}
                onSubmit={this.onSubmit}
                submit='Guardar'
            >
                <div >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LineInputSelect
                                wrap='vertical'
                                data={form.insurance_carrier_id}
                                onChange={this.onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LineInputSelect
                                wrap='vertical'
                                data={form.policy_amount_id}
                                onChange={this.onInputChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LineInputText
                                wrap='vertical'
                                data={form.policy_expiry_date}
                                onChange={this.onInputChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item >
                                    <ItemSelection 
                                        id='balance'
                                        status={balance}
                                        text={'Saldo'}
                                        onChange={(id,status) => this.setState({[id]:status})}/>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {balance ? <LineInputText
                                wrap='vertical'
                                data={form.balance_value}
                                onChange={this.onInputChange}/> : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item><ContentText medium text='Nivel'/></Grid>
                                <Grid item><ContentText medium text={policy_value_name} variant='bold'/></Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </div>
            </SimpleDialog>
        )
    }

    componentDidMount = async() => {
        const {formData} = this.state
        const {origin} = this.props
        let temp = {...formData}
        let balance = false
        let policy_amounts = []
        if(origin){
            Object.keys(formData).forEach(item => {
                let value = origin[item]
                let isValid = true
                if(item === 'policy_expiry_date'){
                    const currentTime = moment(value)
                    if(currentTime.isValid()){
                        value = currentTime.format('YYYY-MM-DD')
                    }else{
                        isValid = false
                    }
                }
                if(value !== undefined && value !== null){
                    temp[item].value = value
                    temp[item].isVisited = true
                    temp[item].isValid = isValid
                }
            })
            balance = origin.balance === 1
            //policy_amounts = await request_policy_amounts()

        } 
        if(temp && balance && temp.balance_value.value){
            const balanceReg = parseFloat(temp.balance_value.value)/100
            temp.balance_value.value = balanceReg.toString()
        }
        this.setState({formData:temp, balance:balance, policy_amounts:policy_amounts})
    }

    onSubmit = () => {
        const {onSubmit, policy_amounts} = this.props;
        const {formData, balance} = this.state;
        let data2Send = {};
        Object.keys(formData).forEach((item)=>{
          if(formData[item].isValid){
              data2Send = {...data2Send, [item]:formData[item].value}
          }
        })

        data2Send = {...data2Send, balance: balance ? 1:0}

        if(balance){
            if(data2Send.balance_value !== null){
                const selectedCatalog = policy_amounts.find(
                    el => data2Send.balance_value >= el.min_value 
                    && data2Send.balance_value <= el.max_value);
                if(selectedCatalog){
                    data2Send = {...data2Send, policy_value_id: selectedCatalog.policy_value_id}
                }else{
                  console.log('Esta fuera de rango el valor')
                }
            }
        }else{
            if(data2Send.policy_amount_id !== null && data2Send.policy_amount_id !== ''){
                //console.log()
                const selectedCatalog = policy_amounts.find(el => el.id_policy_amount === data2Send.policy_amount_id)
                data2Send = {...data2Send, policy_value_id: selectedCatalog.policy_value_id}
            }
        }

        if(JSON.stringify(data2Send)!=="{}")
          onSubmit(data2Send)
    
      }
    

    onInputChange = (data) => {
        const {formData} = this.state;
        const id = data.config.id;
        let temp = {...formData};
        temp[id] = {...data};
        const isValidForm = this.validationForm(temp);
        this.setState({formData:{...temp}, isValid: isValidForm})
    }

    validationForm = (data) => {
        let isValid = true;
        Object.keys(data).forEach((item) => {
          if(data[item].isRequired && !data[item].isValid){
            isValid = false;
          }
        })
        return isValid;
    }
}

export default EditInsuranceModal
