import React from 'react'
import { Grid } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';

const NSEContainer = (props) => {

  const {nse} = props

  return(
    <Grid container>
      <Grid item xs={12}>
        <Grid container wrap='nowrap'>
          <Grid item xs>
            <ContentText text='NSE' variant='light' medium/>
          </Grid>
          <Grid item>
            <ContentText text={nse.nse_name}  medium/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap'>
          <Grid item xs>
            <ContentText text='Estado' variant='light' medium/>
          </Grid>
          <Grid item>
            <ContentText text={nse.state} medium/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap='nowrap'>
          <Grid item xs>
            <ContentText text='Ciudad' variant='light' medium/>
          </Grid>
          <Grid item>
            <ContentText text={nse.city}  medium/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NSEContainer