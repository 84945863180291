export const content = {
  title:'Combinar distribuidor',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Confirmar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    new_provider_id:{
      label:'Seleccione el distribuidor principal',
      placeholder:'Seleccione un elemento...',
    },
  },
}