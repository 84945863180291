export const formData = {
  sectionA:{
    name:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    external_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'external_id',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    folio:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'folio',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    doctor_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'doctor_id',
        type:'select',
        fullWidth: true,
        addoption:true,
      },
      rules:{
        type:'none',
      }
    },
    email:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'email',
        type:'email',
        fullWidth: true,
      },
      rules:{
        type:'email',
      }
    },
    phone:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'phone',
        type:'number',
        fullWidth: true,
      },
      rules:{
        type:'phone_number',
      }
    },
    zip:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'zip',
        type:'number',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    public_insurance_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'public_insurance_id',
        type:'select',
        fullWidth: true,
        addoption:true
      },
      rules:{
        type:'none',
      }
    },
    nse_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'nse_id',
        type:'select',
        fullWidth: true,
        addoption:true
      },
      rules:{
        type:'none',
      }
    },
    main_hospital_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'main_hospital_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    payment_type_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'payment_type_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    insurance_carrier_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'insurance_carrier_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    policy_amount_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'policy_amount_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    policy_value_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'policy_value_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    balance:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'balance',
        type:'checkbox',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    balance_value:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'balance_value',
        type:'number',
        fullWidth: true,
      },
      rules:{
        type:'numeric',
        min:0,
        max:999999999

      }
    },

  },
}