import React, { useState, useEffect } from 'react';
import { Grid, Icon, makeStyles } from '@material-ui/core';
import ContraintLayout from '../Layouts/ConstraintLayout';
import { APPROVAL_STATUS, PATIENT_STATUS, USER_TYPE } from '../../variables/config';
import { RoundedButton } from '../Buttons/GeneralButtons';
import ContentText from '../Texts/ContentText';
import BenefitData from './BenefitData';
import { indigo } from '@material-ui/core/colors';

const BenefitContent = (props) => {

    const classes = useStyles()
    const {data, onManualChange, onStartTreatment, patient_status, onFinishTreatment, disabledFinishTratment, noedit} = props

    let startButtonContent = null
    let deleteButtonContent = null

    const {previousCycles, currentCycles} = onFilterCycles (data)

    //console.log(currentCycles)

    if(data && currentCycles.length === 0){
        startButtonContent = (
            <ContraintLayout constrainst={constrainst.start_treatment} status={patient_status}>
                <RoundedButton 
                    size='small'
                    label='Empezar tratamiento'
                    color='primary'
                    onClick={() => onStartTreatment(data)}/>
            </ContraintLayout>
        )
    }

    if(data && currentCycles.length && !Boolean(disabledFinishTratment)){
        deleteButtonContent = (
            <ContraintLayout constrainst={constrainst.finish_treatment} bystatus={patient_status}>
                <RoundedButton 
                    size='small'
                    label='Finalizar tratamiento'
                    color='secondary'
                    onClick={() => onFinishTreatment(data)}/>
            </ContraintLayout>
        )
    }

    let contentView = null

    if(data && data.benefit_benefit){
        //console.log(data)
        contentView = (
            <Grid container direction='column' alignItems='center' justifyContent='center'spacing={1}>
                <Grid item>
                    <BenefitData data={data}/>
                </Grid>
                <ContraintLayout constrainst={constrainst.change_benefit} status={patient_status}>
                    <RoundedButton 
                        size='small'
                        label='Cambiar'
                        color='secondary'
                        onClick={() => onManualChange(data)}/>
                </ContraintLayout>
                
                {noedit ? null : startButtonContent}
                {noedit ? null : deleteButtonContent}
            </Grid>
        )
    }else{
        contentView = (
            <Grid container direction='column' alignItems='center' justifyContent='center'spacing={1}>
                <Grid item><div style={{marginTop:40}}/></Grid>
                <Grid item><Icon className={classes.icon}>feedback</Icon></Grid>
                <Grid item><ContentText medium text='No se ha calculado el beneficio' variant='bold'/></Grid>
            </Grid>
        )
    }

    return ( 
        <div>
            {contentView}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
      },
      secondContainer:{
        top:'65px',
        marginTop:'-40px',
        right:0,
        marginRight:'-50px',
        position:'absolute'
      },
      firstCircle:{
        width:'130px',
        height:'130px',
        color:'white',
        background:indigo[900],
        fontSize:72,
      },
      secondCircle:{
        width:'80px',
        height:'80px',
        color:'white',
        background:theme.palette.secondary.main,
        fontSize:36,
      },
      icon:{
          color:theme.palette.secondary.main,
          fontSize:54
      }
}))
 
export default BenefitContent;

const onFilterCycles = (patientproduct) => {
    let preCycles = []
    let currentCycles = []

    if(patientproduct){
        const {cycles} = patientproduct
        let temp_cycles =  [...cycles].sort(onOrderCycles)
        if(temp_cycles && temp_cycles.length){
            temp_cycles.forEach(item => {
                if(!item.previous){
                    currentCycles.push(item)
                }else{
                    preCycles.push(item)
                }
            })
        }
    }
    return {previousCycles:preCycles, currentCycles:currentCycles}
}

const onOrderCycles = (a,b) => {
    if(a.number > b.number){
      return 1
    }
    if(b.number > a.number){
      return -1
    }
    return 0
}


export const constrainst= {
    change_benefit:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
        ],
        bystatus:[
            PATIENT_STATUS.IN_PROCESS
        ],
    },
    start_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
        bystatus:[
            //PATIENT_STATUS.IN_PROCESS,
            PATIENT_STATUS.ACTIVE
        ],
    },
    finish_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
        bystatus:[
            PATIENT_STATUS.APPROVE
        ],
    }
}