export const mainForm = {
  hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'hospital_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  secondary_hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'secondary_hospital_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  provider_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'provider_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  secondary_provider_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'secondary_provider_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
}
