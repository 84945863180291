import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { Grid, withStyles, InputBase, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import useExtendedPatientStats from './useExtendedPatientStats';
import AnalyticToolbar from '../../components/AnalyticToolbar';
import ExtendedPatientStatsTableV2 from './ExtendedPatientStatsTableV2';
import { useState } from 'react';
import SimpleContainer from '../../../../../components/Layouts/SimpleContainer';

const header = [
  'ID',
    'Fecha de registro',
    'Estatus del beneficiario',
    //'Diagnóstico',
    //'Producto',
    //'Presentación',
    'Ciudad de beneficiario',
    'Estado del beneficiario',
    'Doctor',
    //'Doctor UCI',
    //'Doctor CRM ID',
    'Hospital',
    //'Hospital UCI',
    //'Hospital CRM ID',
    //'Dirección del hospital',
    //'Hospital de infusión',
    //'Hospital de infusión UCI',
    //'Hospital de infusión CRM ID',
    //'Dirección de hospital de infusión',
    'Distribuidor',
    'Tipo de pago',
    'Aseguradora privada',
    //'Aseguradora pública',
    //'Beneficio',
    //'# Compras previas',
    //'# Beneficios previos',
    //'# Compras',
    //'# Beneficios'
]

console.log(header.length,'-------------------------------')

const ExtendedPatientStats = (props) => {

  //const {classes} = props

  const classes = useStyles()

  const {actions, data, system} = useExtendedPatientStats(props)
  const [expandable, setExpandable] = useState(false)
  console.log(data.stats)
  return(
    <div>
      <div>
        <Grid container>
          <Grid item xs>
            <Typography variant='h6' color='primary'>
              Reporte de beneficiarios
            </Typography>
          </Grid>
          <Grid item>
            <RoundedButton disabled={system.sending || system.loading}
                label='Descargar Excel'
                size='small'
                color='primary'
                onClick={actions.exportData2ExcelSecond}
              />
          </Grid>
        </Grid>
      </div>
      <div>
        <AnalyticToolbar 
        filter={data.filter} 
        catalogs={data.masterCatalogs} 
        expandable={expandable}
        onUpdateExpandableView={(val) => setExpandable(val)}
        onUpdateFilter={actions.onUpdateFilter}
        />
        <SimpleContainer loading={system.loading}>
          <ExtendedPatientStatsTableV2 
            data={data.stats}
            header={header}
            expandable={expandable}
            total={data.total}
            tableFilter={data.pagination}
            onUpdateTableFilter={actions.onUpdatePagination}
          />
        </SimpleContainer>
        
      
      </div>
    </div>
    
  )
}

const useStyles = makeStyles(theme => ({

}))

export default ExtendedPatientStats