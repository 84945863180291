import React from 'react'
import { makeStyles, Grid, Avatar, Icon } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import Subtitle from '../../../../../../../../../components/Texts/Subtitle'
import ContentText from '../../../../../../../../../components/Texts/ContentText'
import {constrainst_benefit as constrainst} from  '../constraints'
import ContraintLayout from '../../../../../../../../../components/Layouts/ConstraintLayout'
import { RoundedButton } from '../../../../../../../../../components/Buttons/GeneralButtons'
import BenefitData from '../../../../../../../../../components/DisplayData/BenefitData'

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
      },
      secondContainer:{
        top:'65px',
        marginTop:'-40px',
        right:0,
        marginRight:'-50px',
        position:'absolute'
      },
      firstCircle:{
        width:'130px',
        height:'130px',
        color:'white',
        background:indigo[900],
        fontSize:72,
      },
      secondCircle:{
        width:'80px',
        height:'80px',
        color:'white',
        background:theme.palette.secondary.main,
        fontSize:36,
      },
      icon:{
          color:theme.palette.secondary.main,
          fontSize:54
      }
}))

const onFilterCycles = (patientproduct) => {
    let preCycles = []
    let currentCycles = []

    if(patientproduct){
        const {cycles} = patientproduct
        let temp_cycles =  [...cycles].sort(onOrderCycles)
        if(temp_cycles && temp_cycles.length){
            temp_cycles.forEach(item => {
                if(!item.previous){
                    currentCycles.push(item)
                }else{
                    preCycles.push(item)
                }
            })
        }
    }
    return {previousCycles:preCycles, currentCycles:currentCycles}
}

const onOrderCycles = (a,b) => {
    if(a.number > b.number){
      return 1
    }
    if(b.number > a.number){
      return -1
    }
    return 0
}

const BenefitComponent = props => {

    const {data, onManualChange, onStartTreatment, approvalStatus, onFinishTreatment, disabledFinishTratment} = props
    const classes = useStyles()
    const percentage = parseInt((data ? parseFloat(data.benefit_percentage) : 0.00)*100.00) 

    let startButtonContent = null
    let deleteButtonContent = null

    const {previousCycles, currentCycles} = onFilterCycles (data)



    if(data && currentCycles.length === 0){
        startButtonContent = (
            <ContraintLayout constrainst={constrainst.start_treatment} approvalStatus={approvalStatus}>
                <RoundedButton 
                    size='small'
                    label='Empezar tratamiento'
                    color='primary'
                    onClick={() => onStartTreatment(data)}/>
            </ContraintLayout>
        )
    }

    if(data && currentCycles.length && !disabledFinishTratment){
        //console.log('Finalizar tratamiento')
        deleteButtonContent = (
            <ContraintLayout constrainst={constrainst.finish_treatment} approvalStatus={approvalStatus}>
                <RoundedButton 
                    size='small'
                    label='Finalizar tratamiento'
                    color='secondary'
                    onClick={() => onFinishTreatment(data)}/>
            </ContraintLayout>
        )
    }


    let contentView = null

    if(data && data.benefit_benefit){
        console.log(data)
        contentView = (
            <Grid container direction='column' alignItems='center' justifyContent='center'spacing={1}>
                <Grid item>
                    <BenefitData data={data}/>
                </Grid>
                <ContraintLayout constrainst={constrainst.change_benefit} approvalStatus={approvalStatus}>
                    <RoundedButton 
                        size='small'
                        label='Cambiar'
                        color='secondary'
                        onClick={() => onManualChange(data)}/>
                </ContraintLayout>
                
                {startButtonContent}
                {deleteButtonContent}
            </Grid>
        )
    }else{
        contentView = (
            <Grid container direction='column' alignItems='center' justifyContent='center'spacing={1}>
                <Grid item><div style={{marginTop:40}}/></Grid>
                <Grid item><Icon className={classes.icon}>feedback</Icon></Grid>
                <Grid item><ContentText medium text='No se ha calculado el beneficio' variant='bold'/></Grid>
            </Grid>
        )
    }

    return(
        <div>
            {contentView}
        </div>
    )
}

export default BenefitComponent

