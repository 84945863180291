import React from 'react'
import { makeStyles, Grid, Divider, Icon } from '@material-ui/core'
import CardWrapper from '../../../../../../../../components/Cards/CardWrapper/CardWrapper'
import PatientProductDetails from './components/PatientProductDetails'
import BenefitComponent from './components/BenefitComponent'
import CycleComponent from './components/CycleComponent'
import { APPROVAL_STATUS, USER_TYPE } from '../../../../../../../../variables/config'
import { RoundedButton } from '../../../../../../../../components/Buttons/GeneralButtons'
import ContentText from '../../../../../../../../components/Texts/ContentText'
import {constrainst_patientproduct as constrainst} from  './constraints'
import ContraintLayout from '../../../../../../../../components/Layouts/ConstraintLayout'
import { grey } from '@material-ui/core/colors'
import { getUserType } from '../../../../../../../../shared/utility'


const useStyles = makeStyles(theme => ({
    container:{
        padding:32,
        [theme.breakpoints.down('sm')]: {
          padding:16,
        },
    },
    addBtn:{
        padding:8,
        borderRadius:32,
        '&:hover':{
          background:grey[100],
          cursor:'pointer'
        },
    },
    addIcon:{
        marginTop:4,
        marginRight:8,
        color:theme.palette.primary.main,
      }
}))

const onFilterCycles = (patientproduct) => {
    let preCycles = []
    let currentCycles = []

    if(patientproduct){
        const {cycles} = patientproduct
        let temp_cycles =  [...cycles].sort(onOrderCycles)
        if(temp_cycles && temp_cycles.length){
            temp_cycles.forEach(item => {
                if(!item.previous){
                    currentCycles.push(item)
                }else{
                    preCycles.push(item)
                }
            })
        }
    }
    return {previousCycles:preCycles, currentCycles:currentCycles}
}

const onOrderCycles = (a,b) => {
    if(a.number > b.number){
      return 1
    }
    if(b.number > a.number){
      return -1
    }
    return 0
}

const TreatmentComponent = props => {


    const classes = useStyles()
    const {type, patientproduct, patient, onDownloadProviderLetter, onChangeBenefitStatus,
        onRegisterUse, onChangeUseDate, onDeleteCycles, onStartNextCycle, onEditPreCycle,
        onStartTreatment, onManualChange, onEditTreatment, onAddPatientProduct, onAddPreviousCycle,
        onEditManualUses, onFinishTreatment, disabledFinishTratment} = props
    
    const {previousCycles, currentCycles} = onFilterCycles(patientproduct)

    const userType = getUserType()

    let status = null
    let approvalStatus = null

    if(patient){
        status = patient.status_id
        approvalStatus = patient.approval_status_id
    }

    let contentView = null

    let addpreviouscycleContent = (
        <ContraintLayout constrainst={constrainst.add_previous_cycle} auto approvalStatus={approvalStatus}>
            <div className={classes.addBtn} onClick={userType !== USER_TYPE.HOSPITAL 
            ? () => onAddPreviousCycle(patientproduct) : null}>
                <Grid container alignItems='center'>
                    <Grid item><Icon  className={classes.addIcon}>add_circle</Icon></Grid>
                    <Grid item><ContentText text='De clic si quiere agregar un ciclo previo' /></Grid>
                </Grid>
            </div>  
        </ContraintLayout>
    )

    let addzerocyclecontent = (
        <ContraintLayout constrainst={constrainst.add_previous_cycle} auto approvalStatus={approvalStatus}>
            <div className={classes.addBtn} onClick={userType !== USER_TYPE.HOSPITAL 
            ? () => onAddPreviousCycle(patientproduct, 'gift') : null}>
                <Grid container alignItems='center'>
                    <Grid item><Icon  className={classes.addIcon}>add_circle</Icon></Grid>
                    <Grid item><ContentText text='Agregar beneficio inicial' /></Grid>
                </Grid>
            </div>  
        </ContraintLayout>
    )

    if(userType === USER_TYPE.HOSPITAL){
        addpreviouscycleContent = (
            <Grid item>
                <Grid container alignItems='center'>
                    <Grid item><ContentText text='No existen ciclos previos' /></Grid>
                </Grid>
            </Grid>
        )
    }

    if(patientproduct){
        contentView = (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <PatientProductDetails 
                                type={type}
                                userType={userType}
                                data={patientproduct} 
                                status={status} 
                                approvalStatus={approvalStatus}
                                onEditTreatment={onEditTreatment}
                                onEditManualUses={onEditManualUses}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BenefitComponent 
                                disabledFinishTratment={disabledFinishTratment}
                                data={patientproduct} 
                                status={status} 
                                approvalStatus={approvalStatus}
                                onStartTreatment={onStartTreatment}
                                onManualChange={onManualChange}
                                onFinishTreatment={onFinishTreatment}/>
                        </Grid>
                    </Grid>
                </Grid>
                {approvalStatus === APPROVAL_STATUS.APPROVE ? (
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}><Divider/></Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center'>
                                    {previousCycles.map((item,key)=>{
                                        return(
                                            <Grid item xs={12} key={key.toString()}>
                                                <CycleComponent cycle={item} type='previous' id={key+1} 
                                                    patientproduct={patientproduct}
                                                    approvalStatus={approvalStatus}
                                                    isLast={previousCycles.length-1===key}
                                                    onDeleteCycles={onDeleteCycles}
                                                    onDownloadProviderLetter={onDownloadProviderLetter}
                                                    onChangeBenefitStatus={onChangeBenefitStatus}
                                                    onRegisterUse={onRegisterUse}
                                                    onChangeUseDate={onChangeUseDate}
                                                    onStartNextCycle={onStartNextCycle}
                                                    onEditPreCycle={onEditPreCycle}/>
                                            </Grid>
                                        )
                                    })}
                                    {previousCycles.length === 0 ? (
                                        <Grid item xs={12}>
                                            <Grid container spacing={3} justifyContent='center'>
                                                <Grid item>
                                                    {addpreviouscycleContent}
                                                </Grid>
                                                <Grid item>
                                                    { addzerocyclecontent}
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid> 
                                        
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}><Divider/></Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    {currentCycles.map((item,key)=>{
                                        //console.log('Crear ciclo independiente',item)
                                        return(
                                            <Grid item xs={12} key={key.toString()}>
                                                <CycleComponent cycle={item} type='current' id={key+1} 
                                                    patientproduct={patientproduct}
                                                    approvalStatus={approvalStatus}
                                                    isLast={currentCycles.length-1===key}
                                                    onDeleteCycles={onDeleteCycles}
                                                    onDownloadProviderLetter={onDownloadProviderLetter}
                                                    onChangeBenefitStatus={onChangeBenefitStatus}
                                                    onRegisterUse={onRegisterUse}
                                                    onChangeUseDate={onChangeUseDate}
                                                    onStartNextCycle={onStartNextCycle}/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        )
    }else{
        let treatmentTitle = 'Tratamiento'
        if(type === 'primary'){
            treatmentTitle = 'Tratamiento principal'
        }else{
            treatmentTitle = 'Tratamiento secundario'
        }
        contentView = (
            <Grid container>
                <Grid item xs={12}> 
                    <ContentText text={treatmentTitle} medium variant='bold'/>
                </Grid>
                <Grid item xs={12}>
                    <div style={{paddingTop:16, paddingBottom:16}}>
                        <Grid container alignItems='center' direction='column' spacing={1}>
                            <Grid item>
                                <ContentText text='No se ha creado el tratamiento' medium/>
                            </Grid>
                            <ContraintLayout constrainst={constrainst.add_treatment}>
                                <RoundedButton 
                                    size='small'
                                    onClick={() => onAddPatientProduct(type)}
                                    label='Agregar tratamiento' icon='add'/>
                            </ContraintLayout>
                        </Grid>
                    </div>
                    
                </Grid>
            </Grid>
            
        )
    }

    return(
        <CardWrapper>
            <div className={classes.container} > 
                {contentView}
            </div>
        </CardWrapper>
    )

   
}

export default TreatmentComponent