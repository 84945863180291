import { useContext } from "react";
import { OnboardingContext } from "./OnboardingContext";

export const useOnboardingContext = () => {

    const context = useContext(OnboardingContext)

    if(!context) throw new Error("OnboardingContext context must be used inside OnboardingProvider")

    return context;
    
    
}
 
export default useOnboardingContext;