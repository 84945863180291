import { Typography } from '@material-ui/core';
import { amber, blue, cyan, deepOrange, green, grey, purple } from '@material-ui/core/colors';
import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';


const Chart = ({ data, colors, key_name, key_value }) => {
	const [activeIndex, setActiveIndex] = useState(0);

	const onPieEnter = (_, index) => {
		setActiveIndex(index);
	};

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';
	
		return (
			<g>
				<text x={cx} y={cy} dy={10} textAnchor='middle' fill={fill} fontSize={30} fontWeight={600}>
					{`${(percent * 100).toFixed(0)}%`}
				</text>
				<text x={cx} y={'95%'} textAnchor='middle' fill='#868686' fontSize={14} fontWeight={600} width={100} style={{ width: 100, textOverflow: 'ellipsis' }}>
					{payload[key_name]}
				</text>
				<Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
				<Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>
					{value}
				</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
					{`${(percent * 100).toFixed(2)}%`}
				</text>
			</g>
		);
	};

    let isValid = false
    if(data){
		if (data.length) {
			data.forEach((item) => {
				if (item.value !== 0) {
					isValid = true;
				}
			});
		}
	}

	return (
		<div style={{ height: '338px' }}>
			<ResponsiveContainer width='100%' height='100%'>
				<PieChart width={400} height={400}>
					<Pie
						activeIndex={activeIndex}
						activeShape={renderActiveShape}
						cx='50%'
						cy='50%'
						innerRadius={80}
						outerRadius={105}
						fill='#8884d8'
						dataKey={key_value}
						onMouseEnter={onPieEnter}
                        data={data}
					>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Chart;
