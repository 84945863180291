export const contentData = {
  spanish:{
    subtitles:{
      basicInfo:'Información del hospital o Centro de Infusión',
      primaryTreatment:'Tratamiento',
      secondaryTreatment:'Tratamiento Adyuvante'
    },
    form:{
      hospital_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      secondary_hospital_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      provider_id:{
        label:'Distribuidor',
        placeholder:'Seleccione una opción',
      },
      secondary_provider_id:{
        label:'Distribuidor',
        placeholder:'Seleccione una opción',
      },
    }
  }
}