import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Chart from './Chart';
import Subtitle from '../../Texts/Subtitle';
import { amber, blue, cyan, deepOrange, green, grey, purple } from '@material-ui/core/colors';

const HorizontalBarChart = ({ data, title, key_name, key_value }) => {
	const classes = useStyles();

	let empty = true;
	for (let i = 0; i < data.length; i++) {
		if (data[i][key_value] && data[i][key_value] > 0) {
			empty = false;
			break;
		}
	}

	return (
		<Grid container className={classes.root} spacing={2}>
			<Grid item xs={12}>
				<Subtitle medium text={title} color={blue[900]} />
			</Grid>
			{!empty ? (
				<Grid item xs={12}>
					<Chart data={data} key_name={key_name} key_value={key_value} />
				</Grid>
			) : (
				<Grid item xs={12}>
					<Typography variant='h6'>Sin datos</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 25
	},
}));

export default HorizontalBarChart;
