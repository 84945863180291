import React from 'react';
import {
  Checkbox,
  Grid,
  Typography,
  withWidth,
} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, grey, red} from '@material-ui/core/colors';
//import ContentText from '../../Texts/ContentText.jsx';
import { useRef } from 'react';

const CheckboxSelectInput = (props) => {

  

    const {
      onKeyPress,
      disabled,
      delayedSearch,
      onDelayedSearch,
      catalogs
    } = props;

    const inputRef = useRef()
    const timerRef = useRef()
    const {value, isVisited, isValid, isRequired, options} = props.data;
    const {id, type,label,placeholder,fullWidth,icon, multiline, rows, helper} = props.data.config;

    const error = isVisited && !isValid;

    const onInnerChange = item => {
      const {onChange, data} = props;
      let temp = {...data};
      temp.value = item.value;
      temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
      temp.isVisited = true;
      onChange(temp);
    }

    let _options = (catalogs || options)  ?? []
    //console.log(options,'insurnce')

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {_options.map(item => {
              return(
                <Grid item key={`cbo-${item.value}`} xs={6} md={3}>
                  <CheckboxOption 
                    checked={value === item.value}
                    onChange={() => onInnerChange(item)}
                    label={item.label}
                  />
                </Grid>
              )
            })}
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' style={{color:red[700]}}>
            {error ? helper : ''}
          </Typography>
        </Grid>

      </Grid>
    );
  
};

export default (withWidth()(CheckboxSelectInput));

const CheckboxOption = ({checked, onChange, label}) => {
  return(
    <div>
      <Grid container alignItems='center' wrap='nowrap'>
        <Grid item>
          <Checkbox
            checked={checked}
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant='body2'>{label}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
