import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../../../variables/texts'
import HorizontalDataWrapper from '../../../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../../../components/Texts/ContentText'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_statuses as constrainst} from  '../constraints'
import { getApprovalStatusColor, getLetterStatusColor, getPatientStatusColor } from '../../../../../../../shared/customFunctions'
import { USER_TYPE } from '../../../../../../../variables/config'
import { catalogs } from '../../../../../../../variables/catalogs'

const StatusContainer = props => {

    const {data, userType, onChangeLetterStatus, onChangePatientStatus} = props

    let status_id = null
    if(data){
        status_id = data.status_id
    }

    const statusData = catalogs.spanish.patient_statuses.find(el => el.value === data?.status_id)
    //console.log('STATUS DATA', statusData)

    let patientStatusContainer = (
        <ContraintLayout constrainst={constrainst.status}>
            <HorizontalDataWrapper 
                label={userType === USER_TYPE.HOSPITAL ? 'Solicitante':'Beneficiario'} icon={'brightness_1'} iconSize={12}
                iconColor={statusData?.color} 
                text={data ? data.status : nodata}
                onClick={userType < USER_TYPE.PRA ? onChangePatientStatus : undefined}/>
        </ContraintLayout>
    )

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center' spacing={1}>
                        <Grid item>
                            <ContentText text='Información de estatus' medium variant='bold'/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {/*<ContraintLayout constrainst={constrainst.approval_status}>
                            <HorizontalDataWrapper 
                                label='Oferta' icon={'brightness_1'} iconSize={12}
                                iconColor={getApprovalStatusColor(data ? data.approval_status_id : null)} 
                                text={data ? (data.approval_status_id === 1 ? 'En proceso' : data.approval_status) : nodata}/>
    </ContraintLayout>*/}
                        {patientStatusContainer}
                        <ContraintLayout constrainst={constrainst.letter_status}>
                            <HorizontalDataWrapper 
                                label='Cartas' icon={'brightness_1'} iconSize={12}
                                iconColor={getLetterStatusColor(data ? data.letter_status_id : null)} 
                                text={data ? data.letter_status : nodata}
                                onClick={userType < USER_TYPE.PRA ?  onChangeLetterStatus : undefined}/>
                        </ContraintLayout>
                        
                        <ContraintLayout constrainst={constrainst.inactive_reason} status={status_id}>
                            <HorizontalDataWrapper 
                                label='Razón de inactividad' text={data ? data.inactive_reason : nodata}/>
                        </ContraintLayout>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default StatusContainer