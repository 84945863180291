const styles = (theme) => ({
  root:{
    padding:'32px',
  },
  image:{
    height:64,
    width:64
  },
  card:{
    background:'white',
    borderRadius:16,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    padding:'16px 24px',
    '&:hover':{
      cursor:'pointer'
    }
  }
})

export default styles