import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../../components/Buttons/ActionModalBar';
import LineInputText from '../../../../../../../components/Inputs/LineInputText/LineInputText';
import SimpleModalV2 from '../../../../../../../components/Modals/SimpleModalV2';
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../../../../../shared/utility';
import moment from 'moment'

const MedicalReportModal = (props) => {

    const classes = useStyles()
    const {open, onClose,  mode, origin, patient_id, patient_product_id,
        onAddData, onUpdateData, onRefreshData} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formaData)))

    useEffect(() => {
        if(open){
            onInitModule()
        }
    }, [open, origin])


    const onInitModule = async() => {
        try {
            let _form = onInitForm(
                formaData,
                mode === 'add' ? null : origin
            )
            if(mode === 'edit'){
                console.log(origin)
                let reportDate = moment(origin?.report_date)
                if(reportDate.isValid()){
                    _form.report_date.value = reportDate.format('YYYY-MM-DD')
                    _form.report_date.isValid = true
                    console.log(reportDate.format('YYYY-MM-DD')) 
                }
            }
            setForm(_form)
        } catch (error) {
            
        }
    }

    const onSubmit = async() => {
        setSending(true)
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setSending(false)
            return
        }
        //Extract data to send use
        let data2send, result

        try {
            if(mode == 'add'){
                data2send = onGetFormData(form)
                data2send.patient_product_id = patient_product_id
                data2send.patient_id = patient_id
                data2send.current = 0
                //console.log(data2send)
                result = await onAddData(data2send)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
            }else{
                data2send = onGetSelectedFormData(form, origin)
                result = await onUpdateData(origin?.id_patient_medical_report, data2send)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
            }
            await onRefreshData(patient_product_id)
        } catch (error) {
            console.log(error)
            setError('Opps, hubo un error. Por favor intente de nuevo')
            setSending(false)
            return
        }
        
        
        
        onClose()
        setSending(false)
    }

    const onInputChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }


    let title = `${mode === 'add' ? 'Agregar' : 'Editar'} reporte médico`

    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <div>
                <Typography variant='h6'>
                    {title}
                </Typography>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.identifier}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.report_date}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar 
                                btnLabel={mode === 'add' ? 'Agregar' : 'Guardar'}
                                error={error}
                                onCloseError={() => setError(null)}
                                loading={sending}
                                onSubmit={onSubmit}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModalV2>
     );
}

const useStyles = makeStyles(theme => ({

}))
 
export default MedicalReportModal;

const formaData = {

    identifier:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'identifier',
            type:'text',
            fullWidth: true,
            label:'Identificador',
            placeholder:'Ingrese un identificador...',
            helper:'Identificador no válido'
        },
        rules:{
            type:'distance',
            min:1,
            max:255
        }
    },
    report_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'report_date',
            type:'date',
            fullWidth: true,
            label:'Fecha del reporte',
            placeholder:'',
            helper:'Fecha no válida'
        },
        rules:{
            type:'date',
        }
    },
}