
import React from 'react'
import { TextField, Grid, makeStyles, withStyles, Typography, RadioGroup, Card } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import Caption from '../../Texts/Caption'
import cx from 'classnames'

const TreatmentSwitchInput = props => {

  const classes = useStyles()

    ///////////////////////////////////
    const {data, onChange, disabled } = props //onSearch
    const {isValid, isVisited, value, options} = data;
    const {label,placeholder, helper} = data.config;

    const onInnerChange = (val) => {
      if(disabled) return
      let temp = {...data}
      temp.value = val
      temp.isValid = true
      temp.isVisited = true
      onChange(temp)
    }
    

    let inputlabel = null;
    if(label) inputlabel = <Grid item xs={12}><Caption text={label} medium /></Grid>
    //console.log(data)
    return(
        <div >
            <Grid container >
                <Grid item >
                  <Card className={classes.form_container}>
                    <Grid container>
                      <Grid item>
                        <div className={
                          cx({
                            [classes.item]:true, 
                            [classes.item_selected]:value===0
                          })}
                          onClick={() => onInnerChange(0)}
                          >
                            Monopieza
                          </div>
                      </Grid>
                      <Grid item>
                        <div className={
                          cx({
                            [classes.item]:true, 
                            [classes.item_selected]:value===1
                          })}
                          onClick={() => onInnerChange(1)}
                          >
                            Multipieza
                          </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Typography variant='caption' style={{color:red[700]}}>
                    {isVisited && !isValid ? helper : ''}
                  </Typography>
                </Grid>
            </Grid>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
  root:{

  },
  form_container:{
    background:'#EEF0F2',
    boxShadow:'none',
    borderRadius:32
    //border:'1px solid black'
  },
  item:{
    width:100,
    padding:'8px',
    textAlign:'center',
    cursor:'pointer',
    transition:'all 0.4s linear'
    //border:'1px solid black'
  },
  item_selected:{
    background:theme.palette.primary.main,
    color:'white',
    borderRadius:32,
    transition:'all 0.4s linear'
  }
}))

export default TreatmentSwitchInput