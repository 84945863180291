import React from 'react';
import { Grid, makeStyles, Radio, Typography } from '@material-ui/core';
import { catalogs } from '../../../../../variables/catalogs';
import { USER_TYPE } from '../../../../../variables/config';
import { usePlatformContext } from '../../../../../context/PlatformContext';

const ProductTypeSelection = ({value, onChange}) => {

    const {state, user} = usePlatformContext()
    const onInnerChange = (e) => {
        const _value = e//parseInt(e.currentTarget.value)
        const _el = catalogs.spanish.product_types.find(el => el.value === _value)
        onChange(_el)
    }

    if(user?.user_type_id === USER_TYPE.MERCURIO || user?.user_type_id === USER_TYPE.HOSPITAL) return null
    if(state.programId !== -1) return null

    return ( 
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='subtitle2'>Tipo de tratamiento</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {catalogs.spanish.product_types.map(item => {
                            return(
                                <Grid item key={item.id.toString()}>
                                    <Grid container wrap='nowrap' alignItems='center'>
                                        <Grid item>
                                            <Radio checked={item.value === value} onClick={() => onInnerChange(item.value)} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2'>{item.label}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
     );
}

const useStyles = makeStyles({
    label:{
        fontSize:'0.875rem'
    }
})
 
export default ProductTypeSelection;