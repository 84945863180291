import React, {useState} from 'react'
import { getHistoryFromProducts } from '../../../../../../../../shared/utility'
import { Grid, makeStyles } from '@material-ui/core'
import CardWrapper from '../../../../../../../../components/Cards/CardWrapper/CardWrapper'
import Subtitle from '../../../../../../../../components/Texts/Subtitle'
import SingleTreatment from './components/SingleTreatment'
import PatientProductModal from './modals/PatientProductModal/PatientProductModal'
import RestoreProductModal from './modals/RestoreProductModal'

const useStyles = makeStyles(theme => ({
    root:{marginTop:16},
    card:{padding:8, paddinTop:16, paddingBottom:16 },
    
}))

const TreatmentHistory = props => {

    const classes = useStyles()
    const {patientproducts, match, catalogs, onUpdateData, onBack2Main} = props

    const [ selectedProduct, setSelectedProduct ] = useState(null)
    const [ viewModal, setViewModal] = useState(false)
    const [ restoreModal, setRestoreModal ] = useState(false)

    const onView = (data) => {
        console.log(data)
        setSelectedProduct(data)
        setViewModal(true)
    }

    const onRestore = (data) => {
        console.log(data)
        setSelectedProduct(data)
        setRestoreModal(true)
    }

    const data = getHistoryFromProducts(patientproducts)
    console.log('Treatment History')
    console.log(data)

    let  modalcontent = null

    if(viewModal){
        modalcontent = (
            <PatientProductModal 
                match={match} catalogs={catalogs}
                selected_patientproduct={selectedProduct}
                onClose={() => setViewModal(false)}
            />
        )
    }

    if(restoreModal){
        modalcontent = (
            <RestoreProductModal match={match}
            patientproducts={patientproducts}
            onUpdateData={onUpdateData} onBack2Main={onBack2Main}
            selectedProduct={selectedProduct} onClose={() => setRestoreModal(false)}/>
        )
    }
    

    return(
        <div className={classes.root}>
            {modalcontent}
            <CardWrapper>
                <div className={classes.card}>
                    <div>
                    <Grid container>
                        <Grid item xs={12}><div style={{marginTop:8,marginBottom:12}}><Subtitle text='Lista de tratamientos' medium variant='bold'/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{overflowX:'auto'}}> 
                            <Grid container >
                                {data.map((item,key)=>{
                                return(
                                    <Grid item xs={12} key={key.toString()}>
                                        <SingleTreatment data={item} onView={onView} onRestore={onRestore}/>
                                    </Grid>
                                )
                                })}
                            </Grid>
                            </div>
                            
                        </Grid>
                        
                    </Grid>
                    </div>

                    
                </div>
            </CardWrapper>
           
        </div>
    )
}

export default TreatmentHistory


