import React, {useState, useEffect} from 'react'
import SimpleDialog from '../../../../../../../../components/Modals/SimpleDialog'
import { formData } from './data'
import { Grid } from '@material-ui/core'
import SimpleLoader from '../../../../../../../../components/Loaders/SimpleLoader'
import { request_edit_patient_product } from '../../../../requests'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'

const EditManualUsesModal = props => {

    const {onClose, onUpdateData, origin } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isloading, setIsLoading] = useState(false)
    const [issending, setIsSending] = useState(false)
    const [error, setError] = useState(null)

    const data = {issending:issending, error:error}

    useEffect(() => {
        const initModule = async() => {
            setIsLoading(true)
            let temp = {...JSON.parse(JSON.stringify(formData))}
            if(origin){
                Object.keys(temp).forEach(item =>{
                    const value = temp[item].config.idcross ? origin[temp[item].config.idcross] : origin[item]
                    if(value!== undefined && value !== null){ temp[item].value = value; temp[item].isVisited = true; temp[item].isValid = true;}
                })
            }
            setForm(temp)
            setIsLoading(false)
        }
        initModule()
    }, [origin]);


    const  onInputChange = async(data) => {
        let temp = {...form}
        temp[data.config.id] = {...data}
        setForm(temp)
    }

    const onUpdate = async() => {
        let data2send = {}
        let isValid = true
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].isVisited && form[item].value !== origin[item]) data2send = {...data2send, [item]:form[item].value}
            if(form[item].isRequired && !form[item].isValid) isValid = false
        })
        if(isValid){
            setError(null)
            setIsSending(true)
            try {
                //console.log(data2send)
                const {patient_id, id_patient_product} = origin
                const patientproducts = await request_edit_patient_product(patient_id, id_patient_product, data2send)
                onUpdateData(patientproducts)
            } catch (error) {
                setError('Hubo un error, intente de nuevo')
            }
            setIsSending(false)
        }else{
            setError('Llene los datos faltantes')
        }
    }

    let mainForm = {...form}

    let contentView = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <LineInputText data={mainForm.manual_pending} onChange={onInputChange}/>
            </Grid>
        </Grid>
    )

    if(isloading) contentView = <Grid container justifyContent='center'><div><SimpleLoader /></div></Grid>

    return(
        <SimpleDialog
            title='Información del hospital'
            data={data}
            onClose={onClose}
            onSubmit={onUpdate}
            submit='Guardar'
        >
            <div>
                {contentView}
            </div>
        </SimpleDialog>
    )
}

export default EditManualUsesModal