import { green, grey, purple, blue, cyan, amber, deepOrange} from '@material-ui/core/colors';
import moment from 'moment'
import {numberWithCommas, onGetDateWithTimezone} from '../../../../shared/utility'

export const cleanData2Table = (data, nodata, users) => {
  const table = data.map(item => {
    
    let temp = {
      id:item.id_log, 
      user_image:null,
      data:[]
    }

    let userInfo = users.find(el => el.id_user === item.user_id)
    temp.user_image = userInfo ? userInfo.image : null
    let fullname = ''
    fullname += (userInfo ? userInfo.first_name : '')
    fullname += ' '+(userInfo ? userInfo.last_name : '')

    let array = [
      item.id_log,
      fullname,
      item.log_event ? item.log_event : nodata, 
    ]
    temp.data = [...array]
    return temp
  })

  return table
}

export const cleanDeliveredBenefits = (data) => {
  let table = []
  if(data){
    table = data.map(item => {
      return [
        item.date ?  onGetDateWithTimezone(item.date, 'DD-MMM-YYYY') : '',
        item.external_id ? item.external_id : '',
        item.city ? item.city : '',
        item.state ? item.state : '',
        item.doctor ? item.doctor : '',
        item.doctor_uci ? item.doctor_uci : '',
        item.doctor_crm_id ? item.doctor_crm_id : '',
        item.main_hospital ? item.main_hospital : '',
        item.main_hospital_uci ? item.main_hospital_uci : '',
        item.main_hospital_crm_id ? item.main_hospital_crm_id : '', 
        item.infusion_hospital ? item.infusion_hospital : '',
        item.infusion_hospital_uci ? item.infusion_hospital_uci : '',
        item.infusion_hospital_crm_id ? item.infusion_hospital_crm_id : '',
        item.provider ? item.provider : '', 
        item.address ? item.address : '',
        item.product ? item.product : '',
        item.presentation ? item.presentation : '',
        item.benefit ? item.benefit : '',
        item.payment_type ? item.payment_type : '',
      ]
    })
  }
  return table
} 

export const cleanDeliveredBenefitsExcel = (data) => {
  let table = [[
    'Fecha de entrega',
    'ID programa de bonificaciones',
    'Ciudad de beneficiario',
    'Estado del beneficiario',
    'Doctor',
    'Doctor UCI',
    'Doctor CRM ID',
    'Hospital',
    'Hospital UCI',
    'Hospital CRM ID',
    'Hospital de infusión',
    'Hospital de infusión UCI',
    'Hospital de infusión CRM ID',
    'Distribuidor',
    'Dirección de entrega',
    'Producto',
    'Presentación',
    '# Piezas',
    'Forma de pago'
  ]]
  if(data){
    data.forEach(item => {
      table.push([
        item.date ? moment.utc(item.date).endOf('day').format().replace('Z', '.000Z') : '',
        item.external_id ? item.external_id : '',
        item.city ? item.city : '',
        item.state ? item.state : '',
        item.doctor ? item.doctor : '',
        item.doctor_uci ? item.doctor_uci : '',
        item.doctor_crm_id ? item.doctor_crm_id : '',
        item.main_hospital ? item.main_hospital : '',
        item.main_hospital_uci ? item.main_hospital_uci : '',
        item.main_hospital_crm_id ? item.main_hospital_crm_id : '', 
        item.infusion_hospital ? item.infusion_hospital : '',
        item.infusion_hospital_uci ? item.infusion_hospital_uci : '',
        item.infusion_hospital_crm_id ? item.infusion_hospital_crm_id : '',
        item.provider ? item.provider : '', 
        item.address ? item.address : '',
        item.product ? item.product : '',
        item.presentation ? item.presentation : '',
        item.benefit ? item.benefit : '',
        item.payment_type ? item.payment_type : '',
      ])
    })
  }
  return table
} 


export const cleanExtendedPatientStats = (data) => {
  let table = []
  if(data){
    //console.log(data)
    table = data.map(item => {
      return [
        item.external_id ? item.external_id : '',
        item.created_at ?  onGetDateWithTimezone(item.created_at, 'DD-MMM-YYYY') : '',
        item.status ? item.status : '',
        item.diagnosis ? item.diagnosis : '',
        item.product ? item.product : '',
        item.presentation ? item.presentation : '',
        item.city ? item.city : '',
        item.state ? item.state : '',
        item.doctor ? item.doctor : '',
        item.doctor_uci ? item.doctor_uci : '',
        item.doctor_crm_id ? item.doctor_crm_id : '',
        item.main_hospital ? item.main_hospital : '',
        item.main_hospital_uci ? item.main_hospital_uci : '',
        item.main_hospital_crm_id ? item.main_hospital_crm_id : '',
        item.main_hospital_address ? item.main_hospital_address : '',
        item.hospital ? item.hospital : '',
        item.hospital_uci ? item.hospital_uci : '',
        item.hospital_crm_id ? item.hospital_crm_id : '',
        item.hospital_address ? item.hospital_address : '',
        item.provider ? item.provider : '',
        item.insurance_carrier ? item.insurance_carrier : '',
        item.public_insurance ? item.public_insurance : '',
        item.uses && item.benefit ? `${item.uses}+${item.benefit}`: '',
        item.total_uses ? item.total_uses : '',
        item.total_benefits ? item.total_benefits : '',
      ]
    })
  }
  return table
} 

export const cleanExtendedPatientStatsExcel = (data) => {
  let table = [[
    'ID',
    'Fecha de registro',
    'Estatus del beneficiario',
    //'Diagnóstico',
    //'Producto',
    //'Presentación',
    'Ciudad de beneficiario',
    'Estado del beneficiario',
    'Doctor',
    //'Doctor UCI',
    //'Doctor CRM ID',
    'Hospital',
    //'Hospital UCI',
    //'Hospital CRM ID',
    //'Dirección del hospital',
    //'Hospital de infusión',
    //'Hospital de infusión UCI',
    //'Hospital de infusión CRM ID',
    //'Dirección de hospital de infusión',
    'Distribuidor',
    'Tipo de pago',
    'Aseguradora privada',
    //'Aseguradora pública',
    //'Beneficio',
    //'# Compras previas',
    //'# Beneficios previos',
    //'# Compras',
    //'# Beneficios',
    //////////////////////////
    'Producto principal',
    'Presentación principal',
    'Tipo',
    'Diagnóstico',
    'Beneficio',
    'Ciclo Previo',
    'Ciclos',
    'Compras',
    'Beneficios'

  ]]
  if(data){
    data.forEach(item => {
      const _date = item.created_at ? moment.utc(item.created_at).endOf('day').format().replace('Z', '.000Z') : ''
      let segment = [
        item.external_id ? item.external_id : '',
        _date,
        item.status ? item.status : '',
        item.city ? item.city : '',
        item.state ? item.state : '',
        item.doctor ? item.doctor : '',
        //item.doctor_uci ? item.doctor_uci : '',
        //item.doctor_crm_id ? item.doctor_crm_id : '',
        item.main_hospital ? item.main_hospital : '',
        //item.main_hospital_uci ? item.main_hospital_uci : '',
        //item.main_hospital_crm_id ? item.main_hospital_crm_id : '',
        //item.main_hospital_address ? item.main_hospital_address : '',
        //item.hospital ? item.hospital : '',
        //item.hospital_uci ? item.hospital_uci : '',
        //item.hospital_crm_id ? item.hospital_crm_id : '',
        //item.hospital_address ? item.hospital_address : '',
        item.provider ? item.provider : '',
        item.payment_type ? item.payment_type : '',
        item.insurance_carrier ? item.insurance_carrier : '',
        //item.public_insurance ? item.public_insurance : '',
        //item.previous_uses ? item.previous_uses : '',
        //item.previous_benefits ? item.previous_benefits : '',
        //item.total_uses ? item.total_uses : '',
        //item.total_benefits ? item.total_benefits : '', //23 items

      ]
      table.push(segment)


      const patient_products = item?.patient_products ?? []

      patient_products.forEach(el => {
        let paid_applied = 0
        let free_applied = 0
        let use_state = 0

        const uses = el?.use_states ?? []

        uses.forEach(j => {
            paid_applied += j.paid_applied
            free_applied += j.free_applied
            use_state += j.use_state
        })  


        segment = [
          item.external_id ? item.external_id : '',_date,'','','','','','','','',
          el?.product_name ?? '',
          el?.product_presentation ?? '',
          el?.product_type ?? '',
          el?.diagnosis ?? '',
          el?.benefit_name ?? '', //
          el?.previous === 1 ? 'Sí' : 'No',
          uses?.length ?? '',
          paid_applied ?? '',
          free_applied ?? '',
        ]
        console.log(segment)
        table.push(segment)
      })
      
    })

      
  }
  return table
} 



export const cleanPatientStats = (data) => {
  let table = []
  let chartTable = []
  if(data){
    table = [
      [blue[900],'Activos',data.active,(data.active/data.total)*100],
      [blue[600],'En proceso',data.in_progress,(data.in_progress/data.total)*100],
      [grey[400],'Inactivos',data.inactive,(data.inactive/data.total)*100],
      // [null,'Totales',data.total,null],
    ]

    chartTable = [
      { name: 'Activos', value: data.active },
      { name: 'En proceso', value: data.in_progress },
      { name: 'Inactivos', value: data.inactive },
    ]

  }
  return {table:[...table], chartTable: [...chartTable]}
}

export const cleanPatientStatsExcel = (data) => {
  let table = [['Nombre','Cantidad','Porcentaje']]
  if(data){
    table = [
      ['Activos',data.active,(data.active/data.total)],
      ['En proceso',data.in_progress,(data.in_progress/data.total)],
      ['Inativos',data.inactive,(data.inactive/data.total)],
      ['Totales',data.total,1.0],
    ]
  }
  return table
}



//const inactive_colors = [purple[700], indigo[700], blue[700], lightBlue[700], 
//cyan[700], teal[700], green[700], lightGreen[700], lime[700], yellow[700], amber[700], orange[700], deepOrange[700], brown[700]];

const inactive_colors = [purple[700], blue[700], cyan[700], 
 green[700],  amber[700],  deepOrange[700]];

export const cleanInactivePatientStats = (data) => {
  let table = []
  let chartTable = []
  
  if(data){
    if(data.length){
      const total = data[0].count ? data[0].count : 1 
      data.forEach((item, key) => {
        if(key){
          table.push([
            inactive_colors[key-1],
            item.name,
            item.count,
            (item.count/total)*100
          ])
          chartTable.push({
            name: item.name,
            value: item.count
          })
        }
      })
      // table.push([null,'Totales',data[0].count,null])
    }
    
  }
  return {table:[...table], chartTable: [...chartTable]}
}

export const cleanInactivePatientStatsExcel = (data) => {
  let table = [['Nombre','Cantidad','Porcentaje']]
  
  if(data){
    if(data.length){
      const total = data[0].count ? data[0].count : 1 
      data.forEach((item, key) => {
        if(key){
          table.push([
            item.name,
            item.count,
            (item.count/total)
          ])
        }
      })
      table.push(['Totales',total,1.00])
    }
    
  }
  return table
}

export const cleanDataToExcel = (data, headers, exclude_keys, value_key, total_headers, log) =>{
  const total = data.reduce((acc, item) => acc + item[value_key], 0);
  const table = [[...headers]]

  
  if(data && data.length){
    data.forEach(elem =>{
      let temp = []
      for(const key in elem){
        if(!exclude_keys.includes(key))
          temp.push(elem[key])
      }
      temp.push(total > 0 ? ((elem[value_key] / total).toFixed(2)) : 0);
      table.push(temp)
    })

    if(total_headers)
      table.push([...total_headers, total,  total > 0 ? 1.0 : 0]);
    else
      table.push(['Totales',total, total > 0 ? 1.00 : 0])
  }
  if(log){
    console.log(data)
    console.log(table)
  }
  return table
}

export const cleanPatientStatsByProduct = (data) => {
  let table = [[''],[''],['Activos'],['En progreso'],['Inactivo'],['Prospecto'],['Total']]
  let chartTable = []
  
  if(data){
    if(data.length){
      data.forEach((item, key) => {
        table[0].push(item.product)
        table[1].push(item.presentation)
        table[2].push(item.active)
        table[3].push(item.in_progress)
        table[4].push(item.inactive)
        table[5].push(item?.prospect ?? 0)
        table[6].push(item.total)

        chartTable.push({
          name: item.product + ' ' + item.presentation,
          Activos:item.active,
          Progreso:item.in_progress,
          Inactivos:item.inactive,
          Prospectos:item.prospect 
        })
      })
    }
    
  }
  return {table:[...table], chartTable: [...chartTable]}
}

export const cleanPatientStatsByProductExcel = (data) => {
  let table = [[''],['Activos'],['En progreso'],['Inactivos'],['Prospecto'],['Total']]
  
  if(data){
    if(data.length){
      data.forEach((item) => {
        table[0].push(item.product)
        table[1].push(item.active)
        table[2].push(item.in_progress)
        table[3].push(item.inactive)
        table[4].push(item?.prospect ?? 0)
        table[5].push(item.total)
      })
    }
  }
  return table
}

export const cleanProductStats = (data) => {
  let temp = []
  let header = ['Producto', 'Presentación', "Beneficiarios Activos", 
  "Número de piezas compradas", "Número de piezas entregadas (Beneficio)",
   "Total (Compradas + Beneficio)", "Precio máximo al público", "Venta", 
   "Bonificación", "Precio", "Total"] 

   let totalSell = 0
   let totalBonification = 0
   let totalPrice = 0

   if(data){
     if(data.length){
       data.forEach(item => {
        
        totalSell +=  parseFloat(item?.sold_value??0.00)
        totalBonification += parseFloat(item.benefit_value??0.00)
        totalPrice += parseFloat(item?.new_product_price??0.00)
        console.log('dataanalysis',item)
        temp.push([
          item.product, item.presentation,item.active,
          item.sold_units, item.benefit_units,
          item.total_units, '$'+numberWithCommas(item?.product_price??0.00), '$'+numberWithCommas(item?.sold_value??0.00),
          '$'+numberWithCommas(item.benefit_value??0.00), '$'+numberWithCommas(parseFloat(parseFloat(item.new_product_price??0.00).toFixed(2))),
          (parseFloat(item?.new_product_price_percentage??0.00)*100).toFixed(2) + '%'

        ])

       })

       //console.log(totalPrice)
       temp.push([
         '','','','',
         '','','Total','$'+numberWithCommas(totalSell),
         '$'+numberWithCommas(totalBonification),'$'+numberWithCommas(parseFloat(totalPrice).toFixed(2)),
         ''
        ])
       
     }
   }
   return {header:[...header], data:[...temp]}
}

export const cleanProductStatsExcel = (data) => {
  let table = [['Producto', 'Presentación', "Beneficiarios Activos", 
  "Número de piezas compradas", "Número de piezas entregadas (Beneficio)",
   "Total (Compradas + Beneficio)", "Precio máximo al público", "Venta", 
   "Bonificación", "Precio", "Total"]]

   if(data){
     if(data.length){
       data.forEach(item => {
        table.push([
          item.product, item.presentation,item.active,
          item.sold_units, item.benefit_units,
          item.total_units, item.product_price, item.sold_value,
          item.benefit_value, parseFloat(item.new_product_price),
          parseFloat(item.new_product_price_percentage)
        ])
       })
       
     }
   }
   return table
}



const numberWithCommas2 = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
