import { useState } from "react"
import { getFileExtension, onUpdateAuthorizationHeader } from "../shared/utility"
import { private_server } from "../variables/config"

const useCatalogs = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const actions = {
        onGetHospitals:async() => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get("/hospital/all")
                response = request.data.data.hospitals
            } catch (error) {
                setError('No se pudo obtener los hopitales, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGetProducts:async(params) => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    "/patientproduct/products", 
                    {params:{...params}}
                )
                response = request.data.data.products
            } catch (error) {
                setError('No se pudo obtener los productos solicitados, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGetDiagnosis:async(params) => {
            setLoading(true)
            let response = []
            try {
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get(
                    "/patientproduct/diagnostics", 
                    {params:{...params}}
                )
                response = request.data.data.diagnostics
                console.log(response)
            } catch (error) {
                setError('No se pudo obtener los productos solicitados, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        onGetSchemes:async(params) => {
            setLoading(true)
            let response = []
            try {
                console.log("Solicitar schemes", params)
                onUpdateAuthorizationHeader(private_server)
                const request = await private_server.get("/patientproduct/schemes", {params:params})
                response = request.data.data.schemes
                console.log(response)
            } catch (error) {
                console.log(error)
                setError('No se pudo obtener los esquemas, intente de nuevo')
            }
            setLoading(false)
            return response
        },
        
        
        
    }

    const system = {loading, sending,error}
    const data = {}

    return {system, data, actions}



} 

export default useCatalogs