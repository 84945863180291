import React from 'react'
import { Grid } from '@material-ui/core'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_actionbuttons as constrainst} from  '../constraints'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons'

const ActionButtonsContainer = props => {

    const {data, onDownloadDoctorLetter, onDownloadPatientLetter} = props
    let approvalStatus = null
    let status = null
    if(data){
        approvalStatus = data.approval_status_id
        status = data.status_id
    }

    return null

    return(
        <div>
            <Grid container spacing={2} justifyContent='flex-end'>
                <ContraintLayout constrainst={constrainst.doctor_letter} auto 
                    status={status}>
                    <RoundedButton 
                        label='Carta Doctor'
                        size='small' 
                        color='primary'
                        onClick={onDownloadDoctorLetter}/>
                </ContraintLayout>
                <ContraintLayout constrainst={constrainst.patient_letter} auto 
                    status={status}>
                    <RoundedButton 
                        label='Carta Beneficiario'
                        size='small' 
                        color='primary'
                        onClick={onDownloadPatientLetter}/>
                </ContraintLayout>

            </Grid>
        </div>
    )
}

export default ActionButtonsContainer