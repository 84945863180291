import React, {Component} from 'react'
import { Grid } from '@material-ui/core'

import SimpleModal from '../../../../../../../../components/Modals/SimpleModal'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'


import {form_data} from './data'
import {content} from './content'
import {catalog_matrix} from './catalog_matrix'
import ItemSelection from '../../../../../../../../components/Inputs/ItemSelection/ItemSelection';

class ProviderLetterModal extends Component {

  state = {
    formData: {},
    isValid:false,
    option:1,
    error:null
  }

  

  render(){

    const {data, onClose, addSpace} = this.props
    const {formData, option, error} = this.state

    let contentA = null

    if(option === 0 || option === 1){
      contentA = (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={formData.patient_provider_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={formData.alternative_hospital_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LineInputText
                wrap='vertical'
                data={formData.manual_hospital_address_value}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    }else if(option === 2){
      contentA = (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={formData.patient_provider_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LineInputText
                wrap='vertical'
                data={formData.manual_provider_address_value}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        
      )
    }else{
      contentA = (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputText
                wrap='vertical'
                data={formData.manual_particular_address_value}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        
      )
    }
    
    let spaceContent = null

    if(addSpace){
      spaceContent = <Grid item xs={12} style={{height:'200px'}}/>
    }


    
    return(
      <SimpleModal
        maxWidth={'md'}
        content={content}
        onSubmit={this.onSubmit}
        data={{...data, error:error}}
        onClose={onClose}
        onClick={this.onSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <ItemSelection 
                  id={1}
                  status={option === 0 || option === 1}
                  text='Hospital'
                  onChange={this.onChangeCheckbox}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ItemSelection 
                  id={2}
                  status={option === 2}
                  text='Distribuidor'
                  onChange={this.onChangeCheckbox}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ItemSelection 
                  id={3}
                  status={option === 3}
                  text='Dirección particular'
                  onChange={this.onChangeCheckbox}
                />
              </Grid>
            </Grid>
          </Grid>
          {contentA}
          {spaceContent}
        </Grid>
      </SimpleModal>
    )
  }

  onChangeCheckbox = (id, status) => {
    console.log(id)
    this.setState({option:id})
  }

  componentDidMount(){
    const {catalogs, use} = this.props
    let temp = JSON.parse(JSON.stringify(form_data))
    if(catalog_matrix){
      catalog_matrix.forEach(item => {
        if(item.catalog_key !== 'none'){
          temp[item.form_key].options = catalogs[item.catalog_key]
        }
        
      })
    }
    Object.keys(form_data).forEach(item => {
      if(use){
        const value = use[item]
        let isValid = value !== null && value !== undefined
        if(isValid){
          temp[item].value = value
          temp[item].isValid = true
          temp[item].isVisited = true
        }
      }
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
    });

    if(use){
      if(use.alternative_hospital){
        this.setState({option:use.alternative_hospital})
      }
    }

    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {formData, option} = this.state;
    let data2Send = {};
    let isValid = true

    if(option === 1 || option === 0){
      if(formData.alternative_hospital_id.value){
        data2Send = {alternative_hospital_id:formData.alternative_hospital_id.value,
        manual_hospital_address_value:formData.manual_hospital_address_value.value,
        patient_provider_id:formData.patient_provider_id.value}
        isValid = formData.alternative_hospital_id.isValid && formData.manual_hospital_address_value.isValid && formData.patient_provider_id.isValid

      }
    }else if(option === 2){
      if(formData.patient_provider_id.value){
        data2Send = {patient_provider_id:formData.patient_provider_id.value,
        manual_provider_address_value:formData.manual_provider_address_value.value}
        isValid = formData.patient_provider_id.isValid && formData.manual_provider_address_value.isValid

      }
    }else{
      data2Send = {manual_particular_address_value:formData.manual_particular_address_value.value}
      isValid = formData.manual_particular_address_value.isValid 
    }
    console.log(data2Send)
    if(isValid){
      onSubmit(data2Send, option)
    }else{
      this.setState({error:'Faltan campos por llenar'})
    }
  }
}

export default ProviderLetterModal