import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../../components/Buttons/ActionModalBar';
import LineInputText from '../../../../../../../components/Inputs/LineInputText/LineInputText';
import SimpleModalV2 from '../../../../../../../components/Modals/SimpleModalV2';
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitForm, onRemoveItemsFromObject, onSetErrorsToForm } from '../../../../../../../shared/utility';
import moment from 'moment'

const PersonalDocumentModal = (props) => {

    const classes = useStyles()
    const {open, onClose,  mode, origin, patient_id, patient_product_id,
        onAddData, onUpdateData, onRefreshData} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formaData)))

    useEffect(() => {
        if(open){
            onInitModule()
        }
    }, [open, origin])


    const onInitModule = async() => {
        try {
            let _form = onInitForm(
                formaData,
                mode === 'add' ? null : origin
            )
            if(mode === 'edit'){
                console.log(origin)
                let reportDate = moment(origin?.date)
                if(reportDate.isValid()){
                    _form.date.value = reportDate.format('YYYY-MM-DD')
                    _form.date.isValid = true
                    console.log(reportDate.format('YYYY-MM-DD')) 
                }
            }
            setForm(_form)
        } catch (error) {
            
        }
    }

    const onSubmit = async() => {
        setSending(true)
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setSending(false)
            return
        }
        //Extract data to send use
        let data2send, result

        try {
            if(mode == 'add'){
                data2send = onGetFormData(form)
                data2send.patient_id = patient_id
                data2send = onRemoveItemsFromObject(data2send, '')
                console.log(data2send)
                result = await onAddData(data2send)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
            }else{
                //console.log('MOdificar esto', origin)
                data2send = onGetSelectedFormData(form, origin)
                console.log(data2send)
                result = await onUpdateData(origin?.id_patient_document, data2send)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
            }
            await onRefreshData(patient_id)
        } catch (error) {
            console.log(error)
            setError('Opps, hubo un error. Por favor intente de nuevo')
            setSending(false)
            return
        }
        
        
        
        onClose()
        setSending(false)
    }

    const onInputChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }


    let title = `${mode === 'add' ? 'Agregar' : 'Editar'} documento`

    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <div>
                <Typography variant='h6'>
                    {title}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                    Ingrese la información solicitada referente al documento
                </Typography>
                <div style={{marginTop:16}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.name}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.date}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LineInputText
                                wrap='vertical'
                                data={form.details}
                                onChange={onInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar 
                                btnLabel={mode === 'add' ? 'Agregar' : 'Guardar'}
                                error={error}
                                onCloseError={() => setError(null)}
                                loading={sending}
                                onSubmit={onSubmit}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModalV2>
     );
}

const useStyles = makeStyles(theme => ({

}))
 
export default PersonalDocumentModal;

const formaData = {

    name:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'name',
            type:'text',
            fullWidth: true,
            label:'Nombre',
            placeholder:'Ingrese un nombre al docuemnto...',
            helper:'Nombre no válido'
        },
        rules:{
            type:'distance',
            min:1,
            max:255
        }
    },
    date:{
        value: moment().format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config:{
            id:'date',
            type:'date',
            fullWidth: true,
            label:'Fecha',
            placeholder:'',
            helper:'Fecha no válida'
        },
        rules:{
            type:'date',
        }
    },
    details:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'details',
            type:'text',
            fullWidth: true,
            multiple:true,
            rows:5,
            label:'Detalles',
            placeholder:'Agregue información adicional...',
            helper:'El número máximo de caracteres permitidos son 256'
        },
        rules:{
            type:'distance',
            min:0,
            max:256
        }
    },
}