export const content = {
  title:'Agregar Obsequio',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Agregar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    uses:{
      label:'No. de aplicaciones',
      placeholder:'No. de aplicaciones',
      helper:'La cantidad debe ser mayor a 1'
    },
  },
}