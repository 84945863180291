export const content = {
  title:'Agregar nota',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Agregar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    patient_note_template_id:{
      label:'Notas',
      placeholder:'Seleccione una nota',
    },
  },
}