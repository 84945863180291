export const cleanData2Table = (data, nodata, translations) => {
  
  const table = data.map(item => {
    
    let temp = {
      id:item.id_hospital, 
      hospital_value:item.hospital_value,
      data:[]
    }

    let array = [
      item.id_hospital,
      item.name ? item.name : nodata, 
      item.hospital_value_name ? item.hospital_value_name : nodata, 
      item.accredited ? 'Si' : 'No',
      item.crm_id ? item.crm_id : '-',
      item.uci ? item.uci : '-',  
    ]
    temp.data = [...array]
    return temp
  })

  return table
}