import React, {Component} from 'react'
import { TableRow,TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {TableItem} from '../../../../../components/DisplayData/DisplayData'

import {onFilterDataArray} from './customFunctions'
import { green, red, grey, cyan, blue, deepPurple, pink } from '@material-ui/core/colors';
import InternalSimpleTable from '../../../../../components/Tables/InternalSimpleTable';


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'24px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class HospitalList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    this.setState({rowsPerPage:value})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  onSearch = (value) => {
    this.setState({page:0,searchText:value})
  }

  onAddDoctor = () => {
  }

  render(){

    const {classes, title, header, data, onAddItem, onUserSelected, user_level, onExport2Excel} = this.props
    const {rowsPerPage,page,searchText} = this.state

    const filterData = onFilterDataArray(data, searchText)
    const sliceArray = filterData.slice(page*rowsPerPage, page * rowsPerPage + rowsPerPage)
    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, sliceArray.length - page * rowsPerPage)
    //let emptylist = this.onFillEmptyArray(emptyRows, rowsPerPage)

    return(
      
      <div className={classes.root}>
        <InternalSimpleTable
          title={title}
          header={header}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={filterData.length}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={user_level < 4}
          onAddItem={onAddItem}
          onExport2Excel={onExport2Excel}
        >
          {sliceArray.map((item,key)=>{
            return(
              <TableRow key={key.toString()} 
              className={classes.tablerow} 
              >
                <TableCell padding='checkbox' className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[0]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[1]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='brightness_1'
                    iconSize={12}
                    iconColor={this.getHospitalValueColor(item.hospital_value)}
                    data={item.data[2]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[3]}
                  />
                </TableCell>   
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[4]}
                  />
                </TableCell> 
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[5]}
                  />
                </TableCell>         
              </TableRow>
            )
          })}
        </InternalSimpleTable>
      </div>
    )
  }

  getHospitalValueColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = green[700]
        break;
      case 2:
        color = cyan[700]
        break;
      case 3:
        color = blue[700]
        break;
      case 4:
          color = deepPurple[700]
          break;
      case 5:
          color = pink[700]
          break;
      case 6:
        color = red[700]
        break;
      default:
          color = grey[500]
        break;
    }
    return color
  }

}

export default withStyles(styles)(HospitalList)