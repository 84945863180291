import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import AddAgentCard from './AddAgentCard/AddAgentCard'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'


import {sleep, request_add_user, request_hospitals} from './requests'
import { onGetHospitalCatalogs } from '../../../../shared/utility'

class AddAgent extends Component{

  state = {
    isloading:false,
    issending:false,
    hospitals:[],
    error:null,
    user:null,
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    this.setState({isloading:true})
    try{
      let hospitals = await request_hospitals()
      hospitals = onGetHospitalCatalogs(hospitals)
      this.setState({hospitals:hospitals})
    }catch(e){
      console.log(e)
    }
    this.setState({isloading:false})
  }

  onCreateItem = async(data) => {
    
    try {
      this.setState({issending:true})
      await request_add_user(data)
      this.setState({issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(2000)
      this.props.history.push("/agents")
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }
      
    }
  }

  render(){

    const { history, language, catalogs,translations} = this.props;
    const {isloading, issending, error, success, hospitals} = this.state
    const content = contentData[language]


    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AddAgentCard 
              language={language}
              catalogs={catalogs}
              translations={translations}
              issending={issending}
              hospitals={hospitals}
              error={error}
              onSubmit={this.onCreateItem}
              />
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }

  onReturn = () => {
    this.props.history.goBack()
  }

}

export default withStyles(styles)(AddAgent)