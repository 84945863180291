import { USER_TYPE } from "../../../../../variables/config"

export const constrainst_mainView = {
    add_doctor:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    },
}

export const constrainst_doctor= {
    delete_doctor:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    },
}

