import React from 'react'
import {constrainst_toolbar as constrainst} from  './constraints'
import { getUserType } from '../../../../../../../shared/utility'
import { USER_TYPE, APPROVAL_STATUS } from '../../../../../../../variables/config'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import { Grid } from '@material-ui/core'

const Toolbar = props => {

    const {patient, onRecalculateBenefit, onRecalculateCycles} = props

    let status = null
    let approvalStatus = null
    const userType = getUserType()

    if(patient){
        status = patient.status_id
        approvalStatus = patient.approval_status_id
    }

    let recalculateBtn = (
        <ContraintLayout 
            constrainst={constrainst.recalculate_benefit} 
            status={status} auto>
            <RoundedButton 
                size='small'
                label='Recalcular Beneficio'
                color='primary'
                onClick={onRecalculateBenefit}/>
        </ContraintLayout>
    )

    //if(userType === USER_TYPE.MERCURIO && approvalStatus === APPROVAL_STATUS.NOTSTARTED) recalculateBtn = null

    return(
        <div>
            <Grid container justifyContent='flex-end' spacing={1}>
                <ContraintLayout 
                    constrainst={constrainst.adjust_cycles} 
                    status={status} auto>
                        <RoundedButton 
                            size='small'
                            label='Ajustar ciclos'
                            color='secondary'
                            onClick={onRecalculateCycles}/>
                </ContraintLayout>
                {recalculateBtn}
            </Grid>
        </div>
    )
}

export default Toolbar