export const isFormValid = (form, keys) => {
    let errors = []
    if(form){
        if(keys){
            keys.forEach(item => {
                if(!form[item].isValid) errors.push(item)
            })
        }else{
            Object.keys(form).forEach(item => {
                if(form[item].isRequired && !form[item].isValid) errors.push(item)
            })
        }
    }
    return errors
} 

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => {
        temp[item].isVisited = true
        temp[item].isValid = false
    })
    return temp
}

export const onGetFormData = (form, keys) => {
    let temp = {}
    if(form){
        if(keys){
            keys.forEach(item => {
                if(form[item].isValid) temp = {...temp, [item]:form[item].value}
            })
        }else{
            Object.keys(form).forEach(item => {
                if(form[item].isValid) temp = {...temp, [item]:form[item].value}
            })
        }
        
    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onInitForm = (form, origin, translations) => {
    let response = {...form}
    Object.keys(form).forEach(item => {
        if(origin){
            const value = origin[item]
            if(value) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
        }
        if(translations && translations[item]) response[item].config = {...response[item].config, ...translations[item]}
    })
    return response
}

export const onGetCatalogs = (options, keyValue, keyLabel, keyLabel2, keyLabel3) => {
    if(!options) return []
  
    /* return options.map(item => {
        return{
            value: item[keyValue], label:item[keyLabel] ? item[keyLabel] : ''
        }
    }) */
  
    const sort_options = options.map(item => {
  
        //console.log(item)
        const label1 = item[keyLabel] || ''
        const label2 = item[keyLabel2] || ''
        const label3 = item[keyLabel3] || ''

        return{
            //value: item[keyValue], label:item[keyLabel] && item[keyLabel2] ? item[keyLabel] + ' ' + item[keyLabel2] : item[keyLabel] 
            value: item[keyValue], label:(label1 + ' ' + label2 + ' ' + label3)?.trim()
        }
    })
  
    return sort_options.sort(function(a,b){return a.label.localeCompare(b.label)})
  }

  export const onSetValueInForm = (form, key, value) => {
    let _form = {...form}
    _form[key].value = value !== undefined && value !== null ? value : ''
    _form[key].isValid = value !== undefined && value !== null
    _form[key].isVisited = value !== undefined && value !== null
    return _form

}

export const onGetErrorMessage = (error) => {
    let message = 'Hubo un error'
    let details = null
    if(!error) return {message:'', details:null}
    console.log('ERROR HANDLER: ', error)
    if(error?.response){
        if(error?.response?.data){
            if(error?.response?.data?.error && error?.response?.data?.error?.code){
                const _error =  'Error response'//ERROR_CODES[error.response.data.error.code]
                console.log(_error)
                details = {...error.response.data.error}
                details.message = _error ? _error : null
                if(_error) message = _error
            }
            
        }
    }
    return {...details, message:message}
}