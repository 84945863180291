import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../../variables/texts'
import HorizontalDataWrapper from '../../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../../components/Texts/ContentText'
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_hospitalInfo as constrainst} from  '../constraints'

const HospitalContainer = props => {

    const {data, onEdit} = props
    
    let isAccredited = null
    if(data && data.accredited !== null && data.accredited !== undefined){
        isAccredited = data.accredited === 1 ? 'Sí' : 'No'
    }
   
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ContentText 
                        text='Información del hospital o clínica de consulta' 
                        medium variant='bold'/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Nombre' text={data ? data.name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.hospital_value_name}>
                            <HorizontalDataWrapper 
                                label='Nivel' text={data ? data.hospital_value_name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.accredited}>
                            <HorizontalDataWrapper 
                                label='Acreditado' text={isAccredited}/>
                        </ContraintLayout>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default HospitalContainer