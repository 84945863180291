export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      title:'Beneficiarios',
      navigation:[
        {name:'Beneficiarios',path:'/patients'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[
      {id:1, label:'Prospecto', url:''},
      {id:2, label:'En Proceso', url:''},
      {id:3, label:'Activo', url:''},
      {id:4, label:'Inactivo', url:''},
      {id:null, label:'Sin Filtro', url:null},
    ],
    
    superfilters:[
      { id:'status',
        placeholder:'Estatus',
        filters:[
          {id:1, label:'Prospecto', url:''},
          {id:2, label:'En Proceso', url:''},
          {id:3, label:'Activo', url:''},
          {id:4, label:'Inactivo', url:''},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      { id:'patient_type',
        placeholder:'Tipo',
        filters:[
          {id:1, label:'Directo', url:''},
          {id:2, label:'Solicitante', url:''},
          {id:null, label:'Todos', url:null},
        ]
      },
      { id:'hospital',
        placeholder:'Hospital',
        filters:[]
      },
      { id:'doctor',
        placeholder:'Doctor',
        filters:[]
      },
      { id:'general',
        placeholder:'General',
        filters:[
          {id:0, label:'ID Programa', url:'externalid', key:'external_id'},
          {id:1, label:'Folio', url:'folio', key:'folio'},
          {id:2, label:'Nombre', url:'name', key:'name'},
          {id:3, label:'ID por ti', url:'portiid', key:'por_ti_id'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
    ],
    special_superfilters:[
      { id:'patient_type',
        placeholder:'Tipo',
        filters:[
          {id:1, label:'Directo', url:''},
          {id:2, label:'Solicitante', url:''},
          {id:null, label:'Todos', url:null},
        ]
      },
      //{ id:'product_type',
      //  placeholder:'Producto',
      //  filters:[
      //    {id:1, label:'Oncología', url:''},
      //    {id:2, label:'Alta Especialidad', url:''},
      //    {id:null, label:'Todos', url:null},
      //  ]
      //},
      
      { id:'status',
        placeholder:'Estatus',
        filters:[
          {id:1, label:'Prospecto', url:''},
          {id:2, label:'En Proceso', url:''},
          {id:3, label:'Activo', url:''},
          {id:4, label:'Inactivo', url:''},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      { id:'hospital',
        placeholder:'Hospital',
        filters:[]
      },
      { id:'doctor',
        placeholder:'Doctor',
        filters:[]
      },
      { id:'general',
        placeholder:'General',
        filters:[
          {id:0, label:'ID', url:'externalid', key:'external_id'},
          {id:1, label:'Folio', url:'folio', key:'folio'},
          {id:3, label:'ID por ti', url:'portiid', key:'por_ti_id'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID Por Ti', 'ID Programa','Folio', 'Tipo', 'Nombre', 'Sexo', 'Hospital','Aseguradora','NSE', 'Estatus','Carta'],
    special_header:['ID Por Ti', 'ID Programa','Folio', 'Tipo', 'Sexo', 'Hospital','Aseguradora','NSE', 'Estatus','Carta'],
    header2:[
      {text:'ID', hidden: false},
      {text:'Folio', hidden: false},
      {text:'Nombre', hidden: false},
      {text:'Hospital', hidden: false},
      {text:'Aseguradora', hidden: false},
      {text:'NSE', hidden: false},
      {text:'Estatus', hidden: false},
      {text:'Oferta', hidden: false},
      {text:'Carta', hidden: false},
      {text:'Beneficio', hidden: false},
    ]
  }
}