import { Grid, Icon, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import { getUserType, onGetBenefitVariant } from '../../shared/utility';
import { APPROVAL_STATUS, PATIENT_STATUS, USER_TYPE } from '../../variables/config';
import ContraintLayout from '../Layouts/ConstraintLayout';
import ContentText from '../Texts/ContentText';
import Cycle from './Cycle';

const CyclesContent = (props) => {

    const classes = useStyles()
    const {cycles, patientproduct, patient_status,
        // Patient product's FUnction
        onRefreshPatientProducts,
        // Cycle's Funtions
        onStartNextCycle, onDeleteCycles, onEditPreCycle, onAddPreviousCycle,onDeletePreCycle,
        // Uses's Funtions
        onChangeBenefitStatus, onRegisterUse, onChangeUseDate, onDownloadProviderLetter,
        noedit} = props

    const [previous_cycles, setPreviousCycles] = useState([])
    const [current_cycles, setCurrentCycles] = useState([])

    const userType = getUserType()
    //console.log('ESTATUS EDITABLE CYCLES', noedit)

    //console.log(noedit)
    //console.log('Patient status', patient_status)


    useEffect(() => {
        if(cycles){

            let temp_cycles =  [...cycles].sort(onOrderCycles)

            let _previous = []
            let _current = []

            temp_cycles.forEach(item => {
                if(item.previous){
                    _previous.push(item)
                }else{
                    _current.push(item)
                }
            })
            setPreviousCycles(_previous)
            setCurrentCycles(_current)
        }
    }, [cycles])

    


    const benefitType = onGetBenefitVariant(patientproduct)


    let addpreviouscycleContent = (
        <ContraintLayout constrainst={constrainst.add_previous_cycle} auto bystatus={patient_status}>
            <div className={classes.addBtn} onClick={userType !== USER_TYPE.HOSPITAL 
            ? () => onAddPreviousCycle(patientproduct) : null}>
                <Grid container alignItems='center'>
                    <Grid item><Icon  className={classes.addIcon}>add_circle</Icon></Grid>
                    <Grid item><ContentText text='De clic si quiere agregar un ciclo previo' /></Grid>
                </Grid>
            </div>  
        </ContraintLayout>
    )

    let addzerocyclecontent = (
        <ContraintLayout constrainst={constrainst.add_previous_cycle} auto bystatus={patient_status}>
            <div className={classes.addBtn} onClick={userType !== USER_TYPE.HOSPITAL 
            ? () => onAddPreviousCycle(patientproduct, 'gift') : null}>
                <Grid container alignItems='center'>
                    <Grid item><Icon  className={classes.addIcon}>add_circle</Icon></Grid>
                    <Grid item><ContentText text='Agregar beneficio inicial' /></Grid>
                </Grid>
            </div>  
        </ContraintLayout>
    )

    if(userType === USER_TYPE.HOSPITAL){
        addpreviouscycleContent = (
            <Grid item>
                <Grid container alignItems='center'>
                    <Grid item><ContentText text='No existen ciclos previos' /></Grid>
                </Grid>
            </Grid>
        )
    }

    if(patient_status < PATIENT_STATUS.IN_PROCESS) return null


    return (
        <div className={classes.root}>
            {previous_cycles.length ? (
                <div className={classes.previous_container}>
                    <Grid container>
                        {previous_cycles.map((item, key)=>{
                            return(
                                <Grid item xs={12} key={`previos-cycle-${key}`}>
                                    <Cycle 
                                        id={key+1} patientproduct={patientproduct} 
                                        benefitType={benefitType}
                                        cycle={item} 
                                        userType={userType}
                                        noedit={noedit}  
                                        //isLast={key === previous_cycles.length -1}
                                        /// Patient Product's Funtions
                                        onRefreshPatientProducts={onRefreshPatientProducts}
                                        /// Cycle's Funtions
                                        onStartNextCycle={onStartNextCycle}
                                        onDeleteCycles={onDeleteCycles}
                                        onEditPreCycle={onEditPreCycle}
                                        onDeletePreCycle={onDeletePreCycle}
                                        /// Use's Funtions
                                        onChangeBenefitStatus={onChangeBenefitStatus}
                                        onRegisterUse={onRegisterUse}
                                        onChangeUseDate={onChangeUseDate}
                                        onDownloadProviderLetter={onDownloadProviderLetter}
                                        
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    {noedit ? null :
                    <div style={{paddingBottom:16}}>
                        <Grid container justifyContent='center'>
                            <Grid item >
                                {addpreviouscycleContent}
                            </Grid>
                            <Grid item>
                                { addzerocyclecontent}
                            </Grid>
                        </Grid>
                    </div>}
                </div> 
            ): (
                <div>
                    {noedit ? null :
                    <div className={classes.previous_container} style={{padding:'32px 0px'}}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent='center'>
                                <Grid item>
                                    {addpreviouscycleContent}
                                </Grid>
                                <Grid item>
                                    { addzerocyclecontent}
                                </Grid>
                            </Grid>
                        </Grid> 
                    </div>}
                </div>
            )}
            {current_cycles.length ? (
                <div className={classes.current_container}>
                    <Grid container>
                        {current_cycles.map((item, key)=>{
                            return(
                                <Grid item xs={12} key={`current-cycle-${key}`}>
                                    <Cycle 
                                        id={key+1} patientproduct={patientproduct}
                                        benefitType={benefitType}
                                        cycle={item} userType={userType}
                                        noedit={noedit} 
                                        isLast={key === current_cycles.length -1}
                                        /// Patient Product's Funtions
                                        onRefreshPatientProducts={onRefreshPatientProducts}
                                        /// Cycle's Funtions
                                        onStartNextCycle={onStartNextCycle}
                                        onDeleteCycles={onDeleteCycles}
                                        onEditPreCycle={onEditPreCycle}
                                        onDeletePreCycle={onDeletePreCycle}
                                        /// Use's Funtions
                                        onChangeBenefitStatus={onChangeBenefitStatus}
                                        onRegisterUse={onRegisterUse}
                                        onChangeUseDate={onChangeUseDate}
                                        onDownloadProviderLetter={onDownloadProviderLetter}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    
                </div> 
            ): null}
        </div>
     );
}

const onOrderCycles = (a,b) => {
    if(a.number > b.number){
      return 1
    }
    if(b.number > a.number){
      return -1
    }
    return 0
}

const useStyles = makeStyles(theme => ({
    root:{
        marginTop:32
    },
    current_container:{
        padding:'0px 0px',
        borderTop:`1px solid ${grey[300]}`,
    },
    previous_container:{
        padding:'0px 0px',
        borderTop:`1px solid ${grey[300]}`,
        background:grey[100]
    },
    container:{
        padding:32,
        [theme.breakpoints.down('sm')]: {
          padding:16,
        },
    },
    addBtn:{
        padding:8,
        borderRadius:32,
        '&:hover':{
          background:grey[100],
          cursor:'pointer'
        },
    },
    addIcon:{
        marginTop:4,
        marginRight:8,
        color:theme.palette.primary.main,
      }
}))
 
export default CyclesContent;

const constrainst = {
    add_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    },
    add_previous_cycle:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL,
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    manual_pending_uses:{
        
    },
    edit_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    },
    add_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    }
}