import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'


import {request_doctors, request_products, request_diagnostics, 
  request_public_insurances, request_nse, 
  request_hospitals, request_hospital_details,
  request_insurance_carriers, request_policy_amounts, request_benefits,
  request_external_id, request_folio, request_create_patient,
  request_add_product, request_add_benefit, request_send_approval, request_providers} from './requests'
import RegisterCard from '../../../../components/Layouts/RegisterCard/RegisterCard';
import FormStepA from './FormStepA/FormStepA';
import FormStepB from './FormStepB/FormStepB';
import { patientForm } from './data';
import FormStepC from './FormStepC/FormStepC';
import FormStepD from './FormStepD/FormStepD';

class AddPatient extends Component{

  state = {
    isloading:false,
    issending:false,
    error:false,
    input_error:{},
    selected:0,
    patients:[],
    doctors:[],
    products:[],
    policy_amounts:[],
    diagnostics:[],
    secondary_diagnostics:[],
    public_insurances:[],
    hospitals:[],
    providers:[],
    policy_value:null,
    primary_hospital:null,
    secondary_hospital:null,
    is_public_insurance:false,
    is_secondary_treatment:false,
    insurance_carriers:[],
    payment_type:1,
    balance:false,
    primary_benefit:null,
    secondary_benefit:null,
    nse:null,
    filter:null,
    patient_form:JSON.parse(JSON.stringify(patientForm)),
    tableConfig:{
      filter:null,
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    modals:{
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const { history, language, catalogs, user_level} = this.props
    const {issending, error,  success, isloading, selected,
      patient_form, products, doctors, diagnostics, secondary_diagnostics, public_insurances, nse,
      hospitals, primary_hospital, secondary_hospital,is_public_insurance,
      is_secondary_treatment, payment_type, insurance_carriers, balance, policy_amounts,
      policy_value, input_error, providers
    } = this.state
    const content = contentData[language]

    //providers
    const doctors_catalog = this.getDoctorsOptions(doctors)
    const products_catalog = this.getProductsOptions(products)
    const diagnostics_catalog = this.getDiagnosticsOptions(diagnostics)
    const secondary_diagnostics_catalog = this.getDiagnosticsOptions(secondary_diagnostics)
    const public_insurances_catalog = this.getPublicInsurancesOptions(public_insurances)
    const hospitals_catalog = this.getHospitalsOptions(hospitals)
    const providers_catalog = this.getProvidersOptions(providers)
    const insurance_carriers_catalog = this.getInsuranceCarriersOptions(insurance_carriers)
    let formContent = null

    if(!isloading){
      switch (selected) {
        case 0:
          formContent = (
            <FormStepA 
              user_level={user_level}
              issending={issending}
              error={error}
              input_error={input_error}
              language={language}
              catalogs={catalogs}
              patient_form={patient_form}
              doctors={doctors_catalog}
              products={products_catalog}
              public_insurances={public_insurances_catalog}
              diagnostics={diagnostics_catalog}
              secondary_diagnostics={secondary_diagnostics_catalog}
              nse={nse}
              is_public_insurance={is_public_insurance}
              is_secondary_treatment={is_secondary_treatment}
              onUpdateData={this.onUpdateData}
              onRequestDiagnostic={this.onRequestDiagnostic}
              onRequestNSE={this.onRequestNSE}
              onRequestExternalID={this.onRequestExternalID}
              onRequestFolio={this.onRequestFolio}
              onChangeStep={this.onChangeStep}
              onChangeCheckbox={this.onChangeCheckbox}
            />
          )
          break;
        case 1:
          formContent = (
            <FormStepB 
              issending={issending}
              error={error}
              language={language}
              catalogs={catalogs}
              patient_form={patient_form}
              hospitals={hospitals_catalog}
              providers={providers_catalog}
              is_secondary_treatment={is_secondary_treatment}
              primary_hospital={primary_hospital}
              secondary_hospital={secondary_hospital}
              onUpdateData={this.onUpdateData}
              onRequestHospital={this.onRequestHospital}
              onChangeStep={this.onChangeStep}
            />
          )
          break;
        case 2:
          formContent = (
            <FormStepC
              issending={issending}
              error={error}
              language={language}
              catalogs={catalogs}
              patient_form={patient_form}
              payment_type={payment_type}
              balance={balance}
              policy_value={policy_value}
              insurance_carriers={insurance_carriers_catalog}
              policy_amounts={policy_amounts}
              hospitals={hospitals_catalog}
              is_secondary_treatment={is_secondary_treatment}
              primary_hospital={primary_hospital}
              secondary_hospital={secondary_hospital}
              onUpdateData={this.onUpdateData}
              onChangeBalance={this.onChangeCheckbox}
              onChangePolicyValue={this.onChangePolicyValue}
              onRequestHospital={this.onRequestHospital}
              onChangePaymentType={this.onChangePaymentType}
              onChangeStep={this.onChangeStep}
            />
          )
          break;
        case 3:
            formContent = (
              <FormStepD
                user_level={user_level}
                issending={issending}
                error={error}
                language={language}
                catalogs={catalogs}
                generalState={this.state}
                onUpdateData={this.onUpdateData}
                onChangeBalance={this.onChangeCheckbox}
                onRequestHospital={this.onRequestHospital}
                onChangePaymentType={this.onChangePaymentType}
                onChangeStep={this.onChangeStep}
                onCreatePatient={this.onCreatePatient}
              />
            )
            break;
      
        default:
          break;
      }
    }

    
    console.log(content.views)
    
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        isloading={isloading}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        returnBtn={true}
        onReturn={this.onReturn}
      >
        <Grid container>
          <Grid item xs={12}>
            <RegisterCard
              menu={content.views}
              selected={selected}
            >
              {formContent}
            </RegisterCard>
          </Grid>
        </Grid>
        
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) this.initModule()
  }

  initModule = async() => {
    try{
      this.setState({isloading:true})
      let response
      response = await request_doctors()
      await this.setState({doctors:[...response]})
      response = await request_products()
      this.setState({products:[...response]})
      response = await request_public_insurances()
      this.setState({public_insurances:[...response]})
      response = await request_hospitals()
      this.setState({hospitals:[...response]})
      response = await request_providers()
      this.setState({providers:[...response]})
      response = await request_insurance_carriers()
      this.setState({insurance_carriers:[...response]})
      response = await request_policy_amounts()
      this.setState({policy_amounts:[...response]})
      //let temp = await localStorage.getItem('state')
      //if(temp){
      //  this.setState({...JSON.parse(temp)});
      //  this.setState({selected:3})
      //  this.setState({issending:false})      
      //}
      this.setState({isloading:false})
    }catch(e){
    }
    
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onUpdateData = async(form, stage) => {
    const {patient_form} = this.state
    let temp = {...patient_form}

    Object.keys(form).forEach(item => {
        temp[item] = form[item].value
    })
    if(stage === 3){
      const {policy_value, nse, patient_form} = this.state
      let nse_id
      if(nse){
        nse_id = nse.nse_id
      }else{
        nse_id = patient_form.nse_id
      }

      await this.onCalculateBenefit(temp, policy_value, nse_id)
    }
    await this.setState({patient_form:{...temp}, selected: stage})
    await localStorage.setItem('state',JSON.stringify({...this.state}))
    
  }

  onCreatePatient = async(data) => {
    this.setState({issending:true})
    //console.log('Init data')
    //console.log(data)
    try {
      let first_product, second_product;
      first_product = {
        patient_id:null,
        product_id: data.product_id,
        diagnosis_id: data.diagnosis_id,
        expected_uses: data.expected_uses,
        secondary:0,
      }

      let isSecondaryTreatment = data.secondary_product_id && data.secondary_product_id !== ""
      if(isSecondaryTreatment){
        second_product = {
          patient_id:null,
          product_id: data.secondary_product_id,
          diagnosis_id: data.secondary_diagnosis_id,
          expected_uses: data.secondary_expected_uses,
          secondary:1,
        }
      }
      let response

      let post_data = {...data}
      post_data = {...post_data, balance_value: data.balance_value !== null && data.balance_value !== undefined ? parseInt(parseFloat(data.balance_value)*100) : undefined}
      //console.log('Fixed data')
      //console.log(post_data)
      response = await request_create_patient(post_data)
      let patient_id = response.patient_id
      first_product.patient_id = patient_id
      await request_add_product(first_product)
      if(isSecondaryTreatment){
        second_product.patient_id = patient_id
        await request_add_product(second_product)
      }
      await request_add_benefit(patient_id)
      await request_send_approval(patient_id)
      this.props.history.push('/patients/'+patient_id) 
    } catch (error) {
    }
    this.setState({issending:false})
  }

  onRequestDiagnostic = async(id, level) => {
    this.setState({issending:true})
    try {
      let response
      response = await request_diagnostics(id)
      if(level === 'principal'){
        await this.setState({diagnostics:[...response]})
      }else{
        await this.setState({secondary_diagnostics:[...response]})
      } 
    } catch (error) {
    }
    this.setState({issending:false})
  }

  
  onRequestHospital = async(id, level) => {
    this.setState({issending:true})
    try {
      let response
      response = await request_hospital_details(id)
      if(level==='principal'){
        await this.setState({primary_hospital:{...response}})
      }else{
        await this.setState({secondary_hospital:{...response}})
      }
    } catch (error) {
    }
    this.setState({issending:false})
  }

  
  onRequestNSE = async(zip) => {
    this.setState({issending:true})
    try {
      
      let response
      response = await request_nse(zip)
      await this.setState({nse:{...response}})
    } catch (error) {
      await this.setState({nse:null})
    }
    this.setState({issending:false})
  }

  onRequestExternalID = async(data) => {
    this.setState({issending:true})
    const {input_error} = this.state
    try {
      await request_external_id(data)
      let temp = {...input_error, external_id:undefined};
      this.setState({input_error:{...temp}})
    } catch (error) {
      let temp = {...input_error, external_id:'El ID introducido está en uso'};
      this.setState({input_error:{...temp}})
    }
    this.setState({issending:false})
  }

  onRequestFolio = async(data) => {
    this.setState({issending:true})
    const {input_error} = this.state
    try {
      await request_folio(data)
      let temp = {...input_error, folio:undefined};
      this.setState({input_error:{...temp}})
    } catch (error) {
      let temp = {...input_error, folio:'El ID introducido está en uso'};
      this.setState({input_error:{...temp}})
    }
    this.setState({issending:false})
  }

  onCalculateBenefit = async(temp, policy_value, nse) => {
    try {
      this.setState({issending:true})
      let data2send = {
        "hospital_id":temp.hospital_id,
        "secondary_hospital_id":temp.secondary_hospital_id ? temp.secondary_hospital_id : undefined,
        "policy_value_id":policy_value.policy_value_id,
        "nse_id":nse
      }
      let response = await request_benefits(data2send)
      await this.setState({
        primary_benefit:{...response.primary_benefit}, 
        secondary_benefit:{...response.secondary_benefit}
      })
      this.setState({issending:false})
    } catch (error) {
    }
    
  }

  onChangePolicyValue = (data) => {
    this.setState({policy_value:data})
  }

  onChangePaymentType = (id,value) => {
    
    if(value === 2){
      this.setState({[id]:value, policy_value:{
        policy_value_id: 6,
        value_name: "Bajo"
      }})
    }else{
      this.setState({[id]:value, policy_value:null})
    }
    this.setState({balance:false})
  }

  onChangeCheckbox = (id, status) => {
    if(id === 'is_secondary_treatment'){
      let temp = {...this.state.patient_form}
      temp.secondary_hospital_id = null
      temp.secondary_provider_id = null
      this.setState({patient_form:{...temp}})
    }
    this.setState({[id]:status})
  }

  onChangeStep = (id) => {
    localStorage.setItem('state',JSON.stringify({...this.state}))
    this.setState({selected:id})
  }

  

  getDoctorsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      let fullname = item.first_name
      fullname += item.second_name ? ' ' + item.second_name : ''  
      fullname += item.first_surname ? ' ' + item.first_surname : ''
      fullname += item.second_surname ? ' ' + item.second_surname : ''
      return {
        id: item.id_doctor,
        value: item.id_doctor,
        label: fullname
      }
    })
    return temp
  }

  getProductsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_product,
        value: item.id_product,
        label: item.name + ', ' + item.presentation
      }
    })
    return temp
  } 

  getDiagnosticsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_diagnosis,
        value: item.id_diagnosis,
        label: item.name
      }
    })
    return temp
  } 

  getPublicInsurancesOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_public_insurance,
        value: item.id_public_insurance,
        label: item.name
      }
    })
    return temp
  }
  
  getHospitalsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_hospital,
        value: item.id_hospital,
        label: item.name
      }
    })
    return temp
  }

  getProvidersOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_provider,
        value: item.id_provider,
        label: item.name
      }
    })
    return temp
  }

  getInsuranceCarriersOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_insurance_carrier,
        value: item.id_insurance_carrier,
        label: item.name
      }
    })
    return temp
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}

export default withStyles(styles)(AddPatient)