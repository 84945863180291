import React, { useState, useEffect } from 'react';
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { amber, green } from '@material-ui/core/colors';
import emptyIcon from '../../../../../assets/empty.png'
import PersonalDocument from './PersonalDocument';
const PersonalDocumentList = (props) => {

    const {documents, userType,  viewOnly,
        onEditPersonalDocument, 
        onUploadDocument, onDeleteDocument, onDownloadDocument
    } = props

    //console.log(documents)

    return ( 
        <div>
            <div>
                {/*<Grid container spacing={4}>
                    <Grid item>
                        <Grid container>
                        <Grid item><Icon style={{marginRight:4, fontSize:12, color:green[700]}}>brightness_1</Icon></Grid>
                        <Grid item><ContentText text='Documento aprobado' variant='bold'/></Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                        <Grid item><Icon style={{marginRight:4, fontSize:12, color:amber[700]}}>brightness_1</Icon></Grid>
                        <Grid item><ContentText text='Documento pendiente' variant='bold'/></Grid>
                        </Grid>
                    </Grid>
    </Grid>*/}
            </div>
            <div style={{marginTop:16}}>
                
                {documents.length === 0 ? (
                        <div style={{padding:32}}>
                            <Grid container direction='column' spacing={2} alignItems='center'>
                                <Grid item>
                                    <img src={emptyIcon} alt='' width={125}/>
                                </Grid>
                                <Grid item>
                                    <Typography align='center' variant='body2'>
                                        No tiene ningún documento <br/> registrado aún.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                )
                
                : <CardWrapper>
                {documents.map((item,key)=>{

                    const data2send = {...item, path:'document', obspath:'obsdocument'}
                    //console.log(item)
                    return(
                    <Grid item xs={12} key={key.toString()}>
                        <PersonalDocument data={data2send} userType={userType}
                          onDelete={(path) => onDeleteDocument(item,path)}
                          onUpload={(path) => onUploadDocument(item,path)}
                          onDownload={(path, filename) => onDownloadDocument(item, path, filename )}
                          onEdit={onEditPersonalDocument}
                        />
                    </Grid>
                    )
                })}
                
            </CardWrapper>} 
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({

}))
 
export default PersonalDocumentList;