export const contentData = {
  spanish:{
    layout:{
      title:'Información del asesor',
      navigation:[
        {name:'Asesores',path:'/agents'},
        {name:'Detalles',path:'/agents/'}
      ]
    },
  }
}
