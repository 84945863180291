export const content = {
  title:'Combinar doctor',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Confirmar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    new_doctor_id:{
      label:'Seleccione el doctor principal',
      placeholder:'Seleccione un elemento...',
    },
  },
}