import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'

import WarningModal from '../../../../components/Modals/WarningModal'

import DoctorList from './DoctorList/DoctorList'

import {request_doctors, request_delete_user} from './requests'
import {cleanData2Table} from './customFunctions'

import XLSX from 'xlsx'
import FileSaver from 'file-saver'

class Doctors extends Component{

  state = {
    isloading:false,
    users:[],
    modals:{
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const { history, language,translations, user_level} = this.props
    const {users,  modals, success, isloading} = this.state
    const content = contentData[language]

    const data_users = cleanData2Table(users,content.nodata,translations)
  
    let modalContent = null

    if(modals.warning_delete_user){
      modalContent=(
        <WarningModal 
          data={modals.warning_delete_user}
          content={content.modals.warning_modal}
          onClose={() => this.onCloseModal('warning_delete_user')}
          onDelete={this.onDeleteUser}
        />
      )
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {modalContent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DoctorList 
              user_level={user_level}
              header={content.header}
              data={data_users}
              onUserSelected={this.onItemSelected}
              onAddItem={this.onAddItem}
              onDeleteUser={this.onPreDeleteUser}
              onExport2Excel={this.onExport2Excel}
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }

  onExport2Excel = () => {
    const {users} = this.state
    const table2excel = this.cleanData2Excel(users)
    console.log(table2excel)

    let tabname = 'Doctores'
    let filename = 'doctors.xlsx'

    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Oncopassport Doctors",
      Subject: "General Data",
      Author: "Trionix Technologies",
      CreatedDate: new Date()
    };
    wb.SheetNames.push(tabname);
    let ws_table2excel = table2excel
    let ws_table = XLSX.utils.aoa_to_sheet(ws_table2excel)
    wb.Sheets[tabname] = ws_table;
    let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    FileSaver.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}),filename);

  }

  s2ab = (s) =>{ 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
  }

  cleanData2Excel = (data) => {
    let table = [['ID','Nombre','Segundo nombre','Apellido Paterno','Apellido Materno', 'CRM ID', 'UCI']]
    if(data){
      if(data.length){
        data.forEach((item,key)=> {
          table.push([
            item.id_doctor,
            item.first_name,
            item.second_name,
            item.first_surname,
            item.second_surname,
            item.crm_id,
            item.uci
          ])
        })
      }
    }
    return table
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true,})
    let response = await request_doctors()
    this.setState({users:[...response]})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/catalogs')
  }

  onItemSelected = async(id) => {
    const {history} = this.props
    history.push('/catalogs/doctors/'+id)
  }

  onAddItem = async() => {
    const {history} = this.props
    history.push('/catalogs/doctors/add')
  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_user'
    this.onEditModal(type, {open:true, id:id})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onDeleteUser = async(id) => {
    const type = 'warning_delete_user'
    try {
      this.onEditModal(type, {issending:true})
      let response = await request_delete_user(id)
      this.setState({users:[...response]})
      this.onEditModal(type, {open:false,issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm(type,{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}

export default withStyles(styles)(Doctors)