export const form_data = {
  uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'uses',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'numeric',
      min:1,
      max:99
    }
  },
}