import { mainServer } from "../../variables/config"

const useHospital = () => {

    const onGetByID = async(id) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/hospital/"+id.toString())
        return response.data.data.hospital
    }

    return {
        onGetByID
    };
}
 
export default useHospital;