import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import userimage from '../../../../../assets/personal-security.png'
import { contentData } from './content';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';
import SimpleFile from '../../../../../components/Files/SimpleFile/SimpleFile';
import CheckboxInput from '../../../../../components/Inputs/CheckboxInput/CheckboxInput';
import CheckboxSelectInput from '../../../../../components/Inputs/CheckboxSelectInput/CheckboxSelectInput';
import useOnboardingContext from '../../../../../providers/OnboardingProvider/useOnboardingContext';
import { usePlatformContext } from '../../../../../context/PlatformContext';
import { getUserType, onCalculatePrebenefit, onCalculatePrebenefitNoErrorHandler, onGetFormData, onGetPolicyValue } from '../../../../../shared/utility';
import OnboardingActionBar from '../components/OnboardingActionBar';

const FormStepC = () => {

    const classes = useStyles()
    const {language} = usePlatformContext() 
    const {
      step,
      nse,
      policy_value,
      payment_types,
      policy_amounts,
      insurance_carriers,
      formManager, 
      insurance_policy,
      setInsurancePolicy,
      setPolicyValue,
      treatmentManager,
      setStep,
      setError
    } = useOnboardingContext()

    const {form, onChangeForm} = formManager


    const onSubmit = useCallback(async()=> {

      const userType = getUserType()
      let error = null
      
      let _treatments = [...treatmentManager.treatments]

      const generalData =  formManager.onGetFormData()
      console.log(generalData)

      for(let i=0; i<_treatments.length; i++){
        let _current = treatmentManager.treatments[i];
        const temp = onGetFormData(_current.form)
        console.log(_current, temp)

        let product_id
        if(temp.multiple_piece){
          const product_data = onGetFormData(_current.vials[0])
          if(_current.vials.length) product_id = product_data.product_id
        }else{
          product_id = temp?.product_id
        }
        
        const data2send = {
          product_id:product_id,
          hospital_id:temp.main_hospital_id,
          policy_value_id:policy_value?.policy_value_id,
          nse_id:nse?.nse_id,
          payment_type_id:generalData.payment_type_id,
          scheme_id:temp.scheme_id
        }
        let _prebenefit
        try {
          _prebenefit = await onCalculatePrebenefitNoErrorHandler(data2send, userType)
        } catch (error) {
          const _errorCode = error.response?.data?.error?.code
          console.log("Hubo un error", error.response?.data?.error)
          if(_errorCode === 502){
            setError("Para asignar un beneficio, selecciona un método de pago soportado: Aseguradora (Directo) o Bolsillo")
            return
          }
          if(_errorCode === 503){
            setError("El paciente no puede ser creado dado que no hay un beneficio aplicable")
            return
          }
        }
        
        _current.prebenefit = _prebenefit?.benefit ?? null
        _treatments[i] = {..._current}
      }

      treatmentManager.actions.onUpdateTreatments(_treatments)
      setStep(step+1)
    }, [treatmentManager, nse, policy_value, onGetFormData,formManager, form])

    useEffect(() => {
      const data = onGetFormData(form, ['policy_amount_id', 'balance_value', 'balance', 'payment_type_id'])
      const _policyValue = onGetPolicyValue(data, policy_amounts)
      setPolicyValue(_policyValue)
    }, [form.policy_amount_id.value, form.balance_value.value, form.balance.value, form.payment_type_id.value])

    const content = contentData[language]

    let formContent = null

    //console.log(form)

    let balanceValueContent = null
    if(form.balance.value){
      balanceValueContent = (
        <LineInputText 
          data={form.balance_value}
          onChange={onChangeForm}
        />
      )
    }

    let policyValueContent = null
    if(policy_value){
      policyValueContent = (
        <Grid item xs={12} md={6}>
           <HorizontalDataWrapper 
            label='Valor' 
            text={policy_value.value_name} 
           />
        </Grid>
        
      )
    }

    switch (form.payment_type_id.value) {
      case 1:
      case 4:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={form.insurance_carrier_id}
                onChange={onChangeForm}
                catalogs={insurance_carriers}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={form.policy_amount_id}
                onChange={onChangeForm}
                catalogs={policy_amounts}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputText 
                data={form.policy_expiry_date}
                onChange={onChangeForm}
              />
            </Grid>
            <Grid item xs={12} md={6}/>
            <Grid item xs={12} md={6}>
              {balanceValueContent}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <CheckboxInput data={form.balance}
                    onChange={onChangeForm}/> 
                </Grid>
              </Grid>
            </Grid>
            {policyValueContent}
          </Grid>
        )
        break;
      case 2:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}/>
            {policyValueContent}
          </Grid>
        )
        break;
      case 3:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={form.insurance_carrier_id}
                onChange={onChangeForm}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={form.policy_amount_id}
                onChange={onChangeForm}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputText 
                data={form.policy_expiry_date}
                onChange={onChangeForm}
              />
            </Grid>
            <Grid item xs={12} md={6}/>
            <Grid item xs={12} md={6}>
              {balanceValueContent}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <CheckboxInput data={form.balance}
                    onChange={onChangeForm}/> 
                </Grid>
              </Grid>
            </Grid>
            {policyValueContent}
          </Grid>
        )
        break;
    
      default:
        break;
    }



    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxSelectInput 
                        data={form.payment_type_id}
                        onChange={onChangeForm}
                        catalogs={payment_types}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {formContent}
                      </Grid>
                      {form.payment_type_id.value !== 2 ? (
                        <Grid item xs={12}>
                          <div style={{marginTop:16}}>
                            <SimpleFile 
                              id='insurance_policy'
                              label='Póliza de seguro'
                              file={insurance_policy}
                              onDocumentAttached={(id, files) => setInsurancePolicy(files[0])}
                              onDocumentRemove={() => setInsurancePolicy(null)}/>
                          </div>
                        </Grid>
                      ) : null}
                      
                    </Grid>
                    
                  </Grid>
                  {/* Secondary Hospital View  */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <OnboardingActionBar
          onReturn={() => setStep(step-1)}
          onSubmit={onSubmit}
        />
      </div>
    )
  

}

const useStyles = makeStyles({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:120,
    height:120,
  }
})

export default FormStepC