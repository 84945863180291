import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralView from './GeneralView/GeneralView'
import {request_hospital} from './requests'
import DoctorsView from './DoctorsView/DoctorsView'
import AddDoctorModal from './DoctorsView/modals/AddDoctorModal/AddDoctorModal'

class HospitalView extends Component{

  state = {
    isloading:false,
    hospital:null
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_hospital(id)
    this.setState({hospital:{...response}})
    this.setState({isloading:false})
  }

  onEditUser = async() => {
    const {match,history} = this.props
    const id = match.params.id
    history.push('/catalogs/hospitals/'+id+'/edit')
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/catalogs/hospitals')
  }


  render(){

    const { history, language,translations, user_level, match} = this.props
    const {hospital} = this.state
    const content = contentData[language]

    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <GeneralView
              user_level={user_level}
              language={language}
              hospital={hospital}
              translations={translations}
              onEdit={this.onEditUser}
            />
          </Grid>
          <Grid item xs={12}>
            <DoctorsView 
              history={history}
              match={match}
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(HospitalView)