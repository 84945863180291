export const contentData = {
  spanish:{
    subtitles:{
      basicInfo:'Información de la aseguradora',
    },
    form:{
      insurance_carrier_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      policy_amount_id:{
        label:'Monto',
        placeholder:'Seleccione una opción',
      },
      balance_value:{
        label:'Cantidad de Saldo',
        placeholder:'Cantidad de Saldo',
      },
    }
  }
}