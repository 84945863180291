import React, { useState, useEffect } from 'react';
//import { isFormValid, sleep } from '../../../../../shared/utility';
import { getDiagnosticsOptions, getHospitalsOptions, getProductsOptions } from './customFunctions';
//import { request_diagnostics } from '../requests';
import { isFormValid, null2undefined, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onUpdateAuthorizationHeader, sleep } from '../../../../../../../../shared/utility';
import { private_server } from '../../../../../../../../variables/config';
import useCatalogs from '../../../../../../../../hooks/useCatalogs';
import { catalogs } from '../../../../../../../../variables/catalogs';
import { getSchemesOptions, onGetProductSearchParams } from '../../../../../../../../shared/customFunctions';
import { usePlatformContext } from '../../../../../../../../context/PlatformContext';
import { useCallback } from 'react';


const usePatientProductModal = ({open, mode, patient,onUpdatePatientProducts, onClose, origin}) => {

    const {user, state} = usePlatformContext()
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const [done, setDone] = useState(false)

    const programId = state.programId

    const catalogManager = useCatalogs()

    const [validForm, setValidForm] = useState(false)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(treatment_formdata)))
    const [vials, setVials] = useState([])
    const [hospitals, setHospitals] = useState([])
    const [products, setProducts] = useState([])
    const [diagnoses, setDiagnoses] = useState([])
    const [schemes, setSchemes] = useState([])
    const [product_type, setProductType] = useState()

    const onMultipleInputChange = (data2update) => {
        let _form ={...form, ...data2update}
        setForm(_form)
    }

    const onAutoFillSchemeData = useCallback((data) => {
        //console.log("Autofilldata", data)
        let expected_uses = {...form.expected_uses}
        let vial_number = {...form.vial_number}
        expected_uses.value = data?.cycles
        expected_uses.isValid = true
        vial_number.value = data?.vials
        vial_number.isValid = true

        onMultipleInputChange({expected_uses, vial_number})

    }, [form])

    useEffect(() => {
        const _scheme = schemes.find(el => el.id_scheme == form.scheme_id.value)
        if(_scheme){
            onAutoFillSchemeData(_scheme)
        }
    }, [form.scheme_id])

    //console.log(form)

    //console.log(treatment_formdata)
    //console.log(JSON.parse(JSON.stringify(treatment_formdata)))
    //console.log(form)

    const onRequestProducts = useCallback(async() => {
        let _products = [] 
        try {
           //const params = {product_type_id:product_type?.value}
           let params = {product_type_id:product_type.value}
           if(programId !== -1) params.product_type_id = programId
           _products = await catalogManager.actions.onGetProducts(params)
           _products = getProductsOptions(_products)
           setProducts(_products)
           //setProductCat(_productsCat)
        } catch (error) {
            console.log(error)
        }
    }, [product_type, programId])

    useEffect(() => {
        if(done){
            //console.log("Solcitar nuevos productos")
            onRequestProducts()
        }
    }, [product_type, done, programId])

    useEffect(() => {
        //console.log('Estoy aqui')
        actions.onInitModule()
    }, [open])

    //useEffect(() => {
    //    actions.onUpdateProductsCatalog()
    //}, [products])
//
    //useEffect(() => {
    //    //console.log('Hospitales',hospitals)
    //    actions.onUpdateHospitalsCatalog()
    //}, [hospitals])

    const actions = {
        onInitModule: async() => {
            //console.log('Inicarlizar todo')
            setDone(false)
            let _hospitals = []
            let _products = []

           // console.log('Patient product vial', origin?.vials)

           

            try {
                _hospitals = await catalogManager.actions.onGetHospitals()
                setHospitals(_hospitals)
                if(mode === 'add'){

                    const _tempproductid = onGetProductSearchParams(user)
                    const _producttype = catalogs.spanish.product_types.find(el => el.value === _tempproductid.product_type_id)
                    //console.log("PRODUCT TYPE DATA", _producttype)
                    setProductType(_producttype)

                    _products = await catalogManager.actions.onGetProducts({
                        product_type_id: patient?.product_type_id
                    })
                    setProducts(_products)
                }else{
                    //console.log(origin)
                    //console.log(origin)
                    const _productypeid = origin?.product_type_id
                    const _producttype = catalogs.spanish.product_types.find(el => el.value === _productypeid)
                    setProductType(_producttype)
                    _products = await catalogManager.actions.onGetProducts({
                        product_type_id: origin?.product_type_id
                    })
                    //console.log(_products)
                    setProducts(_products)
                }
                
                
            } catch (error) {
                console.log(error)
            }

            if(mode === 'add'){
                let _form = JSON.parse(JSON.stringify(treatment_formdata))
                //console.log(_form)
                if(_products){
                    const _productCatalog = getProductsOptions(_products)
                    _form.product_id.options = [..._productCatalog]
                }
                if(_hospitals){
                    const _hospitalCatalog = getHospitalsOptions(_hospitals)
                    _form.hospital_id.options = [..._hospitalCatalog]
                }
                _form.treatment_frequency_id.options = [...catalogs.spanish.treatment_frequencies]
                setForm(_form)
            }else{
                //console.log('Edit', origin)
                let _form = onInitForm(JSON.parse(JSON.stringify(treatment_formdata)), origin)
                if(_products){
                    const _productCatalog = getProductsOptions(_products)
                    _form.product_id.options = [..._productCatalog]
                }
                if(_hospitals){
                    const _hospitalCatalog = getHospitalsOptions(_hospitals)
                    _form.hospital_id.options = [..._hospitalCatalog]
                }
                if(origin?.product_id){
                    const _diagnoses = await catalogManager.actions.onGetDiagnosis({
                        productid:origin.product_id
                    })
                    setDiagnoses(_diagnoses)
                    const _diagnosis = getDiagnosticsOptions(_diagnoses)
                    _form.diagnosis_id.options = [..._diagnosis]
                    if(origin?.scheme_id){
                        const _schemesreq = await catalogManager.actions.onGetSchemes({productid:origin?.product_id})
                        console.log(_schemesreq)
                        setSchemes(_schemesreq)
                        const _schemesCat = getSchemesOptions(_schemesreq)
                        _form.scheme_id.options = [..._schemesCat]
                    }
                }
                _form.treatment_frequency_id.options = [...catalogs.spanish.treatment_frequencies]
                setForm(_form)

                if(origin?.vials){

                    let _originalVials = [...origin?.vials]
                    _originalVials.sort((a,b) => {
                        if(a.number > b.number) return 1
                        if(a.number < b.number) return -1
                        return 0
                    })

                    let _vials = []
                    for(let i=0; i<_originalVials.length; i++){
                        const temp = _originalVials[i]
                        let _formvial = onInitForm(JSON.parse(JSON.stringify(vial_formdata)), temp)
                        //console.log('VIals products', _products)
                        const _productCatalog = getProductsOptions(_products)
                        //console.log(_productCatalog)
                        _formvial.product_id.options = _productCatalog 
                        _vials.push(_formvial)
                    }
                    //console.log(_vials)
                    setVials(_vials)
                }  

                

            }
            setDone(true)
        },
        onInputChange: async(data) => {
            //console.log(data)
            const id = data.config.id;
            let temp = {...form}
            temp[id] = {...data};
            
            if(id === 'product_id'){
                let _diagnosis = []
                try {
                    const _diagnoses = await catalogManager.actions.onGetDiagnosis({
                        productid:data.value
                    })
                    setDiagnoses(_diagnoses)
                    _diagnosis = getDiagnosticsOptions(_diagnoses)
                    temp.diagnosis_id.options = [..._diagnosis]
                    temp.diagnosis_id.value = null
                    temp.diagnosis_id.isVisited = false
                    temp.diagnosis_id.isValid = false
                } catch (error) {
                    console.log(error)
                }
                let _schemes = []
                try {
                    console.log("Cambiar schemes")
                    const _schemesreq = await catalogManager.actions.onGetSchemes({
                        productid:data.value
                    })
                    console.log(_schemesreq)
                    setSchemes(_schemesreq)
                    _schemes = getSchemesOptions(_schemesreq)
                    temp.scheme_id.options = [..._schemes]
                    temp.scheme_id.value = null
                    temp.scheme_id.isVisited = false
                    temp.scheme_id.isValid = false
                } catch (error) {
                    console.log(error)
                }
            }

            const isValidForm = isFormValid(temp)
            setForm(temp)
            setValidForm(Boolean(isValidForm))
        },
        onUpdateVial: async(_data, mode) => {
            console.log('Actualizar vial')
            let _vials
            switch (mode) {
                case 'add':
                    // ADD VIAL PRODUCT PROCCESS
                    const last_position = vials.length + 1
                    _data.number.value = last_position
                    _data.number.isValid = true
                    _vials = [...vials, _data]
                    //actions.onChangeModalStatus('add_vial', false)
                    break;
                case 'edit':
                    // EDIT VIAL PRODUCT PROCCESS
                    _vials = [...vials]
                    _vials[_data.number.value-1] = {..._data}
                    //actions.onChangeModalStatus('edit_vial', false)
                    break;
                case 'delete':
                    const pos = _data.number.value
                    _vials = vials.filter(el => el.number.value !== pos)
                    _vials = actions.onReformatVialsNumber(_vials)
                    break;
            
                default:
                    break;
            }
            setVials(_vials)
            console.log(_vials)
            if(_vials.length){
                console.log('Hay mas de 1')
                const firstProduct = _vials[0]
                let temp = {...form}
                try {
                    const _diagnoses = await catalogManager.actions.onGetDiagnosis({
                        productid:firstProduct.product_id.value
                    })
                    setDiagnoses(_diagnoses)
                    const _diagnosis = getDiagnosticsOptions(_diagnoses)
                    temp.diagnosis_id.options = [..._diagnosis]
                    temp.diagnosis_id.value = null
                    temp.diagnosis_id.isVisited = false
                    temp.diagnosis_id.isValid = false
                } catch (error) {
                    console.log(error)
                }
                let _schemes = []
                try {
                    const _schemesreq = await catalogManager.actions.onGetSchemes({
                        productid:firstProduct.product_id.value
                    })
                    setSchemes(_schemesreq)
                    _schemes = getSchemesOptions(_schemesreq)
                    temp.scheme_id.options = [..._schemes]
                    temp.scheme_id.value = null
                    temp.scheme_id.isVisited = false
                    temp.scheme_id.isValid = false
                } catch (error) {
                    console.log(error)
                }
                setForm(temp)

            }

            //await sleep(300)
            //if(product_id) actions.onRequestDiagnosis(product_id, treatment_id)

        },
        onMoveVial: async(_data, mode) => {
            console.log('Mover funcion')
            let  _vials
            _vials = [...vials]
            if(_vials.length <= 1) return

            const current_pos = _data.number.value - 1
            let new_pos = mode === 'up' ? current_pos - 1 : current_pos + 1
            if(new_pos < 0) new_pos = 0
            if(new_pos > _vials.length -1 ) new_pos = _vials.length - 1
            // swap technique
            let backup = _vials[new_pos]
            _vials[new_pos] = _data
            _vials[current_pos] = backup
            _vials = actions.onReformatVialsNumber(_vials)
            
            setVials(_vials)

        },
        onReformatVialsNumber: (data) => {
            let result = [...data]
            data.forEach((item, key) => {
                result[key].number.value = key + 1
            })
            return result
        },
        
        onUpdateProductsCatalog: async() => {
            const _productCatalog = getProductsOptions(products)
            let _form = {...form}
            _form.product_id.options = [..._productCatalog]
            _form.product_id.value = null
            _form.product_id.isVisited = false
            _form.product_id.isValid = false

            _form.diagnosis_id.options = []
            _form.diagnosis_id.value = null
            _form.diagnosis_id.isVisited = false
            _form.diagnosis_id.isValid = false
            setForm(_form)
        },
        onUpdateHospitalsCatalog: async() => {

            const _hospitalCatalog = getHospitalsOptions(hospitals)
            let _form = {...form}
            _form.hospital_id.options = [..._hospitalCatalog]
            _form.hospital_id.value = null
            _form.hospital_id.isVisited = false
            _form.hospital_id.isValid = false
            setForm(_form)
            
        },
        onSubmit: async() => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            setSending(true)

            console.log(errors)
            if(mode === 'add'){
                let data2send = onGetFormData(form)
                console.log(data2send)
                let vials_data2send = []
                for(let i=0; i<vials.length; i++){
                    const temp = onGetFormData(vials[i])
                    vials_data2send.push(temp)
                }
                


                onUpdateAuthorizationHeader(private_server)
                try {
                    data2send.patient_id = patient?.id_patient
                    data2send.product_status_id = 1

                    if(data2send.multiple_piece === 1 && vials_data2send.length){
                        if(vials_data2send.length){
                            data2send.product_id = vials_data2send[0].product_id
                        }else{
                            setError('Es necesario agregar viales para continuar')
                            setSending(false)
                            return
                        }
                        
                    }

                    let response = await private_server.post('/patientproduct', null2undefined(data2send))
                    const patientproductid = response.data.data.patient_product_id

                    if(data2send.multiple_piece === 1){
                        for(let j=0; j<vials_data2send.length;j++){
                            let vialdata = {...vials_data2send[j]}
                            vialdata.patient_product_id = patientproductid
                            await private_server.post('/patientproductvial', null2undefined(vialdata))
                        }
                    }

                    await private_server.post(`/patient/${patient?.id_patient}/benefit/product/${patientproductid}`)

                    response = await private_server.get(`/patientproduct/patient/${patient?.id_patient}`)
                    console.log(response.data.data)
                    onUpdatePatientProducts(response.data.data.patient_products)
                } catch (error) {
                    console.log(error)
                    setError('Hubo un error al crear el nuevo tratamiento, intente de nuevo')
                    setSending(false)
                    return
                }
                onClose()

            }else{
                let data2send = onGetSelectedFormData(form, origin)
                console.log(data2send)
                try {
                    await private_server.patch(`/patientproduct/${origin?.id_patient_product}`, data2send)
                } catch (error) {
                    console.log(error)
                    setError('Hubo un error al crear el actualizar el tratamiento, intente de nuevo')
                    setSending(false)
                    return
                }
//
                

                if(vials){
                    let vials2send = []
                    let vials2add = []
                    let vials2update = []
                    for(let i=0; i<vials.length; i++){
                        const temp = onGetFormData(vials[i])
                        if(temp?.id_patient_product_vial){
                            vials2update.push(temp)
                        }else{
                            vials2add.push(temp)
                        }
                    }
                    //console.log(vials2send)
                    let original_vials = [...origin?.vials]
                    let vials2delete = []

                    for(let i=0; i<original_vials.length; i++){
                        const temp = original_vials[i]
                        const el = vials2update.find(el => el.id_patient_product_vial === temp.id_patient_product_vial)
                        if(!el)  vials2delete.push(temp)
                    }

                    console.log('VIALS ADD', vials2add)
                    console.log('VIALS DELETE', vials2delete)
                    console.log('VIALS UPDATE', vials2update)

                    try {
                        onUpdateAuthorizationHeader(private_server)
                        //////////////////////////// DELETE VIALS ///////////////////////////////////////
                        for(let i=0; i<vials2delete.length; i++){
                            const data2send = vials2delete[i]
                            await private_server.delete(`/patientproductvial/${data2send.id_patient_product_vial}`)
                        }
                        //////////////////////////// UPDATE VIALS ///////////////////////////////////////
                        for(let i=0; i<vials2update.length; i++){
                            const data2send = vials2update[i]
                            await private_server.patch(`/patientproductvial/${data2send.id_patient_product_vial}`, data2send)
                        }
                        //////////////////////////// UPDATE VIALS ///////////////////////////////////////
                        for(let i=0; i<vials2add.length; i++){
                            let data2send = vials2add[i]
                            data2send.patient_product_id = origin?.id_patient_product
                            await private_server.post(`/patientproductvial`, data2send)
                        }
                    } catch (error) {
                        console.log(error)
                        setError('Hubo un error al crear al modificar los viales, intente de nuevo')
                        setSending(false)
                        return
                    }

                    console.log('Check if is multiple', origin?.multiple_piece)
                    try {
                        if(origin?.multiple_piece === 1){
                            //console.log('PATIENT PRODUCT DETAILS', origin, `/patientproduct/${origin?.id_patient_product}`)
                            const response = await private_server.get(`/patientproduct/${origin?.id_patient_product}`)
                            const _patient_product_temp = response.data.data.patient_product
                            
                            console.log('REQUEST PATIENT PRODUCT', _patient_product_temp)
                            let _updatedVials = []
                            if(_patient_product_temp.vials && _patient_product_temp.vials.length){
                                _updatedVials = _patient_product_temp.vials
                                _updatedVials.sort((a,b) => {
                                    if(a.number > b.number) return 1
                                    if(a.number < b.number) return -1
                                    return 0
                                })
                                
                                const new_product_id = _updatedVials[0]
                                console.log('Nuevo producto', {product_id:new_product_id})
                                //console.log('PATIENT PRODUCT DETAILS', new_product_id)
                                await private_server.patch(`/patientproduct/${origin?.id_patient_product}`, {product_id:new_product_id?.product_id})
                            }
                            //console.log('PATIENT PRODUCT DETAILS', response.data.data)
                            //await private_server.patch(`/patientproduct/${origin?.id_patient_product}`, data2send)
                        } 
                    } catch (error) {
                        
                    }
                    

                }
                try {
                    const response = await private_server.get(`/patientproduct/patient/${patient?.id_patient}`)
                    //console.log(response.data.data)
                    onUpdatePatientProducts(response.data.data.patient_products)
                } catch (error) {
                    
                }
                setSending(false)
                onClose()
            }
            setSending(false)
            



        }
    }

    const system = {loading, sending, error}
    const data = {hospitals, products, diagnoses}
    
    return {system, actions, form, vials, data, product_type, setProductType}


}

export default usePatientProductModal

const treatment_formdata = {
    
    multiple_piece:{
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config:{
            id:'multiple_piece',
            type:'select',
            fullWidth: true,
            label:'Multiple Pieza',
            placeholder:'Seleccione un opción',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    product_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'product_id',
            type:'select',
            fullWidth: true,
            label:'Producto + Tratamiento',
            placeholder:'Seleccione un producto',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    diagnosis_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'diagnosis_id',
            type:'select',
            fullWidth: true,
            label:'Diagnóstico',
            placeholder:'Seleccione un diagnóstico',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    scheme_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'scheme_id',
            type:'select',
            fullWidth: true,
            label:'Esquema',
            placeholder:'Seleccione un esquema',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    hospital_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'hospital_id',
            type:'select',
            fullWidth: true,
            label:'Hospital de infusión',
            placeholder:'Seleccione una opción',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    dose:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'dose',
            type:'text',
            fullWidth: true,
            label:'Dosis',
            placeholder:'Dosis',
            helper:'Texto con 256 caracteres permitidos'
        },
        rules:{
            type:'distance',
            min:0,
            max:255
        }
    },
    expected_uses:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'expected_uses',
            type:'number',
            fullWidth: true,
            label:'No. de ciclos',
            placeholder:'No. de ciclos',
            helper:'Número entre 1-50'
        },
        rules:{
            type:'numeric',
            min:0,
            max:50
        }
    },
    vial_number:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'vial_number',
            type:'number',
            fullWidth: true,
            label:'No. de viales',
            placeholder:'No. de viales',
            helper:'Número entre 0-50'
        },
        rules:{
            type:'numeric',
            min:0,
            max:50
        }
    },
    treatment_frequency_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'treatment_frequency_id',
            type:'select',
            fullWidth: true,
            label:'Frecuencia',
            placeholder:'Seleccione una opción',
            helper:'Seleccione una opción'
        },
        rules:{
            type:'none',
        }
    },
    adjusted:{
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config:{
            id:'adjusted',
            type:'checkbox',
            fullWidth: true,
            label:'¿Es ajustado?',
            placeholder:'¿Es ajustado?',
            helper:''
        },
        rules:{
            type:'none',
        }
    },
}

const vial_formdata = {
    id_patient_product_vial:{
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'id_patient_product_vial',
            type:'number',
            fullWidth: true,
            label:'',
            placeholder:'',
            helper:''
        },
        rules:{
            type:'none',
        }
    },
    number:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'number',
        type:'tel',
        fullWidth: true,
      },
      rules:{
        type:'numeric',
        min:1,
        max:100
      }
    },
    product_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'product_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'select',
      }
    },
  }