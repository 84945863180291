import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_header as constrainst} from  '../constraints'
import { ErrorMessage } from '../../../../../../../components/DisplayData/DisplayData'
import { APPROVAL_STATUS, PATIENT_STATUS } from '../../../../../../../variables/config'
import SimpleLoader from '../../../../../../../components/Loaders/SimpleLoader'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons'
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper'
import Subtitle from '../../../../../../../components/Texts/Subtitle'

const useStyles = makeStyles(theme => ({
    root:{
        padding:32,
        [theme.breakpoints.down('sm')]: {
        padding:16,
        },
    }
}))

const StatusHeader = props => {

    const {data, issending, onChangeApprovalStatus, error_message} = props
    const classes = useStyles()

    let approvalStatus = null // now approval status will be use with status id
    //let status = null
    if(data){
        approvalStatus = data.status_id
        //status = data.status_id
    }

    //////////////////////////////  STATUSES LOGIC  ////////////////////////////////////////
    let actionButtonContent = null

    switch (approvalStatus) {
        case PATIENT_STATUS.PROSPECT:
          actionButtonContent = (
            <HeaderWrapper issending={issending}>
                <Grid item>
                    <RoundedButton 
                        size='small' color='primary'
                        label='Enviar a Aprobación'
                        onClick={() => onChangeApprovalStatus(PATIENT_STATUS.IN_PROCESS)}/>
                </Grid>
            </HeaderWrapper>
          )
          break;
        case PATIENT_STATUS.IN_PROCESS: // Pendiente de aprobacion
            actionButtonContent = (
                <HeaderWrapper issending={issending}>
                    <ContraintLayout auto constrainst={constrainst.preregister}>
                        <RoundedButton 
                            size='small' color='primary'
                            label='Convertir en Prospecto'
                            onClick={() => onChangeApprovalStatus(PATIENT_STATUS.PROSPECT)}/>
                    </ContraintLayout>
                    <ContraintLayout auto constrainst={constrainst.dismissed_button}>
                        <RoundedButton 
                            size='small' color='red'
                            label='Rechazar'
                            onClick={() => onChangeApprovalStatus(PATIENT_STATUS.INACTIVE)}/>
                    </ContraintLayout>
                    <ContraintLayout auto constrainst={constrainst.approve_button}>
                        <RoundedButton 
                            size='small' color='green'
                            label='Aprobar'
                            onClick={() => onChangeApprovalStatus(PATIENT_STATUS.ACTIVE)}/>
                    </ContraintLayout>
                </HeaderWrapper>
            )
            break;
        case PATIENT_STATUS.ACTIVE: // Aprobado
            actionButtonContent = (
                <HeaderWrapper issending={issending}>
                    <ContraintLayout auto constrainst={constrainst.disapprove_button}>
                        <RoundedButton 
                            size='small' color='grey'
                            label='Desaprobar'
                            onClick={() => onChangeApprovalStatus(PATIENT_STATUS.PROSPECT)}/>
                    </ContraintLayout>
                    <ContraintLayout auto constrainst={constrainst.disapprove_button}>
                        <RoundedButton 
                            size='small' color='red'
                            label='Desactivar'
                            onClick={() => onChangeApprovalStatus(PATIENT_STATUS.INACTIVE)}/>
                    </ContraintLayout>
                </HeaderWrapper>
            )
            break;
        case PATIENT_STATUS.INACTIVE: // Rechazado
            actionButtonContent = (
                <HeaderWrapper issending={issending}>
                    <ContraintLayout auto constrainst={constrainst.reactive_button}>
                        <RoundedButton 
                            size='small' color='green'
                            label='Reactivar'
                            onClick={() => onChangeApprovalStatus(PATIENT_STATUS.ACTIVE)}/>
                    </ContraintLayout>
                </HeaderWrapper>
            )
            break;
      
        default:
            break;
      }
    

    return(
        <div>
            <ContraintLayout 
                div
                constrainst={constrainst.header} >
                <CardWrapper >
                    <div className={classes.root}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-end' alignItems='center' spacing={2}> 
                                    <Grid item xs>
                                        <Subtitle text='Acciones de aprobación' medium variant='bold'/> 
                                    </Grid>
                                    <Grid item>
                                        {actionButtonContent}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {error_message ? (
                                <Grid item xs={12}>
                                    <ErrorMessage message={error_message} medium/>
                                </Grid>
                            ) : null}
                        </Grid>
                        
                    </div>
                </CardWrapper>
            </ContraintLayout>
        </div>
    )
}

export default StatusHeader

const HeaderWrapper = props => {

    const {issending} = props

    let sendingLoader = null
    if(issending){
      sendingLoader = (
        <Grid item><SimpleLoader /></Grid>
      )
    }

    return(
        <Grid container spacing={2} justifyContent='flex-end' alignItems='center'>
            {sendingLoader}
            {props.children}
        </Grid>
    )
}