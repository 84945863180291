export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    nospecialities:'No existen especialidades registradas',
    title:{
      sectionB:'Doctor',
      sectionC:'Detalles',
    },
    sectionA:{
      name:{
        label:'Nombre'
      },
      hospital_value_name:{
        label:'Nivel'
      },
      address:{
        label:'Dirección'
      },
      accredited:{
        label:'Acreditado'
      },
      contact_name:{
        label:'Nombre'
      },
      contact_email:{
        label:'E-mail'
      },
      contact_phone:{
        label:'Teléfono'
      },
      contact_ext:{
        label:'Extensión'
      },
      provider:{
        label:'Distribuidor'
      },
      provider_contact_name:{
        label:'Contacto'
      },
      provider_contact_email:{
        label:'E-mail'
      },
      provider_contact_phone:{
        label:'Teléfono'
      },
      provider_contact_ext:{
        label:'Extensión'
      },
      crm_id:{
        label:'CRM ID'
      },
      uci:{
        label:'UCI'
      },
    },
  },
  
}