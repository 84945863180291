export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    add_button:'Guardar',
    title:{
      sectionA:'Información Básica',
      sectionB:'Información Detallada',
      sectionC:'Detalles',
    },
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Longitud entre 1-254 caractéres'
      },
      contact_name:{
        label:'Nombre de contacto',
        placeholder:'Nombre de contacto',
        helper:'Longitud entre 1-254 caractéres'
      },
      contact_email:{
        label:'E-mail de contacto',
        placeholder:'E-mail de contacto',
        helper:'E-mail incorrecto'
      },
      contact_phone:{
        label:'Teléfono de contacto',
        placeholder:'Teléfono de contacto',
        helper:'El número debe contener entre 10-12 dígitos'
      },
      contact_ext:{
        label:'Extensión de contacto',
        placeholder:'Extensión de contacto',
      },
      address:{
        label:'Dirección',
        placeholder:'Dirección',
        helper:'Dirección no válida'
      },
      crm_id:{
        label:'CRM ID',
        placeholder:'CRM ID',
      },
      uci:{
        label:'UCI',
        placeholder:'UCI',
      },
    },
  },
}