export const content = {
  title:'Estatus Cartas',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Guardar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    status_id:{
      letter_status_id:'Estatus Cartas',
      placeholder:'Seleccione una opción',
    },
  },
}