import {mainServer} from '../../../../variables/config'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_add_user = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  //let response
  await mainServer.instance.post("/user", data)
}

export const request_hospitals = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/hospital/all")
  return response.data.data.hospitals
}