import React from 'react'
import { IconButton, Icon, makeStyles, Grid, Avatar } from '@material-ui/core'
import ContentText from '../../../../../../components/Texts/ContentText'
import nouser from '../../../../../../assets/nouser.png'
import { red, grey } from '@material-ui/core/colors'
import {constrainst_doctor as constrainst} from  '../constraints'
import ContraintLayout from '../../../../../../components/Layouts/ConstraintLayout';

const useStyles = makeStyles(theme => ({
    root:{
      padding:16,
      borderTop:`2px solid ${grey[200]}`
      //paddingLeft:32,
      //paddingRight:32,
    },
    iconbutton:{
      color:red[700]
    }
}))

const SingleDoctor = props => {

    const classes = useStyles()
    const {data, onDelete} = props

    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1}>
                <Grid item>
                    <Avatar src={nouser} alt='' />
                </Grid>
                <Grid item xs>
                    <ContentText text={data.doctor} medium />
                </Grid>
                <ContraintLayout constrainst={constrainst.delete_doctor} auto>
                    <IconButton 
                    onClick={() => onDelete(data)}
                    size='small' className={classes.iconbutton}>
                        <Icon>close</Icon>
                    </IconButton>
                </ContraintLayout>
            </Grid>
        </div>
    )
}

export default SingleDoctor