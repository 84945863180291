import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import {DataItemWrapper, TableItem} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'

import {formData} from './data'
import {contentData} from './content'
import { green, red } from '@material-ui/core/colors';

const styles = (theme) => ({
  root:{padding:'32px'}
})



class ProfileInfo extends Component {

  updateForm = (data, user, content, catalogs) => {
    let form = {}
    Object.keys(data).forEach((item,key)=> {
      let value = null
      if(user){
        value = user[item]
        if(item === 'gender_id'){
          if(value)
          value = catalogs.user_genders[value-1].label
        }
        if(item === 'user_type_id'){
          if(value)
          value = catalogs.user_types[value-1].label
        }
      }
      const aux = {...data[item], ...content[item], data:value}
      form = {...form, [item]:{...aux}}
    })
    return form
  }

  render(){

    const {classes, catalogs, language, user, onEdit} = this.props
    const content = contentData[language]

    let formA = {...formData.sectionA}

    let contentA = null
    let avatarContent = null

 
    formA = this.updateForm(formA, user, content.sectionA, catalogs)

    contentA = Object.keys(formA).map((item,key)=> {
      if(item !== 'status'){
        return(
          <Grid item xs={12} md={6} key={key.toString()}>
            <DataItemWrapper 
              label={formA[item].label}
              data={formA[item].data}
              error={content.error}
              icon={formA[item].icon}
            />
          </Grid>
        )
      }
      return null
      
    })
    avatarContent = <ProfileAvatar image={user ? user.image : null}/>

    return(
      <Grid container spacing={3}>
      <Grid item xs={12}>
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
                <Grid item>{avatarContent}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {contentA}
                    <Grid item xs={12} md={6}>
                      <TableItem 
                        label={formA.status.label}
                        icon='brightness_1'
                        iconSize={12}
                        iconColor={formA.status.data ? green[700] : red[700]}
                        data={formA.status.data ? 'Activo' : 'Inactivo'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <RoundedButton label='Editar' size='small' color='secondary' onClick={onEdit}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
      </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ProfileInfo)