import React from 'react'
import { makeStyles, Grid } from '@material-ui/core';
import cx from 'classnames'
import { green, red } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  root:{

  },
  green_status:{
    background:green[700],
    padding:32,
    color:'white',
    fontWeight:600,
    borderRadius:32,
    textAlign:'center'
  },
  red_status:{
    background:red[700],
    padding:32,
    color:'white',
    fontWeight:600,
    borderRadius:32,
    textAlign:'center'
  }
}))

const StatusMessage = props => {

  const {status} = props
  const classes = useStyles()

  let message2show = 'El beneficiario no cuenta con los datos necesarios para generar una propuesta y se realizará un pre-registro'
  if(status){
    message2show = 'El beneficiario cuenta con los datos necesarios para generar una propuesta y enviarla a aprobación'
  }


  return(
    <Grid container>
      <Grid item xs={12}>
        <div className={cx({
          [classes.green_status]:status,
          [classes.red_status]:!status,
        })}>
          {message2show}
          
        </div>
      </Grid>
    </Grid>
  )
}

export default StatusMessage