import React, {Component, Fragment} from 'react'
import { TableRow,TableCell, Collapse } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import {TableItem, PatientRewards} from '../../../../../components/DisplayData/DisplayData'

import { green, red, grey, lightBlue, orange, purple } from '@material-ui/core/colors';
import ExpandableButton from '../components/ExpandableButton'
import { onGetCatalogElement } from '../../../../../shared/utility'
import { catalogs } from '../../../../../variables/catalogs'
import PatientProductRow from '../components/PatientProductRow'


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'16px 16px',
    border:0,
    minWidth:50,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablecell2:{
    maxWidth:200,
    borderTop:`2px solid ${grey[200]}`,
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class SpecialPatientList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    //this.setState({rowsPerPage:value})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = 0
    temp.rowsPerPage = value
    onChangeConfig(temp)
  }

  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    console.log('Cambiar filtro normal')
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }
  
  onChangeMasterFilter = (key, data) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
        console.log('Cambiar filtro master')

    Object.keys(temp.filter).forEach(item => {
      if(item === key){
        temp.filter[item] = data
      }else{
        temp.filter[item] = null
      }
    })
    temp.page = 0
    onChangeConfig(temp)

  }

  render(){

    const {classes, title, header, data, onUserSelected, onAddPatient, loading, view,
      filters, config, master_filters, user_level, total_items, onUpdateExpandableView, expandable
    } = this.props
    const { page, rowsPerPage, isSearch} = config

    //console.log(total_items)

    const expandableButton = <ExpandableButton checked={expandable} onChange={() => onUpdateExpandableView(!expandable)} />

    


    return(
      
      <div className={classes.root}>
        <SimpleTable
          title={title}
          loading={loading}
          header={header}
          startAdorment={expandableButton}
          isSearch={isSearch}
          filter={config.filter}
          filters={filters}
          master_filters={master_filters}
          onChangeFilter={this.onChangeFilter}
          onChangeMasterFilter={this.onChangeMasterFilter}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={total_items}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={user_level < 4}
          onAddItem={onAddPatient}
        >
          {data.map((item,key)=>{
            //console.log(item)

            let gender = onGetCatalogElement(item?.gender_id, catalogs.spanish.genders)
            let patient_status = catalogs.spanish.patient_statuses.find(el => el.value === item?.status_id)
            let letter_status = catalogs.spanish.letter_statuses.find(el => el.value === item?.letter_status_id)

            return(
              <Fragment key={key.toString()}>
                <TableRow  
                className={classes.tablerow} 
                >
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={item?.por_ti_id ?? '-'}
                      textStyle={{minWidth:80}}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={item?.external_id ?? '-'}
                      textStyle={{minWidth:80}}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={item?.folio ?? '-'}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                        icon='brightness_1'
                        iconSize={12}
                        iconColor={item.data[10] === 'Paciente' ? green[700] : purple[700]}
                        data={item.data[10]}
                      />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={gender?.label ?? '-'}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell2}   onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={item.data[3]}
                      wrap textStyle={{width:200}}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={item.data[4]}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      data={item.data[5]}
                    />
                  </TableCell>

                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      icon='brightness_1'
                      iconSize={12}
                      iconColor={patient_status?.color}
                      data={patient_status?.label}
                    />
                  </TableCell>
                  <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                    <TableItem 
                      icon='brightness_1'
                      iconSize={12}
                      iconColor={letter_status?.color}
                      data={letter_status?.label ?? '-'}
                    />
                  </TableCell> 
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding:0 }} colSpan={11}>
                    <Collapse in={expandable} timeout="auto" unmountOnExit>
                      <PatientProductRow data={item?.patient_products ?? []} />
                    </Collapse>
                  </TableCell>  
                </TableRow>
              </Fragment>
              
            )
          })}
        </SimpleTable>
      </div>
    )
  }

  getPatientStatusColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = lightBlue[700]
        break;
      case 2:
        color = green[700]
        break;
      case 3:
        color = red[700]
        break;
      default:
          color = grey[500]
        break;
    }
    return color
  }

  getApprovalStatusColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = grey[700]
        break;
      case 2:
        color = lightBlue[700]
        break;
      case 3:
        color = green[700]
        break;
      case 4:
          color = orange[700]
          break;
      case 5:
          color = red[700]
          break;
      default:
          color = grey[500]
        break;
    }
    return color
  }

  getLetterStatusColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = red[700]
        break;
      case 2:
        color = lightBlue[700]
        break;
      case 3:
        color = green[700]
        break;
      default:
          color = grey[500]
        break;
    }
    return color
  }


  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    console.log('Es aqui')
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }
}

export default withStyles(styles)(SpecialPatientList)