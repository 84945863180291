import * as React from 'react'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actionTypes'
import useAppCatalogs from '../hooks/api/useAppCatalogs'
import { BENEFIT_TYPE } from '../variables/config'

const PlatformContext = React.createContext()

const initialState = {
    count:0,
    programId:-1,
}

function platformReducer(state, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PROGRAM_ID: {
      return updateObject(state, {programId:action.programId})
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function PlatformProvider({children, user}) {
  const catalogManager = useAppCatalogs()
  const [state, dispatch] = React.useReducer(platformReducer, initialState)
  const [language, setLanguage] = React.useState('spanish')
  const [benefits, setBenefits] = React.useState([])
  const [done, setDone] = React.useState(false)

  const initialize = React.useCallback(async() => {
    // Get catalogs
    try {
      let _benefits = await catalogManager.onGetBenefits()
      for(let i=0; i<_benefits.length; i++){
        let temp = _benefits[i]
        if(temp.benefit_type_id === BENEFIT_TYPE.INTERMITENT){
          temp.total = parseInt(temp?.uses ?? 0) + parseInt(temp?.benefit ?? 0)
          temp.recurrence_array = (temp?.recurrence??"").split(",")
        }
      } 
      setBenefits(_benefits)
    } catch (error) {
      console.log(error)
    }
    setDone(true)
  }, [])

  React.useEffect(() => {
    if(user && !done) initialize()
  },[user])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, user, language,benefits, dispatch}
  return <PlatformContext.Provider value={value}>{children}</PlatformContext.Provider>
}

function usePlatformContext() {
  const context = React.useContext(PlatformContext)
  if (context === undefined) {
    throw new Error('usePlatformContext must be used within a PlatformProvider')
  }
  return context
}

export {PlatformProvider, usePlatformContext}