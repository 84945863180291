import {grey} from '@material-ui/core/colors';


const styles = theme => ({
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} )`,
  },
  container:{
    display:'flex',
    position: "relative",
    zIndex: "50", 
    height:'100vh',
  },
  card:{
    flexGrow:1,
    width:'400px',
    boxSizing:'border-box',
    borderRadius:'16px',
    [theme.breakpoints.down('sm')]: {
      margin:'auto',
      padding: '0px',
      width:`350px`,
    },
    background:'white'
  },
  cardContent:{
    width:'100%',
    boxSizing:'border-box',
    padding:'40px 16px',
  },
  button:{
    marginTop:' 0px',
    background: `linear-gradient(135deg, ${theme.palette.primary.main} , ${theme.palette.secondary.main})`,
    borderRadius:'20px',
    height:'40px',
    width:'200px'
  },
  formContent:{
    padding:'0px'
  },
  mobileFormContent:{
    margin: '20px 20px'
  },
  mobileContainer:{
    display:'flex',
    position: "relative",
    zIndex: "50",
    margin:'0 0',
    height:'100vh'
  },
  typoMobile:{
    color:'white'
  },
  errorMessage:{
    color:'red',
    fontSize:'14px',
    fontWeight:'600'
  },
  ima:{
    margin:'auto',
    width:"160px", 
  },
  subtitle:{
    color:grey[700],
    fontWeight:600
  },
  letter:{
    fontStyle: 'italic',
    color:grey[900],
    fontWeight:'500',
  },
  bor:{
    border:'1px solid red'
  },
});

export default styles;
