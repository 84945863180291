import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, AreaChart, ComposedChart, Area, Scatter } from 'recharts';
import { makeStyles, Typography, Grid, Icon } from '@material-ui/core';


const CustomTooltip = ({ active, payload, label }) => {
	const classes = useStyles()

	if (active && payload && payload.length) {
	  return (
			<div className={classes.custom_tooltip}>
				<p className={classes.name}>{`${label}`}</p>
				<p className={classes.label} style={{color: '#4466F2'}}>{`Beneficios : ${payload[0].value}`}</p>
				<p className={classes.label} style={{color: '#0847A7'}}>{`Compras : ${payload[1].value}`}</p>
			</div>
		);
	}
  
	return null;
};

const renderLegend = (value, entry) => {
	return (
		<span style={{ color: '#616161', fontSize: 12, fontFamily: 'Poppins', marginRight: value === 'sold_units' ? 0 : 15 }}>
			{value === 'sold_units' ? 'Compras' : 'Beneficios'}
		</span>
	);
  };

const Chart = ({ data, xKey, step, domainY }) => {
	const classes = useStyles();

	const _data_area = step === 3 
	? data.map((item) => (
		{ ...item, timestamp: `${item.year}-${item.month}` })) : data;
	const xAxis = step < 4 ? 'timestamp' : 'year';

	return (
		<div className={classes.root}>
			<ResponsiveContainer width='100%' height='100%'>
				<AreaChart data={_data_area} width={1000} height={350} margin={{ top: 35, right: 30, left: 10, bottom: 10 }}>
					<defs>
						<linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
							<stop offset='5%' stopColor='#4466F2' stopOpacity={0.8} />
							<stop offset='95%' stopColor='#4466F2' stopOpacity={0} />
						</linearGradient>
						<linearGradient id='colorUv2' x1='0' y1='0' x2='0' y2='1'>
							<stop offset='5%' stopColor='#0847A7' stopOpacity={0.8} />
							<stop offset='95%' stopColor='#0847A7' stopOpacity={0} />
						</linearGradient>
					</defs>
					<XAxis dataKey={xAxis} tickLine={false} axisLine={false} key={Math.random()}  />
					<YAxis tickLine={false} axisLine={false} key={Math.random()} domain={domainY} />
					<CartesianGrid horizontal={false} />
					<Tooltip content={<CustomTooltip />} />
					<Legend verticalAlign='top' align='right' formatter={renderLegend} />
					<Area type='monotone' dataKey='benefit_units' stroke='#4466F2' fillOpacity={1} fill='url(#colorUv)' legendType='square' />
					<Area type='monotone' dataKey='sold_units' stroke='#0847A7' fillOpacity={1} fill='url(#colorUv2)' legendType='square'  />
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: 400
	},
	custom_tooltip:{
		background: 'rgba(255,255,255,0.8)',
		borderRadius: 10,
		padding: '5px 10px'
	},
	label:{
	},
	name: {
		textAlign: 'center',
		fontWeight: 600
	}
}));

export default Chart;
