export const cleanData2Table = (data, nodata, translations) => {
  
  const table = data.map(item => {
    
    let temp = {
      id:item.id_doctor, 
      data:[]
    }

    let array = [
      item.id_doctor,
      item.first_name ? item.first_name : nodata, 
      item.second_name ? item.second_name : nodata, 
      item.first_surname ? item.first_surname : nodata, 
      item.second_surname ? item.second_surname : nodata,
      item.crm_id ? item.crm_id : '-',
      item.uci ? item.uci : '-', 
    ]
    temp.data = [...array]
    return temp
  })

  return table
}