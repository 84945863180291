
//import {catalogs} from '../../../../../../../../variables/catalogs'
//const catalog = catalogs.spanish

export const formData = {
  main_hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'main_hospital_id',
      fullWidth: true,
      label:'Hospital',
      placeholder:'Seleccione un hospital',
      helper:'Hospital no válido'
    },
  },
}