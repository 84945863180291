import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import {DataItemWrapper} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'

import InfoButton from '../../../../../components/Buttons/InfoButton';
import moment from 'moment'
import ContentText from '../../../../../components/Texts/ContentText';

const styles = (theme) => ({
  root:{padding:'32px'}
})



class GeneralView extends Component {

  updateForm = (data, provider, content, user_level) => {
    const {translations} = this.props
    let form = {}
    Object.keys(data).forEach((item,key)=> {
      let value = null
      if(provider){
        value = provider[item]
        if(data[item].translate){
          value = translations[value]
        }
        if(item === 'mobile'){
          let aux =  provider.phone_code ? provider.phone_code : ''
          value = aux + ' ' + value 
        }
      }
      const aux = {...data[item], ...content[item], data:value}
      form = {...form, [item]:{...aux}}
    })
    return form
  }

  render(){

    const {classes, language, provider, onEdit, user_level} = this.props
    const content = contentData[language]
    let formA = {...formData.sectionA}

    formA = this.updateForm(formA, provider, content.sectionA)

    let infoButtonData = []
    const nodata = 'Sin Registro'
    if(provider){
      infoButtonData = [
        {
          title:'Detalles de creación',
          content:[
            {label:'Nombre: ', text: provider.creator ? provider.creator : nodata},
            {label:'Fecha: ', text: provider.created_at ?  moment(provider.created_at).format("DD/MM/YYYY") : nodata},
          ]
        },
        {
          title:'Detalles de actualización',
          content:[
            {label:'Nombre: ', text: provider.updater ? provider.updater : nodata},
            {label:'Fecha: ', text: provider.updated_at ?  moment(provider.updated_at).format("DD/MM/YYYY") : nodata},
          ]
        },
      ]
    }


    return(
      <Grid container spacing={3}>
      <Grid item xs={12}>
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={6}>
            {/************************** CONTACT ******************** */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <ContentText text='Informacion general' variant='bold' medium/>
                    </Grid>
                    <Grid item>
                      <InfoButton data={infoButtonData}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.name.label}
                        data={formA.name.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.address.label}
                        data={formA.address.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.crm_id.label}
                        data={formA.crm_id.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.uci.label}
                        data={formA.uci.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.contact_name.label}
                        data={formA.contact_name.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.contact_email.label}
                        data={formA.contact_email.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.contact_phone.label}
                        data={formA.contact_phone.data}
                        error={content.error}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataItemWrapper
                        label={formA.contact_ext.label}
                        data={formA.contact_ext.data}
                        error={content.error}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {user_level<4 ? 
              <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <RoundedButton label='Editar' size='small' color='secondary' onClick={onEdit}/>
                  </Grid>
                </Grid>
              </Grid>
            :null}
          </Grid>
        </div>
      </CardWrapper>
      </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(GeneralView)