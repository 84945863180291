import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';
import SimpleModalV2 from '../../../../components/Modals/SimpleModalV2';
import GenericFilter from './GenericFilter';

const FilterModal = ({
    open, 
    onClose,
    filter,
    catalogs,
    onUpdateFilter,
    filter_disabled
}) => {

    //console.log(filter)

    const [localFilter, setLocalFilter] = useState({})

    useEffect(() => {
        if(open && filter) setLocalFilter(filter)
    }, [open, filter])

    const onInnerChange = (data, id) => {
        console.log(data, id)
        let temp = {...localFilter}
        temp[id] = data
        console.log(temp)
        setLocalFilter(temp)
    }

    const onSubmit = () => {
        console.log(localFilter)
        onUpdateFilter(localFilter)
        onClose()
    }

    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <div>
                <Typography variant='h6' color='primary'>Filtros</Typography>
                <Typography variant='body2'>Seleccione los filtros que desee agregar</Typography>

                <div style={{marginTop:24, maxHeight:500, overflowY:'auto'}}>
                    <div style={{padding:16}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='patient_status_id' label='Estatus de paciente' placeholder='Estatus de paciente...' disabled={filter_disabled ? filter_disabled.includes('patient_status_id') : false}
                                    value={localFilter.patient_status_id?.value} filters={catalogs.patient_status}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='inactive_reason_id' label='Razón de inactividad' placeholder='Razón de inactividad...' disabled={filter_disabled ? filter_disabled.includes('inactive_reason_id') : false}
                                    value={localFilter.inactive_reason_id?.value} filters={catalogs.inactive_reasons}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='payment_type_id' label='Forma de pago' placeholder='Forma de pago...' disabled={filter_disabled ? filter_disabled.includes('payment_type_id') : false}
                                    value={localFilter.payment_type_id?.value} filters={catalogs.payment_types}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='main_hospital_id' label='Hospital de consulta' placeholder='Hospital  de consulta...' disabled={filter_disabled ? filter_disabled.includes('main_hospital_id') : false}
                                    value={localFilter.main_hospital_id?.value} filters={catalogs.hospitals}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='patient_product_hospital_id' label='Hospital de infusión' placeholder='Hospital de infusión...' disabled={filter_disabled ? filter_disabled.includes('patient_product_hospital_id') : false}
                                    value={localFilter.patient_product_hospital_id?.value} filters={catalogs.hospitals}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='doctor_id' label='Doctor' placeholder='Doctor...' disabled={filter_disabled ? filter_disabled.includes('doctor_id') : false}
                                    value={localFilter.doctor_id?.value} filters={catalogs.doctors}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='insurance_carrier_id' label='Aseguradora privada' placeholder='Aseguradora privada...' disabled={filter_disabled ? filter_disabled.includes('insurance_carrier_id') : false}
                                    value={localFilter.insurance_carrier_id?.value} filters={catalogs.insurance_carries}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='public_insurance_id' label='Aseguradora pública' placeholder='Aseguradora pública...' disabled={filter_disabled ? filter_disabled.includes('public_insurance_id') : false}
                                    value={localFilter.public_insurance_id?.value} filters={catalogs.public_insurances}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='provider_id' label='Distribuidor' placeholder='Distribuidor...' disabled={filter_disabled ? filter_disabled.includes('provider_id') : false}
                                    value={localFilter.provider_id?.value} filters={catalogs.providers}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='product_id' label='Producto' placeholder='Producto...' disabled={filter_disabled ? filter_disabled.includes('product_id') : false}
                                    value={localFilter.product_id?.value} filters={catalogs.products}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='benefit_type_id' label='Tipo de beneficio' placeholder='Tipo de beneficio...' disabled={filter_disabled ? filter_disabled.includes('benefit_type_id') : false}
                                    value={localFilter.benefit_type_id?.value} filters={catalogs.benefit_types}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='benefit_id' label='Beneficio' placeholder='Beneficio...' disabled={filter_disabled ? filter_disabled.includes('benefit_id') : false}
                                    value={localFilter.benefit_id?.value} filters={catalogs.benefits}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='treatment_frequency_id' label='Frecuencia' placeholder='Frecuencia...' disabled={filter_disabled ? filter_disabled.includes('treatment_frequency_id') : false}
                                    value={localFilter.treatment_frequency_id?.value} filters={catalogs.treatment_frequencies}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='gender_id' label='Género' placeholder='Género...' disabled={filter_disabled ? filter_disabled.includes('gender_id') : false}
                                    value={localFilter.gender_id?.value} filters={catalogs.genders}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='state' label='Estado' placeholder='Estado...' 
                                    value={localFilter.state?.value} filters={catalogs.states}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericFilter 
                                    id='nse_id' label='Nivel socioeconómico' placeholder='Nivel socioeconómico...' 
                                    value={localFilter.nse_id?.value} filters={catalogs.nses}
                                    onChangeFilter={onInnerChange}  enableClear
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div style={{marginTop:24}}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <RoundedButton label='FILTRAR' fullWidth onClick={onSubmit}/>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </SimpleModalV2>
     );
}
 
export default FilterModal;