export const patientForm = {
	"external_id":null,
	"folio":null,
	"doctor_id":null,
	"hospital_id":null,
	"secondary_hospital_id":null,
	"payment_type_id":null,
	"insurance_carrier_id":null,
	"public_insurance_status":null,
	"public_insurance_id":null,
	"policy_amount_id":null,
	"balance":null,
	"balance_value":undefined,
	"policy_value_id":null,
	"zip":null,
	"zip_code_id":null,
	"nse_id":null,
	"letter_status_id":1,
	"patient_letter":"0",
	"doctor_letter":"0",
	"distributor_letter":"0",
	"secondary_distributor_letter":"0",
	"approval_status_id":1,
	"status_id":1
}