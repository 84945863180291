import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';

import userimage from '../../../../../assets/personal-security.png'
import { contentData } from './content';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import { mainForm } from './data';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';
import {ErrorMessage } from '../../../../../components/DisplayData/DisplayData';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';

class FormStepC extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    formData: JSON.parse(JSON.stringify(mainForm)),
    doctors:[],
    products:[],
    diagnostics:[],
    is_public_insurance:false,
    is_secondary_treatment:false,
  }

  render(){

    const {classes, language, issending, error, catalogs, 
      insurance_carriers, payment_type, policy_value, balance} = this.props
    const {formData} = this.state

    const content = contentData[language]
    formData.insurance_carrier_id.options = [...insurance_carriers]
    formData.policy_amount_id.options = [...catalogs.policy_amounts]

    let formContent = null

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    let balanceValueContent = null
    if(balance){
      balanceValueContent = (
        <LineInputText 
          data={formData.balance_value}
          onChange={this.onInputChange}
        />
      )
    }

    let policyValueContent = null
    if(policy_value){
      policyValueContent = (
        <Grid item xs={12} md={6}>
           <HorizontalDataWrapper 
            label='Valor' 
            text={policy_value.value_name} 
           />
        </Grid>
        
      )
    }

    switch (payment_type) {
      case 1:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formData.insurance_carrier_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formData.policy_amount_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {balanceValueContent}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ItemSelection 
                    id='balance'
                    status={balance}
                    text={'Saldo'}
                    onChange={(id, status) => this.onChangeBalance(id, status)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {policyValueContent}
          </Grid>
        )
        break;
      case 2:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}/>
            {policyValueContent}
          </Grid>
        )
        break;
      case 3:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formData.insurance_carrier_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formData.policy_amount_id}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {balanceValueContent}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ItemSelection 
                    id='balance'
                    status={balance}
                    text={'Saldo'}
                    onChange={(id, status) => this.onChangeBalance(id, status)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {policyValueContent}
          </Grid>
        )
        break;
    
      default:
        break;
    }



    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          {catalogs.payment_types.map((item,key)=>{
                            return(
                              <Grid item xs={12} md={4} key={key.toString()}>
                                <ItemSelection 
                                  id='payment_type'
                                  status={payment_type === item.value}
                                  text={item.label}
                                  onChange={(id,status) => this.onChangePaymentType(id, item.value)}
                                />
                              </Grid>
                            )
                          })}
                          
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    {formContent}
                  </Grid>
                  {/* Secondary Hospital View  */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
              <Grid item>
                {messageContent}
              </Grid>
              <Grid item>
                <RoundedButton 
                  label='Regresar'
                  onClick={this.onPreviousStep}
                  color='transparent'
                />
              </Grid>
              <Grid item>
                <RoundedButton 
                  label='Siguiente'
                  onClick={this.onNextStep}
                  color='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        
      </div>
    )
  }

  onChangeBalance = (id, status) => {
    const {formData} = this.state
    let temp = {...formData}
    if(status){ // Clear balance_value
      temp.balance_value.value = null
      temp.balance_value.isValid = false
    }else{ // Clear amount_id
      temp.policy_amount_id.value = null
      temp.policy_amount_id.isValid = false
    }
    
    this.props.onChangeBalance(id, status)
    this.props.onChangePolicyValue(null)
  }

  onChangePaymentType = (id,value) => {
    const {formData} = this.state
    let temp = {...formData}
    temp.insurance_carrier_id.value = null
    if(value !== 2){
      temp.policy_amount_id.value = null
    }else{
      temp.policy_amount_id.value = 7
    }
    
    temp.balance_value.value = ''
    this.setState({formData:{...temp}})
    this.props.onChangePaymentType(id, value)
  }

  componentDidMount(){
    const {formData} = this.state
    const {language, patient_form} = this.props
    const content = contentData[language]
    let temp = {...formData}
    ////////************ INIT FORM /////////////*/
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
      let nodata = temp[item].config.type === 'select' ? null : ''
      temp[item].value = patient_form[item] ? patient_form[item] : nodata
    });
    this.setState({formData:{...temp}})
    //this.initModule()
  }

  getDoctorsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      let fullname = item.first_name
      fullname += item.second_name ? ' ' + item.second_name : ''  
      fullname += item.first_surname ? ' ' + item.first_surname : ''
      fullname += item.second_surname ? ' ' + item.second_surname : ''
      return {
        id: item.id_doctor,
        value: item.id_doctor,
        label: fullname
      }
    })
    return temp
  }

  getProductsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_diagnosis,
        value: item.id_diagnosis,
        label: item.name + ', ' + item.presentation
      }
    })
    return temp
  }

  onNextStep = () => {
    const {payment_type, balance} = this.props
    const {formData} = this.state

    let isValid = true
    switch (payment_type) {
      case 1:
      case 3:
        if(formData.insurance_carrier_id.value === null || formData.policy_amount_id.value === null){
          isValid = false
        }
        if(balance){
          if(formData.balance_value.value === null || !(formData.balance_value.value >= 0) || formData.balance_value.value === ''){
            isValid = false
          }
        }
        break;
      default:
        break;
    }
    if(isValid){
      this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),3)
    }
    
  }

  onPreviousStep = () => {
    this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),1)
  }

  onChangeCheckbox = (id, status) => {
    this.setState({[id]:status})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const {payment_type, balance, policy_amounts, onChangePolicyValue} = this.props
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};

    if(id === 'policy_amount_id' || id === 'balance_value'){
      if(payment_type === 1 || payment_type === 3){
        if(balance){
          if(temp.balance_value.value !== null){
            const selectedCatalog = policy_amounts.find(el => temp.balance_value.value >= el.min_value && temp.balance_value.value <= el.max_value);
            onChangePolicyValue({
              policy_value_id: selectedCatalog.policy_value_id,
              value_name: selectedCatalog.value_name
            })
          }
        }else{
          if(temp.policy_amount_id.value !== null && temp.policy_amount_id.value !== ''){
            const selectedCatalog = policy_amounts.find(el => el.id_policy_amount === temp.policy_amount_id.value)
            onChangePolicyValue({
              policy_value_id: selectedCatalog.policy_value_id,
              value_name: selectedCatalog.value_name
            })
          }
        }
      }else{
        temp.policy_amount_id.value = 7
        onChangePolicyValue({
          policy_value_id: 6,
          value_name: "Bajo"
        })
      }
    }

    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

export default withStyles(styles)(FormStepC)