export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    title:'',
    sectionA:{
      first_name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Caracteres entre 1-255'
      },
      second_name:{
        label:'Segundo Nombre',
        placeholder:'Segundo Nombre',
      },
      first_surname:{
        label:'Apellido Paterno',
        placeholder:'Apellido Paterno',
      },
      second_surname:{
        label:'Apellido Materno',
        placeholder:'Apellido Materno',
      },
      crm_id:{
        label:'CRM ID',
        placeholder:'CRM ID',
      },
      uci:{
        label:'UCI',
        placeholder:'UCI',
      },
    },
  },
}