import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid,  } from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import {DataItemWrapper} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'

import InfoButton from '../../../../../components/Buttons/InfoButton';
import moment from 'moment'
import ContentText from '../../../../../components/Texts/ContentText';

const styles = (theme) => ({
  root:{padding:'32px'}
})



class GeneralView extends Component {

  updateForm = (data, doctor, content) => {
    const {translations} = this.props
    let form = {}
    Object.keys(data).forEach((item,key)=> {
      let value = null
      if(doctor){
        value = doctor[item]
        if(data[item].translate){
          value = translations[value]
        }
        if(item === 'mobile'){
          let aux =  doctor.phone_code ? doctor.phone_code : ''
          value = aux + ' ' + value 
        }
      }
      const aux = {...data[item], ...content[item], data:value}
      form = {...form, [item]:{...aux}}
    })
    return form
  }

  render(){

    const {classes, language, doctor, onEdit, user_level} = this.props
    const content = contentData[language]

    let formA = {...formData.sectionA}

    let contentA = null
 
    formA = this.updateForm(formA, doctor, content.sectionA)

    contentA = Object.keys(formA).map((item,key)=> {
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          <DataItemWrapper 
            label={formA[item].label}
            data={formA[item].data}
            error={content.error}
            icon={formA[item].icon}
          />
        </Grid>
      )
    })    

    let infoButtonData = []
    const nodata = 'Sin Registro'
    if(doctor){
      infoButtonData = [
        {
          title:'Detalles de creación',
          content:[
            {label:'Nombre: ', text: doctor.creator ? doctor.creator : nodata},
            {label:'Fecha: ', text: doctor.created_at ?  moment(doctor.created_at).format("DD/MM/YYYY") : nodata},
          ]
        },
        {
          title:'Detalles de actualización',
          content:[
            {label:'Nombre: ', text: doctor.updater ? doctor.updater : nodata},
            {label:'Fecha: ', text: doctor.updated_at ?  moment(doctor.updated_at).format("DD/MM/YYYY") : nodata},
          ]
        },
      ]
    }
    

    return(
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CardWrapper>
            <div className={classes.root}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                          <ContentText text='Informacion del doctor' variant='bold' medium/>
                        </Grid>
                        <Grid item>
                          <InfoButton data={infoButtonData}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        {contentA}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {user_level < 4 ? 
                  <Grid item xs={12}>
                    <Grid container justifyContent='flex-end'>
                      <Grid item>
                        <RoundedButton label='Editar' size='small' color='secondary' onClick={onEdit}/>
                      </Grid>
                    </Grid>
                  </Grid>
                : null}
                
              </Grid>
            </div>
          </CardWrapper>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(GeneralView)