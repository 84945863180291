import {mainServer} from "../../../../../../../../variables/config"

export const request_patientproducts = async (id) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
    return response.data.data.patient_products
}

export const request_edit_patient_product = async (product_id, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.patch('patientproduct/'+product_id, data)
    //let response = await mainServer.instance.get(`/patientproduct/patient/${id}`)
    //return response.data.data.patient_products
}