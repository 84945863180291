export const formData = {
  sectionA:{
    first_name:{
      label:'',
      data:'',
    },
    second_name:{
      label:'',
      data:'',
    },
    first_surname:{
      label:'',
      data:'',
    },
    second_surname:{
      label:'',
      data:'',
    },
    crm_id:{
      label:'',
      data:'',
    },
    uci:{
      label:'',
      data:'',
    },
  },
}