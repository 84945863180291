
const styles = theme => ({
  root:{
    height: "100vh",
    zIndex: 1,
    overflow: 'none',
    position: 'relative',
    display: 'flex',
    width: '100%',
    background:'#edf2f8',
    
  },
  content:{
    width:'100%',
    boxSizing:'border-box',
    marginTop:'67px',
    overflowY:'auto',
    overflowX:'hidden',
  },
});

export default styles;
