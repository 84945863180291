export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    nospecialities:'No existen especialidades registradas',
    title:{
      sectionB:'Doctor',
      sectionC:'Detalles',
    },
    sectionA:{
      name:{
        label:'Nombre'
      },
      contact_name:{
        label:'Nombre de contacto'
      },
      contact_email:{
        label:'E-mail de contacto'
      },
      contact_phone:{
        label:'Teléfono de contacto'
      },
      contact_ext:{
        label:'Extensión de contacto'
      },
      address:{
        label:'Dirección'
      },
      crm_id:{
        label:'CRM ID'
      },
      uci:{
        label:'UCI'
      },
    },
  },
  
}