import { mainServer } from "../../variables/config"

const usePatientProducts = () => {

    const onGetAll = async(params) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/patientproduct/products", {params:{...params}})
        return response.data.data.products
    }


    return {
        onGetAll
    };
}
 
export default usePatientProducts;