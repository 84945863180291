import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, Icon, IconButton, makeStyles, Grid} from '@material-ui/core'
import { onGetDateFormat } from '../../../../../shared/utility';




const PatientLetterList = ({letters, onDownloadLetter, onEdit, noedit, onDelete, sending}) => {

    const classes = useStyles()

    let tableContent = null


    tableContent = (
        <Table>
            <TableHead>
                <TableRow>
                    {header.map(item => {
                        return <TableCell key={`th-${item.id}`} className={classes.cell_header} padding={item?.padding}>{item.label}</TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {letters.map(item=>{

                    const _date = onGetDateFormat(item?.date)


                    return(
                        <TableRow key={`tr-${item.id}`}>
                            <TableCell>{item?.id_patient_letter}</TableCell>
                            <TableCell style={{width:150}}>{item?.letter_type}</TableCell>
                            <TableCell style={{width:150}}>{_date}</TableCell>
                            <TableCell style={{width:150}}>{item?.patient_product_id??'-'}</TableCell>
                            <TableCell style={{width:150}}>{item?.use_id??'-'}</TableCell>
                            <TableCell>{item?.details??'-'}</TableCell>
                            <TableCell>
                                <Grid container wrap='nowrap' spacing={1}>
                                 {noedit ? null :
                                    <Grid item>
                                        <IconButton size='small' onClick={() => onDelete(item)}><Icon>delete</Icon></IconButton> 
                                    </Grid>}
                                    {noedit ? null :
                                    <Grid item>
                                        <IconButton size='small' onClick={() => onEdit(item)}><Icon>edit</Icon></IconButton> 
                                    </Grid>}
                                    <Grid item>
                                        <IconButton disabled={sending} size='small' color='primary' onClick={() => onDownloadLetter(item)}><Icon>download</Icon></IconButton> 
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )

    return ( 
        <div>
            {tableContent}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    cell_header:{
        paddingTop:8,
        paddingBottom:8,
        color:theme.palette.primary.main
    }
}))
 
export default PatientLetterList;

const header = [
    {id:1, padding:'checkbox', label:'ID'},
    {id:2, padding:'inherit', label:'Tipo'},
    {id:2, padding:'inherit', label:'Fecha'},
    {id:3, padding:'inherit', label:'#Tratamiento'},
    {id:4, padding:'inherit', label:'#Uso'},
    {id:5, padding:'inherit', label:'Descripción'},
    {id:6, padding:'checkbox', label:'Acciones'},
]