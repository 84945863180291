import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Card,
  Grid,
  CircularProgress,
  withWidth,
  Typography
} from '@material-ui/core';
import { withRouter, Redirect } from 'react-router-dom';
import {SignIn as settings} from '../../variables/config.jsx';

import {RoundedButton} from '../../components/Buttons/GeneralButtons'
import InputText from '../../components/Inputs/InputText/InputText';
import {contentData} from './content'
import {formData} from './data'
import {request_login} from './requests'

class SignIn extends Component{

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    isValid: false,
    isloading:false,
    error:null,
  }

  componentDidMount(){
    const {formData} = this.state;
    const content = contentData['spanish'];
    let temp = {...formData};
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    });
    this.setState({formData:{...temp}})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {formData, isValid} = this.state;
    let data2Send = {};
    
    if(isValid){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
      this.onSignInHandler(data2Send)
    }
    
  }

  onSignInHandler = async(data) => {
    try {
      await this.setState({isloading:true})
      await request_login(data)
      await this.setState({isloading:false, error:null})
    } catch (error) {
      if(error.response){
        let message = 'Hubo un error, reintente'
        switch (error.response.status) {
          case 401:
            message = 'Contraseña incorrecta'
            break;
          case 404:
            message = 'Usuario no encontrado'
            break;
        
          default:
            break;
        }
        this.setState({error:message})
      }else{
        this.setState({error:'No Internet'})
      }
      await this.setState({isloading:false})
    }
  }

  onKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.onSubmit()
    }
  }

  render(){
    const {classes} = this.props;
    const {formData, isloading, error} = this.state;
    const content = contentData['spanish']

    let contentRedirect = null;

    // Check if it already exist a valid session


    let temp = localStorage.getItem('data');
    if(temp){
      temp = JSON.parse(temp);
      let currentTime = new Date();
      let tokenTime = new Date(temp.exp*1000);
      if(currentTime.getTime() < tokenTime.getTime()){
        let path2redirect = '/';
        let preurl = localStorage.getItem('preurl')
        if(preurl){
          path2redirect = preurl
        }
        
        contentRedirect = <Redirect to={path2redirect}/>
      }
    }

    let errorMesage = null;
    if(isloading){
      errorMesage = (
        <CircularProgress
          size={30}
          className={classes.progress}
          style={{ color: settings.spinner.color }}
          thickness={7}
        />
      );
    } else if(error){
      errorMesage = (
        <Grid item>
          <div className={classes.errorMessage}>
            {error}
          </div>
        </Grid>
      );
    };


    let mainContent = (
      <div>
        <div className={classes.background}/>
        <div className={classes.container}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item>
              <Card className={classes.card}>
                <div className={classes.cardContent}>
                  <Grid container justifyContent='center' alignItems='center' spacing={5}>
                    <Grid item xs={12}> 
                      <Grid container spacing={2} justifyContent='center' direction='column' alignItems='center'>
                        <Grid item>
                          <img src={settings.logo} alt="CD" className={classes.ima}/>
                        </Grid>
                        <Grid item>
                          <Typography variant='h5' className={classes.subtitle}>Acceso</Typography>
                        </Grid>
                      </Grid>   
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.formContent}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <InputText
                              data={formData.username}
                              onChange={this.onInputChange}
                              onKeyPress={this.onKeyPress}
                            />
                          </Grid>
                          
                          <Grid item xs={12}>
                            <InputText
                              data={formData.password}
                              onChange={this.onInputChange}
                              onKeyPress={this.onKeyPress}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction='column' alignItems='center' spacing={1}>
                        <Grid item>
                          <RoundedButton 
                            label={content.button.label}
                            onClick={this.onSubmit}
                            color='secondary'
                          />
                        </Grid>
                        <Grid item xs={12} style={{height:'5px'}}>
                          {errorMesage}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );




    return(
      <div className={classes.root}>
        {contentRedirect}
        {mainContent}
      </div>
    );
  }

  
}



export default withRouter(withStyles(styles)(withWidth()(SignIn)));
