export const contentData = {
  spanish:{
    layout:{
      title:'Información de la aseguradora privada',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Aseguradoras Privadas',path:'/catalogs/insurance_carriers'},
        {name:'Detalles',path:'/catalogs/insurance_carriers/:id'}
      ]
    },
  }
}
