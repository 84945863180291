import React, {Component} from 'react'
import { 
  withStyles,
} from '@material-ui/core/styles'
import {   
  Dialog, 
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle} from '@material-ui/core'
import {grey, red} from '@material-ui/core/colors'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import { ErrorMessage, DataItemWrapper } from '../../../../../components/DisplayData/DisplayData';

const styles = (theme) => ({
  container:{
    padding:'0px 0px',
    boxSizing:'border-box',
    minWidth:'300px'
  },
  icon:{
    color:red[700],
    fontSize:54
  },
  title:{
    color:grey[900],
    fontWeight:'500'
  },
  message:{
    color:grey[700],
    fontWeight:'400'
  }
})

class LogDetailModal extends Component {

  state = {
    log_data: null
  }

  render(){

    const {classes, content, onClose, maxWidth, info} = this.props
    const { isloading} = this.state
    const {open, issending, error} = this.props.data

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    return(
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : 'md'}
      >
        <DialogTitle style={{color:grey[700], fontSize:14}}>{content.title}</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            {!isloading ? (
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {
                      Object.keys(info).map( (item, key) => {
                        return (
                          <Grid item xs={12} key={key}>
                            <DataItemWrapper 
                              label={info[item].label}
                              data={info[item].value}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {messageContent}
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent='center'>
                <Grid item>
                  <SimpleLoader />
                </Grid>
              </Grid>
            )}
            
          </div>
          
        </DialogContent>
        <DialogActions>
          <RoundedButton
            size='small'
            label={content.returnButton}
            color='transparent'
            onClick={onClose}
          />
        </DialogActions>
        


      </Dialog>
    )
  }

}

export default withStyles(styles)(LogDetailModal)

