import { USER_TYPE, PATIENT_STATUS, APPROVAL_STATUS } from "../../../../../../variables/config"


export const constrainst_treatment = {
    edit_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ]
    }
}

export const constrainst_use_modal= {
    delete_date:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ]
    },
    delete_application_date:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ]
    },
    ticket_action:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.PRA,
        ]
    }
}

export const constrainst_hospitalInfo = {  
}

export const constrainst_insurance = {
}

export const constrainst_statuses = {
    inactive_reason:{
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ]
    }
}

export const constrainst_actionbuttons = {
    doctor_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    },
    edit_button:{ // 4
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }
}

export const constrainst_patientproduct= {
    add_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    },
    add_previous_cycle:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL,
        ],
        bystatus:[
            PATIENT_STATUS.ACTIVE
        ],
    },
    manual_pending_uses:{
        
    },
    edit_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    },
    add_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.ROCHE
        ],
    }
}