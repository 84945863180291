import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';


const BenefitData = ({data}) => {

    const classes = useStyles()

    //console.log(data)

    const benefit_type = data?.benefit_type_id

    let content = null

    if(benefit_type === 1 || benefit_type === 3){ // Normal benefit
        content = (
            <div className={classes.root}>
                <div className={classes.paid}>
                    <Typography className={classes.paid_number}>
                        {`${data?.benefit_uses ?? 0}`}
                    </Typography>
                    <div className={classes.free}>
                        <div className={classes.free_container}>
                            <Typography variant='body2' className={classes.paid_number}>
                                {`+${data?.benefit_benefit ?? 0}`}
                            </Typography>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }else{

        const cycles_first = ['1','2','3','4','5','6']
        const cycles_second = ['7','8','9','10','11','12']
        const benefits = (data?.benefit_name??'').split(",")

        content = (
            <div>
                <Grid container direction='column'  justifyContent='flex-start'spacing={1}>
                    <Grid item>
                        <Grid container spacing={0} wrap='nowrap'>
                            {cycles_first.map(item=>{
                                let active = Boolean(benefits.find(el => el === item))
                                return(
                                    <Grid item key={`crc-${item}`}>
                                        <div style={{borderRadius:'50%', border:`1px solid #85368b`, width:10, height:10, position:'relative', margin:1, background: active ? '#85368b' : 'white' , color: active ? 'white' : 'black' }}>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid container spacing={0} wrap='nowrap'>
                            {cycles_second.map(item=>{
                                let active = Boolean(benefits.find(el => el === item))
                                return(
                                    <Grid item key={`crc-${item}`}>
                                        <div style={{borderRadius:'50%', border:`1px solid #85368b`, width:10, height:10, position:'relative', margin:1, background: active ? '#85368b' : 'white' , color: active ? 'white' : 'black' }}>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    
        {/*<div className={classes.root}>
            <div className={classes.paid}>
                <Typography className={classes.paid_number}>
                    {`${data?.benefit_uses ?? 0}`}
                </Typography>
                <div className={classes.free}>
                    <div className={classes.free_container}>
                        <Typography variant='body2' className={classes.paid_number}>
                            {`+${data?.benefit_benefit ?? 0}`}
                        </Typography>
                    </div>
                </div>
            </div>
            
    </div>*/}

    return ( 
        <div>
            {content}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
    },
    paid:{
        background:'#0847A7',
        color:'white',
        width:42,
        height:42,
        position:'relative',
        borderRadius:'50%',
    },
    paid_number:{
        color:'white',
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    free:{
        background:'#4466F2',
        color:'white',
        width:32,
        height:32,
        borderRadius:'50%',
        position:'absolute',
        right:-24,
        top:'50%',
        transform:'translateY(-50%)'
    },
    free_container:{
        width:'100%',
        height:'100%',
        position:'relative'
    }
}))
 
export default BenefitData;