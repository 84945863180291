import React from 'react';
import { Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

const DisplayError = ({text, onClose}) => {

    const classes = useStyles()

    if(!text) return null

    return ( 

        <div className={classes.error_container}>
            <Icon color='error'>error</Icon>
            <Typography variant='subtitle2' color='inherit'  className={classes.error_text}>{text}</Typography>
            <IconButton size='small' onClick={onClose}><Icon>close</Icon></IconButton>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    error_container:{
        background:red[100],
        border:`1px dashed ${red[300]}`,
        padding:8,
        borderRadius:8,
        marginBottom:16,
        color:grey[900],
        display:'flex',
        wrap:'no-wrap',
        alignItems:'center'
        
    },
    error_text:{
        fontWeight:400,
        marginLeft:4,
        flex:1
    }
}))
 
export default DisplayError;