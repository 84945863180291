
//import {catalogs} from '../../../../../../../../variables/catalogs'
//const catalog = catalogs.spanish

export const formData = {
  manual_pending:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'manual_pending',
      idcross:'manual_pending_uses',
      fullWidth: true,
      label:'Ciclos pendientes',
      placeholder:'Ej. 1,100,60 ...',
      helper:'Valor no válido'
    },
    rules:{
      type:'numeric',
      min:0,
      max:9999
    }
  },
}