import { red, lightBlue, green, grey, orange } from '@material-ui/core/colors';
import { USER_TYPE } from '../variables/config';

export const filterLanguageData = (data,language) => {
  let textData = '';
  switch (language.language) {
    case 'spanish':
      textData = data.spanish;
      break;
    case 'english':
      textData = data.english;
      break;
    default:
      textData = data;

  }
  return textData;
}

export const filterLanguageCatalog = (catalogs,language) => {
  let catalog = {};
  switch (language.language) {
    case 'spanish':
      catalog = catalogs.spanish;
      break;
    case 'english':
      catalog = catalogs.english;
      break;
    default:
  }
  return catalog;
}

export const getPatientStatusColor = (status) => {
  let color
  switch (status) {
    case 1:
      color = lightBlue[700]
      break;
    case 2:
      color = green[700]
      break;
    case 3:
      color = red[700]
      break;
    default:
        color = grey[500]
      break;
  }
  return color
}

export const getApprovalStatusColor = (status) => {
  let color
  switch (status) {
    case 1:
      color = grey[700]
      break;
    case 2:
      color = lightBlue[700]
      break;
    case 3:
      color = green[700]
      break;
    case 4:
        color = orange[700]
        break;
    case 5:
        color = red[700]
        break;
    default:
        color = grey[500]
      break;
  }
  return color
}

export const getLetterStatusColor = (status) => {
  let color
  switch (status) {
    case 1:
      color = red[700]
      break;
    case 2:
      color = lightBlue[700]
      break;
    case 3:
      color = green[700]
      break;
    default:
        color = grey[500]
      break;
  }
  return color
}

export const getHospitals2ProviderOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_hospital,
      value: item.id_hospital,
      label: item.name
    }
  })
  return [{id:0,value:0,label:'Sin hospital alternativo'},...temp]
}

export const getDoctorsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    let fullname = item.first_name
    fullname += item.second_name ? ' ' + item.second_name : ''  
    fullname += item.first_surname ? ' ' + item.first_surname : ''
    fullname += item.second_surname ? ' ' + item.second_surname : ''
    return {
      id: item.id_doctor,
      value: item.id_doctor,
      label: fullname
    }
  })
  return temp
}

export const getHospitalsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_hospital,
      value: item.id_hospital,
      label: item.name,
      ...item
    }
  })
  return temp
}

export const getProductsOptions = (data) => {
  let temp = []
  temp = data.map(item => {

    let product_label = `${item.name}, ${item.presentation} ${item.product_type ? `, ${item.product_type}` : '' }`

    return {
      id: item.id_product,
      value: item.id_product,
      label: product_label,
      scheme: item.scheme,
      ...item
    }
  })
  return temp
} 

export const getDiagnosticsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_diagnosis,
      value: item.id_diagnosis,
      label: item.name
    }
  })
  return temp
} 

export const getPublicInsurancesOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_public_insurance,
      value: item.id_public_insurance,
      label: item.name
    }
  })
  return temp
} 


export const getInsuranceCarriersOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_insurance_carrier,
      value: item.id_insurance_carrier,
      label: item.name,
      ...item
    }
  })
  return temp
}

export const getSchemesOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_scheme,
      value: item.id_scheme,
      label: item.name,
      vials: item.vials ?? 0,
      cycles: item.cycles ?? 0


    }
  })
  return temp
}

export const getPolicyAmountsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_policy_amount,
      value: item.id_policy_amount,
      label: item.name,
      ...item
    }
  })
  return temp
}

export const onGetProductSearchParams = (admin_user) => {
  let products_params = {}
  let _product_type = 1

  switch(admin_user.user_type_id){
      case USER_TYPE.ADMIN:
      case USER_TYPE.ROCHE:
          products_params.product_type_id = _product_type
          break;
      case USER_TYPE.MERCURIO:
          products_params.product_type_id = admin_user.agency_id === 1 ? 1 : 2
          _product_type = admin_user.agency_id === 1 ? 1 : 2
          break;
      case USER_TYPE.HOSPITAL:
          products_params.product_type_id = 1
          _product_type = 1
          break;
      default:
  }

  return products_params
}

export const getDoctorsHospitalsOptions = (data) => {
  let temp = []
  if(!data) return temp
  temp = data.map(item => {
    return {
      id: item.doctor_id,
      value: item.doctor_id,
      label: item.doctor
    }
  })
  return temp
}



export const getNotesOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_patient_note_template,
      value: item.id_patient_note_template,
      label: item.content
    }
  })
  return temp
} 

export const getProvidersOptions = (data, providers) => {
  let temp = []
  data.forEach(item => {

    let findItem = providers.find(el => el.provider_id === item.id_provider)

    if(!findItem){
      temp.push({
        id: item.id_provider,
        value: item.id_provider,
        label: item.name
      })
    }
  })
  return temp
}

export const getGenericProvidersOptions = (data) => {
  let temp = []
  data.forEach(item => {

    temp.push({
      id: item.id_provider,
      value: item.id_provider,
      label: item.name
    })
  })
  return temp
}

export const getLetterProvidersOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_patient_provider,
      value: item.id_patient_provider,
      label: item.provider
    }
  })
  
  return [...temp]
}

export const getOnlyHospitalsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_hospital,
      value: item.id_hospital,
      label: item.name
    }
  })
  return temp
}

export const onFilterPatientStatuses = (patient, catalogs) => {
  let patient_statuses = []
  if(patient){
    if(catalogs){
      catalogs.patient_statuses.forEach(item => {
        if(item.id === 2){
          if(patient.approval_status_id === 3){
            patient_statuses.push(item)
          }
        }else{
          patient_statuses.push(item)
        }
      })
    }
  }
  return patient_statuses
}
