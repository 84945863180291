import {mainServer} from '../../../../variables/config'

export const request_brand_stats = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/brandstats", {params:{...params}})
  return response.data.data.brand_stats
}

export const request_patient_count = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount", {params:{...params}})
  return response.data.data.patient_count
}

export const request_patient_count_status = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/status", {params:{...params}})
  return response.data.data.patient_count_by_status
}

export const request_patient_count_inactive_reason = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/inactivereason", {params:{...params}})
  return response.data.data.patient_count_by_inactive_reason
}

export const request_patient_count_main_hospital = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/mainhospital", {params:{...params}})
  return response.data.data.patient_count_by_main_hospital
}

export const request_patient_count_payment_type = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/paymenttype", {params:{...params}})
  return response.data.data.patient_count_by_payment_type
}

export const request_patient_count_doctor = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/doctor", {params:{...params}})
  return response.data.data.patient_count_by_doctor
}

export const request_patient_insurance_carrier = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/insurancecarrier", {params:{...params}})
  return response.data.data.patient_count_by_insurance_carrier
}

export const request_patient_public_insurance = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/publicinsurance", {params:{...params}})
  return response.data.data.patient_count_by_public_insurance
}

export const request_patient_provider = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/provider", {params:{...params}})
  return response.data.data.patient_count_by_provider
}

export const request_patient_diagnosis = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/diagnosis", {params:{...params}})
  return response.data.data.patient_count_by_diagnosis
}

export const request_patient_state = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/state", {params:{...params}})
  return response.data.data.patient_count_by_state
}

export const request_patient_nse = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientcount/nse", {params:{...params}})
  return response.data.data.patient_count_by_nse
}

export const request_product_count = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/productcount", {params:{...params}})
  return response.data.data.product_count
}



// OLD ANALYTICS REQUESTS

export const request_patient_stats = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/patientstats", {params:{...params}})
  return response.data.data.patient_stats
}

export const request_patient_stats_by_product = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/patientstatsbyproduct", {params:{...params}})
  return response.data.data.patient_stats_by_product
}

export const request_patient_stats_by_product_v2 = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/patientstats/product", {params:{...params}})
  return response.data.data.patient_stats_by_product
}

export const request_inactive_patient_stats = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/inactivepatientstats", {params:{...params}})
  return response.data.data.inactive_patient_stats
}

export const request_product_stats = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analyticv2/productstats", {params:{...params}})
  return response.data.data.product_stats
}

export const request_delivered_benefits = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/deliveredbenefits", {params:{...params}})
  return response.data.data.delivered_benefits_stats
}

export const request_extended_patient_stats = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/analytic/patientstats/extended", {params:{...params}})
  return response.data.data.extended_patient_stats
}




