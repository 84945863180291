import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import classNames from 'classnames';
import {
  Drawer,
  Grid,
  Typography,
  Hidden,
} from '@material-ui/core';

import MenuBar from './MenuBar/MenuBar.jsx';
import mainLogo from '../../assets/logo.png'
import oncoLogo from '../../assets/oncopassport-logo.png'

import SidebarAvatar from '../Avatars/SidebarAvatar.jsx';

class SideBar extends Component{

  render(){
    const {classes, history, routes, user, open, onChange, onClose} = this.props;

    let level_type = 'Visualizador'
    let main_logo = mainLogo
    if(user){
      //console.log('User type', user.user_type_id)
      switch (user.user_type_id) {
        //case 1:
        //  if(user?.user_type === "Centro de Contacto"){
//
        //  }else{
        //    level_type = 'Administrador'
        //  }
        //  
        //  break;
        //case 2:
        //  level_type = 'Aprobador'
        //  break;
        case 3:
          level_type = `Centro de contacto ${user.agency}`
          if(user?.agency_id === 1) main_logo = oncoLogo
          break;
        //case 5:
        //  level_type = 'Autoservicio'
        //  break;
        default:
          level_type = user?.user_type
          break;
      }
    }

    let mainContent = null

    mainContent = (
      <div className={classes.root}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container justifyContent='center'spacing={0}>
              <Grid item>
                <div className={classes.mainLogoContainer}>
                  <img src={main_logo} alt='' className={classes.mainlogo}/>
                </div>
              </Grid>
              <Grid item>
                <Typography variant='body2' className={classes.logoTitle}>Programa comercial de Bonificaciones</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <SidebarAvatar 
                  image={user ? user.image : null}
                  name={user ? user.first_name : ''}
                  user_type={level_type}

                />
              </Grid>
            </Grid>
            
          </Grid>
          <Grid item xs={12} style={{paddingBottom:0}}>
            <MenuBar 
              history={history} 
              routes={routes} 
              onClick={onChange}
            />
            
          </Grid>
          
        </Grid>
      </div>
    )

    let drawerDesktop = (
      <Hidden smDown>
        <Drawer
          variant="persistent"
          open={true}
          classes={{
            paper: classNames(classes.drawerPaper,{
              [classes.drawerPaperClose]:!open
            })
          }}
        >
          {mainContent}
          
        </Drawer>
      </Hidden>
    )

    let drawerMobile = (
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={onClose}
          classes={{
            paper: classNames(classes.drawerPaper,{
              [classes.drawerPaperClose]:!open
            })
          }}
        >
          {mainContent}
          
        </Drawer>
      </Hidden>
    )
    return(
      <div>
        {drawerDesktop}
        {drawerMobile}
      </div>
    );
  }
}

export default withStyles(styles)(SideBar);
