export const content = {
  layout:{
    title:'Correos de solicitantes',
    navigation:[
      {name:'Emails',path:'/mails'},
      {name:'Solicitantes',path:'/mails/applicants'},
    ],
  },
  table:{
      header:[
          {id:1, label:'ID'},
          {id:2, label:'Nombre'},
          {id:3, label:'Correo'},
          {id:3, label:'Acciones'}
      ]
  }
}
