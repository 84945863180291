export const contentData = {
  spanish:{
    layout:{
      title:'Información del hospital',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Hospitales',path:'/catalogs/hospitals'},
        {name:'Detalles',path:'/catalogs/hospitals/:id'}
      ]
    },
  }
}
