import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { RoundedButton } from '../../../../../../../../../components/Buttons/GeneralButtons';
import LineInputSelect from '../../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import SimpleModalV2 from '../../../../../../../../../components/Modals/SimpleModalV2';


const VialModal = (props) => {

    const {open, onClose, mode, origin, products, onUpdateVial} = props
    const [_form, setForm] = useState(JSON.parse(JSON.stringify(vial_formdata)))
    const classes = useStyles()
    const content = {
        cancelButton:'Cancel',
        title: mode === 'add' ? 'Agregar product' : 'Editar producto',
        button: mode === 'add' ? 'Agregar' : 'Actualizar',
    }

    useEffect(() => {

        if(open){
            let _form 
            if(origin && mode === 'edit'){
                _form = {...origin}
            }else{
                _form = JSON.parse(JSON.stringify(vial_formdata))
                const _products = getProductsOptions(products)
                 _form.product_id.options = products            
            }
            setForm(_form)
        }else{
            // Clean when close
            setForm(JSON.parse(JSON.stringify(vial_formdata)))
        }
    }, [open, products, origin])

    const onInputChange = (data) => {
        const id = data.config.id;
        let temp = {..._form}
        temp[id] = {...data};
        setForm(temp)
    }

    const onSubmit = () => {
        onUpdateVial(_form, mode)
       // onClose()
    }

    return ( 
        <SimpleModalV2 open={open} onClose={onClose} >
            <div>
                <div>
                    <Typography className={classes.title}  variant='h6'>{content.title}</Typography>
                </div>
                <div style={{marginTop:24}}>
                    <div>
                        <Typography variant='body2'><i>Seleccione un producto</i></Typography>
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <LineInputSelect 
                                data={_form.product_id}
                                onChange={onInputChange}
                            />
                        </Grid>
                    </Grid>
                    <div style={{marginTop:24}}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                <RoundedButton label={content.button} onClick={onSubmit}/>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </SimpleModalV2>
     );
}

const useStyles = makeStyles(theme => ({
    title:{
        fontWeight:600
    }
}))
 
export default VialModal;

const getProductsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
  
      let product_label = `${item.name}, ${item.presentation} ${item.product_type ? `, ${item.product_type}` : '' }`
  
      return {
        id: item.id_product,
        value: item.id_product,
        label: product_label,
        product_type_id:item.product_type_id
      }
    })
    return temp
  } 


const vial_formdata = {
    id_use_vial:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'id_use_vial',
          type:'tel',
          fullWidth: true,
        },
        rules:{
          type:'none'
        }
    },
    number:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'number',
        type:'tel',
        fullWidth: true,
      },
      rules:{
        type:'numeric',
        min:1,
        max:100
      }
    },
    product_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'product_id',
        type:'select',
        fullWidth: true,
      },
      rules:{
        type:'select',
      }
    },
  }