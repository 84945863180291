import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';

const styles = (theme) => ({
  root:{padding:'32px'},
  subtitle:{
    color:theme.palette.primary.main,
    fontWeight:'500'
  }
})



class AddDoctorCard extends Component {

  state = {
    form: JSON.parse(JSON.stringify(formData)),
    isValid:false
  }

  componentDidMount(){
    const {language} = this.props
    const {form} = this.state
    const content = contentData[language]
    let tempA = {...form}
    Object.keys(form).forEach(item => {
      tempA[item].value = ''
      tempA[item].config = {...tempA[item].config,...content.form[item]}
    })
    this.setState({form:{...tempA}})
  }

  onInputChange = (data) => {
    const {form} = this.state
    const id = data.config.id;
    let temp = {...form};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp)
    this.setState({form:{...temp}, isValid:isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props
    const {form} = this.state
    let data2Send = {}
    
    Object.keys(form).forEach((item)=>{
      if(form[item].isValid){
        data2Send = {...data2Send, [item]:form[item].value}
      }
    })
    onSubmit(data2Send)
  }

  render(){

    const {classes, language, issending, error} = this.props
    const {form, isValid} = this.state
    const content = contentData[language]

    let tempForm = {...form}

    let formContent = null
    //// Basic info form
    formContent = Object.keys(tempForm).map((item,key)=> {
      let inputContent = null
      switch (tempForm[item].config.type) {
        case 'select':
          inputContent = (
            <LineInputSelect
              data={tempForm}
              onChange={this.onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <LineInputText
              data={tempForm[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })

    let loaderContent = null
    if(issending){
      loaderContent = <SimpleLoader />
    }else{
      if(error){
        loaderContent = <ErrorMessage message={error}/>
      }
    }    

    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {formContent}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  {loaderContent}
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={content.add_button} 
                    size='small' 
                    disabled={!isValid}
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }
}

export default withStyles(styles)(AddDoctorCard)