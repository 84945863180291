import React, {useState, useEffect} from 'react'
import SimpleDialog from '../../../../../../components/Modals/SimpleDialog'
import LineInputText from '../../../../../../components/Inputs/LineInputText/LineInputText'
import { formData } from './data'
import { Grid } from '@material-ui/core'
import { request_add_contact, request_update_contact } from '../../requests'

const AddEmail = props => {

    const {onClose, onUpdateContacts, origin, type} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [issending, setIsSending] = useState(false)
    const [error, setError] = useState(null)


    const data = {issending:issending, error:error}

    useEffect(() => {
        let temp = {...JSON.parse(JSON.stringify(formData))}
        if(origin){
            Object.keys(temp).forEach(item =>{
                const value = origin[item]
                if(value!== undefined && value !== null){
                    temp[item].value = value
                    temp[item].isVisited = true
                    temp[item].isValid = true
                }
            })
        }
        setForm(temp)
    }, [origin]);


    const  onInputChange = (data) => {
        let temp = {...form}
        temp[data.config.id] = {...data}
        setForm(temp)
    }

    const onSubmit = async() => {
        let data2send = {}
        let isValid = true
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].isVisited) data2send = {...data2send, [item]:form[item].value}
            if(form[item].isRequired && !form[item].isValid) isValid = false
        })
        if(isValid){
            setError(null)
            setIsSending(true)
            try {
                const contacts = await request_add_contact(data2send)
                onUpdateContacts(contacts)
            } catch (error) {
                setError('Hubo un error, intente de nuevo')
            }
            setIsSending(false)
        }else{
            setError('Llene los datos faltantes')
        }
    }

    const onUpdate = async() => {
        let data2send = {}
        let isValid = true
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].isVisited && form[item].value !== origin[item]) data2send = {...data2send, [item]:form[item].value}
            if(form[item].isRequired && !form[item].isValid) isValid = false
        })
        if(isValid){
            setError(null)
            setIsSending(true)
            try {
                const {id_approval_contact} = origin
                const contacts = await request_update_contact(id_approval_contact, data2send)
                onUpdateContacts(contacts)
            } catch (error) {
                setError('Hubo un error, intente de nuevo')
            }
            setIsSending(false)
        }else{
            setError('Llene los datos faltantes')
        }
    }

    return(
        <SimpleDialog
            title='Agregar e-mail'
            data={data}
            onClose={onClose}
            onSubmit={type === 'edit' ? onUpdate : onSubmit}
            submit={type === 'edit' ? 'Guardar' : 'Agregar'}
        >
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LineInputText data={form.name} onChange={onInputChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LineInputText data={form.email} onChange={onInputChange}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleDialog>
    )
}

export default AddEmail