import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralInfoCard from './views/GeneralInfoCard/GeneralInfoCard';
import TreatmentView from './views/TreatmentView/TreatmentView';
import ProvidersView from './views/ProvidersView/ProvidersView';
import NotesView from './views/NotesView/NotesView';
import { getUserType } from '../../../../shared/utility'
import { USER_TYPE } from '../../../../variables/config'
import DocumentsView from './views/DocumentsView/DocumentsView'
import MedicalReportsView from './views/MedicalReportsView/MedicalReportsView'
import PatientLettersView from './views/PatientLettersView/PatientLettersView'
import PersonalDocumentView from './views/PersonalDocumentView/PersonalDocumentView'


class ApplicantView extends Component{

  state = {
    isloading:true,
    issending:false,
    patient:null,
    selected:2,
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data){this.initModule()}
  }

  initModule = async() => {

    try{
      //const {id} = this.props.match.params
      //this.setState({isloading:true})
      //const patient = await request_patient(id)
      //this.setState({patient:patient})
    }catch(e){
      console.log(e)
    }
    
    

    this.setState({isloading:false})
  }

  onEditpatient = async() => {
    const {match,history} = this.props
    const id = match.params.id
    history.push('/patients/'+id+'/edit')
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/patients')
  }


  render(){

    const {history, language, catalogs, match, parentuser, user_level} = this.props
    const {selected, patient, isloading} = this.state
    const content = contentData[language]

    let contentView = null

    switch (selected) {
      case 1:
        contentView = (
          <GeneralInfoCard 
            parentuser={parentuser} user_level={user_level}
            match={match}
            catalogs={catalogs}
          />
        )
        break;
      case 2:
        contentView = (
          <TreatmentView 
            parentuser={parentuser} user_level={user_level}
            match={match}
            catalogs={catalogs}/>
        )
        break
      case 3:
        contentView = (
          <DocumentsView 
            match={match}
            catalogs={catalogs}/>
        )
        break
      case 7:
        contentView = (
          <ProvidersView 
            match={match}
            catalogs={catalogs}/>
        )
        break;
      case 8:
        contentView = (
          <NotesView 
            match={match}
            catalogs={catalogs}/>
        )
        break;
      case 4:
        contentView = (
          <MedicalReportsView 
            match={match} 
            catalogs={catalogs}/>
        )
        break;
      case 5:
        contentView = (
          <PersonalDocumentView 
            match={match} 
            catalogs={catalogs}/>
        )
        break;
      case 6:
          contentView = (
            <PatientLettersView 
              match={match} 
              catalogs={catalogs}/>
          )
          break;
      default:
        break;
    }

    const layout_template = this.onFilterLayout(content.layout)
    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={selected}
        onChange={this.onChangeView}
        content={layout_template}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {contentView}
      </BasicView>
      
    )
  }

  onFilterLayout = (layout) => {
    let temp = {...layout}
    let menu = []
    const userType = getUserType()
    //let filterKeys = [1,2,3,4,5,6,7,8]
    let filterKeys = [1,2,3,4,5,6,7,8]

    switch (userType) {
      //case USER_TYPE.ADMIN:
      case USER_TYPE.ROCHE:
      case USER_TYPE.HOSPITAL:
        filterKeys = [1,2,3,4,5,6]
        break;
      default:
        break;
    }

    filterKeys.forEach(item => {
      let findElement = layout.menu.find(el => el.id === item)
      menu.push(findElement)
    })

    temp.menu = [...menu]

    if(userType !== USER_TYPE.HOSPITAL){
      temp.title = 'Información del beneficiario'
      temp.navigation = [
        {name:'Beneficiarios',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'}
      ]
    }

    return temp

  }

  onChangeView = (id) => {
    this.setState({selected:id})
  }
}

export default withStyles(styles)(ApplicantView)