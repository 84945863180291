import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Grid,
  Typography,
  withWidth,
  InputAdornment,
  TextField,
  Icon
} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, grey, red} from '@material-ui/core/colors';
//import ContentText from '../../Texts/ContentText.jsx';
import Caption from '../../Texts/Caption.jsx';
import { useRef } from 'react';

const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[700],
      fontSize:14,
      fontWeight:'500'
    },
    '& .MuiInput-underline': {
      borderBottomColor: grey[300],
      borderBottomWidth:2,
      '&:hover:not($disabled):after': {
        //its when its hover and input is focused 
      },
      '&:hover:not($disabled):before': {
        borderBottomWidth:2,
        borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
      },
      '&:before': {
        borderBottomWidth:2,
        borderBottomColor: grey[300],// when input is not touched
      },
    }
  },
}))(TextField);

const LineInputText = (props) => {

  

    const {
      onKeyPress,
      disabled,
      delayedSearch,
      onDelayedSearch
    } = props;

    const inputRef = useRef()
    const timerRef = useRef()

    const {value, isVisited, isValid, isRequired} = props.data;
    const {id, type,label,placeholder,fullWidth,icon, multiline, rows, helper} = props.data.config;

    const error = isVisited && !isValid;

    const onStartCountDown = () => {
      if(timerRef.current) clearTimeout(timerRef.current)
      timerRef.current = setTimeout(onRequestSearch, 300);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onRequestSearch = async() => {   
      if(timerRef.current) clearTimeout(timerRef.current) 
      onDelayedSearch(id, value)
        //
        //let temp = {...tableFilter}
        //temp.offset = 0
        //onUpdateTableFilter(temp)            
    }

    const onInnerChange = event => {
      const {onChange, data} = props;
      const response = event.target.value;
      let temp = {...data};
      temp.value = response;
      temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
      temp.isVisited = true;
      onChange(temp);
    }

    let inputaddornment = null;
    if(icon){
      inputaddornment = 
      <InputAdornment position='start'>
        
        <Icon style={{color:blueGrey[500]}}>
          {icon.name}
        </Icon>
      </InputAdornment>
    }

    let inputlabel = null;
    if(label){
      let signal = isRequired ? '' : ''
      inputlabel = (
        <Grid item>
          <Caption text={label+signal} medium />
        </Grid>
      )
    }

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='column' spacing={0}>
            {inputlabel}
            <Grid item>
                <CssTextField
                  ref={inputRef}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  error={error}
                  value={value !== undefined && value !== null ? value : ''}
                  onChange={onInnerChange}
                  multiline={multiline}
                  rows={rows}
                  type={type}
                  startadornment={inputaddornment}
                  onKeyPress={onKeyPress}
                  inputProps={{
                    spellCheck:"false",
                    autoComplete: id === 'password' ? 'new-password' : "off",
                    name:"hidden",
                    onKeyUp: delayedSearch ? onStartCountDown : undefined,
                    onKeyDown: delayedSearch ? onStopCountDown : undefined
                  }}
                />
            </Grid>
            <Grid item>
              <Typography variant='caption' style={{color:red[700]}}>
                {error ? helper : ''}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  
};

export default (withWidth()(LineInputText));
