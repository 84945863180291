import {mainServer} from '../../../../variables/config'
import axios from  'axios'

export const request_add_patient_insurance = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  //patient_insurance_id
  let response = await mainServer.instance.post("/patientinsurance",data)
  return response.data.data.patient_insurance_id
}

export const request_upload_insurance_document = async(idinsurance, path, file, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientinsurance/${idinsurance}/${path}`, data)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}

export const request_add_medical_report = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  //patient_insurance_id
  let response = await mainServer.instance.post("/patientmedicalreport",data)
  return response.data.data.patient_medical_report_id  
}

export const request_upload_document_medical_report = async(id, file) => {
  const type = file.name.split('.').slice(-1)[0] 
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientmedicalreport/${id}/medicalreport`, {ext:type});
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}




export const request_me = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/me")
  return response.data.data.user
}


export const request_upload_document_patient_insurance = async(id, file) => {
  const type = file.name.split('.').slice(-1)[0] 
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/patientinsurance/${id}/insurancepolicy`, {ext:type});
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}

export const request_external_id = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/patient/check/externalid",{external_id:data})
}

export const request_create_patient = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/patient",data)
  return response.data.data
} 

export const request_create_patient_applicant = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/applicant",data)
  return response.data.data
} 

export const request_add_product = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const response = await mainServer.instance.post("/patientproduct",data)
  return response.data.data.patient_product_id
}

export const request_add_vial = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const response = await mainServer.instance.post("/patientproductvial",data)
  return response.data.data.patient_product_vial_id
}

export const request_add_benefit = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let url = '/patient/'+id+'/benefit'
  await mainServer.instance.post(url)
}


export const request_add_benefit_applicant = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let url = '/applicant/'+id+'/benefit'
  await mainServer.instance.post(url)
}

export const request_send_approval = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let url = '/patient/'+id+'/sendforapproval'
  await mainServer.instance.post(url)
}


export const request_folio = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/patient/check/folio",{folio:data})
}

export const request_benefits = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.post("/patient/prebenefit",data)
  return response.data.data
}

export const request_patients = async (payload) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/patient"+payload)
  return response.data.data
}

export const request_delete_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/users/"+id)
  let response
  response = await mainServer.instance.get("/users/type/1")
  return response.data.data
}

export const request_policy_amounts = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/insurancecarrier/policyamounts")
  return response.data.data.policy_amounts
}


export const request_doctors = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/all")
  return response.data.data.doctors
}

export const request_doctors_by_hospital = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/hospitaldoctor/hospital/me")
  return response.data.data.hospital_doctors
}

export const request_products = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/patientproduct/products", {params:{...params}})
  return response.data.data.products
}



export const request_hospitals = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/hospital/all")
  return response.data.data.hospitals
}

export const request_providers = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/provider/all")
  return response.data.data.providers
}


export const request_hospital_details = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/hospital/"+id.toString())
  return response.data.data.hospital
}


export const request_insurance_carriers = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/insurancecarrier/all")
  return response.data.data.insurance_carriers
}

export const request_diagnostics = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(
    "/patientproduct/diagnostics", 
    {params:{
      productid:id
    }}
  )
  return response.data.data.diagnostics
}

export const request_nse = async(code) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(
    "/nse/zip", 
    {params:{
      zip:code
    }}
  )
  return response.data.data.nse
}

export const request_public_insurances = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/publicinsurance/all")
  return response.data.data.public_insurances
}


////////////////////////  DOCUMENTS  //////////////////////////
export const request_upload_document = async(id, file, path, userType) => {
  const type = file.name.split('.').slice(-1)[0] 
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/${userType}/${id}/${path}`, {ext:type});
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}