
//import {catalogs} from '../../../../../../../../variables/catalogs'
//const catalog = catalogs.spanish

export const formData = {
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
      label:'Nombre',
      placeholder:'Nombre',
      helper:'Longitud de 1-100 caracteres'
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'email',
      type:'email',
      fullWidth: true,
      label:'E-mail',
      placeholder:'usuario@test.com',
      helper:'Email no válido'
    },
    rules:{
      type:'email',
    }
  },
  phone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'phone',
      type:'number',
      fullWidth: true,
      label:'Teléfono',
      placeholder:'0000000000',
      helper:'Teléfono no válido'
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  por_ti_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'por_ti_id',
      type:'text',
      fullWidth: true,
      label:'ID Por Ti',
      placeholder:'ID Por Ti',
      helper:'ID no válido'
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  gender_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
        id:'gender_id',
        type:'select',
        fullWidth: true,
        addoption:true,
        label:'Género',
        placeholder:'Género',
        helper:'Seleccione una opción'
    },
    rules:{
      type:'none',
    }
  },
  external_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'external_id',
      type:'text',
      fullWidth: true,
      label:'ID programa de bonificaciones',
      placeholder:'ID programa de bonificaciones',
      helper:'ID no válido'
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  folio:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'folio',
      type:'text',
      fullWidth: true,
      label:'Folio',
      placeholder:'Folio',
      helper:'Folio no válido'
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  doctor_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'doctor_id',
      type:'select',
      fullWidth: true,
      label:'Doctor',
      placeholder:'Seleccione un doctor',
    },
    rules:{
      type:'none',
    }
  },
  zip:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'zip',
      type:'number',
      fullWidth: true,
      label:'Código Postal',
      placeholder:'Ej. 00000',
      helper:'Ingrese un código postal válido'
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  public_insurance_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'public_insurance_id',
      type:'select',
      fullWidth: true,
      label:'Aseguradora pública',
      placeholder:'Seccione una aseguradora',
    },
    rules:{
      type:'none',
    }
  },
  product_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'product_type_id',
      type:'select',
      fullWidth: true,
      label:'Tipo de tratamiento',
      placeholder:'Seleccione una opcion',
    },
    rules:{
      type:'none',
    }
  },
}