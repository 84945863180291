import React from 'react';
import { Grid, Icon, makeStyles, TextField, withStyles } from '@material-ui/core';
import { useState } from 'react';
import GenericPopper from '../../../../../../../components/Poppers/GenericPopper';
import ContentText from '../../../../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';

const ChartFilter = ({ id, filters, placeholder, onChangeFilter, value }) => {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const classes = useStyles();

	console.log(id);

	const onCloseMenu = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const onOpenMenu = (event) => {
		const { currentTarget } = event;
		setAnchorEl(currentTarget);
		setOpen(true);
	};

	const onSelectedItem = (item) => {
		onCloseMenu();
		onChangeFilter(item, id);
	};

	if (id !== 'start_date' && id !== 'end_date') {
		let filterContent = null;
		if (value) {
			const selected = filters.find((item) => item.id === value);
			filterContent = <ContentText text={selected.label} variant='light' />;
		} else filterContent = <ContentText text={placeholder} variant='light' />;

		return (
			<div>
				<GenericPopper anchorEl={anchorEl} open={open} onClickAway={onCloseMenu} onSelectedItem={onSelectedItem} menu={filters} />
				<div>
					<Grid container>
						<Grid item>
							<div className={classes.button} onClick={onOpenMenu}>
								<Grid container alignItems='center' spacing={2}>
									<Grid item>
										<div style={{ minWidth: '100px' }}>{filterContent}</div>
									</Grid>
									<Grid item>
										<Icon className={classes.icon}>keyboard_arrow_down</Icon>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	} else
		return (
			<div className={classes.date_container}>
				<CssTextField
					id='date'
					type='date'
					value={value}
					className={classes.date}
					onChange={(e) => onChangeFilter(e, id)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
		);
};

const useStyles = makeStyles((theme) => ({
	button: {
		padding: '8px 16px',
		borderRadius: '40px',
		background: 'white',
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	icon: {
		paddingTop: 3,
		color: grey[500],
		fontSize: 24,
	},
	date_container: {
		borderRadius: 25,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		background: '#FFF',
		minWidth: '150px',
		padding: '6px 12px',
	},
}));

const CssTextField = withStyles((theme) => ({
	root: {
		paddingTop: 6,
		'& input': {
			color: '#9e9e9e',
			fontSize: 14,
			fontWeight: 400,
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.6)', // (default alpha is 0.38)
		},
		'& .MuiInput-underline': {
			borderBottomColor: grey[300],
			borderBottomWidth: 2,
			'&:hover:not($disabled):after': {
				//its when its hover and input is focused
			},
			'&:hover:not($disabled):before': {
				borderBottomWidth: 0,
				borderBottomColor: theme.palette.secondary.light, //its when you hover and input is not foucused
			},
			'&:after': {
				borderBottomColor: theme.palette.secondary.main, //when input is focused, Its just for example. Its better to set this one using primary color
			},
			'&:before': {
				borderBottomWidth: 0,
				borderBottomColor: grey[300], // when input is not touched
			},
		},
	},
}))(TextField);

export default ChartFilter;
