import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import BasicInfoContainer from './components/BasicInfoContainer';
import HospitalContainer from './components/HospitalContainer';
import InsuranceContainer from './components/InsuranceContainer';
import PatientProduct from './components/PatientProduct/PatientProduct';
import { getUserType, isValidPatientProduct, null2undefined, onGetFormData, onGetLabelOption } from '../../../../../shared/utility';
import useOnboardingContext from '../../../../../providers/OnboardingProvider/useOnboardingContext';
import OnboardingActionBar from '../components/OnboardingActionBar';
import { USER_TYPE } from '../../../../../variables/config';
import { request_add_benefit, request_add_benefit_applicant, request_add_medical_report, request_add_patient_insurance, request_add_product, request_add_vial, request_create_patient, request_create_patient_applicant, request_upload_document, request_upload_document_medical_report, request_upload_document_patient_insurance } from '../requests';
import moment from 'moment';
//import StatusMessage from './components/StatusMessage';

const FormStepD = ({history}) => {

    const classes = useStyles()
    const isloading = false

    const {
      policy_value,
      doctors, 
      hospitals,
      public_insurances,
      insurance_carriers,
      policy_amounts,
      payment_types,
      genders,
      nse,
      formManager,
      product_type,
      is_public_insurance,
      treatmentManager,
      main_hospital,
      products,
      step,
      setStep,
      identity_document, proof_of_address, insurance_policy, medical_report, privacy_policy,
      setCreating,
      setErrorProcessMessage,
      setError
    } = useOnboardingContext()

    const {form} = formManager

    let contentView = null
    const userType = getUserType()

    const [applicant_data, setApplicantData] = useState({})

    const onSubmit = useCallback(async() => {
      const userType = getUserType()
      //let applicant_data = onGetApplicantData(form, applicant_values, statuses)
      let _treatments = [...treatmentManager.treatments]
      let generalError = []

      if(applicant_data.balance && applicant_data.balance_value) {
        const updateBalance = parseInt(parseFloat(applicant_data.balance_value)*100)
        applicant_data.balance_value = updateBalance.toString()
      }

      let _applicant_data = null2undefined(applicant_data)

      console.log('Data 2 send 2123',_applicant_data)

      
      let patient, patient_id
      setCreating(true)
      try {
        if(userType === USER_TYPE.HOSPITAL){
          patient = await request_create_patient_applicant(applicant_data)
          patient_id = patient.patient_id
        }else{
          patient = await request_create_patient(applicant_data)
          patient_id = patient.patient_id
        }
      } catch (error) {
        setError('Hubo un problema al realizar el registro, intente de nuevo por favor')
        setCreating(false)
        return
      }
      

      try{
        if(privacy_policy) await request_upload_document(patient_id, privacy_policy, 'privacyact','patient')
      }catch(e){
        generalError = [...generalError, 
        {title:'Aviso de privacidad', message:'No se pudo subir el archivo'}]
      }

      try{
        if(identity_document) await request_upload_document(patient_id, identity_document, 'identification','patient')
      }catch(e){
        generalError = [...generalError, 
          {title:'Documento de identificación', message:'No se pudo subir el archivo'}]
      }

      try{
        if(proof_of_address) await request_upload_document(patient_id, proof_of_address, 'addressproof','patient')
      }catch(e){
        generalError = [...generalError, 
          {title:'Comprobante de domicilio', message:'No se pudo subir el archivo'}]
      }

      try{
        console.log('POLICY VALUE')
        const data2send = {...applicant_data, patient_id:patient_id, current:1, policy_value_id:policy_value?.policy_value_id,}
        console.log('POLICY VALUE',policy_value)
        console.log('POLICY VALUE',data2send)
        const patient_insurance_id = await request_add_patient_insurance(data2send)
        
        if(insurance_policy) await request_upload_document_patient_insurance(patient_insurance_id, insurance_policy)
        
      }catch(e){
        console.log('Error al crear el policy insurance', e.response)
        generalError = [...generalError, 
          {title:'Póliza de seguro', message:'No se pudo subir el archivo'}]
      }

      //let _vials = [...vials]
      let force_vials = null
      let medicalReportUploaded = false
      for(let i=0; i<_treatments.length;i++){
        const temp = _treatments[i];
        let data2send = onGetFormData(temp.form);
        let vials = temp.vials
        data2send.patient_id = patient_id
        data2send.product_status_id = 1

        if(data2send.multiple_piece && vials.length){
          data2send.product_id = vials[0].product_id.value
        }else{
          if(!Boolean(data2send.multiple_piece) && data2send.product_id){
            console.log('Agregar producto en vial como single ')
            force_vials = {
              number:1,
              product_id:data2send.product_id
            }
          }
        }

        const isvalid =  isValidPatientProduct(data2send)
        console.log(isvalid, data2send)
        if(isvalid){
          try {
            const patientproduct_id =  await request_add_product(null2undefined(data2send))

            // Check if add medical report

            if(!medicalReportUploaded){
              try {
                if(medical_report){
                  const data2sendreport = {
                    identifier:'RP001',
                    report_date:moment().utc().format('YYYY-MM-DD'), 
                    patient_id:patient_id, 
                    patient_product_id: patientproduct_id,
                    current:1
                  }
                  const patient_medical_report_id = await request_add_medical_report(data2sendreport)
                  if(medical_report) await request_upload_document_medical_report(patient_medical_report_id, medical_report)
                  medicalReportUploaded = true
                }
              } catch (error) {
                generalError = [...generalError, 
                  {title:'Reporte médico', message:'Hubo un error al crear el reporte médico'}]
              }
              
            }

            //const patientproduct_id = 48
            for(let j=0; j<vials.length;j++){
              let vialdata = onGetFormData(vials[j])
              vialdata.patient_product_id = patientproduct_id
              console.log('Vial data', vialdata)
              try {
                await request_add_vial(vialdata)
              } catch (error) {
                console.log('Vial Error', error, vialdata)
              }
            }
            if(force_vials){
              try {
                console.log('FORCE VIAL DATA')
                force_vials.patient_product_id = patientproduct_id
                await request_add_vial(force_vials)
              } catch (error) {
                console.log('Vial Error FORCE', error, force_vials)
              }
            }
          } catch (error) {
            console.log('Ppatient product', error, data2send)
          }
          
        }

      }
      
      try {
        if(userType === USER_TYPE.HOSPITAL){
          await request_add_benefit_applicant(patient_id)
        }else{
          await request_add_benefit(patient_id)
        }
      } catch (error) {
        generalError = [...generalError, 
          {title:'Beneficio', message:'No se generó correctamente el beneficio'}]
      }

      setCreating(false)
      if(generalError.length){
        setErrorProcessMessage(generalError)
        return
      }else{
        userType === USER_TYPE.HOSPITAL 
        ?  history.push('/applicants/'+patient_id)
        : history.push('/patients/'+patient_id)
      }
    }, [
      treatmentManager,
      applicant_data,
      identity_document, proof_of_address, insurance_policy, medical_report, privacy_policy,
      policy_value
    ])

    

    const initialize = useCallback(() => {
      let result = {}
      if(!form) return result

      result = formManager.onGetFormData(form)

      result.doctor = onGetLabelOption(result?.doctor_id, doctors)
      result.public_insurance = onGetLabelOption(result?.public_insurance_id, public_insurances)
      result.gender = onGetLabelOption(result?.gender_id, genders) //**** */
      result.payment_type = onGetLabelOption(result?.payment_type_id, payment_types)
      result.main_hospital = onGetLabelOption(result?.main_hospital_id, hospitals)
      result.insurance_carrier = onGetLabelOption(result?.insurance_carrier_id, insurance_carriers)
      result.policy_amount = onGetLabelOption(result?.policy_amount_id, policy_amounts)

      result.nse_name = nse?.nse_name ?? ''
      result.nse_id = nse?.nse_id ?? undefined
      result.zip_code_id = nse?.id_zip_code ?? undefined
      result.product_type_id = product_type?.value
      result.public_insurance_status = is_public_insurance ? 1 : 0
      result.state = nse?.state ?? undefined
      result.city = nse?.city ?? undefined
      console.log(result)
      setApplicantData(result)
    }, [
      form, 
      doctors,
      public_insurances,
      genders,
      payment_types,
      hospitals,
      insurance_carriers, 
      policy_amounts
    ])

    useEffect(() => {
      initialize()
    }, [])
    
    if(isloading){
      contentView = (
        <Grid container justifyContent='center'>
          <Grid item><div>
            <SimpleLoader />  
          </div></Grid>
        </Grid>
      )
    }else{

      contentView = (
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <BasicInfoContainer data={applicant_data}/>
          </Grid>
          <Grid item xs={12}>
            <HospitalContainer data={main_hospital}/>
          </Grid>
          <Grid item xs={12}>
            <InsuranceContainer data={applicant_data} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Tratamientos</Typography>
            <div style={{padding:'16px 0px'}}>
              <Grid container>
                {treatmentManager.treatments.map((item,key)=>{
                  return(
                    <Grid item xs={12} key={`patientproduct-${key}`}>
                        <PatientProduct 
                          data={item} number={key+1}
                          hospitals={hospitals}
                          products={products}
                        />

                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </Grid>
          
        </Grid>
        
      )
    }

    return(
      <div className={classes.root}>
        {contentView}
        <OnboardingActionBar
          onReturn={() => setStep(step-1)}
          onSubmit={onSubmit}
          next_btn_label={
            userType === USER_TYPE.HOSPITAL ? 'Crear solicitante' : 'Crear beneficiario'
          }
        />
      </div>
    )
  
}

const useStyles = makeStyles({
  root:{
    paddingTop:16,
    marginRight:16,
    marginBottom:32,
  },
  image:{
    width:120,
    height:120,
  }
})

export default FormStepD