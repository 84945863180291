import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../components/Buttons/ActionModalBar';
import SimpleFile from '../../../../../../components/Files/SimpleFile/SimpleFile';
import SimpleModalV2 from '../../../../../../components/Modals/SimpleModalV2';

const UploadMedicalReportDocument = (props) => {

    const {open, onClose, origin, patient_id, patient_product_id, type,
         onUpdateDocument, onRefreshData, path} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const [file, setFile] = useState(null) 

    useEffect(() => {
        if(!open){
            setFile(null)
            setError(null)
        }
    }, [open])

    //////////////////////////// INTERNAL FUNCTIONS  ///////////////////////////////
    const onDocumentAttached = (id, files) => {
        setFile(files[0])
    }

    const onDocumentRemove = (id, files) => {
        setFile(null)
    }

    const onSubmit = async() => {

        if(loading) return

        if(!file){
            setError('Debe de subir un documento válido')
            return
        }

        setLoading(true)

        try {
            const result = await onUpdateDocument(origin?.id_patient_medical_report, file, path)
            if(!result.status){
                setError(result.error)
                setLoading(false)
                return
            }
            if(type === 'patient'){
                await onRefreshData(patient_id)
            }else{
                await onRefreshData(patient_product_id)
            }
            console.log('Termine')

        } catch (error) {
            console.log(error)
            setError('Opps, hubo un error. Por favor intente de nuevo')
            setLoading(false)
            return
        }

        setLoading(false)
        onClose()
        
    }



    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <div>
                <Typography variant='h6'>
                    Subir documento
                </Typography>
                <div style={{marginTop:16}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SimpleFile 
                                id='generic'
                                file={file}
                                onDocumentAttached={onDocumentAttached}
                                onDocumentRemove={onDocumentRemove}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar 
                                error={error}
                                onCloseError={() => setError(null)}
                                loading={loading}
                                btnLabel='Subir'
                                onSubmit={onSubmit}
                            />
                        </Grid>
                    </Grid>
                    
                </div>
            </div>
        </SimpleModalV2>
     );
}
 
export default UploadMedicalReportDocument;