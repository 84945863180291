import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import { BENEFIT_TYPE, USER_TYPE } from '../../variables/config';
import { RoundedButton } from '../Buttons/GeneralButtons';
import ContraintLayout from '../Layouts/ConstraintLayout';
import ContentText from '../Texts/ContentText';
import UsesContent from './UsesContent';


const Cycle = (props) => {

    const classes = useStyles()
    const {patientproduct, cycle, benefitType, id, isLast, userType, noedit, key,
         // PatientProduct's Function
        onRefreshPatientProducts, 
        // Cycle's Function
         onDeleteCycles, 
         onStartNextCycle,  
         onEditPreCycle,
         onDeletePreCycle,
        // Use's Function
        onChangeBenefitStatus,
        onDownloadProviderLetter,
        onRegisterUse, 
        onChangeUseDate, 
        
    } = props

    //console.log('ESTATUS EDITABLE SINGLE SYCLE', noedit)
    
    const _title = benefitType === BENEFIT_TYPE.NORMAL ? 'Beneficio' : 'Ciclo'
    let cycleTitle = cycle?.previous === 1 ? `${_title} previo` : `${_title}`
    let cycleNumber = id.toString()

    let providerLetterBtn = null

    const uses = cycle?.uses ?? []

    let isBenefit = checkIsLast(uses)
    let isDateCompleted = checkAllDated(uses)
    let isCompleted = checkAllApplied(uses)

    let newCycleBtn = null
    if(isLast && cycle?.previous === 0){
        newCycleBtn = (
            <ContraintLayout constrainst={constrainst.add_cycle} auto >
                <div style={{marginTop:8}}>
                    <RoundedButton size='small'label='Nuevo Ciclo'color='primary'
                        onClick={() => onStartNextCycle(patientproduct)}
                    />
                </div>
                
            </ContraintLayout>
      )
    }

    let editPrecycleBtn = null
    if(cycle?.previous === 1){
        editPrecycleBtn = (
            <ContraintLayout constrainst={constrainst.edit_precycle} auto >
                <IconButton size='small' onClick={() => onEditPreCycle(cycle)}>
                    <Icon fontSize='small' >edit</Icon>
                </IconButton>
            </ContraintLayout>
        )
    }

    let deletePrecycleBtn = null
    if(cycle?.previous === 1){
        deletePrecycleBtn = (
            <ContraintLayout constrainst={constrainst.edit_precycle} auto >
                <IconButton size='small' onClick={() => onDeleteCycles(cycle)}>
                    <Icon fontSize='small' >delete</Icon>
                </IconButton>
            </ContraintLayout>
        )
    }
    
    return ( 
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <ContentText text={`${cycleTitle} ${cycleNumber}`} medium variant='bold'/>
                        </Grid>
                        {editPrecycleBtn}
                        <Grid item xs></Grid>
                        {deletePrecycleBtn}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <UsesContent 
                        benefitType={benefitType} userType={userType} patientproduct={patientproduct}
                        uses={cycle?.uses ?? []} previous={cycle?.previous}
                        onRefreshPatientProducts={onRefreshPatientProducts}
                        onChangeBenefitStatus={onChangeBenefitStatus}
                        onRegisterUse={onRegisterUse} 
                        onChangeUseDate={onChangeUseDate}
                        onDownloadProviderLetter={onDownloadProviderLetter}
                        noedit={noedit}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent='flex-end'>
                        {isLast ? (
                            <ContraintLayout constrainst={constrainst.delete_cycle} auto >
                                <div style={{marginTop:8}}>
                                <RoundedButton label='Eliminar'size='small' color='red'
                                    onClick={() => onDeleteCycles(cycle)}/>
                                </div>
                            </ContraintLayout>
                        ):null}
                        {providerLetterBtn}
                        {newCycleBtn}
                    </Grid>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'16px 16px',
        borderTop:`1px solid ${grey[300]}`
    }
}))
 
export default Cycle;

const checkIsLast = (uses) => {
    let status = true
    uses.forEach(item => {if(item.free === 0 && item.applied === 0) status = false})
    return status
}
const checkAllApplied = (uses) => {
    let status = true
    uses.forEach(item => {if(item.applied === 0) status = false})
    return status
}
const checkAllDated = (uses) => {
    let status = true
    uses.forEach(item => {if(!item.date) status = false})
    return status
}

const constrainst= {
    delete_cycle:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
        ],
    },
    add_cycle:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    },
    start_treatment:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO
        ],
    },
    edit_precycle:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    }
}


