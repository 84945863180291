import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';
import { contentData } from './content';
import { mainForm } from './data';
import ContentText from '../../../../../components/Texts/ContentText';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';
import TreatmentCard from './TreatmentCard/TreatmentCard';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import { ErrorMessage } from '../../../../../components/DisplayData/DisplayData';

class FormStepD extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    formData: JSON.parse(JSON.stringify(mainForm)),
    doctors:[],
    products:[],
    diagnostics:[],
    is_public_insurance:false,
    is_secondary_treatment:false,
  }

  render(){

    const {classes, catalogs, user_level} = this.props

    const {patient_form, doctors, payment_type, nse, hospitals,
      is_secondary_treatment, insurance_carriers, policy_amounts, balance,
      policy_value,diagnostics, secondary_diagnostics, products, primary_benefit, secondary_benefit,
      issending, error, public_insurances, providers, is_public_insurance} = this.props.generalState

    //formData.insurance_carrier_id.options = [...insurance_carriers]
    //formData.policy_amount_id.options = [...catalogs.policy_amounts]

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    let r_name
    let r_external_id
    let r_doctor_id
    let r_zip
    let r_nse
    let r_hospital_id
    let r_hospital_value
    let r_provider
    let r_secondary_hospital_id
    let r_secondary_hospital_value
    let r_payment_type
    let r_insurance
    let r_insurance_level
    let r_insurance_ammount
    let r_insurance_balance
    let r_insurance_balance_ammount
    let r_public_insurance
    let aux
    let primary_product
    let secondary_product
    let r_folio

    if(patient_form){
      r_name = patient_form.name
      r_external_id = patient_form.external_id
      
      
      r_folio = patient_form.folio

      // NSE
      if(nse){
        r_nse = nse.nse_name
      }else{
        aux = catalogs.nses.find(el => el.id === patient_form.nse_id)
        r_nse = aux ? aux.label : null
      }


      // Doctor request
      aux = doctors.find(el => el.id_doctor === patient_form.doctor_id)
      if(aux){
        r_doctor_id = aux.first_name
        r_doctor_id += aux.second_name ? ' '+aux.second_name: '' 
        r_doctor_id += aux.first_surname ? ' '+aux.first_surname: '' 
        r_doctor_id += aux.second_surname ? ' '+aux.second_surname: '' 
      }

      // Hospital values
      aux = hospitals.find(el => el.id_hospital === patient_form.hospital_id)
      r_hospital_id = aux ? aux.name : null
      r_hospital_value = aux ? aux.hospital_value_name : null
      r_zip = patient_form.zip
      aux = providers.find(el => el.id_provider === patient_form.provider_id)
      r_provider = aux ? aux.name : null
      
      // Secondary and optional values
      if(is_secondary_treatment){
        aux = hospitals.find(el => el.id_hospital === patient_form.secondary_hospital_id)
        r_secondary_hospital_id = aux ? aux.name : null
        r_secondary_hospital_value = aux ? aux.hospital_value_name : null
        aux = providers.find(el => el.id_provider === patient_form.secondary_provider_id)
      }

      // Insurance's values 
      aux = catalogs.payment_types.find(el => el.id === payment_type)
      r_payment_type = aux ? aux.label : null
      //r_payment_type = 
      if(payment_type === 1 || payment_type === 3){
        if(patient_form.insurance_carrier_id && patient_form.policy_amount_id){
          aux = insurance_carriers.find(el => el.id_insurance_carrier === patient_form.insurance_carrier_id)
          r_insurance = aux ? aux.name : null
          aux = policy_amounts.find(el => el.id_policy_amount === patient_form.policy_amount_id)
          r_insurance_balance_ammount = aux ? aux.name : null
          r_insurance_level = policy_value ? policy_value.value_name : null
          r_insurance_balance = balance ? 'Sí' : 'No'
          r_insurance_balance_ammount = balance ?  ('$ '+this.numberWithCommas(parseFloat(patient_form.balance_value))) : null
          aux = policy_amounts.find(el => el.id_policy_amount === patient_form.policy_amount_id)
          r_insurance_ammount = aux ? aux.name : null
        }
      }else{
        r_insurance_level = policy_value ? policy_value.value_name : null
        r_insurance_balance = balance ? 'Sí' : 'No'
        r_insurance_balance_ammount = balance ? ('$ '+this.numberWithCommas(parseFloat(patient_form.balance_value))) : null
        aux = policy_amounts.find(el => el.id_policy_amount === patient_form.policy_amount_id)
        r_insurance_ammount = aux ? aux.name : null
      }

      if(patient_form.public_insurance_id){
        aux = public_insurances.find(el => el.id_public_insurance === patient_form.public_insurance_id)
        r_public_insurance = aux ? aux.name : null
      }

      // Products
      primary_product = this.prepareProduct(patient_form, products, diagnostics, 'primary')
      if(is_secondary_treatment){
        secondary_product = this.prepareProduct(patient_form, products, secondary_diagnostics, 'secondary')
      }

    }

    let secondaryProductBenefit = null
    if(is_secondary_treatment){
      secondaryProductBenefit = (
        <Grid item xs={12}>
          <TreatmentCard 
            type='secondary'
            data={secondary_product ? secondary_product : {}}
            benefit={secondary_benefit ? secondary_benefit : {}}  
          />
        </Grid>
      )
    }

    let secondaryHospitalContent = null
    if(is_secondary_treatment){
      secondaryHospitalContent = (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <HorizontalDataWrapper 
              label='Hospital Secundario'
              text={r_secondary_hospital_id}
              endSpacing
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HorizontalDataWrapper 
              label='Nivel'
              text={r_secondary_hospital_value}
              endSpacing
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HorizontalDataWrapper 
              label='Distribuidor Secundario'
              text={r_provider}
              endSpacing
            />
          </Grid>
          <Grid item xs={12} md={6}/>
        </Grid>
      )
    }

    let insuranceContent = null
    switch(payment_type){
      case 1:
      case 3:
        insuranceContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Forma de pago'
                text={r_payment_type}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Aseguradora'
                text={r_insurance}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Póliza'
                text={r_insurance_ammount}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Nivel'
                text={r_insurance_level}
                endSpacing
              />
            </Grid>
            {balance ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Saldo'
                      text={r_insurance_balance}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Monto'
                      text={r_insurance_balance_ammount}
                      endSpacing
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            
          </Grid>
        )
        break;
      case 2:
        insuranceContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Forma de pago'
                text={r_payment_type}
                endSpacing
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HorizontalDataWrapper 
                label='Nivel'
                text={r_insurance_level}
                endSpacing
              />
            </Grid>
          </Grid>
        )
        break;
      default:
        break;
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContentText text='Resumen' variant='bold' medium/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {user_level === 3 ? (
                    <Grid item xs={12} md={6}>
                      <HorizontalDataWrapper 
                        label='Nombre'
                        text={r_name}
                        endSpacing
                      />
                    </Grid>
                  ) : null}
                  
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='ID'
                      text={r_external_id}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Folio'
                      text={r_folio}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Doctor'
                      text={r_doctor_id}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Código Postal'
                      text={r_zip}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='NSE'
                      text={r_nse}
                      endSpacing
                    />
                  </Grid>
                  {is_public_insurance ? (
                    <Grid item xs={12} md={6}>
                      <HorizontalDataWrapper 
                        label='Aseguradora Pública'
                        text={r_public_insurance}
                        endSpacing
                      />
                    </Grid>
                  ) : null}
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContentText text='Hospitales o Centros de Infusión' variant='bold' medium/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/************** MAIN HOSPITAL *********************/}
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Hospital Principal'
                      text={r_hospital_id}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Nivel'
                      text={r_hospital_value}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <HorizontalDataWrapper 
                      label='Distribuidor'
                      text={r_provider}
                      endSpacing
                    />
                  </Grid>
                  <Grid item xs={12} md={6}/>
                  {/************** SECONDARY HOSPITAL *********************/}  
                  <Grid item xs={12}>
                    {secondaryHospitalContent}
                  </Grid>
                  {/************** END SECONDARY HOSPITAL *********************/}  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/************** INSURANCE HOSPITAL *********************/}   
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContentText text='Aseguradora' variant='bold' medium/>
              </Grid>
              <Grid item xs={12}>
                {insuranceContent}
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} >
            <TreatmentCard 
              type='primary'
              data={primary_product ? primary_product : {}}
              benefit={primary_benefit ? primary_benefit : {}}  
            />
          </Grid>
          {secondaryProductBenefit}
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='flex-end' spacing={2} alignItems='center'>
              <Grid item>
                {messageContent}
              </Grid>
              <Grid item>
                <RoundedButton 
                  label='Regresar'
                  onClick={this.onPreviousStep}
                  color='transparent'
                />
              </Grid>
              <Grid item>
                <RoundedButton 
                  label='Enviar para aprobación'
                  onClick={this.onNextStep}
                  color='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        
      </div>
    )
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  prepareProduct = (data, products, diagnostics, type) => {
    let result = {
      product:null,
      presentation:null,
      diagnostic:null,
      cycles:null,
      availability:null
    }

    let aux
    if(type === 'primary'){
      aux = products.find(el => el.id_product === data.product_id)
      result.product = aux ? aux.name : null
      result.presentation = aux ? aux.presentation : null
      aux = diagnostics.find(el => el.id_diagnosis === data.diagnosis_id)
      result.diagnostic = aux ? aux.name : null
      result.cycles = data.expected_uses
    }else{
      aux = products.find(el => el.id_product === data.secondary_product_id)
      result.product = aux ? aux.name : null
      result.presentation = aux ? aux.presentation : null
      aux = diagnostics.find(el => el.id_diagnosis === data.secondary_diagnosis_id)
      result.diagnostic = aux ? aux.name : null
      result.cycles = data.secondary_expected_uses
    }

    return result
  }

  componentDidMount(){
    const {formData} = this.state
    const {language} = this.props
    const {patient_form} = this.props.generalState
    const content = contentData[language]
    let temp = {...formData}
    ////////************ INIT FORM /////////////*/
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
      temp[item].value = patient_form[item] ? patient_form[item] : ''
    });
    this.setState({formData:{...temp}})
    //this.initModule()
  }

  getDoctorsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      let fullname = item.first_name
      fullname += item.second_name ? ' ' + item.second_name : ''  
      fullname += item.first_surname ? ' ' + item.first_surname : ''
      fullname += item.second_surname ? ' ' + item.second_surname : ''
      return {
        id: item.id_doctor,
        value: item.id_doctor,
        label: fullname
      }
    })
    return temp
  }

  getProductsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_diagnosis,
        value: item.id_diagnosis,
        label: item.name + ', ' + item.presentation
      }
    })
    return temp
  }

  onNextStep = () => {
    const {patient_form, payment_type, nse,balance, is_public_insurance,policy_value,} = this.props.generalState

    let data2send = {}
    Object.keys(patient_form).forEach(item => {
      if(patient_form[item] !== null && patient_form[item] !== undefined && patient_form[item] !== ""){
        data2send = {...data2send, [item]: patient_form[item]}
      }else{
        data2send = {...data2send, [item]: undefined}
      }
    })
    //id_zip_code
    data2send.balance = balance ? 1 : 0
    data2send.balance_value = balance ? data2send.balance_value : undefined
    
    
    data2send.payment_type_id = payment_type
    data2send.policy_value_id = policy_value.policy_value_id
    data2send.public_insurance_status = is_public_insurance ? 1 : 0
    if(nse){
      data2send.nse_id = nse.nse_id
      data2send.zip_code_id = nse.id_zip_code
    }else{
      data2send.nse_id = patient_form.nse_id
    }

    this.props.onCreatePatient(data2send)

  }

  onPreviousStep = () => {
    this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),2)
    //this.props.onChangeStep(2)
  }

  onChangeCheckbox = (id, status) => {
    this.setState({[id]:status})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

    //if(id === 'hospital_id'){
    //  this.props.onRequestHospital(data.value,'principal')
    //}else{
    //  this.props.onRequestHospital(data.value,'secondary')
    //}

  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

export default withStyles(styles)(FormStepD)