export const mainForm = {

  insurance_carrier_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'insurance_carrier_id',
      type:'select',
      fullWidth: true,
      addoption:true
    },
    rules:{
      type:'none',
    }
  },
  policy_amount_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'policy_amount_id',
      type:'select',
      fullWidth: true,
      
    },
    rules:{
      type:'none',
    }
  },
  balance_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'balance_value',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'numeric',
      min:0,
      max:999999999999
    }
  },


  
}
