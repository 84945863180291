import React from 'react'
import { Grid } from '@material-ui/core'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons'

const menuList = [
    {id:'PRINCIPAL', label:'Vista principal'},
    {id:'HISTORY', label:'Historial'}
]


const MenuToolbar = props => {

    const {selected, onChange} = props

    return(
        <div>
            <Grid container spacing={1} justifyContent='center'>
                {menuList.map((item,key)=>{
                    return(
                        <Grid item key={key.toString()}>
                            <RoundedButton size='small' label={item.label} color={selected===item.id ? 'secondary' : 'transparent'}
                            onClick={() => onChange(item.id)}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default MenuToolbar