import React, {useState} from 'react'
import SimpleDialog from '../../../../../components/Modals/SimpleDialog'
import { Grid, makeStyles, Icon, Typography } from '@material-ui/core'
import { request_delete_contact } from '../requests'
import { red, grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    container:{
        padding:'16px 0px'
    },
    icon:{
        color:red[700],
        fontSize:54
    },
    title:{
        color:grey[900],
        fontWeight:'500'
    },
}))

const DeleteEmail = props => {

    const classes = useStyles()

    const {onClose, onUpdateContacts, origin} = props
    const [issending, setIsSending] = useState(false)
    const [error, setError] = useState(null)


    const data = {issending:issending, error:error}

    const onSubmit = async() => {
        setIsSending(false)
        try {
            const {id_applicant_contact} = origin
            const contacts = await request_delete_contact(id_applicant_contact)
            onUpdateContacts(contacts)
        } catch (error) {
            setError('Hubo un error, intente de nuevo')
        }
        setIsSending(false)
    }

    return(
        <SimpleDialog
            title=''
            data={data}
            onClose={onClose}
            onSubmit={onSubmit}
            submit='Eliminar'
            maxWidth='xs'
        >
            <div className={classes.container}>
                <Grid container spacing={2} direction='column' alignItems='center'>
                    <Grid item>
                        <Grid container direction='column' alignItems='center' spacing={1}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>cancel</Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6' className={classes.title}>
                                ¿Estás seguro?
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' align='center' className={classes.message}>
                                ¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SimpleDialog>
    )
}

export default DeleteEmail