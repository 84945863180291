import React from 'react'
import { Icon, Grid, makeStyles, Button, Checkbox, IconButton } from '@material-ui/core'
import { amber, grey, green, teal } from '@material-ui/core/colors'
import ContentText from '../../../../../../../components/Texts/ContentText'
import { nodata } from '../../../../../../../variables/texts'
import { USER_TYPE } from '../../../../../../../variables/config'
import DocumentButton from './DocumentButton'
import {constrainst_single_document as constrainst} from  './constraints'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'


const useStyles = makeStyles(theme => ({
    root:{
        padding:16,
        borderBottom:`2px solid ${grey[200]}`
    },
    icon:{
        color:amber[700]
    },
    button:{
        textTransform:'none',
        borderRadius:32
    }
}))

const SingleDocument = props => {

    const {data, patient, userType, onUpload, onDownload, onValidateDocument, onDelete, status} = props
    const classes = useStyles()

    let actionButtons = null

    let disabledDoc = false
    let disabledObsdoc = false
    let disabledUpdateDoc = false

    if(patient){
        disabledDoc = patient[data.key] === null || patient[data.key] === undefined || patient[data.key] === '' || patient[data.key] === '0'
        disabledObsdoc = patient[data.obskey] === null || patient[data.obskey] === undefined || patient[data.obskey] === '' || patient[data.obskey] === '0' 
        if(userType === USER_TYPE.HOSPITAL){
            disabledUpdateDoc = !disabledDoc

        }
    }

    switch (userType) {
        case USER_TYPE.ADMIN:
        case USER_TYPE.ROCHE:
        case USER_TYPE.PRA:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledDoc}
                                onClick={() => onDownload(data.path, data.filename)}>
                                    <div style={{marginRight:8}}>Original</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledObsdoc}
                                onClick={() => onDownload(data.obspath, data.filename)}>
                                    <div style={{marginRight:8}}>Modificado</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;

        case USER_TYPE.HOSPITAL:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='secondary'
                                onClick={() => onUpload(data.path, data.filename)} disabled={disabledUpdateDoc}>
                                    <div style={{marginRight:8}}>Subir</div>
                                    <Icon>publish</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledDoc} onClick={() => onDownload(data.path, data.filename)}>
                                    <div style={{marginRight:8}}>Descargar</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <IconButton size='small' onClick={() => onDelete(data.path)}
                            disabled={disabledDoc || disabledUpdateDoc}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
        case USER_TYPE.MERCURIO:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DocumentButton text={'Original'} color='primary'
                            disabled={disabledDoc}
                            onUpload={() => onUpload(data.path, data.filename)} 
                            onDownload={() => onDownload(data.path, data.filename)}
                            onDelete={() => onDelete(data.path)}/>
                        </Grid>
                        <Grid item>
                            <DocumentButton text={'Modificado'} color='secondary'
                            disabled={disabledObsdoc}
                            onUpload={() => onUpload(data.obspath, data.filename)} 
                            onDownload={() => onDownload(data.obspath, data.filename)}
                            onDelete={() => onDelete(data.obspath)}/>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
    
        default:
            break;
    }

    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                                <Icon className={classes.icon}  fontSize='large'
                                style={status ? {color:green[700]} : undefined}>folder</Icon>    
                        </Grid>
                        <Grid item>
                            <ContentText text={data ? data.title : nodata} variant='bold' medium/>
                        </Grid>
                        
                    </Grid>
                </Grid>
                
                <ContraintLayout  constrainst={constrainst.validation} auto>
                    <Grid container  alignItems='center'>
                        <Grid item><ContentText text='Aprobado' medium/></Grid>
                        <Grid item>
                            <Checkbox 
                                checked={status}
                                onChange={userType === USER_TYPE.MERCURIO 
                                    ? (e) => onValidateDocument(data, e.target.checked)
                                    : undefined}/>
                        </Grid>
                    </Grid>
                </ContraintLayout>
                <Grid item>
                    {actionButtons}
                </Grid>
            </Grid>
        </div>
    )
}

export default SingleDocument

/*const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);*/