import React from 'react'
import { Icon, Grid, makeStyles, Button, Checkbox, IconButton, Typography } from '@material-ui/core'
import { amber, grey, green } from '@material-ui/core/colors'
import ContentText from '../../../../../components/Texts/ContentText'
import { nodata } from '../../../../../variables/texts'
import DocumentButton from './DocumentButton'
//import {constrainst_single_document as constrainst} from  './constraints'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout'
import moment from 'moment'
import { USER_TYPE } from '../../../../../variables/config'


const useStyles = makeStyles(theme => ({
    root:{
        padding:16,
        borderBottom:`2px solid ${grey[200]}`
    },
    icon:{
        color:amber[700]
    },
    button:{
        textTransform:'none',
        borderRadius:32
    },
    editIcon:{
        marginLeft:8,
        marginTop:-4,
        color:'white',
        background:theme.palette.secondary.main,
        padding:4,
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
       
    },
    editSVG:{
        fontSize:14
    }
}))

const SingleMedicalReportV2 = props => {

    const {data, userType, viewOnly, onUpload, onDownload, onValidate, onValidateDocument, onDelete, onEdit,  status} = props
    const classes = useStyles()

    let reportDate = nodata
    let isValidDocument = false
    let disabledDoc = false
    let disabledObsdoc = false
    let disabledUpdateDoc = false

    if(data){
        const current_date = moment(data.report_date)
        if(current_date.isValid()) reportDate = current_date.format('DD MMM YYYY')
        isValidDocument = data.validated_medical_report === 1
        disabledDoc = data.medical_report === null || data.medical_report === undefined || data.medical_report === '' || data.medical_report === '0'
        disabledObsdoc = data.obs_medical_report === null || data.obs_medical_report === undefined || data.obs_medical_report === '' || data.obs_medical_report === '0'
        if(userType === USER_TYPE.HOSPITAL){
            disabledUpdateDoc = !disabledDoc
        }
    }


    let actionButtons = null

    switch (userType) {
        case USER_TYPE.ADMIN:
        case USER_TYPE.ROCHE:
        case USER_TYPE.PRA:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledDoc}
                                onClick={() => onDownload(data.path, data.filename)}>
                                    <div style={{marginRight:8}}>Original</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledObsdoc}
                                onClick={() => onDownload(data.obspath, data.filename)}>
                                    <div style={{marginRight:8}}>Modificado</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;

        case USER_TYPE.HOSPITAL:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='secondary'
                                onClick={() => onUpload(data.path, data.filename)} disabled={disabledUpdateDoc}>
                                    <div style={{marginRight:8}}>Subir</div>
                                    <Icon>publish</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' className={classes.button} color='primary'
                                disabled={disabledDoc} onClick={() => onDownload(data.path, data.filename)}>
                                    <div style={{marginRight:8}}>Descargar</div>
                                    <Icon>get_app</Icon>
                            </Button>
                        </Grid>
                        <Grid item>
                            <IconButton size='small' onClick={() => onDelete(data.path)} disabled={disabledDoc || disabledUpdateDoc}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
        case USER_TYPE.MERCURIO:
            actionButtons = (
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DocumentButton text={'Original'} color='primary' disabled={disabledDoc}
                            onUpload={() => onUpload(data.path, data.filename)} 
                            onDownload={() => onDownload(data.path, data.filename)}
                            onDelete={() => onDelete(data.path)}/>
                        </Grid>
                        <Grid item>
                            <DocumentButton text={'Modificado'} color='secondary' disabled={disabledObsdoc}
                            onUpload={() => onUpload(data.obspath, data.filename)} 
                            onDownload={() => onDownload(data.obspath, data.filename)}
                            onDelete={() => onDelete(data.obspath)}/>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
    
        default:
            break;
    }

    //const buttonInformation = getComponentLogInfo(data)

    


    return(
        <div className={classes.root}>
            
            <Grid container alignItems='flex-start' spacing={3}>
                <Grid item xs>
                    <Grid container  spacing={1} wrap='nowrap'>
                        <Grid item>
                            <Icon className={classes.icon} fontSize='large'
                            style={isValidDocument ? {color:green[700]} : undefined}>folder</Icon>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <ContentText text={data ? data.identifier : nodata} variant='bold' medium/>
                                        </Grid>
                                        
                                        {/*<Grid item>
                                            <InfoButton data={buttonInformation}/>
                                        </Grid>*/}
                                        {!viewOnly ? <ContraintLayout constrainst={constrainst.edit_button} auto>
                                            <IconButton size='small' className={classes.editIcon} onClick={() => onEdit(data)}><Icon fontSize='small' className={classes.editSVG}>edit</Icon></IconButton>
                                        </ContraintLayout> : null}
                                    </Grid>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item><ContentText text='Fecha:' medium/></Grid>
                                                <Grid item><ContentText text={reportDate} variant='bold' medium/></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        
                    </Grid>
                </Grid>
                <ContraintLayout  auto>
                    <Grid container  alignItems='center'>
                        <Grid item><ContentText text='Actual' medium/></Grid>
                        <Grid item>
                            <Checkbox 
                                checked={status}
                                onChange={userType <= USER_TYPE.MERCURIO && !status && !viewOnly ? 
                                    (e) => onValidate(data, e.target.checked):null}/>
                        </Grid>
                    </Grid>
                </ContraintLayout>
                <ContraintLayout constrainst={constrainst.validation} auto>
                    <Grid container  alignItems='center'>
                        <Grid item><ContentText text='Aprobado' medium/></Grid>
                        <Grid item>
                            <Checkbox 
                                checked={isValidDocument}
                                onChange={  userType <= USER_TYPE.MERCURIO && !viewOnly    
                                    ? (e) => onValidateDocument(data, e.target.checked): null} />
                        </Grid>
                    </Grid>
                </ContraintLayout>
                <Grid item>
                    {actionButtons}
                </Grid>
            </Grid>
            <div>
                <Grid container spacing={2}>
                    {data?.patient_product_id ? <Grid item>
                        <Typography variant='body2'><strong style={{fontWeight:500}}>{`Tratamiento:`}</strong> {data?.patient_product_id}</Typography>
                    </Grid> : null}
                    {data?.product ?<Grid item>
                        <Typography variant='body2'><strong style={{fontWeight:500}}>{`Producto:`}</strong> {data?.product}</Typography>
                    </Grid> : null}
                </Grid>
                
            </div>
        </div>
    )
}

export default SingleMedicalReportV2

const constrainst = {
    validation:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO
        ],
    },
    edit_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    }
}

/*const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);*/