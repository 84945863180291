export const contentData = {
  spanish:{
    subtitles:{
      basicInfo:'Información básica',
      publicInsurance:'Asesguradora Pública',
      mainTreatment:'Tratamiento principal',
      secondaryTreatment:'Tratamiento secundario',
    },
    form:{
      nse_id:{
        label:'Nivel socioeconómico',
        placeholder:'Nivel socioeconómico',
      },
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Longitud de 1-100 caracteres'
      },
      external_id:{
        label:'ID programa de bonificaciones',
        placeholder:'ID programa de bonificaciones',
        helper:'ID no válida'
      },
      folio:{
        label:'Folio',
        placeholder:'Folio',
        helper:'Folio no válido'
      },
      doctor_id:{
        label:'Doctor',
        placeholder:'Seleccione un doctor',
      },
      zip:{
        label:'Código Postal',
        placeholder:'Código Postal',
        helper:'Ingrese un código postal válido'
      },
      nse:{
        label:'Nivel socioeconómico',
        placeholder:'Nivel socioeconómico',
      },
      public_insurance_id:{
        label:'Aseguradora pública',
        placeholder:'Seccione una aseguradora',
      },
      product_id:{
        label:'Producto + Tratamiento',
        placeholder:'Seleccione un producto',
      },
      diagnosis_id:{
        label:'Diagnóstico',
        placeholder:'Seleccione un diagnóstico',
      },
      expected_uses:{
        label:'No. de ciclos',
        placeholder:'No. de ciclos',
        helper:'Número entre 1-50'
      },
      secondary_product_id:{
        label:'Producto + Tratamiento',
        placeholder:'Seleccione un producto',
      },
      secondary_diagnosis_id:{
        label:'Diagnóstico',
        placeholder:'Seleccione un diagnóstico',
      },
      secondary_expected_uses:{
        label:'No. de ciclos',
        placeholder:'No. de ciclos',
        helper:'Número entre 1-50'
      },

    }
  }
}