import { useEffect, useState } from "react"
import usePatient from "../../../../../../hooks/usePatient"
import { useHistory, useParams } from "react-router-dom";
import usePatientDocument from "../../../../../../hooks/usePatientDocument";

const usePersonalDocumentView = (props) => {

    const patientManager = usePatient()
    const {patient} = patientManager.data
    const {id} = useParams()
    const documentManager = usePatientDocument()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [document, setDocument] = useState()
    const [documentPath, setDocumentPath] = useState()

    const [modals,setModals] = useState({
        add_document:false,
        edit_document:false,
        upload_document:false,
        delete_document:false
    })

    useEffect(() => {
        patientManager.actions.onGet(id)
    }, [])

    useEffect(() => {
        if(patient){
            console.log('Iniciarlizar ', patient)
            actions.onInitModule()
        }

    }, [patient])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            await documentManager.actions.onGetAllByPatient(patient?.id_patient)
            setLoading(false)
        },
        
        onEditPersonalDocument: (data) => {
            console.log('WEditar este element',data)
            setDocument(data)
            actions.onChangeModalStatus('edit_document', true)
        },
        onApproveDocument: async(data, status) =>{
            if(sending) return
            setSending(true)
            const data2send = {validated_medical_document:status ? 1 : 0}
            //console.log(data2send)
            try {
                const result = await documentManager.actions.onUpdate(data?.id_patient_document, data2send)
                //console.log(result)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
                await documentManager.actions.onGetAllByPatient(patient?.id_patient)
            } catch (error) {
                console.log(error)
                setError('Hubo un error, intente de nuevo')
            }
            setSending(false)
        },
        onSetCurrentDocument: async(data, status) =>{
            if(sending) return
            setSending(true)
            const data2send = {current:status ? 1 : 0}
            //console.log(data2send)
            try {
                const result = await documentManager.actions.onUpdate(data?.id_patient_document, data2send)
                //console.log(result)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
                await documentManager.actions.onGetAllByPatient(patient?.id_patient)
            } catch (error) {
                console.log(error)
                setError('Hubo un error, intente de nuevo')
            }
            setSending(false)
        },
        onUploadDocument: (data, path) => {
            //console.log(data)
            setDocument(data)
            setDocumentPath(path)
            actions.onChangeModalStatus('upload_document', true)
        },
        onDeleteDocument: (data, path) => {
            //console.log(data)
            setDocument(data)
            setDocumentPath(path)
            actions.onChangeModalStatus('delete_document', true)
        },
        onDownloadDocument: async(data, path) => {
            await documentManager.actions.onDownloadDocument(data?.id_patient_document, path)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
    }

    const system = {loading, sending,error}
    const data = {document, documentPath, patient}

    return {system, data, actions, modals, documentManager}



} 

export default usePersonalDocumentView