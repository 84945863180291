export const contentData = {
  spanish:{
    nodata:'N/D',
    layout:{
      title:'Solicitantes',
      navigation:[
        {name:'Solicitantes',path:'/applicants'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[
      {id:1, label:'En proceso', url:''},
      {id:2, label:'Activo', url:''},
      {id:3, label:'Inactivo', url:''},
      {id:null, label:'Sin Filtro', url:null},
    ],
    superfilters:[
      { id:'status',
        placeholder:'Estatus',
        filters:[
          {id:1, label:'En proceso', url:''},
          {id:2, label:'Activo', url:''},
          {id:3, label:'Inactivo', url:''},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      { id:'general',
        placeholder:'General',
        filters:[
          {id:2, label:'Nombre', url:'name', key:'name'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
    ],
    special_superfilters:[
      { id:'status',
        placeholder:'Estatus',
        filters:[
          {id:1, label:'En proceso', url:''},
          {id:2, label:'Pendiente de aprobación', url:''},
          {id:3, label:'Aprobado', url:''},
          {id:4, label:'Rechazado', url:''},
          {id:5, label:'Cancelado', url:''},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
      { id:'general',
        placeholder:'General',
        filters:[
          {id:2, label:'Nombre', url:'name', key:'name'},
          {id:null, label:'Sin Filtro', url:null},
        ]
      },
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID', 'Nombre','Hospital','Aseguradora','NSE', 'Estatus'],
    special_header:['ID', 'Nombre','Aseguradora','NSE', 'Estatus'],
    header2:[
      {text:'ID', hidden: false},
      {text:'Folio', hidden: false},
      {text:'Nombre', hidden: false},
      {text:'Hospital', hidden: false},
      {text:'Aseguradora', hidden: false},
      {text:'NSE', hidden: false},
      {text:'Estatus', hidden: false},
      {text:'Oferta', hidden: false},
      {text:'Carta', hidden: false},
      {text:'Beneficio', hidden: false},
    ]
  }
}