import moment from 'moment'
import { 
  request_user, 
  request_patient, 
  request_hospital, 
  request_doctor, 
  request_insurance_carrier, 
  request_public_insurance, 
  request_approval_contacts, 
  request_patient_product, 
  request_cycle, 
  request_use, 
  request_provider
} from './requests'

export const cleanData2Table = (data, nodata, users) => {
  const table = data.map(item => {
    
    let temp = {
      id:item.id_log, 
      user_image:null,
      data:[]
    }

    let userInfo = users.find(el => el.id_user === item.user_id)
    temp.user_image = userInfo ? userInfo.image : null
    let fullname = ''
    fullname += (userInfo ? userInfo.first_name : '')
    fullname += ' '+(userInfo ? userInfo.last_name : '')
    let date = item ? moment(item.created_at).format('DD/MM/YYYY HH:mm') : ''

    let array = [
      item.id_log,
      fullname,
      item.log_event ? item.log_event : nodata, 
      date
    ]
    temp.data = [...array]
    return temp
  })

  return table
}

export const getAllLogData = async(aux, extraForm, log_event, ref_1_id, ref_2_id) => {
  const logData = {...aux}
  let response = null 
  
  if(log_event > 1 && log_event < 7 ){
    response = await request_user(ref_1_id)
  }

  if(log_event > 6 && log_event < 10 || log_event === 39 || 
    (log_event > 55 && log_event < 76) || log_event === 80){
    response = await request_patient(ref_1_id)
    const {name} = response
    const {patient} = extraForm
    patient.value = name
    Object.assign(logData, {'patient':patient})
  }

  if(log_event > 9 && log_event < 13){
    response = await request_hospital(ref_1_id)
    const {name} = response
    const {hospital} = extraForm
    hospital.value = name
    Object.assign(logData, {'hospital':hospital})
  }

  if(log_event === 13){
    response = await request_hospital(ref_1_id)
    const h1 = response.name
    response = await request_hospital(ref_2_id)
    const h2 = response.name
    const {hospital_old, hospital_new} = extraForm
    hospital_old.value = h1
    hospital_new.value = h2
    Object.assign(logData, {'hospital_old':hospital_old,'hospital_new':hospital_new})
  }

  if(log_event > 13 && log_event < 17){
    response = await request_doctor(ref_1_id)
    const {first_name, second_name, first_surname, second_surname} = response
    const {doctor} = extraForm
    const name = first_name+' '+(second_name ? second_name+' ': '')+first_surname+' '+second_surname
    doctor.value = name
    Object.assign(logData, {'doctor':doctor})
  }

  if(log_event === 17){
    response = await request_doctor(ref_1_id)
    let d1 = response
    const name_d1 = d1.first_name+' '+(d1.second_name ? d1.second_name+' ': '')+d1.first_surname+' '+d1.second_surname
    response = await request_doctor(ref_2_id)
    let d2 = response
    const name_d2 = d2.first_name+' '+(d2.second_name ? d2.second_name+' ': '')+d2.first_surname+' '+d2.second_surname
    const {doctor_old, doctor_new} = extraForm
    doctor_old.value = name_d1
    doctor_new.value = name_d2
    Object.assign(logData, {'doctor_old':doctor_old,'doctor_new':doctor_new})
  }

  if(log_event > 17 && log_event < 21){
    response = await request_insurance_carrier(ref_1_id)
    const {name} = response
    const {insurance_carrier} = extraForm
    insurance_carrier.value = name
    Object.assign(logData, {'insurance_carrier':insurance_carrier})
  }

  if(log_event === 21){
    response = await request_insurance_carrier(ref_1_id)
    const i1 = response.name
    response = await request_insurance_carrier(ref_2_id)
    const i2 = response.name
    const {insurance_carrier_old, insurance_carrier_new} = extraForm
    insurance_carrier_old.value = i1
    insurance_carrier_new.value = i2
    Object.assign(logData, {'insurance_carrier_old':insurance_carrier_old,'insurance_carrier_new':insurance_carrier_new})
  }

  if(log_event > 21 && log_event < 25){
    response = await request_public_insurance(ref_1_id)
    const {name} = response
    const {public_insurance} = extraForm
    public_insurance.value = name
    Object.assign(logData, {'public_insurance':public_insurance})
  }
  
  if(log_event === 25){
    response = await request_public_insurance(ref_1_id)
    const p1 = response.name
    response = await request_public_insurance(ref_2_id)
    const p2 = response.name
    const {public_insurance_old, public_insurance_new} = extraForm
    public_insurance_old.value = p1
    public_insurance_new.value = p2
    Object.assign(logData, {'public_insurance_old':public_insurance_old,'public_insurance_new':public_insurance_new})
  }

  if(log_event > 25 && log_event < 29){
    response = await request_approval_contacts(ref_1_id)
    const {name} = response
    const {approval_contact} = extraForm
    approval_contact.value = name
    Object.assign(logData, {'approval_contact':approval_contact})
  }

  if((log_event > 28 && log_event < 32) || (log_event > 35 && log_event < 39) || log_event === 44){
    response = await request_patient_product(ref_1_id)
    const {product_name} = response
    const {patient_product} = extraForm
    patient_product.value = product_name
    Object.assign(logData, {'patient_product':patient_product})
  }
  
  if((log_event > 31 && log_event < 35) || log_event === 45){
    response = await request_cycle(ref_1_id)
    const {number, patient_product_id} = response
    console.log(number)
    response = await request_patient_product(patient_product_id)
    const {product_name, patient_id} = response
    response = await request_patient(patient_id)
    const {name} = response

    const {cycle, patient, patient_product} = extraForm
    cycle.value = number.toString()
    patient.value = name
    patient_product.value = product_name
    Object.assign(logData, {'patient':patient, 'cycle':cycle, 
    'patient_product':patient_product})
  }

  if(log_event === 35){
    response = await request_use(ref_1_id)
    const number_use = response.number
    const {cycle_id} = response
    response = await request_cycle(cycle_id)
    const {number, patient_product_id} = response
    response = await request_patient_product(patient_product_id)
    const {product_name, patient_id} = response
    response = await request_patient(patient_id)
    const {name} = response

    const {use, cycle, patient, patient_product} = extraForm
    use.value = number_use.toString()
    cycle.value = number.toString()
    patient.value = name
    patient_product.value = product_name
    Object.assign(logData, {'patient':patient, 'cycle':cycle, 
    'patient_product':patient_product, 'use':use})
  }    

  if(log_event > 39 && log_event < 43){
    response = await request_provider(ref_1_id)
    const {name} = response
    const {provider} = extraForm
    provider.value = name
    Object.assign(logData, {'provider':provider})
  }

  if(log_event === 43){
    response = await request_provider(ref_1_id)
    const p1 = response.name
    response = await request_provider(ref_2_id)
    const p2 = response.name
    const {provider_old, provider_new} = extraForm
    provider_old.value = p1
    provider_new.value = p2
    Object.assign(logData, {'provider_old':provider_old,'provider_new':provider_new})
  }

  if(log_event > 52 && log_event < 56 || log_event === 76){
    response = await request_use(ref_1_id)
    const {name} = response
    const {use} = extraForm
    use.value = name
    Object.assign(logData, {'use':use})
  }

  if(log_event > 76 && log_event < 80 || log_event === 76){
    response = await request_use(ref_1_id)
    const {name} = response
    const {application_contact} = extraForm
    application_contact.value = name
    Object.assign(logData, {'application_contact':application_contact})
  }

  if(log_event > 80 && log_event < 88){
    response = await request_use(ref_1_id)
    const {name} = response
    const {patient_insurance} = extraForm
    patient_insurance.value = name
    Object.assign(logData, {'patient_insurance':patient_insurance})
  }

  if(log_event > 87 && log_event < 91){
    response = await request_use(ref_1_id)
    const {name} = response
    const {hospital_doctor} = extraForm
    hospital_doctor.value = name
    Object.assign(logData, {'hospital_doctor':hospital_doctor})
  }

  return logData
}