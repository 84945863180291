import {mainServer} from "../../../../../../variables/config"

export const request_edit_patient = async (id,data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    await mainServer.instance.patch(`/patient/${id}`, data)
    let response
    response = await mainServer.instance.get(`/patient/${id}`);
    return response.data.data.patient
}

export const request_patient_insurance = async (idpatient) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}/current`)
    return response.data.data.patient_insurance
}


export const request_medical_report = async (idpatient) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.get(`/patientmedicalreport/patient/${idpatient}/current`)
    return response.data.data.patient_medical_report
}
//////////////////////////// VERIFICATIONS ////////////////////////////////////////


export const request_nse = async(code) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/nse/zip", {params:{zip:code}})
    return response.data.data.nse
}

export const request_external_id = async (data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    await mainServer.instance.post("/patient/check/externalid",{external_id:data})
}
  
export const request_folio = async (data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    await mainServer.instance.post("/patient/check/folio",{folio:data})
}

//////////////////////////// HOSPITALS ////////////////////////////////////////


export const request_hospital = async (id) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    response = await mainServer.instance.get("/hospital/"+id);
    return response.data.data.hospital
}

export const request_hospitals = async() => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/hospital/all")
    return response.data.data.hospitals
} 

export const request_doctors = async () => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/doctor/all")
    return response.data.data.doctors
}

//////////////////////////// INSURANCES PUBLIC OR PRIVATE ////////////////////////////////////////

export const request_public_insurances = async() => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/publicinsurance/all")
    return response.data.data.public_insurances
}

export const request_insurance_carriers = async() => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/insurancecarrier/all")
    return response.data.data.insurance_carriers
  }
  
  export const request_policy_amounts = async () => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/insurancecarrier/policyamounts")
    return response.data.data.policy_amounts
  }

  export const request_add_patient_insurance = async(idpatient, data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    //patient_insurance_id
    await mainServer.instance.post("/patientinsurance",data)
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}/current`)
    return response.data.data.patient_insurance
}

export const request_edit_patient_insurance = async(idpatient, idinsurance, data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    //patient_insurance_id
    await mainServer.instance.patch(`/patientinsurance/${idinsurance}`,data)
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}/current`)
    return response.data.data.patient_insurance
}
  