import React from 'react';
import { grey } from '@material-ui/core/colors';
import {Typography,Avatar, Grid} from '@material-ui/core';

import nouser from '../../assets/nouser.png'
import { imgServerS3 } from '../../variables/config';
const imageURL = imgServerS3

const styles = {
  avatar:{
    width:'64px',
    height:'64px',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  noavatar:{
    width:'64px',
    height:'64px',
    borderRadius:'50%',
    color:'blue',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .24)',
  },
  icon:{
    padding:'20px 25px',
    fontSize:'200px',
    color:grey[400]
  },
  name:{
    color:'white'
  },
  usertype:{
    color:'white',
    fontSize:12
  }

}

const SidebarAvatar = (props) => {

  const {image, name, user_type} = props;

  let avatarContent = null;

  if(image && image !== "" && image !== "0" && image!== 0){
    avatarContent = (
      <Avatar 
        alt={nouser}
        src={imageURL + image}
        style={styles.avatar}
      />
    )
  }else{
    avatarContent = (
      <Avatar 
        alt={nouser}
        src={nouser}
        style={styles.avatar}
      />
    )
  }

  return(
    <div>
      <Grid container justifyContent='center' spacing={1}>
        <Grid item>
          {avatarContent}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' align='center' style={styles.name}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' align='center' style={styles.usertype}>
            {user_type}
          </Typography>
        </Grid>
        
      </Grid>
      

    </div>
  )
}

export default SidebarAvatar;