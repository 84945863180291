import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Chart from './Chart';
import Indicators from './Indicators';
import Subtitle from '../../Texts/Subtitle';
import { amber, blue, cyan, deepOrange, green, grey, purple } from '@material-ui/core/colors';

const ShapePieChartTable = ({ data, title, colors, key_name, key_value }) => {
	const classes = useStyles();

	let empty = true;
	for (let i = 0; i < data.length; i++) {
		if (data[i][key_value] && data[i][key_value] > 0) {
			empty = false;
			break;
		}
	}

	const COLORS = colors
		? colors === 1
			? [blue[900], blue[600], grey[400], '#FF8042']
			: [purple[700], blue[700], cyan[700], green[700], amber[700], deepOrange[700], '#A7A108']
		: [blue[900], blue[600], grey[400], '#FF8042'];

	return (
		<Grid container className={classes.root} spacing={2}>
			<Grid item xs={12}>
				<Subtitle medium text={title} color={blue[900]} />
			</Grid>
			{!empty ? (
				<>
					<Grid item xs={12} md={7}>
						<Chart data={data} colors={COLORS} key_name={key_name} key_value={key_value} />
					</Grid>
					<Grid item container xs={12} md={5} alignItems='center'>
						<Indicators data={data} colors={COLORS} key_name={key_name} />
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<Typography variant='h6'>Sin datos</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 25,
	},
}));

export default ShapePieChartTable;
