import React from 'react'
import { Grid } from '@material-ui/core'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import RegisterCard from '../../../../components/Layouts/RegisterCard/RegisterCard';
import FormStepA from './FormStepA/FormStepA';
import FormStepB from './FormStepB/FormStepB';
import FormStepC from './FormStepC/FormStepC';
import FormStepD from './FormStepD/FormStepD';
import FormStep0 from './FormStep0/FormStep0'
import ErrorInfoModal from '../../../../components/Modals/ErrorInfoModal'
import { getUserType } from '../../../../shared/utility'
import { USER_TYPE } from '../../../../variables/config'
import LoadingModal from './modals/LoadingModal'
import ErrorProcessModal from './modals/ErrorProcessModal'
import { OnboardingProvider } from '../../../../providers/OnboardingProvider/OnboardingContext'
import { usePlatformContext } from '../../../../context/PlatformContext'
import useOnboardingContext from '../../../../providers/OnboardingProvider/useOnboardingContext'

const AddApplicant = (props) => {
  return(
    <OnboardingProvider>
      <_AddApplicant 
        {...props}
      />
    </OnboardingProvider>
  )
}

const _AddApplicant = (props) => {

  const {history} = props

  const onboardingManager = useOnboardingContext()
  const {step, setError, creating, error_process_message, loading} = onboardingManager
  const {language} = usePlatformContext()

    const content = contentData[language]
    
    // Requested catalogs reassembly
    const userType = getUserType()
    let formContent = null


    let creatingModal = null
    if(creating) creatingModal = <LoadingModal />

    if(!loading){
      switch (step) {
        case 1:
          /************************** COMPLIANCE DATA ****************************** */
          formContent = (
            <FormStep0 />
          )
          break;
        case 2:
          /************************** APPLICANT DATA ****************************** */
          formContent = (
            <FormStepA />
          )
          break;
        case 3:
          /************************** HOSPITAL DATA ****************************** */
          formContent = (
            <FormStepB />
          )
          break;
        case 4:
          /************************** INSURANCE DATA ****************************** */
          formContent = (
            <FormStepC />
          )
          break;
        case 5:
            /************************** OVERVIEW DATA ****************************** */
            formContent = (
              <FormStepD history={history} />
            )
            break;
      
        default:
          break;
      }
    }

    //////////////// ERROR MODALS ////////////////////
    let errorModal = null


    if(onboardingManager.error){
      errorModal = (
        <ErrorInfoModal 
          data={onboardingManager.error}
          onClose={() => setError(null)}
        />
      )
    }

    if(error_process_message && error_process_message.length > 0){
      errorModal = (
        <ErrorProcessModal 
          history={history} 
          data={error_process_message}
          onClose={() => console.log('No se puede cerrar')}
        />
      )
    }


    //////// HANDLE INTERNAL ERRORS AND WAITINGS STATES //////// 
    /*let messageContent = null
    if(sending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }*/
    
    let layoutData = userType === USER_TYPE.HOSPITAL ?
    content.layout : content.layout_normal

      
    return(
      <BasicView
        history={history}
        content={layoutData}
        isloading={loading}
        returnBtn={true}
        onReturn={() => history.goBack()}
      >
        {errorModal}
        {creatingModal}
        <Grid container>
          <Grid item xs={12}>
            <RegisterCard
              userType={userType}
              menu={content.views}
              step={step}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {formContent}     
                </Grid>
              </Grid>
              
            </RegisterCard>
          </Grid>
        </Grid>
        
        
      </BasicView>
      
    )
  
}


export default AddApplicant