import React, {Component} from 'react'
import { Grid, TableRow,TableCell, Icon, Table, TableBody } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors';
import ContentText from '../../../../../components/Texts/ContentText';


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'24px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  },
  icon:{
    fontSize:12,
  }
})

class InactivePatientStatsTable extends Component{

  render(){

    const {classes, data} = this.props

    return(
      
      <div className={classes.root}>
        <Table>
          <TableBody>
            {data.map((item,key)=>{
              return(
                <TableRow key={key.toString()}>
                  <TableCell>
                    <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                      {item[0] ? <Grid item><Icon className={classes.icon} style={{color:item[0]}}>brightness_1</Icon></Grid> : null}
                      <Grid item><ContentText medium text={item[1]} variant='bold'/></Grid>
                    </Grid>
                  </TableCell>
                  <TableCell><ContentText text={item[2] !== null && item[2] !== undefined ? item[2] : ''} variant='light'/></TableCell>
                  <TableCell><ContentText medium text={item[3] !== null && item[3] !== undefined ? parseFloat(item[3]).toFixed(1) + '%': ''} variant='light'/></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(InactivePatientStatsTable)