export const contentData = {
  spanish:{
    layout:{
      title:'Editar Beneficiario',
      navigation:[
        {name:'Beneficiarios',path:'/patients'},
        {name:'Detalles',path:'/patients/:id'},
        {name:'Editar',path:'/patients/:id/edit'}
        
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
  }
}