import React, {Component} from 'react'
import { withStyles, Grid } from '@material-ui/core';
import TreatmentComponent from '../../../TreatmentComponent/TreatmentComponent';
import { USER_TYPE } from '../../../../../../../../../../variables/config';
import { getUserType } from '../../../../../../../../../../shared/utility';
import { getHospitals2ProviderOptions, getLetterProvidersOptions, getProductsOptions, getOnlyHospitalsOptions, getDiagnosticsOptions } from '../../../../../../../../../../shared/customFunctions';
import { request_products, request_patient, request_patientproducts, request_hospitals, 
  request_patient_providers, request_download_provider_letter, request_update_cycle, 
  request_update_use, request_delete_cycle, request_create_cycle, request_edit_previous_cycle, 
  request_change_benefit, request_diagnostics, request_delete_patient_product, 
  request_edit_patient_product, request_add_patient_product, request_recalculate_benefit_by_product, 
  request_recalculate_benefit, request_update_all_cycles, request_add_previous_cycle, request_patientproduct } from './requests';
import ProviderLetterModal from '../../../../modals/ProviderLetterModal/ProviderLetterModal';
import BenefitStatusModal from '../../../../modals/BenefitStatusModal/BenefitStatusModal';
import UseDateModal from '../../../../modals/UseDateModal/UseDateModal';
import EditPreviousCycleModal from '../../../../modals/EditPreviousCycleModal/EditPreviousCycleModal';
import FormAModal from '../../../../../../../../../../components/Modals/FormAModal';

import {formData as manual_change_data} from '../../../../modals/manualchange/data' 
import {contentData as manual_change_content} from '../../../../modals/manualchange/content' 
import {catalog_matrix as manual_change_matrix} from '../../../../modals/manualchange/catalog_matrix'
import EditProductModal from '../../../../modals/EditProductModal/EditProductModal';
import AddProductModal from '../../../../../../Modals/AddProductModal/AddProductModal';
import SimpleContainer from '../../../../../../../../../../components/Layouts/SimpleContainer';
import AddPreviousCycleModal from '../../../../modals/AddPreviousCycleModal/AddPreviousCycleModal';
import ErrorWarningModal from '../../../../../../../../../../components/Modals/ErrorWarningModal';
import EditManualUsesModal from '../../../../modals/EditManualUsesModal/EditManualUsesModal';
import WarningModal from '../../../../../../../../../../components/Modals/WarningModal';
import SimpleDialog from '../../../../../../../../../../components/Modals/SimpleDialog';
import PatientProductCard from '../../../../../../../../../../components/DisplayData/PatientProductCard';
import { constrainst_patientproduct } from '../../../constraints';
import UseDateModalV2 from '../../../../modals/UseDateModal/UseDateModalV2';


class PatientProductModal extends Component {

  state = {
    isloading:true,
    issending:false,
    selected:'HISTORY',
    patient:null,
    patientproducts:[],
    products:[],
    hospitals:[],
    diagnostics:[],
    selected_cycle:null,
    selected_use:null,
    selected_precycle:null,
    selected_product:null,
    selected_patientproduct:null,
    patientproduct_type:'primary',
    patient_providers:[],
    modals:{
      edit_provider_letter:{open:false,issending:false,error:null},
      edit_benefit_status:{open:false,issending:false,error:null},
      edit_uses_date:{open:false,issending:false,error:null},
      edit_previous_cycle_modal:{open:false,issending:false,error:null},
      edit_benefit:{open:false,issending:false,error:null},
      error_benefit_modal:{open:false,issending:false,error:null},
      edit_product:{open:false,issending:false,error:null},
      add_product:{open:false,issending:false,error:null},
      add_previous_cycle_modal:{open:false,issending:false,error:null},
      edit_manual_uses:{open:false,issending:false,error:null},
      finish_product_modal:{open:false,issending:false,error:null},
    },
    edit_manual_uses_modal:false
  }


  render(){

    const {patient, patientproduct, modals, hospitals, patient_providers, selected_cycle,
      selected_use, selected_precycle, selected_product, products, diagnostics, selected_patientproduct,
      patientproduct_type, isloading, selected} = this.state

    const {edit_manual_uses_modal} =this.state

    const {catalogs, onClose}  = this.props

    const products_catalog = getProductsOptions(products)
    const clean_hospitals_catalog = getOnlyHospitalsOptions(hospitals)
    const diagnostics_catalog = getDiagnosticsOptions(diagnostics)

    let modalcontent = null

    ///////////////////////////////////// ADD PATIENTPRODUCT MODAL//////////////////////////////////////////////
    if(modals.add_product.open){
      modalcontent = (
        <AddProductModal 
          addSpace={true}
          type={patientproduct_type}
          catalogs={{
            products:[...products_catalog],
            diagnostics:[...diagnostics_catalog],
            hospitals:[...clean_hospitals_catalog],
            adjuvants: [...catalogs.adjuvant_statuses]
          }}
          data={modals.add_product}
          onClose={() => this.onCloseModal('add_product')}
          onRequestDiagnostic={this.onRequestDiagnostic}
          onSubmit={this.onAddProduct}
        />
      )
    }

    

    ///////////////////////////////////// EDIT PATIENTPRODUCT MODAL//////////////////////////////////////////////
    if(modals.edit_product.open){
      modalcontent = (
        <EditProductModal 
          addSpace={true}
          origin_data={selected_patientproduct}
          type={patientproduct_type}
          catalogs={{
            products:[...products_catalog],
            diagnostics:[...diagnostics_catalog],
            hospitals:[...clean_hospitals_catalog],
            adjuvants: [...catalogs.adjuvant_statuses]
          }}
          data={modals.edit_product}
          onClose={() => this.onCloseModal('edit_product')}
          onRequestDiagnostic={this.onRequestDiagnostic}
          onSubmit={this.onSubmitProduct}
          onDeleteProduct={this.onDeleteProduct}
        />
      )
    }

    
    if(edit_manual_uses_modal){
      modalcontent = <EditManualUsesModal origin={selected_patientproduct} 
      onUpdateData={(data) => this.setState({patientproducts:data, edit_manual_uses_modal:false})}
      onClose={() => this.setState({edit_manual_uses_modal:false})}/>
    }

    

    ///////////////////////////////////// MANUAL EDITION BENEFIT MODAL//////////////////////////////////////////////
    
    if(modals.edit_benefit.open){
      modalcontent = (
        <FormAModal  addSpace={true} maxWidth='xs' catalogs={catalogs}
          origin_data={selected_product}
          data={modals.edit_benefit}
          catalog_matrix={manual_change_matrix}
          content={manual_change_content['spanish']}
          form_data={manual_change_data}
          onClose={() => this.onCloseModal('edit_benefit')}
          onSubmit={this.onManualChange}
        />
      )
    }

    ///////////////////////////////////// PROVIDER LETTER MODAL//////////////////////////////////////////////
    const hospital_catalogs = getHospitals2ProviderOptions(hospitals)
    const letter_provider_catalogs = getLetterProvidersOptions(patient_providers)

    let hospital_final_cat = {
      hospitals:[...hospital_catalogs], 
      providers: [...letter_provider_catalogs]
    }

    if(modals.edit_provider_letter.open){
      modalcontent = (
        <ProviderLetterModal 
          addSpace={true}
          cycle={selected_cycle}
          catalogs={hospital_final_cat}
          data={modals.edit_provider_letter}
          onClose={() => this.onCloseModal('edit_provider_letter')}
          onSubmit={this.onFinalDownloadProviderLetter}
        />
      )
    }

    ///////////////////////////////////// BENEFIT PROCESS STATUS MODAL//////////////////////////////////////////////

    if(modals.edit_benefit_status.open){
      modalcontent = (
        <BenefitStatusModal 
          origin_data={selected_use}
          catalogs={catalogs ? catalogs : []}
          data={modals.edit_benefit_status}
          onClose={() => this.onCloseModal('edit_benefit_status')}
          onSubmit={(data) => this.onUpdateUseDate(data, 'edit_benefit_status')}
          addSpace
        />
      )
    }

    if(modals.error_benefit_modal.open){
      modalcontent = (
        <ErrorWarningModal 
          data={modals.error_benefit_modal}
          content={{
            title:'¡Hubo un error!',
            message:'Verifique que los datos necesarios para calcular la oferta estén completos',
            cancelButton:'Cancelar',
            deleteButton:'Eliminar'
          }}
          onClose={() => this.onCloseModal('error_benefit_modal')}
          onDelete={this.onDeleteNote}
        />
      )
    }
    ///////////////////////////////////// EDIT DATE STATUS MODAL//////////////////////////////////////////////

    if(modals.edit_uses_date.open){
      let filtered_products_catalog = []
      if(products_catalog.length && selected_use && selected_use.product_name){
        filtered_products_catalog = products_catalog.filter(el => el.label.includes(selected_use.product_name))
        if(filtered_products_catalog.length === 0){
          filtered_products_catalog = [...products_catalog]
        }
      }else{
        filtered_products_catalog = [...products_catalog]
      }


      console.log('USE', filtered_products_catalog)
      
      //console.log(filtered_products_catalog)


      modalcontent = (
        <UseDateModalV2
          origin_data={selected_use} patient={patient} open={true} origin={selected_use}
          hospitals={[...hospital_catalogs]}
          products={[...filtered_products_catalog]}
          catalogs={{hospitals:[...hospital_catalogs], products:[...filtered_products_catalog]}}
          data={modals.edit_uses_date}
          onClose={() => this.onCloseModal('edit_uses_date')}
          onSubmit={(data) => this.onUpdateUseDate(data, 'edit_uses_date')}
          onRefreshPatientProducts={this.onRequestPatientProduct}
          onUpdateData={(data)=>this.setState({patientproducts:data})}
          onDelete={this.onDeleteUseDate}
          noedit={true}
        />
      )

      /* modalcontent = (
        <UseDateModal 
          origin_data={selected_use}
          catalogs={hospital_final_cat}
          data={modals.edit_uses_date}
          onClose={() => this.onCloseModal('edit_uses_date')}
          onSubmit={(data) => this.onUpdateUseDate(data, 'edit_uses_date')}
          onDelete={this.onDeleteUseDate}
        />
      ) */
    }

    ///////////////////////////////////// EDIT PREVIOUS CYCLEMODAL//////////////////////////////////////////////

    if(modals.add_previous_cycle_modal.open){
      modalcontent = (
        <AddPreviousCycleModal
          origin_data={selected_patientproduct}
          catalogs={[]}
          data={modals.add_previous_cycle_modal}
          onClose={() => this.onCloseModal('add_previous_cycle_modal')}
          onSubmit={(data) => this.onAddPreviousCycle(data, 'add_previous_cycle_modal')}
        />
      )
    }

    if(modals.edit_previous_cycle_modal.open){
      modalcontent = (
        <EditPreviousCycleModal
          origin_data={selected_precycle}
          catalogs={[]}
          data={modals.edit_previous_cycle_modal}
          onClose={() => this.onCloseModal('edit_previous_cycle_modal')}
          onSubmit={(data) => this.onEditPreviousCycle(data, 'edit_previous_cycle_modal')}
        />
      )
    }

    
    if(modals.finish_product_modal.open){
      console.log('Montar warning modals')
      modalcontent = (
        <WarningModal data={modals.finish_product_modal} icon='warning'
        content={{message:'¿Realmente quieres finalizar este tratamiento?', deleteButton:'Finalizar'}}
        onClose={() => this.onCloseModal('finish_product_modal')} 
        onDelete={this.onFinishTreatment}/>
      )
    }

    console.log('Mondata patient proudct modal')

    console.log(patientproduct)

    return(
      <SimpleDialog 
        data={{issending:false, error:null}}
        maxWidth='lg'
        onClose={onClose}
        hideSubmitBtn
      >
        <SimpleContainer isloading={isloading}>   
          {modalcontent}
            <PatientProductCard 
              patient={patient}
              patient_status={patient?.status_id}
              patientproduct={patientproduct} 
              constrainst={constrainst_patientproduct} 
              /// Patient Product's functions
              onViewMedicalReports={this.onViewMedicalReports}
              //onEditTreatment={this.onEditTreatmentV2}
              //onEditManualUses={this.onPreEditManualUses}
              //onStartTreatment={this.onStartTreatment}
              //onFinishTreatment={this.onPreFinishTreatment}
              onRefreshPatientProducts={this.onRequestPatientProduct}
              /// Benefit's funroducts={this.onRequestPatientProduct}
              /// Benefit's functions
              onManualChange={this.onPreManualChange}
              /// Cycle's functions
              //onStartNextCycle={this.onStartNextCycle} 
              //onDeleteCycles={this.onDeleteCycles} 
              //onEditPreCycle={this.onPreEditPreCycle}
              //onAddPreviousCycle={this.onPreAddPreviousCycle}
              /// Uses's functions
              onChangeBenefitStatus={this.onChangeBenefitStatus} 
              onRegisterUse={this.onRegisterUse} 
              onChangeUseDate={this.onChangeUseDate} 
              onDownloadProviderLetter={this.onDownloadProviderLetter}
              noedit={true}
            />

            {/* <TreatmentComponent 
                  patient={patient}
                  type='primary'
                  disabledFinishTratment={true}
                  patientproduct={patientproduct}
                  onStartNextCycle={this.onStartNextCycle}
                  onDeleteCycles={this.onDeleteCycles}
                  onDownloadProviderLetter={this.onDownloadProviderLetter}
                  onChangeBenefitStatus={this.onChangeBenefitStatus}
                  onRegisterUse={this.onRegisterUse}
                  onChangeUseDate={this.onChangeUseDate}
                  onEditPreCycle={this.onPreEditPreCycle}
                  onStartTreatment={this.onStartTreatment}
                  onManualChange={this.onPreManualChange}
                  onEditTreatment={this.onEditTreatment}
                  onAddPatientProduct={this.onAddPatientProduct}
                  onAddPreviousCycle={this.onPreAddPreviousCycle}
                  onEditManualUses={this.onPreEditManualUses}
                  onFinishTreatment={this.onPreFinishTreatment}
              /> */}
        </SimpleContainer>
      </SimpleDialog>
      
      
     
    )
  }

  /////////////////////////// INITIALIZATION ////////////////////////////

  onPreEditManualUses = (data) => {
    console.log(data)
    this.setState({selected_patientproduct:data, edit_manual_uses_modal:true})
  }


  componentDidMount(){
    this.initModule()
    console.log('Abrir')
  }
  
  initModule = async() => {
    try {
      const {id} = this.props.match.params
      const {id_patient_product} = this.props.selected_patientproduct
      this.setState({isloading:true})
      const patient = await request_patient(id)
      const {id_patient} = patient
      const patientproduct = await request_patientproduct(id_patient_product)
      this.setState({
        isloading:false,
        patient:patient,
        patientproduct:patientproduct,
        
      })
    } catch (error) {
      this.setState({isloading:false})
      console.log(error)
    }
  }

  onRecalculateBenefit = async(data) => {
    this.setState({issending:true})
    try{
      const {id} = this.props.match.params
      let response = await request_recalculate_benefit(id)
      this.setState({patientproducts:response}) 
    }catch(e){
      this.onOpenModal('error_benefit_modal')
    }
    this.setState({issending:false})
  }

  /////////////////////////// PATIENT PRODUCT MANAGEMENT ////////////////////////////

  onAddPatientProduct = async(type) => {
    try {
      const hospitals = await request_hospitals()
      const products = await request_products()
      this.setState({hospitals:hospitals, products:products, patientproduct_type:type})
      this.onOpenModal('add_product')
    } catch (error) {
      console.log(error.response)
    }
    
  }

  onAddProduct = async(data) => {
    const {patientproduct_type} = this.state
    const {id} = this.props.match.params
    const type = 'add_product'

    let data2send

    if(patientproduct_type === 'primary'){
      console.log('Es primario')
      data2send = {
        ...data,
        product_status_id:1,
        secondary:0,
        adjuvant:0,
        patient_id:parseInt(id)
      }
    }else{
      data2send = {
        ...data,
        product_status_id:1,
        secondary:1,
        adjuvant:data.adjuvant === 1 ? 1 : 0,
        patient_id:parseInt(id)
      }
    }
    console.log(data2send)
  
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_patient_product(id,data2send)
      const patient_product_id = response
      const patientproducts = await request_patientproducts(id)
      this.setState({patientproducts:patientproducts}) 
      response = await request_recalculate_benefit_by_product(id, patient_product_id)
      this.setState({patientproducts:response}) 
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:'Faltan datos para calcular el beneficio'})
      }
      
    }
    //this.setState({issending:false})
  }

    onEditTreatment = async(data) => {  
      console.log(data)
      const diagnostics = await request_diagnostics(data.product_id)
      const hospitals = await request_hospitals()
      const products = await request_products()

      const type = data.secondary === 0 ? 'primary' : 'secondary'

      this.setState({
        selected_patientproduct:data,
        patientproduct_type:type,
        diagnostics:diagnostics,
        hospitals:hospitals,
        products:products})
      this.onOpenModal('edit_product')
    }

    onSubmitProduct = async(data) => {
      const {id} = this.props.match.params
      const {id_patient_product, benefit_benefit, benefit_uses} = this.state.selected_patientproduct
      const type = 'edit_product'  
            
      try {
        this.onEditModal(type,{issending:true})
        if(data.expected_uses){
          if(benefit_benefit && benefit_uses){
            const isApplicable = benefit_benefit + benefit_uses <= data.expected_uses
            data = {...data, applicable: isApplicable ? 1 : 0}
          }else{
            data = {...data, applicable: 0}
          }
        } 
        data = {...data, adjuvant: data.adjuvant === 1 ? 1 : 0}
        let response = await request_edit_patient_product(id,id_patient_product,data)
        this.setState({patientproducts:response}) 
        this.onCloseModal(type)
      } catch (error) {
        this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:'Faltan datos para calcular el beneficio'})
        }
      }
    }

    onDeleteProduct = async(data) => {
      const {id} = this.props.match.params
      const {id_patient_product} = this.state.selected_patientproduct
      const type = 'edit_product'
      try {
        this.onEditModal(type,{issending:true})
        let response = await request_delete_patient_product(id,id_patient_product)
        this.setState({patientproducts:response}) 
        this.onCloseModal(type)
      } catch (error) {
        this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:error.response.data.error.message})
        }
      }
    }


    onRequestDiagnostic = async(id) => {
      this.setState({issending:true})
      try {
        let response = await request_diagnostics(id)
        await this.setState({diagnostics:[...response]})
      } catch (error) {
      }
      this.setState({issending:false})
    }


  /////////////////////////// TREATMENT MANAGEMENT ////////////////////////////

  onPreFinishTreatment = (data) => {
    console.log("Abri modal para termianr")
    this.setState({selected_product:data})
    this.onOpenModal('finish_product_modal')
  }

  onFinishTreatment = async() => {
    const {id} = this.props.match.params
    const {id_patient_product} = this.state.selected_product
    let data2send = {product_status_id:2}
    const type = 'finish_product_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = await request_edit_patient_product(id,id_patient_product,data2send)
      this.setState({patientproducts:response}) 
      this.onCloseModal(type)
    }catch(error){
      console.log(error.response)
      this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:error.response.data.error.message})
        }
    }
  }


  onStartTreatment = async(data) => {
    this.setState({issending:true})
    let data2send = {
      patient_product_id:data.id_patient_product,
      paid:data.benefit_uses,
      free:data.benefit_benefit
    }
    try{
      const {id} = this.props.match.params
      let response
      response = await request_create_cycle(id, data2send)
      this.setState({patientproducts:response}) 
    }catch(e){
      console.log(e)
    }
    this.setState({issending:false})
  }

  onPreManualChange = (data) => {
    let temp = {
      benefit_id:data.benefit_id
    }
    this.setState({aux_data:{...temp}, selected_product: data})
    this.onOpenModal('edit_benefit')
    
  }

  onManualChange = async(data) => {
    const {id} = this.props.match.params
    const {id_patient_product, expected_uses} = this.state.selected_product
    const {catalogs} = this.props

    const type = 'edit_benefit'

    if(JSON.stringify(data) !== '{}'){
      const current_benefit = catalogs.benefits.find(el => el.value === data.benefit_id)
      let aux = current_benefit.label.split("+");
      const total = parseInt(aux[0])+parseInt(aux[1])
      const available = expected_uses >= total ? 1 : 0
      let data2send = {
        benefit_id: data.benefit_id,
        applicable: available
      }
  
      this.setState({issending:true})
      try {
        this.onEditModal(type,{issending:true})
        let response = await request_change_benefit(id, data2send, id_patient_product)
        this.setState({patientproducts:response})    
        this.onCloseModal(type)
        //this.onActivateSuccessSnack()
      } catch (error) {
        this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:error.response.data.error.message})
        }
      }
      this.setState({issending:false})
    }

    
  }

  /////////////////////////// CYCLE MANAGEMENT ////////////////////////////

  onPreAddPreviousCycle = (patient_product) => {
    this.setState({selected_patientproduct:patient_product})
    this.onOpenModal('add_previous_cycle_modal')
  }

  onAddPreviousCycle = async(data) => {
    const {id} = this.props.match.params
    const {id_patient_product} = this.state.selected_patientproduct
    const type = 'add_previous_cycle_modal'

    const data2send = {
      uses: parseInt(data.uses)+1,
      previous:1,
      patient_product_id: id_patient_product
    }

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_previous_cycle(id,data2send)
      this.setState({patientproducts:response})    
      this.onEditModal(type,{open:false, issending:false, error:null})
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
  }

  onRecalculateCycles = async() => {
    const {id} = this.props.match.params
    try {
      let response = await request_update_all_cycles(id)
      this.setState({patientproducts:response})    
    } catch (error) {
      if(error.response){
        console.log(error.response)
      }
      
    }
  }

  onPreEditPreCycle = (cycle) => {
    console.log(cycle)
    this.setState({selected_precycle:cycle})
    this.onOpenModal('edit_previous_cycle_modal')
  }

  onEditPreviousCycle = async(data) => {
    const {id} = this.props.match.params
    const {id_cycle} = this.state.selected_precycle
    const type = 'edit_previous_cycle_modal'
    let data2send = {uses: data.uses ? parseInt(data.uses)+1 : undefined}
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_edit_previous_cycle(id,id_cycle, data2send)
      this.setState({patientproducts:response})    
      this.onEditModal(type,{open:false, issending:false, error:null})
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  onStartNextCycle = async(data) => {
    this.setState({issending:true})
    let data2send = {
      patient_product_id:data.id_patient_product,
      paid:data.benefit_uses,
      free:data.benefit_benefit
    }
    try{
      const {id} = this.props.match.params
      let response
      response = await request_create_cycle(id, data2send)
      this.setState({patientproducts:response})      
    }catch(e){
      console.log(e)
    }
    this.setState({issending:false})
  }

  onDeleteCycles = async(cycle) => {
    this.setState({issending:true})
    try{
      const {id} = this.props.match.params
      const {id_cycle} = cycle
      const response = await request_delete_cycle(id, id_cycle)
      this.setState({patientproducts:response})      
    }catch(e){
    }
    this.setState({issending:false})
  }

  ///////////////////////////  CHANGE USE DATE PROCESS ////////////////////////////

  /* onChangeUseDate = async(use) => {

    try {
      const hospitals = await request_hospitals()
      console.log('Editr fecha de uso')
      this.setState({selected_use:use, hospitals:hospitals})
      this.onOpenModal('edit_uses_date')
    } catch (error) {
      console.log(error)
    }
    
  } */

  onChangeUseDate = async(use) => {

    try {
      /*
      const {patient} = this.state
      const diagnostics = await request_diagnostics(data.product_id)
      const hospitals = await request_hospitals()
      const product_params = {product_type_id: patient.product_type_id}
      const products = await request_products(product_params)
      */
     console.log('ES AQUI!!!')
      const {patient} = this.state
      const hospitals = await request_hospitals()
      const product_params = {product_type_id: patient.product_type_id}
      const products = await request_products(product_params)
      console.log('Editr fecha de uso')
      this.setState({selected_use:use, hospitals:hospitals, products:products})
      this.onOpenModal('edit_uses_date')
    } catch (error) {
      console.log(error)
    }
    
  }

  ///////////////////////////  BENEFIT STATUS PROCESS ////////////////////////////

  onChangeBenefitStatus =(data) => {
    this.setState({selected_use:data})
    this.onOpenModal('edit_benefit_status')
  }

  ///////////////////////////  USES MANAGMENT ////////////////////////////

  onRegisterUse = async(iduse, status) => {
    try {
      const {id} = this.props.match.params
      const data2send = {applied: status ? 1 : 0}
      let response = await request_update_use(id, iduse, data2send)
      this.setState({patientproducts:response})      
    } catch (error) {
      if(error.response){
        console.log(error.response)
      }
    }
  }

  onUpdateUseDate = async(data, type) => {
    const {id} = this.props.match.params
    const {id_use} = this.state.selected_use
    this.setState({issending:true})
    let data2send = {...data}
    try {
      this.onEditModal(type,{issending:true})
      const response = await request_update_use(id, id_use, data2send)
      this.setState({patientproducts:response})      
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onDeleteUseDate = async() => {
    const {id} = this.props.match.params
    const {id_use} = this.state.selected_use
    const type = 'edit_uses_date'
    let data2send = {date:"@null!!"}
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_update_use(id, id_use, data2send)
      this.setState({patientproducts:response})      
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  ///////////////////////////  PROVIDER LETTER MANAGEMENT ////////////////////////////

  onDownloadProviderLetter = async(cycle) => {
    try {
      const {patient} = this.props
      const userType = getUserType()
      if(userType < USER_TYPE.PRA){
        const hospitals = await request_hospitals()
        const patient_providers = await request_patient_providers(patient.id_patient)
        this.setState({hospitals:hospitals, patient_providers:patient_providers, selected_cycle:cycle})
        this.onOpenModal('edit_provider_letter')
      }else{
        await request_download_provider_letter(cycle.id_cycle)
      }
    } catch (error) {
      console.log(error)
    }
  }

  onFinalDownloadProviderLetter = async(data) => {
    const {match} = this.props
    const {selected_cycle} = this.state
    const {id} = match.params
    const {id_cycle} = selected_cycle
    
    const type = 'edit_provider_letter'
    try{
      this.onEditModal(type,{issending:true})
      if(data){
        let data2send
        if(JSON.stringify(data) !== "{}"){
          if(data.alternative_hospital_id){ data2send = {"alternative_hospital":1,...data}
          }else{data2send = {"alternative_hospital":2,...data}}
        }else{data2send = {"alternative_hospital":0,...data}}
        let response = await request_update_cycle(id, id_cycle, data2send)
        this.setState({patientproducts:response})
        await request_download_provider_letter(id_cycle)
        this.onCloseModal(type)
      }
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }
    }
  }

    ///////////////////////////  FILTER PATIENT PRODUCTS ////////////////////////////


  getPatientProduct = (patientproducts, type) => {
    let result = null
    if(patientproducts && patientproducts.length){
      if(type === 'primary') result = patientproducts.find(el => el.product_status_id === 1 && el.secondary === 0)
      if(type === 'secondary') result = patientproducts.find(el => el.secondary === 1  && el.secondary === 1)
    }
    return result
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}


const styles = theme => ({
  root:{
    padding:32,
    [theme.breakpoints.down('sm')]: {
      padding:16,
    },
  }
})

export default withStyles(styles)(PatientProductModal)