import React from 'react';
import { makeStyles, Grid, Icon } from '@material-ui/core';
import ContentText from '../../Texts/ContentText';

const Indicators = ({ data, colors, key_name }) => {
	const classes = useStyles();

	return (
		<Grid className={classes.root} container spacing={1} alignItems='center' justifyContent='center'>
			{data.map((item, i) => (
				<Grid item container spacing={1} xs={12} key={i} alignItems='center'>
					<Grid item>
						<Icon className={classes.icon} style={{ color: colors[i], fontSize: 14 }}>
							stop
						</Icon>
					</Grid>
					<Grid item xs>
						<ContentText medium text={item[key_name]} variant='bold' />
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
}));

export default Indicators;
