export const contentData = {
  spanish:{
    layout:{
      title:'Editar Doctor',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Doctores',path:'/catalogs/doctors'},
        {name:'Detalles',path:'/catalogs/doctors/:id'},
        {name:'Editar',path:'/catalogs/doctors/:id/edit'}
        
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
  }
}