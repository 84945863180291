import React, { Component } from 'react';
import { withStyles,  Grid } from '@material-ui/core';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import {contentData} from './content'
import {formData} from './data'
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect';
import InputText from '../../../../../components/Inputs/InputText/InputText';
import moment from 'moment';

class EditPassworModal extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData))
  }

  render(){

    const {onClose, data, content} = this.props
    const {formData} = this.state

    let formContent = null
    formContent = Object.keys(formData).map((item,key)=>{
      let inputContent = null
      switch (formData[item].config.type) {
        case 'select':
          inputContent = (
            <InputSimpleSelect 
              data={formData[item]}
              onChange={this.onInputChange}/>
          )
          break;
        
        default:
          inputContent = (
            <InputText data={formData[item]} onChange={this.onInputChange}/>
          )
          break;
      }
      return(
        <Grid item xs={12} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })

    return(
      <SimpleModal
        data={data}
        onClose={onClose}
        content={content}
        onClick={this.onSubmit}
      >
        <Grid container spacing={2}>
          {formContent}
        </Grid>
      </SimpleModal>
        
    )
  }

  onSubmit = () => {
    const {onSubmit, user} = this.props;
    const {formData} = this.state;
    let temp = {...formData}

    let isFormGood = true
    Object.keys(formData).forEach(item => {
      if(temp[item].isRequired){
        if(!temp[item].isValid){
          isFormGood = false
          temp[item].isVisited = true
        }
      }
    })

    if(isFormGood){
      let data2Send = {};
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(user){
            if(formData[item].value !== user[item]){
              data2Send = {...data2Send, [item]:formData[item].value}
            }
          }else{
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
      data2Send = {...data2Send, timestamp:moment().format()}
      onSubmit(data2Send)
    }else{
      this.setState({formData:{...temp}})
    }
  }

  componentDidMount(){
    const {language} = this.props
    const {formData} = this.state
    const content = contentData[language]
    let temp = {...formData}

    Object.keys(formData).forEach((item) => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    })
    this.setState({formData:{...temp}})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

const styles = theme => ({
  root:{
    padding:16,
    boxSizing:'border-box',
    width:400,
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    },
  }
})

export default withStyles(styles)(EditPassworModal)