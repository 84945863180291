import React from 'react'
import { Redirect } from 'react-router-dom'
import { Router, Route, Switch } from 'react-router-dom'
import SignIn from '../layouts/Login/login.jsx'
import DashboardAdmin from '../layouts/DashboardAdmin/DashboardAdmin.jsx'


const PublicRouters = (props) => {
  return(
    <Router history={props.history}>
        <Switch>
          <Route
            path={'/signin'}
            component={SignIn}
          />
          <Route
            path={'/'}
            component={DashboardAdmin}
          />
          <Redirect to={'/signin'}/>
        </Switch>
    </Router>
  );
}

export default PublicRouters
