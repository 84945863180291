export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    add_button:'Agregar',
    title:{
      sectionA:'Información Básica',
      sectionB:'Información Detallada',
      sectionC:'Detalles',
    },
    form:{
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)',
      },
      last_name:{
        label:'Apellido(s)',
        placeholder:'Apellido(s)',
      },
      username:{
        label:'Usuario',
        placeholder:'Usuario',
      },
      email:{
        label:'E-mail',
        placeholder:'E-mail',
        helper:'E-mail no válido'
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
      },
      password_confirmation:{
        label:'Confirmar Contraseña',
        placeholder:'Confirmar Contraseña',
        helper:'La contraseña no coincide'
      },
      user_type_id:{
        label:'Tipo de usuario',
        placeholder:'Tipo de usuario',
      },
      agency_id:{
        label:'Agencia',
        placeholder:'Agencia',
      },
      hospital_id:{
        label:'Hospital',
        placeholder:'Seleccione un hospital',
      },
      gender_id:{
        label:'Género',
        placeholder:'Género',
      },
      timezone:{
        label:'Zona Horaria',
        placeholder:'Zona Horaria',
      },
    },
  },
  
}