import { Chip, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import React, { useState } from 'react';
import ExpandableButton from '../../Patients/Patients/components/ExpandableButton';
import DateFilter from './DateFilter';
import FilterModal from './FilterModal';
import GenericFilter from './GenericFilter';

const AnalyticToolbar = ({
    expandable, onUpdateExpandableView, filter, onUpdateFilter,catalogs, noexp, filter_disabled, hideExpandable
}) => {

    const classes = useStyles()

    const [filter_modal, setFilterModal] = useState(false)

    const onDateChange = (data) => {
        let _filter = {...filter, ...data}
        onUpdateFilter(_filter)
    }

    const onDeleteFilterEl = (key) => {
        let _filter = {...filter}
        _filter[key] = null
        onUpdateFilter(_filter)
    }

    const onStepChange = (data, id) => {
        let temp = {...filter}
        temp[id] = data
        onUpdateFilter(temp)
    }

    return ( 
        <div className={classes.root}>
            <FilterModal open={filter_modal} onClose={() => setFilterModal(false)}
                catalogs={catalogs}
                filter={filter}
                onUpdateFilter={onUpdateFilter}
                filter_disabled={filter_disabled}
            />
            <div>
                <Grid container spacing={2}>

                    {!hideExpandable &&
                        <Grid item>
                            <ExpandableButton checked={expandable} onChange={() => onUpdateExpandableView(!expandable)} />
                        </Grid>
                    }
                    <Grid item xs>
                        <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
                            <Grid item>
                                <Typography style={{ color: blue[900] }}>Del</Typography>
                            </Grid>
                            <Grid item>
                                <DateFilter id='start_date' value={filter.start_date} onChange={onDateChange}/>
                            </Grid>
                            <Grid item>
                                <Typography style={{ color: blue[900] }}>al</Typography>
                            </Grid>
                            <Grid item>
                                <DateFilter id='end_date' value={filter.end_date} onChange={onDateChange}/>
                            </Grid>
                            {
                                filter.step && 
                                <>
                                    <Grid item>
                                        <Typography style={{ color: blue[900] }}>ver</Typography>
                                    </Grid>
                                    <Grid item>
                                        <GenericFilter id='step' value={filter.step?.value} onChangeFilter={onStepChange} filters={catalogs.steps}/>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton className={classes.iconbutton} onClick={() => setFilterModal(true)}>
                            <Icon>filter_list</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid container spacing={2}>
                    {Object.keys(filter).map((key, id) => {

                        const _data = filter[key]

                        if(!_data || key === 'start_date' || key === 'end_date' || key === 'step') return

                        return(
                            <Grid item>
                                <Chip label={_data?.label} onDelete={() => onDeleteFilterEl(key)}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        margin:'16px 0px'
    },
    iconbutton:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.main,
        }
    }
}))
 
export default AnalyticToolbar;