import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from '@material-ui/core'
import Subtitle from '../../../../../../../components/Texts/Subtitle'
import { ErrorMessage } from '../../../../../../../components/DisplayData/DisplayData'
import SimpleLoader from '../../../../../../../components/Loaders/SimpleLoader'
import SimpleFile from '../../../../../../../components/Files/SimpleFile/SimpleFile'

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:30
    },
    button:{
        borderRadius:32,
        textTransform:'none'
    }
}))

const UploadDocumentModal = props => {

    const classes = useStyles()
    
    //////////////////////////// MULTIPLE STATES AND PROPS  ///////////////////////////////
    const {onClose, data, onSubmit} = props
    const {issending, error} = data
    const [file, setFile] = useState(null) 


    //////////////////////////// INTERNAL FUNCTIONS  ///////////////////////////////
    const onDocumentAttached = (id, files) => {
        setFile(files[0])
    }

    const onDocumentRemove = (id, files) => {
        setFile(null)
    }

    const onInternalSubmit = () => {
        if(file) onSubmit(file)
    }


    ////////////////////////// RENDER ////////////////////////////////

    let infoContent = null

    if(issending){
        infoContent = <SimpleLoader />
    }else{
        if(error){infoContent = <ErrorMessage message={error}/>}
    }

    return(
        <Dialog
            open={true}
            maxWidth='sm'
            fullWidth
            onClose={onClose}
            classes={{paper:classes.paper}}
        >
            <DialogTitle>
                <Subtitle text='Subir documento' medium variant='bold'/>
            </DialogTitle>
            <DialogContent>
                <SimpleFile 
                    id='generic'
                    file={file}
                    onDocumentAttached={onDocumentAttached}
                    onDocumentRemove={onDocumentRemove}
                />
            </DialogContent>
            <DialogActions>
                {infoContent}
                <div style={{flex:1}}/>
                <Button 
                    className={classes.button} 
                    color='secondary' 
                    variant='text' onClick={onClose}>Cancelar</Button>
                <Button 
                    className={classes.button} 
                    color='secondary' 
                    variant='text' onClick={onInternalSubmit}>Subir</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadDocumentModal