import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Grid,
  Typography,
  OutlinedInput,
  withWidth,
  InputAdornment,
  Icon
} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, grey} from '@material-ui/core/colors';


class InputText extends Component{

  onInnerChange = event => {
    const {onChange, data} = this.props;
    const response = event.target.value;
    let temp = {...data};
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  render(){

    const {
      classes,
      onKeyPress,
      disabled,
    } = this.props;

    const {value, isVisited, isValid} = this.props.data;
    const {type,label,placeholder,fullWidth,icon, multiline, rows, helper} = this.props.data.config;

    const error = isVisited && !isValid;

    let inputaddornment = null;
    if(icon){
      inputaddornment = 
      <InputAdornment position='start'>
        
        <Icon style={{color:blueGrey[500]}}>
          {icon.name}
        </Icon>
      </InputAdornment>
    }

    let inputlabel = null;
    if(label){
      inputlabel = (
        <Grid item>
          <Typography style={{fontSize:'0.9em', fontWeight:'600', color: grey[700], padding:'8px 8px'}}>
            {label}
          </Typography>
        </Grid>
      )
    }

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='column'>
            {inputlabel}
            <Grid item>
                <OutlinedInput
                  margin="dense"
                  variant="outlined"
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  error={error}
                  value={value}
                  onChange={this.onInnerChange}
                  multiline={multiline}
                  rows={rows}
                  type={type}
                  startAdornment={inputaddornment}
                  labelWidth={0}
                  onKeyPress={onKeyPress}
                  inputProps={{
                    spellCheck:"false",
                    autoComplete:"false",
                    name:"hidden"
                  }}
                  classes={classes}
                />
            </Grid>
            <Grid item>
              <Typography variant='caption' className={classes.helper}>
                {error ? helper : ''}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  }
};

export default withStyles(styles)(withWidth()(InputText));
