import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';

import userimage from '../../../../../assets/building.png'
import { contentData } from './content';
import { mainForm } from './data';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';

const HospitalDataCard = (props) => {

  const {data} = props

  return(
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Nivel' text={data.hospital_value_name} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Acreditado' text={data.accredited ? 'Acreditado' : 'No Acreditado'} endSpacing/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <ContentText text='Contacto' variant='bold' medium />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Nombre' text={data.contact_name} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='E-mail' text={data.contact_email} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Teléfono' text={data.contact_phone} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Extensión' text={data.contact_ext} endSpacing/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

class FormStepB extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    formData: JSON.parse(JSON.stringify(mainForm)),
    doctors:[],
    products:[],
    diagnostics:[],
    is_public_insurance:false,
    is_secondary_treatment:false,
  }

  render(){

    const {classes, language, hospitals, primary_hospital, secondary_hospital, is_secondary_treatment, providers} = this.props
    const {formData} = this.state

    const content = contentData[language]
    
    formData.hospital_id.options = [...hospitals]
    formData.secondary_hospital_id.options = [...hospitals]
    formData.provider_id.options = [...providers]
    formData.secondary_provider_id.options = [...providers]

    let primary_hospital_content = null
    let secondary_hospital_content = null

    if(primary_hospital){
      primary_hospital_content = (
        <Grid item xs={12}>
          <HospitalDataCard data={primary_hospital}/>
        </Grid> 
      )
    }

    if(secondary_hospital){
      secondary_hospital_content = (
        <Grid item xs={12}>
          <HospitalDataCard data={secondary_hospital}/>
        </Grid> 
      )
    }

    if(is_secondary_treatment){
      secondary_hospital_content = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText 
                text={content.subtitles.basicInfo} 
                variant='bold'
                medium
              />
              <ContentText 
                text={content.subtitles.secondaryTreatment} 
                medium
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <LineInputSelect 
                    data={formData.secondary_hospital_id}
                    onChange={this.onInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LineInputSelect 
                    data={formData.secondary_provider_id}
                    onChange={this.onInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            {secondary_hospital_content}
          </Grid>
        </Grid>
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt={''} className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                        <ContentText 
                          text={content.subtitles.primaryTreatment} 
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formData.hospital_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formData.provider_id}
                              onChange={this.onInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {primary_hospital_content}
                    </Grid>
                  </Grid>
                  {/* Secondary Hospital View  */}
                  {secondary_hospital_content}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <RoundedButton 
                  label='Regresar'
                  onClick={this.onPreviousStep}
                  color='transparent'
                />
              </Grid>
              <Grid item>
                <RoundedButton 
                  label='Siguiente'
                  onClick={this.onNextStep}
                  color='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        
      </div>
    )
  }

  componentDidMount(){
    const {formData} = this.state
    const {language, patient_form} = this.props
    const content = contentData[language]
    let temp = {...formData}
    ////////************ INIT FORM /////////////*/
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
      temp[item].value = patient_form[item] ? patient_form[item] : ''
      temp[item].isValid = patient_form[item] ? true : false
    });
    this.setState({formData:{...temp}})
    //this.initModule()
  }

  getDoctorsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      let fullname = item.first_name
      fullname += item.second_name ? ' ' + item.second_name : ''  
      fullname += item.first_surname ? ' ' + item.first_surname : ''
      fullname += item.second_surname ? ' ' + item.second_surname : ''
      return {
        id: item.id_doctor,
        value: item.id_doctor,
        label: fullname
      }
    })
    return temp
  }

  getProductsOptions = (data) => {
    let temp = []
    temp = data.map(item => {
      return {
        id: item.id_diagnosis,
        value: item.id_diagnosis,
        label: item.name + ', ' + item.presentation
      }
    })
    return temp
  }

  onNextStep = () => {
    const {formData} = this.state
    const {is_secondary_treatment} = this.props
    let isValid = true
    if(formData.hospital_id.value === null || formData.hospital_id.value === "" || 
    formData.provider_id.value === null || formData.provider_id.value === ""){
      isValid = false
    }
    if(is_secondary_treatment){
      if(formData.secondary_hospital_id.value === null || formData.secondary_hospital_id.value === "" || 
      formData.secondary_provider_id.value === null || formData.secondary_provider_id.value === ""){
        isValid = false
      }
    }

    if(isValid){
      this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),2)
    }
    
  }

  onPreviousStep = () => {
    this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formData)),0)
    //this.props.onChangeStep(0)
  }

  onChangeCheckbox = (id, status) => {
    this.setState({[id]:status})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

    if(id === 'hospital_id'){
      this.props.onRequestHospital(data.value,'principal')
    }else if(id === 'secondary_hospital_id'){
      this.props.onRequestHospital(data.value,'secondary')
    }

  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

export default withStyles(styles)(FormStepB)