import React, { Component } from 'react';
import SimpleContainer from '../../../../../components/Layouts/SimpleContainer';
import { withStyles, Grid, IconButton, Icon } from '@material-ui/core';
import { request_doctors_by_hospital, request_doctors, request_delete_doctor } from '../requests';
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../../components/Texts/ContentText';
import Subtitle from '../../../../../components/Texts/Subtitle';
import SingleDoctor from './components/SingleDoctor';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import AddDoctorModal from './modals/AddDoctorModal/AddDoctorModal';
import { getDoctorsOptions } from '../../../../../shared/customFunctions';
import {constrainst_mainView as constrainst} from  './constraints'
import ContraintLayout from '../../../../../components/Layouts/ConstraintLayout';


class DoctorsView extends Component {

    state = {
        isloading:false,
        error:null,
        doctor_catalogs:[],
        doctors:[],
        add_doctor_modal:false
    }

    

    render(){

        const {isloading, doctors, doctor_catalogs, add_doctor_modal, } = this.state
        const {classes, match} = this.props

        let modalcontent

        let filterDoctors = []
        doctor_catalogs.forEach(item => {
            let findDoctor = doctors.find(el => el.doctor_id === item.id_doctor)
            if(!findDoctor) filterDoctors.push(item)
        })
        filterDoctors = getDoctorsOptions(filterDoctors)

        if(add_doctor_modal){
            modalcontent = (
              <AddDoctorModal
                hospital_id={match.params.id}
                options={filterDoctors}
                onClose={() => this.setState({add_doctor_modal:false})}
                onUpdateData={(data) => this.setState({doctors:data, add_doctor_modal:false})}/>
            )
        }


        let contentView = null

        if(doctors && doctors.length > 0) {
            contentView = (
                <CardWrapper>
                    <div className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item>
                                        <Subtitle text='Lista de doctores' variant='bold' medium/>
                                    </Grid>
                                    <ContraintLayout constrainst={constrainst.add_doctor} auto>
                                        <IconButton size='small' className={classes.addBtn}
                                        onClick={()=>this.setState({add_doctor_modal:true})}><Icon fontSize='small'>add</Icon></IconButton>
                                    </ContraintLayout>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {doctors.map((item,key)=>{
                                        return(
                                        <Grid item xs={12} key={key.toString()}>
                                            <SingleDoctor data={item} onDelete={this.onDeleteDoctor}/>
                                        </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </div>
                </CardWrapper>
                
            ) 
        }else{
            contentView = (
                <CardWrapper>
                    <div className={classes.container}>
                        <Grid container spacing={1} justifyContent='center'>
                            <Grid item xs={12}>
                                <ContentText text='No existen doctores asignados a este hospital' medium align='center'/>
                            </Grid>
                            <ContraintLayout constrainst={constrainst.add_doctor} auto>
                                <RoundedButton label='Agregar doctor' size='small' 
                                onClick={()=>this.setState({add_doctor_modal:true})}/>
                            </ContraintLayout>
                        </Grid>
                        
                    </div>
                </CardWrapper>
            )
        }

        return(
            <SimpleContainer isloading={isloading}> 
            {modalcontent}
                <div>{contentView}</div>
            </SimpleContainer>
        )
    }

    componentDidMount(){
        this.initModule()
    }

    initModule = async() => {
        const {id} = this.props.match.params
        this.setState({isloading:true})
        try {
            const doctors = await request_doctors_by_hospital(id)
            const doctor_catalogs = await request_doctors()
            this.setState({doctors:doctors, doctor_catalogs:doctor_catalogs})
            console.log(doctors)
        } catch (error) {
            console.log(error.response)
        }
        this.setState({isloading:false})

    }

    onDeleteDoctor = async(data) => {
        const {id} = this.props.match.params
        const {id_hospital_doctor} = data
        try {
            const doctors = await request_delete_doctor(id, id_hospital_doctor)
            this.setState({doctors:doctors})
        } catch (error) {
            console.log(error.response)
        }
    }
}

export default withStyles(theme => ({
    root:{padding:16},
    container:{
        padding:32
    },
    addBtn:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    }
}))(DoctorsView)