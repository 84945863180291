export const getDoctorsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    let fullname = item.first_name
    fullname += item.second_name ? ' ' + item.second_name : ''  
    fullname += item.first_surname ? ' ' + item.first_surname : ''
    fullname += item.second_surname ? ' ' + item.second_surname : ''
    return {
      id: item.id_doctor,
      value: item.id_doctor,
      label: fullname
    }
  })
  return temp
}

export const getDoctorsHospitalsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.doctor_id,
      value: item.doctor_id,
      label: item.doctor
    }
  })
  return temp
}

export const getProductsOptions = (data) => {
  let temp = []
  temp = data.map(item => {

    let product_label = `${item.name}, ${item.presentation} ${item.product_type ? `, ${item.product_type}` : '' }`

    return {
      id: item.id_product,
      value: item.id_product,
      label: product_label,
      product_type_id:item.product_type_id
    }
  })
  return temp
} 

export const getDiagnosticsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_diagnosis,
      value: item.id_diagnosis,
      label: item.name
    }
  })
  return temp
} 

export const getPublicInsurancesOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_public_insurance,
      value: item.id_public_insurance,
      label: item.name
    }
  })
  return temp
}

export const getHospitalsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_hospital,
      value: item.id_hospital,
      label: item.name
    }
  })
  return temp
}

export const getInsuranceCarriersOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_insurance_carrier,
      value: item.id_insurance_carrier,
      label: item.name
    }
  })
  return temp
}

export const getUserProgress = (state) => {
  let documents_elements = ['privacy_policy','identity_document','proof_of_address','medical_report', 'insurance_policy']
  let main_data_elements = ['name','email','phone','doctor_id','zip']
  let first_treatment_elements = ['product_id','diagnosis_id', 'hospital_id', 'expected_uses']
  let second_treament_elements = ['secondary_product_id','secondary_diagnosis_id', 'secondary_hospital_id', 'secondary_expected_uses']
  let main_hospital_elements = ['main_hospital_id']

  const {patient_form} = state


  let total = documents_elements.length + 
              main_data_elements.length

  const document_count = onValidateData(state, documents_elements, [])
  const main_data_count = onValidateData(state, [], main_data_elements)

  const subtotal = document_count + main_data_count

  return parseInt((subtotal/total)*100)
}

const onValidateData = (state, options, formOptions) => {
  let count = 0
  options.forEach(item => {
    if(state[item]){
      count++;
    }
  })
  formOptions.forEach(item => {
    if(state.formData[item].isValid){
      count++;
    }
  })

  return count
}

