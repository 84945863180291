import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';

const styles = (theme) => ({
  root:{padding:'32px'}
})



class EditBasicInfo extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData.sectionA))
  }

  updateData2Form = (form, data) => {
    let temp = {...form}
    Object.keys(form).forEach(item => {
      if(data){
        let value = data[item];
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        temp[item].value = value!== undefined && value!==null ? value : '';
        temp[item].isValid = value!== undefined && value!==null ? true : false;
      }
    });
    return temp
  }

  componentDidMount(){
    const {insurance_carrier, language} = this.props;
    const {formData} = this.state;
    const content = contentData[language];
    let temp = {...formData};
    
    Object.keys(formData).forEach(item => {
      if(insurance_carrier){
        let value = insurance_carrier[item];
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        temp[item].value = value!== undefined && value!==null ? value : '';
        temp[item].isValid = value!== undefined && value!==null ? true : false;
      }
      temp[item].config = {...temp[item].config,...content.sectionA[item]}
    })
    
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {formData, isValid} = this.state;
    let data2Send = {};
    
    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid && formData[item].value !== ""){
        data2Send = {...data2Send, [item]:formData[item].value}
      }
    })

    if(isValid){
      onSubmit(data2Send)
    }

    
  }

  render(){

    const {classes, language, issending, error, onMergeCatalog} = this.props
    const {formData} = this.state
    const content = contentData[language]

    let contentA = null
    //let tempForm = this.updateData2Form(formData, insurance_carrier)

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      switch (formData[item].config.type) {
        case 'select':
          inputContent = (
            <LineInputSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <LineInputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })

    let loaderContent = null
    if(issending){
      loaderContent = <SimpleLoader />
    }else{
      if(error){
        loaderContent = <ErrorMessage message={error}/>
      }
    }
    
    

    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {contentA}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  {loaderContent}
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={'Combinar'} 
                    size='small' 
                    color='primary'
                    onClick={onMergeCatalog}
                  />
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={content.save_button} 
                    size='small' 
                    color='secondary'
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }
}

export default withStyles(styles)(EditBasicInfo)