import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import InputText from '../Inputs/InputText/InputText'
import LineInputSelect from '../Inputs/LineInputSelect/LineInputSelect'

import SimpleModal from './SimpleModal'

const prepareData = (users) => {
  let usersData = [];

  if(users){
    usersData = users.map((item) => {
      let firstname = item.first_name ? item.first_name : '';
      let lastname = item.last_name ? item.last_name : ''; 
      return{
        value: item.id_user,
        label: firstname + ' ' + lastname,
        image: item.image
      }
    })
  }

  return usersData;
}

class FormAModal extends Component {

  state = {
    formData: {},
    isValid:false,
  }

  componentDidMount(){
    const {origin_data, catalogs, catalog_matrix, content, form_data} = this.props
    let temp = JSON.parse(JSON.stringify(form_data))
    if(catalog_matrix){
      catalog_matrix.forEach(item => {
        if(item.catalog_key !== 'none'){
          temp[item.form_key].options = catalogs[item.catalog_key]
        }else{
          const aux_catalog = prepareData(this.props[item.propname])
          temp[item.form_key].options = aux_catalog
        }
        
      })
    }
    Object.keys(form_data).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
      if(origin_data){ 
        let value = origin_data[item]
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null
        }
        if(temp[item].config.type === 'time'){
          value = value ? value : null
          value = value === 'Invalid date' ? null : value
        }
        temp[item].value = value ? value : ''
        temp[item].isValid = value ? true : false
      }else{ // When initialization is not necessary
        temp[item].value = temp[item].value ? temp[item].value : '' 
        temp[item].isValid = false
      }
    });

    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, origin_data} = this.props;
    const {formData} = this.state;
    let data2Send = {};

    if(origin_data){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(formData[item].value !== origin_data[item]){
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
    }else{
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
    }
    
    onSubmit(data2Send)
  }

  render(){

    const {data, content, onClose, addSpace, maxWidth} = this.props
    const {formData} = this.state

    let contentA = null

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      
      switch (formData[item].config.type) {
        
        case 'select':
          inputContent = (
            <LineInputSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <InputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={formData[item].config.md ? formData[item].config.md : 6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })

    let spaceContent = null

    if(addSpace){
      spaceContent = <Grid item xs={12} style={{height:'200px'}}/>
    }
    
    return(
      <SimpleModal
        content={content}
        onSubmit={this.onSubmit}
        data={data}
        onClose={onClose}
        onClick={this.onSubmit}
        maxWidth={maxWidth}
      >
          <Grid container spacing={3}>
            {contentA}
            {spaceContent}
          </Grid>
      </SimpleModal>
    )
  }
}

export default FormAModal