import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import usePatientLettersView from './usePatientLettersView';
import { getUserType } from '../../../../../../shared/utility';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import PatientLetterList from '../../components/PatientLetterList';
import ErrorInfoModal from '../../../../../../components/Modals/ErrorInfoModal';
import ErrorInfoModalV2 from '../../../../../../components/Modals/ErrorInfoModalV2';
import PatientLetterModal from '../../Modals/GenericPatientLetterModal/modals/PatientLetterModal'
import DeleteGenericLetterModal from '../../Modals/GenericPatientLetterModal/modals/DeleteGenericLetterModal'
import { USER_TYPE } from '../../../../../../variables/config';

const PatientLettersView = () => {

    const classes = useStyles()

    const {actions, system, patientLetterManager, modals, data} = usePatientLettersView()
    const {patient_letters, patient_letter} = patientLetterManager.data

    const {patient, document} = data
    const userType = getUserType()

    let addMedicalReportButton = null
    
    const type = document?.letter_type_id

    return ( 
        <div className={classes.root}>
            <ErrorInfoModalV2 open={Boolean(system.error)}  onClose={actions.onResetError} 
            data={system.error}/>
            <PatientLetterModal
                open={modals.edit_letter} origin={document}
                mode='edit' type={type}
                onRefreshData={
                    () => patientLetterManager.actions.onGetAll(patient?.id_patient, null, null)
                }
                onUpdateData={patientLetterManager.actions.onEdit} 
                onClose={() => actions.onChangeModalStatus('edit_letter', false)}
                />
            <DeleteGenericLetterModal
                open={modals.delete_letter} origin={document}
                patient={patient} 
                onRefreshData={ () => 
                    patientLetterManager.actions.onGetAll(patient?.id_patient, null, null)
                }
                onDeleteData={patientLetterManager.actions.onDelete} 
                onClose={() => actions.onChangeModalStatus('delete_letter', false)}
                />
            <div>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        <Typography variant='h6'>
                            Cartas
                        </Typography>
                        
                    </Grid>
                    {addMedicalReportButton}
                </Grid>
            </div>
            
            <div style={{marginTop:16}}>
                <CardWrapper>
                    <SimpleContainer loading={system.loading}>
                        <div>
                            <PatientLetterList letters={patient_letters} noedit={userType === USER_TYPE.PRA}
                            sending={system.sending}
                            onDownloadLetter={actions.onDownloadLetter}
                            onEdit={actions.onEditLetter}
                            onDelete={actions.onDeleteLetter}/>
                        </div>
                    </SimpleContainer>
                </CardWrapper>
                
                
            </div>
            

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    addIcon:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
          background:theme.palette.primary.dark,
        }
    }
}))
 
export default PatientLettersView;