export const contentData = {
  spanish:{
    subtitles:{
      basicInfo:'Hospital de consulta',
      primaryTreatment:'Seleccione el hospital o clínica de consulta',
      secondaryTreatment:'Tratamiento Adyuvante'
    },
    form:{
      hospital_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      secondary_hospital_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      provider_id:{
        label:'Distribuidor',
        placeholder:'Seleccione una opción',
      },
      secondary_provider_id:{
        label:'Distribuidor',
        placeholder:'Seleccione una opción',
      },
    }
  }
}