import React, { Component } from 'react'
import ContentText from './ContentText';
import { Grid, Icon, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { nodata } from '../../variables/texts';

const styles = theme => ({
  button:{
    paddingLeft:2,
    paddingRight:2,
    borderRadius:16,
    '&:hover':{
      background:grey[100],
      cursor:'pointer',
    }
  }
})

class HorizontalDataWrapper extends Component {

  render(){
    const {classes, label, text, disableEndSpacing, icon, iconColor, iconSize, onClick} = this.props
    let endSpacingContent = <Grid item xs={1}/>
    if(disableEndSpacing){
      endSpacingContent = null
    }
  
    let iconContent = null
    if(icon){
      iconContent = (
        <Grid item>
          <Icon fontSize='small' style={{color:iconColor, paddingTop:4, fontSize: iconSize}}>{icon}</Icon>
        </Grid>
      )
    }
  
    let labelContent = null
    if(onClick){
      labelContent = (
          <Grid container>
            <Grid item xs/>
            <Grid item>
              <div className={classes.button} onClick={onClick}>
                <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
                  {iconContent}
                  <Grid item>
                    <ContentText text={text !== null && text !== undefined && text !== '' ? text : nodata} medium align='right'/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
      )
    }else{
      labelContent=(
        <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
          {iconContent}
          <Grid item>
            <ContentText text={text !== null && text !== undefined && text !== '' ? text : nodata} medium align='right'/>
          </Grid>
        </Grid>
      )
    }
  
    return(
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={12} md={4}>
          <ContentText text={label ? label : ''} variant='light' medium/>
        </Grid>
        <Grid item xs={12} md={8} >
          {labelContent}
        </Grid>
        {endSpacingContent}
      </Grid>
    )
  }

  
}

export default withStyles(styles)(HorizontalDataWrapper)