import {mainServer} from '../../../../../variables/config'

export const request_benefits = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.post("/patient/prebenefit",data)
  return response.data.data
}

export const request_doctors = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/all")
  return response.data.data.doctors
}

export const request_products = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/patientproduct/products")
  return response.data.data.products
}

export const request_diagnostics = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(
    "/patientproduct/products", 
    {params:{
      productid:id
    }}
  )
  return response.data.data.products
}
