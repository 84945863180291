import React from  'react'
import { Grid } from '@material-ui/core'
import PatientProductDetails from './PatientProductDetails'
import BenefitComponent from './BenefitComponent'
import { isTreatmentApplicable, onGetBenefitVariant, onGetPatientProductData, onGetVialInformation } from '../../../../../../../shared/utility'
import { catalogs } from '../../../../../../../variables/catalogs'

const dummy = {
    "id_benefit": 8,
    "name": "1,5",
    "min_value": 999,
    "max_value": 1000,
    "uses": 10,
    "benefit": 2,
    "percentage": "0.17000",
    "benefit_type_id": 2,
    "benefit_type": "Anual"
}

const PatientProduct = props => {


    const {data, number, products, hospitals, benefit} = props

    const {diagnostics, schemes} = data
    
    //console.log(patientproduct)
    //console.log(benefit)
    console.log(data)
    const patientproduct = onGetPatientProductData(data.form, products, hospitals, diagnostics, catalogs.spanish.treatment_frequencies, schemes)
    const patientvials = data.vials.map(item => onGetVialInformation(item))

    let isApplicable = isTreatmentApplicable(patientproduct, data?.prebenefit)
    let benefitType = onGetBenefitVariant(patientproduct)

    return(
        <div>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <PatientProductDetails 
                        number={number} 
                        product={patientproduct}
                        vials={patientvials}
                        isApplicable={isApplicable}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BenefitComponent data={data?.prebenefit} />
                </Grid>
            </Grid>
        </div>
    )
}

export default PatientProduct