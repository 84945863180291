import React, { useCallback, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import userimage from '../../../../../assets/building.png'
import { contentData } from './content';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';
import SimpleFile from '../../../../../components/Files/SimpleFile/SimpleFile';
import { getUserType } from '../../../../../shared/utility';
import { USER_TYPE } from '../../../../../variables/config';
import { usePlatformContext } from '../../../../../context/PlatformContext';
import useOnboardingContext from '../../../../../providers/OnboardingProvider/useOnboardingContext';
import OnboardingActionBar from '../components/OnboardingActionBar';
import useHospital from '../../../../../hooks/api/useHospital';

const HospitalDataCard = (props) => {

  const {data } = props

  return(
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Nivel' text={data.hospital_value_name} endSpacing  />
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Acreditado' text={data.accredited ? 'Acreditado' : 'No Acreditado'} endSpacing/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <ContentText text='Contacto' variant='bold' medium />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Nombre' text={data.contact_name} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='E-mail' text={data.contact_email} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Teléfono' text={data.contact_phone} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Extensión' text={data.contact_ext} endSpacing/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const FormStepB = () => {

    const classes = useStyles()
    const hospitalManager = useHospital()
    const {language} = usePlatformContext() 
    const {
      step, 
      main_hospital,
      setStep,
      formManager,
      medical_report,
      setMedicalReport,
      hospitals,
      setMainHospital,
    } = useOnboardingContext()

    const {form, onChangeForm} = formManager
    const content = contentData[language]

    let main_hospital_content = null

    const userType = getUserType()

    const onSubmit = useCallback(() => {
      setStep(step + 1)
    }, [])

    const onSearchHospitalData = useCallback(async(hospital_id) => {
      let _hospitaldata = null
      try {
        const _data = await hospitalManager.onGetByID(hospital_id)        
        _hospitaldata = _data
        
      } catch (error) {
        console.log(error)
      }
      setMainHospital(_hospitaldata)
    }, [form])

    useEffect(() => {
      if(form.main_hospital_id.value){
        console.log("Buscar info del hospital")
        onSearchHospitalData(form.main_hospital_id.value)
      }
    }, [form.main_hospital_id])



    if(main_hospital){
      main_hospital_content = (
        <Grid item xs={12}>
          <div style={{marginBottom:32}}>
            <HospitalDataCard data={main_hospital}/>
          </div>
          
        </Grid> 
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt={''} className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                        <ContentText 
                          text={content.subtitles.primaryTreatment} 
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={form.main_hospital_id}
                              onChange={onChangeForm}
                              catalogs={hospitals}
                              disabled={userType === USER_TYPE.HOSPITAL}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {main_hospital_content}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{marginTop:16}}>
                        <SimpleFile 
                          id='medical_report'
                          label='Informe médico'
                          file={medical_report}
                          onDocumentAttached={(_, files) => setMedicalReport(files[0])}
                          onDocumentRemove={() => setMedicalReport(null)}/>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        <OnboardingActionBar
          onReturn={() => setStep(step-1)}
          onSubmit={onSubmit}
        />
      </div>
    )
  

}

const useStyles = makeStyles({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:120,
    height:120,
  }
})

export default FormStepB