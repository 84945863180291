import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { LETTER_TYPES } from '../../../shared/enums';
import GenericPatientLetterModal from '../../../views/Admin/Applicants/ApplicantView/Modals/GenericPatientLetterModal/GenericPatientLetterModal';
import MedicalReportListModal from '../../../views/Admin/Applicants/ApplicantView/Modals/MedicalReports/MedicalReportListModal/MedicalReportListModal';

const PatientProductActions = (props) => {

    const {patientproduct, patient, noedit} = props
    const [open, setOpen] = useState(false)
    const [modals, setModals] = useState({
        medical_report:false,
        patient:false,
        doctor:false,
    })
    const btnRef = useRef()

    const onOpenModal = (type) => {
        let _modals = {...modals}
        _modals[type] = true
        setModals(_modals)
        setOpen(false)
    }

    const onCloseModal = (type) => {
        let _modals = {...modals}
        _modals[type] = false
        setModals(_modals)
    }

    return ( 
        <div>
            <MedicalReportListModal
            open={modals.medical_report} patient={patient} patientproduct={patientproduct}
            onClose={() => onCloseModal('medical_report')} noedit={noedit}
            />
            <GenericPatientLetterModal
                type={LETTER_TYPES.INIT_PATIENT}
                open={modals.patient} patient={patient} patientproduct={patientproduct}
                onClose={() => onCloseModal('patient')} noedit={noedit}
            />
            <GenericPatientLetterModal
                type={LETTER_TYPES.INIT_DOCTOR}
                open={modals.doctor} patient={patient} patientproduct={patientproduct}
                onClose={() => onCloseModal('doctor')} noedit={noedit}
            />
            <div style={{paddingRight:28}}>
                <IconButton onClick={() => setOpen(true)} ref={btnRef}>
                    <Icon>
                        more_vert
                    </Icon>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={btnRef.current}
                    keepMounted
                    open={open}
                    onClose={() => setOpen(false)}
                    >
                    <MenuItem onClick={()=>onOpenModal('medical_report')}>Reportes Médicos</MenuItem>
                    <MenuItem onClick={()=>onOpenModal('patient')}>Carta Paciente</MenuItem>
                    <MenuItem onClick={()=>onOpenModal('doctor')}>Carta Doctor</MenuItem>
                </Menu>
            </div>
        </div>
     );
}
 
export default PatientProductActions;