import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';
import userimage from '../../../../../assets/patient.png'
import { contentData } from './content';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';
import NSEContainer from '../NSEContainer/NSEContainer';

class FormStepA extends Component {

  render(){

    const {classes,  language, nse, is_public_insurance,
      is_secondary_treatment, user_level, input_data, onInputChange, onChangeCheckbox} = this.props

    const content = contentData[language]

    //console.log(input_data)

    let formDataTemp = {...input_data};
    //console.log(formDataTemp)

    let publicInsuranceContent = null
    if(is_public_insurance){
      publicInsuranceContent = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText 
                text={content.subtitles.publicInsurance} 
                variant='bold'
                medium
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <LineInputSelect 
                    data={formDataTemp.public_insurance_id}
                    onChange={onInputChange}
                  />
                </Grid> 
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    let secondaryTreatmentContent = null
    if(is_secondary_treatment){
      secondaryTreatmentContent = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText 
                text={content.subtitles.secondaryTreatment} 
                variant='bold'
                medium
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <LineInputSelect 
                    data={formDataTemp.secondary_product_id}
                    onChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LineInputSelect 
                    data={formDataTemp.secondary_diagnosis_id}
                    onChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LineInputSelect 
                    data={formDataTemp.secondary_hospital_id}
                    onChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LineInputText 
                    data={formDataTemp.secondary_expected_uses}
                    onChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LineInputSelect 
                    data={formDataTemp.secondary_adjuvant_id}
                    onChange={onInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          {user_level === 3 ? (
                            <Grid item xs={12} md={6}>
                              <LineInputText 
                                data={formDataTemp.name}
                                onChange={onInputChange}
                              />
                            </Grid>
                          ) : null}
                          
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formDataTemp.external_id}
                              onChange={onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formDataTemp.folio}
                              onChange={onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formDataTemp.doctor_id}
                              onChange={onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formDataTemp.zip}
                              onChange={onInputChange}
                            />
                          </Grid>
                          {nse ? (
                            <Grid item xs={12} md={6}>
                              <NSEContainer nse={nse}/>
                            </Grid>
                          ) : null}
                          {nse === null ? (
                            <Grid item xs={12} md={6}>
                              <LineInputSelect 
                                data={formDataTemp.nse_id}
                                onChange={onInputChange}
                              />
                            </Grid>
                          ) : null}
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item>
                            <ItemSelection 
                              id='is_public_insurance'
                              status={is_public_insurance}
                              text='¿El Beneficiario cuenta con aseguradora pública?'
                              onChange={onChangeCheckbox}
                            />
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------PUBLIC INSURANCE ------------------------- */}
                  {publicInsuranceContent}
                  {/* ------------------ FIRST TREATMENT ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.mainTreatment} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <LineInputSelect 
                              data={formDataTemp.product_id}
                              onChange={onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LineInputSelect 
                              data={formDataTemp.diagnosis_id}
                              onChange={onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LineInputSelect 
                              data={formDataTemp.hospital_id}
                              onChange={onInputChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LineInputText 
                              data={formDataTemp.expected_uses}
                              onChange={onInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item>
                            <ItemSelection 
                              id='is_secondary_treatment'
                              status={is_secondary_treatment}
                              text='Agregar tratamiento secundario'
                              onChange={onChangeCheckbox}
                            />
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ------------------ SECOND TREATMENT ------------------------- */}
                  {secondaryTreatmentContent}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
  }


  finalValidation = () => {
    const {formData} = this.state
    const {nse, is_public_insurance, is_secondary_treatment} = this.props
    let formDataTemp = {...formData}
    let isValid = formDataTemp.external_id.isValid && formDataTemp.doctor_id.isValid && formDataTemp.zip.isValid &&
    formDataTemp.product_id.isValid && formDataTemp.diagnosis_id.isValid && formDataTemp.expected_uses.isValid

    if(!nse){ //Zip code not found, nse_id is a must
      isValid = isValid && formDataTemp.nse_id.isValid
    }
    if(is_public_insurance){
      isValid = isValid && formDataTemp.public_insurance_id.isValid
    }
    if(is_secondary_treatment){
      isValid = isValid && formDataTemp.secondary_product_id.isValid && 
      formDataTemp.secondary_diagnosis_id.isValid && formDataTemp.secondary_expected_uses.isValid
    }
    return isValid

  }
}

export default withStyles(styles)(FormStepA)