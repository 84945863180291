import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { onGetVialInformation } from '../../../../../../../../../shared/utility';
import AddButton from '../../../../../../../../../components/Buttons/AddButon';

const header = [
    {id:1, label:'#Vial', padding:undefined},
    {id:2, label:'Producto', padding:undefined},
    {id:3, label:'', padding:'checkbox'},
]

const MultipleVias = (props) => {

    const {vials, products, 
        onUpdateVial, onMoveVial, onEditVial, onUpdateModal,
        onAddVial, editable} = props
    const classes = useStyles()

    let viasContent = (
        <div style={{padding:8}}>
            <Grid container alignItems='center' direction='column'>
                <Grid item>
                    <Typography variant='body2' color='textSecondary'>No tiene productos agregados aún</Typography>
                </Grid> 
            </Grid>
        </div>
    )

    if(vials && vials.length){
        viasContent = (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {header.map(item => {
                                return(
                                    <TableCell padding={item.padding} key={`th-cell-${item.id}`} style={{paddingTop:8, paddingBottom:8}}>
                                        {item.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>  
                    <TableBody>
                        {vials.map(item => {

                            const vialData = onGetVialInformation(item)

                            return (
                                <TableRow key={`th-cell-${item.vialData?.number}`}>
                                    <TableCell >
                                        <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                                            {editable ? <Grid item>
                                                <IconButton size='small' onClick={() => onMoveVial(item,'down')}><Icon fontSize='small'>arrow_drop_down</Icon></IconButton>
                                            </Grid> : null}
                                            <Grid item>
                                                {vialData?.number}
                                            </Grid>
                                            {editable ?  <Grid item>
                                                <IconButton size='small' onClick={() => onMoveVial(item,'up')}><Icon fontSize='small'>arrow_drop_up</Icon></IconButton>
                                            </Grid> : null}
                                            
                                        </Grid>
                                        
                                        </TableCell>
                                        <TableCell><Typography variant='body2'>{vialData?.product}</Typography></TableCell>
                                        <TableCell padding='checkbox'>
                                        {editable ? <Grid container spacing={1} wrap='nowrap'>
                                            <Grid item>
                                                <IconButton size='small' onClick={() => onEditVial(item)}><Icon fontSize='small'>edit</Icon></IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size='small' onClick={() => onUpdateVial(item, 'delete' )}><Icon fontSize='small'>delete</Icon></IconButton>
                                            </Grid>
                                        </Grid> : null}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }


    return ( 
        <div>
            <div>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item >
                        <Typography variant='body2'>Productos(s)</Typography>
                    </Grid>
                    {editable ? <Grid item>
                        <AddButton onClick={onAddVial} />
                    </Grid> : null}
                </Grid>
            </div>
            <div className={classes.vias_content}>
                {viasContent}
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    vias_content:{
        marginTop:16,
        padding:'0px 8px',
        borderRadius:12,
        border:`1px solid ${grey[200]}`
    }
}))
 
export default MultipleVias;