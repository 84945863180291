import React from 'react';
import {
  Checkbox,
  Grid,
  Typography,
  withWidth,
} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, grey, red} from '@material-ui/core/colors';
//import ContentText from '../../Texts/ContentText.jsx';
import { useRef } from 'react';

const CheckboxInput = (props) => {

  

    const {
      onKeyPress,
      disabled,
      delayedSearch,
      onDelayedSearch
    } = props;

    const inputRef = useRef()
    const timerRef = useRef()

    const {value, isVisited, isValid, isRequired} = props.data;
    const {id, type,label,placeholder,fullWidth,icon, multiline, rows, helper} = props.data.config;

    const error = isVisited && !isValid;

    const onInnerChange = event => {
      const {onChange, data} = props;
      let temp = {...data};
      temp.value = temp.value === 1 ? 0 : 1;
      temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
      temp.isVisited = true;
      onChange(temp);
    }



    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='column' spacing={0}>
            <Grid item>
              <Grid container alignItems='center' wrap='nowrap'>
                <Grid item>
                  <Checkbox
                    disabled={disabled}
                    checked={value !== undefined && value !== null ? Boolean(value) : false}
                    onChange={onInnerChange}
                  />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography variant='body2'>{label}</Typography>
                  </Grid>
                </Grid>
              </Grid>
                
            </Grid>
            <Grid item>
              <Typography variant='caption' style={{color:red[700]}}>
                {error ? helper : ''}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  
};

export default (withWidth()(CheckboxInput));
