import React, {Component} from 'react'
import { TableRow,TableCell} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import {TableItem, PatientRewards} from '../../../../../components/DisplayData/DisplayData'

import { green, red, grey, lightBlue, orange } from '@material-ui/core/colors';


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablecell2:{
    maxWidth:200,
    borderTop:`2px solid ${grey[200]}`,
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class PatientList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    //this.setState({rowsPerPage:value})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.rowsPerPage = value
    onChangeConfig(temp)
  }

  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }
  
  onChangeMasterFilter = (key, data) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    Object.keys(temp.filter).forEach(item => {
      if(item === key){
        temp.filter[item] = data
      }else{
        temp.filter[item] = null
      }
    })
    temp.page = 0
    onChangeConfig(temp)

  }

  render(){

    const {classes, title, header, data, onUserSelected, onAddPatient, 
      filters, config, master_filters, user_level
    } = this.props
    const { page, rowsPerPage, totalItems, isSearch} = config

    return(
      
      <div className={classes.root}>
        <SimpleTable
          title={title}
          header={header}
          isSearch={isSearch}
          filter={config.filter}
          filters={filters}
          master_filters={master_filters}
          onChangeFilter={this.onChangeFilter}
          onChangeMasterFilter={this.onChangeMasterFilter}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={totalItems}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={user_level < 4}
          onAddItem={onAddPatient}
        >
          {data.map((item,key)=>{

            return(
              <TableRow key={key.toString()} 
              className={classes.tablerow} 
              >
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[0]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[1]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} style={{width:50, maxWidth:50}} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[2]}
                    
                  />
                </TableCell>
                <TableCell className={classes.tablecell2} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[3]}
                    wrap
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[4]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[5]}
                  />
                </TableCell>

                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='brightness_1'
                    iconSize={12}
                    iconColor={this.getPatientStatusColor(item.status)}
                    data={item.data[6]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='brightness_1'
                    iconSize={12}
                    iconColor={this.getApprovalStatusColor(item.approval_status)}
                    data={item.data[7]}
                  />
                </TableCell> 
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    icon='brightness_1'
                    iconSize={12}
                    iconColor={this.getLetterStatusColor(item.letter_status)}
                    data={item.data[8]}
                  />
                </TableCell> 
                <TableCell className={classes.tablecell}>
                  {item.rewards.map((product, key2)=>{
                    let title = key2 ? 'TA: ' : 'T: '
                    return(
                    <TableItem 
                      key={key2.toString()}
                      customIcon={<PatientRewards 
                        title={title}
                        rewards={product.use_states ? product.use_states : []}
                      />}
                    />
                    ) 
                  })}
                </TableCell>
              </TableRow>
            )
          })}
        </SimpleTable>
      </div>
    )
  }

  getPatientStatusColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = lightBlue[700]
        break;
      case 2:
        color = green[700]
        break;
      case 3:
        color = red[700]
        break;
      default:
          color = grey[500]
        break;
    }
    return color
  }

  getApprovalStatusColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = grey[700]
        break;
      case 2:
        color = lightBlue[700]
        break;
      case 3:
        color = green[700]
        break;
      case 4:
          color = orange[700]
          break;
      case 5:
          color = red[700]
          break;
      default:
          color = grey[500]
        break;
    }
    return color
  }

  getLetterStatusColor = (status) => {
    let color
    switch (status) {
      case 1:
        color = red[700]
        break;
      case 2:
        color = lightBlue[700]
        break;
      case 3:
        color = green[700]
        break;
      default:
          color = grey[500]
        break;
    }
    return color
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    //this.setState({rowsPerPage:value})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.rowsPerPage = value
    onChangeConfig(temp)
  }

  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }
}

export default withStyles(styles)(PatientList)