import {mainServer} from '../../../../variables/config'

export const request_hospital = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/hospital/"+id);
  return response.data.data.hospital
}

export const request_doctors_by_hospital = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/hospitaldoctor/hospital/${id}`);
  return response.data.data.hospital_doctors
}

export const request_doctors = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/doctor/all")
  return response.data.data.doctors
}

export const request_add_doctor2hospital = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/hospitaldoctor`,data);
  let response;
  response = await mainServer.instance.get(`/hospitaldoctor/hospital/${id}`);
  return response.data.data.hospital_doctors
}

export const request_delete_doctor = async (id, iddoctor) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/hospitaldoctor/${iddoctor}`);
  let response;
  response = await mainServer.instance.get(`/hospitaldoctor/hospital/${id}`);
  return response.data.data.hospital_doctors
}




