import { alpha, fade, Grid, makeStyles, Typography } from '@material-ui/core';
import { blueGrey, grey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import { usePlatformContext } from '../../context/PlatformContext';
import { catalogs } from '../../variables/catalogs';
import cx from 'classnames'
import { actionTypes } from '../../context/actionTypes';
import { USER_TYPE } from '../../variables/config';

const ProgramSection = ({user}) => {

    const classes = useStyles()
    const {state, dispatch} = usePlatformContext()

    const onSelected = (item) => {
        dispatch({type:actionTypes.UPDATE_PROGRAM_ID, programId:item.value})
    }

    if( !Boolean(user) ||
        user?.user_type_id === USER_TYPE.MERCURIO 
        || user?.user_type_id === USER_TYPE.HOSPITAL ) return null

    return ( 
        <div className={classes.root}>
            <Grid container wrap='nowrap' spacing={2}>
                {catalogs.spanish.header_programs_types.map(item => {
                    //console.log(item)

                    const selected = state.programId === item.value

                    return(
                        <Grid item key={`program-btn-${item.value}`}>
                            <div className={cx({
                                [classes.button]:true,
                                [classes.button_selected]:selected,
                            })} onClick={() => onSelected(item)}>
                                <Grid container wrap='nowrap' alignItems='center'>
                                    <Grid item>
                                        <div className={classes.icon_container}>
                                            <img src={item?.icon} alt='' className={classes.icon}/>
                                        </div>
                                    </Grid>
                                    <Grid item >
                                        <Typography color='inherit' variant='body2' noWrap>{item?.label}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    button:{
        color:grey[700],
        border:`1px solid ${grey[300]}`,
        padding:'8px 16px',
        borderRadius:32,
        cursor:'pointer',
        transition:'all 0.4s linear',
        '&:hover':{
            transition:'all 0.4s linear',
            background:blueGrey[50]
        }
    },
    button_selected:{
        border:`1px solid ${alpha('#6C89FF',0.3)}`,
        background:alpha('#6C89FF',0.3),
        transition:'all 0.4s linear',
    },
    icon_container:{
        width:32,
        height:32,
        marginRight:4,
    },
    icon:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    }
}))
 
export default ProgramSection;