import {mainServer} from '../../../../variables/config'

export const request_applicant_contacts = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/applicantcontact/all")
  return response.data.data.applicant_contacts
}

export const request_add_contact = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/applicantcontact", data)
  let response = await mainServer.instance.get("/applicantcontact/all")
  return response.data.data.applicant_contacts
}


export const request_update_contact = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/applicantcontact/${id}`, data)
  let response = await mainServer.instance.get("/applicantcontact/all")
  return response.data.data.applicant_contacts
}

export const request_delete_contact = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/applicantcontact/${id}`)
  let response = await mainServer.instance.get("/applicantcontact/all")
  return response.data.data.applicant_contacts
}
