import {mainServer} from '../../../../variables/config'

export const request_insurance_carrier = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/insurancecarrier/"+id);
  return response.data.data.insurance_carrier
}

export const request_edit_insurance_carrier = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/insurancecarrier/"+id, data)
  let response
  response = await mainServer.instance.get("/insurancecarrier/"+id);
  return response.data.data.insurance_carrier
}

export const request_merge_catalog = async (path, id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/"+path+"/"+id+"/replace", data);
}

export const request_insurance_carriers = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/insurancecarrier/all")
  return response.data.data.insurance_carriers
}

/* export const request_details = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/insurance_carrier/me/details");
  return response.data.data.insurance_carrier_details
}

export const request_details_translation = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/insurance_carrier/me/translations/1");
  return response.data.data.insurance_carrier_details_translation
} */
