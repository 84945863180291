export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      label:'Logs',
      navigation:[
        {name:'Logs',path:'/logs'},
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[ 
      {id:null, label:'Sin Filtro', url:null},
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      log_detail:{
        nodata:'No hay registro',
        title:'Detalles de log',
        returnButton:'Regresar',
        form:{
          action:{label:'Acción',value:''},
          detail:{label:'Responsable', value:''},
          date:{label:'Fecha', value:''},
          data:{label:'Data', value:''}
        },
        extraForm:{
          patient:{label:'Beneficiario',value:''},
          hospital:{label:'Hospital',value:''},
          hospital_old:{label:'Hospital 1', value:''},
          hospital_new:{label:'Hospital 2', value:''},
          doctor:{label:'Doctor', value:''},
          doctor_old:{label:'Doctor 1', value:''},
          doctor_new:{label:'Doctor 2', value:''},
          insurance_carrier:{label:'Aseguradora',value:''},
          insurance_carrier_old:{label:'Aseguradora 1',value:''},
          insurance_carrier_new:{label:'Aseguradora 2',value:''},
          public_insurance:{label:'Aseguradora pública', value:''},
          public_insurance_old:{label:'Aseguradora pública 1', value:''},
          public_insurance_new:{label:'Aseguradora pública 2', value:''},
          approval_contact:{label:'Contacto para aprobación', value:''},
          patient_product:{label:'Producto de beneficiario', value:''},
          cycle:{label:'Ciclo modificado', value:''},
          use:{label:'Número de uso',value:''},
          provider:{label:'Distribuidor',value:''},
          provider_old:{label:'Distribuidor 1',value:''},
          provider_new:{label:'Distribuidor 2',value:''},
          application_contact:{label:'Contacto de aplicación', value:''},
          patient_insurance:{label:'Seguro de paciente', value:''},
          hospital_doctor:{label:'Doctor del hospital', value:''},
        }
      }
    },
    header:['ID','Asesor','Evento', 'Fecha']
  }
}