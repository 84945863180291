import React, {Component} from 'react'
import { TableRow,TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import {TableItem} from '../../../../../components/DisplayData/DisplayData'

import { grey } from '@material-ui/core/colors';


const styles = (theme) => ({
  root:{},
  tablecell:{
    borderTop:`2px solid ${grey[200]}`,
    padding:'24px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class LogList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    //this.setState({rowsPerPage:value})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.rowsPerPage = value
    onChangeConfig(temp)
  }

  handleChangePage = (event, newPage) => {
    //this.setState({page:newPage})
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.page = newPage
    onChangeConfig(temp)
  }

  onSearch = (value) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.searchText = value
    onChangeConfig(temp)
  }

  onChangeFilter = (item) => {
    const {config, onChangeConfig} = this.props
    let temp = {...config}
    temp.filter = item.id
    onChangeConfig(temp)

  }

  render(){

    const {classes, title, header, data, onUserSelected, onAddItem, 
      filters, config
    } = this.props
    const {filter, page, rowsPerPage, totalItems} = config

    return(
      
      <div className={classes.root}>
        <SimpleTable
          title={title}
          header={header}
          filter={filter}
          filters={filters}
          onChangeFilter={this.onChangeFilter}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={totalItems}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={false}
          onAddItem={onAddItem}
        >
          {data.map((item,key)=>{
            return(
              <TableRow key={key.toString()} 
              className={classes.tablerow} 
              >
                <TableCell padding='checkbox' className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[0]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    avatar={item.user_image}
                    data={item.data[1]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[2]}
                  />
                </TableCell>           
                <TableCell className={classes.tablecell} onClick={() => onUserSelected(item.id)}>
                  <TableItem 
                    data={item.data[3]}
                  />
                </TableCell>           
              </TableRow>
            )
          })}
        </SimpleTable>
      </div>
    )
  }
}

export default withStyles(styles)(LogList)