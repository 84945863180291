import {mainServer} from '../../variables/config'

export const request_reauth = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/login/reauthenticate")
  localStorage.setItem('data',JSON.stringify(response.data.data))
}

export const request_get_user_info = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/me")
  return response.data.data.user
}

export const request_get_catalogs = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  //let routes = [
  //  '/user/types',
  //  '/user/genders',
  //  '/patient/statuses',
  //  '/patient/approvalstatuses',
  //  '/patient/paymenttypes',
  //  '/patient/letterstatuses',
  //  '/hospital/values',
  //  '/insurancecarrier/policyamounts',
  //  '/insurancecarrier/policyvalues',
  //  '/patientproduct/benefits'
  //]
  let routes = [
    '/user/types',
    '/user/genders',
    '/patient/statuses',
    '/patient/approvalstatuses',
    '/patient/paymenttypes',
    '/patient/letterstatuses',
    '/hospital/values',
    '/insurancecarrier/policyamounts',
    '/insurancecarrier/policyvalues',
    '/patientproduct/benefits',
    '/patient/inactivereasons'
  ]
  let response
  for(let i in routes){
    response = await mainServer.instance.get(routes[i])
    let name = Object.keys(response.data.data)[0]
    let dataSelected = response.data.data[name]
    let idTag = Object.keys(dataSelected[0]).filter(el => el.includes("id"))[0]
    let labelTag = Object.keys(dataSelected[0]).filter(el => el.includes("name"))[0]
    let string2Print = ''
    string2Print += name + ':[';
    for(let j in dataSelected){

      let temp = {
        id:dataSelected[j][idTag],
        value:dataSelected[j][idTag],
        label:dataSelected[j][labelTag],
      }
      string2Print += JSON.stringify(temp) + ',';
    }
    string2Print += '],';
    console.log(string2Print)
  }
  
  //let response
  //response = await mainServer.instance.get("/user/me")
  //return response.data.data.user
}

