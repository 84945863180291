export const formData = {
  first_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'first_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  last_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'last_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  username:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'username',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'email',
      type:'email',
      fullWidth: true,
    },
    rules:{
      type:'email',
    }
  },
  password:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password',
      type:'password',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  password_confirmation:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password_confirmation',
      type:'password',
      fullWidth: true,
    },
    rules:{
      type:'equals',
      min:1,
      max:100
    }
  },
  user_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'user_type_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  agency_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'agency_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'hospital_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  gender_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'gender_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  timezone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'timezone',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
}