export const formdata = {
    name:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'name',
        type:'text',
        fullWidth: true,
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Longitud de 1-100 caracteres'
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    email:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'email',
        type:'email',
        fullWidth: true,
        label:'E-mail',
        placeholder:'usuario@test.com',
        helper:'Email no válido'
      },
      rules:{
        type:'email',
      }
    },
    phone:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'phone',
        type:'number',
        fullWidth: true,
        label:'Teléfono',
        placeholder:'0000000000',
        helper:'Teléfono no válido'
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    external_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'external_id',
        type:'text',
        fullWidth: true,
        label:'ID programa de bonificaciones',
        placeholder:'ID programa de bonificaciones',
        helper:'ID no válido'
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    por_ti_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'por_ti_id',
        type:'text',
        fullWidth: true,
        label:'ID por Ti',
        placeholder:'ID por Ti',
        helper:'ID por ti no válido'
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    folio:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'folio',
        type:'text',
        fullWidth: true,
        label:'Folio',
        placeholder:'Folio',
        helper:'Folio no válido'
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    gender_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'gender_id',
        type:'select',
        fullWidth: true,
        addoption:true,
        label:'Género',
        placeholder:'Género',
        helper:'Seleccione una opción'
      },
      rules:{
        type:'none',
      }
    },
    doctor_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'doctor_id',
        type:'select',
        fullWidth: true,
        addoption:true,
        label:'Doctor',
        placeholder:'Seleccione un doctor',
      },
      rules:{
        type:'none',
      }
    },
    zip:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'zip',
        type:'number',
        fullWidth: true,
        label:'Código Postal',
        placeholder:'Código Postal',
        helper:'Ingrese un código postal válido'
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    public_insurance_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'public_insurance_id',
        type:'select',
        fullWidth: true,
        addoption:true,
        label:'Aseguradora pública',
        placeholder:'Seccione una aseguradora',
      },
      rules:{
        type:'none',
      }
    },
    nse_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'nse_id',
        type:'select',
        fullWidth: true,
        addoption:true,
        label:'Nivel socioeconómico',
        placeholder:'Nivel socioeconómico',
      },
      rules:{
        type:'none',
      }
    },
    ///////////////////   MAIN HOSPITAL    ///////////////////////

	main_hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
        id:'main_hospital_id',
        type:'select',
        fullWidth: true,
        label:'Hospital/Clínina',
        placeholder:'Seleccione una opción',
    },
    rules:{
      type:'none',
    }
	},

	///////////////////   INSURANCE SECTION    ///////////////////////

	insurance_carrier_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
        id:'insurance_carrier_id',
        type:'select',
        fullWidth: true,
        addoption:true,
        label:'Nombre',
        placeholder:'Seleccione una opción',
    },
    rules:{
      type:'none',
    }
  },
  policy_amount_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
        id:'policy_amount_id',
        type:'select',
        fullWidth: true,
        label:'Monto',
        placeholder:'Seleccione una opción',
    },
    rules:{
      type:'none',
    }
  },
  policy_expiry_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
        id:'policy_expiry_date',
        type:'date',
        fullWidth: true,
        label:'Expiración',
        placeholder:'',
    },
    rules:{
      type:'date',
    }
  },
  balance_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
        id:'balance_value',
        type:'number',
        fullWidth: true,
        label:'Cantidad de Saldo',
        placeholder:'Cantidad de Saldo',
    },
    rules:{
      type:'numeric',
      min:0,
      max:999999999999
    }
  },
  balance:{
    value: 0,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
        id:'balance',
        type:'checkbox',
        fullWidth: true,
        label:'Saldo',
        placeholder:'Saldo',
        helper:''
    },
    rules:{
        type:'none',
    }
  },
  payment_type_id:{
    value: 4,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    options:[],
    config:{
        id:'payment_type_id',
        type:'select',
        fullWidth: true,
        label:'',
        placeholder:'Seleccione una opción',
    },
    rules:{
      type:'none',
    }
  },
}