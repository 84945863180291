export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    nospecialities:'No existen especialidades registradas',
    title:{
      sectionB:'Doctor',
      sectionC:'Detalles',
    },
    sectionA:{
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)',
      },
      last_name:{
        label:'Apellido(s)',
        placeholder:'Apellido(s)',
      },
      username:{
        label:'Usuario',
        placeholder:'Usuario',
      },
      email:{
        label:'E-mail',
        placeholder:'E-mail',
        helper:'E-mail no válido'
      },
      mobile:{
        label:'Celular',
        placeholder:'Celular',
        helper:'El número debe contener entre 10-12 dígitos'
      },
      phone:{
        label:'Teléfono',
        placeholder:'Teléfono',
        helper:'El número debe contener entre 10-12 dígitos'
      },
      ext:{
        label:'Extensión',
        placeholder:'Extensión',
      },
      user_type_id:{
        label:'Tipo de usuario',
        placeholder:'Tipo de usuario',
      },
      gender_id:{
        label:'Género',
        placeholder:'Género',
      },
      timezone:{
        label:'Zona Horaria',
        placeholder:'Zona Horaria',
      },
      status:{
        label:'Estatus',
        placeholder:'Estatus'
      }
    },
  },
  
}