import React from 'react';
import { Card, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { isValidDocument } from '../../shared/utility';
import empty from '../../assets/empty.png'
import { imgServerS3 } from '../../variables/config';

const BrandCard = ({ item }) => {
	const classes = useStyles();

	let validSrc = !isValidDocument(item.brand_logo)
	let path = validSrc ? `${imgServerS3}${item.brand_logo}` : empty

	//console.log('BRAND', item.brand_logo)

	return (
		<Paper className={classes.root}>
			<Grid container>
				<Grid item xs={4}>
					<div className={classes.logo_container}>
						<img src={path} alt={`logo ${item.brand}`} />
					</div>
				</Grid>
				<Grid item container xs={8}>
					<Grid item xs={12}>
						<Typography align='center' color='primary' variant='h6' style={{ textTransform: 'uppercase', fontSize: '1rem' }}>
							{item.brand}
						</Typography>
					</Grid>
					<Grid item container alignItems='center' xs={12}>
						<Grid item xs={4}>
							<Typography align='center' className={classes.number}>{item.patient_count}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography align='center' className={classes.number}>{item.sold_units}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography align='center' className={classes.number}>{item.benefit_units}</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={4}>
							<Typography align='center' className={classes.subtitle}>Beneficiarios</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography align='center' className={classes.subtitle}>Compras</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography align='center' className={classes.subtitle}>Beneficios por producto</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
        padding: '15px 22px',
        boxShadow: '0px 3.61053px 22.5658px rgba(0, 0, 0, 0.1)',
        borderRadius: 10
    },
    logo_container:{
        width: '90%',
        height: 95,
        margin: '0px auto',
        '& img':{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    number:{
        color: '#616161',
        fontWeight: 500,
        fontSize: '1.65rem'
    },
    subtitle:{
        color: '#616161',
        fontWeight: 600,
        fontSize: '0.563rem',
    }
}));

export default BrandCard;
