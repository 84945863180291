import {mainServer} from '../../../../variables/config'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_add_mail = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  //let response
  await mainServer.instance.post("/approvalcontact", data)
}
