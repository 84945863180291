import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout';
import SimpleContainer from '../../../../../../../components/Layouts/SimpleContainer';
import SimpleModalV2 from '../../../../../../../components/Modals/SimpleModalV2';
import { getUserType } from '../../../../../../../shared/utility';
import { USER_TYPE } from '../../../../../../../variables/config';
import MedicalReportList from '../../../components/MedicalReportList';
import DeleteMedicalReportDocument from '../DeleteMedicalReportDocument';
import MedicalReportModal from '../MedicalReportModal/MedicalReportModal';
import UploadMedicalReportDocument from '../UploadMedicalReportDocument';
import useMedicalReportListModal from './useMedicalReportListModal';

const MedicalReportListModal = (props) => {

    const classes = useStyles()
    const {open, onClose, patientproduct, patient, noedit} = props
    const {actions, system, medicalReportManager, modals, data} = useMedicalReportListModal({patientproduct, patient})

    const {medical_reports, medical_report} = medicalReportManager.data

    const userType = getUserType()
    let addMedicalReportButton = (
        <ContraintLayout constrainst={constrainst.add_insurance} auto>
          <IconButton size='small' className={classes.addIcon}
          onClick={() => actions.onChangeModalStatus('add_report', true)}>
            <Icon fontSize='small'>add</Icon>
          </IconButton>
        </ContraintLayout>
    )

    return ( 
        <SimpleModalV2 open={open} onClose={onClose} maxWidth='md'>
            <div className={classes.root}>
                <MedicalReportModal mode='add'
                    open={modals.add_report} 
                    patient_product_id={patientproduct?.id_patient_product}
                    patient_id={patient?.id_patient}
                    onAddData={medicalReportManager.actions.onAdd}
                    onRefreshData={medicalReportManager.actions.onGetAllByProduct}
                    onClose={() => actions.onChangeModalStatus('add_report', false)}
                />
                
                <MedicalReportModal mode='edit'
                    open={modals.edit_report} origin={data.report}
                    patient_product_id={patientproduct?.id_patient_product}
                    patient_id={patient?.id_patient}
                    onClose={() => actions.onChangeModalStatus('edit_report', false)}
                    onUpdateData={medicalReportManager.actions.onUpdate}
                   
                    onRefreshData={medicalReportManager.actions.onGetAllByProduct}
                />
                <UploadMedicalReportDocument
                    open={modals.upload_report} origin={data.report} path={data.documentPath}
                    patient_product_id={patientproduct?.id_patient_product}
                    patient_id={patient?.id_patient}
                    onUpdateDocument={medicalReportManager.actions.onUploadDocument}
                    onRefreshData={medicalReportManager.actions.onGetAllByProduct}
                    onClose={() => actions.onChangeModalStatus('upload_report', false)}
                />
                <DeleteMedicalReportDocument
                    open={modals.delete_report} origin={data.report} path={data.documentPath}
                    patient_product_id={patientproduct?.id_patient_product}
                    patient_id={patient?.id_patient}
                    onDeleteDocument={medicalReportManager.actions.onDeleteDocument}
                    onRefreshData={medicalReportManager.actions.onGetAllByProduct}
                    onClose={() => actions.onChangeModalStatus('delete_report', false)}
                />
                <div>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <Typography variant='h6'>
                                Reportes médicos
                            </Typography>
                            
                        </Grid>
                        {(noedit || userType > USER_TYPE.MERCURIO) ? null : addMedicalReportButton}
                    </Grid>
                </div>
                
                <div style={{marginTop:16}}>
                    <SimpleContainer loading={system.loading}>
                        <MedicalReportList 
                            documents={medical_reports} 
                            userType={userType} 
                            onEditMedicalReport={actions.onEditMedicalReport}  
                            onSetCurrentMedicalReport={actions.onSetCurrentMedicalReport}
                            onApproveMedicalReport={actions.onApproveMedicalReport} 
                            onUploadDocument={actions.onUploadDocument}
                            onDeleteDocument={actions.onDeleteDocument}
                            onDownloadDocument={actions.onDownloadDocument}
                            viewOnly={noedit}
                        />
                    </SimpleContainer>
                    
                </div>
                

            </div>

        </SimpleModalV2>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    addIcon:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
          background:theme.palette.primary.dark,
        }
    }
}))
 
export default MedicalReportListModal;

const constrainst = {
    add_insurance:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }

}