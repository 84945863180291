import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { Grid, withStyles, InputBase, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import Subtitle from '../../../../../components/Texts/Subtitle';
import { blue } from '@material-ui/core/colors';
import DeliveredBenefitsTable from './DeliveredBenefitsTable';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';


import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import Caption from '../../../../../components/Texts/Caption';
import { palette, USER_TYPE } from '../../../../../variables/config';

import moment from 'moment'
import { request_delivered_benefits } from './requests';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import { cleanDeliveredBenefits, cleanDeliveredBenefitsExcel } from '../customFunctions';
import { catalogs } from '../../../../../variables/catalogs';
import FilterButton from '../../../../../components/Buttons/FilterButton';
import { request_me } from '../../../Applicants/AddApplicant/requests';
import useDeliveredBenefitsStats from './useDeliveredBenefitsStats';
import AnalyticToolbar from '../../components/AnalyticToolbar';
import SimpleContainer from '../../../../../components/Layouts/SimpleContainer';

const header = [
  'Fecha de entrega',
  'ID programa de bonificaciones',
  'Ciudad de beneficiario',
  'Estado del beneficiario',
  'Doctor',
  'Doctor UCI',
  'Doctor CRM ID',
  'Hospital',
  'Hospital UCI',
  'Hospital CRM ID',
  'Hospital de infusión',
  'Hospital de infusión UCI',
  'Hospital de infusión CRM ID',
  'Distribuidor',
  'Dirección de entrega',
  'Producto',
  'Presentación',
  '# Piezas',
  'Forma de pago'
]

const DeliveredBenefitsStats = (props) => {

  const {actions, data, system} = useDeliveredBenefitsStats(props)
  
  
  return(
    <div>
        <div>
          <Grid container>
            <Grid item xs>
              <Typography variant='h6' color='primary'>
                Reporte de beneficios entregados
              </Typography>
            </Grid>
            <Grid item>
              <RoundedButton   disabled={system.sending || system.loading}
                  label='Descargar Excel'
                  size='small'
                  color='primary'
                  onClick={actions.exportData2ExcelThird}
                />
            </Grid>
          </Grid>
        </div>
        <div>
          <AnalyticToolbar hideExpandable
          filter={data.filter} 
          //filter_disabled={data.filter_disabled}
          catalogs={data.masterCatalogs} 
          onUpdateFilter={actions.onUpdateFilter}
          noexp
          />
          <SimpleContainer loading={system.loading}>
            <DeliveredBenefitsTable 
            data={data.stats} header={header}
            total={data.total}
            tableFilter={data.pagination}
            onUpdateTableFilter={actions.onUpdatePagination}
            />
          </SimpleContainer>
        </div>

    </div>
  )
  

}


export default DeliveredBenefitsStats