import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { Grid } from '@material-ui/core';
import Subtitle from '../../../../../components/Texts/Subtitle';
import PatientStatsTable from './PatientStatsTable';
import { blue } from '@material-ui/core/colors';
import PatientStatsChart from './PatientStatsChart';

class PatientStats extends Component {
  render(){

    const {data} = this.props

    console.log(data)

    return(
      <CardWrapper>
        <div style={{padding:32}}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <Subtitle medium text='Estadísticas Beneficiarios' color={blue[900]}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <PatientStatsTable data={data.table}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <PatientStatsChart data={data.chartTable}/>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }
}

export default PatientStats