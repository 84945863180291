export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    add_button:'Agregar',
    title:{
      sectionA:'Información Básica',
      sectionB:'Información Detallada',
      sectionC:'Detalles',
    },
    form:{
      first_name:{
        label:'Nombre',
        placeholder:'Nombre',
      },
      second_name:{
        label:'Segundo Nombre',
        placeholder:'Segundo Nombre',
      },
      first_surname:{
        label:'Apellido Paterno',
        placeholder:'Apellido Paterno',
      },
      second_surname:{
        label:'Apellido Materno',
        placeholder:'Apellido Materno',
      },
      crm_id:{
        label:'CRM ID',
        placeholder:'CRM ID',
      },
      uci:{
        label:'UCI',
        placeholder:'UCI',
      },
    },
  },
  
}