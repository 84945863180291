import { useEffect, useState } from "react"
import usePatient from "../../../../../../hooks/usePatient"
import usePatientMedicaReport from "../../../../../../hooks/usePatientMedicalReport"
import { useHistory, useParams } from "react-router-dom";
import usePatientLetter from "../../../../../../hooks/usePatientLetter";

const usePatientLettersView = (props) => {

    const patientManager = usePatient()
    const {patient} = patientManager.data
    const {id} = useParams()
    const patientLetterManager = usePatientLetter()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()

    const [document, setDocument] = useState()
    const [documentPath, setDocumentPath] = useState()

    const [modals,setModals] = useState({
        add_letter:false,
        edit_letter:false,
        upload_letter:false,
        delete_letter:false
    })

    useEffect(() => {
        console.log(id)
        patientManager.actions.onGet(id)
    }, [])

    useEffect(() => {
        if(patient){
            console.log('Iniciarlizar paciente', patient)
            actions.onInitModule()
        }

    }, [patient])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            console.log('Ya puedo inicializar', patient?.id_patient)
            
            await patientLetterManager.actions.onGetAll(patient?.id_patient)
            setLoading(false)
        },
        onEditLetter: (data) => {
            setDocument(data)
            actions.onChangeModalStatus('edit_letter', true)
        },  
        onDeleteLetter: (data) => {
            setDocument(data)
            actions.onChangeModalStatus('delete_letter', true)
        },  
        onDownloadLetter: async(data) => {
            console.log('download', data)
            setSending(true)
            try {
                let response = await patientLetterManager.actions.onDownloadDocument(data?.id_patient_letter)
                console.log(response)
                if(!response.status){
                    setError(response.error)
                    return
                }
            } catch (error) {
                
            }
            setSending(false)

        },
        onResetError: () => setError(null),
        onEditMedicalReport: (data) => {
            setDocument(data)
            actions.onChangeModalStatus('edit_letter', true)
        },
        onApproveMedicalReport: async(data, status) =>{
            if(sending) return
            setSending(true)
            const data2send = {validated_medical_letter:status ? 1 : 0}
            //console.log(data2send)
            try {
                const result = await patientLetterManager.actions.onUpdate(data?.id_patient_medical_letter, data2send)
                //console.log(result)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
                await patientLetterManager.actions.onGetAll(patient?.id_patient)
            } catch (error) {
                console.log(error)
                setError('Hubo un error, intente de nuevo')
            }
            setSending(false)
        },
        onSetCurrentMedicalReport: async(data, status) =>{
            if(sending) return
            setSending(true)
            const data2send = {current:status ? 1 : 0}
            //console.log(data2send)
            try {
                const result = await patientLetterManager.actions.onUpdate(data?.id_patient_medical_letter, data2send)
                //console.log(result)
                if(!result.status){
                    setError(result.error)
                    setSending(false)
                    return
                }
                await patientLetterManager.actions.onGetAll(patient?.id_patient)
            } catch (error) {
                console.log(error)
                setError('Hubo un error, intente de nuevo')
            }
            setSending(false)
        },
        onUploadDocument: (data, path) => {
            //console.log(data)
            setDocument(data)
            setDocumentPath(path)
            actions.onChangeModalStatus('upload_letter', true)
        },
        onDeleteDocument: (data, path) => {
            //console.log(data)
            setDocument(data)
            setDocumentPath(path)
            actions.onChangeModalStatus('delete_letter', true)
        },
        onDownloadDocument: async(data, path) => {
            await patientLetterManager.actions.onDownloadDocument(data?.id_patient_medical_letter, path)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
    }

    const system = {loading, sending,error}
    const data = {document, documentPath, patient}

    return {system, data, actions, modals, patientLetterManager}



} 

export default usePatientLettersView