import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import SimpleModal from '../../../../../../../../components/Modals/SimpleModal'
import {form_data} from './data'
import {content} from './content'
import {catalog_matrix} from './catalog_matrix'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import { PATIENT_STATUS } from '../../../../../../../../variables/config'

class PatientStatusModal extends Component {

  state = {
    formData: {},
    isValid:false,
  }

  componentDidMount(){
    const {origin_data, catalogs} = this.props
    let temp = JSON.parse(JSON.stringify(form_data))
    if(catalog_matrix){
      catalog_matrix.forEach(item => {
        if(item.catalog_key !== 'none'){
          temp[item.form_key].options = catalogs[item.catalog_key]
        }
        
      })
    }
    console.log(origin_data)
    Object.keys(form_data).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
      if(origin_data){ 
        let value = origin_data[item]
        console.log(item)
        console.log(value)
        temp[item].value = value ? value : ''
        temp[item].isValid = value ? true : false
      }else{ // When initialization is not necessary
        temp[item].value = temp[item].value ? temp[item].value : '' 
        temp[item].isValid = false
      }
    });

    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {formData} = this.state;

    let data2Send = {
      status_id: formData.status_id.value
    }
    if(formData.status_id.value === PATIENT_STATUS.INACTIVE){
      if(formData.inactive_reason_id.value){
        data2Send = {...data2Send, inactive_reason_id:formData.inactive_reason_id.value}
        onSubmit(data2Send)
      }
    }else{
      onSubmit(data2Send)
    }
  }

  render(){

    const {data, onClose, addSpace} = this.props
    const {formData} = this.state

    let spaceContent = null

    if(addSpace){
      spaceContent = <Grid item xs={12} style={{height:'200px'}}/>
    }

    let reasonContent = null
    if(formData.status_id){
      if(formData.status_id.value === PATIENT_STATUS.INACTIVE){
        reasonContent = (
          <Grid item xs={12}>
            <LineInputSelect
              wrap='vertical'
              data={formData.inactive_reason_id}
              onChange={this.onInputChange}
            />
          </Grid>
        )
      }
    }
    
    
    return(
      <SimpleModal
        maxWidth={'sm'}
        content={content}
        onSubmit={this.onSubmit}
        data={data}
        onClose={onClose}
        onClick={this.onSubmit}
      >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LineInputSelect
                wrap='vertical'
                data={formData.status_id}
                onChange={this.onInputChange}
              />
            </Grid>
            {reasonContent}
            {spaceContent}
          </Grid>
      </SimpleModal>
    )
  }
}

export default PatientStatusModal