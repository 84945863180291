import React, {Component} from 'react'
import {
  Icon,
  Avatar,
  Typography,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import noimage from '../../assets/nouser.png'
import specialityImage from '../../assets/speciality.png'
import {grey, blue, red, amber, lightBlue} from '@material-ui/core/colors'
import ContentText from '../Texts/ContentText';
import Caption from '../Texts/Caption';
import PopperWrapper from '../Poppers/PopperWrapper';
import { withStyles } from '@material-ui/styles';
import { alpha, fade } from '@material-ui/core/styles';
import { imgServerS3 } from '../../variables/config';

const imageURL = imgServerS3

const useTableActionsStyles = makeStyles(theme => ({
  editIcon:{color:'white', padding:5, background:theme.palette.primary.main, '&:hover':{background:theme.palette.primary.dark}},
  deleteIcon:{color:red[700], padding:5}
}))

export const TableActions = props => {
  const {data, onDelete} = props
  const classes = useTableActionsStyles()
  return(
    <div style={{paddingLeft:8}}>
      <Grid container wrap='nowrap' spacing={1}>
        {/*<Grid item>
          <IconButton onClick={() => onEdit(data)} size='small' className={classes.editIcon}><Icon fontSize='small'>edit</Icon></IconButton>
        </Grid>*/}
        <Grid item>
          <IconButton onClick={() => onDelete(data)} size='small'className={classes.deleteIcon}><Icon >close</Icon></IconButton>
        </Grid>
      </Grid>
    </div>
    
  )
}



const stylesA = {
  icon:{
    color:blue[900]
  },
  text:{
    color:grey[700],
    fontWeight:'500'
  }
}

export const IconDataItem = (props) => {

  const data = props.data ? props.data : props.error;

  return(
    <div>
      <Grid container wrap="nowrap" spacing={1} alignItems='center'>
        <Grid item>
          <Icon style={stylesA.icon}>{props.icon}</Icon>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant='body2' noWrap style={stylesA.text}>{data}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

const stylesD = {
  text:{
    fontWeight:'500',
    color:grey[700],
  },
  label:{
    fontWeight:'500',
    color:grey[500],
  }
}

export const DataItemWrapper = (props) => {

  let infoContent = null;

  if(!props.icon && !props.avatar){
    let data = props.data ? props.data : props.error;
    let wrap = props.wrapText
    infoContent = (
      <Typography variant='body2' noWrap={wrap ? !wrap : true} style={stylesD.text}>
        {data}
      </Typography>
    )
  }else{
    if(props.icon){
      infoContent = (
        <IconDataItem data={props.data} icon={props.icon} error={props.error}/>
      )
    }else{
      if(props.avatar){

        let temp = {
          id_user: props.avatar.id_user ? props.avatar.id_user : null,
          first_name:props.avatar.first_name ? props.avatar.first_name : props.error.split(' ')[0],
          last_name:props.error.split(' ')[1],
          image:props.avatar.image ? props.avatar.image : null
        };

        if(props.avatar.first_name){
          temp.last_name = props.avatar.last_name ? props.avatar.last_name : '';
        }    
      }
    }
  }


  let labelContent = null;
  if(props.label){
    labelContent = (
      <Grid item xs={12}>
        <Typography variant='body2' style={stylesD.label}>
          {props.label}
        </Typography>
      </Grid>
    )
  }

  return(
      <Grid container direction='column' spacing={1}>
        {labelContent}
        <Grid item xs={12}>
          {infoContent}
        </Grid>
      </Grid>
  )
}

const styles_error = {
  text:{
    color:red[900],
    fontWeight:'500'
  }
}

export const ErrorMessage = (props) => {
  return(
    <Typography variant='body2' style={styles_error.text} {...props}>
      {props.message}
    </Typography>
  )
}

const styles_cell_item = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  icon:{
    color:blue[800]
  }
}

export const TableItem = (props) => {
  const {icon, customIcon, avatar, iconColor, iconSize, label, wrap, disableData, textStyle} = props
  let data = props.data ? props.data : props.error

  let iconContent = null
  if(icon){
    iconContent = (
      <Grid item>
        <Icon style={{
          color: iconColor ? iconColor : blue[800],
          fontSize : iconSize ? iconSize : null
        }}>{icon}</Icon>
      </Grid>
    )
  }
  if(customIcon){
    iconContent = (
      <Grid item>
        {customIcon}
      </Grid>
    )
  }
  if(avatar !== undefined){
    if(avatar !== null && avatar !=="0" && avatar !== 0){
      iconContent = (
        <Grid item>
          <Avatar
            src={imageURL+avatar}
            alt={noimage}
            style={styles_cell_item.avatar}
          />
        </Grid>
      )
    }else{
      iconContent = (
        <Grid item>
          <Avatar
            src={noimage}
            alt={noimage}
            style={styles_cell_item.avatar}
          />
        </Grid>
      )
    }
    
  }

  let labelContent = null
  if(label){
    labelContent = (
      <Grid item xs={12}>
        <ContentText text={label} variant='light' medium/>
      </Grid>
    )    
  }

  return(
    <Grid container spacing={1}>
      {labelContent}
      <Grid item xs={12}>
        <Grid container wrap='nowrap' spacing={1} alignItems='center'>
          {iconContent}
          {!disableData ? (<Grid item xs zeroMinWidth>
            <Typography variant='body2' noWrap={!wrap} style={{styles_cell_item, ...textStyle}}>
              {data !== null && data !== undefined ? data : 'N/D'}
            </Typography>
          </Grid>) : null}
        </Grid>
      </Grid>
    </Grid>
    
  )
}

const styles_subtitle = {
  text:{
    fontWeight:'500'
  }
}

export const Subtitle = (props) => {
  return(
    <Typography color='primary' style={styles_subtitle.text}>{props.data}</Typography>
  )
}

const styles_speciality = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  image:{
    width:40
  }
}

const styles_title = {
  text:{
    fontWeight:'500',
  }
}

export const Title = (props) => {

  const {color, align} = props

  return(
    <Typography 
      align={align}
      variant='h5'
      color='primary' 
      style={{
        ...styles_title.text,
        color: color
      }}
    >
      {props.data}
    </Typography>
  )
}

export const DoctorSpeciality = (props) => {
  return(
    <div>
      <Grid container alignItems='center' spacing={3}>
        <Grid item>
          <img src={specialityImage} alt='' style={styles_speciality.image}/>
        </Grid>
        <Typography variant='body2' style={styles_speciality.text}>
          {props.data}
        </Typography>
      </Grid>
    </div>
  )
}


const styles_rewards = (theme) => ({
  not_used:{
    fontSize:18,
    color:grey[400]
  },
  used:{
    color:'#0847a7'
  },
  to_use:{
    color:amber[700]
  },
  popper:{
    borderRadius:8,
    width:'200px', 
    padding:8,
    background:alpha(theme.palette.secondary.main,0.1)
  }
})

class PatientRewardsW extends Component {

  state = {
    anchorEl: null,
    open: false
  }

  render(){
    const {classes, rewards, title} = this.props
    const {anchorEl, open} = this.state

    const menulist = [
      {name:'Tratamiento no iniciado', color:grey[400]},
      {name:'Tratamiento iniciado', color:lightBlue[400]},
      {name:'Proximo beneficio', color:amber[600]},
      {name:'Beneficio aplicado', color:blue[900]},
    ]

    const starColors = [grey[400], grey[400],lightBlue[400],amber[600],blue[900]]

    let rewardsContent = null
    if(rewards){
      rewardsContent = rewards.map((item,key)=>{
        return(
          <Grid container alignItems='center' key={key.toString()}>  
            <Grid item>
              <Icon className={classes.not_used} style={{color: item.use_state ? starColors[item.use_state] : 'grey'}}>star</Icon>
            </Grid>   
          </Grid>
          
        )
      })
      if(rewards.length === 0){
        rewardsContent = (
          <Grid container alignItems='center'>  
            <Grid item>
              <Icon className={classes.not_used} style={{color: starColors[1]}}>star</Icon>
            </Grid>   
          </Grid>
        )
      }
    }

    return(
      <div>
        <PopperWrapper
          anchorEl={anchorEl}
          open={open}
          onClickAway={this.onCloseMenu}
          onSelectedItem={this.onSelectedItem}
        >
          <div className={classes.popper}>
            <Grid container>
              {menulist.map((item,key)=>{
                return(
                  <Grid item xs={12} key={key.toString()}>
                    <TableItem 
                      icon='brightness_1'
                      iconSize={12}
                      iconColor={item.color}
                      data={item.name}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </div>
          
        </PopperWrapper>
        <div onClick={this.onOpenMenu}>
          <Grid container wrap='nowrap'>
            <Grid item>
                <Caption text={title} variant='bold' medium noWrap/>
            </Grid>
            {rewardsContent}
          </Grid>
        </div>
      </div>
    )
  }

  onCloseMenu = () => {
    this.setState({open: false,anchorEl: null});
  };
 

  onOpenMenu = event => {
    const { currentTarget } = event;
    this.setState({anchorEl: currentTarget,open: true});
  }

  onSelectedItem = (item) => {
    const {onChangeFilter} = this.props
    this.onCloseMenu()
    onChangeFilter(item)
  }
}

export const PatientRewards = withStyles(styles_rewards)(PatientRewardsW)