export const contentData = {
  spanish:{
    layout:{
      title:'Agregar Doctor',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Doctores',path:'/catalogs/doctors'},
        {name:'Agregar',path:'/catalogs/doctors/add'}
      ],
      menu:null
    },
    header:['ID','Nombre','Email','Teléfono','Género']
  }
}