import React from 'react'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../../../../variables/texts'
import HorizontalDataWrapper from '../../../../../../../components/Texts/HorizontalDataWrapper'
import ContentText from '../../../../../../../components/Texts/ContentText'
import ContraintLayout from '../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_hospitalInfo as constrainst} from  '../constraints'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons'

const HospitalContainer = props => {

    const {data, onEdit} = props
    
    let isAccredited = null
    if(data && data.accredited !== null && data.accredited !== undefined){
        isAccredited = data.accredited === 1 ? 'Sí' : 'No'
    }
   
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ContentText 
                        text='Información del hospital o clínica de consulta' 
                        medium variant='bold'/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ContraintLayout constrainst={constrainst.name}>
                            <HorizontalDataWrapper 
                                label='Nombre' text={data ? data.name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.hospital_value_name}>
                            <HorizontalDataWrapper 
                                label='Nivel' text={data ? data.hospital_value_name : nodata}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.accredited}>
                            <HorizontalDataWrapper 
                                label='Acreditado' text={isAccredited}/>
                        </ContraintLayout>
                        <ContraintLayout constrainst={constrainst.edit_button} md={12}>
                            <Grid  container>
                                <Grid item xs={0} md={6}/>
                                <Grid item xs={12} md={6}>
                                    <Grid container wrap='nowrap'>
                                        <Grid item xs={0} md={12}/>
                                        <Grid item>
                                            <RoundedButton size='small' label='Editar' 
                                            onClick={onEdit}/>
                                        </Grid>
                                        <Grid item xs={1}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </ContraintLayout>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default HospitalContainer