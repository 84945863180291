import moment from 'moment-timezone'

export const onGetDateFormat = (data, format) => {
  if(!data) return null
  const momentdata = moment(data)
  if(!momentdata.isValid()) return null
  if(format) return momentdata.format(format)
  return momentdata.format('YYYY-MM-DD')
}

export const onGetDateWithTimezone = (data, format) => {
  // This function returns a moment object
  if(!data) return null 
  const temp = moment.utc(data)
  if(!temp.isValid()) return null 
  //console.log(moment.utc(data).format())
  if(!format) return moment(temp.format())

  return moment(temp.format()).format(format)
  
}
