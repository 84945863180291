import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'

import {formData} from './data'
import {contentData} from './content'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import ContentText from '../../../../../components/Texts/ContentText';

const styles = (theme) => ({
  root:{padding:'32px'}
})



class EditBasicInfo extends Component {

  state = {
    form: JSON.parse(JSON.stringify(formData)),
    accredited:false,
    isValid:false
  }

  render(){

    const {classes, language, issending, error, onMergeCatalog} = this.props
    const {form, isValid} = this.state
    const content = contentData[language]

    let tempForm = {...form}
    let loaderContent = null
    if(issending){
      loaderContent = <SimpleLoader />
    }else{
      if(error){
        loaderContent = <ErrorMessage message={error}/>
      }
    }
    
    

    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={6}>
            {/************************** CONTACT ******************** */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ContentText text='Información general' variant='bold' medium/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.name}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.address}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.crm_id}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.uci}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.contact_name}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.contact_email}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.contact_phone}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LineInputText
                        data={tempForm.contact_ext}
                        onChange={this.onInputChange}
                      />
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  {loaderContent}
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={'Combinar'} 
                    size='small' 
                    color='primary'
                    onClick={onMergeCatalog}
                  />
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={content.add_button} 
                    size='small' 
                    disabled={!isValid}
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  componentDidMount(){
    const {language, provider} = this.props
    const {form} = this.state
    const content = contentData[language]
    let tempA = {...form}
    Object.keys(form).forEach(item => {
      if(provider){
        let value = provider[item]
        if(tempA[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        tempA[item].value = value ? value : '';
        tempA[item].isValid = value ? true : false;
      }
      tempA[item].config = {...tempA[item].config,...content.form[item]}
    })
    const isValidForm = this.validationForm(tempA);
    this.setState({form:{...tempA}, isValid: isValidForm})
  }

  onInputChange = (data) => {
    const {form} = this.state
    const id = data.config.id;
    let temp = {...form};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp)
    this.setState({form:{...temp}, isValid:isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, provider} = this.props
    const {form} = this.state
    let data2Send = {}
    
    Object.keys(form).forEach((item)=>{
      if(form[item].isValid){
        if(form[item].value !== provider[item]){
          data2Send = {...data2Send, [item]:form[item].value}
        }
      }
    })
    
    onSubmit(data2Send)
  }

  onChangeCheckbox = (id, status) => {
    this.setState({[id]:status})
  }
}

export default withStyles(styles)(EditBasicInfo)