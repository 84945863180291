export const form_data = {
  patient_provider_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'patient_provider_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  manual_provider_address_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'manual_provider_address_value',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:512
    }
  },
  manual_particular_address_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'manual_particular_address_value',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:512
    }
  },
  alternative_hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'alternative_hospital_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  manual_hospital_address_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'manual_hospital_address_value',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:512
    }
  },
}