import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import moment from 'moment'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import WarningModal from '../../../../components/Modals/WarningModal'
import LogList from './LogList/LogList'
import { request_logs, request_users } from './requests'
import {cleanData2Table, getAllLogData} from './customFunctions'
import LogDetailModal from './LogDetailModal/LogDetailModal';

class Logs extends Component{

  state = {
    isloading:false,
    logs:[],
    users:[],
    filter:null,
    tableConfig:{
      filter:null,
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    selected_log:{},
    modals:{
      log_detail:{open:false, issending:false, error:null},
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {history, language} = this.props
    const {logs,  modals, success, filter, tableConfig, isloading, users,
      selected_log} = this.state
    const content = contentData[language]

    const data_users = cleanData2Table(logs,content.nodata,users)
  
    let modalContent = null

    if(modals.warning_delete_user.open){
      modalContent=(
        <WarningModal 
          data={modals.warning_delete_user}
          content={content.modals.warning_modal}
          onClose={() => this.onCloseModal('warning_delete_user')}
          onDelete={this.onDeleteUser}
        />
      )
    }

    
    if(modals.log_detail.open){
      modalContent=(
        <LogDetailModal 
          info={selected_log}
          data={modals.log_detail}
          content={content.modals.log_detail}
          onClose={() => this.onCloseModal('log_detail')}
          onDelete={this.onDeleteUser}
        />
      )
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        {modalContent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <LogList 
              filter={filter}
              filters={content.filters}
              config={tableConfig}
              onChangeFilter={this.onChangeFilter}
              header={content.header}
              data={data_users}
              onUserSelected={this.onItemSelected}
              onAddItem={this.onAddItem}
              onDeleteUser={this.onPreDeleteUser}
              onChangeConfig={this.onChangeTableConfig}
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }

  onItemSelected = async(id) => {
    try {
      const {language} = this.props
      const {logs, users} = this.state
      const content = contentData[language]
      const logContent = content.modals.log_detail
      let aux = {...logContent.form}
      let aux2 = {...logContent.extraForm}

      let log = logs.find(el => el.id_log === id)
      // Find responsible and get the full name
      let userInfo = users.find(el => el.id_user === log.user_id)
      let fullname = ''
      fullname += (userInfo ? userInfo.first_name : '')
      fullname += ' '+(userInfo ? userInfo.last_name : '')

      aux.action.value = log && log.log_event ? log.log_event : logContent.nodata
      aux.detail.value = fullname
      aux.date.value = log ? moment(log.created_at).format('DD/MM/YYYY HH:mm') : logContent.nodata
      aux.data.value = log && log.data ? log.data : logContent.nodata

      const selected_log = await getAllLogData(aux, aux2, log.log_event_id, log.reference_1_id, log.reference_2_id) 

      this.setState({selected_log:{...selected_log, log:{...log}}})
      this.onOpenFileModal('log_detail')
      
    } catch (error) {
      
    }
    
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) this.initModule()
  }

  initModule = async() => {
    const {tableConfig} = this.state
    this.setState({isloading:true,})
    const payload = this.getUrlWithFilters(tableConfig)
    let response = await request_logs(payload)
    let temp = {...this.state.tableConfig}
    temp.totalItems = response.count
    this.setState({logs:[...response.logs], tableConfig:temp})
    response = await request_users()
    this.setState({users:[...response]})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/catalogs')
  }

  getUrlWithFilters = (tableConfig, filters) => {
    const {filter, searchText, page, rowsPerPage} = tableConfig
    let url = '/'
    //check filters
    if(filter !== undefined && filter !== null){
      //url += filter + '?'
      if(searchText !== '' && searchText !== null){
        url += filters[filter].url + '?' + filters[filter].url + '=' + searchText
        url += '&limit=' + + rowsPerPage + '&offset=' + page*rowsPerPage
      }else{
        url += 'all'
        url += '?limit=' + rowsPerPage + '&offset=' + page*rowsPerPage
      }
      
    }else{
      url += 'all'
      url += '?limit=' + rowsPerPage + '&offset=' + page*rowsPerPage
    }
    
    return url

  }

  onChangeTableConfig = async(data) => {
    const content = contentData['spanish']
    const payload = this.getUrlWithFilters(data, content.filters)
    let response = await request_logs(payload)
    let temp = {...data}
    data.totalItems = response.count
    this.setState({logs:[...response.logs], tableConfig:temp})
  }

  onChangeFilter = (item) => {
    this.setState({filter:item.id})
  }

  

  onAddItem = async() => {
    const {history} = this.props
    history.push('/catalogs/logs/add')
  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_user'
    this.onEditModal(type, {open:true, id:id})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  
}

export default withStyles(styles)(Logs)