export const patientForm = {
	"external_id":null,
	"folio":null,
	"doctor_id":null,
	"hospital_id":null,
	"secondary_hospital_id":null,
	"payment_type_id":null,
	"insurance_carrier_id":null,
	"public_insurance_status":null,
	"public_insurance_id":null,
	"policy_amount_id":null,
	"balance":null,
	"balance_value":undefined,
	"policy_value_id":null,
	"zip":null,
	"zip_code_id":null,
	"nse_id":null,
	"letter_status_id":1,
	"patient_letter":"0",
	"doctor_letter":"0",
	"distributor_letter":"0",
	"secondary_distributor_letter":"0",
	"approval_status_id":1,
	"status_id":1
}

export const formData = {
	///////////////////   PATIENT INFO    ///////////////////////
	//name:{
  //  value: null,
  //  error: false,
  //  isVisited: false,
  //  isRequired: false,
  //  isValid: false,
  //  config:{
  //    id:'name',
  //    type:'text',
  //    fullWidth: true,
  //  },
  //  rules:{
  //    type:'distance',
  //    min:1,
  //    max:100
  //  }
  //},
  external_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'external_id',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  folio:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'folio',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  doctor_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'doctor_id',
      type:'select',
      fullWidth: true,
      addoption:true,
    },
    rules:{
      type:'none',
    }
  },
  zip:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'zip',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  public_insurance_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'public_insurance_id',
      type:'select',
      fullWidth: true,
      addoption:true
    },
    rules:{
      type:'none',
    }
  },
  nse_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'nse_id',
      type:'select',
      fullWidth: true,
      addoption:true,
    },
    rules:{
      type:'none',
    }
	},
	///////////////////   PRIMARY TREATMENT    ///////////////////////
  product_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'product_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  diagnosis_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'diagnosis_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
	},
	hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'hospital_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
	},
  expected_uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'expected_uses',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'numeric',
      min:0,
      max:50
    }
	},
	///////////////////   SECONDARY TREATMENT    ///////////////////////
  secondary_product_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'secondary_product_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  secondary_diagnosis_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'secondary_diagnosis_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
	},
	secondary_hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'secondary_hospital_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
	},
  secondary_expected_uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'secondary_expected_uses',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },

  secondary_adjuvant_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'secondary_adjuvant_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
	///////////////////   MAIN HOSPITAL    ///////////////////////

	main_hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'main_hospital_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
	},

	///////////////////   INSURANCE SECTION    ///////////////////////

	insurance_carrier_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'insurance_carrier_id',
      type:'select',
      fullWidth: true,
      addoption:true
    },
    rules:{
      type:'none',
    }
  },
  policy_amount_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'policy_amount_id',
      type:'select',
      fullWidth: true,
      
    },
    rules:{
      type:'none',
    }
  },
  balance_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'balance_value',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'numeric',
      min:0,
      max:999999999999
    }
  },


}