import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditBasicInfo from './EditBasicInfo/EditBasicInfo'
import ChangeCatalogModal from './Modals/ChangeCatalogModal/ChangeCatalogModal';

import {request_insurance_carrier, request_edit_insurance_carrier, request_insurance_carriers, request_merge_catalog} from './requests'
import { getInsuranceCarriersOptions } from './customFunctions';

class InsuranceCarrierEdit extends Component{

  state = {
    isloading:false,
    issending:false,
    error:null,
    insurance_carrier:null,
    insurance_carriers:[],
    modals:{
      merge_item:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_insurance_carrier(id)
    this.setState({insurance_carrier:{...response}})
    response = await request_insurance_carriers()
    this.setState({insurance_carriers:[...response]})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onSubmitUser = async(data) => {
    const {match} = this.props
    const id = match.params.id
    try {
      this.setState({issending:true})
      let response = await request_edit_insurance_carrier(id,data)
      this.setState({insurance_carrier:{...response}})
      this.setState({issending:false, error:null})
      this.props.history.goBack()
      //this.onActivateSuccessSnack()
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }
      
    }
  }

  render(){

    const {history, language, catalogs,translations, match} = this.props;
    const {isloading, issending, error, insurance_carrier, success, insurance_carriers,modals} = this.state
    const content = contentData[language]

    let modalcontent = null

    let catalog_id
    if(match){
      catalog_id = match.params.id
    }

    let custom_catalogs = getInsuranceCarriersOptions(insurance_carriers, catalog_id)
    let final_catalogs = {insurance_carriers:[...custom_catalogs]}
    if(modals.merge_item.open){
      modalcontent = (
        <ChangeCatalogModal 
          fullWidth={true}
          addSpace={true}
          catalogs={final_catalogs}
          data={modals.merge_item}
          onClose={() => this.onCloseModal('merge_item')}
          onSubmit={this.onMergeCatalog}
        />
      )
    }
    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditBasicInfo
              language={language}
              catalogs={catalogs}
              insurance_carrier={insurance_carrier}
              issending={issending}
              error={error}
              translations={translations}
              onSubmit={this.onSubmitUser}
              onMergeCatalog={() => this.onOpenFileModal('merge_item')}
            />
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }

  onMergeCatalog = async(data) => {
    const {match} = this.props
    const id = match.params.id
    this.setState({issending:true})
    const type = 'merge_item'
    let data2send = {...data}
    try {
      this.onEditModal(type,{issending:true})
      await request_merge_catalog('insurancecarrier',id,data2send)
      this.onEditModal(type,{open:false, issending:false, error:null})
      this.props.history.push('/catalogs/insurance_carriers/'+data.new_insurance_carrier_id)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
    this.setState({issending:false})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }


}

export default withStyles(styles)(InsuranceCarrierEdit)