import React, {Component} from 'react'
import SimpleDialog from '../../../../../../../../components/Modals/SimpleDialog'
import {formaData} from './data'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'
import { Grid } from '@material-ui/core'

import { ErrorMessage } from '../../../../../../../../components/DisplayData/DisplayData'

class AddMedicalReportModal extends Component {

    state = {
        formData: JSON.parse(JSON.stringify(formaData)),
        balance:false,
        isValid:false,
        error:null,
    }

    render(){

        const {data, onClose, catalogs, policy_amounts} = this.props
        const {formData, error} = this.state
        

        let form = {...formData}

        return(
            <SimpleDialog
                title='Nueva reporte médico'
                maxWidth='sm'
                data={data}
                onClose={onClose}
                onSubmit={this.onSubmit}
            >
                <div >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <LineInputText
                                wrap='vertical'
                                data={form.identifier}
                                onChange={this.onInputChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LineInputText
                                wrap='vertical'
                                data={form.report_date}
                                onChange={this.onInputChange}/>
                        </Grid>
                        {error ? (
                            <Grid item xs={12}>
                                <ErrorMessage message={error} align='center'/>
                            </Grid>
                        ) : null}
                    </Grid>
                </div>
            </SimpleDialog>
        )
    }

    onSubmit = () => {
        const {onSubmit} = this.props;
        const {formData, isValid} = this.state;
        let data2Send = {};

        if(isValid){
            Object.keys(formData).forEach((item)=>{
                if(formData[item].isValid){
                    data2Send = {...data2Send, [item]:formData[item].value}
                }
              })
      
              if(JSON.stringify(data2Send)!=="{}"){
                this.setState({error:null})
                onSubmit(data2Send)
              }
                
        }else{
            this.setState({error:'Llene todos los campos solicitados'})
        }
        
    
      }
    

    onInputChange = (data) => {
        const {formData} = this.state;
        const id = data.config.id;
        let temp = {...formData};
        temp[id] = {...data};
        const isValidForm = this.validationForm(temp);
        this.setState({formData:{...temp}, isValid: isValidForm})
    }

    validationForm = (data) => {
        let isValid = true;
        Object.keys(data).forEach((item) => {
          if(data[item].isRequired && !data[item].isValid){
            isValid = false;
          }
        })
        return isValid;
    }
}

export default AddMedicalReportModal
