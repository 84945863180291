export const TREATMENT_FREQUENCY = {
    WEEKLY:1,
    BIWEEKLY:2,
    MONTHLY:4,
    BIMONTHLY:5
}

export const PAYMENT_TYPES = {
    INSURANCE_DIRECT:1,
    CASH:2,
    MIX:3,
    INSURANCE_RETURN:4
}

export const LETTER_TYPES = {
    INIT_PATIENT:1,
    INIT_DOCTOR:2,
    INIT_PROVIDER:3
}