import React, {useState, useEffect} from 'react'
import SimpleDialog from '../../../../../../../../components/Modals/SimpleDialog'
import { formData, insuranceData } from './data'
import { Grid } from '@material-ui/core'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import { getHospitalsOptions } from '../../../../../../../../shared/customFunctions'
import SimpleLoader from '../../../../../../../../components/Loaders/SimpleLoader'
import { request_edit_patient, request_policy_amounts, request_insurance_carriers,
    request_add_patient_insurance, request_edit_patient_insurance } from '../../requests'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'
import ItemSelection from '../../../../../../../../components/Inputs/ItemSelection/ItemSelection'
import ContentText from '../../../../../../../../components/Texts/ContentText'
import { getInsuranceEvaluation } from '../../../../../../../../shared/utility'
import { grey } from '@material-ui/core/colors'
import moment from 'moment'
import { getInsuranceCarriersOptions } from '../../../../../AddApplicant/customFunctions'

const EditInsuranceModal = props => {

    const {onClose, onUpdateData, origin, catalogs, patient_insurance, onUpdateInsurances } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [balance, setBalance] = useState(false)
    const [insuranceForm, setInsuranceForm] = useState(JSON.parse(JSON.stringify(insuranceData)))
    const [policyAmounts, setPolicyAmounts] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [issending, setIsSending] = useState(false)
    const [error, setError] = useState(null)

    const data = {issending:issending, error:error}

    useEffect(() => {
        const initModule = async() => {
            setIsLoading(true)
            let temp = {...JSON.parse(JSON.stringify(formData))}
            let insuranceTemp = JSON.parse(JSON.stringify(insuranceData))
            const policy_amounts = await request_policy_amounts()
            const insurance_carriers = await request_insurance_carriers()
            const insurance_carriers_catalog = getInsuranceCarriersOptions(insurance_carriers)
            setPolicyAmounts(policy_amounts)
            if(origin){
                Object.keys(temp).forEach(item =>{
                    const value = origin[item]
                    if(value!== undefined && value !== null){ temp[item].value = value; temp[item].isVisited = true; temp[item].isValid = true;}
                })
                
            }
            if(patient_insurance){
                setBalance(patient_insurance.balance === 1)
                Object.keys(insuranceTemp).forEach(item =>{
                    const value = patient_insurance[item]
                    if(value!== undefined && value !== null){ 
                        let isValid = true
                        let currentValue = value
                        if(item === 'policy_expiry_date'){
                            let aux = moment(value)
                            isValid = aux.isValid()
                            if(isValid) currentValue = aux.format('YYYY-MM-DD')
                        }
                        insuranceTemp[item].value = currentValue; 
                        insuranceTemp[item].isVisited = isValid; 
                        insuranceTemp[item].isValid = true;}
                })
                if(insuranceTemp && patient_insurance.balance && insuranceTemp.balance_value.value){
                    const balanceReg = parseFloat(insuranceTemp.balance_value.value)/100
                    insuranceTemp.balance_value.value = balanceReg.toString()
                }
            }

            insuranceTemp.insurance_carrier_id.options = [...insurance_carriers_catalog]
            insuranceTemp.policy_amount_id.options = [...catalogs.policy_amounts]
            setForm(temp)
            setInsuranceForm(insuranceTemp)
            setIsLoading(false)
        }
        initModule()
    }, [origin]);


    const  onInputChange = async(data) => {
        let temp = {...form}
        temp[data.config.id] = {...data}
        setForm(temp)
    }

    const  onInsuranceInputChange = async(data) => {
        let temp = {...insuranceForm}
        temp[data.config.id] = {...data}
        setInsuranceForm(temp)
    }

    const onUpdate = async() => {
        let data2send = {}
        let isValid = true
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].isVisited && form[item].value !== origin[item]) data2send = {...data2send, [item]:form[item].value}
            if(form[item].isRequired && !form[item].isValid) isValid = false
        })

        
        let _payment_type_id = null
        if(data2send.payment_type_id){
            _payment_type_id = data2send.payment_type_id
        }else{
            _payment_type_id = origin.payment_type_id
        }

        let insurancedata2send = {}
        let insuranceFormValid = true
        Object.keys(insuranceForm).forEach((item)=>{
            if(insuranceForm[item].isValid) insurancedata2send = {...insurancedata2send, [item]:insuranceForm[item].value}
            if(insuranceForm[item].isRequired && !insuranceForm[item].isValid && _payment_type_id !== 2){ // Only check forms if is different from self payment
                insuranceFormValid = false
            }
        })
        const policy_value_id = getInsuranceEvaluation(balance, insuranceForm.balance_value.value, insuranceForm.policy_amount_id.value, policyAmounts)
        insurancedata2send = {...insurancedata2send, balance: balance ? 1:0, current:1, policy_value_id:policy_value_id}

        if(insurancedata2send && insurancedata2send.balance && insurancedata2send.balance_value){
            const balanceReg = parseInt(parseFloat(insurancedata2send.balance_value)*100)
            insurancedata2send.balance_value = balanceReg.toString()
        }

        //  console.log(insurancedata2send)

        if(isValid && insuranceFormValid){
            setError(null)
            setIsSending(true)
            try {
                if(form.payment_type_id.value === 2) 
                    data2send = {...data2send, policy_amount_id:7, policy_value_id:6} // 
                const {id_patient} = origin
                const newPatient = await request_edit_patient(id_patient, data2send)
                
                let currentPatientInsurance = null
                if(form.payment_type_id.value !== 2){
                    if(patient_insurance){ // Do a patch
                        console.log('Hace patch en patient insurance')
                        const {id_patient_insurance} = patient_insurance
                        currentPatientInsurance = await request_edit_patient_insurance(id_patient, id_patient_insurance, insurancedata2send)
                    }else{ // Create new patient insurance
                        insurancedata2send = {...insurancedata2send, patient_id:id_patient}
                        console.log('Add insurance', insurancedata2send)
                        currentPatientInsurance = await request_add_patient_insurance(id_patient,insurancedata2send)
                    }
                }
                onUpdateData(newPatient, currentPatientInsurance)

            } catch (error) {
                setError('Hubo un error, intente de nuevo')
            }
            setIsSending(false)
        }else{
            setError('Llene los datos faltantes')
        }
    }

    let mainForm = {...form}

    let insuranceContent = null

    

    if(mainForm.payment_type_id.value !== 2){

        let policy_value_name = null
        const policy_value_id = getInsuranceEvaluation(balance, insuranceForm.balance_value.value, insuranceForm.policy_amount_id.value, policyAmounts)
        if(policy_value_id && policyAmounts){
            let findItem = policyAmounts.find(el => el.policy_value_id === policy_value_id)
            policy_value_name = findItem.value_name
        }

        insuranceContent = (
            <Grid item xs={12}>
                <div>
                    <div style={{paddingTop:16, borderTop:`1px dashed ${grey[200]}`,marginBottom:8}}><ContentText text='Póliza de seguros' medium variant='bold'/></div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LineInputSelect
                                wrap='vertical'
                                data={insuranceForm.insurance_carrier_id}
                                onChange={onInsuranceInputChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <LineInputSelect
                                wrap='vertical'
                                data={insuranceForm.policy_amount_id}
                                onChange={onInsuranceInputChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LineInputText
                                wrap='vertical'
                                data={insuranceForm.policy_expiry_date}
                                onChange={onInsuranceInputChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item >
                                    <ItemSelection 
                                        id='balance'
                                        status={balance}
                                        text={'Saldo'}
                                        onChange={(id,status) => setBalance(status)}/>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {balance ? <LineInputText
                                wrap='vertical'
                                data={insuranceForm.balance_value}
                                onChange={onInsuranceInputChange}/> : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item><ContentText medium text='Nivel'/></Grid>
                                <Grid item><ContentText medium text={policy_value_name} variant='bold'/></Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </div>
            </Grid>
        )
    }

    let contentView = (
        <Grid container>
            <Grid item xs={12}>
                <div style={{marginBottom:24}}>
                    <LineInputSelect data={mainForm.payment_type_id} onChange={onInputChange}/>
                </div>
            </Grid>
            {insuranceContent}
            {/*mainForm.payment_type_id.value === 2 ? (
                <Grid item xs={12}>
                    <LineInputText data={mainForm.balance_value} onChange={onInputChange}/>
                </Grid>
            ) : null*/}
        </Grid>
    )

    if(isloading) contentView = <Grid container justifyContent='center'><div><SimpleLoader /></div></Grid>

    return(
        <SimpleDialog
            title='Información de la aseguradora'
            data={data}
            onClose={onClose}
            onSubmit={onUpdate}
            submit='Guardar'
        >
            <div>
                {contentView}
            </div>
        </SimpleDialog>
    )
}

export default EditInsuranceModal