import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';

import userimage from '../../../../../assets/personal-security.png'
import { contentData } from './content';
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText';
import { mainForm } from './data';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';

class FormStepC extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    formdataTemp: JSON.parse(JSON.stringify(mainForm)),
    doctors:[],
    products:[],
    diagnostics:[],
    is_public_insurance:false,
    is_secondary_treatment:false,
  }

  render(){

    const {classes, input_data, language,  catalogs, payment_type, 
      policy_value, balance, onInputChange, onChangeBalance, onChangePaymentType} = this.props
    //const {formdataTemp} = this.state

    const content = contentData[language]
    let formdataTemp = {...input_data}
    let formContent = null

    let balanceValueContent = null
    if(balance){
      balanceValueContent = (
        <LineInputText 
          data={formdataTemp.balance_value}
          onChange={onInputChange}
        />
      )
    }

    let policyValueContent = null
    if(policy_value){
      policyValueContent = (
        <Grid item xs={12} md={6}>
           <HorizontalDataWrapper 
            label='Valor' 
            text={policy_value.value_name} 
           />
        </Grid>
        
      )
    }

    switch (payment_type) {
      case 1:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formdataTemp.insurance_carrier_id}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formdataTemp.policy_amount_id}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {balanceValueContent}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ItemSelection 
                    id='balance'
                    status={balance}
                    text={'Saldo'}
                    onChange={(id, status) => onChangeBalance(id, status)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {policyValueContent}
          </Grid>
        )
        break;
      case 2:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}/>
            {policyValueContent}
          </Grid>
        )
        break;
      case 3:
        formContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formdataTemp.insurance_carrier_id}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineInputSelect 
                data={formdataTemp.policy_amount_id}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {balanceValueContent}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <ItemSelection 
                    id='balance'
                    status={balance}
                    text={'Saldo'}
                    onChange={(id, status) => onChangeBalance(id, status)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {policyValueContent}
          </Grid>
        )
        break;
    
      default:
        break;
    }



    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt='' className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          {catalogs.payment_types.map((item,key)=>{
                            return(
                              <Grid item xs={12} md={4} key={key.toString()}>
                                <ItemSelection 
                                  id='payment_type'
                                  status={payment_type === item.value}
                                  text={item.label}
                                  onChange={(id,status) => onChangePaymentType(id, item.value)}
                                />
                              </Grid>
                            )
                          })}
                          
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    {formContent}
                  </Grid>
                  {/* Secondary Hospital View  */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
  }

  onNextStep = () => {
    const {payment_type, balance} = this.props
    const {formdataTemp} = this.state

    let isValid = true
    switch (payment_type) {
      case 1:
      case 3:
        if(formdataTemp.insurance_carrier_id.value === null || formdataTemp.policy_amount_id.value === null){
          isValid = false
        }
        if(balance){
          if(formdataTemp.balance_value.value === null || !(formdataTemp.balance_value.value >= 0) || formdataTemp.balance_value.value === ''){
            isValid = false
          }
        }
        break;
      default:
        break;
    }
    if(isValid){
      this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formdataTemp)),3)
    }
    
  }

  onPreviousStep = () => {
    this.props.onUpdateData(JSON.parse(JSON.stringify(this.state.formdataTemp)),1)
  }

}

export default withStyles(styles)(FormStepC)