export const contentData = {
  spanish:{
    layout:{
      title:'Mi Perfil',
      navigation:[
        {name:'Mi perfil',path:'/profile'},
      ]
    },
  }
}
