import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  container:{
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }
})

const contentData = {
  spanish:{
    message:'Arrastra un archivo aquí o da clic para seleccionar alguno'
  },
  english:{
    message:"Drag 'n' drop a file here, or click to select one"
  }
}

// Note that there will be nothing logged when files are dropped
class SimpleDropzone extends Component {
  render(){

    const {classes, onDrop, language, accept} = this.props
    const content = contentData[language]
    return(
      <Dropzone onDrop={onDrop} accept={accept}>
        {({getRootProps, getInputProps}) => (
          <div className={classes.container}>
            <div
              {...getRootProps({
                onDrop: event => event.stopPropagation()
              })}
            >
              <input {...getInputProps()} />
              <p>{content.message}</p>
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
  
}

export default withStyles(styles)(SimpleDropzone)


