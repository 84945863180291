import React, { useState, useEffect } from 'react';
import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const header = [
    {id:1, label:'#Vial', padding:undefined},
    {id:2, label:'Producto', padding:undefined},
]

const SimpleMultitpleVias = (props) => {

    const {vials} = props
    const classes = useStyles()

    let viasContent = <div></div>
    //console.log(vials)
    if(vials && vials.length){
        viasContent = (
            <div>
                <div className={classes.vias_content}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {header.map(item => {
                                    return(
                                        <TableCell padding={item.padding} key={`th-cell-${item.id}`} className={classes.cell_body} style={{borderTop:0}}>
                                            {item.label}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>  
                        <TableBody>
                            {vials.map(item => {

                                const vialData = item


                                return (
                                    <TableRow key={`th-cell-${item.vialData?.number}`}>
                                        <TableCell className={classes.cell_body}>
                                            {vialData?.number}  
                                        </TableCell>
                                        <TableCell className={classes.cell_body}>{vialData?.product} {vialData?.product_presentation??''}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
            
        )
    }


    return ( 
        <div>
            {viasContent}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    vias_content:{
        marginTop:4,
        //padding:8,
        borderRadius:12,
        border:`1px solid ${grey[200]}`
    },
    cell_body:{
        padding:'4px 16px',
        borderTop:`1px solid ${grey[200]}`,
        borderBottom:0,
    }
}))
 
export default SimpleMultitpleVias;