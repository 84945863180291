import logoSignIn from '../assets/roche azul.png'
import axios from 'axios';

export const drawerWidth = 145;
export const closedDrawerWidth = 0;

export const imgServerS3 = "https://oncopassport-dev-public.s3.us-east-2.amazonaws.com/";
export const rfcImgServerS3 = "https://s3.us-east-2.amazonaws.com/asiconnect3-public/business/image";

export const DASHBOARD_SETTINGS = {
  boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
}

export const SignIn = {
  logo: logoSignIn,
  spinner: {
    color: '#0c519d'
  }
}

export const SideBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const TopBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const Footer = {

}

export const palette = {
  primary:{
    main:'#0847a7'
  },
  secondary: { main: '#4466f2' },
}

export const Languages = [
  {id:2, code:'MX', language:'spanish'},
  {id:1, code:'US', language:'english'},
];

export const BENEFIT_TYPE = {
  NORMAL:1,
  ROACTERMA:2,
  INTERMITENT:3
}

export const REGISTRATION_STEPS = {
  DISCLAIMER:1,
  APPLICANT:2,
  HOSPITAL:3,
  INSURANCE:4,
  OVERVIEW:5
}

export const USER_TYPE = {
  ADMIN:1,
  ROCHE:2,
  MERCURIO:3,
  PRA:4,
  HOSPITAL:5
}

export const PATIENT_STATUS = {
  PROSPECT:1,
  IN_PROCESS:2,
  ACTIVE:3,
  INACTIVE:4
}

export const APPROVAL_STATUS = {
  NOTSTARTED:1,
  PENDING:2,
  APPROVE:3,
  DISMISSED:4,
  CANCEL:5
}

export const mainServer = {
  instance: axios.create({
    baseURL: "https://api.oncopassport.com/dev",
    headers: {'Content-Type': 'application/json'},
  }),
  credentials: {
    user: 'trionix',
    password: 'Tgr8JzZdLwnHqLTVam9JDagjK8erAX2T'
  }
}

export const private_server = axios.create({
  baseURL: "https://api.oncopassport.com/dev",
  headers: {'Content-Type': 'application/json'},
})

export const s3Server = {
  instance: axios.create({
    baseURL: "https://s3.us-east-2.amazonaws.com/asiconnect3-public/user/image",
    headers: {'Access-Control-Allow-Origin': '*'},
  }),
}
