
import {catalogs} from '../../../../../../../../variables/catalogs'
const catalog = catalogs.spanish

export const formData = {
  payment_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[...catalog.payment_types],
    config:{
      id:'payment_type_id',
      fullWidth: true,
      label:'Forma de pago',
      placeholder:'Seleccione una forma de pago',
      helper:'Forma de pago no válida'
    },
  },
  balance_value:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'balance_value',
      type:'number',
      fullWidth: true,
      label:'Cantidad de Saldo',
      placeholder:'Ingrese una cantidad',
      helper:'La cantidad debe de ser positiva'
    },
    rules:{
      type:'numeric',
      min:0,
      max:999999999999
    }
  },
}

export const insuranceData = {
  insurance_carrier_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
        id:'insurance_carrier_id',
        type:'select',
        fullWidth: true,
        label:'Nombre',
        placeholder:'Seleccione una opción',
    },
    rules:{
      type:'none',
    }
  },
  policy_amount_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
          id:'policy_amount_id',
          type:'select',
          fullWidth: true,
          label:'Monto',
          placeholder:'Seleccione una opción',
          
      },
      rules:{
          type:'none',
      }
  },
  policy_expiry_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
          id:'policy_expiry_date',
          type:'date',
          fullWidth: true,
          label:'Expiración',
          placeholder:'',
      },
      rules:{
          type:'date',
      }
  },
  balance_value:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
          id:'balance_value',
          type:'number',
          fullWidth: true,
          label:'Cantidad de Saldo',
          placeholder:'Cantidad de Saldo',
      },
      rules:{
          type:'numeric',
          min:0,
          max:999999999999
      }
  },
}