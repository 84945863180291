export const formData = {
  doctor_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'doctor_id',
      type:'select',
      fullWidth: true,
      label:'Doctor',
      placeholder:'Seleccione un doctor',
      helper:'Doctor no válido'
    },
  }
}