import React, {useState, useEffect} from 'react'
import SimpleDialog from '../../../../../../../../components/Modals/SimpleDialog'
import { formData } from './data'
import { Grid } from '@material-ui/core'
import {  } from '../../requests'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import { request_nse, request_doctors, request_folio, request_external_id, request_public_insurances } from '../../requests'
import NSEContainer from './components/NSEContainer'
import ItemSelection from '../../../../../../../../components/Inputs/ItemSelection/ItemSelection'
import { getPublicInsurancesOptions, getDoctorsOptions } from '../../../../../../../../shared/customFunctions'
import SimpleLoader from '../../../../../../../../components/Loaders/SimpleLoader'
import { request_edit_patient } from '../../../../requests'
import {constrainst_basicInfoModal as constrainst} from  '../../constraints'
import ContraintLayout from '../../../../../../../../components/Layouts/ConstraintLayout';
import { USER_TYPE } from '../../../../../../../../variables/config'
import { catalogs } from '../../../../../../../../variables/catalogs'


const EditBasicInfoModal = props => {

    const {onClose, onUpdateData, origin, user_level } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [nse, setNse] = useState(null)
    const [isPublicInsurance, setIsPublicInsurance] = useState(false)
    const [isloading, setIsLoading] = useState(false)
    const [issending, setIsSending] = useState(false)
    const [error, setError] = useState(null)


    const data = {issending:issending, error:error}

    useEffect(() => {
        const initModule = async() => {
            setIsLoading(true)
            let temp = {...JSON.parse(JSON.stringify(formData))}
            const public_insurances = await request_public_insurances()
            const doctors = await request_doctors()
            temp.public_insurance_id.options = getPublicInsurancesOptions(public_insurances)
            temp.doctor_id.options = getDoctorsOptions(doctors)
            temp.gender_id.options = [...catalogs.spanish.genders]
            temp.product_type_id.options = [...catalogs.spanish.product_types]
            let nseTemp = null
            if(origin){
                const {nse, nse_id, nse_value, city, state, public_insurance_status} = origin
                Object.keys(temp).forEach(item =>{
                    const value = origin[item]
                    if(value!== undefined && value !== null){ temp[item].value = value; temp[item].isVisited = true; temp[item].isValid = true;}
                })
                nseTemp = {nse_id:nse_id,nse_value:nse_value,nse_name:nse,state:state,city:city}
                setIsPublicInsurance(public_insurance_status === 1)
                // Disabled patient_product_id if there are already  at least one patient product
                if(origin.patient_products){
                    if(origin.patient_products.length) temp.product_type_id.disabled = true
                }
            }
            setNse(nseTemp)
            setForm(temp)
            setIsLoading(false)
        }
        initModule()
    }, [origin]);


    const  onInputChange = async(data) => {
        let temp = {...form}
        temp[data.config.id] = {...data}
        setForm(temp)
        if(data.config.id === 'zip') onSearchZipCode(data.value)
        if(data.config.id === 'folio') onSearchFolio(temp, data.value)
        if(data.config.id === 'external_id') onSearchExternalID(temp, data.value)
    }

    const onSearchZipCode = async(code) => {
        try {
            const nseResponse = await request_nse(code)
            setNse(nseResponse)
        } catch (error) {
            setNse(null)
        }
    }

    const onSearchFolio = async(data, value) => {
        try {
            await request_folio(value)
        } catch (error) {
            let temp = {...data}
            temp.folio.isValid = false
            setForm(temp)
        }
    }

    const onSearchExternalID = async(data, value) => {
        try {
            await request_external_id(value)
        } catch (error) {
            let temp = {...data}
            temp.external_id.isValid = false
            setForm(temp)
        }
    }



    const onUpdate = async() => {
        let data2send = {}
        let isValid = true
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].isVisited && form[item].value !== origin[item]) data2send = {...data2send, [item]:form[item].value}
            if(form[item].isRequired && !form[item].isValid){
                isValid = false
                console.log(item)
            }
        })
        if(isValid){
            setError(null)
            setIsSending(true)
            try {
                data2send = {
                    ...data2send, ...nse, 
                    public_insurance_status: isPublicInsurance ? 1 : 0,
                    zip_code_id: nse ? nse.id_zip_code : undefined
                }
                const {id_patient} = origin
                const newPatient = await request_edit_patient(id_patient, data2send)
                onUpdateData(newPatient)
            } catch (error) {
                setError('Hubo un error, intente de nuevo')
            }
            setIsSending(false)
        }else{
            setError('Llene los datos faltantes')
        }
    }

    let mainForm = {...form}


    let productTypeForm = null

    if(user_level === USER_TYPE.ADMIN || user_level === USER_TYPE.ROCHE){
        productTypeForm = (
            <Grid item xs={12} md={12}>
                <LineInputSelect disabled data={mainForm.product_type_id} onChange={onInputChange}/>
            </Grid>
        )
    }   

    let contentView = (
        <Grid container spacing={2} justifyContent='flex-end'>
            <ContraintLayout constrainst={constrainst.external_id}>
                <LineInputText data={mainForm.por_ti_id} onChange={onInputChange}/>
            </ContraintLayout>
            <ContraintLayout constrainst={constrainst.external_id}>
                <LineInputText data={mainForm.external_id} onChange={onInputChange}/>
            </ContraintLayout>
            <ContraintLayout constrainst={constrainst.folio}>
                <LineInputText data={mainForm.folio} onChange={onInputChange}/>
            </ContraintLayout>
            <ContraintLayout constrainst={constrainst.name}>
                <LineInputText data={mainForm.name} onChange={onInputChange}/>
            </ContraintLayout>
            <ContraintLayout constrainst={constrainst.email}>
                <LineInputText data={mainForm.email} onChange={onInputChange}/>
            </ContraintLayout>
            <ContraintLayout constrainst={constrainst.phone}>
                <LineInputText data={mainForm.phone} onChange={onInputChange}/>
            </ContraintLayout>
            <ContraintLayout constrainst={constrainst.name}>
                <LineInputSelect data={mainForm.gender_id} onChange={onInputChange}/>
            </ContraintLayout>
            <Grid item xs={12} md={6}>
                <LineInputSelect data={mainForm.doctor_id} onChange={onInputChange}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <LineInputText data={mainForm.zip} onChange={onInputChange}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <NSEContainer nse={nse}/>
            </Grid>
            
            <Grid item xs={12} md={6}>
                <ItemSelection position='start' text='¿Tiene aseguradora pública?' status={isPublicInsurance} 
                onChange={(id,status) => setIsPublicInsurance(status)}/>
            </Grid>
            {isPublicInsurance ? (
                <Grid item xs={12}>
                    <LineInputSelect data={mainForm.public_insurance_id} onChange={onInputChange}/>
                </Grid>
            ) : null}
            {productTypeForm}
            
            
            
        </Grid>
    )

    if(isloading) contentView = <Grid container justifyContent='center'><div><SimpleLoader /></div></Grid>

    return(
        <SimpleDialog
            title='Información general'
            data={data}
            onClose={onClose}
            onSubmit={onUpdate}
            submit='Guardar'
        >
            <div>
                {contentView}
            </div>
        </SimpleDialog>
    )
}

export default EditBasicInfoModal