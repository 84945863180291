import React, {useState} from 'react';
import { Card, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import FilterButton from '../../../../../../components/Buttons/FilterButton';
import { RoundedButton } from '../../../../../../components/Buttons/GeneralButtons';
import InactivePatientStats from '../../InactivePatientStats/InactivePatientStats';
import PatientStats from '../../PatientStats/PatientStats';
import ProductPatientStats from '../../ProductPatientStats/ProductPatientStats';
import ProductStats from '../../ProductStats/ProductStats';
import useGeneralView from './useGeneralView';
import Brands from './sections/Brands';
import ShapePieChartTable from '../../../../../../components/Charts/ShapePieChart/ShapePieChartTable';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import HorizontalBarChart from '../../../../../../components/Charts/HorizontalBarChart/HorizontalBarChart';
import AreaScatterChart from '../../../../../../components/Charts/AreaScatterChart/AreaScatterChart';
import StackedAreaChart from '../../../../../../components/Charts/StackedAreaChart/StackedAreaChart';
import ChartFilter from './sections/ChartFilter';
import { blue } from '@material-ui/core/colors';
import { catalogs } from '../../../../../../variables/catalogs';
import AnalyticToolbar from '../../../components/AnalyticToolbar';

const dummyScatter = [
	{
		name: '2017/05/03',
		value: 2000,
	},
	{
		name: '2017/08/03',
		value: 2500,
	},
	{
		name: '2017/12/31',
		value: 2500,
	},
	{
		name: '2018/01/01',
		value: 5000,
	},
	{
		name: '2019/01/01',
		value: 2000,
	},
	{
		name: '2020/01/01',
		value: 3000,
	},
	{
		name: '2020/05/01',
		value: 3000,
	},
	{
		name: '2021/01/01',
		value: 2890,
	},
	{
		name: '2022/01/01',
		value: 4390,
	},
];

const GeneralView = ({ user, product_filter_enable, products_options }) => {
	const { data, system, actions } = useGeneralView({user});

	const classes = useStyles();

	let stacketdChartXAxiskey = 'year'
	if(data?.filters?.step?.value === 1 || data?.filters?.step?.value === 2 ) stacketdChartXAxiskey = 'timestamp'


	//console.log('PROD COUNT', data.product_count )

	let maxProdCount = 0
	let maxPatientCount = 0

	if(data.product_count && data.product_count.length){
		const maxbenefitsunits = Math.max(...data.product_count.map(o => parseFloat(o.benefit_units)))
		const maxsoldunits = Math.max(...data.product_count.map(o => parseFloat(o.sold_units)))
		maxProdCount = Math.max(maxbenefitsunits, maxsoldunits)
		//console.log('PROD COUNT', data.product_count, maxbenefitsunits, maxsoldunits, maxProdCount )
	}

	if(data.patient_count && data.product_count.length){
		//const maxbenefitsunits = Math.max(...data.product_count.map(o => parseFloat(o.benefit_units)))
		//const maxsoldunits = Math.max(...data.product_count.map(o => parseFloat(o.sold_units)))
		//maxProdCount = Math.max(maxbenefitsunits, maxsoldunits)
		//console.log('PROD COUNT', data.product_count, maxbenefitsunits, maxsoldunits, maxProdCount )
		maxPatientCount = Math.max(...data.patient_count.map(o => parseFloat(o.patient_count)))
		//console.log('patient COUNT', data.patient_count, maxpatientcount )
	}
	
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Grid container justifyContent='flex-end' alignItems='flex-end' spacing={2}>
					<Grid item>
						<RoundedButton disabled={system.sending} label='Descargar Excel' size='small' color='primary' onClick={actions.exportData2Excel} />
					</Grid>
					{/*product_filter_enable && (
						<Grid item>
							<FilterButton selected={data.product_type_id} label='Tratamiento' options={products_options} onChange={actions.onChangeProductTypeFilter} />
						</Grid>
					)*/}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Brands data={data.brand_stats} />
			</Grid>
			<Grid item xs={12}>
				<AnalyticToolbar filter={data.filters} catalogs={data.masterCatalogs} onUpdateFilter={actions.onUpdateFilters} hideExpandable={true} />
			</Grid>
			{system.sending && (
				<Grid item xs={12}>
					<LinearProgress />
				</Grid>
			)}
			<Grid item xs={12}>
				<CardWrapper>
					<AreaScatterChart step={data.filters.step.value} data_area={data.patient_count} data_scatter={dummyScatter} title='Beneficiarios históricos' 
					domainY={[0, Math.round(maxPatientCount*1.10)]}/>
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<ShapePieChartTable data={data.patient_status} key_name='patient_status' key_value='patient_count' colors={1} title='Beneficiarios por estatus' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<ShapePieChartTable
						data={data.patient_inactive_reason}
						key_name='inactive_reason'
						key_value='patient_count'
						title='Beneficiarios inactivos por motivo de inactividad'
						colors={2}
					/>
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_main_hospital} key_name='hospital' key_value='patient_count' title='Beneficiarios por hospital de consulta' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<ShapePieChartTable data={data.patient_payment_type} key_name='payment_type' key_value='patient_count' title='Beneficiarios por forma de pago' colors={1} />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_doctor} key_name='doctor' key_value='patient_count' title='Beneficiarios por doctor' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_insurance_carrier} key_name='insurance_carrier' key_value='patient_count' title='Beneficiarios por aseguradora' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_public_insurance} key_name='public_insurance' key_value='patient_count' title='Beneficiarios por aseguradora pública' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_provider} key_name='provider' key_value='patient_count' title='Beneficiarios por distribuidor' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12}>
				<ProductPatientStats data={data.patient_stats_by_product} />
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_diagnosis} key_name='diagnosis' key_value='patient_count' title='Beneficiarios por diagnósitco' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<HorizontalBarChart data={data.patient_state} key_name='state' key_value='patient_count' title='Beneficiarios por estado' />
				</CardWrapper>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardWrapper>
					<ShapePieChartTable data={data.patient_nse} key_name='nse' key_value='patient_count' title='Beneficiarios por nivel socioeconómico' colors={2} />
				</CardWrapper>
			</Grid>
			<Grid item xs={12}>
				<ProductStats data={data.product_stats} />
			</Grid>
			<Grid item xs={12}>
				<CardWrapper>
					<StackedAreaChart 
					data={data.product_count} 
					title='Histórico de entrega de productos' 
					xKey={stacketdChartXAxiskey}
					step={data.filters.step.value}
					domainY={[0, Math.round(maxProdCount*1.10)]}
					/>
				</CardWrapper>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
}));

export default GeneralView;
