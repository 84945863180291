import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'


import {request_doctors, request_products, request_diagnostics, 
  request_public_insurances, request_nse, 
  request_hospitals, request_hospital_details,
  request_insurance_carriers, request_policy_amounts, request_benefits,
  request_external_id, request_folio, request_create_patient,
  request_add_product, request_add_benefit, request_send_approval} from './requests'
import RegisterCard from '../../../../components/Layouts/RegisterCard/RegisterCard';
import FormStepA from './FormStepA/FormStepA';
import FormStepB from './FormStepB/FormStepB';
import { patientForm, formData } from './data';
import FormStepC from './FormStepC/FormStepC';
import FormStepD from './FormStepD/FormStepD';
import SimpleLoader from '../../../../components/Loaders/SimpleLoader';
import { ErrorMessage } from '../../../../components/DisplayData/DisplayData';
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';

import {getDoctorsOptions, getProductsOptions, getDiagnosticsOptions, getPublicInsurancesOptions, getHospitalsOptions, getInsuranceCarriersOptions} from './customFunctions'

class AddPatientv2 extends Component{

  state = {
    // Main data
    formData:JSON.parse(JSON.stringify(formData)),
    // Variables to add in post request
    nse:null,

    //Checkboxes
    is_public_insurance:false,
    is_secondary_treatment:false,

    //Catalogs
    doctors:[],
    products:[],
    hospitals:[],
    diagnostics:[],
    secondary_diagnostics:[],
    public_insurances:[],
    policy_amounts:[],
    insurance_carriers:[],

    // Loading system
    isloading:false,
    issending:false,
    error:false,
    // Step ID
    selected:0,

    // Extra items
    policy_value:null,
    payment_type:1, 
    balance:false,

    // Data to display
    doctor:null,
    public_insurance:null,
    product:null,
    diagnosis:null,
    hospital:null,
    hospital_value:null,
    secondary_product:null,
    secondary_diagnosis:null,
    secondary_hospital:null,
    secondary_hospital_value:null,
    main_hospital:null,
    main_hospital_value:null,
    insurance_carrier:null,
    policy_amount:null,
    insurance_level:null,
    payment_type_name:null,

    //Decision items
    patient_status:false,
    first_product:null,
    second_product:null,
    dataItems:null,

    // Trash items
    primary_benefit:null,
    secondary_benefit:null,
    patient_form:JSON.parse(JSON.stringify(patientForm)),
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {history, language,catalogs, user_level} = this.props
    const {issending, error, success, isloading, selected,
      products, doctors, diagnostics, secondary_diagnostics, public_insurances, nse,
      hospitals, is_public_insurance,
      is_secondary_treatment, payment_type, insurance_carriers, balance, 
      policy_value, formData, main_hospital, patient_status
    } = this.state
    const content = contentData[language]
    

    // Requested catalogs reassembly

    const doctors_catalog = getDoctorsOptions(doctors)
    const products_catalog = getProductsOptions(products)
    const diagnostics_catalog = getDiagnosticsOptions(diagnostics)
    const secondary_diagnostics_catalog = getDiagnosticsOptions(secondary_diagnostics)
    const public_insurances_catalog = getPublicInsurancesOptions(public_insurances)
    const hospitals_catalog = getHospitalsOptions(hospitals)
    const insurance_carriers_catalog = getInsuranceCarriersOptions(insurance_carriers)
    let formContent = null

    let updated_form = {...formData}

    // Update catalogs

    updated_form.diagnosis_id.options = [...diagnostics_catalog]
    updated_form.secondary_diagnosis_id.options = [...secondary_diagnostics_catalog]
    updated_form.public_insurance_id.options = [...public_insurances_catalog]
    updated_form.nse_id.options = [...catalogs.nses]
    updated_form.doctor_id.options = [...doctors_catalog]
    updated_form.product_id.options = [...products_catalog]
    updated_form.secondary_product_id.options = [...products_catalog]
    updated_form.hospital_id.options = [...hospitals_catalog]
    updated_form.main_hospital_id.options = [...hospitals_catalog]
    updated_form.secondary_hospital_id.options = [...hospitals_catalog]
    updated_form.insurance_carrier_id.options = [...insurance_carriers_catalog]
    updated_form.policy_amount_id.options = [...catalogs.policy_amounts] 
    updated_form.secondary_adjuvant_id.options = [...catalogs.adjuvant_statuses]




    if(!isloading){
      switch (selected) {
        case 0:
          formContent = (
            <FormStepA 
              user_level={user_level}
              language={language}
              input_data={updated_form}
              nse={nse}
              is_public_insurance={is_public_insurance}
              is_secondary_treatment={is_secondary_treatment}
              onChangeStep={this.onChangeStep}
              onInputChange={this.onInputChange}
              onChangeCheckbox={this.onChangeCheckbox}
            />
          )
          break;
        case 1:
          formContent = (
            <FormStepB 
              language={language}
              catalogs={catalogs}
              input_data={updated_form}
              main_hospital={main_hospital}
              onInputChange={this.onInputChange}
            />
          )
          break;
        case 2:
          formContent = (
            <FormStepC
              language={language}
              catalogs={catalogs}
              input_data={updated_form}
              onInputChange={this.onInputChange}
              payment_type={payment_type}
              balance={balance}
              policy_value={policy_value}
              onChangeBalance={this.onChangeCheckbox}
              onChangePaymentType={this.onChangePaymentType}
            />
          )
          break;
        case 3:
            formContent = (
              <FormStepD
                user_level={user_level}
                issending={issending}
                error={error}
                language={language}
                catalogs={catalogs}
                generalState={this.state}
                onUpdateData={this.onUpdateData}
                onChangeBalance={this.onChangeCheckbox}
                onRequestHospital={this.onRequestHospital}
                onChangePaymentType={this.onChangePaymentType}
                onChangeStep={this.onChangeStep}
                onCreatePatient={this.onCreatePatient}
              />
            )
            break;
      
        default:
          break;
      }
    }

    //////// HANDLE NAVIGATION BUTTONS //////// 

    let nextButton = null
    let backButton = null

    if(selected < 4){
      nextButton = (
        <Grid item>
          <RoundedButton 
            label={selected === 3 ? (patient_status ? 'Enviar a aprobación' : 'Generar pre-registro') :'Siguiente'}
            onClick={this.onNextStep}
            color='secondary'
          />
        </Grid>
      )
    }

    if(selected > 0){
      backButton = (
        <Grid item>
          <RoundedButton 
            label='Regresar'
            onClick={this.onPreviousStep}
            color='transparent'
          />
        </Grid>
      )
    }


    //////// HANDLE INTERNAL ERRORS AND WAITINGS STATES //////// 
    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }
    
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        isloading={isloading}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        returnBtn={true}
        onReturn={this.onReturn}
      >
        <Grid container>
          <Grid item xs={12}>
            <RegisterCard
              menu={content.views}
              selected={selected}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {formContent}     
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                      {messageContent}
                    </Grid>
                    {backButton}
                    {nextButton}
                  </Grid>
                </Grid>
              </Grid>
              
            </RegisterCard>
          </Grid>
        </Grid>
        
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) this.initModule()

    const {formData} = this.state
    const {language} = this.props
    const content = contentData[language]   
    let temp = {...formData} 

    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    })

    this.setState({formData:{...temp}})

    //const auxdata = localStorage.getItem('dummydata')
    ////console.log(auxdata)
    //if(auxdata){
    //  this.setState({...JSON.parse(auxdata)})
    //  this.setState({selected:3})
    //}
  
    

  }

  initModule = async() => {
    try{
      this.setState({isloading:true})
      let response
      response = await request_doctors()
      await this.setState({doctors:[...response]})
      response = await request_products()
      this.setState({products:[...response]})
      response = await request_public_insurances()
      this.setState({public_insurances:[...response]})
      response = await request_hospitals()
      this.setState({hospitals:[...response]})
      response = await request_insurance_carriers()
      this.setState({insurance_carriers:[...response]})
      response = await request_policy_amounts()
      this.setState({policy_amounts:[...response]})
      this.setState({isloading:false})
    }catch(e){
      this.setState({isloading:false})
    }
    
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm}, () => this.analyzeData(data))

  }


  // For some specific values a request must be done to ensure a valid input data for the system
  analyzeData = async(data) => {
    const id = data.config.id;
    const {formData} = this.state
    let temp = {...formData}
    let response

    switch(id){
      // Check if Unique ID is valid
      case 'external_id':
        try{
          await request_external_id(data.value)
        }catch(e){
          temp.external_id.isValid = false
          this.setState({formData:{...temp}})
        }
        break
      // Check if folio exists
      case 'folio':
        try{
          await request_folio(data.value)
        }catch(e){
          temp.folio.isValid = false
          this.setState({formData:{...temp}})
        }
        break
      // Search for NSE according to Zip code
      case 'zip':
        try{
          response = await request_nse(data.value)
          this.setState({nse:{...response}})
        }catch(e){
          this.setState({nse:null})
        }
        break
      // Search for diagnostics according to primary traetment
      case 'product_id':
        try{
          response = await request_diagnostics(data.value)
          this.setState({diagnostics:[...response]})
        }catch(e){
          this.setState({diagnostics:[]})
        }
        break
      // Search for diagnostics according to secondary traetment
      case 'secondary_product_id':
        try{
          response = await request_diagnostics(data.value)
          this.setState({secondary_diagnostics:[...response]})
        }catch(e){
          this.setState({secondary_diagnostics:[]})
        }
        break
      // Get detailed information about main hospital 
      case 'main_hospital_id':
          try{
            response = await request_hospital_details(data.value)
            this.setState({main_hospital:{...response}})
          }catch(e){
            this.setState({main_hospital:null})
          }
          break
      // Get the correct policy value
      case 'policy_amount_id':
      case 'balance_value':
        const {payment_type, balance, policy_amounts} = this.state
        let policy_value_temp
        if(payment_type === 1 || payment_type === 3){
          if(balance){
            if(temp.balance_value.value !== null){
              const selectedCatalog = policy_amounts.find(el => temp.balance_value.value >= el.min_value && temp.balance_value.value <= el.max_value);
              if(selectedCatalog){
                policy_value_temp = {
                  policy_value_id: selectedCatalog.policy_value_id,
                  value_name: selectedCatalog.value_name
                }
              }else{
                console.log('Esta fuera de rango el valor')
              }
              
            }
          }else{
            if(temp.policy_amount_id.value !== null && temp.policy_amount_id.value !== ''){
              //console.log()
              const selectedCatalog = policy_amounts.find(el => el.id_policy_amount === temp.policy_amount_id.value)
              policy_value_temp ={
                policy_value_id: selectedCatalog.policy_value_id,
                value_name: selectedCatalog.value_name
              }
            }
          }
        }else{
          temp.policy_amount_id.value = 7
          policy_value_temp = {
            policy_value_id: 6,
            value_name: "Bajo"
          }
          
        }
        this.setState({policy_value:{...policy_value_temp}})
        break;
      default:
       break
    }
  }

  onChangeCheckbox = (id,status) => {
    switch(id){
      case 'is_secondary_treatment':
        const product_status = this.onCheckProductStatus('primary')
        if(product_status){
          this.onCleanItem('secondary_product_id')
          this.onCleanItem('secondary_diagnosis_id')
          this.onCleanItem('secondary_expected_uses')
          this.onCleanItem('secondary_hospital_id')
          this.setState({[id]:status, error:null})
        }else{
          this.setState({error:'Registre primero el producto principal'})
        }
        
        break
      case 'is_public_insurance':
        this.onCleanItem('public_insurance_id')
        this.setState({[id]:status})
        break
      case 'balance':
        if(status){
          this.onCleanItem('balance_value')
        }else{
          this.onCleanItem('policy_amount_id')
        }
        this.setState({[id]:status, policy_value:null})
        break
      default:
        break
    }

    
  }

  onCheckProductStatus = (type) => {
    const {product_id, diagnosis_id, expected_uses,
    secondary_product_id, secondary_diagnosis_id, secondary_expected_uses} = this.state.formData
    
    if(type === 'primary'){
      return product_id.value !== null && product_id.value !== undefined && product_id.value !== '' &&
      diagnosis_id.value !== null && diagnosis_id.value !== undefined && diagnosis_id.value !== '' &&
      expected_uses.value !== null && expected_uses.value !== undefined && expected_uses.value !== ''
      //&& hospital_id.value !== null && hospital_id.value !== undefined && hospital_id.value !== ''
    }else{
      return secondary_product_id.value !== null && secondary_product_id.value !== undefined && secondary_product_id.value !== '' &&
      secondary_diagnosis_id.value !== null && secondary_diagnosis_id.value !== undefined && secondary_diagnosis_id.value !== '' &&
      secondary_expected_uses.value !== null && secondary_expected_uses.value !== undefined && secondary_expected_uses.value !== '' 
      //&& secondary_hospital_id.value !== null && secondary_hospital_id.value !== undefined && secondary_hospital_id.value !== ''
    }
  }



  onChangePaymentType = (id,value) => {
    this.onCleanItem('insurance_carrier_id')
    this.onCleanItem('balance_value')

    if(value === 2){
      this.setState({policy_value:{
        policy_value_id: 6,
        value_name: "Bajo"
      }})
      this.onSetItem('policy_amount_id', 7)
    }else{
      this.onCleanItem('policy_amount_id')
      this.setState({policy_value:null})
    }
    this.setState({[id]:value, balance:false})
  }

  onSetItem = (id,value) => {
    const {formData} = this.state
    let temp = {...formData}
    temp[id].value = value
    temp[id].isValid = true
    temp[id].isVisited = true
    this.setState({formData:{...temp}})
  }

  onCleanItem = (id) => {
    const {formData} = this.state
    let temp = {...formData}
    temp[id].value = null
    temp[id].isValid = false
    temp[id].isVisited = false
    this.setState({formData:{...temp}})
  }

  onNextStep = () => {
    const {selected} = this.state

    if(selected === 2){
      console.log('Actualizar nombres')
      this.onUpdateItemNames()
    }

    if(selected <3){
      this.setState({selected:selected+1})
      this.setState({error:null})
    }else{
      this.onAddPatient()
    }
  }

  onPreviousStep = () => {
    const {selected} = this.state
    if(selected > 0){
      this.setState({selected:selected-1})
    }
  }

  onUpdateItemNames = () => {
    const {catalogs} = this.props
    const {doctors, products, diagnostics, secondary_diagnostics, public_insurances, 
           hospitals, insurance_carriers, policy_value, payment_type} = this.state
    const {doctor_id, public_insurance_id, product_id, diagnosis_id, hospital_id,
          secondary_product_id, secondary_diagnosis_id, secondary_hospital_id,
          main_hospital_id, insurance_carrier_id, policy_amount_id} = this.state.formData
    
    const doctors_catalog = getDoctorsOptions(doctors)
    const products_catalog = getProductsOptions(products)
    const diagnostics_catalog = getDiagnosticsOptions(diagnostics)
    const secondary_diagnostics_catalog = getDiagnosticsOptions(secondary_diagnostics)
    const public_insurances_catalog = getPublicInsurancesOptions(public_insurances)
    const hospitals_catalog = getHospitalsOptions(hospitals)
    const insurance_carriers_catalog = getInsuranceCarriersOptions(insurance_carriers)

    let data2update = {
      doctor:this.onFindItemInArray(doctor_id.value, doctors_catalog),
      public_insurance:this.onFindItemInArray(public_insurance_id.value, public_insurances_catalog),
      product:this.onFindItemInArray(product_id.value, products_catalog),
      diagnosis:this.onFindItemInArray(diagnosis_id.value, diagnostics_catalog),
      hospital:this.onFindItemInArray(hospital_id.value, hospitals_catalog),
      hospital_value:this.onFindHospitalValue(hospital_id.value),
      secondary_product:this.onFindItemInArray(secondary_product_id.value, products_catalog),
      secondary_diagnosis:this.onFindItemInArray(secondary_diagnosis_id.value, secondary_diagnostics_catalog),
      secondary_hospital:this.onFindItemInArray(secondary_hospital_id.value, hospitals_catalog),
      secondary_hospital_value:this.onFindHospitalValue(secondary_hospital_id.value),
      main_hospital:this.onFindItemInArray(main_hospital_id.value, hospitals_catalog),
      main_hospital_value:this.onFindHospitalValue(main_hospital_id.value),
      insurance_carrier:this.onFindItemInArray(insurance_carrier_id.value, insurance_carriers_catalog),
      policy_amount:this.onFindItemInArray(policy_amount_id.value, [...catalogs.policy_amounts]),
      payment_type_name:this.onFindItemInArray(payment_type, [...catalogs.payment_types]),
      insurance_level: policy_value ? policy_value.value_name : null
    }

    this.setState(data2update)


  }

  onFindItemInArray = (id, items) => {
    if(id){
      let filterItem = items.find(el => el.id === id)
      if(filterItem){
        return filterItem.label
      }
    }
    return null
  }

  onFindHospitalValue = (id) => {
    const {hospitals} = this.state
    if(id){
      let filterItem = hospitals.find(el => el.id_hospital === id)
      if(filterItem){
        return filterItem.hospital_value_name
      }
    }
    return null
  }

  onFindProductPresentation = (id) => {
    const {hospitals} = this.state
    if(id){
      let filterItem = hospitals.find(el => el.id_hospital === id)
      if(filterItem){
        return filterItem.hospital_value_name
      }
    }
    return null
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onUpdateData = (data) => {
    this.setState({...data})
  }

  onAddPatient = async() => {
    console.log('Evaluando')
    
    const {patient_status, first_product, second_product,dataItems} = this.state
    console.log(first_product)
    const first_product_status = this.isProductValid({...first_product})
    const second_product_status = this.isProductValid({...second_product})

    try {
      this.setState({issending:true})
      if(patient_status){ // Create complete patient
        
        console.log(first_product_status)
        console.log(second_product_status)
        console.log(dataItems)
        console.log('ENVIANDO CREACION')
        let response = await request_create_patient(dataItems)
        console.log(response)
        let patient_id = response.patient_id
        let first_treatment = {...first_product, patient_id:patient_id}
        let second_treatment = {...second_product, patient_id:patient_id}
        if(first_product_status){
          console.log('ENVIANDO PRODUCTO 1')
          await request_add_product(first_treatment)
        }
        if(second_product_status){
          console.log('ENVIANDO PRODUCTO 2')
          await request_add_product(second_treatment)
        }
        console.log('ENVIANDO BENEFICIO')
        await request_add_benefit(patient_id)
        console.log('ENVIANDO APROBACION')
        await request_send_approval(patient_id)
        console.log('Termine!!!!!!!!!')
        this.props.history.push('/patients/'+patient_id) 
      }else{ // Create preregister
        let response = await request_create_patient(dataItems)
        let patient_id = response.patient_id

        let first_treatment = {...first_product, patient_id:patient_id}
        let second_treatment = {...second_product, patient_id:patient_id}

        if(first_product_status){
          console.log('ENVIANDO PRODUCTO 1')
          await request_add_product(first_treatment)
        }
        if(second_product_status){
          console.log('ENVIANDO PRODUCTO 2')
          await request_add_product(second_treatment)
        }

        this.props.history.push('/patients/'+patient_id) 
      }
    } catch (error) {
      console.log(error.response)
    }
    this.setState({issending:false})
  }

  isProductValid = (product) => {
    console.log(product)
    return  product.product_id !== null && product.product_id !== undefined && 
            product.diagnosis_id !== null && product.diagnosis_id !== undefined && 
            product.expected_uses !== null && product.expected_uses !== undefined 

            // &&product.hospital_id !== null && product.hospital_id !== undefined && 

  }

  onCreatePatient = async(data) => {

    
    this.setState({issending:true})
    try {
      let first_product, second_product;
      first_product = {
        patient_id:null,
        product_id: data.product_id,
        diagnosis_id: data.diagnosis_id,
        expected_uses: data.expected_uses,
        secondary:0,
      }

      let isSecondaryTreatment = data.secondary_product_id && data.secondary_product_id !== ""
      if(isSecondaryTreatment){
        second_product = {
          patient_id:null,
          product_id: data.secondary_product_id,
          diagnosis_id: data.secondary_diagnosis_id,
          expected_uses: data.secondary_expected_uses,
          secondary:1,
        }
      }
      let response

      let post_data = {...data}
      post_data = {...post_data, balance_value: data.balance_value !== null && data.balance_value !== undefined ? parseInt(parseFloat(data.balance_value)*100) : undefined}
      //console.log('Fixed data')
      //console.log(post_data)
      response = await request_create_patient(post_data)
      let patient_id = response.patient_id
      first_product.patient_id = patient_id
      await request_add_product(first_product)
      if(isSecondaryTreatment){
        second_product.patient_id = patient_id
        await request_add_product(second_product)
      }
      await request_add_benefit(patient_id)
      await request_send_approval(patient_id)
      this.props.history.push('/patients/'+patient_id) 
    } catch (error) {
    }
    this.setState({issending:false})
  }
  
  onCalculateBenefit = async(temp, policy_value, nse) => {
    try {
      this.setState({issending:true})
      let data2send = {
        "hospital_id":temp.hospital_id,
        "secondary_hospital_id":temp.secondary_hospital_id ? temp.secondary_hospital_id : undefined,
        "policy_value_id":policy_value.policy_value_id,
        "nse_id":nse
      }
      let response = await request_benefits(data2send)
      await this.setState({
        primary_benefit:{...response.primary_benefit}, 
        secondary_benefit:{...response.secondary_benefit}
      })
      this.setState({issending:false})
    } catch (error) {
    }
    
  }

  onChangePolicyValue = (data) => {
    this.setState({policy_value:data})
  }

  

  onChangeStep = (id) => {
    localStorage.setItem('state',JSON.stringify({...this.state}))
    this.setState({selected:id})
  }

  

  

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}

export default withStyles(styles)(AddPatientv2)