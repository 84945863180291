import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../../../components/Buttons/ActionModalBar';
import CheckboxInput from '../../../../../../../../components/Inputs/CheckboxInput/CheckboxInput';
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText';
import TreatmentSwitchInput from '../../../../../../../../components/Inputs/TreatmentSwitchInput/TreatmentSwitchInput';
import SimpleModalV2 from '../../../../../../../../components/Modals/SimpleModalV2';
import MultipleVias from '../../../../../AddApplicant/components/MultitpleVias/MultitpleVias';
import usePatientProductModal from './usePatientProductModal';
import ProductTypeSelection from '../../../../../AddApplicant/components/ProductTypeSelection';


const PatientProductModal = (props) => {

    const {open, onClose, mode, origin, catalogs, patient, onUpdatePatientProducts} = props
    const {form, vials, system, actions, data, product_type, setProductType } = usePatientProductModal({mode, catalogs, patient, onUpdatePatientProducts, onClose, open, origin})
    const {products} = data

    const title = mode === 'add' ? 'Agregar tratamiento' : 'Editar tratamiento'

    return ( 
        <SimpleModalV2 open={open} onClose={onClose} maxWidth='md'>
            <div>
                <Typography variant='h6'>
                    {title}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ProductTypeSelection
                            value={product_type?.value} 
                            onChange={(value) => setProductType(value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            
                            <Grid item xs={12}>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                        <TreatmentSwitchInput disabled={mode==='edit'}
                                            data={form.multiple_piece}
                                            onChange={actions.onInputChange}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {form.multiple_piece.value === 1 ? (
                                <Grid item xs={12}>
                                    <LineInputText
                                        data={form.dose}
                                        onChange={actions.onInputChange}
                                    />
                                </Grid>
                            ) : null}
                            
                            {form.multiple_piece.value === 0 
                            ? (
                                <Grid item xs={12}>
                                    <LineInputSelect
                                        data={form.product_id}
                                        onChange={actions.onInputChange}
                                        catalogs={products}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <MultipleVias
                                        vials={vials??[]} 
                                        products={products??[]} 
                                        onUpdateVial={actions.onUpdateVial}
                                        onMoveVial={actions.onMoveVial}/>
                                </Grid>
                            )}

                            
                                
                            <Grid item xs={12}>
                                <LineInputSelect 
                                    data={form.diagnosis_id}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>
                            {form.scheme_id.options.length > 0 && <Grid item xs={12}>
                                <LineInputSelect 
                                    data={form.scheme_id}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <LineInputSelect 
                                    data={form.hospital_id}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LineInputText
                                    data={form.expected_uses}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LineInputText
                                    data={form.vial_number}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LineInputSelect 
                                    data={form.treatment_frequency_id}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CheckboxInput
                                    data={form.adjusted}
                                    onChange={actions.onInputChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar 
                        btnLabel={mode === 'add' ? 'Agregar' : 'Guardar'}
                        error={system.error} onCloseError={actions.onResetError}
                        loading={system.sending}
                        onSubmit={actions.onSubmit}
                        />
                    </Grid>
                </Grid>
            </div>
        </SimpleModalV2>
     );
}
 
export default PatientProductModal;