export const contentData = {
  spanish:{
    layout:{
      title:'Agregar Hospital',
      navigation:[
        {name:'Catálogos',path:'/catalogs'},
        {name:'Hospitales',path:'/catalogs/hospitals'},
        {name:'Agregar',path:'/catalogs/hospitals/add'}
      ],
      menu:null
    },
    header:['ID','Nombre','Email','Teléfono','Género']
  }
}