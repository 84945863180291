export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    nospecialities:'No existen especialidades registradas',
    title:{
      sectionB:'Doctor',
      sectionC:'Detalles',
    },
    sectionA:{
      first_name:{
        label:'Nombre'
      },
      second_name:{
        label:'Segundo Nombre'
      },
      first_surname:{
        label:'Apellido Paterno'
      },
      second_surname:{
        label:'Apellido Materno'
      },
      crm_id:{
        label:'CRM ID'
      },
      uci:{
        label:'UCI'
      },
    },
  },
  
}