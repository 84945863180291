import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CardWrapper from '../../../../../../components/Cards/CardWrapper/CardWrapper';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import ErrorInfoModalV2 from '../../../../../../components/Modals/ErrorInfoModalV2';
import SimpleModalV2 from '../../../../../../components/Modals/SimpleModalV2';
import GenericLetterList from '../../components/GenericLetterList';
import PatientLetterModal from './modals/PatientLetterModal';
import useGenericPatientLetterModal from './useGenericPatientLetterModal';
import emptyIcon from '../../../../../../assets/empty.png'
import DeleteGenericLetterModal from './modals/DeleteGenericLetterModal';
import { getUserType } from '../../../../../../shared/utility';
import { USER_TYPE } from '../../../../../../variables/config';

const GenericPatientLetterModal = (props) => {

    const classes = useStyles()

    const {patientproduct, open, onClose, type, use} = props
    const {actions, system, patientLetterManager, modals, data} = useGenericPatientLetterModal({...props})
    const {patient_letters, patient_letter} = patientLetterManager.data

    const {patient, document} = data
    //console.log(type)
    const noedit = props.noedit || getUserType() > USER_TYPE.MERCURIO

    return ( 
        <SimpleModalV2 open={open} onClose={onClose} maxWidth='md'>
            <div>
                <PatientLetterModal 
                open={modals.add_letter} use={use}
                patient={patient} patientproduct={patientproduct}
                mode='add' type={type}
                onRefreshData={actions.onRefresh}
                onAddData={patientLetterManager.actions.onAdd} 
                onClose={() => actions.onChangeModalStatus('add_letter', false)}
                />
                <PatientLetterModal 
                open={modals.edit_letter} use={use} origin={document}
                patient={patient} patientproduct={patientproduct}
                mode='edit' type={type}
                onRefreshData={actions.onRefresh}
                onUpdateData={patientLetterManager.actions.onEdit} 
                onClose={() => actions.onChangeModalStatus('edit_letter', false)}
                />
                <DeleteGenericLetterModal 
                open={modals.delete_letter} use={use} origin={document}
                patient={patient} patientproduct={patientproduct} type={type}
                onRefreshData={actions.onRefresh}
                onDeleteData={patientLetterManager.actions.onDelete} 
                onClose={() => actions.onChangeModalStatus('delete_letter', false)}
                />
                <ErrorInfoModalV2 open={Boolean(system.error)}  onClose={actions.onResetError} 
                    data={system.error}/>
                
                <div className={classes.root}>
                    
                    <div>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Typography variant='h6'>{TITLES[type]}</Typography>
                            </Grid>
                            {noedit ? null :
                            <Grid item>
                                <IconButton className={classes.addIcon}
                                onClick={() => actions.onChangeModalStatus('add_letter', true)}
                                ><Icon>add</Icon></IconButton>
                            </Grid>}
                        </Grid>
                    </div>
                    
                    <div style={{marginTop:16}}>
                            {patient_letters.length === 0 ? (
                                <div style={{padding:32}}>
                                    <Grid container direction='column' spacing={2} alignItems='center'>
                                        <Grid item>
                                            <img src={emptyIcon} alt='' width={125}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography align='center' variant='body2'>
                                                No tiene ninguna carta <br/> registrado aún.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                        ):
                        <CardWrapper>
                            <SimpleContainer loading={system.loading}>
                                <div>
                                    <GenericLetterList letters={patient_letters}  type={type} sending={system.sending}
                                    onDownloadLetter={actions.onDownloadLetter} noedit={noedit}
                                    onEdit={actions.onEditLetter}
                                    onDelete={actions.onDeleteLetter}/>
                                </div>
                            </SimpleContainer>
                        </CardWrapper>
                        }
                        
                    </div>
                    

                </div>
            </div>
        </SimpleModalV2>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    addIcon:{
        background:theme.palette.primary.main,
        padding:4,
        color:'white',
        '&:hover':{
          background:theme.palette.primary.dark,
        }
    }
}))
 
export default GenericPatientLetterModal;

const TITLES = {
    1:'Cartas Paciente',
    2:'Cartas Doctor',
    3:'Cartas Distribuidor'
}