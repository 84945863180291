import React, {Component, Fragment} from 'react'
import { Grid, IconButton, Icon, withStyles, Button } from '@material-ui/core'
import moment from 'moment'

import SimpleModal from '../../../../../../../../components/Modals/SimpleModal'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'


import {form_data} from './data'
import {content} from './content'
import {catalog_matrix} from './catalog_matrix'
import DocumentButton from '../../../DocumentsView/components/DocumentButton'
import { amber } from '@material-ui/core/colors'
import ContentText from '../../../../../../../../components/Texts/ContentText'
import { USER_TYPE } from '../../../../../../../../variables/config'
import { getUserType, getFileExtension } from '../../../../../../../../shared/utility'
import ErrorInfoModal from '../../../../../../../../components/Modals/ErrorInfoModal'

import {request_download_document, request_upload_document, request_delete_document} from './requests'
import UploadDocumentModal from './modals/UploadDocumentModal'
import WarningModal from '../../../../../../../../components/Modals/WarningModal'
import ContraintLayout from '../../../../../../../../components/Layouts/ConstraintLayout'
import {constrainst_use_modal as constrainst} from  '../../constraints'
import VialModal from './modals/VialModal'
import MultipleVias from './components/MultipleVias'

class UseDateModal extends Component {

  state = {
    formData: {},
    use:null,
    isValid:false,
    error:null, 
    modals:{
      upload_document:{open:false,issending:false,error:null},
      delete_document:{open:false,issending:false,error:null},
      delete_document:{open:false,issending:false,error:null},

    }
  }

  componentDidMount(){
    const {origin_data, catalogs} = this.props
    let temp = JSON.parse(JSON.stringify(form_data))

    if(catalog_matrix){
      catalog_matrix.forEach(item => {
        if(item.catalog_key !== 'none'){
          temp[item.form_key].options = catalogs[item.catalog_key]
        }
        
      })
    }
    Object.keys(form_data).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
      if(origin_data){ 
        let value = origin_data[item]
        if(temp[item].config.id === 'date' || temp[item].config.id === 'application_date'){
          const timestamp = moment(value)
          value = timestamp.isValid() ? timestamp.format('YYYY-MM-DD') : null
          if(temp[item].config.id === 'date'){
            if(!origin_data.free) temp[item].config.label = 'Fecha de compra'
          }
        }
        temp[item].value = value ? value : ''
        temp[item].isValid = value ? true : false
      }else{ // When initialization is not necessary
        temp[item].value = temp[item].value ? temp[item].value : '' 
        temp[item].isValid = false
      }    
      const userType = getUserType()
      if(userType === USER_TYPE.HOSPITAL) temp.hospital_id.disabled =  true
      
    });
    console.log('original', origin_data)
    const isValidForm = this.validationForm(temp);
    console.log(temp)
    this.setState({formData:{...temp}, isValid: isValidForm, use:origin_data})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  onClear = (key) => {
    console.log()
    let temp = {...this.state.formData}
    temp[key].value = '@null!!'
    temp[key].isValid = true
    temp[key].isVisited = true
    this.setState({formData:temp})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, origin_data} = this.props;
    const {formData} = this.state;
    let data2Send = {};

    console.log(formData)

    if(origin_data){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(formData[item].value !== origin_data[item]){
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
    }else{
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
    }
    if(JSON.stringify(data2Send)!=="{}"){
      console.log(data2Send)
      onSubmit(data2Send)

    }
  }

  render(){

    const {data, onClose, classes, onDelete} = this.props
    const {formData, error, modals, use} = this.state

    let actionButtons = null
    const userType = getUserType()

    const originalPath = 'ticket'
    const obsPath = 'obsticket'
    const filename = 'ticket'

    let disabledDoc = false
    let disabledObsdoc = false

    console.log(use)

    let isLast = false

    if(use){
        isLast = use.free === 1
        disabledDoc = use.ticket === null || use.ticket === undefined || use.ticket === '' || use.ticket === '0'
        disabledObsdoc = use.obs_ticket === null || use.obs_ticket === undefined || use.obs_ticket === '' || use.obs_ticket === '0'
    }else{
      disabledDoc = data.ticket === null || data.ticket === undefined || data.ticket === '' || data.ticket === '0'
      disabledObsdoc = data.obs_ticket === null || data.obs_ticket === undefined || data.obs_ticket === '' || data.obs_ticket === '0'
    }


    switch (userType) {
        case USER_TYPE.ADMIN:
        case USER_TYPE.ROCHE:
        case USER_TYPE.PRA:
            actionButtons = (
              <Grid item><Grid container spacing={1}><Grid item>
                <Button variant='contained' className={classes.button} color='primary' disabled={disabledObsdoc}
                    onClick={() => this.onDownload(obsPath, filename)}>
                        <div style={{marginRight:8}}>Descargar</div>
                        <Icon>get_app</Icon>
                </Button>
              </Grid></Grid></Grid>
            )
            break;

        case USER_TYPE.HOSPITAL:
            actionButtons = (
              <Grid item>
                  <DocumentButton text={'Acciones'} color='primary' disabled={disabledDoc}
                  onUpload={() => this.onUpload(originalPath, filename)} 
                  onDownload={() => this.onDownload(originalPath, filename)}
                  onDelete={() => this.onDelete(originalPath)}/>
              </Grid>
            )
            break;
        case USER_TYPE.MERCURIO:
            actionButtons = (
                <Grid item>
                    <Grid container direction='column' spacing={1} alignItems='flex-end'>
                        <Grid item>
                            <DocumentButton text={'Original'} color='primary' disabled={disabledDoc}
                            onUpload={() => this.onUpload(originalPath, filename)} 
                            onDownload={() => this.onDownload(originalPath, filename)}
                            onDelete={() => this.onDelete(originalPath)}/>
                        </Grid>
                        <Grid item>
                            <DocumentButton text={'Modificado'} color='secondary' disabled={disabledObsdoc}
                            onUpload={() => this.onUpload(obsPath, filename)} 
                            onDownload={() => this.onDownload(obsPath, filename)}
                            onDelete={() => this.onDelete(obsPath)}/>
                        </Grid>
                    </Grid>
                </Grid>
            )
            break;
    
        default:
            break;
    }
    let errorModal = null
    if(error){
      errorModal = (
        <ErrorInfoModal 
          data={error}
          onClose={() => this.setState({error:null})}/>
      )
    }

    let modalcontent = null
    
    if(modals.upload_document.open){
      modalcontent = (
        <UploadDocumentModal
          data={modals.upload_document}
          onClose={() => this.onCloseModal('upload_document')}
          onSubmit={this.onUploadDocument}
        />
      )
    }

    if(modals.delete_document.open){
      modalcontent = (
        <WarningModal data={modals.delete_document} 
        onDelete={this.onDeleteDocument}
        onClose={() => this.onCloseModal('delete_document')}
        />
      ) 
    }

    return(
      <SimpleModal
        maxWidth={'sm'}
        content={content}
        onSubmit={this.onSubmit}
        data={data}
        onClose={onClose}
        onClick={this.onSubmit}
      >
        
        {errorModal}
        {modalcontent}
          <Grid container spacing={4}>
          
          
          <Grid item xs={12}>
                  <LineInputSelect
                  disabled={userType > USER_TYPE.MERCURIO}
                  data={formData.product_id}
                  onChange={this.onInputChange}/>
                </Grid>
            <Grid item xs={12}>
              <Grid container alignItems='flex-end' spacing={1}>
                
                <Grid item xs>
                  <LineInputText
                  disabled={userType > USER_TYPE.MERCURIO}
                  data={formData.date}
                  onChange={this.onInputChange}/>
                </Grid>
                <ContraintLayout constrainst={constrainst.delete_date} auto >
                  <IconButton size='small' onClick={()=>this.onClear('date')} style={{marginBottom:3}}>
                  <Icon fontSize='small'>delete</Icon>
                  </IconButton>
                </ContraintLayout>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems='flex-end' spacing={1}>
                <Grid item xs>
                  <LineInputText
                  disabled={userType > USER_TYPE.MERCURIO}
                  data={formData.application_date}
                  onChange={this.onInputChange}/>
                </Grid>
                <ContraintLayout constrainst={constrainst.delete_application_date} auto >
                  <IconButton size='small' onClick={()=>this.onClear('application_date')} style={{marginBottom:3}}>
                  <Icon fontSize='small'>delete</Icon>
                  </IconButton>
                </ContraintLayout>
              </Grid>
            </Grid>
            <Grid item xs={12}>
                  <LineInputSelect
                  disabled={userType > USER_TYPE.MERCURIO}
                  data={formData.hospital_id}
                  onChange={this.onInputChange}/>
                </Grid>
            <ContraintLayout constrainst={constrainst.ticket_action} xs={12} md={12} >
              <Grid container>
                  <Grid item xs>
                      <Grid container alignItems='center' spacing={1}>
                          <Grid item><Icon className={classes.icon}  fontSize='large'>folder</Icon></Grid>
                          <Grid item><ContentText text={isLast ? 'Acuse' : 'Ticket'} variant='bold' medium/></Grid>    
                      </Grid>
                  </Grid>
                  {actionButtons}
                </Grid>
            </ContraintLayout>
          </Grid>
      </SimpleModal>
    )
  }

  onUpload = (path) => {
    console.log(`Upload to ${path}`)
    this.setState({selected_path:path})
    this.onOpenModal('upload_document')
  }

  onUploadDocument = async(file) => {
    const {id_use} = this.props.origin_data
    const {selected_path} = this.state
    const {id_patient} = this.props.patient

    let data2send = {ext:getFileExtension(file.name)}
    const type = 'upload_document'
    try{
      this.onEditModal(type,{issending:true})
      const use = await request_upload_document(id_patient, id_use,selected_path,file,data2send)
      this.setState({use:use})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onDelete = (path) => {
    this.setState({selected_path:path})
    this.onOpenModal('delete_document')
  }

  onDeleteDocument = async() => {
    const {id_use} = this.props.origin_data
    const {selected_path} = this.state
    const {id_patient} = this.props.patient
    const type = 'delete_document'
    try{
      this.onEditModal(type,{issending:true})
      const use = await request_delete_document(id_patient, id_use,selected_path)
      this.setState({use:use})
      this.onCloseModal(type)
    }catch(error){
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onDownload = async(path, filename) => {
    const {id_use} = this.props.origin_data
    const errorMessage = 'No se pudo descargar el documento solicitado. Las razones pueden ser porque no se ha cargado previamente el documento o hubo un error en la comunicación'
    console.log(`Download ${filename} to ${path}`)
    try {
      await request_download_document(id_use, path, filename)
    } catch (error) {
      this.setState({error:errorMessage})
    }
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
    
  }

}

export default withStyles(theme => ({
  icon:{
    color:amber[700]
  },
}))(UseDateModal)