import doctor from '../../../../../assets/doctor.png'
import hospital from '../../../../../assets/hospital_2.png'
import insurance from '../../../../../assets/private-insurance.png'
import publicInsurance from '../../../../../assets/public-insurance.png'
import provider from '../../../../../assets/delivery-truck.png'

export const contentData = {
  spanish:{
    layout:{
      title:'Catálogos',
      navigation:[
        {name:'Catálogos',path:'/catalogs'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
    catalogs:[
      {id:0, name:'Doctores', image:doctor, url:'/catalogs/doctors'},
      {id:1, name:'Hospitales', image:hospital, url:'/catalogs/hospitals'},
      {id:2, name:'Aseguradoras privadas', image:insurance, url:'/catalogs/insurance_carriers'},
      {id:3, name:'Aseguradoras públicas', image:publicInsurance, url:'/catalogs/public_insurances'},
      {id:4, name:'Distribuidores', image: provider, url:'/catalogs/providers'}
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID','','Nombre', 'Apellidos','Email','Teléfono','Acciones'],
    tableTitle:'Lista de administradores'
  }
}