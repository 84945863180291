export const formData = {
  sectionA:{
    name:{
      label:'',
      data:'',
    },
    crm_id:{
      label:'',
      data:'',
    },
    uci:{
      label:'',
      data:'',
    },
  },
}