import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import SimpleModal from '../../../../../../../../components/Modals/SimpleModal'
import LineInputSelect from '../../../../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import LineInputText from '../../../../../../../../components/Inputs/LineInputText/LineInputText'


import {form_data} from './data'
import {content} from './content'
import {catalog_matrix} from './catalog_matrix'

class EditProductModal extends Component {

  state = {
    formData: {},
    isValid:false,
  }

  componentDidMount(){
    const {origin_data, catalogs} = this.props
    let temp = JSON.parse(JSON.stringify(form_data))
    if(catalog_matrix){
      catalog_matrix.forEach(item => {
        if(item.catalog_key !== 'none'){
          temp[item.form_key].options = catalogs[item.catalog_key]
        }
        
      })
    }
    Object.keys(form_data).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
      if(origin_data){ 
        let value = origin_data[item]
        let isValid = value !== null && value !== undefined
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null
        }
        if(temp[item].config.type === 'time'){
          value = value ? value : null
          value = value === 'Invalid date' ? null : value
        }
        temp[item].value = isValid ? value : ''
        temp[item].isValid = isValid
      }else{ // When initialization is not necessary
        temp[item].value = temp[item].value ? temp[item].value : '' 
        temp[item].isValid = false
      }
    });

    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
    if(id === 'product_id'){
      this.props.onRequestDiagnostic(data.value)
    }
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, origin_data} = this.props;
    const {formData} = this.state;
    let data2Send = {};

    if(origin_data){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(formData[item].value !== origin_data[item]){
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
    }else{
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
    }
    if(JSON.stringify(data2Send)!=="{}")
      onSubmit(data2Send)
  }

  render(){

    const {data, onClose, addSpace, catalogs, origin_data, onDeleteProduct, type} = this.props
    const {formData} = this.state
    if(formData.diagnosis_id){
      formData.diagnosis_id.options = [...catalogs.diagnostics]
    }
    //formData.diagnosis_id.options = [...catalogs.diagnostics]

    let contentA = null

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      
      switch (formData[item].config.type) {
        
        case 'select':
          inputContent = (
            <LineInputSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <LineInputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }

      let content2return = (
        <Grid item xs={12} md={formData[item].config.md ? formData[item].config.md : 6} key={key.toString()}>
          {inputContent}
        </Grid>
      )

      if(item === 'adjuvant'){
        if(type === 'primary' || type === undefined || type === null){
          content2return = null
        }
      }

      return content2return
    })

    let spaceContent = null

    if(addSpace){
      spaceContent = <Grid item xs={12} style={{height:'200px'}}/>
    }

    let secondProduct = false
    if(origin_data){
      secondProduct = origin_data.secondary ? true : false
    }
    
    return(
      <SimpleModal
        maxWidth={'sm'}
        content={content}
        onSubmit={this.onSubmit}
        data={data}
        onClose={onClose}
        onClick={this.onSubmit}
        onDelete={() => onDeleteProduct(origin_data)}
      >
          <Grid container spacing={3}>
            {contentA}
            {spaceContent}
          </Grid>
      </SimpleModal>
    )
  }
}

export default EditProductModal