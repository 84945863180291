import React from 'react';
import { Icon, IconButton, makeStyles } from '@material-ui/core';


const AddButton = (props) => {

    const classes = useStyles()

    return ( 
        <IconButton {...props} className={classes.root} size='small'>
            <Icon fontSize='small'>
                add
            </Icon>
        </IconButton>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    }
}))
 
export default AddButton;