import React, {Component} from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import { BENEFIT_TYPE, USER_TYPE } from '../../../../../../variables/config';
import { getUserType, getPatientProduct, onGetBenefitVariant, onGetCycleData2Create, onGetBenefitInfo } from '../../../../../../shared/utility';
import { getHospitals2ProviderOptions, getLetterProvidersOptions, getProductsOptions, getOnlyHospitalsOptions, getDiagnosticsOptions } from '../../../../../../shared/customFunctions';
import { request_products, request_patient, request_patientproducts, request_hospitals, 
  request_patient_providers, request_download_provider_letter, request_update_cycle, 
  request_update_use, request_delete_cycle, request_create_cycle, request_edit_previous_cycle, 
  request_change_benefit, request_diagnostics, request_delete_patient_product, 
  request_edit_patient_product, request_add_patient_product, request_recalculate_benefit_by_product, 
  request_recalculate_benefit, request_update_all_cycles, request_add_previous_cycle } from '../../requests';
import ProviderLetterModal from './modals/ProviderLetterModal/ProviderLetterModal';
import BenefitStatusModal from './modals/BenefitStatusModal/BenefitStatusModal';
import EditPreviousCycleModal from './modals/EditPreviousCycleModal/EditPreviousCycleModal';
import FormAModal from '../../../../../../components/Modals/FormAModal';

import {formData as manual_change_data} from './modals/manualchange/data' 
import {contentData as manual_change_content} from './modals/manualchange/content' 
import {catalog_matrix as manual_change_matrix} from './modals/manualchange/catalog_matrix'
import EditProductModal from './modals/EditProductModal/EditProductModal';
import AddProductModal from '../../Modals/AddProductModal/AddProductModal';
import SimpleContainer from '../../../../../../components/Layouts/SimpleContainer';
import Toolbar from './components/Toolbar';
import AddPreviousCycleModal from './modals/AddPreviousCycleModal/AddPreviousCycleModal';
import ErrorWarningModal from '../../../../../../components/Modals/ErrorWarningModal';
import EditManualUsesModal from './modals/EditManualUsesModal/EditManualUsesModal';
import MenuToolbar from './components/MenuToolbar';
import WarningModal from '../../../../../../components/Modals/WarningModal';
import TreatmentHistory from './components/TreatmentHistory/TreatmentHistory';
import FinishTreatmentModal from './modals/FinishTreatmentModal';
import AddGiftModal from './modals/AddGiftModal/AddGiftModal';
import PatientProductCard from '../../../../../../components/DisplayData/PatientProductCard';
import {constrainst_patientproduct} from  './constraints'
import UseDateModalV2 from './modals/UseDateModal/UseDateModalV2';
import PatientProductModal from './modals/PatientProductModal/PatientProductModal';
import AddButton from '../../../../../../components/Buttons/AddButon';
import useAppCatalogs from '../../../../../../hooks/api/useAppCatalogs';

class TreatmentView extends Component {

  state = {
    isloading:true,
    issending:false,
    selected:'PRINCIPAL',
    patient:null,
    patientproducts:[],
    products:[],
    hospitals:[],
    diagnostics:[],
    benefits:[],
    selected_cycle:null,
    selected_use:null,
    selected_precycle:null,
    selected_product:null,
    selected_patientproduct:null,
    patientproduct_type:'primary',
    patient_providers:[],
    modals:{
      edit_provider_letter:{open:false,issending:false,error:null},
      edit_benefit_status:{open:false,issending:false,error:null},
      edit_uses_date:{open:false,issending:false,error:null},
      edit_previous_cycle_modal:{open:false,issending:false,error:null},
      edit_benefit:{open:false,issending:false,error:null},
      error_benefit_modal:{open:false,issending:false,error:null},
      edit_product:{open:false,issending:false,error:null},
      add_product:{open:false,issending:false,error:null},
      add_previous_cycle_modal:{open:false,issending:false,error:null},
      edit_manual_uses:{open:false,issending:false,error:null},
      finish_product_modal:{open:false,issending:false,error:null},
      add_gift_modal:{open:false,issending:false,error:null},
    },
    modalsv2:{
      medical_reports:false,
      add_treatment:false,
      edit_treatment:false
    },
    edit_manual_uses_modal:false,
    finish_treatment_modal:false
  }


  render(){

    const {patient, patientproducts, modals, hospitals, patient_providers, selected_cycle,
      selected_use, selected_precycle, selected_product, products, diagnostics, selected_patientproduct,
      patientproduct_type, isloading, selected, finish_treatment_modal} = this.state


    const {edit_manual_uses_modal} =this.state

    const {catalogs, match}  = this.props

    //const {benefits} = usePlatformContext()
    
    const products_catalog = getProductsOptions(products)
    const clean_hospitals_catalog = getOnlyHospitalsOptions(hospitals)
    const diagnostics_catalog = getDiagnosticsOptions(diagnostics)

    const userType = getUserType()


    let modalcontent = null

    ///////////////////////////////////// ADD PATIENTPRODUCT MODAL//////////////////////////////////////////////
    if(modals.add_product.open){
      modalcontent = (
        <AddProductModal 
          patient={patient}
          addSpace={true}
          type={patientproduct_type}
          catalogs={{
            products:[...products_catalog],
            diagnostics:[...diagnostics_catalog],
            hospitals:[...clean_hospitals_catalog],
            adjuvants: [...catalogs.adjuvant_statuses]
          }}
          data={modals.add_product}
          onClose={() => this.onCloseModal('add_product')}
          onRequestDiagnostic={this.onRequestDiagnostic}
          onSubmit={this.onAddProduct}
        />
      )
    }

    

    ///////////////////////////////////// EDIT PATIENTPRODUCT MODAL//////////////////////////////////////////////
    if(modals.edit_product.open){
      modalcontent = (
        <EditProductModal 
          patient={patient}
          addSpace={true}
          origin_data={selected_patientproduct}
          type={patientproduct_type}
          catalogs={{
            products:[...products_catalog],
            diagnostics:[...diagnostics_catalog],
            hospitals:[...clean_hospitals_catalog],
            adjuvants: [...catalogs.adjuvant_statuses]
          }}
          data={modals.edit_product}
          onClose={() => this.onCloseModal('edit_product')}
          onRequestDiagnostic={this.onRequestDiagnostic}
          onSubmit={this.onSubmitProduct}
          onDeleteProduct={this.onDeleteProduct}
        />
      )
    }

    
    if(edit_manual_uses_modal){
      modalcontent = <EditManualUsesModal origin={selected_patientproduct} 
      onUpdateData={(data) => this.setState({patientproducts:data, edit_manual_uses_modal:false})}
      onClose={() => this.setState({edit_manual_uses_modal:false})}/>
    }

    //console.log(selected_product)
    
    if(finish_treatment_modal){
      console.log('Montar treatment')
      modalcontent = <FinishTreatmentModal patientproduct={selected_product} match={match}
      onUpdateData={(data) => this.setState({patientproducts:data, finish_treatment_modal:false})}
      onClose={() => this.setState({finish_treatment_modal:false})}/>
    }
    

    ///////////////////////////////////// MANUAL EDITION BENEFIT MODAL//////////////////////////////////////////////
    


    if(modals.edit_benefit.open){

      const benefitType = onGetBenefitVariant(selected_product)

      modalcontent = (
        <FormAModal  addSpace={true} maxWidth='xs' catalogs={catalogs}
          origin_data={selected_product}
          data={modals.edit_benefit}
          catalog_matrix={[
            {form_key:'benefit_id', catalog_key:benefitType === BENEFIT_TYPE.NORMAL ? 'benefits' : 'benefits_roactemra'},
          ]}
          content={manual_change_content['spanish']}
          form_data={manual_change_data}
          onClose={() => this.onCloseModal('edit_benefit')}
          onSubmit={this.onManualChange}
        />
      )
    }

    ///////////////////////////////////// PROVIDER LETTER MODAL//////////////////////////////////////////////
    const hospital_catalogs = getHospitals2ProviderOptions(hospitals)
    
    const letter_provider_catalogs = getLetterProvidersOptions(patient_providers)
    //console.log(letter_provider_catalogs)

    let hospital_final_cat = {
      hospitals:[...hospital_catalogs], 
      providers: [...letter_provider_catalogs]
    }

    if(modals.edit_provider_letter.open){
      modalcontent = (
        <ProviderLetterModal 
          addSpace={true}
          use={selected_use}
          catalogs={hospital_final_cat}
          data={modals.edit_provider_letter}
          onClose={() => this.onCloseModal('edit_provider_letter')}
          onSubmit={this.onFinalDownloadProviderLetter}
        />
      )
    }

    ///////////////////////////////////// BENEFIT PROCESS STATUS MODAL//////////////////////////////////////////////

    if(modals.edit_benefit_status.open){
      modalcontent = (
        <BenefitStatusModal 
          origin_data={selected_use}
          catalogs={catalogs ? catalogs : []}
          data={modals.edit_benefit_status}
          onClose={() => this.onCloseModal('edit_benefit_status')}
          onSubmit={(data) => this.onUpdateUseDate(data, 'edit_benefit_status')}
          addSpace
        />
      )
    }

    if(modals.error_benefit_modal.open){
      modalcontent = (
        <ErrorWarningModal 
          data={modals.error_benefit_modal}
          content={{
            title:'¡Hubo un error!',
            message:'Verifique que los datos necesarios para calcular la oferta estén completos',
            cancelButton:'Cancelar',
            deleteButton:'Eliminar'
          }}
          onClose={() => this.onCloseModal('error_benefit_modal')}
          onDelete={this.onDeleteNote}
        />
      )
    }
    ///////////////////////////////////// EDIT DATE STATUS MODAL//////////////////////////////////////////////

    
    if(modals.edit_uses_date.open){

      /*console.log("DEBUG: Product List")
      console.log(products_catalog)

      console.log(selected_use)*/
      console.log('AQUI ESTA EL DATO......')
      console.log(products_catalog)
      //console.log(selected_use)
      //console.log(selected_use.product_name)

      let filtered_products_catalog = []
      if(products_catalog.length && selected_use && selected_use.product_name){
        filtered_products_catalog = products_catalog.filter(el => el.label.includes(selected_use.product_name))
        if(filtered_products_catalog.length === 0){
          filtered_products_catalog = [...products_catalog]
        }
      }else{
        filtered_products_catalog = [...products_catalog]
      }

      modalcontent = (
        <UseDateModalV2
          origin_data={selected_use} patient={patient} open={true} origin={selected_use}
          patientproduct={selected_patientproduct}
          hospitals={[...hospital_catalogs]} noedit={userType === USER_TYPE.PRA}
          products={[...filtered_products_catalog]}
          catalogs={{hospitals:[...hospital_catalogs], products:[...filtered_products_catalog]}}
          data={modals.edit_uses_date}
          onClose={() => this.onCloseModal('edit_uses_date')}
          onSubmit={(data) => this.onUpdateUseDate(data, 'edit_uses_date')}
          onRefreshPatientProducts={this.onRequestPatientProduct}
          onUpdateData={(data)=>this.setState({patientproducts:data})}
          onDelete={this.onDeleteUseDate}
        />
      )
    }

    ///////////////////////////////////// EDIT PREVIOUS CYCLEMODAL//////////////////////////////////////////////

    if(modals.add_previous_cycle_modal.open){
      modalcontent = (
        <AddPreviousCycleModal
          //origin_data={selected_patientproduct}
          catalogs={[]}
          data={modals.add_previous_cycle_modal}
          onClose={() => this.onCloseModal('add_previous_cycle_modal')}
          onSubmit={(data) => this.onAddPreviousCycle(data, 'add_previous_cycle_modal')}
        />
      )
    }

    ////////////////////////////////////////////////////////////////
    if(modals.add_gift_modal.open){
      modalcontent = (
        <AddGiftModal
          origin_data={selected_patientproduct}
          catalogs={[]}
          data={modals.add_gift_modal}
          onClose={() => this.onCloseModal('add_gift_modal')}
          onSubmit={(data) => this.onAddGift(data, 'add_gift_modal')}
        />
      )
    }

    if(modals.edit_previous_cycle_modal.open){
      modalcontent = (
        <EditPreviousCycleModal
          origin_data={selected_precycle}
          catalogs={[]}
          data={modals.edit_previous_cycle_modal}
          onClose={() => this.onCloseModal('edit_previous_cycle_modal')}
          onSubmit={(data) => this.onEditPreviousCycle(data, 'edit_previous_cycle_modal')}
        />
      )
    }

    
    if(modals.finish_product_modal.open){
      modalcontent = (
        <WarningModal data={modals.finish_product_modal} icon='warning'
        content={{message:'¿Realmente quieres finalizar este tratamiento?', deleteButton:'Finalizar'}}
        onClose={() => this.onCloseModal('finish_product_modal')} 
        onDelete={this.onFinishTreatment}/>
      )
    }

    let mainView = null


    //console.log(patientproducts)


    
    switch (selected) {
      case 'PRINCIPAL':
        mainView = (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div>
                <Grid container alignItems='center' spacing={1}>
                  <Grid item >
                    <Typography variant='h6' color='primary' style={{fontWeight:600}}>Tratamientos</Typography>
                  </Grid>
                  {userType < USER_TYPE.PRA ? <Grid item>
                    <AddButton onClick={() => this.onOpenModalV2('add_treatment')}/>
                  </Grid> : null}
                  <Grid item xs>
                    <Toolbar patient={patient}
                      onRecalculateBenefit={this.onRecalculateBenefit}
                      onRecalculateCycles={this.onRecalculateCycles}/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {patientproducts.map(item => {
                  if(item.product_status_id !== 1) return null
                  return(
                    <Grid item xs={12} key={`patient-product-${item?.id_patient_product}`}>
                      <PatientProductCard 
                        patient={patient} no_edit={userType === USER_TYPE.PRA}
                        patient_status={patient?.status_id}
                        patientproduct={item} 
                        constrainst={constrainst_patientproduct} 
                        /// Patient Product's functions
                        onViewMedicalReports={this.onViewMedicalReports}
                        onEditTreatment={this.onEditTreatmentV2}
                        onEditManualUses={this.onPreEditManualUses}
                        onStartTreatment={this.onStartTreatment}
                        onFinishTreatment={this.onPreFinishTreatment}
                        onRefreshPatientProducts={this.onRequestPatientProduct}
                        /// Benefit's funroducts={this.onRequestPatientProduct}
                        /// Benefit's functions
                        onManualChange={this.onPreManualChange}
                        /// Cycle's functions
                        onStartNextCycle={this.onStartNextCycle} 
                        onDeleteCycles={this.onDeleteCycles} 
                        onEditPreCycle={this.onPreEditPreCycle}
                        onAddPreviousCycle={this.onPreAddPreviousCycle}
                        /// Uses's functions
                        onChangeBenefitStatus={this.onChangeBenefitStatus} 
                        onRegisterUse={this.onRegisterUse} 
                        onChangeUseDate={(use) => this.onChangeUseDate(use, item)} 
                        onDownloadProviderLetter={this.onDownloadProviderLetter}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        )
        break;
      case 'HISTORY':
        mainView = (
          <TreatmentHistory patientproducts={patientproducts} catalogs={catalogs} match={match}
          onBack2Main={() => this.setState({selected:'PRINCIPAL'})}
          onUpdateData={(data) => this.setState({patientproducts:data})}/>
        )
        break
    
      default:
        break;
    }



    return(
      <SimpleContainer isloading={isloading}>   
        {modalcontent}
        {/*<MedicalReportListModal 
          open={this.state.modalsv2.medical_reports} patient={patient} patientproduct={selected_patientproduct}
          onClose={() => this.onCloseModalV2('medical_reports')}
    />*/}
        <PatientProductModal mode='add'
          open={this.state.modalsv2.add_treatment} patient={patient} catalogs={catalogs} hospitals={hospitals} products={products}
          onUpdatePatientProducts={(data) => this.setState({patientproducts:data})}
          onClose={() => this.onCloseModalV2('add_treatment')}
        />
        <PatientProductModal mode='edit' origin={selected_patientproduct}
          open={this.state.modalsv2.edit_treatment} patient={patient} catalogs={catalogs} hospitals={hospitals} products={products}
          onUpdatePatientProducts={(data) => this.setState({patientproducts:data})}
          onClose={() => this.onCloseModalV2('edit_treatment')}
        />
        <MenuToolbar selected={selected} onChange={(data) => this.setState({selected:data})}/>
        {mainView}
        
        
      </SimpleContainer>
      
     
    )
  }

  /////////////////////////// INITIALIZATION ////////////////////////////

  onUpdateData = (patientproducts) => {

    //console.log(patientproducts)

    this.setState({
      patientproducts:patientproducts
    })
  }

  onPreEditManualUses = (data) => {
    this.setState({selected_patientproduct:data, edit_manual_uses_modal:true})
  }


  componentDidMount(){
    this.initModule()
  }
  
  initModule = async() => {
    try {
      const catalogManager = useAppCatalogs()
      const {id} = this.props.match.params
      this.setState({isloading:true})
      const patient = await request_patient(id)
      const {id_patient} = patient
      const patientproducts = await request_patientproducts(id_patient)
      const _benefits = await catalogManager.onGetBenefits()
      //console.log(_benefits)
      this.setState({
        isloading:false,
        patient:patient,
        patientproducts:[...patientproducts],
        benefits:_benefits
        
      })
    } catch (error) {
      this.setState({isloading:false})
      console.log(error)
    }
  }
  onRequestPatientProduct = async() => {
    const {id_patient} = this.state.patient
    console.log('sollcititar nuevos pacientes')
    const patientproducts = await request_patientproducts(id_patient)
    this.setState({
      patientproducts:[...patientproducts]
    })
  }

  onRecalculateBenefit = async(data) => {
    this.setState({issending:true})
    try{
      const {id} = this.props.match.params
      let response = await request_recalculate_benefit(id)
      this.setState({patientproducts:response}) 
    }catch(e){
      this.onOpenModal('error_benefit_modal')
    }
    this.setState({issending:false})
  }

  /////////////////////////// PATIENT PRODUCT MANAGEMENT ////////////////////////////

  onAddPatientProduct = async(type) => {
    try {
      const {patient} = this.state
      const hospitals = await request_hospitals()
      const product_params = {product_type_id: patient.product_type_id}
      const products = await request_products(product_params)
      this.setState({hospitals:hospitals, products:products, patientproduct_type:type})
      this.onOpenModal('add_product')
    } catch (error) {
      console.log(error.response)
    }
    
  }

  onAddProduct = async(data) => {
    const {patientproduct_type} = this.state
    const {id} = this.props.match.params
    const type = 'add_product'

    let data2send

    if(patientproduct_type === 'primary'){
      data2send = {
        ...data,
        product_status_id:1,
        secondary:0,
        adjuvant:0,
        patient_id:parseInt(id)
      }
    }else{
      data2send = {
        ...data,
        product_status_id:1,
        secondary:1,
        adjuvant:data.adjuvant === 1 ? 1 : 0,
        patient_id:parseInt(id)
      }
    }
  
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_patient_product(id,data2send)
      const patient_product_id = response
      const patientproducts = await request_patientproducts(id)
      this.setState({patientproducts:patientproducts}) 
      response = await request_recalculate_benefit_by_product(id, patient_product_id)
      this.setState({patientproducts:response}) 
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:'Faltan datos para calcular el beneficio'})
      }
      
    }
    //this.setState({issending:false})
  }

    onEditTreatment = async(data) => {  
      console.log(data)
      const {patient} = this.state
      const diagnostics = await request_diagnostics(data.product_id)
      const hospitals = await request_hospitals()
      const product_params = {product_type_id: patient.product_type_id}
      const products = await request_products(product_params)

      const type = data.secondary === 0 ? 'primary' : 'secondary'

      this.setState({
        selected_patientproduct:data,
        patientproduct_type:type,
        diagnostics:diagnostics,
        hospitals:hospitals,
        products:products})
      this.onOpenModal('edit_product')
    }

    onEditTreatmentV2 = (data) => {
      this.setState({selected_patientproduct:data})
      this.onOpenModalV2('edit_treatment')
    }

    onViewMedicalReports = (_data) => {
      this.setState({
        selected_patientproduct:_data
      })
      this.onOpenModalV2('medical_reports')
    }

    onSubmitProduct = async(data) => {
      const {id} = this.props.match.params
      const {id_patient_product, benefit_benefit, benefit_uses} = this.state.selected_patientproduct
      const type = 'edit_product'  
            
      try {
        this.onEditModal(type,{issending:true})
        if(data.expected_uses){
          if(benefit_benefit && benefit_uses){
            const isApplicable = benefit_benefit + benefit_uses <= data.expected_uses
            data = {...data, applicable: isApplicable ? 1 : 0}
          }else{
            data = {...data, applicable: 0}
          }
        } 
        data = {...data, adjuvant: data.adjuvant === 1 ? 1 : 0}
        let response = await request_edit_patient_product(id,id_patient_product,data)
        this.setState({patientproducts:response}) 
        this.onCloseModal(type)
      } catch (error) {
        this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:'Faltan datos para calcular el beneficio'})
        }
      }
    }

    onDeleteProduct = async(data) => {
      const {id} = this.props.match.params
      const {id_patient_product} = this.state.selected_patientproduct
      const type = 'edit_product'
      try {
        this.onEditModal(type,{issending:true})
        let response = await request_delete_patient_product(id,id_patient_product)
        this.setState({patientproducts:response}) 
        this.onCloseModal(type)
      } catch (error) {
        this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:error.response.data.error.message})
        }
      }
    }


    onRequestDiagnostic = async(id) => {
      this.setState({issending:true})
      try {
        let response = await request_diagnostics(id)
        await this.setState({diagnostics:[...response]})
      } catch (error) {
      }
      this.setState({issending:false})
    }


  /////////////////////////// TREATMENT MANAGEMENT ////////////////////////////

  onPreFinishTreatment = (data) => {
    console.log("Abri modal para termianr")
    this.setState({selected_product:data, finish_treatment_modal:true})
    //this.onOpenModal('finish_product_modal')
  }

  onFinishTreatment = async() => {
    const {id} = this.props.match.params
    const {id_patient_product} = this.state.selected_product
    let data2send = {product_status_id:2}
    const type = 'finish_product_modal'
    try{
      this.onEditModal(type,{issending:true})
      let response = await request_edit_patient_product(id,id_patient_product,data2send)
      this.setState({patientproducts:response}) 
      this.onCloseModal(type)
    }catch(error){
      console.log(error.response)
      this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:error.response.data.error.message})
        }
    }
  }


  onStartTreatment = async(data) => {
    this.setState({issending:true})
    let data2send = onGetCycleData2Create(data)
    data2send.countable = 1
    const benefitType = data?.benefit_type_id
    if(benefitType === BENEFIT_TYPE.INTERMITENT){
      const _benefit_info = onGetBenefitInfo(data?.benefit_id, this.state.benefits)
      if(_benefit_info){
        data2send.total = _benefit_info.total
        data2send.free = _benefit_info.recurrence_array
      }
    }
    try{
      const {id} = this.props.match.params
      let response = await request_create_cycle(id, data2send, benefitType)
      this.setState({patientproducts:response}) 
    }catch(e){
      console.log(e)
    }
    this.setState({issending:false})
  }

  onPreManualChange = (data) => {
    let temp = {
      benefit_id:data.benefit_id
    }
    this.setState({aux_data:{...temp}, selected_product: data})
    this.onOpenModal('edit_benefit')
    
  }

  onManualChange = async(data) => {
    const {id} = this.props.match.params
    const {id_patient_product, expected_uses} = this.state.selected_product
    const {catalogs} = this.props

    const type = 'edit_benefit'

    if(JSON.stringify(data) !== '{}'){
      let data2send
      const benefitType = onGetBenefitVariant(this.state.selected_product)
      if(benefitType === BENEFIT_TYPE.NORMAL){
        const current_benefit = catalogs.benefits.find(el => el.value === data.benefit_id)
        let aux = current_benefit.label.split("+");
        const total = parseInt(aux[0])+parseInt(aux[1])
        const available = expected_uses >= total ? 1 : 0
        data2send = {
          benefit_id: data.benefit_id,
          applicable: available
        }
      }else{
        const available = expected_uses >= 12 ? 1 : 0
        data2send = {
          benefit_id: data.benefit_id,
          applicable: available
        }
      }
      
  
      this.setState({issending:true})
      try {
        this.onEditModal(type,{issending:true})
        let response = await request_change_benefit(id, data2send, id_patient_product)
        this.setState({patientproducts:response})    
        this.onCloseModal(type)
        //this.onActivateSuccessSnack()
      } catch (error) {
        this.onEditModal(type,{issending:false})
        if(error.response){
          this.onEditModal(type,{error:error.response.data.error.message})
        }
      }
      this.setState({issending:false})
    }

    
  }

  /////////////////////////// CYCLE MANAGEMENT ////////////////////////////

  onPreAddPreviousCycle = (patient_product, mode) => {
    console.log(mode)
    this.setState({selected_patientproduct:patient_product})
    this.onOpenModal(mode !== 'gift' ? 'add_previous_cycle_modal' : 'add_gift_modal')
  }

  onAddPreviousCycle = async(data, mode) => {
    const {id} = this.props.match.params
    const {id_patient_product} = this.state.selected_patientproduct
    console.log(mode)
    const type = mode !== 'gift' ? 'add_previous_cycle_modal' : 'add_gift_modal'

    

    const data2send = {
      uses: parseInt(data.uses),
      //uses: parseInt(data.uses),
      countable: data?.countable,
      benefit:data.benefit,
      previous:1,
      patient_product_id: id_patient_product
    }

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_previous_cycle(id,data2send)
      this.setState({patientproducts:response})    
      this.onEditModal(type,{open:false, issending:false, error:null})
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
  }

  onAddGift = async(data) => {
    const {id} = this.props.match.params
    const {id_patient_product} = this.state.selected_patientproduct
    const type = 'add_gift_modal'

    const data2send = {
      uses: 1,
      previous:1,
      patient_product_id: id_patient_product,
      countable:0,
      benefit:1
    }

    try {
      this.onEditModal(type,{issending:true})
      let response = await request_add_previous_cycle(id,data2send)
      this.setState({patientproducts:response})    
      this.onEditModal(type,{open:false, issending:false, error:null})
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
      
    }
  }

  onRecalculateCycles = async() => {
    const {id} = this.props.match.params
    const {patientproducts} = this.state
    const patientproduct = patientproducts && patientproducts.length ? patientproducts[0] : null
    try {
      const benefitType = onGetBenefitVariant(patientproduct)
      let response = await request_update_all_cycles(id, benefitType)
      this.setState({patientproducts:response})    
    } catch (error) {
      if(error.response){
        console.log(error.response)
      }
      
    }
  }

  onPreEditPreCycle = async(cycle) => {
    console.log(cycle)
    try {
      const hospitals = await request_hospitals()
      console.log(hospitals)
      this.setState({selected_precycle:cycle, hospitals:hospitals})
      this.onOpenModal('edit_previous_cycle_modal')
    } catch (error) {
      console.log(error)
    }
    
  }

  onEditPreviousCycle = async(data) => {
    const {id} = this.props.match.params
    const {id_cycle} = this.state.selected_precycle
    const type = 'edit_previous_cycle_modal'
    let data2send = {uses: data.uses ? parseInt(data.uses)+1 : undefined}
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_edit_previous_cycle(id,id_cycle, data2send)
      this.setState({patientproducts:response})    
      this.onEditModal(type,{open:false, issending:false, error:null})
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  onStartNextCycle = async(data) => {


///// ES AQUI

    console.log('Agregar nuevo ciclo en ya existente', data)
    

    let data2send = onGetCycleData2Create(data)
    data2send.countable = 1
    //console.log(data2send)
    //const benefitType = onGetBenefitVariant(data)
    const benefitType = data?.benefit_type_id

    console.log('Benefit type',benefitType)
    console.log('Datasend', data2send)
    if(benefitType === BENEFIT_TYPE.INTERMITENT){
      const _benefit_info = onGetBenefitInfo(data?.benefit_id, this.state.benefits)
      if(_benefit_info){
        data2send.total = _benefit_info.total
        data2send.free = _benefit_info.recurrence_array
      }
    }

    this.setState({issending:true})
    try{
      const {id} = this.props.match.params
      let response
      response = await request_create_cycle(id, data2send, benefitType)
      this.setState({patientproducts:response})      
    }catch(e){
      console.log(e)
    }
    this.setState({issending:false})
  }

  onDeleteCycles = async(cycle) => {
    this.setState({issending:true})
    try{
      const {id} = this.props.match.params
      const {id_cycle} = cycle
      const response = await request_delete_cycle(id, id_cycle)
      this.setState({patientproducts:response})      
    }catch(e){
    }
    this.setState({issending:false})
  }

  ///////////////////////////  CHANGE USE DATE PROCESS ////////////////////////////

  onChangeUseDate = async(use, patientproduct) => {

    try {
      /*
      const {patient} = this.state
      const diagnostics = await request_diagnostics(data.product_id)
      const hospitals = await request_hospitals()
      const product_params = {product_type_id: patient.product_type_id}
      const products = await request_products(product_params)
      */
      const {patient} = this.state
      const hospitals = await request_hospitals()
      const product_params = {product_type_id: patientproduct.product_type_id}
      const products = await request_products(product_params)
      this.setState({selected_use:use, hospitals:hospitals, products:products, selected_patientproduct:patientproduct})
      this.onOpenModal('edit_uses_date')
    } catch (error) {
      console.log(error)
    }
    
  }
  

  ///////////////////////////  BENEFIT STATUS PROCESS ////////////////////////////

  onChangeBenefitStatus =(data) => {
    console.log('OnChange Benefit')
    this.setState({selected_use:data})
    this.onOpenModal('edit_benefit_status')
  }

  ///////////////////////////  USES MANAGMENT ////////////////////////////

  onRegisterUse = async(iduse, status) => {
    try {
      const {id} = this.props.match.params
      const data2send = {applied: status ? 1 : 0}
      let response = await request_update_use(id, iduse, data2send)
      this.setState({patientproducts:response})      
    } catch (error) {
      if(error.response){
        console.log(error.response)
      }
    }
  }

  onUpdateUseDate = async(data, type) => {
    const {id} = this.props.match.params
    const {id_use} = this.state.selected_use
    this.setState({issending:true})
    let data2send = {...data}
    try {
      this.onEditModal(type,{issending:true})
      const response = await request_update_use(id, id_use, data2send)
      this.setState({patientproducts:response})      
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onDeleteUseDate = async() => {
    const {id} = this.props.match.params
    const {id_use} = this.state.selected_use
    const type = 'edit_uses_date'
    let data2send = {date:"@null!!"}
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_update_use(id, id_use, data2send)
      this.setState({patientproducts:response})      
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  ///////////////////////////  PROVIDER LETTER MANAGEMENT ////////////////////////////

  onDownloadProviderLetter = async(_use) => {
    try {
      const {patient} = this.state
      const userType = getUserType()
      if(userType < USER_TYPE.PRA){
        const hospitals = await request_hospitals()
        const patient_providers = await request_patient_providers(patient.id_patient)
        this.setState({hospitals:hospitals, patient_providers:patient_providers, selected_use:_use})
        this.onOpenModal('edit_provider_letter')
      }else{
        await request_download_provider_letter(_use.id_use)
      }
    } catch (error) {
      console.log(error)
    }
  }

  onFinalDownloadProviderLetter = async(data, option) => {

    // Need to be modiffied to selected use
    const {match} = this.props
    const {selected_use} = this.state
    const {id} = match.params
    const {id_use} = selected_use
    
    const type = 'edit_provider_letter'
    try{
      this.onEditModal(type,{issending:true})
      if(data){
      let data2send   
        if(JSON.stringify(data) !== "{}"){
          if(data.alternative_hospital_id){ 
            data2send = {"alternative_hospital":1,...data}
          }else{
            data2send = {"alternative_hospital":option,...data
          }
        }
        }else{
          data2send = {"alternative_hospital":0,...data}
        }
        console.log(data2send)
        let response = await request_update_use(id, id_use, data2send)
        this.setState({patientproducts:response})
        await request_download_provider_letter(id_use)
        this.onCloseModal(type)
      }
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        if(e.response.data){
          if(e.response.data.error){
            this.onEditModal(type,{error:e.response.data.error.message})
          }else{
            this.onEditModal(type, {error:'Hubo un error, intente de nuevo'})
          }
        }else{
          this.onEditModal(type, {error:'Hubo un error, intente de nuevo'})
        }
      }
    }
  }

    ///////////////////////////  FILTER PATIENT PRODUCTS ////////////////////////////


  getPatientProduct = (patientproducts, type) => {
    let result = null
    if(patientproducts && patientproducts.length){
      if(type === 'primary') result = patientproducts.find(el => el.product_status_id === 1 && el.secondary === 0)
      if(type === 'secondary') result = patientproducts.find(el => el.secondary === 1  && el.secondary === 1)
    }
    return result
  }

  /////////////////////////// MODALS MANAGER ////////////////////////////////

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onOpenModalV2 = (type) => {
    let result = {...this.state.modalsv2}
    result[type] = true
    this.setState({
      modalsv2:result
    })
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onCloseModalV2 = (type) => {
    let result = {...this.state.modalsv2}
    result[type] = false
    this.setState({
      modalsv2:result
    })
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}


const styles = theme => ({
  root:{
    padding:32,
    [theme.breakpoints.down('sm')]: {
      padding:16,
    },
  },
  addIcon:{
    background:theme.palette.primary.main,
    padding:4,
    color:'white',
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  }
})

export default withStyles(styles)(TreatmentView)