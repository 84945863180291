import FileSaver from 'file-saver';
import { mainServer } from "../../../../../../../../variables/config"
import axios from  'axios'

export const request_upload_document = async(id, useid, path, file, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.post(`/use/${useid}/${path}`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.path, file, options);
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    response = await mainServer.instance.get(`/use/${useid}`)
    return response.data.data.use
    
}

export const request_download_document = async(useid, path, filename) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response
    response = await mainServer.instance.get(`/use/${useid}/${path}`)
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.get(response.data.data.path, {responseType: 'blob'});
    FileSaver.saveAs(response.data, `${filename}`);
} 

export const request_delete_document = async(id, useid, path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.delete(`/use/${useid}/${path}`)
    let response = await mainServer.instance.get(`/use/${useid}`)
    return response.data.data.use
}
