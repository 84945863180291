import { Grid, makeStyles, Switch, Typography, withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { DASHBOARD_SETTINGS } from '../../../../../variables/config';


const ExpandableButton = (props) => {

    //const [checked, setChec]
    const classes = useStyles()


    return ( 
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <Typography variant='body2'>Vista extendida</Typography>
                </Grid>
                <Grid item>
                    <IOSSwitch {...props}/>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:DASHBOARD_SETTINGS.boxShadow,
        borderRadius:32,
        padding:'8px 8px',
        paddingRight:4
    }
}))
 
export default ExpandableButton;

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 32,
      height: 16,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#4466F2',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 14,
      height: 14,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[800]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });