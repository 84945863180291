import { mainServer } from '../../../../../../variables/config';
import axios from  'axios'
import FileSaver from 'file-saver';

export const request_upload_document = async(idpatient, path, file, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.post(`/patient/${idpatient}/${path}`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.path, file, options);
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    response = await mainServer.instance.get(`/patient/${idpatient}`);
    return response.data.data.patient
}


export const request_upload_insurance_document = async(idpatient, idinsurance, path, file, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.post(`/patientinsurance/${idinsurance}/${path}`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.path, file, options);
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}`)
    return response.data.data.patient_insurances
}

export const request_download_document = async(idpatient, path, filename) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response
    response = await mainServer.instance.get(`/patient/${idpatient}/${path}`)
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.get(response.data.data.path, {responseType: 'blob'});
    FileSaver.saveAs(response.data, `${filename}`);
} 



export const request_download_insurance_document = async(idinsurance, path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response
    response = await mainServer.instance.get(`/patientinsurance/${idinsurance}/${path}`)
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.get(response.data.data.path, {responseType: 'blob'});
    FileSaver.saveAs(response.data, `${path}`);
} 

export const request_delete_document = async(idpatient, path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.delete(`/patient/${idpatient}/${path}`)
    let response = await mainServer.instance.get(`/patient/${idpatient}`);
    return response.data.data.patient
}

export const request_delete_insurance_document = async(idpatient, idinsurance, path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.delete(`/patientinsurance/${idinsurance}/${path}`)
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}`)
    return response.data.data.patient_insurances
}

export const request_patient_insurances = async (idpatient) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}`)
    return response.data.data.patient_insurances
}
  
export const request_insurance_carriers = async() => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/insurancecarrier/all")
    return response.data.data.insurance_carriers
}

export const request_policy_amounts = async () => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response
    response = await mainServer.instance.get("/insurancecarrier/policyamounts")
    return response.data.data.policy_amounts
  }

export const request_add_patient_insurance = async(idpatient, data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    //patient_insurance_id
    await mainServer.instance.post("/patientinsurance",data)
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}`)
    return response.data.data.patient_insurances
}

export const request_edit_patient_insurance = async(idpatient, idinsurance, data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    //patient_insurance_id
    await mainServer.instance.patch(`/patientinsurance/${idinsurance}`,data)
    let response = await mainServer.instance.get(`/patientinsurance/patient/${idpatient}`)
    return response.data.data.patient_insurances
}

////////////////////////////// MEDICAL REPORTS ///////////////////////////////////////
export const request_medical_reports = async (idpatient) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.get(`/patientmedicalreport/patient/${idpatient}`)
    return response.data.data.patient_medical_reports
}


export const request_add_medical_report = async(idpatient, data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    //patient_insurance_id
    await mainServer.instance.post("/patientmedicalreport",data)
    let response = await mainServer.instance.get(`/patientmedicalreport/patient/${idpatient}`)
    return response.data.data.patient_medical_reports
}

export const request_edit_medical_report = async(idpatient, idmedicalreport, data) => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    //patient_insurance_id
    await mainServer.instance.patch(`/patientmedicalreport/${idmedicalreport}`,data)
    let response = await mainServer.instance.get(`/patientmedicalreport/patient/${idpatient}`)
    return response.data.data.patient_medical_reports
}

export const request_upload_medical_report = async(idpatient, idmedicalreport, path, file, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.post(`/patientmedicalreport/${idmedicalreport}/${path}`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.path, file, options);
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    response = await mainServer.instance.get(`/patientmedicalreport/patient/${idpatient}`)
    return response.data.data.patient_medical_reports
}

export const request_download_medical_report = async(idmedicalreport, path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response
    response = await mainServer.instance.get(`/patientmedicalreport/${idmedicalreport}/${path}`)
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.get(response.data.data.path, {responseType: 'blob'});
    FileSaver.saveAs(response.data, `${path}`);
} 

export const request_delete_medical_report = async(idpatient, idmedicalreport, path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.delete(`/patientmedicalreport/${idmedicalreport}/${path}`)
    let response = await mainServer.instance.get(`/patientmedicalreport/patient/${idpatient}`)
    return response.data.data.patient_medical_reports
}
  
