import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import { Switch, Route, Redirect } from "react-router-dom"
import SideBAr from '../../components/SideBar/SideBar.jsx'
import TopBar from '../../components/TopBar/TopBar.jsx'
import adminRoutes from '../../routes/dashboardAdmin'
import genericRoutes from '../../routes/dashboardOthers'
import selfServiceRoutes from '../../routes/dashboardSelfService'
import { withRouter } from 'react-router-dom'
import {
  withWidth
} from '@material-ui/core';
import cx from  'classnames'
import {catalogs as catalogContent}  from '../../variables/catalogs'
import {translations as translationContent}  from '../../variables/translations'
import moment from 'moment-timezone'


import {request_reauth, request_get_user_info} from './request'
import { PlatformProvider } from '../../context/PlatformContext.jsx'
import { USER_TYPE } from '../../variables/config.jsx'

class Dashboard extends Component{
 
  state={
    isDrawerOpen: true,
    isreauth:false,
    section: "HOME",
    user: null,
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data){
      this.initModule()
    }
    
  }

  initModule = async() => {
    try {
      this.setState({isloading:true})
      let response
      response = await request_get_user_info()
      //await request_get_catalogs()
      // Apply bypass
      //console.log('Mi usuario inicial', response)
      if(response.user_type_id === USER_TYPE.MERCURIO && response?.agency_id === null){
        //console.log('Este usuario debe ser cambiado')
        response.user_type_id = USER_TYPE.ADMIN
      }
      

      


      this.setState({user:{...response}})
      this.setState({isloading:false})
    } catch (error) {
      this.setState({isloading:false})
    }
  }

  mouseMoveEvent = async(e) => {
    try {
      const {isreauth} = this.state;
      const data = JSON.parse(localStorage.getItem('data')) 
      if(data && !isreauth){
        const {history} = this.props;
        let expDate = new Date(data.exp*1000)
        let currentDate = new Date()
        if(currentDate.getTime() < expDate.getTime()){ // valid token
          if((currentDate.getTime() + 15*60*1000) > expDate.getTime()){ // expiration date soon 
            this.setState({isreauth:true})
            await request_reauth()
            this.setState({isreauth:false})
          }
        }else{
          localStorage.clear();
          history.push("/signin");
        }
      }
    } catch (error) {
    }
  }

  onChangeView = (url) => {
    const {history, width} = this.props;

    //console.log(url)
    if(url !== '/patients'){
      localStorage.removeItem('patients_config')
    }

    if(width === 'xs' || width === 'sm'){
      this.isDrawerCloset()
    }

    history.push(url)
    
  }

  isDrawerCloset = () => {
    const temp = this.state.isDrawerOpen;
    this.setState({isDrawerOpen: !temp})
  } 

  isDrawerOpen = () => {
    const temp = this.state.isDrawerOpen
    this.setState({isDrawerOpen:!temp})
  }
  handlerExit = () => {
    localStorage.clear();
    this.props.history.push("/signin");
  }
  handlerHome = () => {
    this.props.history.push("/");
    this.setState({section: "HOME"})
  }

  onChangeParentUser = (data) => {
    this.setState({user:{...data}})
  }

  render(){

    const { classes, history } = this.props;
    const {isDrawerOpen, section, user} = this.state;

    let catalogs = catalogContent['spanish']
    const timezones = moment.tz.names().map(item => ({value:item, label:item}))
    catalogs.timezones = [...timezones]
    const translations = translationContent['spanish']
    let contentRedirect = null
    let temp = localStorage.getItem('data');

    let dashboardRoutes = {sections:[]}

    let userLevel = 4
    if(temp){
      temp = JSON.parse(temp);
      let currentTime = new Date();
      let tokenTime = new Date(temp.exp*1000);
      userLevel = temp.user_type_id
      if(userLevel === 1){ // Admin profile
        //console.log('Sidebar de Admin')
        //console.log('Sidebar de Admin', userLevel, temp)
        dashboardRoutes = {...adminRoutes}
      }else if(userLevel === 5){ // Self service profile
        //console.log('Sidebar de Visualizador')
        //console.log('Sidebar de Visualizador', userLevel, temp)
        dashboardRoutes = {...selfServiceRoutes}
      }else{ // The others
        //console.log("Sidebar de otros")
        //console.log('Sidebar de Otros', userLevel, temp)
        dashboardRoutes = {...genericRoutes}
      }


      if(currentTime.getTime() > tokenTime.getTime()){
        
        localStorage.setItem('preurl',history.location.pathname);
        let path2redirect = '/signin';
        contentRedirect = <Redirect to={path2redirect}/>
      }else{
        localStorage.removeItem('preurl')
      }
    }else{
      localStorage.setItem('preurl',history.location.pathname);
      let path2redirect = '/signin';
      contentRedirect = <Redirect to={path2redirect}/>
    }

    

    return(
      <PlatformProvider user={user}>
        <div className={classes.root} onMouseMove={this.mouseMoveEvent}>
                {contentRedirect}
              <TopBar 
                open={isDrawerOpen} 
                user={user}
                isdraweropen={this.isDrawerOpen} 
                onClose={this.isDrawerCloset} 
                closet={this.handlerExit} 
                section={section}
                history={history}
              />
              <SideBAr 
                history={history} 
                user={user}
                routes={dashboardRoutes.sections}
                onChange={this.onChangeView}
                open={isDrawerOpen} 
                onClose={this.isDrawerCloset}
              />
              <main ref={(ref)=> this.mainRef = ref} className={cx({
                [classes.content]:true,
                [classes.contentOpen]:isDrawerOpen
              })}>
                <Switch>
                  {dashboardRoutes.sections.map((route,idx) => {
                    if(route.redirect)
                      return <Redirect to={route.to} key={idx.toString()}/>
                    return <Route 
                      path={route.path} 
                      exact={route.exact}
                      render={(props) => <route.component 
                        {...props} 
                        language={'spanish'} 
                        mainRef={this.mainRef}
                        parentuser={user} 
                        catalogs={catalogs}
                        user_level={userLevel}
                        translations={translations}
                        onChangeParentUser={this.onChangeParentUser}
                      />} 
                      key={idx.toString()}
                    />
                  })
                  }
                </Switch>
              </main>
            </div>
      </PlatformProvider>
      
    );
  }
};


export default withRouter((withStyles(styles)(withWidth()(Dashboard))));