import React from 'react'
import { Grid, makeStyles, Table, TableHead, TableRow, TableCell, TableBody, withStyles, IconButton, Icon } from '@material-ui/core'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import ContentText from '../../../../../components/Texts/ContentText'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons'
import { grey } from '@material-ui/core/colors'
import { TableItem, TableActions } from '../../../../../components/DisplayData/DisplayData'

const CustomCell = withStyles(theme => ({
    
    body:{border:0, '&:hover':{cursor:'pointer'}, borderBottom:`1px solid ${grey[200]}`},
    head:{color:theme.palette.primary.main, paddingBottom:4, borderBottom:`1px solid ${grey[200]}`}
}))(TableCell)


const useStyles = makeStyles(theme => ({
    row:{
        '&:hover':{
            background:`${grey[50]}`
        }
    },
    nodata:{padding:40},
    table:{minWidth:500, },
    tableWrapper:{overflowX: 'auto', paddingLeft:8, paddingRight:8},
    addIcon:{background:theme.palette.secondary.main, padding:6, color:'white', '&:hover':{background:theme.palette.secondary.dark}}
}))


const GenericTable = props => {

    const {data, nodata, onAddItem, table, onSelectedItem, onDeleteItem} = props
    const classes = useStyles()
    const {header} = table

    let contentView = null
    let toolbarContent = null

    if(data && data.length){
        toolbarContent = (
            <Grid item xs={12}>
                <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
                    <Grid item><IconButton onClick={onAddItem} size='small' color='secondary' className={classes.addIcon}><Icon>add</Icon></IconButton> </Grid>
                </Grid>
            </Grid>
        )
        contentView = (
            <div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {header.map((item,key)=>
                                <CustomCell key={key.toString()} variant='head' {...item.config} >{item.label}</CustomCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item,key)=>{

                                const {id_approval_contact, name, email} = item

                                return(
                                    <TableRow key={key.toString()} className={classes.row}>
                                        <CustomCell onClick={() => onSelectedItem(item)}><TableItem data={id_approval_contact}/></CustomCell>
                                        <CustomCell onClick={() => onSelectedItem(item)}><TableItem data={name}/></CustomCell>
                                        <CustomCell onClick={() => onSelectedItem(item)}><TableItem data={email}icon='mail'/></CustomCell>
                                        <CustomCell padding='checkbox'><TableActions data={item} onDelete={onDeleteItem}/></CustomCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
            
            
        )
    }else{
        contentView = (
            <div className={classes.nodata}>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item xs={12}>
                        <ContentText align='center' text={nodata ? nodata : 'No existen registros encontrados'}/>
                    </Grid>
                    <Grid item>
                        <RoundedButton label='Agregar' size='small' onClick={onAddItem}/>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return(
        <div>
            <Grid container spacing={1}>
                {toolbarContent}
                <Grid item xs={12}>
                    <CardWrapper>
                        {contentView}
                    </CardWrapper>
                </Grid>
                
            </Grid>
        </div>
    )
}

export default GenericTable