export const form_data = {
  product_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'product_id',
      type:'select',
      fullWidth: true,
      md:12,
      label:'Producto+tratamiento',
      placeholder:'Seleccione una opción',
      helper:'Producto no válido'
    },
    rules:{
      type:'select',
    }
  },
  date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'date',
      type:'date',
      fullWidth: true,
      md:12,
      label:'Fecha de entrega',
      placeholder:'Fecha de entrega',
      helper:'Fecha no válida'
    },
    rules:{
      type:'date',
    }
  },
  application_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'application_date',
      type:'date',
      fullWidth: true,
      md:12,
      label:'Fecha de aplicación',
      placeholder:'Fecha de aplicación',
      helper:'Fecha no válida'
    },
    rules:{
      type:'date',
    }
  },
  hospital_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'hospital_id',
      type:'select',
      fullWidth: true,
      md:12,
      label:'Hospital de infusión',
      placeholder:'Seleccione un hospital',
      helper:'Hospital no válido'
    },
    rules:{
      type:'select',
    }
  },
}