export const onFilterDataArray = (data, text) => {
  const newArray =  data.filter(item => {
    let mustReturn = false;
    item.data.forEach(el =>{
      let response;
      if(typeof(el) === 'number'){
        response =  el.toString().toLowerCase().includes(text.toLowerCase());
      }else{
        response = el.toLowerCase().includes(text.toLowerCase());
      }
      if(response){
        mustReturn = true;
      }
    })
    return mustReturn;
  })
  return newArray;
}

export const onFillEmptyArray = (emptyRows, rowsPerPage) => {
  let emptylist = [];
  if(emptyRows > 0){
    let i;
    for(i = rowsPerPage - emptyRows; i < rowsPerPage; i++){
      emptylist.push(i)
    };
  }
  return emptylist
}
