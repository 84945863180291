export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    add_button:'Agregar',
    title:{
      sectionA:'Información Básica',
      sectionB:'Información Detallada',
      sectionC:'Detalles',
    },
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      },
      hospital_value_id:{
        label:'Nivel',
        placeholder:'Nivel',
      },
      address:{
        label:'Dirección',
        placeholder:'Dirección',
        helper:'Dirección no válida'
      },
      crm_id:{
        label:'CRM ID',
        placeholder:'CRM ID',
      },
      uci:{
        label:'UCI',
        placeholder:'UCI',
      },
      contact_name:{
        label:'Nombre',
        placeholder:'Nombre',
      },
      contact_email:{
        label:'E-mail',
        placeholder:'E-mail',
      },
      contact_phone:{
        label:'Teléfono',
        placeholder:'Teléfono',
        helper:'El número debe contener entre 10-12 dígitos'
      },
      contact_ext:{
        label:'Extensión',
        placeholder:'Extensión',
      },
    },
  },
  
}