import React from 'react';
import { grey } from '@material-ui/core/colors';
import {Avatar} from '@material-ui/core';


import nouser from '../../assets/nouser.png'
import { imgServerS3 } from '../../variables/config';

const imageURL = imgServerS3

const styles = {
  avatar:{
    width:'200px',
    height:'200px',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  noavatar:{
    width:'200px',
    height:'200px',
    borderRadius:'50%',
    color:'blue',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .24)',
  },
  icon:{
    padding:'20px 25px',
    fontSize:'200px',
    color:grey[400]
  }

}

const SidebarAvatar = (props) => {

  const {image} = props;

  let avatarContent = null;

  if(image && image !== 0 && image !== "0" && image !== ""){
    avatarContent = (
      <Avatar 
        alt={nouser}
        src={imageURL + image}
        style={styles.avatar}
      />
    )
  }else{
    avatarContent = (
      <Avatar 
        alt={'No data'}
        src={nouser}
        style={styles.avatar}
      />
    )
  }

  return(
    <div>
      {avatarContent}
    </div>
  )
}

export default SidebarAvatar;