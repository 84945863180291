export const form_data = {
  uses:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'uses',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'numeric',
      min:1,
      max:99
    }
  },
  countable:{
    value: 1,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
        id:'countable',
        type:'checkbox',
        fullWidth: true,
        label:'¿Es contabilizable?',
        placeholder:'¿Es contabilizable?',
        helper:''
    },
    rules:{
        type:'none',
    }
  },
  benefit:{
    value: 0,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
        id:'benefit',
        type:'checkbox',
        fullWidth: true,
        label:'¿Tiene beneficio?',
        placeholder:'¿Tiene beneficio?',
        helper:''
    },
    rules:{
        type:'none',
    }
  },
}