import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import styles from './styles'
import { Grid } from '@material-ui/core';
import userimage from '../../../../../assets/building.png'
import { contentData } from './content';
import { mainForm } from './data';
import ContentText from '../../../../../components/Texts/ContentText';
import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect';
import HorizontalDataWrapper from '../../../../../components/Texts/HorizontalDataWrapper';

const HospitalDataCard = (props) => {

  const {data} = props

  return(
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Nivel' text={data.hospital_value_name} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Acreditado' text={data.accredited ? 'Acreditado' : 'No Acreditado'} endSpacing/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <ContentText text='Contacto' variant='bold' medium />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Nombre' text={data.contact_name} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='E-mail' text={data.contact_email} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Teléfono' text={data.contact_phone} endSpacing/>
              </Grid>
              <Grid item xs={12} md={6}>
                <HorizontalDataWrapper label='Extensión' text={data.contact_ext} endSpacing/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

class FormStepB extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    formData: JSON.parse(JSON.stringify(mainForm)),
    doctors:[],
    products:[],
    diagnostics:[],
    is_public_insurance:false,
    is_secondary_treatment:false,
  }

  render(){

    const {classes, language, input_data, main_hospital, onInputChange} = this.props

    const content = contentData[language]

    let formdataTemp = {...input_data}

    let main_hospital_content = null

    if(main_hospital){
      main_hospital_content = (
        <Grid item xs={12}>
          <div style={{marginBottom:32}}>
            <HospitalDataCard data={main_hospital}/>
          </div>
          
        </Grid> 
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={3}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img src={userimage} alt={''} className={classes.image}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg>
                <Grid container spacing={6}>
                {/* ------------------ BASIC INFO ------------------------- */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ContentText 
                          text={content.subtitles.basicInfo} 
                          variant='bold'
                          medium
                        />
                        <ContentText 
                          text={content.subtitles.primaryTreatment} 
                          medium
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <LineInputSelect 
                              data={formdataTemp.main_hospital_id}
                              onChange={onInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {main_hospital_content}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
        
      </div>
    )
  }

}

export default withStyles(styles)(FormStepB)