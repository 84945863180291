import React, { useEffect, useState } from 'react'
import { makeStyles, Table, TableRow, TableBody, TableCell, Grid, Checkbox, Avatar, Icon, IconButton, Tooltip, fade } from '@material-ui/core'
import UseComponent from './UseComponent'

const useStyles = makeStyles({
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
})

const UsesComponent = props => {

    const {uses, onChangeBenefitStatus, onRegisterUse, onChangeUseDate, userType, benefitType, onDownloadProviderLetter} = props
    const classes = useStyles()

    const [_uses, setUses] = useState([])

    useEffect(() => {
        if(uses){
            const _data = []
            console.log(uses)
            let last_benefit = 0
            for(let i=0; i<uses.length;i++){
                let use = {...uses[i]}
                if(use.free){
                    // verificar si es candidato a ser liberado
                    const isdatecompleted = checkAllDated(uses, last_benefit, i);
                    const iscompleted = checkAllApplied(uses, last_benefit, i);
                    last_benefit = i;
                    use.isdatecompleted=isdatecompleted
                    use.iscompleted=iscompleted
                }
                _data.push({...use})
            }
            setUses(_data)

        }
    },[uses])

    const checkAllApplied = (uses, startIdx, endIdx) => {
        let status = true
        if(startIdx >= uses.length-1 && endIdx <= uses.length-1){
            for(let i=startIdx; i<endIdx + 1; i++){
                const item = uses[i]
                if(item.applied === 0) status = false
            }
        }
        return status
    }
    const checkAllDated = (uses, startIdx, endIdx) => {
        let status = true
        if(startIdx <= uses.length-1 && endIdx <= uses.length-1){
            for(let i=startIdx; i<endIdx + 1; i++){
                const item = uses[i]
                if(!item.date) status = false
            }
        }
        return status
    }

    return(
        <div className={classes.tableWrapper}>
            <Table className={classes.table}>
               <TableBody>
                    <TableRow>
                        {_uses.map((item, key)=>{
                            return(
                                <TableCell key={key.toString()} style={{border:0}}>
                                    <UseComponent
                                        use={item} benefitType={benefitType}
                                        userType={userType}
                                        onChangeBenefitStatus={onChangeBenefitStatus}
                                        onRegisterUse={onRegisterUse} onDownloadProviderLetter={onDownloadProviderLetter}
                                        onChangeUseDate={onChangeUseDate}/>
                                </TableCell>
                            )
                        })}
                    </TableRow>
               </TableBody>
            </Table>
        </div>
    )
}

export default UsesComponent
