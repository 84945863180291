const styles = (theme) => ({
  root:{
    paddingTop:16,
    marginRight:16,
  },
  image:{
    width:120,
    height:120,
  }
})

export default styles