import { mainServer } from "../../variables/config"

const useAppCatalogs = () => {

    const onGetHospitals = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/hospital/all")
        return response.data.data.hospitals
    }

    const onGetProviders = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/provider/all")
        return response.data.data.providers
    }

    const onGetPublicInsurances = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/publicinsurance/all")
        return response.data.data.public_insurances
    }

    const onGetInsuranceCarriers = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/insurancecarrier/all")
        return response.data.data.insurance_carriers
    }

    const onGetNSE = async(code) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get(
            "/nse/zip", 
            {params:{
            zip:code
            }}
        )
        return response.data.data.nse
    }

    const onGetPolicyAmounts = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/insurancecarrier/policyamounts")
        return response.data.data.policy_amounts
    }

    const onGetDoctors = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/doctor/all")
        return response.data.data.doctors
    }

    const onGetDoctorsByHospital = async() =>{
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/hospitaldoctor/hospital/me")
        return response.data.data.hospital_doctors
    }

    const onCheckExternalId = async(data) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        await mainServer.instance.post("/patient/check/externalid",{external_id:data})
    }

    const onCheckFolio = async(data) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        await mainServer.instance.post("/patient/check/folio",{folio:data})
    }

    const onGetDiagnosticsbyProduct = async(params) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/patientproduct/diagnostics", {params:params})
        return response.data.data.diagnostics
    }

    const onGetSchemesbyProduct = async(params) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/patientproduct/schemes", {params:params})
        return response.data.data.schemes
    }

    const onGetBenefits = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/patientproduct/benefits")
        return response.data.data.benefits
    }




    return {
        onGetHospitals,
        onGetProviders,
        onGetInsuranceCarriers,
        onGetNSE,
        onGetPolicyAmounts,
        onGetPublicInsurances,
        onGetDoctors,
        onGetDoctorsByHospital,
        onCheckExternalId,
        onCheckFolio,
        onGetSchemesbyProduct,
        onGetDiagnosticsbyProduct,
        onGetBenefits
    };
}
 
export default useAppCatalogs;