import { green, grey, lightBlue, red } from "@material-ui/core/colors";
import allprogramIcon from '../assets/header/all_program.png'
import oncopassportProgramIcon from '../assets/header/onco_program.png'
import porTiProgramIcon from '../assets/header/por_ti_program.png'
export const catalogs={
  spanish:{
    genders:[{"id":1,"value":1,"label":"Masculino", acronym:'M'},{"id":2,"value":2,"label":"Femenino", acronym:'F'}],
    benefit_statuses:[{"id":1,"value":1,"label":"No aplica"},{"id":2,"value":2,"label":"En proceso"},{"id":3,"value":3,"label":"Entregado"},{"id":4,"value":4,"label":"No recogido"},{"id":5,"value":5,"label":"No aplicado"},{"id":6,"value":6,"label":"No enviado"},],
    adjuvant_statuses:[{"id":1,"value":1,"label":"Sí"},{"id":0,"value":0,"label":"No"}],
    agencies:[{"id":1,"value":1,"label":"Mercurio"},{"id":2,"value":2,"label":".Pen"}],
    user_statuses:[{"id":0,"value":0,"label":"Inactivo"},{"id":1,"value":1,"label":"Activo"}],
    user_types:[
      {"id":1,"value":1,"label":"Administrador"},
      {"id":2,"value":2,"label":"Aprobador"},
      {"id":3,"value":3,"label":"Centro de Contacto"},
      {"id":4,"value":4,"label":"Visualizar"},
      {"id":5,"value":5,"label":"Autoservicio"},],
    user_genders:[{"id":1,"value":1,"label":"Hombre"},{"id":2,"value":2,"label":"Mujer"},{"id":3,"value":3,"label":"Otro"},],
    patient_statuses:[
      {"id":1,"value":1,"label":"Prospecto", color:grey[300]},
      {"id":2,"value":2,"label":"En Proceso", color:lightBlue[700]},
      {"id":3,"value":3,"label":"Activo", color:green[700]},
      {"id":4,"value":4,"label":"Inactivo", color:red[700]}
    ],
    approval_statuses:[{"id":1,"value":1,"label":"No empezado"},{"id":2,"value":2,"label":"Pendiente de aprobación"},{"id":3,"value":3,"label":"Aprovado"},{"id":4,"value":4,"label":"Rechazado"},{"id":5,"value":5,"label":"Cancelado"},],
    payment_types:[
      {"id":4,"value":4,"label":"Aseguradora (Reembolso)"},
      {"id":1,"value":1,"label":"Aseguradora (Directo)"},
      {"id":2,"value":2,"label":"Bolsillo"},
      {"id":3,"value":3,"label":"Mixto"},],
    letter_statuses:[
      {"id":1,"value":1,"label":"No generada", color:grey[300]},
      {"id":2,"value":2,"label":"Pendiente de envío", color:lightBlue[700]},
      {"id":3,"value":3,"label":"Enviada", color:green[700]},
    ],
    hospital_values:[{"id":1,"value":1,"label":"Muy alto"},{"id":2,"value":2,"label":"Alto"},{"id":3,"value":3,"label":"Medio-Alto"},{"id":4,"value":4,"label":"Medio"},{"id":5,"value":5,"label":"Medio-Bajo"},{"id":6,"value":6,"label":"Bajo"},],
    policy_amounts:[{"id":1,"value":1,"label":"> 3M"},{"id":2,"value":2,"label":"2.5M - 3M"},{"id":3,"value":3,"label":"2M - 2.5M"},{"id":4,"value":4,"label":"1.5M - 2M"},{"id":5,"value":5,"label":"1M - 1.5M"},{"id":6,"value":6,"label":"500K - 1M"},{"id":7,"value":7,"label":"0 - 500K"},],
    policy_values:[{"id":1,"value":1,"label":"Muy alto"},{"id":2,"value":2,"label":"Alto"},{"id":3,"value":3,"label":"Medio-Alto"},{"id":4,"value":4,"label":"Medio"},{"id":5,"value":5,"label":"Medio-Bajo"},{"id":6,"value":6,"label":"Bajo"},],
    benefits:[{"id":1,"value":1,"label":"10+1"},{"id":2,"value":2,"label":"7+1"},{"id":3,"value":3,"label":"6+1"},{"id":4,"value":4,"label":"5+1"},{"id":5,"value":5,"label":"4+1"},{"id":6,"value":6,"label":"3+1"},{"id":7,"value":7,"label":"2+1"}],
    benefits_roactemra:[
      {"id":8,"value":8,"label":"1,5"},
      {"id":9,"value":9,"label":"1,4,7,10"},
      {"id":10,"value":10,"label":"1,4,7,9,11"},
      
    ],
    nses:[{"id":1,"value":1,"label":"Muy Alto"},{"id":2,"value":2,"label":"Alto"},{"id":3,"value":3,"label":"Medio-Alto"},{"id":4,"value":4,"label":"Medio"},{"id":5,"value":5,"label":"Medio-Bajo"},{"id":6,"value":6,"label":"Bajo"},],
    inactive_reasons:[{"id":1,"value":1,"label":"Off label"},{"id":2,"value":2,"label":"Fallecimiento"},{"id":3,"value":3,"label":"Finaliza tratamiento indicado por médico tratante"},{"id":4,"value":4,"label":"Suspende tratamiento por evento adverso"},{"id":5,"value":5,"label":"Cuestiones económicas "},{"id":7,"value":7,"label":"Cambio a sector público"},{"id":6,"value":6,"label":"Otros"},],
    benefit_types:[
      {id:1, value:1, label:'Estándar'},
      {id:2, value:2, label:'Anual'},
    ],
    product_types:[
      {id:1, value:1, label:'Oncología'},
      {id:2, value:2, label:'Alta especialidad'},
    ],
    header_programs_types:[
      {id:-1, value:-1, label:'Todos', icon:allprogramIcon},
      {id:1, value:1, label:'Onco Passport', icon:oncopassportProgramIcon},
      {id:2, value:2, label:'Integral Pass Por Ti', icon:porTiProgramIcon},
    ],
    product_treatment_types:[
      {id:0, value:0, label:'Monopieza'},
      {id:1, value:1, label:'Multipieza'},
    ],
    treatment_frequencies:[
      {id:5, value:5, label:'Diaria'},
      {id:1, value:1, label:'Semanal'},
      {id:2, value:2, label:'Quincenal'},
      {id:3, value:3, label:'Mensual'},
      {id:4, value:4, label:'Bimestral'},
    ],
    inactive_reasons:[
      {id:1, value:1, label:'Off label'},
      {id:2, value:2, label:'Fallecimiento'},
      {id:3, value:3, label:'Finaliza tratamiento indicado por médico tratante'},
      {id:4, value:4, label:'Suspende tratamiento por evento adverso'},
      {id:5, value:5, label:'Cuestiones económicas'},
      {id:6, value:6, label:'Otros'},
      {id:7, value:7, label:'Cambio a sector público'},
    ],
    steps:[
      {id: 1, value: 1, label: 'Días'},
      {id: 2, value: 2, label: 'Semanas'},
      {id: 3, value: 3, label: 'Meses'},
      {id: 4, value: 4, label: 'Años'},
    ],
    nses:[
      {id: 1, value: 1, label: 'Muy Alto'},
      {id: 2, value: 2, label: 'Alto'},
      {id: 3, value: 3, label: 'Medio-Alto'},
      {id: 4, value: 4, label: 'Medio'},
      {id: 5, value: 5, label: 'Medio-Bajo'},
      {id: 6, value: 6, label: 'Bajo'},
    ],
    states:[{"value":"Aguascalientes","label":"Aguascalientes"},{"value":"Baja California","label":"Baja California"},{"value":"Baja California Sur","label":"Baja California Sur"},{"value":"Campeche","label":"Campeche"},{"value":"Chiapas","label":"Chiapas"},{"value":"Chihuahua","label":"Chihuahua"},{"value":"Ciudad de México","label":"Ciudad de México"},{"value":"Coahuila de Zaragoza","label":"Coahuila de Zaragoza"},{"value":"Colima","label":"Colima"},{"value":"Durango","label":"Durango"},{"value":"Guanajuato","label":"Guanajuato"},{"value":"Guerrero","label":"Guerrero"},{"value":"Hidalgo","label":"Hidalgo"},{"value":"Jalisco","label":"Jalisco"},{"value":"México","label":"México"},{"value":"Michoacán de Ocampo","label":"Michoacán de Ocampo"},{"value":"Morelos","label":"Morelos"},{"value":"Nayarit","label":"Nayarit"},{"value":"Nuevo León","label":"Nuevo León"},{"value":"Oaxaca","label":"Oaxaca"},{"value":"Puebla","label":"Puebla"},{"value":"Querétaro","label":"Querétaro"},{"value":"Quintana Roo","label":"Quintana Roo"},{"value":"San Luis Potosí","label":"San Luis Potosí"},{"value":"Sinaloa","label":"Sinaloa"},{"value":"Sonora","label":"Sonora"},{"value":"Tabasco","label":"Tabasco"},{"value":"Tamaulipas","label":"Tamaulipas"},{"value":"Tlaxcala","label":"Tlaxcala"},{"value":"Veracruz de Ignacio","label":"Veracruz de Ignacio"},{"value":"Yucatán","label":"Yucatán"},{"value":"Zacatecas","label":"Zacatecas"}]
  },
  english:{
  },
}
  