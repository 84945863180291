export const formData = {
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  crm_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'crm_id',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  uci:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'uci',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  contact_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'contact_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  contact_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'contact_email',
      type:'email',
      fullWidth: true,
    },
    rules:{
      type:'email',
    }
  },
  contact_phone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'contact_phone',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'phone_number',
    }
  },
  contact_ext:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'contact_ext',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  address:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'address',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:255
    }
  },
}