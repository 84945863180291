export const getDoctorsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    let fullname = item.first_name
    fullname += item.second_name ? ' ' + item.second_name : ''  
    fullname += item.first_surname ? ' ' + item.first_surname : ''
    fullname += item.second_surname ? ' ' + item.second_surname : ''
    return {
      id: item.id_doctor,
      value: item.id_doctor,
      label: fullname
    }
  })
  return temp
}

export const getProductsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_diagnosis,
      value: item.id_diagnosis,
      label: item.name + ', ' + item.presentation
    }
  })
  return temp
}

export const getPublicInsurancesOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_public_insurance,
      value: item.id_public_insurance,
      label: item.name
    }
  })
  temp = [{id:0, value:0, label:'Sin Aseguradora'},...temp]
  return temp
  
}

export const getHospitalsOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_hospital,
      value: item.id_hospital,
      label: item.name
    }
  })
  return temp
}


export const getProvidersOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_provider,
      value: item.id_provider,
      label: item.name
    }
  })
  return temp
}

export const getInsuranceCarriersOptions = (data) => {
  let temp = []
  temp = data.map(item => {
    return {
      id: item.id_insurance_carrier,
      value: item.id_insurance_carrier,
      label: item.name
    }
  })
  return temp
}