import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import BrandCard from '../../../../../../../components/DisplayData/BrandCard';


const Brands = ({ data }) => {
	const classes = useStyles();

	return (
		<Grid container spacing={2} className={classes.root}  >
			{data.map((item, i) => (
				<Grid item xs={12} md={4} lg={3}>
					<BrandCard item={item} key={i} />
				</Grid>
			))}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
        marginTop: 20,
        marginBottom: 20
    },
}));

export default Brands;
