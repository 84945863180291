import {mainServer} from '../../../../variables/config'

export const request_insurance_carriers = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/insurancecarrier/all")
  return response.data.data.insurance_carriers
}

export const request_delete_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/users/"+id)
  let response
  response = await mainServer.instance.get("/users/type/1")
  return response.data.data
}
