import { Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../components/Buttons/ActionModalBar';
import SimpleFile from '../../../../../../components/Files/SimpleFile/SimpleFile';
import SimpleModalV2 from '../../../../../../components/Modals/SimpleModalV2';

const DeleteMedicalReportDocument = (props) => {

    const classes = useStyles()

    const {open, onClose, origin, patient_id, patient_product_id, type,
         onDeleteDocument, onRefreshData, path} = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const [file, setFile] = useState(null) 

    useEffect(() => {
        if(!open){
            setFile(null)
            setError(null)
        }
    }, [open])

    const onSubmit = async() => {

        if(loading) return

        setLoading(true)

        try {
            const result = await onDeleteDocument(origin?.id_patient_medical_report, path)
            if(!result.status){
                setError(result.error)
                setLoading(false)
                return
            }
            if(type === 'patient'){
                await onRefreshData(patient_id)
            }else{
                await onRefreshData(patient_product_id)
            }
            //console.log('Termine')

        } catch (error) {
            console.log(error)
            setError('Opps, hubo un error. Por favor intente de nuevo')
            setLoading(false)
            return
        }

        setLoading(false)
        onClose()
        
    }



    return ( 
        <SimpleModalV2 open={open} onClose={onClose}>
            <div>
                <div style={{marginTop:16}}>
                    <Grid container spacing={2} direction='column' alignItems='center'>
                        <Grid item>
                            <Grid container direction='column' alignItems='center' spacing={1}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>cancel</Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6' className={classes.title}>
                                    ¿Estás seguro?
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' align='center' className={classes.message}>
                                    ¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido
                                </Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{marginTop:16}}>
                        <ActionModalBar 
                            error={error}
                            onCloseError={() => setError(null)}
                            loading={loading}
                            btnColor='red'
                            btnLabel='Eliminar'
                            onSubmit={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </SimpleModalV2>
     );
}

const useStyles = makeStyles(theme => ({
    container:{
        padding:'16px 0px'
    },
    icon:{
        color:red[700],
        fontSize:54
    },
    title:{
        color:grey[900],
        fontWeight:'500'
    },
    message:{
        color:grey[700],
        fontWeight:'400'
    }
}))
 
export default DeleteMedicalReportDocument;