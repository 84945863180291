export const content = {
  title:'Agregar Tratamiento',
  nodata:'Sin Registro',
  error:'Sin registro',
  button:'Agregar',
  cancelButton:'Cancelar',
  image_button:'Cambiar',
  form:{
    product_id:{
      label:'Producto + Tratamiento',
      placeholder:'Seleccione un producto',
    },
    diagnosis_id:{
      label:'Diagnóstico',
      placeholder:'Seleccione un diagnóstico',
    },
    expected_uses:{
      label:'No. de ciclos',
      placeholder:'No. de ciclos',
      helper:'Número entre 1-50'
    },
    hospital_id:{
      label:'Hospital',
      placeholder:'Seleccione un hospital',
    },
    secondary_provider_id:{
      label:'Distribuidor',
      placeholder:'Seleccione un distribuidor',
    },
    adjuvant:{
      label:'¿Es adyuvante?',
      placeholder:'Seleccione una opción',
    },
  },
}