export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    views:[
      {id:0, name:'Datos Principales'},
      {id:1, name:'Hospital'},
      {id:2, name:'Aseguradora'},
      {id:3, name:'Propuesta'},
    ],
    layout:{
      title:'Agregar Beneficiario',
      navigation:[
        {name:'Beneficiarios',path:'/patients'},
        {name:'Agregar',path:'/patients/add'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[
      {id:0, label:'ID', url:'externalid'},
      {id:1, label:'Hospital', url:'doctor'},
      {id:2, label:'Aseguradora', url:'insurancecarrier'},
      {id:3, label:'Tipo', url:'type'},
      {id:null, label:'Sin Filtro', url:null},
    ],
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['ID','Hospital','Aseguradora','NSE', 'Estatus','Oferta','Carta','Beneficio'],
    form:{
      nse_id:{
        label:'Nivel socioeconómico',
        placeholder:'Nivel socioeconómico',
      },
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Longitud de 1-100 caracteres'
      },
      external_id:{
        label:'ID programa de bonificaciones',
        placeholder:'ID programa de bonificaciones',
        helper:'ID no válido'
      },
      folio:{
        label:'Folio',
        placeholder:'Folio',
        helper:'Folio no válido'
      },
      doctor_id:{
        label:'Doctor',
        placeholder:'Seleccione un doctor',
      },
      zip:{
        label:'Código Postal',
        placeholder:'Código Postal',
        helper:'Ingrese un código postal válido'
      },
      nse:{
        label:'Nivel socioeconómico',
        placeholder:'Nivel socioeconómico',
      },
      public_insurance_id:{
        label:'Aseguradora pública',
        placeholder:'Seccione una aseguradora',
      },
      product_id:{
        label:'Producto + Tratamiento',
        placeholder:'Seleccione un producto',
      },
      diagnosis_id:{
        label:'Diagnóstico',
        placeholder:'Seleccione un diagnóstico',
      },
      expected_uses:{
        label:'No. de ciclos',
        placeholder:'No. de ciclos',
        helper:'Número entre 1-50'
      },
      secondary_product_id:{
        label:'Producto + Tratamiento',
        placeholder:'Seleccione un producto',
      },
      secondary_diagnosis_id:{
        label:'Diagnóstico',
        placeholder:'Seleccione un diagnóstico',
      },
      secondary_expected_uses:{
        label:'No. de ciclos',
        placeholder:'No. de ciclos',
        helper:'Número entre 1-50'
      },
      secondary_adjuvant_id:{
        label:'¿Es adyuvante?',
        placeholder:'Seleccione una opción',
      },
      hospital_id:{
        label:'Hospital',
        placeholder:'Seleccione una opción',
      },
      secondary_hospital_id:{
        label:'Hospital',
        placeholder:'Seleccione una opción',
      },
      provider_id:{
        label:'Distribuidor',
        placeholder:'Seleccione una opción',
      },
      secondary_provider_id:{
        label:'Distribuidor',
        placeholder:'Seleccione una opción',
      },
      main_hospital_id:{
        label:'Hospital/Clínina',
        placeholder:'Seleccione una opción',
      },
      insurance_carrier_id:{
        label:'Nombre',
        placeholder:'Seleccione una opción',
      },
      policy_amount_id:{
        label:'Monto',
        placeholder:'Seleccione una opción',
      },
      balance_value:{
        label:'Cantidad de Saldo',
        placeholder:'Cantidad de Saldo',
      },
    }
  }
}