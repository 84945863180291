export const formData = {
  sectionA:{
    first_name:{
      label:'',
      data:'',
    },
    last_name:{
      label:'',
      data:'',
    },
    username:{
      label:'',
      data:'',
    },
    email:{
      label:'',
      data:'',
    },
    mobile:{
      label:'',
      data:'',
    },
    phone:{
      label:'',
      data:'',
    },
    ext:{
      label:'',
      data:'',
    },
    user_type_id:{
      label:'',
      data:'',
    },
    gender_id:{
      label:'',
      data:'',
    },
    timezone:{
      label:'',
      data:'',
    },
    status:{
      label:'',
      data:'',
    },
  },
}