import { USER_TYPE, PATIENT_STATUS, APPROVAL_STATUS } from '../../../../../../../variables/config'

export const constrainst_single_document = {
    validation:{
        byuser:[
            //USER_TYPE.MERCURIO
        ],
    },
    edit_button:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
        ],
    }
}


export const constrainst_principal = {
    add_insurance:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.ROCHE,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
    }

}

export const constrainst_actionbuttons = {

    patient_letter:{
        byuser:[
            USER_TYPE.ADMIN,
            USER_TYPE.MERCURIO,
            USER_TYPE.HOSPITAL
        ],
        bystatus:[
            PATIENT_STATUS.INACTIVE
        ],
        byapprovalstatus:[
            APPROVAL_STATUS.APPROVE
        ],
    }
}