import { TextField, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';

const DateFilter = ({
    value, id, onChange
}) => {

    const classes = useStyles()

    const onInnerChange = (e) => {
        //console.log(e.currentTarget.value)
        onChange({[id]:e.currentTarget.value})
    }

    return ( 
        <div className={classes.date_container}>
            <CssTextField
                id={id}
                type='date'
                value={value}
                className={classes.date}
                onChange={onInnerChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    date_container: {
		borderRadius: 25,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		background: '#FFF',
		minWidth: '150px',
		padding: '6px 12px',
	},
}))
 
export default DateFilter;

const CssTextField = withStyles((theme) => ({
	root: {
		paddingTop: 6,
		'& input': {
			color: '#9e9e9e',
			fontSize: 14,
			fontWeight: 400,
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.6)', // (default alpha is 0.38)
		},
		'& .MuiInput-underline': {
			borderBottomColor: grey[300],
			borderBottomWidth: 2,
			'&:hover:not($disabled):after': {
				//its when its hover and input is focused
			},
			'&:hover:not($disabled):before': {
				borderBottomWidth: 0,
				borderBottomColor: theme.palette.secondary.light, //its when you hover and input is not foucused
			},
			'&:after': {
				borderBottomColor: theme.palette.secondary.main, //when input is focused, Its just for example. Its better to set this one using primary color
			},
			'&:before': {
				borderBottomWidth: 0,
				borderBottomColor: grey[300], // when input is not touched
			},
		},
	},
}))(TextField);