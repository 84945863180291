import React from 'react';
import { Grid, Icon, IconButton, makeStyles, TextField, Typography, withStyles } from '@material-ui/core';
import { useState } from 'react';
import ContentText from '../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';
import GenericPopper from '../../../../components/Poppers/GenericPopper';
import cx from 'classnames'
import { sleep } from '../../../../shared/utility';

const GenericFilter = ({ id, label, filters, placeholder, onChangeFilter, value, disabled, enableClear }) => {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const classes = useStyles();


	const onCloseMenu = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const onOpenMenu = (event) => {
		const { currentTarget } = event;
		setAnchorEl(currentTarget);
		setOpen(true);
	};

	const onClear = async() => {
		onCloseMenu();
		onChangeFilter(null, id);
		await sleep(50)
		onCloseMenu();
	}

	const onSelectedItem = (item) => {
		//console.log(item)
		onCloseMenu();
		onChangeFilter(item, id);
	};

	let filterContent = null;
	if (value) {
		const selected = filters.find((item) => item.value === value);
		filterContent = (
			<Grid container alignItems='center'>
				<Grid item xs>
					<ContentText text={selected?.label} variant='light' />
				</Grid>
				{enableClear ? <Grid item>
					<IconButton size='small'><Icon fontSize='small' onClick={onClear}>close</Icon></IconButton>
				</Grid> : null}
			</Grid>
		);
	} else filterContent = <ContentText text={placeholder} variant='light' />;

	return (
		<div>
			<GenericPopper anchorEl={anchorEl} open={open} onClickAway={onCloseMenu} onSelectedItem={onSelectedItem} menu={filters} />
			<div>
				<Grid container>
					<Grid item xs={12}>
						<div className={cx({[classes.button]:true, [classes.button_disabled]:disabled})} onClick={!disabled ? onOpenMenu : undefined}>
							<Grid container alignItems='center' spacing={2}>
								<Grid item xs>
									<div style={{ minWidth: '100px' }}>
										<Grid container>
											<Grid item xs={12}>
												<Typography variant='caption' color='primary'>{label}</Typography>
											</Grid>
											<Grid item xs={12}>
												{filterContent}
											</Grid>
										</Grid>
										
									</div>
								</Grid>
								<Grid item>
									<Icon className={classes.icon}>keyboard_arrow_down</Icon>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	button: {
		padding: '8px 16px',
		borderRadius: '40px',
		background: 'white',
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	button_disabled:{
		background:'rgb(0,0,0,0.05)',
		'&:hover': {
			cursor: 'auto',
		},
	},
	icon: {
		paddingTop: 3,
		color: grey[500],
		fontSize: 24,
	},
	date_container: {
		borderRadius: 25,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		background: '#FFF',
		minWidth: '150px',
		padding: '6px 12px',
	},
}));

const CssTextField = withStyles((theme) => ({
	root: {
		paddingTop: 6,
		'& input': {
			color: '#9e9e9e',
			fontSize: 14,
			fontWeight: 400,
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.6)', // (default alpha is 0.38)
		},
		'& .MuiInput-underline': {
			borderBottomColor: grey[300],
			borderBottomWidth: 2,
			'&:hover:not($disabled):after': {
				//its when its hover and input is focused
			},
			'&:hover:not($disabled):before': {
				borderBottomWidth: 0,
				borderBottomColor: theme.palette.secondary.light, //its when you hover and input is not foucused
			},
			'&:after': {
				borderBottomColor: theme.palette.secondary.main, //when input is focused, Its just for example. Its better to set this one using primary color
			},
			'&:before': {
				borderBottomWidth: 0,
				borderBottomColor: grey[300], // when input is not touched
			},
		},
	},
}))(TextField);

export default GenericFilter;
