import React from 'react'
import { makeStyles, Grid, Avatar, Icon, Typography } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import Subtitle from '../Texts/Subtitle'
import ContentText from '../Texts/ContentText'


const BenefitData = ({data}) => {
    const classes = useStyles()
    const percentage = parseInt((data ? parseFloat(data.benefit_percentage) : 0.00)*100.00) 

    let contentView = null

    if(data && data.benefit_benefit){
        //console.log('data', data)
        if(data.benefit_type_id === 1 || data.benefit_type_id === 3){
            //console.log('Beneficio tipo 1')
            contentView = (
                <Grid container direction='column' alignItems='center' justifyContent='center'spacing={1}>
                    <Grid item><Subtitle text='PLAN A OFRECER' medium/></Grid>
                    <Grid item>
                        <div className={classes.root}>
                            <div>
                                <Avatar className={classes.firstCircle}>
                                    {data ? data.benefit_uses : '0'}
                                </Avatar>
                            </div>
                            <div className={classes.secondContainer}>
                                <Avatar className={classes.secondCircle}>
                                    {'+'+(data ? data.benefit_benefit : '0')}
                                </Avatar>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <ContentText text={`Ahorro del ${percentage}%`} variant='light' medium/>
                    </Grid>
                </Grid>
            )
        }else{
            const cycles_first = ['1','2','3','4','5','6']
            const cycles_second = ['7','8','9','10','11','12']
            const benefits = data.benefit_name.split(",")

            contentView = (
                <Grid container direction='column' alignItems='center' justifyContent='center'spacing={1}>
                    <Grid item><Subtitle text='PLAN A OFRECER' medium/></Grid>
                    <Grid item><Typography style={{fontSize:18, fontWeight:600, color:'#85368b'}}>RoActemra</Typography></Grid>
                    <Grid item>
                        <div>
                            <Typography><strong>{`${benefits.length} piezas`}</strong> anuales </Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{marginBottom:16}}>
                            <ContentText text={`Ahorro del ${percentage}%`} variant='light' medium/>
                        </div>
                        
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            {cycles_first.map(item=>{
                                let active = Boolean(benefits.find(el => el === item))
                                return(
                                    <Grid item key={`crc-${item}`}>
                                        <div style={{borderRadius:'50%', border:`1px solid #85368b`, width:32, height:32, position:'relative', background: active ? '#85368b' : 'white' , color: active ? 'white' : 'black' }}>
                                            <Typography color='inherit' style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', fontWeight:600}}>{item}</Typography>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid container spacing={1}>
                            {cycles_second.map(item=>{
                                let active = Boolean(benefits.find(el => el === item))
                                return(
                                    <Grid item key={`crc-${item}`}>
                                        <div style={{borderRadius:'50%', border:`1px solid #85368b`, width:32, height:32, position:'relative', background: active ? '#85368b' : 'white' , color: active ? 'white' : 'black' }}>
                                            <Typography color='inherit' style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', fontWeight:600}}>{item}</Typography>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        
    }

    return(
        <div style={{marginBottom:32}}>
            {contentView}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative'
      },
      secondContainer:{
        top:'65px',
        marginTop:'-40px',
        right:0,
        marginRight:'-50px',
        position:'absolute'
      },
      firstCircle:{
        width:'130px',
        height:'130px',
        color:'white',
        background:indigo[900],
        fontSize:72,
      },
      secondCircle:{
        width:'80px',
        height:'80px',
        color:'white',
        background:theme.palette.secondary.main,
        fontSize:36,
      },
      icon:{
          color:theme.palette.secondary.main,
          fontSize:54
      }
}))
 
export default BenefitData;